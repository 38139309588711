<label class="label" *ngIf="labelOutsideBox && label" [ngClass]="{'required': required}">{{ label }}</label>

<igx-input-group [type]="groupType" [ngClass]="{'input-label-external': labelOutsideBox}">

  <label *ngIf="!labelOutsideBox && label" igxLabel [for]="name">{{ label }}</label>

  <input #input="ngModel" igxInput [attr.name]="name" type="number" [(ngModel)]="model" [placeholder]="placeholder"
    [required]="required" [disabled]="disabled" [readonly]="readonly" [tabindex]="index" [min]="min" [max]="max"
    [step]="step" (keydown)="onInputKeyDown($event)" (keyup)="onInputKeyUp($event)" (change)="onInputChanged($event)"
    [igxFocus]="isFocused" />

</igx-input-group>

<!-- Hint -->
<div class="input-helper">
  <div class="error">
    <ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
  </div>
  <div *ngIf="hint" class="desc">{{ hint }}</div>
</div>

<!-- Error Template -->
<ng-template #errorTemplate>
  <span class="text-danger">
    {{ errorMessage }}
  </span>
</ng-template>
