import { Component, OnInit, EventEmitter, Inject, TemplateRef, ElementRef } from '@angular/core';
import { User } from '../../models/user.model';
import { Router, Params, ActivatedRoute, Route, NavigationExtras } from '@angular/router';
import { UserManagementService } from '../../Services/usermanagement.service';
import { UtilityService, RouteHandlerService, ContractHttpResponse, LayoutService, CommonMessageKeys, SsiTabItem } from 'ssi-framework';

@Component({
  selector: 'ssi-identity-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  public userProfileComponent = true;
  public additionalInfo: TemplateRef<ElementRef>;
  public imageUploaded: EventEmitter<any> = new EventEmitter();
  // uncomment for horizontal tab
  selectedIndex: number;
  public url: string;
  user: User;
  backUrl: string;

  constructor(private layoutService: LayoutService, public route: ActivatedRoute, private router: Router,
     private userService: UserManagementService, private utilityService: UtilityService, private routeHandlerService: RouteHandlerService,
    @Inject('EnvironmentVariables') private environment: any, private commonMessageKeys: CommonMessageKeys) {
      
    this.layoutService.setTitle('User Profile');

    userService.onUserUpdated.subscribe(response => {
      if (this.user.ID === response.userId) {
        this.user.Name = response.fullName;
      }
    });
  }
  routes: Route[] = [];
  tabItems: SsiTabItem[] = [];
  parentRoute: string;
  ngOnInit() {
    this.backUrl = this.routeHandlerService.getPreviousUrl();
    this.routes = this.routes.concat(this.routeHandlerService.getChildRoutes("Users/Edit/:id"));
    this.routes.sort((r1, r2) => { return r1.data.sortOrder - r2.data.sortOrder; });
    this.routes.forEach(element => {
      const permission = element.data.permissions ? element.data.permissions : '';
      this.tabItems.push({ Name: element.data.title , Route: element.path, Permission: permission, Url: ''});
    });
    this.route.params.subscribe(
      (params: Params) => {
        const idKey = 'id';
        const id = params[idKey];
        if (id) {
          this.parentRoute = `Users/Edit/${id}`;
          setTimeout(() => {
            this.userService.get(id).then((response: ContractHttpResponse<User>) => {
              if (response.Success === true) {
                this.user = response.Source;
                this.loadProfileImage();
                // uncomment for vertical tab
                // let selectedRoute = this.routes[0];
                this.selectedIndex = 0;
                if (this.route.snapshot.firstChild != null) {
                  const filtered = this.routes.filter(o => o.path === this.route.snapshot.firstChild.routeConfig.path);
                  if (filtered.length > 0) {
                    // uncomment for vertical tab
                    // selectedRoute = filtered[0];
                    this.selectedIndex = this.routes.indexOf(filtered[0]);
                  }
                }
                // uncomment for vertical tab
                // this.router.navigate(['Users/Edit', id, { outlets: { userProfileOutlet: [selectedRoute.path] } }]);
              }
            });
          },20)
        } else {
        }
      }
    );
  }

  loadProfileImage() {
    this.userService.getImageSetting(this.user.ID).then((response: ContractHttpResponse<any>) => {
      if (response.Success === true && response.Source != null) {
        // tslint:disable-next-line: no-shadowed-variable
        this.userService.downloadProfileImage(response.Source.Value).then((response: ContractHttpResponse<any>) => {
          if (response.Success && response.Source != null) {
            const binaryString = response.Source.Content;
            this.url = 'data:image/JPEG;base64,' + binaryString;
          }
        });
      }
    });
  }

  public onBack() {
    this.router.navigate([this.backUrl]);
  }

  // uncomment for vertical tab
  // public onLinkSelected(link: Route) {
  //   let navigationExtras: NavigationExtras = {
  //     queryParams: {
  //       userName: this.user.LoginName,
  //       userId: this.user.ID,
  //     }
  //   }
  //   this.router.navigate(['Users/Edit', this.user.ID, { outlets: { userProfileOutlet: [link.path] } }], navigationExtras);
  // }

  public onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      if (file.size > this.environment.MaxFileSize) {
        let maxSize = (Math.floor((this.environment.MaxFileSize / 1024) / 1024)).toString();
        maxSize = maxSize + ' MB'
        this.utilityService.displayErrorMessage(CommonMessageKeys.FormatString(this.commonMessageKeys.FILE_SIZE_ERROR, [maxSize]));
      } else {
        this.utilityService.getFileInfoDetails(file).then((fileInfoDetail: any) => {
          if (this.environment.AllowedExtensions.includes(fileInfoDetail.Extension ?
             fileInfoDetail.Extension.toLowerCase() : fileInfoDetail.Extension)) {
            this.url = 'data:image/JPEG;base64,' + fileInfoDetail.Content;
            const key = this.utilityService.getUniqueIdentifier(250,true)+fileInfoDetail.Extension;
            this.userService.uploadProfileImage(file.name, file.type, key, fileInfoDetail.Content)
            .then((response: ContractHttpResponse<any>) => {
              if (response.Success === true) {
                // get file id from response and set it to user settings.
                response.Source.Content = fileInfoDetail.Content;
                this.userService.onProfilePictureUpdated.emit({ userId: this.user.ID, image: response.Source });
                this.userService.saveImageID(this.user.ID, response.Source.ID).then((userSettingResponse: ContractHttpResponse<any>) => {
                  this.imageUploaded.emit({ userId: this.user.ID, image: response.Source });
                  if (userSettingResponse.Success === false) {
                    this.utilityService.displayErrorMessage(userSettingResponse.Message);
                  }
                });
              }
            });

          } else {
            this.utilityService.displayErrorMessage(this.commonMessageKeys.INVALID_IMAGE_FILE_SELECTED_ERROR);
          }
        });
      }

      // this.utilityService.getFileInfoDetails(file).then((fileInfoDetail: any) => {
      //   if (this.environment.AllowedExtensions.includes(fileInfoDetail.Extension ?
      // fileInfoDetail.Extension.toLowerCase() : fileInfoDetail.Extension)) {
      //     var reader = new FileReader();
      //     reader.readAsArrayBuffer(event.target.files[0]); // read file as data url
      //     reader.addEventListener('load', (event: any) => {
      //       let buffer = new Uint8Array(event.target.result);
      //       var binaryString = this.ab2str(buffer);
      //       this.url = "data:image/JPEG;base64," + binaryString;
      //       let data: number[] = [];
      //       buffer.forEach(b => { data.push(b); });
      //       if (data.length > this.environment.MaxFileSize) {
      //         let maxSize = (Math.floor((this.environment.MaxFileSize / 1024) / 1024)).toString();
      //         maxSize = maxSize + " MB"
      //         this.utilityService.displayErrorMessage(CommonMessageKeys.FormatString(this.commonMessageKeys.FILE_SIZE_ERROR, [maxSize]));
      //         return;
      //       }

      //       this.userService.uploadProfileImage(file.name, file.type, this.getKey(file.name), data).
      // then((response: ContractHttpResponse<any>) => {
      //         if (response.Success == true) {
      //           //notifying app about image upload
      //           response.Source.Content = data;
      //           //get file id from response and set it to user settings.
      //           this.userService.onProfilePictureUpdated.emit({ userId: this.user.ID, image: response.Source });
      //           this.userService.saveImageID(this.user.ID, response.Source.ID).then((userSettingResponse: ContractHttpResponse<any>) => {
      //             this.imageUploaded.emit({ userId: this.user.ID, image: response.Source });
      //             if (userSettingResponse.Success == false) {
      //               this.utilityService.displayErrorMessage(userSettingResponse.Message);
      //             }
      //           });
      //         }
      //       });

      //     });
      //   } else {
      //     this.utilityService.displayErrorMessage(this.commonMessageKeys.INVALID_IMAGE_FILE_SELECTED_ERROR);
      //   }
      // });
    }
  }

  ab2str(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  getKey(fileName: string): string {
    let result = fileName + '_';
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_-';
    const charactersLength = chars.length;
    for (let i = 0; i < 30; i++) {
      result += chars.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public navigate(eventArgs) {
    const selectedIndex = eventArgs.group.index;

    const navigationExtras: NavigationExtras = {
      queryParams: {
        userName: this.user.LoginName,
        userId: this.user.ID,
      }
    }

    this.router.navigate(['Users/Edit', this.user.ID, { outlets: { ssiTabs: [this.routes[selectedIndex].path] } }], navigationExtras);
  }

}
