import { Component } from '@angular/core';

@Component({
  selector: '.app-auth-content',
  templateUrl: './auth-content.component.html',
  styleUrls: ['./auth-content.component.scss']
})
export class AuthContentComponent {

  constructor() {
   }

}
