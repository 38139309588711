import { Component, OnInit, ViewChild, Input, TemplateRef } from '@angular/core';
import { SSIDialogComponent } from 'ssi-framework';
import { Notification } from '../models/notification';

@Component({
  selector: 'lib-view-notification',
  templateUrl: './view-notification.component.html',
  styleUrls: ['./view-notification.component.scss']
})
export class ViewNotificationComponent implements OnInit {

  @ViewChild('viewNotification', { static: false }) notificationDialog: SSIDialogComponent;

  @Input() viewDetailTemplate: TemplateRef<any>;
  @Input() selectedNotification: Notification;

  constructor() { }

  ngOnInit() {
  }

}
