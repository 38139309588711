import { Component, OnInit, ViewChild, ContentChild, TemplateRef, Inject, AfterContentInit, Input } from '@angular/core';
import { ContractHttpResponse, UserContextService, UtilityService } from 'ssi-framework';
import { NotificationService } from '../services/notification.service';
import { NotificationConstants } from '../Common/notification-constants';
import { SortingDirection } from 'igniteui-angular';
import { Notification } from '../models/notification';
import { ViewNotificationComponent } from '../view-notification/view-notification.component';

declare var $: any;

@Component({
  selector: 'lib-inapp-notification',
  templateUrl: './inapp-notification.component.html',
  styleUrls: ['./inapp-notification.component.scss']
})
export class InappNotificationComponent implements OnInit, AfterContentInit {

  @ContentChild(TemplateRef, { static: false }) viewDetailTemplate: TemplateRef<any>;
  @ViewChild('viewNotificationComponent', {static: false}) viewNotificationComponent: ViewNotificationComponent;

  notifications: Notification[] = new Array();
  readStatus = NotificationConstants.Statuses.Read;
  unreadCount = 0;
  selectedNotification: Notification;
  connection: any;
  notificationHubProxy: any;

  @Input() route: string;

  constructor(private notificationService: NotificationService,
    private ucs: UserContextService, @Inject('EnvironmentVariables') private _environment: any,
    private utilityService: UtilityService) {
  }

  ngOnInit() {
    if (this.route === '' || this.route == null || this.route === undefined)
      this.route = 'Information/EventNotifications';
    this.loadNotification();
    this.initNotificationHub();
  }

  ngAfterContentInit() {
    this.notificationService.viewDetailTemplate = this.viewDetailTemplate;
  }

  getDate(date: string) {
    const selectedDate = this.utilityService.getLocalDateFromUTC(new Date(date)).getTime();
    const today = new Date().getTime();

    const diffMs = (today - selectedDate); // milliseconds
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

    if (diffDays > 0) {
      return diffDays + 'd ago';
    } else if (diffHrs > 0) {
      return diffHrs + 'h ago';
    } else {
      return diffMins + 'm ago';
    }
  }

  transformDateToLocalTime(date: Date): Date {
    const selectedDate = new Date(date);
    return selectedDate;
  }

  MarkAllRead() {
    const modes = [];
    modes.push(NotificationConstants.Modes.InApp);
    const callback = this.notificationService.MarkAllAsRead(modes);
    callback.then((response: ContractHttpResponse<boolean>) => {
      if (response.Success) {
        this.ngOnInit();
      }
    });
  }

  notificationClicked(notification: Notification) {
    if (notification.NotificationStatus === NotificationConstants.Statuses.UnRead) {
      this.notificationService.MarkAsRead(notification.ID).
        then((response: ContractHttpResponse<boolean>) => {
          if (response.Success) {
            this.ngOnInit();
          }
        })
    }

    this.selectedNotification = notification;
    setTimeout(() => {
      this.viewNotificationComponent.notificationDialog.show();
    }, 100);
  }

  private loadNotification(silentRefresh: boolean = false) {
    let params = {
      modes: [NotificationConstants.Modes.InApp],
      statuses: [],
      eventCodes: [],
      subject:  '',
      createdBy: '',
      searchText:  '',
      pageIndex: 0,
      pageSize: 6,
      orderBy: 'CreatedOn',
      orderDirection: 2,
    };

    const filter = {
      modes: [NotificationConstants.Modes.InApp]
    };
    this.notificationService.getRecentNotifications(params)
    .then((n: ContractHttpResponse<Notification[]>) => {
      if (n.Success) {
        this.notifications = n.Source;
      }
    });

    this.notificationService.getUnReadCount(filter.modes, silentRefresh).then((n: ContractHttpResponse<number>) => {
      if (n.Success) {
        this.unreadCount = n.Source;
      }
    });
  }

  private initNotificationHub() {
    this.connection = $.hubConnection(this._environment.SignalRServerEndPoint);
    this.connection.qs = `UserName=${this.ucs.userName}`;

    this.notificationHubProxy = this.connection.createHubProxy('NotificationHub');
    this.notificationHubProxy.on('receiveNotification', (response: any) => {
      this.notificationService.notificationReceived.emit(response);
      if (response.IsSubscribed) {
        this.loadNotification(true);
      }
    });

    this.connection.start();
  }

}
