import { Injectable } from '@angular/core';
import {
	WebApiProxyFactoryService,
	IWebApiProxy,
	BaseApiService,
	PermissionManagerService,
	ContractHttpResponse,
	PagedList,
} from 'ssi-framework';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { SortingDirection } from 'igniteui-angular';
import { Attachment } from '../models/attachment.mode.l';

@Injectable({
	providedIn: 'root',
})
export class AttachmentService extends BaseApiService<Attachment> {
	private readonly proxy: IWebApiProxy;

	constructor(private proxyFactory: WebApiProxyFactoryService, private permissionService: PermissionManagerService) {
		super();
		this.proxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'Attachment');
	}
	//eslint-disable-next-line
	public registerRoutes(route: ActivatedRoute): void {}

	get(id: number): Promise<ContractHttpResponse<Attachment>> {
		const callback = this.proxy.Get(`${id}`);
		return callback;
	}

	public getAll(): Promise<ContractHttpResponse<Attachment[]>> {
		throw new Error('Method not implemented.');
	}

	public getPaged(
		//eslint-disable-next-line
		pageIndex: number,
		//eslint-disable-next-line
		pageSize: number,
		//eslint-disable-next-line
		orderBy: string,
		//eslint-disable-next-line
		orderDir: SortingDirection,
		//eslint-disable-next-line
		filter: any
	): //eslint-disable-next-line
	Promise<ContractHttpResponse<PagedList<Attachment>>> {
		throw new Error('Method not implemented.');
	}
	//eslint-disable-next-line
	public validate(obj: Attachment): boolean {
		return true;
	}
	//eslint-disable-next-line
	public saveOrUpdate(attachment: Attachment): Promise<ContractHttpResponse<Attachment>> {
		throw new Error('Method not implemented.');
	}
	//eslint-disable-next-line
	public delete(id: number): Promise<ContractHttpResponse<boolean>> {
		throw new Error('Method not implemented.');
	}
	public downloadImageBase64(id: number, guid: string): Promise<ContractHttpResponse<Attachment>> {
		const callback = this.proxy.Get('DownloadFile', {
			id: id,
			guid: guid,
		});
		return callback;
	}

  public GetUserProfilePhoto(attachmentId: number): Promise<ContractHttpResponse<any>> {
		return this.proxy.Get(`GetBase64String`, {
			attachmentId: attachmentId,
		});
	}
}
