import { Constants } from './../../../../Common/constants';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { IgxMonthPickerComponent, IgxCalendarComponent } from 'igniteui-angular';
import * as moment from 'moment';
import { SSIDialogComponent } from '../../ssi-dialog/ssi-dialog.component';

@Component({
  selector: 'cal-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, AfterViewInit {

  @Input() date: any;
  @Input() viewType: string;
  @Input() firstDayOfWeek: number;
  @Input() monthDateFormat: string;
  @Input() dayDateFormat: string;
  @Input() displayControlButtons: boolean;

  @Output() dateChanged = new EventEmitter();

  @ViewChild(IgxMonthPickerComponent) monthPicker: IgxMonthPickerComponent;
  @ViewChild(IgxCalendarComponent) calendarPicker: IgxCalendarComponent;
  @ViewChild(SSIDialogComponent) pickerDialog: SSIDialogComponent;

  pickerHeaderDate: string;
  monthPickerDate: Date;
  lastSavedDate;

  constructor() { }

  ngOnInit() {
    moment.locale('en', {
      week: {
        dow: this.firstDayOfWeek,
        doy: moment.localeData('en').firstDayOfYear()
      }
    });

    this.updateHeaderDate();

    this.monthPickerDate = this.date.clone().toDate();

    if (this.displayControlButtons) {
      this.pickerDialog.saveClicked.subscribe(() => {
        this.pickerDialog.hide();
        this.dateChanged.emit(this.date);
      });
      this.pickerDialog.cancelClicked.subscribe(() => {
        this.pickerDialog.hide();
        this.date = this.lastSavedDate;
        this.onDateUpdated();
      });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.updateSelection();
    });
  }

  public getViewType(viewType) {
    if (viewType === 'month') {
      return Constants.CalendarViewType.month;
    } else if (viewType === 'week') {
      return Constants.CalendarViewType.week;
    } else if (viewType === 'day') {
      return Constants.CalendarViewType.day;
    }
  }

  public pickerClicked() {
    if (this.displayControlButtons) {
      this.lastSavedDate = this.date;
    }
    this.pickerDialog.show();
  }

  public onDateSelected(dates) {
    if (Constants.CalendarViewType.month === this.viewType) {
      this.date = moment(dates);
    }
    else if (Constants.CalendarViewType.week === this.viewType) {
      if(this.getWeekNumber(dates[0]) === this.getWeekNumber(dates[1])) {
        this.date = moment(dates[dates.length - 1]);
      } else {
        this.date = moment(dates[0]);
      }
    }
    else {
      const filteredDates = dates.filter(date => !moment(date).isSame(moment(this.date)));
      if (filteredDates?.length) {
        this.date = moment(filteredDates[0]);
      }
    }
    this.onDateUpdated();
    if (!this.displayControlButtons && !(Constants.CalendarViewType.month === this.viewType)) {
      this.pickerDialog.hide();
    }
  }
  public getWeekNumber(date) {
    const firstJan: any = new Date(date.getFullYear(),0,1);
    return Math.ceil((((date - firstJan) / 86400000) + firstJan.getDay()+1)/7);
  }
  public pickerPrev() {
    if (this.viewType === Constants.CalendarViewType.week) {
      this.date = moment(this.date.clone().weekday(0)).subtract(1, 'd');
    } else if (this.viewType === Constants.CalendarViewType.month) {
      this.date.subtract(1, 'M');
    } else if (this.viewType === Constants.CalendarViewType.day) {
      this.date.subtract(1, 'd');
    }

    const eventTriggered = this.onDateUpdated();
    if (!eventTriggered) {
      this.dateChanged.emit(this.date);
    }
  }

  public pickerNext() {
    if (this.viewType === Constants.CalendarViewType.week) {
      this.date = moment(this.date.clone().weekday(6)).add(1, 'd');
    } else if (this.viewType === Constants.CalendarViewType.month) {
      this.date.add(1, 'M');
    } else if (this.viewType === Constants.CalendarViewType.day) {
      this.date.add(1, 'd');
    }

    const eventTriggered = this.onDateUpdated();
    if (!eventTriggered) {
      this.dateChanged.emit(this.date);
    }
  }

  public onViewChange(viewType) {
    this.viewType = viewType;
    this.updateHeaderDate();
    setTimeout(() => {
      this.updateSelection();
    });
  }

  public onMonthSelected(event) {
    if (event.target.className.includes('month') && !this.displayControlButtons) {
      this.pickerDialog.hide();
      this.dateChanged.emit(this.date);
    }
  }

  private updateSelection() {
    if (this.viewType === Constants.CalendarViewType.month) {
      this.monthPicker.selectDate(this.date.clone().toDate());
    } else if (this.viewType === Constants.CalendarViewType.week) {
      this.calendarPicker.deselectDate();
      const firstDay = this.date.clone().startOf('week');
      this.calendarPicker.selectDate([0, 1, 2, 3, 4, 5, 6].map(d => {
        return firstDay.clone().add(d, 'days').toDate();
      }
      ));
      this.calendarPicker.viewDate = this.date.clone().toDate();
    } else if (this.viewType === Constants.CalendarViewType.day) {
      this.calendarPicker.deselectDate();
      this.calendarPicker.selectDate(this.date.clone().toDate());
      this.calendarPicker.viewDate = this.date.clone().toDate();
    }
  }

  private updateHeaderDate() {
    if (this.viewType === Constants.CalendarViewType.week) {
      this.pickerHeaderDate = this.date.clone().startOf('week').format(this.dayDateFormat) + ' - ' +
       this.date.clone().endOf('week').format(this.dayDateFormat);
    } else if (this.viewType === Constants.CalendarViewType.month) {
      this.pickerHeaderDate = this.date.clone().format(this.monthDateFormat);
    } else if (this.viewType === Constants.CalendarViewType.day) {
      this.pickerHeaderDate = this.date.clone().format(this.dayDateFormat);
    }
  }

  private onDateUpdated(): boolean {
    this.updateSelection();
    this.updateHeaderDate();

    if (!this.displayControlButtons && Constants.CalendarViewType.month !== this.viewType) {
      this.dateChanged.emit(this.date);
      return true;
    } else {
      return false;
    }
  }

}
