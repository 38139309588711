
import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-cal-day-tile',
  templateUrl: './calendar-tile.component.html',
  styleUrls: ['./calendar-tile.component.scss']
})
export class CalendarTileComponent implements OnInit {

  @Input() template: ElementRef;
  @Input() data: any;
  @Input() date: Date;
  @Input() parentId: any;
  @Input() displayTileExtensionButton: boolean;
  @Input() tileExpandTemplate: ElementRef;
  @Input() tileCollapseTemplate: ElementRef;
  @Input() isRowExpanded: boolean;

  @Output() notifyOnTileExpanded = new EventEmitter();

  isExpanded = false;

  constructor() { }

  ngOnInit() {
  }

  onTileExpanded() {
    this.notifyOnTileExpanded.emit(this.parentId);
  }

}
