<div class="cal-picker">
  <div class="cal-action" (click)="pickerPrev()">
    <span class="icon icon-chevron-left"></span>
  </div>
  <div class="cal-title-wrap">
    <div class="cal-title"><a href="javascript:;" (click)="pickerClicked()">{{pickerHeaderDate}}</a></div>
    <lib-ssi-dialog #pickerDialog height="400px" width="400px" leftButtonLabel="Close" rightButtonLabel="Select" [closeOnOutsideSelect]="!displayControlButtons">
      <igx-card class="card-wrapper">
        <igx-card-header>
          <h5 class="igx-card-header__title"> {{pickerHeaderDate}} </h5>
        </igx-card-header>
        <igx-card-content *ngIf="viewType == getViewType('month')">
          <igx-month-picker #monthPicker (click)="onMonthSelected($event)" [(ngModel)]="monthPickerDate"
            (onSelection)="onDateSelected($event)">
          </igx-month-picker>
        </igx-card-content>
        <igx-card-content *ngIf="viewType != getViewType('month')">
          <igx-calendar #calendarPicker [weekStart]="firstDayOfWeek" [selection]="'multi'"
            (onSelection)="onDateSelected($event)">
          </igx-calendar>
        </igx-card-content>
      </igx-card>
    </lib-ssi-dialog>
  </div>
  <div class="cal-action" (click)="pickerNext()">
    <span class="icon icon-chevron-right"></span>
  </div>
  <span class="icon icon-calendar ml-1 pointer" (click)="pickerClicked()" style="display: none;"></span>
</div>


