import { AfterViewInit, Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, TemplateRef, ViewChild } from '@angular/core';
import { IgxTreeGridComponent, IgxOverlayOutletDirective, HorizontalAlignment, VerticalAlignment, OverlaySettings, ConnectedPositioningStrategy, CloseScrollStrategy, IgxStringFilteringOperand } from '@infragistics/igniteui-angular';

@Component({
  selector: 'lib-ssi-tree-grid',
  templateUrl: './ssi-tree-grid.component.html',
  styleUrls: ['./ssi-tree-grid.component.scss']
})
export class SsiTreeGridComponent implements OnInit, OnChanges {

  @Input() data: any[] = [];
  @Input() childKey: string;
  @Input() parentKey: string;
  @Input() textKey: string;
  @Input() valueKey: string;
  @Input() selectionLevel: number;
  @Input() showSearchBox = true;
  @Input() showButtons = false;
  @Input() hasSelectionEnabled: Boolean = false;
  @Input() enableSingleSelect: Boolean = false;
  @Input() enableMultiSelect: Boolean = false;
  @Input() enableReadonly: boolean = false;
  @Input() header: string;
  @Input() saveLabel = 'Save';
  @Input() cancelLabel = 'Cancel';
  @Input() width: string;
  @Input() height: string;
  @Input() disable: boolean = false;
  @Input() overlayOutlet: Boolean = false;
  @Output() onSelectionChange = new EventEmitter();
  @Output() saveClicked = new EventEmitter<any[]>();

  @ViewChild(IgxTreeGridComponent, { static: false }) treeGrid: IgxTreeGridComponent;
  @ViewChild(IgxOverlayOutletDirective, { static: false }) outlet: IgxOverlayOutletDirective;
  @ContentChild(TemplateRef, { static: false }) template: TemplateRef<any>;

  showContent = true;
  isWidthUpdated = false;
  public _positionSettings = {
    horizontalStartPoint: HorizontalAlignment.Left,
    horizontalDirection: HorizontalAlignment.Right,
    verticalStartPoint: VerticalAlignment.Bottom,
    verticalDirection: VerticalAlignment.Bottom
  };

  public _overlaySettings: OverlaySettings = {
    closeOnOutsideClick: true,
    modal: true,
    positionStrategy: new ConnectedPositioningStrategy(this._positionSettings),
    scrollStrategy: new CloseScrollStrategy()
  };

  constructor() { }

  ngOnInit(): void {
    this.selectionLevel = null
    setTimeout(() => {
      if (this.width !== undefined && this.width != null && this.height != null && this.height !== undefined) {
        this.isWidthUpdated = true;
        this.treeGrid.width = this.width;
        this.treeGrid.height = this.height;
      }
      else {
      }
    }, 100);
  }

  ngOnChanges(): void{  
  }

  public filter(term) {
    this.treeGrid.filter(this.textKey, term, IgxStringFilteringOperand.instance().condition("contains"));
  }

  clear() {
    this.treeGrid.clearFilter(this.textKey);
  }
  
  public onSingleNodeSelect(event, rowData) {
    this.clearSelection(this.data);
    let array : any [] = [];
    array.push(rowData.ID);
    this.onSelectionChange.emit(array);
  }

  @Input() multiSelection: number[] = [];
  public onMultiNodeSelect(event, rowData) {
    if (event !== undefined && event.checked && rowData !== undefined && rowData !== null && rowData.ID !== undefined && rowData.ID !== null && rowData.ID > 0) {
      var idx = this.multiSelection.indexOf(rowData.ID);
      if (idx < 0) {
        this.multiSelection.push(rowData.ID);
      }
    } else {
      if (this.multiSelection.indexOf(rowData.ID) >= 0) {
        this.multiSelection = this.multiSelection.filter(x => x !== rowData.ID);
      }
    }
    let filtered = this.treeGrid.flatData?.filter(x => x.Selected === true || x.Selected === 'true').map(x => { x.ID });
    this.multiSelection = Array.from(new Set(this.multiSelection.map((item: any) => item)))
    const selectedItems = this.getSelectedValues(this.data, 0);
    this.onSelectionChange.emit(this.multiSelection);
  }

  public onRowSelectionChange(event: any, rowData: any) {
    if (event != null) {
      if (this.enableSingleSelect) {
        this.onSingleNodeSelect(event, rowData)
      }
      else if (this.enableMultiSelect) {
        this.onMultiNodeSelect(event, rowData);
      }
      else {
        this.selectChilds(rowData, event.checked);
        if (event.checked) {
          this.selectParent(rowData);
        } else {
          this.unselectParent(rowData);
        }
        const selectedItems = this.getSelectedValues(this.data, 0);
        this.onSelectionChange.emit(selectedItems);
      }
    }
  }

  private getSelectedValues(items: any[], level: number): any[] {
    let selected: any[] = [];
    if (items != null && items.length) {
      items.forEach(n => {
        if ((this.selectionLevel == null || this.selectionLevel === level) && n.Selected != null && n.Selected) {
          selected.push(n.ID);
        }

        const selectedItems = this.getSelectedValues(n[this.childKey], level + 1);
        if (selectedItems != null && selectedItems.length) {
          selected = selected.concat(selectedItems);
        }
      });
    }
    return selected;
  }

  private clearSelection(items: any[]) {
    if (items != null && items.length) {
      items.forEach(n => {
        if (n.Selected != null && n.Selected) {
          n.Selected = null;
        }
        if (n.Indeterminate != null && n.Indeterminate) {
          n.Indeterminate = null;
        }

        this.clearSelection(n[this.childKey]);
      });
    }
  }

  private selectChildren(items: any[], values: any[]) {
    if (items != null && items.length) {
      items.forEach(n => {
        if (values.indexOf(n[this.valueKey]) !== -1) {
          n.Selected = true;

          this.selectChilds(n, true);
          this.selectParent(n);
        }

        this.selectChildren(n[this.childKey], values);
      });
    }
  }

  private selectChilds(item: any, selected: boolean) {
    const children = item[this.childKey] as any[];
    if (children != null && children.length ) {
      children.forEach(n => {
        if (!this.enableReadonly) {
          n.Selected = n.Readonly != undefined && n.Readonly == true ? false : selected;
          n.Indeterminate = n.Selected == true ? false : n.Indeterminate;
        }else{
          n.Selected = selected;
          n.Indeterminate = n.Selected == true ? false : n.Indeterminate;
        }
        this.selectChilds(n, selected);
      });
    }else{
      item.Selected = selected;
    }
    
  }

  private unselectParent(item: any) {
    let parent = this.treeGrid.flatData.filter(n => n[this.valueKey] === item[this.parentKey]);
    while (parent != null && parent.length) {
      parent[0].Selected = false;
      if (this.isAnyChildrenSelected(parent[0], item)) {
        parent[0].Indeterminate = true;
      } else {
        parent[0].Indeterminate = false;
      }

      const parentId = parent[0][this.parentKey];
      if (parentId > 0) {
        parent = this.treeGrid.flatData.filter(n => n[this.valueKey] === parent[0][this.parentKey]);
      } else {
        parent = null;
      }
    }
  }

  private selectParent(item: any) {
    let parent = this.treeGrid.flatData.filter(n => { return n[this.valueKey] === item[this.parentKey] });
    while (parent != null && parent.length) {
      if (this.isAllChildrenSelected(parent[0], item)) {
        parent[0].Selected = true;
        parent[0].Indeterminate = false;
      } else {
        parent[0].Selected = false;
        parent[0].Indeterminate = true;
      }

      const parentId = parent[0][this.parentKey];
      if (parentId > 0) {
        parent = this.treeGrid.flatData.filter(n => n[this.valueKey] === parent[0][this.parentKey]);
      } else {
        parent = null;
      }
    }
  }

  private isAllChildrenSelected(item: any, ignoreItem: any) {
    let selected = true;
    const children = item[this.childKey] as any[];
    if (children != null && children.length) {
      children.forEach(n => {
        if (n[this.valueKey] !== ignoreItem[this.valueKey] && !n.Selected) {
          selected = false;
        } else {
          const _selected = this.isAllChildrenSelected(n, ignoreItem);
          if (!_selected) {
            selected = false;
          }
        }
      });
    }
    return selected;
  }

  private isAnyChildrenSelected(item: any, ignoreItem: any) {
    let selected = false;
    const children = item[this.childKey] as any[];
    if (children != null && children.length) {
      children.forEach(n => {
        if (n[this.valueKey] !== ignoreItem[this.valueKey] && n.Selected) {
          selected = true;
        } else {
          const _selected = this.isAnyChildrenSelected(n, ignoreItem);
          if (_selected) {
            selected = true;
          }
        }
      });
    }
    return selected;
  }

  private filterDataByLevel() {
    if (this.selectionLevel != null && this.data != null) {
      this.data = this.data.filter((item) => this.hasSelectionLevelItem(item, 1));
    }
  }

  private hasSelectionLevelItem(item: any, level: number): boolean {
    let isValid = false;

    if (level === this.selectionLevel) {
      isValid = true;
    } else {
      const children = item[this.childKey];
      if (children != null && children.length) {
        children.forEach((childItem) => {
          const isValidChild = this.hasSelectionLevelItem(childItem, level + 1);
          if (isValidChild) {
            isValid = true;
          }
        });
      }
    }

    return isValid;
  }

  public setReadonly(values: any[], readonly: boolean) {
    if (values != undefined && values.length > 0) {
      this.setChildrenReadonly(this.data, values, readonly);
    }
  }

  private setChildrenReadonly(items: any[], values: any[], readonly: boolean) {
    if (items != null && items.length) {
      items.forEach(n => {
        if (values.indexOf(n[this.valueKey]) !== -1) {
          n.Readonly = readonly;
        }
        this.setChildrenReadonly(n[this.childKey], values, readonly);
      });
    }
  }

  onCloseToggle(){
    this.clearSelection(this.data);
  }

  onSaveToggle(){
    const selectedItems = this.getSelectedValues(this.data, 1);
    this.saveClicked.emit(selectedItems);
    this.clearSelection(this.data);
  }

}
