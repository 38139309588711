import { Injectable, Injector, EventEmitter, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpHandler, HttpXhrBackend } from '@angular/common/http';
import { SharedConstant } from '../../Helpers/shared-constant';
import { MenuItem } from './menu.model';
import { RouteHandlerService } from '../Routing/route-handler.service';
import { AuthenticateClientService } from '../../Authentication/authenticate-client.service';
import { PermissionManagerService } from '../../Authentication/permission-manager.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  injector = Injector.create({ 
    providers: [
      { provide: HttpClient, deps: [HttpHandler] },
      { provide: HttpHandler, useValue: new HttpXhrBackend({ build: () => new XMLHttpRequest() }) },
    ],
  });
  http: HttpClient = this.injector.get(HttpClient);

  private menuItems: MenuItem[] = [];
  private permissions: string[];
  private completeEvent = new EventEmitter<void>();

  constructor(private sharedConstant: SharedConstant,
    private routerService: RouteHandlerService,
    private authenticateClientService: AuthenticateClientService,
    private permissionManagerSerivce: PermissionManagerService,
    @Inject('EnvironmentVariables') private environment: any) {
  }

  private LoadMenu(): EventEmitter<void> {
    const self = this;
    const path = self.sharedConstant.PathsToMenuFile;
    const request = self.readConfigFile(path);
    request.subscribe(response => {
      this.getMenuJSFromXML(response, self); 
      self.menuItems = self.menuItems.filter((firstLevel: MenuItem) => {  
        if (firstLevel.MenuItem) {
          firstLevel.MenuItem = firstLevel.MenuItem.filter((secondLevel: MenuItem) => {
            if (secondLevel.ComponentSelector != null || (secondLevel.MenuItem && secondLevel.MenuItem.length > 0)) {
              return true;
            }
          });
        }
        if (firstLevel.ComponentSelector != null || (firstLevel.MenuItem && firstLevel.MenuItem.length > 0)) {
          return true;
        }
      });
      self.completeEvent.emit();
    });
    return this.completeEvent;
  }
  private getMenuJSFromXML(xmlText, self: any) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlText, 'text/xml');
    if (xmlDoc.children.length > 0 && xmlDoc.children[0].children.length > 0) {
      this.GetMenuItems(0, (xmlDoc.children[0].children.length - 1), null, xmlDoc.children[0].children, self);
    }
  }
  private GetMenuItems(firstIndex: number, LastIndex: number, parent: MenuItem, menuItems: any, self: any) {
    let childRoot: any;
    if (menuItems == null) {
      return null;
    }
    else {
      childRoot = menuItems[firstIndex];
      const node = this.GetMenuNodes(childRoot);
      if (childRoot.children.length > 0) {
        node.MenuItem = [];
        this.GetMenuItems(node.MenuItem.length, (childRoot.children.length - 1), node, childRoot.children, self);
        if (parent != null) {
          parent.MenuItem.push(node);
        }
        else if (parent == null) {
          if (node.MenuItem.length > 0) {
            this.menuItems.push(node);
          }
        }
      }
      else {
        if ((node.PermissionCode == null || 
          this.permissionManagerSerivce.checkPermission(node.PermissionCode)) && node.ComponentSelector != null) {
          if (parent != null) {
            parent.MenuItem.push(node);
          }
          else {
            this.menuItems.push(node);
          }
        }
      }
    }
    if (firstIndex === LastIndex) {
      return;
    } else {
      this.GetMenuItems(firstIndex + 1, LastIndex, parent, menuItems, self);
    }
  }

  private GetMenuNodes(menuNode) {
  const menuItem: MenuItem = {
    Name: menuNode.getAttribute('Name'),
    DisplayText: menuNode.getAttribute('DisplayText'),
    Icon: menuNode.getAttribute('Icon'),
    Path: menuNode.getAttribute('Path'),
    ComponentSelector: menuNode.getAttribute('ComponentSelector'),
    PermissionCode: menuNode.getAttribute('PermissionCode'),
    MenuItem: null,
    IsActive: false,
    PersistState: false,
    IsLazyRoute: false
  };
  return menuItem;
}

  public GetMenu(): MenuItem[] | EventEmitter < MenuItem[] > {
  if(!this.authenticateClientService.isAuthenticated()) {
  this.menuItems = [];
  return this.menuItems;
}

const self = this;
if (self.menuItems.length > 0) {
  return self.menuItems;
}
else {
  // Permissions
  self.permissions = self.sharedConstant.accessiblePermissions;
  const completeEvent = new EventEmitter<MenuItem[]>();
  const eventMenu = self.LoadMenu();
  eventMenu.subscribe((n: MenuItem[]) => {
    completeEvent.emit(self.menuItems);
  });
  return completeEvent;
}
  }

  private readConfigFile(path: string) {
  const headers = new HttpHeaders({ 'Content-Type': 'text/xml' }).set('Accept', 'text/xml');
  return this.http.get(path, { headers, responseType: 'text' as 'json' });
}

}
