import { IWebApiProxy } from '../../ServiceInterfaces/WebApi/iweb-api-proxy';
import { DiscoveryClientService } from '../Discovery/discovery-client.service';
import { HttpGetHandlerService } from './http-get-handler.service';
import { WebApi } from '../Discovery/web-api';
import { HttpPostHandlerService } from './http-post-handler.service';
import { HttpClient } from '@angular/common/http';
import { HttpDeleteHandlerService } from './http-delete-handler.service';
import { HttpPutHandlerService } from './http-put-handler.service';
import { IOrderColumn } from '../../Infrastructure/Models/order-column.model';

export class WebApiProxy implements IWebApiProxy {
	private webApi: WebApi | Promise<WebApi>;

	constructor(
		appCode: string,
		contract: string,
		private discovery: DiscoveryClientService,
		private http: HttpClient
	) {
		this.webApi = this.discovery.Resolve(appCode, contract);
	}

	public Get(
		apiName: string,
		data?: any,
		silentRefresh?: boolean,
		isCancelable: boolean = true,
		orderColumns: IOrderColumn[] = null
	): Promise<any> {
		var clonedObj = this.CloneObject(data);
		if (orderColumns != null) {
			let orderBy = [];
			orderColumns.forEach((col) => {
				orderBy.push(col.OrderBy + '|' + col.OrderDirection);
			});

			clonedObj['orderBy'] = orderBy;
		}
		const handler = new HttpGetHandlerService(
			this.webApi,
			apiName,
			clonedObj,
			this.http,
			silentRefresh,
			isCancelable
		);
		return handler.Handle();
	}

	public Post(apiName: string, data?: any, silentRefresh?: boolean): Promise<any> {
		const handler = new HttpPostHandlerService(
			this.webApi,
			apiName,
			this.CloneObject(data),
			this.http,
			silentRefresh
		);
		return handler.Handle();
	}

	public Put(apiName: string, data?: any, silentRefresh?: boolean): Promise<any> {
		const handler = new HttpPutHandlerService(
			this.webApi,
			apiName,
			this.CloneObject(data),
			this.http,
			silentRefresh
		);
		return handler.Handle();
	}

	public Delete(apiName: string, data?: any, silentRefresh?: boolean): Promise<any> {
		const handler = new HttpDeleteHandlerService(
			this.webApi,
			apiName,
			this.CloneObject(data),
			this.http,
			silentRefresh
		);
		return handler.Handle();
	}

	public GetURL(): Promise<string> {
		return new Promise<any>((resolve, reject) => {
			if (this.webApi instanceof Promise) {
				(this.webApi as Promise<WebApi>).then((n: WebApi) => {
					resolve((n as WebApi).Addresses[0]);
				});
			} else {
				resolve((this.webApi as WebApi).Addresses[0]);
			}
		});
	}

	private CloneObject(data?: any) {
		if (data != null) {
			const json = JSON.parse(JSON.stringify(data), (key, value) => {
				const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
				const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

				if (typeof value === 'string') {
					let a = reISO.exec(value);
					if (a) return new Date(value);
					a = reMsAjax.exec(value);
					if (a) {
						const b = a[1].split(/[-+,.]/);
						return new Date(b[0] ? +b[0] : 0 - +b[1]);
					}
				}
				return value;
			});
			return json;
		}
	}
}
