<div class="main-content-holder row">
  <div class=" col p0"> 
    <igx-grid #FWRoleGrid id="FWRoleGrid" ssiGrid [actionItems]="items" [enableDefault]="true">
      <igx-column field="Code" header="Code">
        <ng-template igxCell let-val let-cell="cell">
          <a href="Javascript:void(0)" *ssiPermission="'EDIT_ROLE'; else noPermissionTemplate"
            [routerLink]="['Edit', cell.cellID.rowID.ID]">
            {{ val }}
          </a>
          <ng-template #noPermissionTemplate>
            {{ val }}
          </ng-template>
        </ng-template>
      </igx-column>
      <igx-column field="Name" header="Name">
      </igx-column>
      <!-- <igx-column field="CompanyName" header="Company"></igx-column> -->
      <igx-column field="CreatedBy" header="Created By" [hidden]="true"> 
      </igx-column>
      <igx-column field="CreatedOn" header="Created On" [hidden]="true">
        <ng-template igxCell let-value>
          {{ value | ssiDate :"datetime" : true }}
        </ng-template>
      </igx-column>
      <igx-column field="ModifiedBy" header="Modified By" [hidden]="true">
      </igx-column>
      <igx-column field="ModifiedOn" header="Modified On" [hidden]="true">
        <ng-template igxCell let-value>
          {{ value | ssiDate :"datetime" : true }}
        </ng-template>
      </igx-column>
      <igx-column></igx-column>

      <ng-template igxToolbarCustomContent let-igxGrid="grid">
        <div class="action-area">
          <ul class="btn-action-list">
            <li>
              <a [routerLink]="['/Roles/Add']">
                <button ssiTooltip="Add Role" igxButton="flat" class="btn-primary"
                  *ngIf="permissionMangerService.checkPermission('ADD_ROLE')">
                  <span class="icon icon-add-btn"></span>Add
                </button>
              </a>
            </li>
          </ul>
        </div>

        <lib-ssi-filter #ssiGridFilter class="filter" (search)="onApplyFilter($event)"
          (saveAndSearch)="onSaveAndApplyFilter()" (resetSearch)="onResetFilter()">
          <div class="ssi-ThreeColForm">
            <div class="row">
              <div class="col control-holder">
                <igx-input-group>
                  <label igxLabel for="code">Code</label>
                  <input igxInput name="code" type="text" autocomplete="none" />
                </igx-input-group>
              </div>
              <div class="col control-holder">
                <igx-input-group>
                  <label igxLabel for="name">Name</label>
                  <input igxInput name="name" type="text" autocomplete="none" />
                </igx-input-group>
              </div>
              <div class="col control-holder">
                <lib-ssi-select label="Company" name="companyId" [items]="companies"
                  itemKey="ID" itemText="Name" [singleSelect]="true">
                </lib-ssi-select>
              </div>
            </div>
          </div>
        </lib-ssi-filter>
      </ng-template>
    </igx-grid>
  </div>
</div>


<lib-ssi-dialog #copyRoleDialog header="Copy Role" rightButtonLabel="Save" (saveClicked)="onCopyRoleSaved()"
  leftButtonLabel="Cancel" (cancelClicked)="onCopyRoleCancel()" width="600" height="300">

  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <span>Please provide the following information to create a copy.</span>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col control-holder">
            <lib-ssi-select label="Copy From Role" name="RoleID" [items]="roles"
              itemKey="ID" itemText="Code" [singleSelect]="true" [(singleModel)]="model.ID" [required]="true">
            </lib-ssi-select>
          </div>
          <div class="col control-holder">
            <lib-ssi-select #companyRef label="Company" [items]="companies"
              itemKey="ID" itemText="Name" [singleSelect]="true" [(singleModel)]="model.CompanyID" [required]="true">
            </lib-ssi-select>
          </div>
        </div>

        <div class="row">
          <div class="col control-holder">
            <igx-input-group>
              <label igxLabel for="Code">Code</label>
              <input igxInput igxFocus="true" tabindex="1" [(ngModel)]="model.Code" name="Code" type="text"
                autocomplete="none" [required]="true" />
            </igx-input-group>
          </div>

          <div class="col control-holder">
            <igx-input-group #nameInputGroup>
              <label igxLabel for="Name">Name</label>
              <input tabindex="2" #nameInput igxInput [(ngModel)]="model.Name" name="Name" type="text"
                autocomplete="none" [required]="true" />
            </igx-input-group>
          </div>
        </div>
        <div class="row">
          <div class="col control-holder">
            <igx-input-group class="input-group__textarea">
              <label igxLabel for="Description">Description</label>
              <textarea tabindex="3" igxInput [(ngModel)]="model.Description" name="Description" type="text"></textarea>
            </igx-input-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</lib-ssi-dialog>
