import { Injectable } from '@angular/core';
import { WebApiProxyFactoryService, IWebApiProxy, ContractHttpResponse } from 'ssi-framework';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	private readonly proxy: IWebApiProxy;

	constructor(private proxyFactory: WebApiProxyFactoryService) {
		this.proxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'Authentication');
	}
	//eslint-disable-next-line
	public registerRoutes(route: ActivatedRoute): void {}

	verifyGUID(guid: string, authenticationTypeID: number): Promise<ContractHttpResponse<number>> {
		const callback = this.proxy.Get('VerifyGUID', {
			guidID: guid,
			authenticationTypeID: authenticationTypeID,
		});
		return callback;
	}

	generateOTPForSignIn(name,password): Promise<ContractHttpResponse<boolean>> {
		return this.proxy.Get('generateOTPForSignIn',{name,password});
	}

	validateOtpForSignIn(name,password,otp): Promise<ContractHttpResponse<any>> {
		return this.proxy.Get('validateOtpForSignIn',{name,password,otp});
	}

}
