import { Injectable } from '@angular/core';

@Injectable()
export class SharedMessagingKeys {
    public static readonly RESET_PASSWORD_URL_SENT_MESSAGE: string = "Reset Password URL has been sent to your email address which will be expired with in 1 hour.";
    public static readonly INVALID_EMAIL_ADDRESS: string = "No user exists against the entered email address.";
    public static readonly ENTER_VALID_EMAIL_ADDRESS: string = "Please enter valid email address.";
    public static readonly NOTIFICATION_SUCCESS: string = "Notification has been sent successfully.";
    public static readonly NOTIFICATION_FAILURE: string = "System is experiencing some problem while sending notification. Please try again.";
    

    public static FormatString(str: string, val: string[]): string {
        if (val) {
            for (let index = 0; index < val.length; index++) {
                str = str.replace(`{${index}}`, val[index]);
            }
        }

        return str;
    }

}
