import { Directive, Input, ViewContainerRef, Inject, ComponentFactory, ComponentFactoryResolver, OnInit, TemplateRef, EmbeddedViewRef, Component, ViewChild, Output, EventEmitter } from '@angular/core';

import { GridSettings, ColumnSetting } from '../../Infrastructure/Models/grid-settings.model';
import { IgxColumnComponent, IgxGridComponent, ISortingExpression, FilterMode, NoopSortingStrategy, IgxExcelExporterService, IgxExcelExporterOptions, IgxCsvExporterService, IgxCsvExporterOptions, CsvFileTypes, IColumnExportingEventArgs } from 'igniteui-angular';
import { UserSettingsService } from '../../WebInfrastructure/Services/user-settings.service';
import { RowActionMenuComponent } from '../Controls/grid/row-action-menu/row-action-menu.component';
import { GridRowActionItem } from '../../Infrastructure/Models/grid-row-action-item';
import { LayoutService } from '../../WebInfrastructure/Services/layout.service';
import { GridRowActionType } from '../../Infrastructure/Models/grid-row-action-type';
import { RouteHandlerService } from '../../WebInfrastructure/Routing/route-handler.service';
import { GridExporterComponent } from '../Controls/grid/grid-exporter/grid-exporter.component';

@Directive({
  selector: '[ssiGrid]'
})
export class SsiGridDirective implements OnInit {
  @Input() ssiGrid: boolean;
  @Input() enableDefault: boolean;
  @Input() enablePaging: boolean;
  @Input() enableSorting: boolean;
  @Input() enableToolbar: boolean;
  @Input() enableFiltering: boolean;
  @Input() enableColumnHiding: boolean;
  @Input() enableAutoGenerate: boolean;
  @Input() enablePersonalization: boolean;
  @Input() enableColumnMovable: boolean;
  @Input() enableColumnResizable: boolean;
  @Input() enableColumnPinning: boolean;
  @Input() actionItems: GridRowActionItem[];
  @Input() sortingStrategy : NoopSortingStrategy;
  @Input() rowActionType: string = GridRowActionType.IconOverlay;
  @Input() rowActionPinToLeft: boolean = false;
  @Input() rowMenuColumnHeader: string = '';
  @Input() exportGridData: boolean = false;
  @Input() ExcelExport: boolean = false;
  @Input() CSVExport: boolean = false;
  @Output() getRemoteData = new EventEmitter();
  @Input() setRemoteData : any;

  gridId: string;

  private isInitialSortCall = true;

  constructor(private componentRef: IgxGridComponent,
    private exportExcelService: IgxExcelExporterService,
    private exportCSVService: IgxCsvExporterService,
    private routeHandlerService: RouteHandlerService,
    @Inject('EnvironmentVariables') private environment: any,
    private userSettingsService: UserSettingsService,
    private viewRef: ViewContainerRef,
    private componentResolver: ComponentFactoryResolver,
    private layoutService: LayoutService,
    private _viewContainerRef: ViewContainerRef) {
  }

  ngOnInit() {
    // this.componentRef = (<any>this._view)._data.componentView.component as IgxGridComponent;
    this.gridId = this.componentRef.id;
    this.componentRef.data = [];

    this.componentRef.clipboardOptions = {
      enabled: true,
      copyHeaders: false,
      copyFormatters: true,
      separator: '\t'
    };

    this.calculateHeight();
    this.layoutService.menuExp.subscribe(m => {
      try {
        if (this.componentRef) {
          this.componentRef.reflow();
        }
      } catch (error) {
        // ignore error as grid is not rendered at that point
      }

    });

    if (this.enableDefault) {
      if (this.enablePaging === undefined) {
        this.enablePaging = true;
      }
      if (this.enableColumnHiding === undefined) {
        this.enableColumnHiding = true;
      }
      if (this.enableToolbar === undefined) {
        this.enableToolbar = true;
      }

      if (this.enableSorting === undefined) {
        this.enableSorting = true;
      }
      if (this.enableColumnMovable === undefined) {
        this.enableColumnMovable = true;
      }
      if (this.enableColumnResizable === undefined) {
        this.enableColumnResizable = true;
      }
      if (this.enablePersonalization === undefined) {
        this.enablePersonalization = true;
      }
      if (this.enableColumnPinning === undefined) {
        this.enableColumnPinning = true;
      }
    }

    this.componentRef.autoGenerate = false;

    // this.componentRef.hiddenColumnsText = "Hidden";
    this.componentRef.columnHidingTitle = 'Hidden Columns';
    if (this.enablePaging) {
      this.componentRef.paging = true;
      this.componentRef.perPage = this.environment.DefaultGridPage;
    } else {
      this.componentRef.perPage = Math.pow(2, 16);
    }

    if (this.enableToolbar) {
      this.componentRef.showToolbar = true;
    }

    // applying column level settings here
    const self = this;
    this.waitForColumnsToRender()
      .then(() => {
        if (!(self.componentRef as any)._destroyed) {
          self.applyColumnsSetting();
          self.applyPersonalization();
          self.bindColumnEvents();
          self.applyExportGridSetting()

          self.componentRef.reflow();
        }
      });
  }

  calculateHeight() {
    if (this.componentRef.height === '100%') {
      this.componentRef.width = '100%';

      let parent = this.componentRef.nativeElement.parentElement;

      if (this.routeHandlerService.previousTabComponentSelector) {
        while (parent != null) {
          if (parent != null && parent.nodeName.toLowerCase() === this.routeHandlerService.currentTabComponentSelector) {
            var nodeToRemove = parent.previousElementSibling;
            //reaching to required node
            while (nodeToRemove != null && nodeToRemove.nodeName.toLowerCase() !== this.routeHandlerService.previousTabComponentSelector) {
              nodeToRemove = nodeToRemove.previousElementSibling;
            }
            if (nodeToRemove !== null) {
              nodeToRemove.remove();
            }
            break;
          }
          //ignore extra nodes over grid
          parent = parent.parentElement;
        }
      }

      while (parent != null && parent.clientHeight === 0) {
        parent = parent.parentElement;
      }
      if (parent == null) {
        parent = this.componentRef.nativeElement.parentElement;
      }

      this.componentRef.height = parent.clientHeight + 'px';
    }
  }

  private waitForColumnsToRender(): Promise<void> {
    return new Promise((resolve, reject) => {
      let columnRendered = false;
      this.componentRef.onColumnInit.subscribe(n => {
        if (columnRendered === false) {
          columnRendered = true;

          setTimeout(() => {
            resolve();
          }, 100);
        }
      });
    });
  }

  private applyColumnsSetting() {
    if (this.enableColumnHiding) {
      this.componentRef.columnHiding = true;

      if (this.enablePersonalization) {
        setTimeout(() => {
          this.componentRef.toolbar.columnHidingUI.actionableColumns.forEach(n => {
            n.hiddenChange.subscribe(value => {
              if (!value) {
                const gridSettings = this.userSettingsService.getGridSettings(this.gridId);
                if (gridSettings) {
                  const columns = gridSettings.ColumnSettings.filter((col) => {
                    return (n.field === col.Key);
                  });
                  const column = columns ? columns[0] : null;
                  if (column) {
                    if (this.enableColumnResizable) {
                      n.width = column.Width;
                    }
                  }
                }
              }

              this.saveColumnVisibilitySetting(n, value);
            });
          });
        }, 50);

      }
    }

    if (this.enableColumnPinning) {
    }

    if (this.enableFiltering) {
      this.componentRef.allowFiltering = true;
      this.componentRef.filterMode = FilterMode.excelStyleFilter;
    }

    // Properties for each column
    let actionColumn: IgxColumnComponent;
    for (const column of this.componentRef.columns) {
      if (column.header) {
        if (this.enableColumnMovable) {
          column.movable = true;
        }

        if (this.enableColumnResizable) {
          column.resizable = true;
        }

        if (this.enableSorting) {

          column.sortable = true;
        }

        if (this.enableFiltering) {
          column.filterable = true;
        }

      } else {
        actionColumn = column;
      }
    }

    if (actionColumn) {
      this.bindRowActionColumn(actionColumn);
    }
  }

  private applyPersonalization() {
    if (this.enablePersonalization) {
      // getting grid settings
      const gridSettings = this.userSettingsService.getGridSettings(this.gridId);
      if (gridSettings) {
        if (this.enablePaging) {
          this.componentRef.paging = true;
          this.componentRef.perPage = gridSettings.PageSize;
        }

        if (gridSettings.OrderBy != null && gridSettings.OrderDirection != null) { // this.enableSorting
          var sortExp: ISortingExpression = { fieldName: gridSettings.OrderBy, dir: gridSettings.OrderDirection, ignoreCase: true};
          this.componentRef.sort(sortExp);
        }

        this.applyColumnPersonalization(gridSettings);
      }
    }
  }

  private applyExportGridSetting() {
    if (this.exportGridData) {
      this.componentRef.exportText = "Export";

      if (this.ExcelExport) {
        this.componentRef.exportExcel = true
        this.componentRef.exportExcelText = 'Grid Data to Excel'
      }
      if (this.CSVExport) {
        this.componentRef.exportCsv = true;
        this.componentRef.exportCsvText = 'Grid Data to CSV'
      }

    }
    if (this.CSVExport && this.componentRef.exportCsv) {
      this.componentRef.onToolbarExporting.subscribe(x => {
        // this.exportCSVService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
        //   if (args.field == undefined) {
        //     args.cancel = true;
        //   }
        // });
        this.getRemoteData.emit();
        x.cancel = true
       // this.exportCSVService.export(this.componentRef, new IgxCsvExporterOptions(this.componentRef.id, CsvFileTypes.CSV))
      })
    }
    if (this.ExcelExport && this.componentRef.exportExcel) {
      this.componentRef.onToolbarExporting.subscribe(x => {
        this.getRemoteData.emit();
        x.cancel = true
      })
    }


  }

  private applyColumnPersonalization(gridSettings: GridSettings) {
    let colIndex = 0;
    let actionColumn: IgxColumnComponent
    // apply grid column settings
    for (const columnSetting of gridSettings.ColumnSettings) {
      const columns = this.componentRef.columns.filter((col) => {
        return columnSetting.Key === col.field;
      });
      const column = columns ? columns[0] : null;
      if (column) {
        if (column.header && column.header != this.rowMenuColumnHeader) {
          if (this.enableColumnHiding) {
            column.hidden = columnSetting.Hidden;
          }

          if (this.enableColumnResizable) {
            column.width = columnSetting.Width;
          }

          if (this.enableColumnMovable) {


            this.componentRef.moveColumn(column, this.componentRef.columns[colIndex]);
          }

          if (this.enableColumnPinning) {
            column.pinned = columnSetting.Pinned
          }

        } else {
          actionColumn = column;
        }
        colIndex++;
      }
    }

    if (actionColumn) {
      this.bindRowActionColumn(actionColumn);
    }
  }

  private bindRowActionColumn(actionColumn: IgxColumnComponent) {
    if (this.actionItems != null && this.actionItems.length) {
      actionColumn.movable = false;
      actionColumn.resizable = false;
      actionColumn.sortable = false;
      actionColumn.disableHiding = true;
      actionColumn.hidden = false;
      actionColumn.filterable = false;

      if (this.rowActionType === GridRowActionType.IconOverlay) {
        actionColumn.pinned = true;
        actionColumn.width = '0';
        actionColumn.cellClasses = { 'actions-pinned': true };
        actionColumn.headerClasses = 'actions-pinned-header';
      } else if (this.rowActionType === GridRowActionType.Dropdown) {
        actionColumn.editable = false;
        actionColumn.pinned = false;
        actionColumn.width = '78';
        if (this.rowActionPinToLeft) {
          actionColumn.headerGroupClasses = 'actions-pinned-header-group-column';
          actionColumn.pinned = true;
          actionColumn.cellClasses = { 'column-center': true };
        }
        if (!!this.rowMenuColumnHeader) {
          actionColumn.header = this.rowMenuColumnHeader;
        }
      }

      const factory: ComponentFactory<RowActionMenuComponent> = this.componentResolver.resolveComponentFactory(RowActionMenuComponent);
      const rowMenu = this.viewRef.createComponent(factory);
      rowMenu.instance.rowItems = this.actionItems;
      rowMenu.instance.rowActionType = this.rowActionType;
      rowMenu.instance.gridRef = this.componentRef;
      rowMenu.instance.rowActionPinToLeft = this.rowActionPinToLeft;
      setTimeout(() => {
        actionColumn.bodyTemplate = rowMenu.instance.rowActionMenuTemplate;
      }, 50);
    }
  }

  private bindColumnEvents() {
    if (this.enablePersonalization) {
      // subscribing events
      if (this.enableColumnMovable) {
        this.componentRef.onColumnMovingEnd.subscribe(response => {
          this.saveColumnNewPositionSetting([response.source, response.target]);
        });


      }

      if (this.enableColumnResizable) {
        this.componentRef.onColumnResized.subscribe(response => {
          this.saveColumnNewWidth(response.column);
        });
      }

      // if (this.enableSorting) {
      this.componentRef.onSortingDone.subscribe(response => {
        this.saveColumnSorting(response.fieldName, response.dir);
        // if (this.isInitialSortCall) {
        //   this.isInitialSortCall = false;
        //   this.saveColumnSorting(response.fieldName, response.dir);
        // } else {
        //   this.saveColumnSorting(response.fieldName, response.dir);
        // }
        // This is to enable NoopSortingStrategy if set
        if(this.sortingStrategy == NoopSortingStrategy.instance())
        {
          response.strategy = this.sortingStrategy;
        }

        // This is to enable sorting on single column
        this.componentRef.clearSort();
        if (response.dir > 0) {
          this.componentRef.sortingExpressions.push(response);
        }
      });
      // }
    }
  }

  private saveColumnSorting(columnName: string, direction: number) {
    const gridSettings = new GridSettings();
    gridSettings.ID = this.componentRef.id;
    gridSettings.OrderBy = columnName;
    gridSettings.OrderDirection = direction;
    gridSettings.PageSize = this.componentRef.perPage;
    gridSettings.ColumnSettings = [];

    if (this.componentRef.columns != null && this.componentRef.columns.length > 0) {
      this.componentRef.columns.forEach((column: IgxColumnComponent) => {
        const columnSettings = new ColumnSetting();
          columnSettings.Key = column.field;
          columnSettings.Index = column.index;
          columnSettings.Hidden = column.hidden;
          columnSettings.Pinned = column.pinned
          columnSettings.Width = column.width;


        gridSettings.ColumnSettings.push(columnSettings);
      });
    }

    this.saveGridSettings(gridSettings);
  }

  private saveColumnPinningSetting(column: IgxColumnComponent, newValue: boolean) {
    const gridSettings = this.userSettingsService.getGridSettings(this.gridId);
    if (gridSettings == null) {
      this.saveGridSettings(null);
    }
    else {
      if (gridSettings.ColumnSettings == null || gridSettings.ColumnSettings.length < 1) {
        // save settings for all cols
        this.saveGridSettings(null);
      }
      else {
        const columnSettingIndex = this.getColumnSettingsIndexByKeyForColumnPin(gridSettings, column.field);
        if (columnSettingIndex !== -1) {
          gridSettings.ColumnSettings[columnSettingIndex].Pinned = newValue;
        }


        this.saveGridSettings(gridSettings);
      }
    }
  }

  private saveColumnNewWidth(column: IgxColumnComponent) {
    const gridSettings = this.userSettingsService.getGridSettings(this.gridId);
    if (gridSettings == null) {
      this.saveGridSettings(null);
    }
    else {
      if (gridSettings.ColumnSettings == null || gridSettings.ColumnSettings.length < 1) {
        // save settings for all cols
        this.saveGridSettings(null);
      }
      else {
        const columnSettingIndex = this.getColumnSettingsIndexByKey(gridSettings, column.field);
        if (columnSettingIndex !== -1) {
          gridSettings.ColumnSettings[columnSettingIndex].Width = column.width;
        }
        else {
          // settings for this col don't exist
          const columnSetting = new ColumnSetting();
          columnSetting.Hidden = column.hidden;
          columnSetting.Key = column.field;
          columnSetting.Index = column.index;
          columnSetting.Pinned = column.pinned
          columnSetting.Width = column.width;

          gridSettings.ColumnSettings.push(columnSetting);
        }

        this.saveGridSettings(gridSettings);
      }
    }
  }

  private saveColumnNewPositionSetting(columns: IgxColumnComponent[]) {
    // all the columns are re-arranged so saving state of all the columns
    this.saveGridSettings(null);
  }

  private saveColumnVisibilitySetting(column: IgxColumnComponent, newValue: boolean) {
    const gridSettings = this.userSettingsService.getGridSettings(this.gridId);
    if (gridSettings == null) {
      this.saveGridSettings(null);
    }
    else {
      if (gridSettings.ColumnSettings == null || gridSettings.ColumnSettings.length < 1) {
        // save settings for all cols
        this.saveGridSettings(null);
      }
      else {
        const columnSettingIndex = this.getColumnSettingsIndexByKey(gridSettings, column.field);
        if (columnSettingIndex !== -1) {
          gridSettings.ColumnSettings[columnSettingIndex].Hidden = newValue;

          this.saveGridSettings(gridSettings);
        }
        else {
          // settings for this col don't exist
          const columnSetting = new ColumnSetting();
          columnSetting.Hidden = newValue;
          columnSetting.Key = column.field;
          columnSetting.Index = column.index;
          columnSetting.Pinned = column.pinned
          columnSetting.Width = column.width;

          gridSettings.ColumnSettings.push(columnSetting);

          this.saveGridSettings(gridSettings);
        }
      }
    }
  }

  private getColumnSettingsIndexByKey(gridSettings: GridSettings, key: string): number {
    if (gridSettings != null) {
      for (let iterator = 0; iterator < gridSettings.ColumnSettings.length; iterator++) {
        if (gridSettings.ColumnSettings[iterator].Key === key)
          return iterator;
      }
    }

    return -1;
  }

  private getColumnSettingsIndexByKeyForColumnPin(gridSettings: GridSettings, key: string): number {
    if (gridSettings != null) {
      for (let iterator = 0; iterator < gridSettings.ColumnSettings.length; iterator++) {
        if (gridSettings.ColumnSettings[iterator].Key === key)
          return iterator;
      }
    }

    return -1;
  }

  private saveGridSettings(gridSettings: GridSettings) {
    if (gridSettings == null) {
      gridSettings = new GridSettings();
      gridSettings.ID = this.componentRef.id;
      gridSettings.PageSize = this.componentRef.perPage;
      gridSettings.ColumnSettings = [];
      if (this.componentRef.columns != null && this.componentRef.columns.length > 0) {
        this.componentRef.columns.forEach((column: IgxColumnComponent) => {
          const columnSettings = new ColumnSetting();
            columnSettings.Key = column.field;
            columnSettings.Index = column.index;
            columnSettings.Hidden = column.hidden;
            columnSettings.Pinned = column.pinned
            columnSettings.Width = column.width;

          gridSettings.ColumnSettings.push(columnSettings);
        });
      }
    }
    this.userSettingsService.saveGridSettings(gridSettings);
  }
}
