import { Component, OnInit, EventEmitter, Output, ElementRef, Input } from '@angular/core';
import { MenuService, MenuItem, PermissionManagerService } from 'ssi-framework';
import { Router } from '@angular/router';

@Component({
  selector: '.app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  host: {
    '(document:click)': 'handleClick($event)',
  }
})
export class MainMenuComponent implements OnInit {
  innerWidth: any;
  elementRef: ElementRef;
  showsection:boolean;

  @Output() onActive: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() onToggled: boolean;

  active: boolean = false;

  menuItems: MenuItem[];

  constructor(
    private menuService: MenuService,
    private permissionManagerService: PermissionManagerService,
    myElement: ElementRef,
    private router: Router, ) {
    this.elementRef = myElement;
    this.innerWidth = (window.screen.width);
  }

  ngOnInit() {
    var self = this;
    var response = self.menuService.GetMenu();
    if (response instanceof EventEmitter) {
      (response as EventEmitter<MenuItem[]>)
        .subscribe((resp: MenuItem[]) => {
          self.menuItems = resp;
        });
    }
    else {
      self.menuItems = response;
    }

    // setTimeout(()=>{
    //   if ( this.router.url=='/userAffilation')
    // {
    //   this.showsection= false; 
           
    // }
    // else{
    //   this.showsection= true;
    // }
    // }, 2000);
      
  }

  navbartoggler() {
    this.active = !this.active;
    this.onActive.emit(this.active)
  }

  closeMenu() {
    this.active = true;
    this.onActive.emit(this.active)
  }

  handleClick(event: any) {
    var clickedComponent = event.target;
    var inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (!inside) {
      this.active = true;
      this.onActive.emit(this.active);
    }
  }

  isActive(url: string): Boolean {
    return this.router.isActive(url, false);
  }

  isAnyActive(menuItem: MenuItem): Boolean {
    if (menuItem.Path == 'home') {
      return true;
    }

    let isActive: Boolean = false;
    if (menuItem.MenuItem != null) {
      menuItem.MenuItem.forEach((subMenu: MenuItem) => {
        if (subMenu.MenuItem) {
          subMenu.MenuItem.forEach((subSubMenu: MenuItem) => {
            if (subSubMenu && this.router.isActive(subSubMenu.Path, false)) {
              isActive = true;
            }
          });
        } else if (subMenu && this.router.isActive(subMenu.Path, false)) {
          isActive = true;
        }

      });
    } else {
      if (this.router.isActive(menuItem.Path, false)) {
        isActive = true;
      }
    }
    return isActive;
  }

  showDropdown(dropdown) {
    //dropdown.srcElement.lastElementChild.classList.add('show');
    var bounding = dropdown.srcElement.lastElementChild.getBoundingClientRect();
    if (bounding.top >= 0 && bounding.bottom <= window.innerHeight){
      dropdown.srcElement.lastElementChild.style.top='-64px';
    } 
    else {
      var top = (window.innerHeight - bounding.bottom) - 64;
      dropdown.srcElement.lastElementChild.style.top=top +'px';
    }
  }

  hideDropdown(dropdown) {
    //dropdown.srcElement.lastElementChild.classList.remove('show');
    dropdown.srcElement.lastElementChild.style.top='-64px';
  }

  checkPermissionForItem(Items): boolean {
    var flag = false;
    Items?.MenuItem?.forEach(item => {
      if (this.permissionManagerService.checkPermission(item?.PermissionCode)) {
        flag = true;
      }
    });
    return flag;
  }

  checkPermissionForMenu(menu): boolean {
    var flag = false;
    for (let i = 0; i < menu.length; i++) {
      const menuElement = menu[i];
      menuElement?.MenuItem?.forEach(element => {
        if (this.permissionManagerService.checkPermission(element?.PermissionCode)) {
          flag = true;
        }
      });
    }

    return flag;
  }

  checkPermission(PermissionCode): boolean {
    var flag = false;

    if (this.permissionManagerService.checkPermission(PermissionCode)) {
      flag = true;
    }

    return flag;
  }

}
