import { Component, ViewChild, Output, EventEmitter, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IDragMoveEventArgs, IgxDialogComponent } from 'igniteui-angular';

@Component({
  selector: 'lib-ssi-dialog',
  templateUrl: './ssi-dialog.component.html',
  styleUrls: ['./ssi-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SSIDialogComponent implements OnInit {

  @ViewChild('dialog', { static: true }) private dialog: IgxDialogComponent;

  @Input() header: string;
  @Input() width = 900;
  @Input() height = 500;
  @Input() showFooter = true;
  @Input() closeOnOutsideSelect = false;
  @Input() leftButtonLabel = 'Cancel';
  @Input() rightButtonLabel = 'Save';
  @Input() showCrossButton: boolean = false;
  @Input() isDraggable: boolean = false;

  @Output() openDialog = new EventEmitter<SSIDialogComponent>();
  @Output() closeDialog = new EventEmitter<SSIDialogComponent>();
  @Output() saveClicked = new EventEmitter<SSIDialogComponent>();
  @Output() cancelClicked = new EventEmitter<SSIDialogComponent>();
  @Output() crossClicked = new EventEmitter<SSIDialogComponent>();

  Show = false;

  constructor() { }

  ngOnInit(): void {
    if (!this.showFooter) {
      this.leftButtonLabel = null;
      this.rightButtonLabel = null;
    } else {
      this.dialog.onRightButtonSelect.subscribe(event => {
        this.saveClicked.emit(this);
      });
      this.dialog.onLeftButtonSelect.subscribe(event => {
        this.cancelClicked.emit(this);
      });
    }

    this.leftButtonLabel = this.cancelClicked.observers.length > 0 ? this.leftButtonLabel : null;
    this.rightButtonLabel = this.saveClicked.observers.length > 0 ? this.rightButtonLabel : null;
  }

  public show() {
    this.Show = true;
    this.dialog.open();

    setTimeout(() => {
      this.openDialog.emit(this);
    }, 50);
  }

  public hide() {
    this.Show = false;
    this.dialog.close();


    setTimeout(() => {
      this.closeDialog.emit(this);
    }, 50);
  }

  public onCrossClicked() {
    this.cancelClicked.observers.length > 0 ? this.dialog.onLeftButtonSelect.emit() : this.dialog.close();
    this.crossClicked.emit(this)
  }

  dragMove(e: IDragMoveEventArgs): void {
    if (this.isDraggable) {
      const deltaX = e.nextPageX - e.pageX;
      const deltaY = e.nextPageY - e.pageY;
      e.cancel = true;
      this.dialog.toggleRef.setOffset(deltaX, deltaY);
    }
  }

}
