import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { CachedUserSettingsService } from 'src/app/core/services/cached-user-settings-service';
import SocketService from '../../utils/socket-service/socket-service';
import {environment} from '../../../../../environments/environment';
import { Notification_Add,Notification_Update } from './constants/notificaiton_events';
import { UserProfilesService } from 'src/app/information/services/user-profiles.service';
import { LoggedInUserInformation } from 'src/app/Common/services/LoggedInUserInformation.service';


@Injectable()
export class NotificationListnerService extends SocketService{

  constructor(private userProfilesService:UserProfilesService,private loggedInService: LoggedInUserInformation) {
    super(environment.SignalrNotificationHubEndpoint ,false,false);
    this.showAllReceivedDataOnConsole();
    this.listenToEvent(Notification_Add);
    this.listenToEvent(Notification_Update);
  }

  public startConnection():any {
    return new Promise<void>((res,rej)=>{
      super.startConnection().then(()=>{
        this.joinOrganisation();
        res();
      }).catch((err)=>{
        rej(err);
      })
    })
  }

  private getUserProfileId(){
    console.log("service about to get user profile");
    let loginAffiliation = this.loggedInService.GetUserAffiliationFromSettings();
    let UserId = loginAffiliation[0].UserProfileID;
    return UserId;
  }

  closeConnection(){
    console.log("service leaving connection");
    this.leaveOrganisation();
    super.closeConnection();
  }

  joinOrganisation(){
    console.log("Got User Profile now sending StartListening");
    this.hubConnection.send("StartListeningToNotifications",this.getUserProfileId());
  }
  
  leaveOrganisation(){
    console.log("Got User Profile now sending StopListening");
    this.hubConnection.send("StopListeningToNotifications",this.getUserProfileId());
  }

  getNotificationAddObservable(){
    return this.getObservable().pipe(filter(obj =>(obj != null && obj.data != null && obj.data.length > 0 && obj.event_name === Notification_Add)));
  }
  getNotificationUpdateObservable(){
    return this.getObservable().pipe(filter(obj =>(obj != null  && obj.event_name === Notification_Update)));
  }
}
