import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, Injector } from '@angular/core';
import { IgxGridComponent, IgxGridCellComponent, SortingDirection, IgxDialogComponent } from 'igniteui-angular';
import { ContractHttpResponse,  UserSettingsService, GridRowActionItem, LayoutService, UtilityService, CommonMessageKeys,
   PermissionManagerService, BaseLVComponent, PagedList, SsiTranslatorPipe, IOrderColumn } from 'ssi-framework';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { UserManagementService } from '../../Services/usermanagement.service';
import { User } from '../../models/user.model';
import { Company } from '../../models/company.model';
import { Role } from '../../models/role.model';
import { RoleService } from '../../Services/role.service';
import { CompanyService } from '../../Services/company.service';


@Component({
  selector: 'ssi-identity-user-lv',
  templateUrl: './user-lv.component.html',
  styleUrls: ['./user-lv.component.css']
})
export class UserLVComponent extends BaseLVComponent<User> {

  @ViewChild('FWUserGrid', {static: false})
  public gridRef: IgxGridComponent;

  items: GridRowActionItem[] = [
    new GridRowActionItem(this, 'edit', 'Edit', 'EDIT_USER', 'editUser','edit'),
    new GridRowActionItem(this, 'delete', 'Delete', 'DEL_USER', 'deleteUser','delete'),
    new GridRowActionItem(this, 'activate', 'Activate', 'ACTIVATE_USER', 'activateUser','active'),
    new GridRowActionItem(this, 'deactivate', 'Deactivate', 'DEACTIVATE_USER', 'deactivateUser','inactive')
  ];
  private _ssiTranslatorPipe : SsiTranslatorPipe;

  companies: Company[] = [];
  roles: Role[] = [];

  constructor(private layoutService: LayoutService,private _injector: Injector,
    viewRef: ViewContainerRef, componentResolver: ComponentFactoryResolver, userSettingsService: UserSettingsService,
     router: Router, route: ActivatedRoute,private userManagementService: UserManagementService, private roleService: RoleService,
      private companyService: CompanyService,utilityService: UtilityService, commonMessageKeys: CommonMessageKeys,
       public permissionMangerService: PermissionManagerService) {
    super(viewRef, componentResolver, userSettingsService, utilityService, commonMessageKeys, router, route);
    this._ssiTranslatorPipe = new SsiTranslatorPipe(_injector);
  }

  public setTitle(){
    const title = this._ssiTranslatorPipe.transform('MANAGE_USERS_TITLE');
    this.layoutService.setTitle(title);
  }

  protected onBeforeLoad(): Promise<any> {

    const loaded = new Promise<any>((resolve, reject) => {
      const companyLoaded = this.loadCompanies();
      companyLoaded.then(n => {
        const roleLoaded = this.loadRoles();
        roleLoaded.then(m => {
          resolve(true);
        });
      });
    });
    return loaded;
  }

  protected getData(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any, orderColumns?: IOrderColumn[]): Promise<any> {
    return this.userManagementService.getPaged(pageIndex, pageSize, orderBy, orderDir, filter);
  }

  protected onAfterLoad(data: PagedList<User>) {
  }

  editUser(cell: IgxGridCellComponent) {
    this.router.navigate(['Users/Edit', cell.cellID.rowID.ID], { skipLocationChange: true } as NavigationExtras);
  }

  deleteUser(cell: IgxGridCellComponent) {
    const deleteMsg = this.utilityService.displayConfirmationMessage('Are you sure you want to delete the selected record(s)?');
    if (deleteMsg) {
      deleteMsg.onConfirm.subscribe((dialog: IgxDialogComponent) => {
        const callback= this.userManagementService.delete(cell.cellID.rowID.ID)
        callback.then((response: any) => {
          if (response.Success === true) {
            this.utilityService.displaySuccessMessage(this.commonMessageKeys.RECORD_DELETED_SUCCESSFULLY);
            this.onApplyFilter();
          }
          else {
            this.utilityService.displayErrorMessage(response.Message);
          }
        });
      });
    }
  }

  activateUser(cell: IgxGridCellComponent) {
    this.userManagementService.activateUser(cell.cellID.rowID.ID)
      .then((response: any) => {
        if (response.Success === true) {
          this.userManagementService.onUserUpdated.emit(cell.cellID.rowID.ID);
          this.utilityService.displaySuccessMessage(this.commonMessageKeys.RECORD_UPDATED_SUCCESSFULLY);

          this.onApplyFilter();
        }
        else {
          this.utilityService.displayErrorMessage(response.Message);
        }
      });
  }

  deactivateUser(cell: IgxGridCellComponent) {
    this.userManagementService.deactivateUser(cell.cellID.rowID.ID)
      .then((response: any) => {
        if (response.Success === true) {
          this.userManagementService.onUserUpdated.emit(cell.cellID.rowID.ID);
          this.utilityService.displaySuccessMessage(this.commonMessageKeys.RECORD_UPDATED_SUCCESSFULLY);

          this.onApplyFilter();
        }
        else {
          this.utilityService.displayErrorMessage(response.Message);
        }
      });
  }

  private loadCompanies() {
    return new Promise<any>((resolve, reject) => {
      this.companyService.getAll()
        .then((response: ContractHttpResponse<Company[]>) => {
          if (response.Success === true) {
            this.companies = response.Source;
            resolve(true);
          }
          else {
            resolve(true);
          }
        });
    });
  }

  private loadRoles() {
    return new Promise<any>((resolve, reject) => {
      this.roleService.getAll()
        .then((response: ContractHttpResponse<Role[]>) => {
          if (response.Success === true) {
            this.roles = response.Source;
            resolve(true);
          }
          else {
            resolve(true);
          }
        });
    });
  }
}
