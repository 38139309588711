<div class="main-content-holder row">
  <div class="col p0">
    <div class="ssi-formGroup">
      <div class=" btn-holder">
        <ul class="btnlist">
          <li><button tabindex="5" igxButton="flat" type="submit" class="btn-primary" (click)="onSave(true,'/Information/Roles')"
              *ngIf="permissionMangerService.checkPermission('ADD_ROLE')"
              ssiTooltip="Save">
              <span class="icon icon-save"></span>Save</button></li>
          <li><button tabindex="4" igxButton="flat" type="submit" class="btn-primary"
              (click)="onSave(true,'/Roles/Add')" *ngIf="permissionMangerService.checkPermission('ADD_ROLE')"
              ssiTooltip="Save & New">
              <span class="icon icon-save"></span>Save & New</button></li>
          <li><button tabindex="{{ permissionMangerService.checkPermission('ADD_ROLE') ? 6 : 5}}" igxButton="flat"
              type="submit" class="igx-button--flat" (click)="onCancel('/Information/Roles')"
              ssiTooltip="Cancel">
              <span class="icon icon-times-circle"></span>Cancel</button></li>
        </ul>
      </div>
      <div class="ssi-FourColForm">
        <div class="row">
          <div class="col control-holder">
            <igx-input-group>
              <label igxLabel for="code">Role Code</label>
              <input igxFocus="true" tabindex="1" igxInput ngModel [(ngModel)]="model.Code" name="Code" type="text"  autocomplete="none"
                [required]="true" />
            </igx-input-group>
          </div>
          <div class="col control-holder">
            <igx-input-group>
              <label igxLabel for="name">Role Name</label>
              <input igxInput tabindex="2" ngModel [(ngModel)]="model.Name" name="Name" type="text" [required]="true"
                autocomplete="none" />
            </igx-input-group>
          </div>
          <div class="col control-holder">
            <lib-ssi-select label="Tenant" [items]="companies" tabindex="3" itemKey="ID"
              itemText="Name" (ItemSelected)='getAppPermissionByAppCodeAndCompany($event)' [(singleModel)]="model.CompanyID" [singleSelect]="true" [required]="true" index="3">
            </lib-ssi-select>
          </div>
          <div class="col control-holder">
            <igx-input-group class="input-group__textarea">
              <label igxLabel for="description">Description</label>
              <textarea tabindex="4" #description igxInput ngModel [(ngModel)]="model.Description" name="description"
                type="text"></textarea>
            </igx-input-group>
          </div>
        </div>
      </div>
    </div>

    <div class="grid-wrap">
      <igx-tree-grid #treeGrid height="100%" [data]="userTypePermissions" childDataKey="Modules"
        [autoGenerate]="false" expansionDepth="0">
        <igx-column header="Module" width="20%" field="Selected" dataType="string"
          [cellTemplate]="cellSelectionTemplate">
        </igx-column>
        <igx-column width="80%" header="Permissions" [cellTemplate]="cellTemplate" field="Permissions"></igx-column>
      </igx-tree-grid>

      <ng-template #cellSelectionTemplate igxCell let-user="cell">
        <igx-checkbox [(ngModel)]="user.row.rowData.Selected" [indeterminate]="user.row.rowData.Indetermine"
          (change)="onModuleSelectionChange($event, user.row.rowData)">
          {{ user.row.rowData.Name }}
        </igx-checkbox>
      </ng-template>

      <ng-template #cellTemplate igxCell let-value>
        <div class="row w-100">
          <igx-checkbox class="col-lg-2 py-2" *ngFor="let permission of value" [(ngModel)]="permission.Selected"
            (change)="onPermissionSelectionChange($event, permission)">
            {{ permission.Name }}
          </igx-checkbox>
        </div>
      </ng-template>
    </div>
  </div>
</div>