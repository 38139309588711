import { Component, ElementRef, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AuthenticateClientService, ContractHttpResponse, UserContextService, UserSettings, UserSettingsService, UtilityService } from 'ssi-framework';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { CommonConstants, ConfigKeys, LocalStorageKeys } from 'src/app/Common/constants/constants';
import { CachedUserSettingsService } from 'src/app/core/services/cached-user-settings-service';
import { MasterUserProfileService } from 'src/app/information/services/master-user-profile.service';
import { AuthenticationService } from 'src/app/information/services/authentication.service';
import { UserProfileDetail } from 'src/app/information/models/user-profile-detail.model';
import { EventEmitter } from '@angular/core'
import { GlobalSettingService } from 'src/app/information/services/global-settings.services';
import { parseBool } from 'igniteui-angular-core';
import { OAuthService } from 'angular-oauth2-oidc';
import asyncLocalStorage from 'src/app/Common/utils/AsyncLocalStorage';
import { RenaissantAuthenticateClientService } from 'src/app/Common/services/renaissant-authenticate-client.service';
import { LoggedInUserInformation } from 'src/app/Common/services/LoggedInUserInformation.service';
import { InformationMessagingKeys } from 'src/app/information/common/information-messaging-keys.service';
import { UserService } from 'src/app/core/services/user-service';
export const PROGRESS_STATUS = { PROGRESS_START: "PROGRESS_START", PROGRESS_END: "PROGRESS_END" };
@Component({
  selector: 'app-deactivate-user',
  templateUrl: './deactivate-user.component.html',
  styleUrls: ['./deactivate-user.component.scss']
})
export class DeactivateUserComponent implements OnInit {
  @Output()
  ProgressStatusEmitter = new EventEmitter();
  ClientName: string = environment.AppTitle;
  Login: string;
  Password: string;
  Error: string = '';
  type: string = 'password';
  loginAffiliation: any = null;
  onlyChromeSupport =
    'It looks like you are using a web browser other than Google Chrome to access Marketplace.  Please use Google Chrome for the best experience with this application.';
  showOtpDialog: boolean = false;
  otpCode: string = null;
  enableResendCode: boolean = true;
  resendCodeTime: number = 0;
  userId = 0;
  disableButton = false;
  showLoading = false;
  constructor(
    private router: Router,
    private authenticateClientService: AuthenticateClientService,
    private Meta: Meta,
    private UserSettingsService: UserSettingsService,
    private cachedUserSettingsService: CachedUserSettingsService,
    private masterUserProfileService: MasterUserProfileService,
    private authenticationService: AuthenticationService,
    private globalsettingsService: GlobalSettingService,
    private userContextService: UserContextService,
    private oauthService: OAuthService,
    private renaissantAuthenticateClientService: RenaissantAuthenticateClientService,
    private _loggedInUserInformation: LoggedInUserInformation,
    private utilityService: UtilityService,
    private _userService: UserService,
  ) {
    this.authenticateClientService.getErrorMessage().subscribe((error) => {
      this.Error = error;
    });
  }
  ngOnInit() {
    this.Meta.updateTag({ name: 'viewport', content: 'width=device-width' });
  }
  //Reset content width on page redirect
  ngOnDestroy() {
    this.Meta.updateTag({ name: 'viewport', content: 'width=1366, initial-scale=0.5, maximum-scale=0.5' });
  }
  togglePassword() {
    if (this.type == 'text') {
      this.type = 'password';
    } else {
      this.type = 'text';
    }
  }
  Signout() {
    this.disableButton=false;
    this.authenticateClientService.signOut();
    window.location.href = 'deleteuser';
  }
  signInUser(name, password) {
    this.Error = null;
    this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_START);
    this.showLoading=true;
    this.renaissantAuthenticateClientService.login(name, password).then(async (response) => {
      const isvalid = await this.GetUserAffiliationFromSettings();
      if (isvalid) {
        if (this.userId == null || this.userId == 0 || Number.isNaN(this.userId) || this.userId == undefined) {
          this.utilityService.displayErrorMessage("User Id is null");
          setTimeout(() => {
            this.Signout();
          }, 1500);
        } else {
            await this.deActivateUser(this.userId);
          //this.activateUser(id);
        }
      }
    }).finally(() => {
      this.showLoading=false;
      this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
    });
  }
  async deActivateUser(id): Promise<boolean> {
    const confirmationMessage = this.utilityService
      .displayConfirmationMessage('Are you sure you want to delete the selected user account?');
    if (confirmationMessage) {
      confirmationMessage.onConfirm.subscribe(() => {
        this._userService
          .deactivateUser(id)
          .then((response: ContractHttpResponse<void>) => {
            if (response.Success === true) {
              this.utilityService.displaySuccessMessage('User deleted successfully');
              setTimeout(() => {
                this.Signout();
              }, 1500);
            } else {
              this.utilityService.displayErrorMessage(response.Message);
              setTimeout(() => {
                this.Signout();
              }, 1500);
            }
          });
      });
      confirmationMessage.onCancel.subscribe(() => {
        this.Signout();
      })
    }
    return true;
  }

	// activateUser(id): void {
	// 	this.utilityService
	// 		.displayConfirmationMessage(InformationMessagingKeys.USER_ACTIVATE_CONFIRM)
	// 		.onConfirm.subscribe(() => {
	// 			this._userService
	// 				.activateUser(id)
	// 				.then((response: ContractHttpResponse<void>) => {
	// 					if (response.Success === true) {
	// 						//this.identityUser.IsActive = true;
	// 						this.utilityService.displaySuccessMessage(InformationMessagingKeys.USER_ACTIVATE_SUCCESS);
	// 					} else {
	// 						this.utilityService.displayErrorMessage(response.Message);
	// 					}
	// 				});
	// 		});
	// }

  is2faEnabledByOrganization() {
    return new Promise<boolean>(async (res, rej) => {
      await this.GetUserAffiliationFromSettings();
      let isOrganizationSelected = this.loginAffiliation != null && this.loginAffiliation.length > 0 ? true : false;
      if (!isOrganizationSelected)
        return res(false);
      let organizationID = this.loginAffiliation[0].OrganizationID;
      this.globalsettingsService.getConfigurationsByOrganizationIdAnonymous(organizationID, ConfigKeys.RequireOtpForLogin).then((response) => {
        const config = response.Source.filter(
          (x) => x.ConfigKey == ConfigKeys.RequireOtpForLogin
        );
        let isConfigAvailable = config != null && config.length > 0 ? true : false;
        if (!isConfigAvailable)
          return res(false);
        let isEnabled = parseBool(config[0].ConfigValue);
        res(isEnabled);
      })
    });
  }
  is2faEnabledByUserProfile() {
    return new Promise<boolean>((res, rej) => {
      let u: UserProfileDetail = new UserProfileDetail();
      u.Name = this.Login;
      this.masterUserProfileService.is2faEnabled(u).then((resp) => {
        let is2FaEnabledByUser = resp.Source;
        res(is2FaEnabledByUser);
      }).catch((e) => {
        rej(e);
      });
    });
  }
  verifyOTP(name: string, password: string, otp: string) {
    this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_START);
    this.showLoading=true;
    this.Error = null;
    this.authenticationService.validateOtpForSignIn(name, password, otp).then((res) => {
      let status = res.Source.status;
      if (status === "success") {
        asyncLocalStorage.setItem(LocalStorageKeys.TWO_FA_VERIFIED, "true").then(() => {
          this.signInUser(this.Login, this.Password);
        })
      } else {
        this.Error = "Could Not Verify OTP. Please Try Again";
        this.showLoading=false;
        this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
      }
    });
  }
  ResendCode() {
    this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_START);
    this.showLoading=true;
    this.authenticationService.generateOTPForSignIn(this.Login, this.Password).finally(() => {
      this.showLoading=false;
      this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
    });
  }
  protected async GetUserAffiliationFromSettings(): Promise<boolean> {
    const settingCode = CommonConstants.CURRENT_LOGIN_AFFILIATION;
    const isloadSettingValid = await this.UserSettingsService.loadUserSettings()
    if (isloadSettingValid) {
      const UsersAffiliationsetting = this.UserSettingsService.getUserSettingsByCode(settingCode);
      if (UsersAffiliationsetting != null) {
        this.userId = UsersAffiliationsetting.UserId;
        this.loginAffiliation = JSON.parse(UsersAffiliationsetting.Value);
        const roles = this.cachedUserSettingsService.GetCommaSepRolesByParam(this.loginAffiliation)
        await this.cachedUserSettingsService.setPermissions(roles);
        return true;
      } else {
        this.loginAffiliation = null;
        return true;
      }
    }
    else {
      this.loginAffiliation = null;
      return true;
    }
  }
  handleSignInButtonClick() {
    this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_START);
    this.showLoading=true;
    if ((this.Login == '' || this.Login == null) || (this.Password == '' || this.Password == null)) {
      this.Error = "Please Enter All Details To Continue";
      this.showLoading=false;
      this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
      return;
    }
    this.renaissantAuthenticateClientService.login(this.Login, this.Password).then((t) => {
      Promise.all([this.is2faEnabledByUserProfile(), this.is2faEnabledByOrganization()]).then((resp) => {
        this.userId = Number(t.sub);
        this.disableButton=true;
        let enabledByUser = resp[0];
        let enabledByOrganization = resp[1];
        if (enabledByUser === true || enabledByOrganization === true) {
          this.Error = null;
          asyncLocalStorage.setItem(LocalStorageKeys.TWO_FA_VERIFIED, "false").then(() => {
            this.showOtpDialog = true; this.authenticationService.generateOTPForSignIn(this.Login, this.Password);
          })
        }
        else {
          asyncLocalStorage.setItem(LocalStorageKeys.TWO_FA_VERIFIED, "true");
          this.signInUser(this.Login, this.Password);
        }
      this.showLoading=false;
        this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
      });
    }).catch(() => {
      this.showLoading=false;
      this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
    });
  }
  handleOtpCodeCompleted(code: string) {
    this.otpCode = code;
  }
  handleOtpCancelButtonClick() {
    this.Error = null;
    this.showOtpDialog = false;
  }
  handleOtpVerifyButtonClick() {
    if (this.otpCode == null || this.otpCode.length < 6) {
      this.Error = "Please enter OTP Code";
      return;
    }
    this.verifyOTP(this.Login, this.Password, this.otpCode);
  }
  handleResendButtonClick() {
    if (!this.enableResendCode) return;
    this.ResendCode();
    this.enableResendCode = false;
    this.resendCodeTime = 60;
    let intervalID = setInterval(() => {
      if (this.resendCodeTime > 0)
        this.resendCodeTime--;
      if (this.resendCodeTime == 0) {
        this.enableResendCode = true;
        clearInterval(intervalID);
      }
    }, 1000)
  }
  handleTogglePasswordButtonClick() {
    this.togglePassword();
  }
}