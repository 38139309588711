import { Directive, Input, ViewContainerRef, TemplateRef, OnInit } from '@angular/core';
import { PermissionManagerService } from '../../Authentication/permission-manager.service';

@Directive({
    selector: '[ssiPermission]'
})
export class SsiPermissionDirective implements OnInit {

    private permission: string;
    private elseTemplateRef: TemplateRef<any>;

    @Input() set ssiPermissionElse(content: TemplateRef<any>) {
        this.elseTemplateRef = content;
    }

    @Input() set ssiPermission(permission: string) {
        this.permission = permission;
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private permissionManagerService: PermissionManagerService) {
    }

    ngOnInit(): void {
        if (!this.permissionManagerService.checkPermission(this.permission)) {
            if (this.elseTemplateRef) {
                this.viewContainer.createEmbeddedView(this.elseTemplateRef);
            } else {
                this.viewContainer.clear();
            }
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

}
