<div class="cal-week-view">
  <div class="cal-days">
    <div class="row no-gutters cal-cell-row">
      <div class="col cal-cell" *ngFor="let date of activeDates">
        <lib-cal-day-tile *ngIf="weekTemplate; else default " [template]="weekTemplate" [date]="date.toDate()"
          [data]="getData(date.toDate())" [displayTileExtensionButton]="displayTileExtensionButton" >
        </lib-cal-day-tile>
        <ng-template #default>
          <lib-cal-day-tile [template]="defaultDay" [data]="getData(date.toDate())" [date]="date.toDate()" [displayTileExtensionButton]="displayTileExtensionButton">
          </lib-cal-day-tile>
        </ng-template>
      </div>
    </div>
  </div>
</div>


<ng-template #defaultDay let-date='date'>
  <div class="tile">{{date.getDate()}}</div>d
</ng-template>
