
import { Injector, NgModule } from '@angular/core';
import {
  IgxButtonModule, IgxGridModule, IgxInputGroupModule, IgxRippleModule, IgxExcelExporterService,
  IgxAutocompleteModule, IgxDropDownModule, IgxCardModule, IgxCalendarModule, IgxChipsModule, IgxToggleModule,
  IgxTreeGridModule, IgxTabsModule, IgxTooltipModule, IgxTimePickerModule, IgxMaskModule, IgxButtonGroupModule, IgxComboModule, IgxCsvExporterService, IgxDragDropModule
} from 'igniteui-angular';
import { CommonModule } from '@angular/common';
import { SsiGridDirective } from './WebControls/CustomAttributes/ssi-grid.directive';
import { SSIDialogComponent } from './WebControls/Controls/ssi-dialog/ssi-dialog.component';
import { FormsModule } from '@angular/forms';
import { IgxIconModule, IgxDialogModule, IgxListModule } from 'igniteui-angular';
import { BasicGridPagerComponent } from './WebControls/Controls/grid/basic-grid-pager/basic-grid-pager.component';
import { SsiFilterComponent } from './WebControls/Controls/ssi-filter/ssi-filter.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SSIHttpInterceptor } from './WebApiClient/Interceptors/ssi-http-interceptor';
import { RowActionMenuComponent } from './WebControls/Controls/grid/row-action-menu/row-action-menu.component';
import { SsiToastComponent } from './WebControls/Controls/ssi-toast/ssi-toast.component';
import { SsiConfirmationComponent } from './WebControls/Controls/ssi-confirmation/ssi-confirmation.component';
import { SsiDropdownComponent } from './WebControls/Controls/ssi-dropdown/ssi-dropdown.component';
import { SsiLoaderComponent } from './WebControls/Controls/ssi-loader/ssi-loader.component';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { SSIReuseStrategy } from './Infrastructure/ssi-reuse-strategy';
import { SsiMessageBoxComponent } from './WebControls/Controls/ssi-message-box/ssi-message-box.component';
import { SsiNumberDirective } from './WebControls/CustomAttributes/ssi-number.directive';
import { SsiTooltipDirective } from './WebControls/CustomAttributes/ssi-tooltip.directive';
import { SsiFilterPipe } from './WebControls/CustomPipes/ssi-filter-pipe.filter';
import { SSICalendarComponent } from './WebControls/Controls/ssi-calendar/ssi-calendar.component';
import { SsiPermissionDirective } from './WebControls/CustomAttributes/ssi-permission.directive';
import { MonthViewComponent } from './WebControls/Controls/ssi-calendar/month-view/month-view.component';
import { WeekViewComponent } from './WebControls/Controls/ssi-calendar/week-view/week-view.component';
import { DayViewComponent } from './WebControls/Controls/ssi-calendar/day-view/day-view.component';
import { DatePickerComponent } from './WebControls/Controls/ssi-calendar/date-picker/date-picker.component';
import { CalendarTileComponent } from './WebControls/Controls/ssi-calendar/calendar-tile/calendar-tile.component';
import { SSIContextMenuDirective } from './WebControls/CustomAttributes/ssi-contextmenu.directive';
import { SSIContextMenuComponent } from './WebControls/Controls/ssi-context-menu/ssi-context-menu.component';
import { SsiHierarchicalDropdownComponent } from './WebControls/Controls/ssi-hierarchical-dropdown/ssi-hierarchical-dropdown.component';
import { SsiTabComponent } from './WebControls/Controls/ssi-tab/ssi-tab.component';
import { SsiDatePipe } from './WebControls/CustomPipes/ssi-date.pipe';
import { SsiCursorPositionDirective } from './WebControls/CustomAttributes/ssi-cursor-position.directive';
import { SsiImageLoaderDirective } from './WebControls/CustomAttributes/ssi-image-loader.directive';
import { SsiTranslatorPipe } from './WebControls/CustomPipes/ssi-translator.pipe';
import { SsiTimePickerComponent } from './WebControls/Controls/ssi-time-picker/ssi-time-picker.component';
import { RecentSearchOverlayComponent } from './WebControls/Controls/ssi-filter/recent-search-overlay/recent-search-overlay.component';
import { SsiButtonGroupComponent } from './WebControls/Controls/ssi-buttongroup/ssi-buttongroup.component';
import { SsiOverlayComponent } from './WebInfrastructure/Components/ssi-overlay/ssi-overlay.component';
import { SsiOutsideClickDirective } from './WebControls/CustomAttributes/ssi-out-side-click.directive';
import { SearchShortcutsOverlayComponent } from './WebControls/Controls/ssi-filter/search-shortcuts-overlay/search-shortcuts-overlay.component';
import { SsiDropdownRemoteComponent } from './WebControls/Controls/ssi-dropdown-remote/ssi-dropdown-remote.component';
import { SsiSelectComponent } from './WebControls/Controls/ssi-select/ssi-select.component';
import { SsiTreeGridComponent } from './WebControls/Controls/ssi-tree-grid/ssi-tree-grid.component';
import { GridExporterComponent } from './WebControls/Controls/grid/grid-exporter/grid-exporter.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SsiDocViewerComponent } from './WebControls/Controls/ssi-doc-viewer/ssi-doc-viewer.component';
import { SsiImageViewerComponent } from './WebControls/Controls/ssi-image-viewer/ssi-image-viewer.component';
import { SsiTextEditorComponent } from './WebControls/Controls/ssi-text-editor/ssi-text-editor.component';
import { SsiInputComponent } from './WebControls/Controls/ssi-input/ssi-input.component';
import { SsiTextAreaComponent } from './WebControls/Controls/ssi-text-area/ssi-text-area.component';
import { SsiNumberComponent } from './WebControls/Controls/ssi-number/ssi-number.component';
import { SsiDatepickerComponent } from './WebControls/Controls/ssi-datepicker/ssi-datepicker.component';

export let AppInjector: Injector;

@NgModule({
  imports: [
    FormsModule,
    RouterModule,
    IgxIconModule,
    IgxDialogModule,
    IgxListModule,
    CommonModule,
    IgxButtonModule,
    IgxGridModule,
    IgxInputGroupModule,
    IgxCardModule,
    IgxDropDownModule,
    IgxComboModule,
    IgxCalendarModule,
    IgxAutocompleteModule,
    IgxRippleModule,
    IgxChipsModule,
    IgxToggleModule,
    IgxTreeGridModule,
    IgxTabsModule,
    IgxTooltipModule,
    IgxTimePickerModule,
    IgxMaskModule,
    IgxButtonGroupModule,
    AngularEditorModule,
    NgxDocViewerModule,
    IgxDragDropModule
  ],
  declarations: [
    SSIDialogComponent,
    SsiGridDirective,
    SsiFilterPipe,
    BasicGridPagerComponent,
    SsiFilterComponent,
    RowActionMenuComponent,
    SsiToastComponent,
    SsiConfirmationComponent,
    SsiDropdownComponent,
    SsiSelectComponent,
    SsiLoaderComponent,
    SsiMessageBoxComponent,
    SsiNumberDirective,
    SsiTooltipDirective,
    SSICalendarComponent,
    CalendarTileComponent,
    SsiPermissionDirective,
    MonthViewComponent,
    WeekViewComponent,
    DayViewComponent,
    DatePickerComponent,
    SSIContextMenuComponent,
    SSIContextMenuDirective,
    SsiHierarchicalDropdownComponent,
    SsiTabComponent,
    SsiDatePipe,
    SsiCursorPositionDirective,
    SsiImageLoaderDirective,
    SsiTranslatorPipe,
    SsiTimePickerComponent,
    RecentSearchOverlayComponent,
    SsiButtonGroupComponent,
    SsiOverlayComponent,
    SsiOutsideClickDirective,
    SearchShortcutsOverlayComponent,
    SsiDropdownRemoteComponent,
    SsiSelectComponent,
    SsiTreeGridComponent,
    GridExporterComponent,
    SsiDocViewerComponent,
    SsiImageViewerComponent,
    SsiTextEditorComponent,
    SsiInputComponent,
    SsiTextAreaComponent,
    SsiNumberComponent,
    SsiTextEditorComponent,
    SsiDatepickerComponent
  ],
  exports: [
    SSIDialogComponent,
    SsiPermissionDirective,
    SsiGridDirective,
    BasicGridPagerComponent,
    SsiFilterComponent,
    SsiDropdownComponent,
    SsiSelectComponent,
    SsiNumberDirective,
    SsiTooltipDirective,
    SSICalendarComponent,
    SSIContextMenuComponent,
    SSIContextMenuDirective,
    SsiHierarchicalDropdownComponent,
    SsiTabComponent,
    SsiDatePipe,
    SsiCursorPositionDirective,
    SsiImageLoaderDirective,
    SsiOutsideClickDirective,
    SsiTranslatorPipe,
    SsiTimePickerComponent,
    SsiButtonGroupComponent,
    RowActionMenuComponent,
    SsiOverlayComponent,
    SsiDropdownRemoteComponent,
    SsiLoaderComponent,
    SsiTreeGridComponent,
    GridExporterComponent,
    SsiMessageBoxComponent,
    SsiDocViewerComponent,
    SsiImageViewerComponent,
    SsiTextEditorComponent,
    SsiInputComponent,
    SsiTextAreaComponent,
    SsiNumberComponent,
    SsiTextEditorComponent,
    SsiDatepickerComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SSIHttpInterceptor,
      multi: true
    },
    IgxExcelExporterService, IgxCsvExporterService,
    { provide: RouteReuseStrategy, useClass: SSIReuseStrategy },
  ],
  entryComponents: [BasicGridPagerComponent, RowActionMenuComponent, SsiToastComponent, SsiConfirmationComponent,
    SsiDocViewerComponent, SsiImageViewerComponent, SsiTextEditorComponent,
    SsiMessageBoxComponent, SsiLoaderComponent, SSIContextMenuComponent, RecentSearchOverlayComponent, GridExporterComponent]
})
export class SsiFrameworkModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
