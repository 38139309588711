import { Component, ViewContainerRef, Injector, ViewChild } from '@angular/core';
import {
  ContractHttpResponse, RouteHandlerService, LayoutService, UtilityService, CommonMessageKeys, PermissionManagerService,
  UserContextService, BaseAEComponent, SsiTranslatorPipe
} from 'ssi-framework';
import { Role } from '../../models/role.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleService } from '../../Services/role.service';
import { CompanyService } from '../../Services/company.service';
import { Company } from '../../models/company.model';
import { ApplicationPermission } from '../../models/applicationPermission.model';
import { PermissionService } from '../../Services/permission.service';
import { Permission } from '../../models/permission.model';
import * as _ from 'lodash';
import { IChangeCheckboxEventArgs, IgxTreeGridComponent } from '@infragistics/igniteui-angular';

@Component({
  selector: 'ssi-identity-role-ae',
  templateUrl: './role-ae.component.html',
  styleUrls: ['./role-ae.component.css']
})
export class RoleAEComponent extends BaseAEComponent<Role> {

  @ViewChild('treeGrid', { static: true }) public gridRef: IgxTreeGridComponent;
  model: Role = new Role();
  companies: Company[] = [];
  private _ssiTranslatorPipe: SsiTranslatorPipe;
  userTypePermissions: ApplicationPermission[] = [];
  oldSource: unknown;
  RolesOldSource: any;
  uniqueKeys: any;

  constructor(route: ActivatedRoute,
    router: Router,
    routeHandlerService: RouteHandlerService,
    private roleService: RoleService,
    private companyService: CompanyService,
    private userContext: UserContextService,
    viewRef: ViewContainerRef,
    private _injector: Injector,
    private layoutService: LayoutService,
    utilityService: UtilityService,
    public permissionMangerService: PermissionManagerService,
    commonMessageKeys: CommonMessageKeys,
    private permissionService: PermissionService,
  ) {
    super(route, router, routeHandlerService, viewRef, utilityService, commonMessageKeys);
    this._ssiTranslatorPipe = new SsiTranslatorPipe(_injector);
    const title = this._ssiTranslatorPipe.transform('Add Role');
    this.layoutService.setTitle(title);
  }

  get(id: number): Promise<any> {
    return this.roleService.get(id);
  }

  onBeforeAddEdit() {
    this.setGridHeight();
    this.layoutService.menuExp.subscribe(m => {
      try {
        if (this.gridRef) {
          this.gridRef.reflow();
        }
      } catch (error) {
      }
    });
    return this.loadCompanies();
  }

  onAfterAddEdit(obj: Role) {
    this.layoutService.setTitle('Edit - ' + obj.Name);
    this.getAppPermissionByAppCodeAndCompany(null).then((res: ApplicationPermission[]) => {
      this.setSource(res).then((res) => {
        this.oldSource = res;
        let oldObj = {
          "Name": !!obj.Name ? obj.Name : "",
          "User Type": !!obj.CompanyName ? obj.CompanyName : "",
        }

        this.RolesOldSource = JSON.parse(JSON.stringify(oldObj));
      });

    });
  }

  private setGridHeight() {
    let parent = this.gridRef.nativeElement.parentElement;
    while (parent != null && parent.clientHeight === 0) {
      parent = parent.parentElement;
    }
    if (parent == null) {
      parent = this.gridRef.nativeElement.parentElement;
    }
    const height = parent.clientHeight;
    this.gridRef.height = height + 'px';
  }

  validate(obj: Role): boolean {
    if (!obj.Name || !obj.Name.replace(/\s/g, '').length || !obj.CompanyID) {
      this.utilityService.displayErrorMessage(this.commonMessageKeys.MANDATORY_FIELD_ERROR);
      return false;
    }
    if (obj.Name?.replace(/\s/g, '').length > 200) {
      this.utilityService.displayErrorMessage(`Name should be upto 200 charcters.`);
      return false;
    }
    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (format.test(obj.Name)) {
      this.utilityService.displayErrorMessage(`Special characters are not allowed in name.`);
      return false;
    }
    return true;
  }

  saveInternal(obj: Role): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.model.Name = this.model.Name.trim();
      this.model.Code = this.model.Name;
      this.model.ApplicationCode = this.userContext.appCode;

      this.roleService.saveOrUpdate(this.model)
        .then((response: ContractHttpResponse<Role>) => {
          if (response.Success && response.Source?.ID > 0) {
            const selectedIDs = this.getSelectedPermissionIds(this.userTypePermissions);
            this.roleService.savePermissionAssignments(response.Source.ID, selectedIDs).then((response: ContractHttpResponse<Company[]>) => { });
            this.model = new Role();
          }
          resolve(response);
        });
    });
  }

  getSelectedPermissionIds(appPermissions: ApplicationPermission[]): number[] {
    let selectedIds: number[] = [];

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < appPermissions.length; i++) {
      selectedIds = selectedIds.concat(appPermissions[i].Permissions.filter(o => o.Selected).map(o => o.ID));
      selectedIds = selectedIds.concat(this.getSelectedPermissionIds(appPermissions[i].Modules));
    }

    return selectedIds;
  }

  private loadCompanies() {
    return new Promise((resolve, reject) => {
      this.companyService.getAll().then((response: ContractHttpResponse<Company[]>) => {
        if (response.Success === true) {
          this.companies = response.Source;
        }
        resolve(true);
      });
    });
  }

  public getAppPermissionByAppCodeAndCompany(event) {
    return new Promise((resolve, reject) => {
      if (this.model.CompanyID > 0) {
        this.permissionService.getAppPermissionByAppCodeAndCompany(this.model.CompanyID).then((response: ContractHttpResponse<ApplicationPermission>) => {
          if (response.Success === true) {

            this.userTypePermissions = response.Source?.Modules ?? [];
            this.sortPermissions(this.userTypePermissions);
            this.removePrivatePermissions(this.userTypePermissions);
            this.removeEmptyNodes();

            for (let index = 0; index < this.userTypePermissions.length; index++) {
              this.setParent(this.userTypePermissions[index]);
            }

            this.toggleState(this.userTypePermissions);

            if (this.model.ID > 0) {
              this.permissionService.getByRoleId(this.model.ID).then((response: ContractHttpResponse<Permission[]>) => {
                if (response.Source?.length > 0) {
                  let SelectedIds = response.Source.map((item) => item.ID);
                  this.makeSelection(this.userTypePermissions, SelectedIds);
                  this.toggleState(this.userTypePermissions);
                }

                resolve(this.userTypePermissions);
              });
            }
          }
          else {
            this.utilityService.displayErrorMessage(response.Message);
          }
        });
      } else {
        this.userTypePermissions = [];
        resolve(this.userTypePermissions);
      }
    })
  }

  sortPermissions(appPermissions: ApplicationPermission[]) {
    appPermissions.forEach(element => {
      if (element?.Modules.length) {
        element.Modules = _.sortBy(element.Modules, (e) => e.SortOrder);
      }
      element.Permissions = _.sortBy(element.Permissions, (e) => e.Name);
      this.sortPermissions(element.Modules);
    });
  }

  removeEmptyNodes() {
    this.removeEmptyChildNodes(this.userTypePermissions);
    this.userTypePermissions = this.userTypePermissions.filter(o => o.Permissions.length > 0 || o.Modules.length > 0);
  }

  removeEmptyChildNodes(appPermissions: ApplicationPermission[]) {
    appPermissions.forEach(element => {
      this.removeEmptyChildNodes(element.Modules);
      element.Modules = element.Modules.filter(o => o.Permissions.length > 0 || o.Modules.length > 0);
    });
  }

  removePrivatePermissions(appPermissions: ApplicationPermission[]) {
    appPermissions.forEach(element => {
      element.Permissions = element.Permissions.filter(o => !o.IsPrivate);
      this.removePrivatePermissions(element.Modules);
    });
  }

  setParent(appPermission: ApplicationPermission) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < appPermission.Permissions.length; i++) {
      appPermission.Permissions[i].Parent = appPermission;
    }

    // tslint:disable-next-line: prefer-for-of
    for (let j = 0; j < appPermission.Modules.length; j++) {
      appPermission.Modules[j].Parent = appPermission;
      this.setParent(appPermission.Modules[j]);
    }
  }

  toggleState(appPermission: ApplicationPermission[]) {
    appPermission.forEach(element => {
      element.Selected = (element.Permissions.filter(o => o.Selected == null || o.Selected === false).length === 0);
      element.Indetermine = element.Permissions.length > 0 && (element.Permissions.filter(o => o.Selected === true).length > 0) &&
        (element.Permissions.filter(o => o.Selected === true).length !== element.Permissions.length);

      // recursive call to make selection in modules.
      if (element.Modules.length > 0) {
        this.toggleState(element.Modules);

        // Select parent module if child module is selected along with its own permission.
        element.Selected = element.Selected && (element.Modules.filter(o => o.Selected == null || o.Selected === false).length === 0);
        element.Indetermine = element.Indetermine || (element.Modules.filter(o => o.Indetermine === true).length > 0) ||
          ((element.Modules.filter(o => o.Selected == null || o.Selected === false).length > 0) &&
            (element.Modules.filter(o => o.Selected == null || o.Selected === true).length > 0));
      }
    });
  }

  setSource(applicationPermissions: ApplicationPermission[]) {
    return new Promise((resolve, reject) => {
      let obj = {};
      applicationPermissions.forEach(module => {
        if (module.Selected || module.Indetermine) {
          module.Modules.forEach(item => {
            if (item.Selected || item.Indetermine) {
              const name = item.Name?.replace(/ /g, "-");
              const moduleName = module.Name?.replace(/ /g, "-");
              const key = `${moduleName}-${name}`;
              obj[key] = item.Permissions?.filter(x => x.Selected === true).map(o => `${o.Name}`).join(', ');
            }
          });
        }
      });
      resolve(obj);
    });
  }

  makeSelection(appPermission: ApplicationPermission[], selectedIds: number[]) {
    appPermission.forEach(element => {
      const filtered = element.Permissions.filter(x => selectedIds.includes(x.ID));
      // tslint:disable-next-line: no-shadowed-variable
      filtered.forEach(element => {
        element.Selected = true;
      });

      // recursive call to make selection in modules.
      if (element.Modules.length > 0) {
        this.makeSelection(element.Modules, selectedIds);
      }
    });

  }

  onModuleSelectionChange(e: IChangeCheckboxEventArgs, appPermission: ApplicationPermission) {
    this.togglePermissions([appPermission], e.checked);
    const root = this.getModuleRoot(appPermission);
    if (root != null) {
      this.toggleState([root]);
    }
  }

  togglePermissions(appPermissions: ApplicationPermission[], checked: boolean) {
    appPermissions.forEach(element => {
      element.Selected = checked;
      // tslint:disable-next-line: no-shadowed-variable
      if (checked === false)
        element.Indetermine = checked;
      if (checked === true)
        element.Indetermine = null;

      element.Permissions.forEach(element => {
        element.Selected = checked;
      });
      this.togglePermissions(element.Modules, checked);
    });
  }

  getModuleRoot(module: ApplicationPermission): ApplicationPermission {
    let root = module.Parent;
    while (root != null && root.Parent != null) {
      root = root.Parent;
    }
    return root;
  }

  onPermissionSelectionChange(e: IChangeCheckboxEventArgs, permission: Permission) {
    permission.Selected = e.checked;
    this.toggleState([this.getPermissionRoot(permission)]);
  }

  getPermissionRoot(permission: Permission): ApplicationPermission {
    let root = permission.Parent;
    while (root?.Parent != null) {
      root = root.Parent;
    }
    return root;
  }
}