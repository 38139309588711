<div class="cal-cell-top">
  <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ data:data , date:date }"></ng-template>
</div>
<!-- events -->
<!-- row expand dropdown icon  -->
<div class="cal-expand">
  <a *ngIf="displayTileExtensionButton" href="javascript:void(0)" (click)="onTileExpanded()">
      <span class="icon icon-chevron-down"></span>
    <!-- <ng-template *ngIf="tileExpandTemplate && tileCollapseTemplate; else default"  >
      <ng-template *ngIf="!isRowExpanded" [ngTemplateOutlet]="tileExpandTemplate" [ngTemplateOutletContext]="{ data:data , date:date }" > </ng-template>
      <ng-template *ngIf="isRowExpanded" [ngTemplateOutlet]="tileCollapseTemplate" [ngTemplateOutletContext]="{ data:data , date:date }" > </ng-template>
    </ng-template>
    <ng-template #default >
        <span class="icon icon-chevron-down"></span>
    </ng-template> -->
  </a>
</div>
