import { Injectable } from '@angular/core';
import { SortingDirection } from '@infragistics/igniteui-angular';
import { BaseApiService, IWebApiProxy, WebApiProxyFactoryService, UserContextService, IOrderColumn } from 'ssi-framework';
import { Permission } from '../models/permission.model';


@Injectable({
  providedIn: 'root'
})
export class PermissionService extends BaseApiService<Permission> {

  private proxy: IWebApiProxy;

  constructor(private proxyFactory: WebApiProxyFactoryService, private userContext: UserContextService) {
      super();
      this.proxy = this.proxyFactory.GetProxy('Permission');
  }

  getAppPermissionByAppCode(): Promise<any>{
    const applicationCode = 'applicationCode';
    const request = this.proxy.Get('GetAppPermissionByAppCode', {applicationCode: this.userContext.appCode});
    return request;
  }

  getAppPermissionByAppCodeAndCompany(companyId: number): Promise<any> {
    const request = this.proxy.Get('getAppPermissionByAppCodeAndCompany', { applicationCode: this.userContext.appCode, companyId });
    return request;
  }

  getByRoleId(roleId: number): Promise<any>{
    const applicationCode = 'applicationCode';
    const request = this.proxy.Get('GetByRoleId', {applicationCode: this.userContext.appCode, roleId});
    return request;
  }

  registerRoutes(route: import('@angular/router').ActivatedRoute) {
    throw new Error('Method not implemented.');
  }
  get(id: number): Promise<any> {
    throw new Error('Method not implemented.');
  }


  getPaged(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any, orderColumns?: IOrderColumn[]): Promise<any> {
    throw new Error('Method not implemented.');
  }
  getAll(): Promise<any> {
    throw new Error('Method not implemented.');
  }
  validate(obj: Permission): boolean {
    throw new Error('Method not implemented.');
  }
  saveOrUpdate(obj: Permission): Promise<any> {
    throw new Error('Method not implemented.');
  }
  delete(id: number): Promise<any> {
    throw new Error('Method not implemented.');
  }

}
