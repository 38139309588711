import { GridRowActionItemExtras } from './../../../../Infrastructure/Models/grid-row-action-item-extras';
import { Component, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { ConnectedPositioningStrategy, CloseScrollStrategy, VerticalAlignment, HorizontalAlignment,
  IgxGridCellComponent, PositionSettings, OverlaySettings, BlockScrollStrategy } from 'igniteui-angular';
import { IgxDropDownComponent} from 'igniteui-angular';
import { GridRowActionItem } from '../../../../Infrastructure/Models/grid-row-action-item'
import { PermissionManagerService } from '../../../../Authentication/permission-manager.service';
import { GridRowActionType } from '../../../../Infrastructure/Models/grid-row-action-type';

@Component({
  selector: 'lib-row-action-menu',
  templateUrl: './row-action-menu.component.html',
  styleUrls: ['./row-action-menu.component.css']
})
export class RowActionMenuComponent implements OnInit {

  @ViewChild('rowActionMenuTemplate', { read: TemplateRef, static: false })
  public rowActionMenuTemplate: TemplateRef<any>;

  public rowActionsDDL: IgxDropDownComponent;

  public rowItems: GridRowActionItem[] = [];
  public displayRowItems: GridRowActionItem[] = [];
  public rowActionType: string = GridRowActionType.IconOverlay;
  public gridRef: any;
  public rowActionId: string;
  public rowActionPinToLeft: boolean = false;
  selectedCell: IgxGridCellComponent;

  private _positionSettings: PositionSettings = {
    horizontalStartPoint: HorizontalAlignment.Left,
    verticalStartPoint: VerticalAlignment.Bottom,
    horizontalDirection: HorizontalAlignment.Left
  };
  private _overlaySettings: OverlaySettings = {
    closeOnOutsideClick: true,
    modal: false,
    positionStrategy: new ConnectedPositioningStrategy(this._positionSettings),
    scrollStrategy: new BlockScrollStrategy()
  };

  constructor(
    public permissionMangerService: PermissionManagerService) {
  }

  ngOnInit(): void {
    const filteredRowItems: GridRowActionItem[] = [];
    this.rowItems.forEach(element => {
      if (this.permissionMangerService.checkPermission(element.permissionCode)) {
        filteredRowItems.push(element);
      }
    });
    this.rowItems = filteredRowItems;
  }

  public toggleDropDown(eventArgs, cell: IgxGridCellComponent, rowActionsDDL: IgxDropDownComponent) {
    this.rowActionsDDL = rowActionsDDL;

    if (this.rowActionType === GridRowActionType.Dropdown && !!this.gridRef) {
      this.setOverlaySettings(eventArgs, cell)
    }

    this.selectedCell = cell;

    this.displayRowItems = this.getRowItems(this.selectedCell);

    this._overlaySettings.target = eventArgs.target;

    if (!rowActionsDDL.collapsed && this.rowActionId == rowActionsDDL.id) {
      this.rowActionsDDL.close();
    }else{
      setTimeout(() => {
        this.rowActionId = this.rowActionsDDL.id;
        this.rowActionsDDL.toggle(this._overlaySettings);
      }, 150);
    }


  }

  public onOpening(ev) {
    if (this.rowActionsDDL.focusedItem) {
      this.rowActionsDDL.focusedItem = undefined;
    }
    this.rowActionsDDL.selectItem(undefined);
  }

  public onSelection(eventArgs: any) {
    if (eventArgs.newSelection && this.selectedCell != null) {
      const key = eventArgs.newSelection.value;
      const rowItem = this.rowItems.filter(item => {
        return item.key === key;
      });

      if (rowItem) {
        rowItem[0].componentRef[rowItem[0].callback](this.selectedCell);

        this.selectedCell = null;
        this.rowActionsDDL.toggle(this._overlaySettings);
      }
    }

    eventArgs.cancel = true;
  }

  public onOverlayActionIconClicked(event : any,key, cell: IgxGridCellComponent) {
    const rowItem = this.rowItems.filter(item => {
      return item.key === key;
    });

    if (rowItem && rowItem.length > 0) {
      rowItem[0].componentRef[rowItem[0].callback](cell,event);
    }
  }

  public onActionClick(actionName: string, cell: any) {
    const rowItem = this.rowItems.filter(item => {
      return item.key === actionName;
    });
    if (rowItem) {
      rowItem[0].componentRef[rowItem[0].callback](cell);
    }
  }

  public getRowItems(cell: IgxGridCellComponent) {
    const filteredRowItems = [];
    this.rowItems.forEach((rowItem) => {
      if (rowItem.componentRef.gridActionItemTooltip) {
        rowItem.tooltip = rowItem.componentRef.gridActionItemTooltip(cell, rowItem.key);
      }

      if (rowItem.componentRef.gridActionItemVisibility) {
        const response = rowItem.componentRef.gridActionItemVisibility(cell, rowItem.key);
        if ((typeof response === 'boolean' && response) ||
          (response instanceof GridRowActionItemExtras && (response as GridRowActionItemExtras).visibility)) {
          rowItem.isDisabled = false;
          this.getDisplayText(rowItem, cell);
          filteredRowItems.push(rowItem);
        } else if ((typeof response === 'boolean' && !rowItem.hide) ||
          (response instanceof GridRowActionItemExtras && !(response as GridRowActionItemExtras).hide)) {
          rowItem.isDisabled = true;
          filteredRowItems.push(rowItem);
        }
      } else {
        filteredRowItems.push(rowItem);
      }
    });
    return filteredRowItems;
  }

  private getDisplayText(rowItem: GridRowActionItem, cell: IgxGridCellComponent) {
    if (rowItem.displayName && rowItem.displayName.length) {
      return;
    } else {
      if (rowItem.componentRef.getGridRowActionItemDisplayText) {
        rowItem.displayName = rowItem.componentRef.getGridRowActionItemDisplayText(cell, rowItem.key);
      }
    }
  }

  private setOverlaySettings(eventArgs, cell){
    if ((eventArgs.clientY - 30) > this.gridRef.totalHeight) {

      this._positionSettings.verticalDirection = VerticalAlignment.Top;
      this._overlaySettings.positionStrategy = new ConnectedPositioningStrategy(this._positionSettings)
    } else {
      this._positionSettings.verticalDirection = VerticalAlignment.Bottom;
      this._overlaySettings.positionStrategy = new ConnectedPositioningStrategy(this._positionSettings)
    }

    if (this.rowActionPinToLeft) {
      this._positionSettings.horizontalDirection = HorizontalAlignment.Right;
      this._positionSettings.horizontalStartPoint = HorizontalAlignment.Right
      this._overlaySettings.positionStrategy = new ConnectedPositioningStrategy(this._positionSettings)
    }
  }

  public rowItemsLength(cell: IgxGridCellComponent) {
    return this.rowItems.filter((rowItem) => {
      if (!rowItem.componentRef.gridActionItemVisibility || !rowItem.hide) {
        return true;
      } else {
        return rowItem.componentRef.gridActionItemVisibility(cell, rowItem.key);
      }
    }).length;
  }

}
