<div class="ssi-formGroup">
  <div class="btn-holder">
    <ul class="btnlist">
      <li *ngIf="permissionMangerService.checkPermission('CHANGEPASSWORD')">
          <button tabindex="4" igxButton="flat" type="submit" class="btn-primary" (click)="onSave()"
                  ssiTooltip="{{'CHANGE_PASSWORD_BTN' | ssiTranslator }}">
              <span class="icon icon-save"></span>{{'CHANGE_PASSWORD_BTN' | ssiTranslator }}
          </button>
      </li>
      <!-- <li><button tabindex="{{permissionMangerService.checkPermission('EDIT_USER')? 5: 4}}" igxButton="flat"
          type="submit" (click)="onCancel()" ssiTooltip="Cancel"><span
            class="icon icon-times-circle"></span>Cancel</button></li> -->
    </ul>
  </div>
  <div class="ssi-FourColForm wrapContent">
    <div class="row" *ngIf="isSelf">
      <div class="col control-holder">
        <igx-input-group>
          <label igxLabel for="oldPassword">{{'OLD_PASSWORD_LBL' | ssiTranslator }}</label>
          <input igxFocus="true" tabindex="1" igxInput ngModel [(ngModel)]="oldPassword" name="oldPassword"  autocomplete="none"
            type="password" [required]="true" />
        </igx-input-group>
      </div>
    </div>
    <div class="row">
      <div class="col control-holder">
        <igx-input-group>
          <label igxLabel for="password">{{'PASSWORD_LBL' | ssiTranslator }}</label>
          <input tabindex="2" igxInput ngModel [(ngModel)]="password" name="password" type="password"  autocomplete="none"
            [required]="true" />
        </igx-input-group>
      </div>
    </div>
    <div class="row">
      <div class="col control-holder">
        <igx-input-group>
          <label igxLabel for="confirmPassword">{{'CONFIRM_PASSWORD_LBL' | ssiTranslator }}</label>
          <input tabindex="3" igxInput ngModel [(ngModel)]="confirmPassword" name="confirmPassword" type="password"  autocomplete="none"
            [required]="true" />
        </igx-input-group>
      </div>
    </div>
  </div>
</div>
