export enum LookupCategoryCodes {
	OrgAreaType = 'ORG_AREA_TYPE',
}

export enum OrganizationLevels {}
export const FrontEndLookupStartingValue = 1000;
export enum OrganizationAreaTypeCodes {
	TENANT = 'TENANT',
	SUBSIDIARY = 'SUBSIDIARY',
	SUBTENANT = 'SUBTENANT',
	WAREHOUSE = 'WAREHOUSE',
	YARD = 'YARD',
	STORAGE = 'STORAGE',
	WAREHOUSE_LOCATIONS = 'WAREHOUSE_LOCATIONS',
	STAGING = 'STAGING',
	STORAGE_LOCATIONS = 'STORAGE_LOCATIONS',
	DOORS = 'DOORS',
	YARD_STORAGE = 'YARD_STORAGE',
	YARD_STAGING = 'YARD_STAGING',
	YARD_STORAGE_LOCATION = 'YARD_STORAGE_LOCATION',
	YARD_STAGING_LOCATION = 'YARD_STAGING_LOCATION',
}
export enum TripStatusCodes {
	CANCELLED = 'CANCELLED',
	COMPLETE = 'COMPLETE',
	SCHEDULED = 'SCHEDULED',
	IN_PROGRESS = 'IN_PROGRESS',
	UNSCHEDULED = 'UNSCHEDULED',
}
export enum ExternalUserCodes {
	CARRIER = 'CARRIER',
	VENDOR = 'VENDOR',
	CUSTOMER = 'CUSTOMER',
}
export enum ExternalCompanyLookupValues {
	Customer = 4,
	Vendor = 5,
}
export enum Statuses {
	Active = 1,
	InActive = 2,
}
export enum MoveRequestStatus {
	PENDING = 1,
	INPROGRESS = 2,
	COMPLETE = 3,
}
export enum OrganizationAreaTypeValues {
	TENANT = 1,
	SUBSIDIARY = 2,
	SUBTENANT = 3,
	WAREHOUSE = 4,
	STORAGE = 5,
	WAREHOUSE_LOCATIONS = 6,
	// SHIPINNG_RECIEVING = 7,
	STAGING = 7,
	STORAGE_LOCATIONS = 8,
	DOORS = 9,
	YARD = 10,
	// YARDLOCATION = 11,
	YARD_STORAGE = 11,
	YARD_STAGING = 12,
	YARD_STORAGE_LOCATION = 13,
	YARD_STAGING_LOCATION = 14,
}
export enum TripStatusLookupValues {
	CANCELLED = 1,
	COMPLETE = 2,
	SCHEDULED = 3,
	IN_PROGRESS = 4,
	UNSCHEDULED = 5,
}
export enum LoadingDockSubTypeLookupValues {
	LiveLoad = 1,
	PreLoad = 2,
	DropLoad = 2,
}
export enum DriverStatusLookupValues {
	PENDING = 1,
	CHECKEDIN = 2,
	CHECKEDOUT = 3,
	HOLD = 4,
	DENIED = 5,
}
export enum CommonLoadWorkFlowValue {
	CANCELLED = 3,
	TERMINATED = 4,
	COMPLETE = 5,
	HOLD = 6,
	PENDING = 7,
	STOP = 8,
}
export enum OutboundLiveLoadWorkFlowValue {
	NEW_OUTBOUNDLIVE = 1,
	LOADING = 2,
	CANCELLED = 3,
	TERMINATED = 4,
	SHIPPED = 5,
	HOLD = 6,
	PENDING = 7,
	STOP = 8,
}
export enum OutboundPreLoadWorkFlowValue {
	NEW_OUTBOUNDPRE = 1,
	LOADING = 2,
	CANCELLED = 3,
	TERMINATED = 4,
	SHIPPED = 5,
	HOLD = 6,
	PENDING = 7,
	STOP = 8,
	PRELOADED = 9,
}
export enum InboundLiveLoadWorkFlowValue {
	SCHEDULED = 1,
	ARRIVED = 2,
	CANCELLED = 3,
	TERMINATED = 4,
	RECEIVED = 5,
	HOLD = 6,
	PENDING = 7,
	STOP = 8,
	UNLOADING = 9,
	READY_FOR_CHECKOUT = 10,
}
export enum InboundDropLoadWorkFlowValue {
	SCHEDULED = 1,
	ARRIVED = 2,
	CANCELLED = 3,
	TERMINATED = 4,
	RECEIVED = 5,
	HOLD = 6,
	PENDING = 7,
	STOP = 8,
	DROPPED = 9,
	UNLOADING = 10,
}

export enum YardAssetTypeStatusLookupValues {
	TRAILER = 1,
	CONTAINER = 2,
	CHASIS = 3,
	TRACTOR = 4,
}

export enum CheckoutTypeSelectionStatusLookupValues {
	Cell_Phone = 1,
	Facial_Recognition = 2,
	CDL_Scan = 3,
}
export enum LoadingDockLoadTypes {
	OUT_BOUND = 1,
	IN_BOUND = 2,
	ASSET = 3,
}
export enum LoadingDockShipmentReceiptTypes {
	OUT_BOUND_ONLY = 1000,
	IN_BOUND_ONLY = 1001,
}
export enum LoadingDockLoadSubTypes {
	None = -1,
	LiveLoad = 1,
	PreLoad = 2,
	DropLoad = 3,
	DELIVERY = 1,
	PICKUP = 2,
	AssetDelivery,
}
export enum SafetyQuestionOptions {
	DONT_SHOW = 3,
	SHOW_ON_VERY_TRIP_CHECKIN = 2,
	SHOW_ONCE = 1,
}

export enum LoadingDockOutBoundLoadSubTypesValues {
	LiveLoad = 1,
	PreLoad = 2,
}

export enum LoadingDockInBoundLoadSubTypesValues {
	LiveLoad = 1,
	DropLoad = 2,
}

export enum LoadNumberTypeForBackend {
	LOAD_NUMBER = 1,
	DRAYAGE_ORDER_NUMBER = 2,
	ContainerNumber = 3,
	FILE_NUMBER = 4,
	BookingNumber = 5,
	TrailerNumber = 6,
}
export enum AssetStatusLookupValues {
	OUT_BOUND_FULL = 6,
	IN_BOUND_FULL = 7,
	EMPTY = 8,
	DEPARTED = 9,
}
export enum AssetStatusIndependentLookupValues {
	ARRIVED = 1,
	DIRTY = 2,
	CLEAN = 3,
	COMPLETED = 4,
	DEPARTED = 5,
}
export enum LoadingDockOutboundLoadNumberTypes {
	LOAD_NUMBER = 1,
	DRAYAGE_ORDER_NUMBER = 2,
	CONTAINER_NUMBER = 3,
	FILE_NUMBER = 4,
	BOOKING_NUMBER = 5,
	TRAILER_NUMBER = 6,
	SHIPMENT_NUMBER = 7,
	TRANSFER_ORDER = 8,
}
export enum LoadingDockInboundLoadNumberTypes {
	LOAD_NUMBER = 1,
	DRAYAGE_ORDER_NUMBER = 2,
	CONTAINER_NUMBER = 3,
	FILE_NUMBER = 4,
	BOOKING_NUMBER = 5,
	TRAILER_NUMBER = 6,
	ADVANCE_SHIPPING_NOTICE = 7,
	PURCHASE_ORDER = 8,
	TRANSFER_ORDER = 10,
}
export enum HighLevelWorkflowsLoadNumberLookupValues {
	NO_WORKFLOW_DECIDED_YET = 0,
	OUTBOUND_LIVE_LOAD = 1,
	OUTBOUND_PRE_LOAD = 2,
	INBOUND_LIVE_LOAD = 3,
	INBOUND_DROP_LOAD = 4,
	EMPTY_ASSET_PICKUP = 5,
	EMPTY_ASSET_DELIVERY = 6,
	INBOUND_LOAD_PICKUP = 7,
	INBOUND_ASSET_PICKUP = 8,
	INBOUND_LIVE_PICKUP = 9,
}
export enum HighLevelWorkflowsAppointmentLookupValues {
	OUTBOUND_LIVE_LOAD = 1,
	OUTBOUND_PRE_LOAD = 2,
	INBOUND_LIVE_LOAD = 3,
	INBOUND_DROP_LOAD = 4,
}
export enum TemplateType {
	Email = 1,
	SMS = 2,
	PushNotification = 3,
	ApplicationTemplate=4,
}
export enum HDCMainWorkflowENUMs {
	CheckInForLoad = 1,
	UploadAttachments = 2,
	CheckOutForLoad = 3,
}

export enum VehicleStatusLookupValues {
	ARRIVED = 1,
	DEPARTED = 2,
}
export enum VehicleClassWeightLookupValues{
	EIGHT = 1,
	FOUR_TO_SEVEN = 2,
	TWO_B_TO_Three = 3,
}
export enum VehicleEmissionTypeLookupValues{
	ZE = 1,
	NZE = 2,
	NEITHER = 3,
}
export enum InformationCompanyTypeLookupValues {
	CARRIER = 1,
	COMPANY = 2,
	OWNER = 3,
	CUSTOMER = 4,
	VENDOR = 5,
}
export enum HighLevelWorkflowsLookupValues {
	ASSET_PICK_UP = 5,
	OUTBOUND_LIVE_LOAD = 1,
	INBOUND_LIVE_LOAD = 3,
	INBOUND_DROP_LOAD = 4,
	EMPTY_ASSET_DELIVERY = 6,
	OUTBOUND_PRE_LOAD = 2,
}

export enum RequestEntityType {
	LOAD = 'LOAD',
	SPLIT_LOAD = 'SPLIT_LOAD',
}

export enum ShipmentTypes {
	OUTBOUND = 'Outbound',
	INBOUND = 'Inbound',
}

export enum DestinationTypes {
	WAREHOUSE = 'Warehouse',
	YARD = 'Yard',
}

export enum ScanCDLOptions{
	NOT_REQUIRED = 1,
	REQUIRED_ONE_TIME = 2,
	REQUIRED_PARTIALLy_WITH_OTP = 3,
	REQUIRED = 4
}
export enum VisitorMainWorkflows {
	CheckInForVisitor = 1,
	CheckOutForVisitor = 2,
}

export enum VisitorMainWorkflowCodes {
  CHECKIN_FOR_VISTOR = 'CHECKIN_FOR_VISTOR',
	CHECKOUT_FOR_VISITOR = 'CHECKOUT_FOR_VISITOR',
}

export enum VisitorMainWorkflowValues {
	CheckIn = 1,
	CheckOut = 2,
}

export enum VisitorMainWorkflowNames {
	Check_In = 'Check-In',
	Check_Out = 'Check-Out',
}

export enum VisitorStatusLookupCodes
{
    CHECKIN = "CHECKIN",
    CHECKOUT = "CHECKOUT"
}

export enum VisitorStatusLookupValues
{
    CHECKIN = 1,
    CHECKOUT = 2
}