import { BrowserModule } from '@angular/platform-browser';
import { NgModule, InjectionToken, Injector } from '@angular/core';
// import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
// import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
// import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes, RouterModule, Router, PreloadAllModules } from '@angular/router';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { HelperService } from './Common/services/helper.service';
import { CommonModule, DatePipe } from '@angular/common';

import {
	AuthenticateClientService,
	BootstrapperService,
	PermissionManagerService,
	RouteHandlerService,
	UserSettingsService,
	SsiFrameworkModule,
} from 'ssi-framework';
import { CommonConstants } from './Common/constants/constants';
import { SelfCheckinService } from './Common/services/self-checkin.service';
import { CachedUserSettingsService } from './core/services/cached-user-settings-service';
import { NgIdleModule } from '@ng-idle/core';
import { LoadingDockModule } from './loading-dock/loading-dock.module';
import { DataIntegrationModule } from './data-integration/data-integration.module';
//import { AuditLogService } from './shared/services/audit-log.service';
// import { OrganizationAssignmentModule } from './general/organization-assignment/organization-assignment.module';
// import { OrganizationAssignmentComponent } from './general/organization-assignment/organization-assignment/organization-assignment.component';
// import { JobService } from './job/services/job.service';
// import { LookupStoreService } from './Common/lookup-store.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
	IPublicClientApplication,
	PublicClientApplication,
	BrowserCacheLocation,
	LogLevel,
	InteractionType,
} from '@azure/msal-browser';
import {
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalInterceptorConfiguration,
	MSAL_GUARD_CONFIG,
	MsalGuardConfiguration,
	MsalBroadcastService,
	MsalService,
	MsalGuard,
	MsalRedirectComponent,
	MsalModule,
	MsalInterceptor,
} from '@azure/msal-angular';
const appRoutes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{
		path: 'Information',
		loadChildren: () => import('./information/information.module').then((m) => m.InformationModule),
		data: { routeRegistered: false },
	},
	{
		path: 'LoadingDock',
		loadChildren: () => import('./loading-dock/loading-dock.module').then((m) => m.LoadingDockModule),
		data: { routeRegistered: false },
	},
	{
		path: 'loadingdock',
		loadChildren: () => import('./loading-dock/loading-dock.module').then((m) => m.LoadingDockModule),
		data: { routeRegistered: false },
	},
	{
		path: 'visitor',
		loadChildren: () => import('./visitor/visitor.module').then((m) => m.VisitorModule),
		data: { routeRegistered: false },
	},
	{
		path: 'DataIntegration',
		loadChildren: () => import('./data-integration/data-integration.module').then((m) => m.DataIntegrationModule),
		data: { routeRegistered: false },
	},
	{ path: '**', redirectTo: '/home' },
];

export const HTTPSERVICESETTINGS = new InjectionToken('http-service-settings');

// FullCalendarModule.registerPlugins([ // register FullCalendar plugins
//   dayGridPlugin,
//   interactionPlugin
// ]);

const GRAPH_ENDPOINT = environment.AzureEntraIDConfiguration.GraphEndpoint;

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string): void {
	console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.AzureEntraIDConfiguration.ClientID,
			authority: environment.AzureEntraIDConfiguration.Authority,
			redirectUri: environment.AzureEntraIDConfiguration.RedirectUri,
			//postLogoutRedirectUri: 'https://r2-qa.renaissant.com/login',
		},
		system: {
			loggerOptions: {
				loggerCallback,
				logLevel: LogLevel.Info,
				piiLoggingEnabled: false,
			},
			allowRedirectInIframe: true,
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: isIE, // set to true for IE 11
		},
	});
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set(GRAPH_ENDPOINT, environment.AzureEntraIDConfiguration.Scopes);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			//scopes: ['user.read'],
			scopes: environment.AzureEntraIDConfiguration.Scopes,
		},
	};
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' }),
		OAuthModule.forRoot(),
		HttpClientModule,
		BrowserModule,
		SharedModule,
		CoreModule,
		BrowserAnimationsModule,
		CommonModule,
		NgIdleModule.forRoot(),
		SsiFrameworkModule,
		MsalModule,
		// FullCalendarModule // register FullCalendar with you app
		// OrganizationAssignmentModule
		// Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
		// Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes)
		// and `ping` is 120 (2 minutes).
	],
	providers: [
		{
			provide: 'EnvironmentVariables',
			useValue: environment,
		},
		{ provide: OAuthStorage, useValue: localStorage },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true,
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService,
		HelperService,
		DatePipe,
		CachedUserSettingsService,
		SelfCheckinService,
		//, AuditLogService, LookupStoreService
	],
	entryComponents: [AppComponent],
	exports: [SharedModule],
	// bootstrap: [MsalRedirectComponent],
})
export class AppModule {
	constructor(
		private bootstrapService: BootstrapperService,
		private routeHandler: RouteHandlerService,
		private router: Router,
		private authenticateClientService: AuthenticateClientService,
		private permissionManagerService: PermissionManagerService,
		private cachedUserSettingsService: CachedUserSettingsService,
		private userSettingsService: UserSettingsService,
		private permissionManager: PermissionManagerService,
		private injector: Injector
	) {}

	ngDoBootstrap(app) {
		this.bootstrapService.Init(environment).then(() => {
			// loading user settings
			this.cachedUserSettingsService.getUserAffiliationSetting().then((resolve) => {
				if (resolve) {
					this.SetUserPermissions().then(() => {
						// console.log('user Affiliation settings  access', 'app module', new Date());
						this.registerAppRoleRoutes();
						app.bootstrap(AppComponent);
						app.bootstrap(MsalRedirectComponent);
					});
				} else {
					// console.log('user Affiliation settings  access', 'app module', new Date());
					this.registerAppRoleRoutes();
					app.bootstrap(AppComponent);
					app.bootstrap(MsalRedirectComponent);
				}
			});

			// var lookupsStoreService = this.injector.get<LookupStoreService>(LookupStoreService);
			// lookupsStoreService.InitLookups().then(n => {

			//   if (!this.isJobView(app)) {
			//     app.bootstrap(AppComponent);
			//   }

			// });
		});
	}

	private SetUserPermissions(): Promise<any> {
		return new Promise((resolve) => {
			const settingCode = CommonConstants.CURRENT_LOGIN_AFFILIATION;
			// console.log('user settings access', 'app component');
			return Promise.all([
				this.SetPermissions(this.cachedUserSettingsService.getRolesFromSettings()),
				this.userSettingsService.loadUserSettings(),
			]).then(() => {
				resolve(true);
			});
		});
	}
	protected async SetPermissions(roles: string): Promise<any> {
		// console.log('SetPermissions');

		if (roles == '') {
			return null;
		}
		const response = new Promise((resolve) => {
			this.cachedUserSettingsService.getPermissions(roles).then((response) => {
				if (response.Success === true) {
					// console.log('PermissionsClearSet');
					this.permissionManager.clearPermissions();
					// console.log('PermissionsSet');
					this.permissionManager.setPermissionsFromAccessToken(response.Source);
					// console.log('PermissionsDone');
					resolve(true);
				} else resolve(false);
			});
		});

		return response;

		// this.userAffilationServices
		// 	.getNewAffiliationRoles(roles)
		// 	.then(async (response: ContractHttpResponse<permission>) => {
		// 		if (response.Success === true) {
		// 			console.log('PermissionsClearSet');
		// 			this.permissionManager.clearPermissions();
		// 			console.log('PermissionsSet');
		// 			this.permissionManager.setPermissionsFromAccessToken(response.Source);
		// 			console.log('PermissionsDone');
		// 			this.loadLayout = true;
		// 		}
		// 	});
	}

	private registerAppRoleRoutes() {
		//check if user is candidate
		// let candidateIdSetting = this.userSettingsService.getUserSettingsByCode("CandidateId");
		// if (!candidateIdSetting && this.permissionManagerService.checkPermission("ASSIGN_ORGANIZATION")) {
		//   //Register OrganizationAssignment route if Organization User
		//   let userTypeSetting = this.userSettingsService.getUserSettingsByCode("UserType");
		//   if (!userTypeSetting) {
		//     if (!this.routeHandler.getChildRoutes("Users/Edit/:id").some(n => n.path == "OrganizationAssignment")) {
		//        this.routeHandler.addChildRoutes("Users/Edit/:id", [{ path: 'OrganizationAssignment', component: OrganizationAssignmentComponent, outlet: 'ssiTabs', data: { title: 'Organization Assignment', sortOrder: 6 } }]);
		//     }
		//   } else {
		//     if (this.routeHandler.getChildRoutes("Users/Edit/:id").some(n => n.path == "OrganizationAssignment")) {
		//       this.routeHandler.removeChildRoutes("Users/Edit/:id", "OrganizationAssignment");
		//     }
		//   }
		// }
	}
}
