import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'search-shortcuts-overlay',
  templateUrl: './search-shortcuts-overlay.component.html',
  styleUrls: ['./search-shortcuts-overlay.component.scss']
})
export class SearchShortcutsOverlayComponent implements OnInit {

  @Output() onSearchSelected: EventEmitter<string> = new EventEmitter<string>();
  
  constructor() { }

  ngOnInit() {
  }

  selectSearch(searchText:string) {
    this.onSearchSelected.emit(searchText);
  }
}
