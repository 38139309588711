import { Injectable, Injector, Inject } from '@angular/core';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { SharedConstant } from '../Helpers/shared-constant';
import { Router, NavigationStart, ActivatedRoute, Params } from '@angular/router';
import { PermissionManagerService } from './permission-manager.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Constants } from '../Common/constants';
import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { ContractHttpResponse } from '../WebApiClient/HttpHandler/contract-http-response';
import { PermissionService } from '../WebInfrastructure/Services/permission.service';
import { Permission } from './permission';

@Injectable({
	providedIn: 'root',
})
export class AuthenticateClientService {
	private _errorResponse = new Subject<any>();
	private _authenticationAccess = new Subject<any>();
	private queryParams: Params;

	constructor(
		private sharedConstant: SharedConstant,
		private oauthService: OAuthService,
		private router: Router,
		public permissionManager: PermissionManagerService,
		private _injector: Injector,
		@Inject('EnvironmentVariables') private _environment: any,
		private activatedRoute: ActivatedRoute
	) {
		this.activatedRoute.queryParamMap.subscribe((params) => {
			const paramsEntries = Object.entries(params);
			if (paramsEntries[0][1]['redirectUrl'] != undefined) {
				this.queryParams = params;
			}
		});
	}

	InitAuthClient(userType?: any, social_token?: any, identityProvider?: any) {
		return new Promise<void>((resolve, reject) => {
			const identityConfig = this.sharedConstant.clients.find(
				(n) => n.Name === this.sharedConstant.webClientName
			);
			this.sharedConstant.webClient = identityConfig;

			if (!!userType && userType === 'saml_token') {
				var authConfig: AuthConfig = {
					issuer: identityConfig.IdentityServiceUrl,
					redirectUri: identityConfig.RelyingPartyUrl,
					clientId: identityProvider != undefined ? identityProvider.ClientCode : identityConfig.ClientCode,
					dummyClientSecret:
						identityProvider != undefined ? identityProvider.ClientSecretName : identityConfig.ClientSecret,
					scope: identityConfig.Scopes,
					requireHttps: false,
					customQueryParams: {
						grant_type:
							identityProvider != undefined
								? identityProvider.GrantType
								: this.sharedConstant.ThirdPartyCustomGrant,
						signin_platform: this.sharedConstant.CognitoSamlPlatformName,
						saml_token: social_token,
					},
				};
			} else {
				var authConfig: AuthConfig = {
					issuer: identityConfig.IdentityServiceUrl,
					redirectUri: identityConfig.RelyingPartyUrl,
					clientId: identityProvider != undefined ? identityProvider.ClientCode : identityConfig.ClientCode,
					dummyClientSecret:
						identityProvider != undefined ? identityProvider.ClientSecret : identityConfig.ClientSecret,
					scope: identityConfig.Scopes,
					requireHttps: false,
					customQueryParams: {
						grant_type:
							identityProvider != undefined
								? identityProvider.GrantType
								: this.sharedConstant.ThirdPartyCustomGrant,
					},
				};
			}

			if (identityConfig.Flow === Constants.IdentityClientFlows.ResourceOwner) {
				authConfig.sessionChecksEnabled = false;
				authConfig.oidc = false;
			}

			this.oauthService.configure(authConfig);

			this.oauthService.tokenValidationHandler = new JwksValidationHandler();
			this.oauthService.setStorage(localStorage);

			this.getPermissions().then((n) => {
				this.oauthService.events.subscribe(({ type, reason }: any) => {
					this._authenticationAccess.next({ type, reason });
					switch (type) {
						case 'discovery_document_loaded': {
							if (this.isAuthenticated()) {
								resolve();
							}

							break;
						}
						case 'token_received': {
							// TODO: add actionlog for signin
							// var permissions = this.getClaim("permission");
							// this.permissionManager.setPermissionsFromAccessToken(permissions);

							resolve();

							break;
						}
						case 'token_error': {
							// TODO: add actionlog signin error
							const res = reason as HttpErrorResponse;
							this._errorResponse.next(
								res.error.Message != null ? res.error.Message : 'Invalid user account or password'
							);
							resolve();
							break;
						}
						case 'logout': {
							this.permissionManager.clearPermissions();
							break;
						}
						case 'session_terminated': {
							this.oauthService.logOut();
							break;
						}
					}
				});

				this.oauthService
					.loadDiscoveryDocument()
					.then(() =>
						this.oauthService.tryLogin({
							onTokenReceived: (info) => {
								const eventSubscription = this.router.events.subscribe((event) => {
									if (event instanceof NavigationStart) {
										eventSubscription.unsubscribe();

										if (info.state + '/' !== window['base-href']) {
											this.router.navigateByUrl(info.state);
										}
									}
								});
							},
						})
					)
					.then((result) => {
						if (!this.isAuthenticated()) {
							// if (this.activatedRoute.data["AllowAnonymous"] != null && this.activatedRoute.data["AllowAnonymous"] == true) {
							//   return true;
							// }
							const currentRoute = window.location.pathname.replace(window['base-href'], '');
							if (this._environment.AllowAnonymousURLs.some((x) => currentRoute.startsWith(x))) {
								resolve();
								return true;
							} else if (identityConfig.Flow === Constants.IdentityClientFlows.Implicit) {
								this.oauthService.initImplicitFlow(currentRoute);
							} else if (identityConfig.Flow === Constants.IdentityClientFlows.ResourceOwner) {
								if (window.location.href.includes('#access_token=')) {
									let token = window.location.href.split('#access_token=')[1];
									this.router.navigateByUrl(`/login?access_token=${token}`);
								} else if (currentRoute === 'login') {
									this.router.navigateByUrl(`/${currentRoute}`);
								} else if (currentRoute !== 'resetPassword') {
									if (this._environment.UnAuthorizedRedirection != null) {
										window.location.href = this._environment.UnAuthorizedRedirection;
									} else {
										this.router.navigateByUrl(`/login?redirectUrl=${currentRoute}`);
									}
								}
							}
						}

						resolve();
					});
			});
		});
	}

	public isAuthenticated() {
		if (
			(this.sharedConstant.webClient != null &&
				this.sharedConstant.webClient.Flow === Constants.IdentityClientFlows.Implicit &&
				!this.oauthService.hasValidIdToken()) ||
			!this.oauthService.hasValidAccessToken()
		) {
			return false;
		}
		return true;
	}

	public login(loginName: string, password: string, redirectUrl?: string, headers?: HttpHeaders) {
		const request = this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(loginName, password, headers);
		request.then((resp) => {
			if (this.queryParams != null && !!this.queryParams.params.redirectUrl) {
				window.location.href = this.queryParams.params.redirectUrl;
				this.queryParams = null;
				// this.router.navigateByUrl(this.queryParams["redirectUrl"]);
			} else if (redirectUrl) {
				window.location.href = redirectUrl;
				// this.router.navigateByUrl(redirectUrl);
			}
		});
		return request;
	}

	public signOut() {
		localStorage.clear();

		if (
			this.sharedConstant.webClient != null &&
			this.sharedConstant.webClient.Flow === Constants.IdentityClientFlows.ResourceOwner
		) {
			this.oauthService.logOut(true);
			// this.router.navigateByUrl("/login");
			location.href = 'login';
		} else {
			this.oauthService.logOut();
		}
	}

	public getClaim(name: string): any {
		const claims = this.getDecodedAccessToken();
		if (claims != null) {
			return claims[name];
		}
		return null;
	}

	public getAccessTokenExpiration(): number {
		return this.oauthService.getAccessTokenExpiration();
	}

	public getErrorMessage(): Observable<any> {
		return this._errorResponse.asObservable();
	}

	public getAuthenticationAccess(): Observable<any> {
		return this._authenticationAccess.asObservable();
	}

	public loginWithSocialAccount(redirectUrl?: string) {
		const request = this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile('', '');
		request.then((resp) => {
			if (this.queryParams != null && this.queryParams['redirectUrl'] != null) {
				window.location.href = this.queryParams['redirectUrl'];
				// this.router.navigateByUrl(this.queryParams["redirectUrl"]);
			} else if (redirectUrl) {
				window.location.href = redirectUrl;
				// this.router.navigateByUrl(redirectUrl);
			}
		});
		return request;
	}

	public refreshAccessToken() {
		if (
			this.sharedConstant.webClient.Flow === Constants.IdentityClientFlows.ResourceOwner &&
			this.oauthService.hasValidAccessToken()
		) {
			const tokenExpiry = new Date(this.oauthService.getAccessTokenExpiration());
			const refreshThreshold = tokenExpiry.setMinutes(tokenExpiry.getMinutes() - 15);
			if (refreshThreshold < new Date().getTime() && tokenExpiry.getTime() > new Date().getTime()) {
				this.oauthService.refreshToken();
			} else if (tokenExpiry.getTime() < new Date().getTime()) {
				this.signOut();
			}
		}
	}

	private getDecodedAccessToken(): any {
		try {
			const helper = new JwtHelperService();
			const decodedToken = helper.decodeToken(this.oauthService.getAccessToken());
			return decodedToken;
		} catch (Error) {
			return null;
		}
	}

	public getPermissions(): Promise<any> {
		return new Promise<void>((resolve, reject) => {
			if (this.isAuthenticated()) {
				if (this.getClaim('permission') != null && this.getClaim('permission') != undefined) {
					this.permissionManager.setPermissionsFromAccessToken(this.getClaim('permission'));
				} else {
					const permissionService = this._injector.get<PermissionService>(PermissionService);
					const getPermissionsPromise = permissionService.getForUserByClientCode(
						this.getClaim('sub'),
						this.getClaim('client_id')
					);
					getPermissionsPromise.then((resp: ContractHttpResponse<Permission[]>) => {
						if (resp.Success) {
							const permissions = resp.Source.map((x) => this.mapPermissions(x.Code));
							this.permissionManager.setPermissionsFromAccessToken(permissions);
						}
						resolve();
					});
				}
			} else {
				resolve();
			}
		});
	}

	private mapPermissions(code: string): string {
		return 'http:' + '//' + this._environment.ApplicationCode + '/' + code;
	}
}
