export class User
    {
        public ID: number;
        public CompanyID :number;
        public CompanyCode: string;
        public CompanyName: string;
        public TeamID: number;
        public TeamCode: string;
        public TeamName: string;
        public Code: string;
        public Title: string;
        public FirstName: string;
        public MiddleName: string;
        public LastName: string;
        public Name: string;
        public Description: string;
        public LoginName: string;
        public Email: string;
        public Password: string;
        public ConfirmPassword: string;
        public IsPrivate: boolean;
        public IsActive: boolean;
        public DaysToExpire: number;
        public IsReadOnly: boolean;
        public IsDomainUser: boolean;
        public SortOrder: number;
        public AssignedRolesList;
        public LastLoggedInOn: Date;
        public CreatedBy: string;
        public CreatedOn: Date;
        public ModifiedBy: string;
        public ModifiedOn: Date;
    }