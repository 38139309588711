import { Component, ElementRef, Inject, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import {
	AuthenticateClientService,
	ContractHttpResponse,
	UserContextService,
	UserSettings,
	UserSettingsService,
} from 'ssi-framework';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { CommonConstants, ConfigKeys, LocalStorageKeys } from 'src/app/Common/constants/constants';
import { CachedUserSettingsService } from 'src/app/core/services/cached-user-settings-service';
import { MasterUserProfileService } from 'src/app/information/services/master-user-profile.service';
import { AuthenticationService } from 'src/app/information/services/authentication.service';
import { UserProfileDetail } from 'src/app/information/models/user-profile-detail.model';
import { EventEmitter } from '@angular/core';
import { GlobalSettingService } from 'src/app/information/services/global-settings.services';
import { parseBool, parseInt64_1 } from 'igniteui-angular-core';
import { OAuthService } from 'angular-oauth2-oidc';
import asyncLocalStorage from 'src/app/Common/utils/AsyncLocalStorage';
import { RenaissantAuthenticateClientService } from 'src/app/Common/services/renaissant-authenticate-client.service';
import { IdentityProvider, IdentityProviderService } from 'ssi-identity';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { HelperService } from 'src/app/Common/services/helper.service';
import { SharedMessagingKeys } from 'src/app/shared/common/shared-messaging-keys.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SocialUserPayload } from 'src/app/shared/models/social-user-payload.model';
import { UserProfile } from 'src/app/information/models/user-profile.model';
import { UserProfilesService } from 'src/app/information/services/user-profiles.service';
import { LoggedInUserInformation } from 'src/app/Common/services/LoggedInUserInformation.service';
import { UserProfilePropertyService } from 'src/app/information/services/user-profile-property.service';
import { Subject } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import {
	AuthenticationResult,
	InteractionStatus,
	InteractionType,
	PopupRequest,
	RedirectRequest,
	SilentRequest,
} from '@azure/msal-browser';
import { debug } from 'console';

export const PROGRESS_STATUS = { PROGRESS_START: 'PROGRESS_START', PROGRESS_END: 'PROGRESS_END' };
const GRAPH_ENDPOINT = environment.AzureEntraIDConfiguration.GraphEndpoint;

type ProfileType = {
	givenName?: string;
	surname?: string;
	userPrincipalName?: string;
	id?: string;
	businessPhones: string[];
	displayName: string;
	jobTitle: string;
	mail: string;
	preferredLanguage: string;
};
type KeyValueType = {
	key: string;
	value: string;
};
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	@Output()
	ProgressStatusEmitter = new EventEmitter();
	profile: any;
	isIframe = false;
	loginDisplay = false;
	private readonly _destroying$ = new Subject<void>();
	disabledFields = false;
	ClientName: string = environment.AppTitle;
	Login: string;
	Password: string;
	Error = '';
	type = 'password';
	loginAffiliation: any = null;
	onlyChromeSupport =
		'It looks like you are using a web browser other than Google Chrome to access Marketplace.  Please use Google Chrome for the best experience with this application.';

	showOtpDialog = false;
	otpCode: string = null;
	enableResendCode = true;
	resendCodeTime = 0;
	identityProviders: IdentityProvider[] = [];
	selectedIdentityProvider!: IdentityProvider | undefined;
	socialPayload: SocialUserPayload;
	// SAML related variables
	isSamlUser = false;
	isEmptyUsername = false;
	isPasswordUser = false;
	access_token_saml: string;
	showLoader = false;
	ErrorClass = '';
	private readonly _key = 'username-' + environment.Version;
	private readonly _providerUserType = 'providerUserType-' + environment.Version;
	private readonly _providerKey = 'providerIdentity-' + environment.Version;
	private readonly _providerToken = 'providerToken-' + environment.Version;
	private readonly _userRoleskey = 'userRoles-' + environment.Version;
	authError = '';
	mfaError = '';
	redirectUrl: any;
	activateLoader = false;
	mfaInfo = {
		email: {
			enabled: false,
			value: '',
		},
		sms: {
			enabled: false,
			value: '',
		},
	};
	userProfileDetailDTO: ContractHttpResponse<UserProfileDetail>;
	constructor(
		private router: Router,
		private authenticateClientService: AuthenticateClientService,
		private Meta: Meta,
		private UserSettingsService: UserSettingsService,
		private cachedUserSettingsService: CachedUserSettingsService,
		private masterUserProfileService: MasterUserProfileService,
		private authenticationService: AuthenticationService,
		private globalsettingsService: GlobalSettingService,
		private _loggedInUserInformation: LoggedInUserInformation,
		private userContextService: UserContextService,
		private oauthService: OAuthService,
		private renaissantAuthenticateClientService: RenaissantAuthenticateClientService,
		private identityProviderService: IdentityProviderService,
		private route: ActivatedRoute,
		private userProfileService: UserProfilesService,
		private _userProfileProperties: UserProfilePropertyService,
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private authService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private http: HttpClient
	) {
		this.authenticateClientService.getErrorMessage().subscribe((error) => {
			this.Error = error;
			switch (this.Error) {
				case '[IDENTITY_USERNAME_OR_PWD_INCORRECT_WITHOUT_ATTEMPTS]':
					this.Error = 'Invalid username or password';
					break;
				case '[IDENTITY_USERNAME_OR_PWD_INCORRECT_LOCKED]':
					this.Error = 'User locked. Please contact your administrator';
					break;
				case '[IDENTITY_USERNAME_OR_PWD_INCORRECT]':
					this.Error = 'Invalid username or password';
					break;
				case '[IDENTITY_USER_INACTIVE]':
					this.Error = 'User deactivated, please contact dispatch';
					break;
			}

			if (this.Error == '') {
			}
		});
		this.authenticateClientService
			.getAuthenticationAccess()
			.pipe(debounceTime(50))
			.subscribe(({ type, reason }: any) => {
				switch (type) {
					case 'token_received': {
						break;
					}
					case 'token_error': {
						this.ErrorClass = 'help-block';
						const res = reason as HttpErrorResponse;
						this.authError = 'Invalid user account or password';

						if (
							this.authError.toLowerCase().includes('incorrect') ||
							this.authError.toLowerCase().includes('invalid')
						) {
							console.log('Sign-in failed. Invalid password.');
						} else if (this.authError.toLowerCase().includes('locked')) {
							console.log('Sign-in failed. Account Locked.');
						}
						break;
					}
					case 'logout': {
						break;
					}
					case 'session_terminated': {
						console.log('session_terminated', this.Login);
						break;
					}
				}
			});

		this.redirectUrl = this.route.snapshot.queryParams?.['redirectUrl'];
		if (this.redirectUrl == undefined) {
			this.redirectUrl = 'home';
		}
	}
	getProfile(): void {
		this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_START);
		this.http.get(GRAPH_ENDPOINT).subscribe((profile) => {
			this.profile = profile;
			if (profile != null && this.IsSAML(this.profile.userPrincipalName)) {
				const userProfileDetailDTO = new UserProfileDetail();
				userProfileDetailDTO.Name = this.profile.displayName;
				userProfileDetailDTO.FirstName = this.profile.givenName;
				userProfileDetailDTO.LastName = this.profile.surname;
				userProfileDetailDTO.WorkEmail = this.profile.userPrincipalName;
				userProfileDetailDTO.User = this.profile.userPrincipalName;
				userProfileDetailDTO.SubTenantID = this.selectedIdentityProvider.EntityID;
				userProfileDetailDTO.CompanyID = this.selectedIdentityProvider.CompanyID;

				this.userProfileService
					.GetOrCreateSocialUser(userProfileDetailDTO)
					.then(async (response) => {
						if (response.Success == true && response.Source != null) {
							this.userProfileDetailDTO = response;
							this.signInUser(response.Source.User, response.Source.Password);
						} else {
							this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
							this.Error = 'Unable to login. Please try again';
							this.disabledFields = false;
						}
					})
					.catch(() => {
						this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
						this.Error = 'Unable to login. Please try again';
						this.disabledFields = false;
					});
			} else {
				this.Error = 'This account does not have access to Renaissant.';
				this.disabledFields = false;
				this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
			}
		});
	}

	ngOnInit(): void {
		this.getIdentityProviders();
		this.isIframe = window !== window.parent && !window.opener;

		this.msalBroadcastService.inProgress$
			.pipe(
				filter((status: InteractionStatus) => status === InteractionStatus.None),
				takeUntil(this._destroying$)
			)
			.subscribe(() => {
				debugger;
				this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_START);
				this.setLoginDisplay();
			});
		//Cognito Code committed for now
		// this.route.queryParams.subscribe((params) => {
		// 	if (!!params && !!params?.['access_token']) {
		// 		this.disabledFields = true;
		// 		this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_START);
		// 		this.access_token_saml = params?.['access_token'];
		// 		this.socialPayload = new SocialUserPayload();
		// 		this.socialPayload.accessToken = this.access_token_saml;
		// 		this.socialPayload.username = localStorage.getItem(this._key) as string;
		// 		this.selectedIdentityProvider = JSON.parse(localStorage.getItem(this._providerKey) || '{}');
		// 		const saml_token = JSON.stringify(this.socialPayload);
		// 		this.showLoader = true;
		// 		this.activateLoader = true;
		// 		this.removeLocalStorageKeys();
		// 		localStorage.setItem(this._providerToken, saml_token);
		// 		localStorage.setItem(this._providerUserType, 'saml_token');

		// 		this.authenticateClientService
		// 			.InitAuthClient('saml_token', saml_token, this.selectedIdentityProvider)
		// 			.then(() => {
		// 				this.authenticateClientService
		// 					.loginWithSocialAccount('')
		// 					.then((response) => {
		// 						this.showLoader = false;
		// 						this.userProfileService
		// 							.GetOrCreateUser(parseInt64_1(response.sub), this.selectedIdentityProvider.EntityID)
		// 							.then(async (response) => {
		// 								if (response.Success == true) {
		// 									const isvalid = await this.GetUserAffiliationFromSettings();
		// 									if (isvalid) {
		// 										asyncLocalStorage.setItem(LocalStorageKeys.TWO_FA_VERIFIED, 'true');

		// 										if (this.loginAffiliation == null) {
		// 											window.location.href = 'userAffilation';
		// 										} else {
		// 											window.location.href = 'home';
		// 										}
		// 									}
		// 								} else {
		// 									this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
		// 									this.Error = 'Unable to login. Please try again';
		// 									this.disabledFields = false;
		// 								}
		// 							})
		// 							.catch(() => {
		// 								this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
		// 								this.Error = 'Unable to login. Please try again';
		// 								this.disabledFields = false;
		// 							});
		// 					})
		// 					.catch(() => {
		// 						this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
		// 						this.Error = 'Unable to login. Please try again';
		// 						this.disabledFields = false;
		// 					});
		// 			})
		// 			.catch(() => {
		// 				this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
		// 				this.Error = 'Unable to login. Please try again';
		// 				this.disabledFields = false;
		// 			});
		// 	}
		// 	if (localStorage.getItem('ResetPassword') == 'Yes') {
		// 		localStorage.setItem('ResetPassword', 'No');
		// 		this.ErrorClass = 'success-block';
		// 		this.Error = SharedMessagingKeys.RESET_PASSWORD_URL_SENT_MESSAGE;
		// 	}
		// });
		this.Meta.updateTag({ name: 'viewport', content: 'width=device-width' });
	}

	//Reset content width on page redirect
	ngOnDestroy() {
		this.Meta.updateTag({ name: 'viewport', content: 'width=1366, initial-scale=0.5, maximum-scale=0.5' });
	}

	togglePassword(): void {
		if (this.type == 'text') {
			this.type = 'password';
		} else {
			this.type = 'text';
		}
	}
	setLoginDisplay(): void {
		const allAccounts = this.authService.instance.getAllAccounts();
		this.loginDisplay = allAccounts.length > 0;
		if (this.loginDisplay) {
			this.disabledFields = true;

			this.getProfile();
			this.authService.instance.setActiveAccount(allAccounts[0]);
		} else {
			this.disabledFields = false;
			this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
		}
	}

	loginSAML(): void {
		this.disabledFields = true;
		if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
			if (this.msalGuardConfig.authRequest) {
				this.authService
					.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
					.subscribe((response: AuthenticationResult) => {
						this.authService.instance.setActiveAccount(response.account);
					});
			} else {
				this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
					this.authService.instance.setActiveAccount(response.account);
				});
			}
		} else {
			if (this.msalGuardConfig.authRequest) {
				this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
			} else {
				this.authService.loginRedirect();
			}
		}
	}

	// logout(): void {
	// 	if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
	// 		this.authService.logoutPopup({
	// 			postLogoutRedirectUri: '/',
	// 			mainWindowRedirectUri: '/',
	// 		});
	// 	} else {
	// 		this.authService.logoutRedirect({
	// 			postLogoutRedirectUri: '/',
	// 		});
	// 	}
	// }
	signInUser(name: string, password: string): void {
		this.Error = null;
		this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_START);
		this.renaissantAuthenticateClientService
			.login(name, password)
			.then(async (response) => {
				const isvalid = await this.GetUserAffiliationFromSettings();
				const DistrictID =
					this.loginAffiliation != null && this.loginAffiliation.length > 0
						? this.loginAffiliation[0].DistrictID
						: this.userProfileDetailDTO != null
						? this.userProfileDetailDTO.Source.TenantID
						: 0;
				const identifyUserID = parseFloat(response.sub);
				if (isvalid) {
					if (this.userProfileDetailDTO != null) {
						asyncLocalStorage.setItem(LocalStorageKeys.TWO_FA_VERIFIED, 'true');
						asyncLocalStorage.setItem(LocalStorageKeys.IS_SOCIAL_USER, 'true');
						asyncLocalStorage.setItem(LocalStorageKeys.LAST_LOGIN_REFRESH_TIME, Date.now());
						const request = {
							scopes: environment.AzureEntraIDConfiguration.Scopes,
						};
						this.authService.instance.acquireTokenSilent(request).then((response) => {
							const cachEntry: KeyValueType = {
								key: 'user_token_' + this.userProfileDetailDTO.Source.IdentityUserID,
								value: response.accessToken,
							};
							this.cachedUserSettingsService.saveEntry(cachEntry);
							if (this.loginAffiliation == null) {
								window.location.href = 'userAffilation';
							} else {
								window.location.href = 'home';
							}
						});
					} else {
						const userprofile = await this.userProfileService.GetUserByIdentityUserIdAndDistrict(
							identifyUserID,
							DistrictID
						);
						if (this.loginAffiliation == null) {
							window.location.href = 'userAffilation';
							//this.router.navigate(['userAffilation']);
						} else {
							if (userprofile.Success && userprofile.Source) {
								this._userProfileProperties
									.GetProperty(
										'DefaultPage',
										this.loginAffiliation[0].OrganizationID,
										userprofile.Source.ID
									)
									.then((res) => {
										if (res.Success && res.Source) {
											window.location.href = res.Source.Value;
										} else {
											//this.router.navigate(['home']);
											window.location.href = 'home';
										}
									});
							} else {
								window.location.href = 'home';
							}
						}
					}
				}
			})
			.catch(() => {
				window.location.href = 'userAffilation';
				this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
			})
			.finally(() => {
				this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
			});
	}

	is2faEnabledByOrganization(): Promise<boolean> {
		return new Promise<boolean>(async (res, rej) => {
			await this.GetUserAffiliationFromSettings();
			const isOrganizationSelected =
				this.loginAffiliation != null && this.loginAffiliation.length > 0 ? true : false;
			if (!isOrganizationSelected) return res(false);

			const organizationID = this.loginAffiliation[0].OrganizationID;
			this.globalsettingsService
				.getConfigurationsByOrganizationIdAnonymous(organizationID, ConfigKeys.RequireOtpForLogin)
				.then((response) => {
					const config = response.Source.filter((x) => x.ConfigKey == ConfigKeys.RequireOtpForLogin);

					const isConfigAvailable = config != null && config.length > 0 ? true : false;

					if (!isConfigAvailable) return res(false);

					let isEnabled = parseBool(config[0].ConfigValue);
					res(isEnabled);
				});
		});
	}

	is2faEnabledByUserProfile(): any {
		return new Promise<boolean>((res, rej) => {
			const u: UserProfileDetail = new UserProfileDetail();
			u.Name = this.Login;
			this.masterUserProfileService
				.is2faEnabled(u)
				.then((resp) => {
					const is2FaEnabledByUser = resp.Source;
					res(is2FaEnabledByUser);
				})
				.catch((e) => {
					rej(e);
				});
		});
	}

	verifyOTP(name: string, password: string, otp: string): void {
		this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_START);
		this.Error = null;
		this.authenticationService.validateOtpForSignIn(name, password, otp).then((res) => {
			const status = res.Source.status;
			if (status === 'success') {
				asyncLocalStorage.setItem(LocalStorageKeys.TWO_FA_VERIFIED, 'true').then(() => {
					this.signInUser(this.Login, this.Password);
				});
			} else {
				this.Error = 'Could Not Verify OTP. Please Try Again';
				this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
			}
		});
	}

	ResendCode(): void {
		this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_START);
		this.authenticationService.generateOTPForSignIn(this.Login, this.Password).finally(() => {
			this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
		});
	}

	protected async GetUserAffiliationFromSettings(): Promise<boolean> {
		const settingCode = CommonConstants.CURRENT_LOGIN_AFFILIATION;
		const isloadSettingValid = await this.UserSettingsService.loadUserSettings();
		if (isloadSettingValid) {
			const UsersAffiliationsetting = this.UserSettingsService.getUserSettingsByCode(settingCode);
			if (UsersAffiliationsetting != null) {
				this.loginAffiliation = JSON.parse(UsersAffiliationsetting.Value);
				const roles = this.cachedUserSettingsService.GetCommaSepRolesByParam(this.loginAffiliation);
				await this.cachedUserSettingsService.setPermissions(roles);
				return true;
			} else {
				this.loginAffiliation = null;
				return true;
			}
		} else {
			this.loginAffiliation = null;
			return true;
		}
	}

	handleSignInButtonClick(): void {
		this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_START);

		if (this.Login == '' || this.Login == null || this.Password == '' || this.Password == null) {
			this.Error = 'Please Enter All Details To Continue';
			this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
			return;
		}

		this.renaissantAuthenticateClientService
			.login(this.Login, this.Password)
			.then((t) => {
				Promise.all([this.is2faEnabledByUserProfile(), this.is2faEnabledByOrganization()]).then((resp) => {
					const enabledByUser = resp[0];
					const enabledByOrganization = resp[1];

					if (enabledByUser === true || enabledByOrganization === true) {
						this.Error = null;
						asyncLocalStorage.setItem(LocalStorageKeys.TWO_FA_ENABLED, 'true');
						asyncLocalStorage.setItem(LocalStorageKeys.TWO_FA_VERIFIED, 'false').then(() => {
							this.showOtpDialog = true;
							this.authenticationService.generateOTPForSignIn(this.Login, this.Password);
						});
					} else {
						asyncLocalStorage.setItem(LocalStorageKeys.TWO_FA_ENABLED, 'false');
						asyncLocalStorage.setItem(LocalStorageKeys.TWO_FA_VERIFIED, 'true');
						this.signInUser(this.Login, this.Password);
						//this.onSignIn();
					}
					this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
				});
			})
			.catch(() => {
				this.ProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
			});
	}

	handleOtpCodeCompleted(code: string): void {
		this.otpCode = code;
	}

	handleOtpCancelButtonClick(): void {
		this.Error = null;
		this.showOtpDialog = false;
	}

	handleOtpVerifyButtonClick(): void {
		if (this.otpCode == null || this.otpCode.length < 6) {
			this.Error = 'Please enter OTP Code';
			return;
		}
		this.verifyOTP(this.Login, this.Password, this.otpCode);
	}

	handleResendButtonClick(): void {
		if (!this.enableResendCode) return;

		this.ResendCode();
		this.enableResendCode = false;
		this.resendCodeTime = 60;
		const intervalID = setInterval(() => {
			if (this.resendCodeTime > 0) this.resendCodeTime--;
			if (this.resendCodeTime == 0) {
				this.enableResendCode = true;
				clearInterval(intervalID);
			}
		}, 1000);
	}

	handleTogglePasswordButtonClick(): void {
		this.togglePassword();
	}
	getIdentityProviders(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.identityProviderService.getAll().then((response: ContractHttpResponse<IdentityProvider[]>) => {
				if (response && !!response.Source && response.Source.length > 0) {
					this.identityProviders = [];
					this.identityProviders = response.Source;
					console.log(this.identityProviders);
				} else {
					this.isSamlUser = false;
					this.isPasswordUser = true;
					this.isEmptyUsername = this.Login === '' || this.Login == undefined ? true : false;
				}
				resolve(true);
			});
		});
	}
	IsSAML(username: string): boolean {
		for (const provider of this.identityProviders) {
			if (username && username.trim().toLowerCase().endsWith(provider.IssuerDomain.toLowerCase())) {
				if (provider.IssuerType == 'URL') {
					this.selectedIdentityProvider = provider;
					this.isEmptyUsername = false;
					this.isPasswordUser = false;
					return true;
				} else if (provider.IssuerType == 'DB') {
					this.selectedIdentityProvider = provider;
					this.isEmptyUsername = false;
					this.isPasswordUser = true;
					return false;
				}
			} else {
				this.selectedIdentityProvider = undefined;
				this.isPasswordUser = true;
				this.isEmptyUsername = username.trim() === '' || username == undefined ? true : false;
			}
		}
		return false;
	}

	onSignIn(): void {
		this.isSamlUser = this.IsSAML(this.Login ? this.Login.trim() : '');
		this.Error = null;
		if (!this.isSamlUser) {
			this.signInUser(this.Login, this.Password);
		}

		//  this.router.navigate(['home']);
	}
	signInSAMLUser(): void {
		//SAML user workflow
		this.removeLocalStorageKeys();
		localStorage.setItem(this._key, this.Login);
		localStorage.setItem(this._providerKey, JSON.stringify(this.selectedIdentityProvider));

		if (!!this.selectedIdentityProvider && !!this.selectedIdentityProvider.IssuingAuthority) {
			this.activateLoader = true;

			window.location.replace(this.selectedIdentityProvider.IssuingAuthority);
		}
	}
	removeLocalStorageKeys(): void {
		localStorage.removeItem(this._key);
		localStorage.removeItem(this._providerToken);
		localStorage.removeItem(this._providerUserType);
		localStorage.removeItem(this._providerKey);
	}
	public validateCredentials(): boolean {
		if (
			this.isPasswordUser &&
			!this.isEmptyUsername &&
			(!this.Password || !HelperService.validateEmptyOrBlankSpaces(this.Password)) &&
			(!this.Login || !HelperService.validateEmptyOrBlankSpaces(this.Login))
		) {
			this.ErrorClass = 'help-block';
			this.Error = '';
			this.Error = 'Username is missing.';
			return false;
		} else if (
			this.isPasswordUser &&
			!this.isEmptyUsername &&
			//this.passwordRef &&
			(!this.Password || !HelperService.validateEmptyOrBlankSpaces(this.Password))
		) {
			this.ErrorClass = 'help-block';
			this.Error = '';
			this.Error = 'Password is missing';
			return false;
		} else if (!this.Login || !HelperService.validateEmptyOrBlankSpaces(this.Login)) {
			this.ErrorClass = 'help-block';
			this.Error = '';
			this.Error = 'Username is missing.';
			return false;
		}
		return true;
	}
	onUsernameUpdate(): void {
		this.Error = '';
		if (!!this.identityProviders && this.identityProviders.length > 0 && this.IsSAML(this.Login)) {
			this.Password = '';
			this.isSamlUser = true;
			this.disabledFields = true;
			this.signInSAMLUser();
		} else {
			this.isSamlUser = false;
			this.isPasswordUser = true;
			this.isEmptyUsername = this.Login.trim() === '' || this.Login == undefined ? true : false;
		}
	}
	backToUsername(): void {
		this.Error = '';
		this.Password = '';
	}
}
