<igx-dialog #dialog  leftButtonLabel="No" (onLeftButtonSelect)="Cancel($event)"
        rightButtonLabel="Yes" (onRightButtonSelect)="Confirm($event)" [message]="message">
        <igx-dialog-title>
                <div class="dialog-container">
                        <div class="dialog-title">{{title}}</div>
                    </div>
        <ng-container *ngIf="showCrossIcon">
               <div class="action-close">  <a href="javascript:;" >
                <span class="material-icons" (click)="Cancel($event)">close</span></a></div>
        </ng-container>
        </igx-dialog-title>
</igx-dialog>