import { Injectable } from '@angular/core';
import { IgxComboComponent } from 'igniteui-angular';
import { IgxInputDirective } from 'igniteui-angular';
import { SsiSelectComponent } from '../WebControls/Controls/ssi-select/ssi-select.component';
import { UserSettingsService } from '../WebInfrastructure/Services/user-settings.service';

@Injectable({
    providedIn: 'root'
})
export class FilterService {


    private static filters: any = [];
    private static filterObj: any = {};
    public static inputs: any = [];
    public static combos: any = [];
    public static dropdowns: any = [];
    public static ssiSelect: any = [];
    private static userSettingsApplied = false;
    public static filterComponents: any = [];

    public static onApplyFilter(removedFilter?: any): any {
        // filter passed from Filter Tags
        if (removedFilter) {
            this.clearRemovedFilter(removedFilter);
            this.setFilterValues(this.filterObj,false);
        } else {
            this.filterObj = this.getFilterValues();
        }
        return this.filterObj;
    }


    public static onSaveAndApplyFilter(userSettings?: UserSettingsService, gridRef?: any, removedFilter?: any): any {
        if (removedFilter) {
            this.clearRemovedFilter(removedFilter);
            this.setFilterValues(this.filterObj, false);
        }
        else {
            this.filterObj = this.getFilterValues();
        }
        this.applyAndSaveFilter(userSettings, gridRef);
        return this.filterObj;

    }

    private static clearRemovedFilter(removedFilter: any) {
        const value = this.filterObj[removedFilter.fieldName];
        if (Array.isArray(value)) {
            this.filterObj[removedFilter.fieldName] = value.filter(n => n !== removedFilter.value);
        } else {
            this.filterObj[removedFilter.fieldName] = null;
        }

        this.filters = this.filters.filter(x => x.fieldName != removedFilter.fieldName || x.value != removedFilter.value);
    }

    private static getFilterValues(): any {
        const filter: any = {};
        this.filters = [];
        this.inputs.forEach(n => {
            if (this.checkIfControlIsFilter(n.nativeElement)) {
                if (n.value) {
                    filter[n.nativeElement.attributes.name.nodeValue] = n.value;
                    if (n.value) {
                        this.filters.push({
                            fieldName: n.nativeElement.attributes.name.nodeValue,
                            key: n.value,
                            value: n.value
                        });
                    }
                }
            }
        });

        this.combos.forEach(n => {
            if (this.checkIfControlIsFilter(n.comboInput.nativeElement)) {
                const name = this.GetNameByNode(n.comboInput.nativeElement, 'igx-combo');
                if (name) {
                    const itemValues = n.selectedItems();
                    const items = n.data.filter(x => itemValues.some(y => y === x[n.valueKey]));

                    if (items) {
                        const itemIds = items.map(value => {

                            if (value[n.valueKey]) {
                                this.filters.push({
                                    fieldName: name,
                                    key: value[n.displayKey],
                                    value: value[n.valueKey],
                                    label: (n as any).elementRef.nativeElement.title
                                });
                            }
                            return value[n.valueKey];
                        });
                        if (itemIds) {
                            filter[name] = itemIds.length ? itemIds : [];
                        }
                    }
                }
            }
        });

        this.ssiSelect.forEach(n => {
            if (this.checkIfControlIsFilter(n.ssiCombo.comboInput.nativeElement)) {
                const name = this.GetNameByNode(n.ssiCombo.comboInput.nativeElement, 'igx-combo');
                if (name) {
                    let items = [];
                    if (!n.singleSelect && n.hasRemoteData) {
                        const multiSelectValues = n.ssiCombo.selectedItems();
                        items = n.allItems.filter(x => multiSelectValues.some(y => y === x[n.ssiCombo.valueKey]));
                        if (multiSelectValues.length !== items.length) {
                            const remainingItemValues = multiSelectValues.filter(x => !items.some(y => y[n.ssiCombo.valueKey] === x));
                            items.push(...n.initialRemoteItems.filter(x => remainingItemValues.some(y => y === x[n.ssiCombo.valueKey])));
                        }
                    } else {
                        const itemValues = n.ssiCombo.selectedItems();
                        if (n.hasRemoteData) {
                            items = n.allItems.filter(x => itemValues.some(y => y === x[n.ssiCombo.valueKey]));
                            if (itemValues.length !== items.length) {
                                const remainingItemValues = itemValues.filter(x => !items.some(y => y[n.ssiCombo.valueKey] === x));
                                items.push(...n.initialRemoteItems.filter(x => remainingItemValues.
                                    some(y => y === x[n.ssiCombo.valueKey])));
                            }
                        } else {
                            items = n.ssiCombo.data.filter(x => itemValues.some(y => y === x[n.ssiCombo.valueKey]));
                        }
                    }
                    if (items) {
                        const itemIds = items.map(value => {

                            if (value[n.ssiCombo.valueKey]) {
                                this.filters.push({
                                    fieldName: name,
                                    key: value[n.ssiCombo.displayKey],
                                    value: value[n.ssiCombo.valueKey],
                                    label: n.label
                                });
                            }
                            return value[n.ssiCombo.valueKey];
                        });
                        if (itemIds) {
                            if (n.singleSelect) {
                                filter[name] = itemIds.length ? itemIds[0] : null;
                            } else {
                                filter[name] = itemIds.length ? itemIds : null;
                            }
                        }
                    }
                }
            }
        });

        this.dropdowns.forEach(n => {
            if (this.checkIfControlIsFilter(n.element.nativeElement)) {
                filter[n.name] = n.value;
                if (n.value > -1 && n.selectedText) {
                    this.filters.push({
                        fieldName: n.name,
                        key: n.selectedText,
                        value: n.value
                    });
                }

            }
        });

        if (this.filterComponents.length && this.filterComponents.first.searchText) {
            filter.searchText = this.filterComponents.first.searchText;
            this.filters.push({
                fieldName: 'searchText',
                key: this.filterComponents.first.searchText,
                value: this.filterComponents.first.searchText
            });
        } else {
            filter.searchText = null;
        }

        if (this.filterComponents && this.filterComponents.first) {
            this.filterComponents.first.filterObject = this.filters;
        }

        return filter;
    }

    private static setFilterValues(filterObj: any, clearFilterTags: boolean = true, ssiSelectDummyItem = null) {
        if (clearFilterTags) {
            this.filters = [];
        }

        if (filterObj != null) {
            this.inputs.forEach(n => {
                if (this.checkIfControlIsFilter(n.nativeElement)) {
                    this.setIgxInputValue(n, filterObj[n.nativeElement.attributes.name.nodeValue]);

                    // if (filterObj[n.nativeElement.attributes.name.nodeValue] != null) {
                    //     this.filters.push({
                    //         fieldName: n.nativeElement.attributes.name.nodeValue,
                    //         key: filterObj[n.nativeElement.attributes.name.nodeValue],
                    //         value: filterObj[n.nativeElement.attributes.name.nodeValue]
                    //     });
                    // }
                }
            });

            this.combos.forEach(n => {
                if (this.checkIfControlIsFilter(n.comboInput.nativeElement)) {
                    const name = this.GetNameByNode(n.comboInput.nativeElement, 'igx-combo');
                    if (name) {
                        this.setIgxComboValue(n, filterObj[name], name);
                    }
                }
            });

            this.ssiSelect.forEach(n => {
                if (this.checkIfControlIsFilter(n.ssiCombo.comboInput.nativeElement)) {
                    const name = this.GetNameByNode(n.ssiCombo.comboInput.nativeElement, 'igx-combo');
                    if (name) {
                        this.setSsiSelectValue(n, filterObj, name);
                    }
                }
            });

            this.ssiSelect.forEach(n => {
                if (this.checkIfControlIsFilter(n.ssiCombo.comboInput.nativeElement)) {
                    const name = this.GetNameByNode(n.ssiCombo.comboInput.nativeElement, 'igx-combo');
                    if (name) {
                        let dummyItems = null;
                        if (ssiSelectDummyItem && ssiSelectDummyItem.field === name) {
                            dummyItems = ssiSelectDummyItem.items;
                        }
                        this.setSsiSelectValue(n, filterObj, name, dummyItems);
                    }
                }
            });

            this.dropdowns.forEach(n => {
                if (this.checkIfControlIsFilter(n.element.nativeElement)) {
                    n.value = this.filterObj[n.name];
                    // Tag filters
                    // if (n.value != null) {
                    //     const item = n.items.filter(m => m[n.itemKey] === n.value);
                    //     if (item && item[0][n.itemText]) {
                    //         this.filters.push({
                    //             fieldName: n.name,
                    //             key: item[0][n.itemText],
                    //             value: this.filterObj[n.name]
                    //         });
                    //     }
                    // }
                }
            });
            if (this.filterComponents && this.filterComponents.first) {
                this.filterComponents.first.filterObject = this.filters;
            }
        }
    }

    private static checkIfControlIsFilter(nativeElement: any): boolean {
        let element: any = nativeElement;
        if (nativeElement.attributes.name) {
            while (element) {
                if (element.parentElement && element.parentElement.className.indexOf('ssi-filter') > -1) {
                    return true;
                } else {
                    element = element.parentElement;
                }
            }
        }
        return false;
    }

    private static GetNameByNode(nativeElement: any, nodeName: string): string {
        let element: any = nativeElement;
        while (element) {
            if (element.attributes.name && element.localName === nodeName) {
                return element.attributes.name.nodeValue;
            } else {
                element = element.parentElement;
            }
        }
        return null;
    }

    private static setIgxComboValue(element: IgxComboComponent, values: any[], name?: string) {
        if (values) {
            values = Array.isArray(values) ? values : [values];
            const selectedItems: any[] = [];
            element.data.forEach(n => {
                if (values.includes(n[element.valueKey])) {
                    selectedItems.push(n[element.valueKey]);
                    // if (n[element.displayKey]) {
                    //     this.filters.push({
                    //         fieldName: name,
                    //         key: n[element.displayKey],
                    //         value: n[element.valueKey]
                    //     });
                    // }
                }
            });
            element.selectItems(selectedItems, true);
        }
    }

    private static setSsiSelectValue(element: SsiSelectComponent, filterObj: any, name?: string, initialDummyItems?: any[]) {
        let selectedFilterValues = filterObj[name] ? filterObj[name] : [];
        if (selectedFilterValues) {
            selectedFilterValues = Array.isArray(selectedFilterValues) ? selectedFilterValues : [selectedFilterValues];
            const selectedItems: any[] = [];

            if (element.hasRemoteData) {
                if (initialDummyItems && initialDummyItems.length) {
                    initialDummyItems.forEach(val => {
                        this.filters.push({
                            fieldName: name,
                            key: val[element.ssiCombo.displayKey],
                            value: val[element.ssiCombo.valueKey],
                            label: element.label
                        });
                    });
                }
                selectedFilterValues.forEach(val => {
                    if (val) {
                      selectedItems.push(val);
                    }
                  });
                const dummyItems = [];
                const fileds = this.filters.filter(n => n.fieldName === name);
                if (fileds) {
                    fileds.forEach(field => {
                        const dummyItem = {};
                        dummyItem[element.itemKey] = field.value;
                        dummyItem[element.itemText] = field.key;
                        dummyItems.push(dummyItem);
                    });
                }
                element.initialRemoteItems = dummyItems;
            } else {
                element.ssiCombo.data.forEach(n => {
                    selectedFilterValues.forEach(val => {
                        if (val != null && n[element.ssiCombo.valueKey] != null
                            && val.toString() === n[element.ssiCombo.valueKey].toString()) {
                            selectedItems.push(n[element.ssiCombo.valueKey]);

                            // if (n[element.ssiCombo.displayKey]) {
                            //     this.filters.push({
                            //         fieldName: name,
                            //         key: n[element.ssiCombo.displayKey],
                            //         value: n[element.ssiCombo.valueKey],
                            //         label: element.label
                            //     });
                            // }
                        }
                    });
                });
            }

            element.ssiCombo.selectItems(selectedItems, true);
        }
    }

    private static setIgxInputValue(element: IgxInputDirective, value: any) {
        if (!value) {
            value = '';
        }
        element.value = value;
    }

    public static onResetFilter() {
        this.filterObj = {};
        this.filters = [];
        this.resetFilterValues();

        if (this.filterComponents && this.filterComponents.first) {
            this.filterComponents.first.filterObject = [];
        }
        return this.filterObj;
    }

    private static resetFilterValues() {
        this.inputs.forEach(n => {
            if (this.checkIfControlIsFilter(n.nativeElement)) {
                this.setIgxInputValue(n, '');
            }
        });

        this.combos.forEach(n => {
            if (this.checkIfControlIsFilter(n.comboInput.nativeElement)) {
                this.setIgxComboValue(n, []);
            }
        });

        this.ssiSelect.forEach(n => {
            if (this.checkIfControlIsFilter(n.ssiCombo.comboInput.nativeElement)) {
                const name = this.GetNameByNode(n.ssiCombo.comboInput.nativeElement, 'igx-combo');
                this.setSsiSelectValue(n, [], name);
            }
        });

        this.dropdowns.forEach(n => {
            if (this.checkIfControlIsFilter(n.element.nativeElement)) {
                n.selectedIndex = -1;
            }
        });
    }

    
    private static applyAndSaveFilter(userSettings: UserSettingsService, gridRef: any): any {
        if (!this.userSettingsApplied) {
            this.userSettingsApplied = true;
            const filterSetting = userSettings.getFilterSettings(gridRef.id);
            if (filterSetting && filterSetting.filterValues) {
                this.filterObj = filterSetting.filterValues;
            }

            if (filterSetting && filterSetting.filterDetails) {
                this.filters = [];
                this.filters.push(...filterSetting.filterDetails);
            }
            this.setFilterValues(this.filterObj, false);
        }

        userSettings.saveFilterSettings(gridRef.id, {
            filterValues: this.filterObj,
            filterDetails: this.filters
        });
    }
}