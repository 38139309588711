import { Injectable } from '@angular/core';
import { BaseApiService, WebApiProxyFactoryService, IWebApiProxy, UtilityService, UserContextService } from 'ssi-framework';
import { SortingDirection } from 'igniteui-angular';
import { NotificationEvent } from '../models/notification-event';

@Injectable({
  providedIn: 'root'
})
export class NotificationEventService extends BaseApiService<NotificationEvent> {

  proxy: IWebApiProxy;

  constructor(private proxyFactory: WebApiProxyFactoryService, private utilityService: UtilityService, private ucs: UserContextService) {
    super();
    this.proxy = this.proxyFactory.GetProxy('NotificationEvent');
  }

  registerRoutes(route: import('@angular/router').ActivatedRoute) {
    throw new Error('Method not implemented.');
  }

  get(id: number): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getPaged(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getAll(): Promise<any> {
    const request = this.proxy.Get('GetAll');
    return request;
  }

  validate(obj: NotificationEvent): boolean {
    throw new Error('Method not implemented.');
  }

  saveOrUpdate(obj: NotificationEvent): Promise<any> {
    throw new Error('Method not implemented.');
  }

  delete(id: number): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getSubscriptionsByLogin(loginName: string): Promise<any> {
    const request = this.proxy.Get('GetSubscriptionsByLogin', { loginName: loginName });
    return request;
  }

  getAllConfiguration(): Promise<any> {
    const request = this.proxy.Get('GetAllConfiguration');
    return request;
  }

  saveSubscriptionByLogin(loginName: string, eventConfigIDs: number[]): Promise<any> {
    const request = this.proxy.Get('SaveSubscriptionByLogin', { loginName: loginName, EventConfigIDs: eventConfigIDs });
    return request;
  }

  bulkAddSubscription(data: any[]): Promise<any> {
    const request = this.proxy.Post('BulkAddSubscription', data);
    return request;
  }
}
