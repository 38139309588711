import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, Injector } from '@angular/core';
import { IgxGridCellComponent, IgxGridComponent, SortingDirection } from 'igniteui-angular';
import { ContractHttpResponse, UserSettingsService, LayoutService, UtilityService, CommonMessageKeys, PermissionManagerService,
   BaseLVComponent, SsiTranslatorPipe, GridRowActionItem } from 'ssi-framework';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationConfigurationService } from '../../services/notification-configuration.service';
import { NotificationEventConfiguration } from '../../models/notification-configuration';
import { NotificationConstants } from '../../Common/notification-constants';
import { NotificationSubscription } from '../../models/notification-subscription';
import { NotificationSubscriptionLVComponent } from '../notification-subscription-lv/notification-subscription-lv.component';

@Component({
  selector: 'lib-ssi-notification-event-configuration-lv',
  templateUrl: './notification-event-configuration-lv.component.html',
  styleUrls: ['./notification-event-configuration-lv.component.scss']
})
export class NotificationEventConfigurationLVComponent extends BaseLVComponent<NotificationEventConfiguration> {

  @ViewChild('FWNotificationConfigurationGrid', {static: false}) public gridRef: IgxGridComponent;
  @ViewChild('NotificationSubscriptionRef', {static: false}) public notificationSubscriptionRef: NotificationSubscriptionLVComponent;

  direction = 'horizontal';
  show = false;

  public notificationModes: any[] = [];
  public eventSubscriptions: NotificationSubscription[];
  public selection = true;
  public selectedConfigID: number;
  private _ssiTranslatorPipe : SsiTranslatorPipe;

  constructor(private layoutService: LayoutService, private _injector: Injector, viewRef: ViewContainerRef,
    componentResolver: ComponentFactoryResolver, userSettingsService: UserSettingsService, router: Router,
    route: ActivatedRoute, private notificationConfigurationService: NotificationConfigurationService,
    utilityService: UtilityService,commonMessageKeys: CommonMessageKeys, public permissionMangerService: PermissionManagerService) {
    super(viewRef, componentResolver, userSettingsService, utilityService, commonMessageKeys, router, route);
    this._ssiTranslatorPipe = new SsiTranslatorPipe(_injector);

  }

  items: GridRowActionItem[] = [
    new GridRowActionItem(this, "edit", "Edit", "EDIT_NOTIFICATIONEVENTCONFIGURATION", "editEvent", "edit"),
  ];

  public setTitle() {
    const title = this._ssiTranslatorPipe.transform('MANAGE_NOTIFICATION_EVENT_TITLE');
    this.layoutService.setTitle(title);
  }

  protected onBeforeLoad(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loadNotificationModes();
      resolve(true);
    });
  }

  protected getData(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any): Promise<any> {
    const response = this.notificationConfigurationService.getPaged(pageIndex, pageSize, orderBy, orderDir, filter);
    return response;
  }

  protected onAfterLoad() {
  }

  public handleRowSelection(e) {
    const rowID = e.newSelection[0].ID;
    const callback = this.notificationConfigurationService.getSubscriptions(rowID);
    callback.then((response: ContractHttpResponse<NotificationSubscription[]>) => {
      if (response.Success) {
        this.selectedConfigID = rowID;
        this.eventSubscriptions = response.Source;
      }
      else {
      }
    });
  }

  public splitter() {
    setTimeout(() => {
      this.gridRef.reflow();
      this.notificationSubscriptionRef.ResizeGrid();
    }, 300);
    this.show = !this.show;
  }

  public dragEnd(e) {
    setTimeout(() => {
      this.gridRef.reflow();
      this.notificationSubscriptionRef.ResizeGrid();
    }, 300);
  }

  private loadNotificationModes() {
    this.notificationModes.push({ Text: NotificationConstants.Modes.Email, Value: NotificationConstants.Modes.Email });
    this.notificationModes.push({ Text: NotificationConstants.Modes.InApp, Value: NotificationConstants.Modes.InApp });
    this.notificationModes.push({ Text: NotificationConstants.Modes.SMS, Value: NotificationConstants.Modes.SMS });
    this.notificationModes.push({ Text: NotificationConstants.Modes.Push, Value: NotificationConstants.Modes.Push });
  }

  editEvent(cell: IgxGridCellComponent){
    this.router.navigate(['NotificationEventConfigurations/Edit/', cell.cellID.rowID.ID]);
  }
}
