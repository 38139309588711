import { Component, OnInit, ElementRef, ContentChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'lib-ssi-overlay',
  templateUrl: './ssi-overlay.component.html',
  styleUrls: ['./ssi-overlay.component.scss']
})
export class SsiOverlayComponent implements OnInit {
  elementRef: ElementRef;
  isOverlayActive = false;
  clickOutsideEnabled = false;
  @Input() closeOnOutsideClick = true;
  @ContentChild('Header', { static: false }) Header: TemplateRef<ElementRef>;
  @Output() closeOverlay: EventEmitter<any> = new EventEmitter();
  @Output() outsideClicked: EventEmitter<any> = new EventEmitter();
  @Output() toggleOverlayEvent: EventEmitter<any> = new EventEmitter();
  @Input() showInOverlay = true;
  @Input() title: string;
  @Input() titleIcon: string;
  @Input() isFluid: Boolean = false;
  @Input() isHorizontal: Boolean = false;
  @Input() isModal: Boolean = false;
  @Input() showCrossIcon: Boolean = false;
  @Input() excludedClassesFromOutsideClick = [];
  @Input() width;
  @Input() autoCloseOverlay: boolean = true;
  isExpanded: Boolean = false;
  isExpandedX: Boolean = false;

  constructor(myElement: ElementRef) {
    this.elementRef = myElement;
  }
  ngOnInit() {
  }

  public showOverlay() {
    setTimeout(() => {
      this.clickOutsideEnabled = true;
      this.isOverlayActive = true;
    }, 100);
  }

  public hideOverlay() {
    this.isOverlayActive = false;
    this.isExpanded = false;
    this.isExpandedX = false;
    this.clickOutsideEnabled = false;
    this.closeOverlay.emit();
  }
  public onClickedOutside() {
    if (this.isOverlayActive && this.closeOnOutsideClick) {
      if (this.autoCloseOverlay) {
        this.hideOverlay();
      } else {
        this.outsideClicked.emit();
      }
    }
  }

  public toggleOverlay() {
    setTimeout(() => {
      this.isExpanded = !this.isExpanded;
      this.toggleOverlayEvent.emit();
    }, 50);
  }

  public toggleOverlayX() {
    setTimeout(() => {
      this.isExpandedX = !this.isExpandedX;
    }, 50);
  }
}

