<!-- <h1 class="h1">Personal Info</h1> -->
<div class="portlet portlet__wrap">
  <div class="ssi-formGroup">
    <div class="btn-holder">
      <ul class="btnlist">
        <li><button tabindex="7" igxButton="flat" type="submit" class="btn-primary" (click)="onSave()"
            *ngIf="permissionMangerService.checkPermission('EDIT_USER')" ssiTooltip="{{'SAVE_BTN_TTP' | ssiTranslator }}">
            <span class="icon icon-save"></span>{{'SAVE_BTN' | ssiTranslator }}
          </button>
        </li>
        <!-- <li><button tabindex="{{ permissionMangerService.checkPermission('EDIT_USER') ? 8 : 7}}" igxButton="flat"
            type="submit" (click)="onCancel()" ssiTooltip="Cancel">
            <span class="icon icon-times-circle"></span>Cancel
          </button>
        </li> -->
      </ul>
    </div>
    <div class="ssi-FourColForm">
      <div class="row">
        <div class="col control-holder">
          <igx-input-group>
            <label igxLabel for="Login">{{'LOGIN_NAME_LBL' | ssiTranslator }}</label>
            <input igxFocus="true" tabindex="1" igxInput ngModel [(ngModel)]="model.LoginName" name="LoginName"  autocomplete="none"
              type="text" [required]="true" [readonly]="model.ID > 0? 'readonly':null" />
          </igx-input-group>
        </div>

        <!-- commented for CCH -->
        <!-- <div class="col control-holder">
          <igx-input-group>
            <label igxLabel for="Title">{{'TITLE_LBL' | ssiTranslator }}</label>
            <input tabindex="2" igxInput ngModel [(ngModel)]="model.Title" name="Title" type="text" [required]="true" />
          </igx-input-group>
        </div> -->
        <div class="col control-holder">
          <igx-input-group>
            <label igxLabel for="Email">{{'EMAIL_LBL' | ssiTranslator }}</label>
            <input tabindex="3" igxInput ngModel [(ngModel)]="model.Email" name="Email" type="email"  autocomplete="none"
              [required]="true" />
          </igx-input-group>
        </div>
        <div class="col control-holder">
          <igx-input-group>
            <label igxLabel for="FirstName">{{'FIRST_NAME_LBL' | ssiTranslator }}</label>
            <input tabindex="4" igxInput ngModel [(ngModel)]="model.FirstName" name="FirstName" type="text"  autocomplete="none"
              [required]="true" />
          </igx-input-group>
        </div>

        <div class="col control-holder">
          <igx-input-group>
            <label igxLabel for="MiddleName">{{'MIDDLE_NAME_LBL' | ssiTranslator }}</label>
            <input tabindex="5" igxInput ngModel [(ngModel)]="model.MiddleName" name="MiddleName" type="text"  autocomplete="none"/>
          </igx-input-group>
        </div>
        <div class="col control-holder">
          <igx-input-group>
            <label igxLabel for="LastName">{{'LAST_NAME_LBL' | ssiTranslator }}</label>
            <input tabindex="6" igxInput ngModel [(ngModel)]="model.LastName" name="LastName" type="text"  autocomplete="none"
              [required]="true" />
          </igx-input-group>
        </div>

        <div class="col control-holder d-none">
          <lib-ssi-select label="{{'COMPANY_LBL' | ssiTranslator }}" name="CompanyName" [items]="companies"
            itemKey="ID" itemText="Name" [singleSelect]="true" [(singleModel)]="model.CompanyID" [disabled]="true">
          </lib-ssi-select>
        </div>

        <div class="col control-holder d-none">
          <lib-ssi-select label="{{'TEAM_LBL' | ssiTranslator }}" name="TeamName" [items]="teams"
            itemKey="ID" itemText="Name" [singleSelect]="true" [(singleModel)]="model.TeamID" [disabled]="true">
          </lib-ssi-select>
        </div>
      </div>
    </div>
  </div>
</div>