import { Injectable } from '@angular/core';
import { SortingDirection } from 'igniteui-angular';
import { ActivatedRoute } from '@angular/router';
import { BaseApiService } from './base-api-service';
import { IWebApiProxy } from '../../ServiceInterfaces/WebApi/iweb-api-proxy';
import { WebApiProxyFactoryService } from '../../WebApiClient/web-api-proxy-factory.service';
import { Permission } from '../../Authentication/permission';
import { IOrderColumn } from '../../Infrastructure/Models/order-column.model';

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends BaseApiService<Permission> {
  private proxy: IWebApiProxy;
  constructor(private proxyFactory: WebApiProxyFactoryService) {
    super();
    this.proxy = this.proxyFactory.GetProxy('Permission');
  }

  registerRoutes(route: ActivatedRoute) {
    throw new Error('Method not implemented.');
  }

  get(id: number): Promise<any> {
    return this.proxy.Get('Get', {
      id
    });
  }

  getPaged(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any, orderColumns?: IOrderColumn[]): Promise<any> {
    return this.proxy.Get('GetPaged', {
      key: filter.key ? filter.key : '',
      type: filter.type ? filter.type : '',
      searchText: filter.searchText ? filter.searchText : '',
      pageIndex,
      pageSize,
      orderBy: orderBy ? orderBy : "",
      orderDirection: (!orderDir || orderDir === 0) ? 0 : orderDir - 1,
    }, null, null, orderColumns);
  }

  getAll(): Promise<any> {
    return this.proxy.Get('GetAll');
  }

  validate(obj: Permission): boolean {
    return true;
  }

  saveOrUpdate(obj: Permission): Promise<any> {
    throw new Error('Method not implemented.');
  }

  delete(id: number): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getForUserByClientCode(userId: number, clientCode: string): Promise<any> {
    return this.proxy.Get('GetForUserByClientCode', { userId, clientCode }, false, false);
  }

}
