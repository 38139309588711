import { Injectable } from '@angular/core';
import { SortingDirection } from 'igniteui-angular';
import { ActivatedRoute } from '@angular/router';
import { BaseApiService } from './base-api-service';
import { IWebApiProxy } from '../../ServiceInterfaces/WebApi/iweb-api-proxy';
import { WebApiProxyFactoryService } from '../../WebApiClient/web-api-proxy-factory.service';
import { MessageInfo } from '../../Infrastructure/Models/message-info.model';
import { IOrderColumn } from '../../Infrastructure/Models/order-column.model';

@Injectable({
  providedIn: 'root'
})
export class MessageInfoService extends BaseApiService<MessageInfo> {
  private proxy: IWebApiProxy;
  constructor(private proxyFactory: WebApiProxyFactoryService) {
    super();
    this.proxy = this.proxyFactory.GetProxy('MessageInfo');
  }

  registerRoutes(route: ActivatedRoute) {
    throw new Error('Method not implemented.');
  }

  get(id: number): Promise<any> {
    return this.proxy.Get('Get', {
      id
    });
  }

  getPaged(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any, orderColumns?: IOrderColumn[]): Promise<any> {
    return this.proxy.Get('GetPaged', {
      key: filter.key ? filter.key : '',
      type: filter.type ? filter.type : '',
      searchText: filter.searchText ? filter.searchText : '',
      pageIndex,
      pageSize,
      orderBy: orderBy ? orderBy : "",
      orderDirection: (!orderDir || orderDir === 0) ? 0 : orderDir - 1,
    }, null, null, orderColumns);
  }

  getAll(): Promise<any> {
    return this.proxy.Get('GetAll');
  }

  getAllTexts(appCode: string): Promise<any> {
    return this.proxy.Get('GetAllTexts',{appCode});
  }

  validate(obj: MessageInfo): boolean {
    return true;
  }

  saveOrUpdate(obj: MessageInfo): Promise<any> {
    throw new Error('Method not implemented.');
  }

  save(obj: MessageInfo[]): Promise<any> {
    return this.proxy.Post('AddBulk', obj);
  }

  update(obj: MessageInfo): Promise<any> {
    return this.proxy.Post('UpdateMessage', obj);
  }

  delete(id: number): Promise<any> {
    return this.proxy.Get('DeleteMessageKey', {
      id
    });
  }

  getTexts(keyId: number): Promise<any> {
    return this.proxy.Get('GetTextsByKey', { messageKeyId: keyId });
  }
}
