import { Component, OnInit, ElementRef, Input } from '@angular/core';
@Component({
  selector: 'lib-cal-month-view',
  templateUrl: './month-view.component.html',
  styleUrls: ['./month-view.component.scss']
}

) export class MonthViewComponent implements OnInit {

  @Input() monthTemplate: ElementRef;
  @Input() activeDates;
  @Input() tileData: Map<string, any>;
  @Input() currentDate;
  @Input() displayTileExtensionButton = true;
  @Input() tileExpandTemplate: ElementRef;
  @Input() tileCollapseTemplate: ElementRef;

  isRowExpanded: boolean[] = [false, false, false, false, false, false];

  constructor() { }

  ngOnInit() {
  }

  public getData(date: Date) {
    if(this.tileData.size){
      return this.tileData.get(date.toDateString());
    }
  }

  public onTileExpanded(index) {
    if (!this.isRowExpanded[index]) {
      this.isRowExpanded[index] = true;
    } else {
      this.isRowExpanded[index] = false;
    }
  }

  public isCurrentMonthDate(date): boolean {
    if (this.currentDate.year() === date.year() && this.currentDate.month() === date.month()) {
      return true;
    }
    return false;
  }


}

