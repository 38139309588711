<div class="cal-month-view">
  <div class="cal-days">
    <div *ngFor="let week of activeDates; let i = index;" class="row no-gutters cal-cell-row" [ngClass]="{'rowExpanded': isRowExpanded[i]}">

      <!-- apply class 'currentDate' for today's date -->
      <div class="col cal-cell"
        [ngClass]="{'activeDate': isCurrentMonthDate(day), 'inactiveDate': !isCurrentMonthDate(day)}"
        *ngFor="let day of week">
        <lib-cal-day-tile *ngIf="monthTemplate; else default " [template]="monthTemplate" [data]="getData(day.toDate())"
          [date]="day.toDate()" [parentId]="i" (notifyOnTileExpanded)="onTileExpanded($event)"
          [displayTileExtensionButton]="displayTileExtensionButton" [tileExpandTemplate]="tileExpandTemplate" [tileCollapseTemplate]="tileCollapseTemplate" [isRowExpanded]="isRowExpanded" >
        </lib-cal-day-tile>
        <ng-template #default>
          <lib-cal-day-tile [template]="defaultDay" [data]="getData(day)" [date]="day.toDate()" [parentId]="i"
            (notifyOnTileExpanded)="onTileExpanded($event)" [displayTileExtensionButton]="displayTileExtensionButton">
          </lib-cal-day-tile>
        </ng-template>
      </div>
    </div>
  </div>
</div>



<!-- Default Template -->
<ng-template #defaultDay let-date='date'>
  <div class="tile">{{date.getDate()}}</div>
  <br>
  <br>
  <br>
  <br>
  <br>
</ng-template>
