import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IgxDialogComponent, IgxGridComponent, IgxGridCellComponent } from 'igniteui-angular';
import { UtilityService, CommonMessageKeys, PermissionManagerService, GridRowActionItem } from 'ssi-framework';
import { NotificationSubscription } from '../../models/notification-subscription';
import { NotificationConfigurationService } from '../../services/notification-configuration.service';

@Component({
  selector: 'lib-ssi-notification-subscription-lv',
  templateUrl: './notification-subscription-lv.component.html',
  styleUrls: ['./notification-subscription-lv.component.scss']
})
export class NotificationSubscriptionLVComponent implements OnInit {

  @Input() subscriptions: NotificationSubscription[];
  @Input() eventConfigID: number;

  items: GridRowActionItem[] = [
    new GridRowActionItem(this, 'unsubscribe', 'Unsubscribe', 'UPDATE_NOTIFICATIONSUBSCRIPTION', 'unsubscribe', 'times-circle'),
  ];

  @ViewChild('FWNotificationSubscriptionGrid', {static: false}) public subscriptionGrid: IgxGridComponent

  constructor(private utilityService: UtilityService, public permissionManagerService: PermissionManagerService,
    private notificationConfigService: NotificationConfigurationService, public commonMessageKeys: CommonMessageKeys) { }

  ngOnInit() {
  }
  ResizeGrid(){
    this.subscriptionGrid.reflow();
  }
  unsubscribe(cell: IgxGridCellComponent){
    const deleteMsg = this.utilityService.displayConfirmationMessage('Are you sure you want to unsubscribe?');
    if (deleteMsg) {
      deleteMsg.onConfirm.subscribe((dialog: IgxDialogComponent) => {
        const callback= this.notificationConfigService.deleteSubscription(cell.cellID.rowID)
        callback.then((response: any) => {
          if (response.Success === true) {
            this.utilityService.displaySuccessMessage(this.commonMessageKeys.RECORD_DELETED_SUCCESSFULLY);
            this.subscriptionGrid.deleteRow(cell.cellID.rowID);
          }
          else {
            this.utilityService.displayErrorMessage(response.Message);
          }
        });
      });
    }
  }
}
