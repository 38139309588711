export class InformationConstants {
	static Lookups = class {};
	static LoadTypeLookupValues = class {
		public static get OUTBOUND(): number {
			return 1;
		}
		public static get INBOUND(): number {
			return 2;
		}
		public static get ASSET(): number {
			return 3;
		}
	};
	static LookupCategories = class {
		public static get ExternalCompanyTypes(): string {
			return 'COMPANY_TYPE';
		}
		public static get AWS_LANGUAGES(): string {
			return 'AWS_LANGUAGES';
		}
		public static get Trailer_Types(): string {
			return 'TRAILER_TYPES';
		}
		public static get Driver_Status(): string {
			return 'DRIVER_STATUS';
		}

		public static get LOAD_TYPES(): string {
			return 'LOAD_TYPES';
		}
		public static get INBOUND_DROP_LOAD_WORKFLOW(): string {
			return 'INBOUND_DROP_LOAD_WORKFLOW';
		}
		public static get INBOUND_LIVE_LOAD_WORKFLOW(): string {
			return 'INBOUND_LIVE_LOAD_WORKFLOW';
		}
		public static get OUTBOUND_LIVE_LOAD_WORKFLOW(): string {
			return 'OUTBOUND_LIVE_LOAD_WORKFLOW';
		}
		public static get OUTBOUND_PRELOAD_WORKFLOW(): string {
			return 'OUTBOUND_PRELOAD_WORKFLOW';
		}

		public static get LOAD_TYPE(): string {
			return 'LOAD_TYPES';
		}
		public static get TRAILER_TYPE(): string {
			return 'TRAILER_TYPES';
		}
		public static get TRIP_STATUS(): string {
			return 'TRIP_STATUS';
		}
		public static get VEHICLE_WEIGHT(): string {
			return 'VEHICLE_WEIGHT';
		}
		public static get EMISSION_TYPE(): string {
			return 'EMISSION_TYPE';
		}
		public static get VEHICLE_CLASS(): string {
			return 'VEHICLE_CLASS';
		}

		public static get VEHICLE_STATUS(): string {
			return 'VEHICLE_STATUS';
		}
		public static get VISITOR_STATUS(): string {
			return 'VISITOR_STATUS';
		}
		public static get YARD_ASSETS(): string {
			return 'YARD_ASSETS';
		}
		public static get MOVE_REQUEST_STATUS(): string {
			return 'MOVE_REQUEST_STATUS';
		}
		public static get YARD_ASSET_STATUSES(): string {
			return 'YARD_ASSET_STATUSES';
		}
		public static get YARD_ASSET_CHECK(): string {
			return 'YARD_ASSET_CHECK';
		}
		public static get OUTBOUND_LOAD_NUMBERS(): string {
			return 'OUTBOUND_LOAD_NUMBERS';
		}
		public static get INBOUND_LOAD_NUMBERS(): string {
			return 'INBOUND_LOAD_NUMBERS';
		}
		public static get HIGH_PRIORITY_COMMENT(): string {
			return 'HIGH_PRIORITY_COMMENT';
		}

		public static get USERPROFILE_CREDENTIALS(): string {
			return 'USERPROFILE_CREDENTIALS';
		}

		public static get TRIP_STOPOVER(): string {
			return 'TRIP_STOPOVER';
		}

		public static get FUEL_TYPE(): string {
			return 'FUEL_TYPE';
		}
		public static get ASSET_STATE(): string {
			return 'ASSET_STATE';
		}
	};
	static OTPRequiredOnDriverCheckinOptions = class {
		public static get NotRequired(): number {
			return 0;
		}
		public static get Required(): number {
			return 1;
		}
		public static get PartiallyRequired(): number {
			return 2;
		}
		public static get RequiredOneTime(): number {
			return 3;
		}
		public static get PartiallyRequiredOneTime(): number {
			return 4;
		}
		public static get DRIVER_STATUS(): string {
			return 'DRIVER_STATUS';
		}
		public static get TRIP_STATUS(): string {
			return 'TRIP_STATUS';
		}
		public static get FUEL_TYPE(): string {
			return 'FUEL_TYPE';
		}
	};
	public static title = [
		{ ID: '', Name: '--Select Title--' },
		{ ID: 'Mr.', Name: 'Mr.' },
		{ ID: 'Ms.', Name: 'Ms.' },
		{ ID: 'Mrs.', Name: 'Mrs.' },
	];
	public static gender = [
		{ ID: '', Name: '--Select Gender--' },
		{ ID: 'Male', Name: 'Male' },
		{ ID: 'Female', Name: 'Female' },
		{ ID: 'Other', Name: 'Other' },
	];
	public static defaultPages = [
		{ ID: '', Name: '--Select Default Landing Page--' },
		{ ID: 'home', Name: 'Home' },
		{ ID: 'LoadingDock/Loads', Name: 'Loads' },
		{ ID: 'LoadingDock/Trips', Name: 'Trips' },
		{ ID: 'LoadingDock/Schedules', Name: 'Schedules' },
		{ ID: 'LoadingDock/requests', Name: 'Requests' },
		{ ID: 'Information/Organizations', Name: 'Organizations' },
		{ ID: 'Information/Companies', Name: 'Companies' },
		{ ID: 'Information/UserProfiles', Name: 'User Management' },
		{ ID: 'Information/events', Name: 'Events' },
		{ ID: 'Roles', Name: 'Roles' },
	];

  static SystemDefinedTempleteCodes = class {
		public static get INVALID_CDL_IMAGE(): string {
			return 'INVALID_CDL_IMAGE';
		}
  }
}
