<div class="main-content-holder row">
  <div class="col p0">
    <igx-grid #FWNotificationSubscriptionGrid id="FWNotificationSubscriptionGrid" ssiGrid [enableDefault]="true"
      [actionItems]="items" [enablePaging]="false" [enableColumnHiding]="false" [enableFiltering]="false"
      [data]="subscriptions" [primaryKey]="'ID'">
      <igx-checkbox field="ID"></igx-checkbox>
      <igx-column field="LoginName" header="Login Name">
      </igx-column>
      <igx-column></igx-column>
      <ng-template igxToolbarCustomContent let-igxGrid="grid">
        <div class="action-area">
          <ul class="btn-action-list">
            <li *ssiPermission="'UPDATE_NOTIFICATIONSUBSCRIPTION'">
              <a [routerLink]="['Subscriptions', eventConfigID]"
                *ngIf="eventConfigID != 0 && eventConfigID != undefined">
                <button igxButton class="btn-primary">
                  <span class="icon icon-add"></span> Add
                </button>
              </a>
            </li>
          </ul>
        </div>
      </ng-template>
    </igx-grid>
  </div>
</div>