<div class="main-content-holder row grid-notifications grid-row-selection">
  <div class="col p0">
    <igx-grid #FWNotificationConfigurationGrid id="FWNotificationConfigurationGrid" ssiGrid [enableDefault]="true"
      [actionItems]="items" [rowSelection]="'single'" (onRowSelectionChange)="handleRowSelection($event)"
      [hideRowSelectors]="true">
      <igx-column field="EventCode" header="{{'CODE_LBL' | ssiTranslator }}">
      </igx-column>
      <igx-column field="EventName" header="{{'NAME_LBL' | ssiTranslator }}"></igx-column>
      <igx-column field="NotificationMode" header="{{'NOTIFICATION_MODE_LBL' | ssiTranslator }}"></igx-column>
      <igx-column field="SubjectTemplate" header="{{'SUBJECT_LBL' | ssiTranslator }}"></igx-column>
      <igx-column field="MessageTemplate" header="{{'MSG_LBL' | ssiTranslator }}"></igx-column>
      <igx-column></igx-column>
      <ng-template igxToolbarCustomContent let-igxGrid="grid">
        <div class="action-area">
          <ul class="btn-action-list">
            <li>
              <!-- <button igxButton (click)="splitter()"><span class="icon icon-info"></span>
                {{'SHOW_SUBSCRIBERS_BTN' | ssiTranslator }}
              </button> -->
            </li>
          </ul>
        </div>

        <lib-ssi-filter #ssiGridFilter class="filter" (search)="onApplyFilter($event)"
          (saveAndSearch)="onSaveAndApplyFilter()" (resetSearch)="onResetFilter()">
          <div class="ssi-TwoColForm">
            <div class="row">
              <div class="col control-holder">
                <igx-input-group>
                  <label igxLabel for="eventCode">{{'CODE_LBL' | ssiTranslator }}</label>
                  <input igxInput name="eventCode" type="text" autocomplete="none" />
                </igx-input-group>
              </div>
              <div class="col control-holder">
                <lib-ssi-select label="Notification Modes" name="modes"
                  [items]="notificationModes" itemKey="Value" itemText="Text">
                </lib-ssi-select>
              </div>
            </div>
          </div>
        </lib-ssi-filter>
      </ng-template>
    </igx-grid>
  </div>
</div>

<lib-ssi-notification-subscription-lv #NotificationSubscriptionRef [subscriptions]="eventSubscriptions"
  [eventConfigID]="selectedConfigID">
</lib-ssi-notification-subscription-lv>