import { Injectable } from '@angular/core';
import { userorganizationmapping } from '../models/userorganizationmapping.model';
import {
	WebApiProxyFactoryService,
	IWebApiProxy,
	BaseApiService,
	SsiDropdownItem,
	PermissionManagerService,
	IOrderColumn,
} from 'ssi-framework';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { SortingDirection } from 'igniteui-angular';
import { permission } from '../models/permission.model';
import { Subject } from 'rxjs';
import { Organization } from 'src/app/information/models/organization.model';

@Injectable({
	providedIn: 'root',
})
export class UserAffiliationsService extends BaseApiService<userorganizationmapping> {
	private subject = new Subject<any>();
	private readonly proxy: IWebApiProxy;
	private readonly roleProxy: IWebApiProxy;
	private reRenderComp: boolean = false;
	private api: IWebApiProxy;
	constructor(private proxyFactory: WebApiProxyFactoryService, private permissionService: PermissionManagerService) {
		super();
		this.proxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'UserOrganizationMapping');
		this.roleProxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'WrapperRole');
	}

	getPaged(
		pageIndex: number,
		pageSize: number,
		orderBy: string,
		orderDir: SortingDirection,
		filter: any,
		orderColumns?: IOrderColumn[]
	): Promise<any> {
		throw new Error('Method not implemented.');
	}
	getAll(): Promise<any> {
		throw new Error('Method not implemented.');
	}
	validate(obj: userorganizationmapping): boolean {
		throw new Error('Method not implemented.');
	}
	saveOrUpdate(obj: userorganizationmapping): Promise<any> {
		throw new Error('Method not implemented.');
	}
	delete(id: number): Promise<any> {
		throw new Error('Method not implemented.');
	}

	public registerRoutes(route: ActivatedRoute) {}

	public getAffiliationByUserID(id: number): Promise<any> {
		const gorupedRoles = new Promise((resolve) => {
			this.api = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'UserOrganizationMapping');
			const request = this.api.Get(`${id}/GroupedByRoles`, false);
			request.then((response: any) => {
				if (response.Success === true) {
					resolve(response);
				}
			});
		});
		return gorupedRoles;
	}

	public get(id: number): Promise<Organization> {
		this.api = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'Organization');
		return this.proxy.Get(`${id}`);
	}

	//let request = this.proxy.Get();

	// let request = this.proxy.Get(`GetUserOrganizationMappingGroupedByRoleID`,{

	//     userId: 1
	// });

	public getFilteredByDistrictAndUser(id: number, districtName: string): Promise<any> {
		return this.proxy.Get(`Search`, {
			userId: id,
			districtName: districtName,
		});
	}
	public FilterAffiliation(id: number, searchText: string): Promise<any> {
		return this.proxy.Get(`SearchAffiliation`, {
			userId: id,
			searchText: searchText,
		});
	}

	public PermissionRefreshedHandler(permissionHandlr: string): void {
		if (permissionHandlr === 'PermissionByRoleHandler') {
			this.reRenderComp = true;
		} else {
			this.reRenderComp = false;
		}
	}

	public reRenderComponent(): boolean {
		return this.reRenderComp;
	}

	public getNewAffiliationRoles(roles: string): Promise<any> {
		const request = this.proxy.Get(`PermissionByRoles`, {
			Roles: roles,
		});

		return request;
	}

	public getRoles(companyId: number): Promise<any> {
		const request = this.roleProxy.Get('GetByAppAndCompany', {
			applicationCode: environment.ApplicationCode,
			companyID: companyId,
		});
		return request;
	}
}
