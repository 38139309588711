import { AuthenticateClientService } from 'ssi-framework';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  selectedType: string;

  constructor(private _router: Router, private _authenticateClientService: AuthenticateClientService) { }

  ngOnInit() {
    if (this._authenticateClientService.isAuthenticated()) {
      this._router.navigateByUrl('/home');
    }
  }

  public onNext() {
    if (this.selectedType === 'candidate') {
      this._router.navigate(['/Candidates/Candidate/SignUp']);
    } else {

    }
  }

  public onSelection(state, type) {
    if (state) {
      this.selectedType = type;
    }
  }

}
