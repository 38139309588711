import { Injectable } from '@angular/core';
import {
	BaseApiService,
	IWebApiProxy,
	WebApiProxyFactoryService,
	PermissionManagerService,
	ContractHttpResponse,
} from 'ssi-framework';
import { SortingDirection } from 'igniteui-angular';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Organization } from '../models/organization.model';
import { LoggedInUserInformation } from '../../Common/services/LoggedInUserInformation.service';
import { TimeZoneInformation } from '../models/time-zone-information.model';
import { OrganizationHierarchy } from '../models/organization-hierarcy.model';
import { CommonConstants } from 'src/app/Common/constants/constants';
import { DropdownItem } from '../models/dropdown-item.model';
import { TimeZone } from '../models/time-zone.model';

@Injectable({
	providedIn: 'root',
})
export class OrganizationService extends BaseApiService<Organization> {
	private readonly proxy: IWebApiProxy;
	public timeZoneResponse: any;
	constructor(
		private proxyFactory: WebApiProxyFactoryService,
		private _logedInUserInformation: LoggedInUserInformation,
		private _permissionManagerService: PermissionManagerService
	) {
		super();
		this.proxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'Organization');
	}

	// eslint-disable-next-line
	registerRoutes(route: ActivatedRoute): Promise<Organization> {
		throw new Error('Method not implemented.');
	}

	get(id: number): Promise<Organization> {
		return this.proxy.Get(`${id}`);
	}
	getOrganization(id: number): Promise<ContractHttpResponse<Organization>> {
		return this.proxy.Get(`${id}`);
	}

	getAll(): Promise<Organization> {
		const callback = this.proxy.Get('');
		return callback;
	}
	//eslint-disable-next-line
	validate(organization: Organization): boolean {
		throw new Error('Method not implemented.');
	}

	saveOrUpdate(obj: Organization): Promise<ContractHttpResponse<Organization>> {
		obj.LoginAffiliation = JSON.stringify(this._logedInUserInformation.GetUserAffiliationFromSettings());
		if (obj.ID > 0) {
			return this.proxy.Put(`Update`, obj);
		} else {
			return this.proxy.Post(`Save`, obj);
		}
	}

	//eslint-disable-next-line
	delete(id: number): Promise<Organization> {
		throw new Error('Method not implemented.');
	}

	getOrganizationHierarchy(filter: number): Promise<ContractHttpResponse<OrganizationHierarchy[]>> {
		let allSchoolWithinTenant = false;
		let loginAffiliation = JSON.stringify(this._logedInUserInformation.GetUserAffiliationFromSettings());
		if (this._permissionManagerService.checkPermission(CommonConstants.Permissions.VIEW_ALL_COMPANY_DATA)) {
			loginAffiliation = CommonConstants.Permissions.VIEW_ALL;
		}
		if (
			this._permissionManagerService.checkPermission(
				CommonConstants.Permissions.VIEW_ALL_SUBTENANTS_WITHIN_TENANT
			)
		) {
			allSchoolWithinTenant = true;
		}
		return this.proxy.Get('Search', {
			loginAffiliation: loginAffiliation,
			level: filter == undefined ? 4 : filter,
			AllSchoolWithinTenant: allSchoolWithinTenant,
		});
	}

  getOrganizationHierarchyForUserProfile(filter: number): Promise<ContractHttpResponse<OrganizationHierarchy[]>> {
		let allSchoolWithinTenant = false;
		const loginAffiliation = JSON.stringify(this._logedInUserInformation.GetUserAffiliationFromSettings());

		if (
			this._permissionManagerService.checkPermission(
				CommonConstants.Permissions.VIEW_ALL_SUBTENANTS_WITHIN_TENANT
			)
		) {
			allSchoolWithinTenant = true;
		}
		return this.proxy.Get('Search', {
			loginAffiliation: loginAffiliation,
			level: filter == undefined ? 4 : filter,
			AllSchoolWithinTenant: allSchoolWithinTenant,
		});
	}

	//eslint-disable-next-line
	getPaged(
		//eslint-disable-next-line
		pageIndex: number,
		//eslint-disable-next-line
		pageSize: number,
		//eslint-disable-next-line
		orderBy: string,
		//eslint-disable-next-line
		orderDir: SortingDirection,
		//eslint-disable-next-line
		filter: any
	): Promise<Organization> {
		throw new Error('Method not implemented.');
	}
	getTenants(): Promise<ContractHttpResponse<Organization[]>> {
		let loginAffiliation = JSON.stringify(this._logedInUserInformation.GetUserAffiliationFromSettings());
		if (this._permissionManagerService.checkPermission(CommonConstants.Permissions.VIEW_ALL_COMPANY_DATA)) {
			loginAffiliation = CommonConstants.Permissions.VIEW_ALL;
		}
		return this.proxy.Get('GetTenants', { loginAffiliation: loginAffiliation });
	}

	GetParentOrganizationsByLevel(levelId: number,tenantId:number): Promise<ContractHttpResponse<Organization[]>> {
		let loginAffiliation = this._logedInUserInformation.GetUserAffiliationFromSettings();
		loginAffiliation = JSON.stringify(loginAffiliation);
		if (this._permissionManagerService.checkPermission(CommonConstants.Permissions.VIEW_ALL_COMPANY_DATA)) {
			loginAffiliation = CommonConstants.Permissions.VIEW_ALL;
		}
		return this.proxy.Get('ParentLevel', {
			loginAffiliation: loginAffiliation,
			levelID: levelId,
			districtID: tenantId,
		});
	}
	getTimeZones(): Promise<ContractHttpResponse<TimeZoneInformation[]>> {
		return this.proxy.Get('GetTimeZones');
	}

	getTimeZoneByOrganizationID(organizationID: number): Promise<ContractHttpResponse<TimeZone>> {
		if (this.timeZoneResponse != null) {
			return this.timeZoneResponse;
		} else {
			this.timeZoneResponse = this.proxy.Get('GetTimeZoneInfoForOrganization', {
				organizationID: organizationID,
			});
			return this.timeZoneResponse;
		}
	}
	getByAreaTypeValues(areaTypeValues: string, districtID: number): Promise<ContractHttpResponse<Organization[]>> {
		return this.proxy.Get('GetByDistrictIDAndAreaTypes', {
			districtID: districtID,
			areaTypeValues: areaTypeValues,
		});
	}
	getByOrganizationIDAndAreaTypeValues(
		areaTypeValues: string,
		organizationID: number
	): Promise<ContractHttpResponse<Organization[]>> {
		return this.proxy.Get('GetByOrganizationIDAndAreaTypes', {
			organizationID: organizationID,
			areaTypeValues: areaTypeValues,
		});
	}
	getByParentID(parentID: number): Promise<ContractHttpResponse<Organization[]>> {
		const request = this.proxy.Get('GetByParentID', {
			parentID: parentID,
		});
		return request;
	}
	getByParentIDAnonymous(parentID: number): Promise<ContractHttpResponse<Organization[]>> {
		const request = this.proxy.Get('GetByParentIDAnonymous', {
			parentID: parentID,
		});
		return request;
	}
	getLightAnonymous(id: number): Promise<ContractHttpResponse<Organization>> {
		return this.proxy.Get('GetLightAnonymous', { id: id });
	}
	getAllOrganizationsByLevelAndTenantID(
		tenantID: number,
		areaTypeValue: number
	): Promise<ContractHttpResponse<DropdownItem[]>> {
		return this.proxy.Get('GetByDistrictIDAndAreaTypeForDropdown', {
			tenantID: tenantID,
			areaTypeValue: areaTypeValue,
		});
	}
	loadTimeZoneInformation(): Promise<ContractHttpResponse<TimeZone>> {
		const organizationID = this._logedInUserInformation.GetUserAffiliationFromSettings()[0].OrganizationID;
		return this.proxy.Get('GetTimeZoneInfoForOrganization', {
			organizationID: organizationID,
		});
	}
	loadTimeZoneInformationAnonymous(orgID: string): Promise<ContractHttpResponse<Array<TimeZone>>> {
		const timezone = this.proxy.Get('GetTimeZoneInfoAnonymous', {
			Organizationids: orgID,
		});
		return timezone;
	}
	getOrgChildrenByAreaType(
		organizationID: number,
		areaTypeValue: number
	): Promise<ContractHttpResponse<Organization[]>> {
		return this.proxy.Get('GetOrgChildrenByAreaType', {
			OrganizationID: organizationID,
			areaTypeValue: areaTypeValue,
		});
	}
	GetOrgChildrenByAreaTypeAnonymous(
		organizationID: number,
		areaTypeValue: number
	): Promise<ContractHttpResponse<Organization[]>> {
		return this.proxy.Get('GetOrgChildrenByAreaTypeAnonymous', {
			organizationID: organizationID,
			areaTypeValue: areaTypeValue,
		});
	}

	getDedicatedDoors(
		carrierID:number,
		waregouseID: number,
		allowDedicatedDoor:boolean
	): Promise<ContractHttpResponse<Organization[]>> {
		return this.proxy.Get('GetDedicatedDoors', {
			carrierID: carrierID,
			waregouseID: waregouseID,
			allowDedicatedDoor: allowDedicatedDoor,
		});
	}
	GetDedicatedDoorList(
		OrganizationID: number
		): Promise<ContractHttpResponse<any>> {
		return this.proxy.Get('GetDedicatedDoorList', {
			organizationID: OrganizationID
		});
	}

	SetDoorInUse(
		doorId: number,
		DoorInUse:boolean
		): Promise<ContractHttpResponse<any>> {
		return this.proxy.Get('OccupyDoor', {
			doorId: doorId,
			DoorInUse:DoorInUse

		});
	}
}
