import { Pipe, PipeTransform, Injector } from '@angular/core';
import { SharedConstant } from '../../Helpers/shared-constant';

@Pipe({
  name: 'ssiTranslator'
})
export class SsiTranslatorPipe implements PipeTransform {
  _sharedConstant: SharedConstant;
  constructor(private _injector: Injector) {
    this._sharedConstant = this._injector.get<SharedConstant>(SharedConstant);
  }

  transform(value: any, args?: any[]): any {
    const text = this.getTextByKey(value);
    if (text && args) {
      return this.formatString(text, args);
    }
    if (text == null) {
      return value;
    }
    return text;
  }

  private getTextByKey(key: string): string {
    if (this._sharedConstant.messageTexts) {
      const messageInfos = this._sharedConstant.messageTexts.filter(t => t.messageKey == key);
      return messageInfos != null && messageInfos.length > 0 ? messageInfos[0].messageText : null;
    }
    return null;
  }

  private formatString(str: string, val: any[]): string {
    if (val) {
      for (let index = 0; index < val.length; index++) {
        str = str.replace(`{${index}}`, val[index]);
      }
    }
    return str;
  }

}
