import { Component, ViewChild, TemplateRef, EventEmitter, Inject } from '@angular/core';

@Component({
  selector: 'lib-basic-grid-pager',
  templateUrl: './basic-grid-pager.component.html',
  styleUrls: ['./basic-grid-pager.component.scss']
})

export class BasicGridPagerComponent {

  @ViewChild('basicPager', { read: TemplateRef, static: true })
  public basicPager: TemplateRef<any>;

  public paginateGridCallback: EventEmitter<any>;

  _totalRows: number;
  _pageRows: number;
  _totalPage: number;
  _perPage: number;
  _selectedPage: number;
  _pages: number[];

  constructor(@Inject('EnvironmentVariables') private environment: any) {
    this._pages = environment.GridPages as number[];
  }

  public init(pageRows: number, totalRows: number, selectedPage: number, perPage: number) {
    this._totalRows = totalRows;
    this._pageRows = pageRows;
    this._perPage = perPage;
    this._selectedPage = selectedPage;
    this._totalPage = Math.ceil(totalRows / perPage);
  }

  goToPage(pageNumber: number) {
    if (pageNumber > 0 && pageNumber <= this._totalPage) {
      this._selectedPage = pageNumber;
      this.paginateGridCallback.emit({ page: this._selectedPage, perPage: this._perPage });
    }
  }

  pageSizeChanged(selectedPageSize: number) {
    this._selectedPage = 1;
    this._totalPage = Math.ceil(this._totalRows / selectedPageSize);
    this._perPage = selectedPageSize;
    this.paginateGridCallback.emit({ page: this._selectedPage, perPage: this._perPage });
  }

  get hasFirstPage(): boolean {
    if (this._selectedPage > 1) {
      return false;
    }
    return true;
  }

  get hasPreviousPage(): boolean {
    if (this._selectedPage > 1) {
      return false;
    }
    return true;
  }

  get hasNextPage(): boolean {
    if (this._selectedPage < this._totalPage) {
      return false;
    }
    return true;
  }

  get hasLastPage(): boolean {
    if (this._selectedPage < this._totalPage) {
      return false;
    }
    return true;
  }
}
