import { CompanyService } from './../../Services/company.service';
import { BaseLVComponent, LayoutService, PagedList, CommonMessageKeys, UtilityService, UserSettingsService, GridRowActionItem, SsiTranslatorPipe, IOrderColumn } from 'ssi-framework';
import { Component, ViewContainerRef, ComponentFactoryResolver, ViewChild, Injector } from '@angular/core';
import { Company } from '../../models/company.model';
import { Router, ActivatedRoute } from '@angular/router';
import { SortingDirection, IgxGridComponent, IgxGridCellComponent, IgxDialogComponent } from 'igniteui-angular';


@Component({
  selector: 'ssi-identity-company-lv',
  templateUrl: './company-lv.component.html',
  styleUrls: ['./company-lv.component.scss']
})
export class CompanyLvComponent extends BaseLVComponent<Company> {

  @ViewChild('FWCompanyGrid', {static: false})
  protected gridRef: IgxGridComponent;
  private _ssiTranslatorPipe : SsiTranslatorPipe;

  items: GridRowActionItem [] = [
    new GridRowActionItem(this, 'edit', 'Edit', 'EDIT_COMPANY', 'editCompany','edit'),
    new GridRowActionItem(this, 'delete', 'Delete', 'DEL_COMPANY', 'deleteCompany','trash') ];

  constructor(private layoutService: LayoutService, private _injector: Injector,
     viewRef: ViewContainerRef, componentResolver: ComponentFactoryResolver,
              userSettingsService: UserSettingsService, router: Router, route: ActivatedRoute,
              private companyService: CompanyService, utilityService: UtilityService, commonMessageKeys: CommonMessageKeys) {
    super(viewRef, componentResolver, userSettingsService, utilityService, commonMessageKeys, router, route);
    this._ssiTranslatorPipe = new SsiTranslatorPipe(_injector);

  }

  public setTitle(): void {
    const title = this._ssiTranslatorPipe.transform('MANAGE_COMPANY_TITLE');
    this.layoutService.setTitle(title);
  }

  protected onBeforeLoad(): Promise<any> {
     return new Promise<any>(( resolve, reject) => {
      resolve(true);
    });
  }
  protected onAfterLoad(data: PagedList<Company>) {
    return new Promise<any>(( resolve, reject) => {
      resolve(true);
    });
  }
  protected getData(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any, orderColumns?: IOrderColumn[]): Promise<any> {
    return this.companyService.getPaged(pageIndex, pageSize, orderBy, orderDir, filter, orderColumns);
  }

  private editCompany(cell: IgxGridCellComponent): void {
    this.router.navigate(['Companies/Edit', cell.cellID.rowID.ID]);
  }

  private deleteCompany(cell: IgxGridCellComponent): void {
    const deleteMsg = this.utilityService.displayConfirmationMessage('Are you sure you want to delete the selected Company?');
    if (deleteMsg) {
      deleteMsg.onConfirm.subscribe((dialog: IgxDialogComponent) => {
        const callback = this.companyService.delete(cell.cellID.rowID.ID);
        callback.then((response: any) => {
          if (response.Success === true) {
            this.utilityService.displaySuccessMessage(this.commonMessageKeys.RECORD_DELETED_SUCCESSFULLY);
            this.onApplyFilter();
          }
          else {
            this.utilityService.displayErrorMessage(response.Message);
          }
        });
      });
    }
  }


}
