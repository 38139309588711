import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class CommonMessagingKeys {
	public static readonly ERROR_CONFIRM_PASSWORD_MISMATCH: string = 'Your confirm password does not match password.';
	public static readonly PASSWORD_NOT_MATCHED: string = 'Password does not match.';
	public static readonly OLD_PASSWORD_NOT_MATCHED: string = "'Current Password' is not correct.";
	public static readonly PASSWORD_NOT_CHANGED: string = "The 'New Password' must be different from the 'Current Password'.";
	public static readonly OLD_PASSWORD_REPEAT: string = "You cannot add previous five passwords. Please try new password.";
	public static readonly INVALID_EMAIL_ERROR: string = 'Please enter a valid Email Address.';
	public static readonly INVALID_PHONE_ERROR: string = 'Please enter a valid Phone Number.';
	public static readonly INVALID_ZIP_CODE_ERROR: string = 'Please enter a valid Zip Code.';
	public static readonly INVALID_PASSWORD_ERROR: string =
		'Your Password does not match the criteria. It must contain at least 6 characters, with at least 1 uppercase letter, at least 1 lowercase letter and at least 1 number.';
	public static readonly INVALID_IMAGE_FILE_SELECTED_ERROR: string =
		'Please select a file with a valid image format.';
	public static readonly INVALID_DOCUMENT_FILE_SELECTED_ERROR: string =
		'Please select a file with a document valid format.';
	public static readonly FILE_NOT_SELECTED_ERROR: string = 'Please choose a document to upload.';
	public static readonly DELETE_CONFIRMATION_MESSAGE: string =
		'Are you sure you want to delete the selected record(s)?';
	public static readonly PENDING_CHANGES_ERROR: string = 'Please Save or Cancel changes before moving next.';
	public static readonly EDIT_MODE_PENDING_CHANGES_ERROR: string = 'Please Cancel changes before moving next.';
	public static readonly NO_ATTACHMENT_ERROR: string = 'No Attachment found.';
	public static readonly USER_LOGIN_EXISTS: string = 'User login already exists. Sign in using existing credentials.';
	public static readonly CLICKED_OUT_SIDE: string =
		'Are you sure you want to leave this area? Your changes will not be saved.';
    public static readonly MANDATORY_QUESTIONS_ERROR: string = 'Please mark all the questions to continue.';
	public readonly SET_ContactPerson_SUCCESSFULLY: string = 'Selected employee(s) were Set as Contact Person Successfuly.';

    public readonly SET_PrimaryContactPerson_SUCCESSFULLY: string = 'Selected employee(s) were Set as Primary Contact Person Successfuly.';

    public readonly REMOVE_ContactPerson_SUCCESSFULLY: string = 'Selected employee(s) were Removed as Contact Person Successfuly.';

    public readonly REMOVE_PrimaryContactPerson_SUCCESSFULLY: string = 'Selected employee(s) were Removed as Primary Contact Person Successfuly.';

    public readonly MANDATORY_FIELD_ERROR: string = 'Please fill all mandatory fields.';
	public static FormatString(str: string, val: string[]): string {
		if (val) {
			for (let index = 0; index < val.length; index++) {
				str = str.replace(`{${index}}`, val[index]);
			}
		}
		return str;
	}




}
