import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { IgxInputGroupType } from '@infragistics/igniteui-angular';

@Component({
  selector: 'lib-ssi-text-area',
  templateUrl: './ssi-text-area.component.html',
  styleUrls: ['./ssi-text-area.component.scss']
})
export class SsiTextAreaComponent {
  // Inputs
  @Input() groupType: IgxInputGroupType = 'line';
  @Input() labelOutsideBox = false;
  @Input() label: string;
  @Input() name: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() index: number;
  @Input() cols: number;
  @Input() rows: number;
  @Input() maxlength: number;
  @Input() hint: string;
  @Input() placeholder: string = '';
  @Input() showCharacterCount = false;
  @Input() selectOnFocus = false;
  @Input() isFocused: boolean = false;

  // Outputs
  @Output() private modelChange = new EventEmitter();
  @Output() onKeyUp: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() onKeyDown: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() onChanged: EventEmitter<Event> = new EventEmitter();

  @ViewChild('textArea', { static: false }) textArea: NgModel;
  @ContentChild('labelTemplate', { static: false }) public labelTemplate: TemplateRef<any>;

  // NgModel
  @Input() get model() {
    return this.modelValue;
  }
  set model(val) {
    this.modelValue = val;
    this.modelChange.emit(this.modelValue);
  }

  // Private
  private modelValue: any;

  constructor() { }

  onTextAreaKeyDown(event: KeyboardEvent) {
    this.onKeyDown.emit(event);
  }

  onTextAreaKeyUp(event: KeyboardEvent) {
    this.onKeyUp.emit(event);
  }

  onTextAreaChanged(event: Event) {
    this.onChanged.emit(event);
  }

  reset() {
    this.textArea.control.reset();
  }

}
