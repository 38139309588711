<div class="main-content-holder row">
    <div class="col p0">
        <igx-grid #FWNotificationGrid id="FWNotificationGrid" ssiGrid [enableDefault]="true" [enableFiltering]="true"
            [enablePaging]="false" [enableColumnHiding]="false" [data]="recipients">
            <igx-column field="UserID" header="{{'USER_LBL' | ssiTranslator }}">
            </igx-column>
            <igx-column field="Email" header="{{'EMAIL_LBL' | ssiTranslator }}">
            </igx-column>
            <igx-column field="PhoneNumber" header="{{'PHONE_NUMBER_LBL' | ssiTranslator }}"></igx-column>
            <igx-column field="NotificationStatus" header="Status">
                <ng-template igxCell let-value>
                    {{ firstCap(value) }}
                </ng-template>
            </igx-column>
            <igx-column field="ResponseMessage" header="{{'ERROR_MSG_LBL' | ssiTranslator }}"></igx-column>
            <ng-template igxToolbarCustomContent let-igxGrid="grid">
                <div class="action-area">
                    <ul class="btn-action-list">
                        <li>
                        </li>
                    </ul>
                </div>
            </ng-template>
        </igx-grid>
    </div>
</div>