<div class="row tabs-profile">
  <div class="col-tab-12 col-lg-3 pr-3">
    <div class="portlet portlet-tab-left">
      <div class="row">
        <div class="col">
          <div class="imageHolder">
            <div class="hoverable">
              <img [src]="url ? url : 'assets/images/user.jpg'" width="100%">
              <div class="hover-text">
                <label for="fileInput">
                  <span class="btn-icon">
                    <igx-icon>edit</igx-icon>
                  </span>
                </label>
              </div>
              <input id="fileInput" type='file' (change)="onSelectFile($event)">
            </div>
          </div>
          <div class="infoArea">
            <div class="name">
              {{user?.Name}}
            </div>
            <div class="email">
              {{user?.Email}}
            </div>
          </div>
        </div>
      </div>
      <!-- Additional Info Passed from app -->
      <ng-template *ngIf="additionalInfo != null" [ngTemplateOutlet]="additionalInfo"></ng-template>
    </div>
  </div>
  <div class="col-tab-12 col-lg-9 pl-3">
    <div class="portlet portlet-tab-grid">
      <lib-ssi-tab [tabs]="tabItems" [parentRoute]="parentRoute" [entity]="user">
      </lib-ssi-tab>
          <!-- <igx-tabs #eventTabs (onTabItemSelected)="navigate($event)" [selectedIndex]="selectedIndex">
            <igx-tabs-group *ngFor="let r of routes;">
              <ng-template igxTab>
                {{r.data.title}}
              </ng-template>
            </igx-tabs-group>
          </igx-tabs> -->

      <!-- <h1 class="h1 mb-5 mt-0">{{route.snapshot.firstChild?.routeConfig.data.title}}</h1> -->
      <!-- <div class="tabsHolder mt-5">
        <router-outlet name="ssiTabs"></router-outlet>
      </div> -->
    </div>
  </div>
</div>
