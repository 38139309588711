import { Component, OnInit } from '@angular/core';
import { UserAffiliationsService } from '../../services/UserAffiliations.service';
import { ViewChild, ViewContainerRef, ComponentFactoryResolver, Injector } from '@angular/core';
import { IgxGridComponent, IgxGridCellComponent, SortingDirection, IgxDialogComponent } from 'igniteui-angular';
import {
	ContractHttpResponse,
	BaseLVComponent,
	SsiTranslatorPipe,
	PagedList,
	UserSettingsService,
	CommonMessageKeys,
	UtilityService,
	LayoutService,
	GridRowActionItem,
	PermissionManagerService,
	IOrderColumn,
	UserSettings,
} from 'ssi-framework';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { userorganizationmapping } from '../../models/userorganizationmapping.model';
import { UserContextService } from 'ssi-framework';
import { stringCompare1 } from 'igniteui-angular-core';
import { permission } from '../../models/permission.model';
import { CommonConstants } from 'src/app/Common/constants/constants';
import { userAffilation } from '../../models/userAffiliation';
import { filter, pairwise } from 'rxjs/operators';
import { resolve } from 'path';
import { CachedUserSettingsService } from '../../services/cached-user-settings-service';
import { OrganizationService } from 'src/app/information/services/organization.service';
import { UserProfilesService } from 'src/app/information/services/user-profiles.service';
import { LayoutComponent } from 'src/app/shared/layout/layout.component';

@Component({
	selector: 'app-user-affiliation-lv',
	templateUrl: './user-affiliation-lv.component.html',
	styleUrls: ['./user-affiliation-lv.component.scss'],
})
export class UserAffiliationLvComponent extends BaseLVComponent<userorganizationmapping> {
	_allAffilation: any;
	gridRef: IgxGridComponent;
	userid: any;
	map = new Map<string, any[]>();
	ShowOwnLayout: boolean = false;

	constructor(
		private layoutService: LayoutService,
		viewRef: ViewContainerRef,
		_injector: Injector,
		componentResolver: ComponentFactoryResolver,
		route: ActivatedRoute,
		router: Router,
		userSettingsService: UserSettingsService,
		private userAffilationServices: UserAffiliationsService,
		utilityService: UtilityService,
		commonMessageKeys: CommonMessageKeys,
		private usercontextService: UserContextService,
		private UserContextService: UserContextService,
		private UserSettingsService: UserSettingsService,
		private cachedSettingsService: CachedUserSettingsService,
		public permissionMangerService: PermissionManagerService,
		public organizationService: OrganizationService,
		public userProfileService: UserProfilesService
	) {
		super(viewRef, componentResolver, userSettingsService, utilityService, commonMessageKeys, router, route);

		const title = 'User Affiliation';
		this.layoutService.setTitle(title);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.layoutService.disableBackNavigation();
	}

	// public get userId(): number {
	//   UserSettingsService.
	//   let userId = this.authClient.getClaim('sub');
	//   if (userId == null) {
	//     userId = this.authClient.getClaim(this._nameIdentifier);
	//   }
	//   return userId != null ? userId : 0;
	// }

	protected onAfterLoad(data: PagedList<userorganizationmapping>) {
		throw new Error('Method not implemented.');
	}

	protected getData(
		pageIndex: number,
		pageSize: number,
		orderBy: string,
		orderDir: SortingDirection,
		filter: any,
		orderColumns?: IOrderColumn[]
	): Promise<any> {
		throw new Error('Method not implemented.');
	}

	protected onBeforeLoad(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.getUserOrganizationMapping();
			this.setupHeader();
			resolve(true);
		});
	}

	protected getUserOrganizationMapping() {
		let temp: any[] = [];
		let orgname: any;
		this.userid = this.usercontextService.userId;
		this.userAffilationServices.getAffiliationByUserID(this.userid).then((response: ContractHttpResponse<any>) => {
			if (response.Success === true) {
				this._allAffilation = response.Source;
				console.log(response.Source);
				if (response.Source != null) {
					response.Source.forEach((outer) => {
						outer.forEach((element) => {
							temp.push(element);
							orgname = element.DistrictName;
						});
						this.map.set(orgname, temp);
						temp = [];
					});
				}
			}
		});
	}

	GetFilteredOrganization(searchText: any) {
		let temp: any[] = [];
		let orgname: any;
		this.userid = this.usercontextService.userId;
		this.userAffilationServices
			.FilterAffiliation(this.userid, searchText)
			.then((response: ContractHttpResponse<any>) => {
				this.map.clear();
				let mm = this.map;
				if (response.Success === true) {
					this._allAffilation = response.Source;
					if (response.Source != null) {
						this._allAffilation = response.Source;
						if (response.Source != null) {
							response.Source.Source.forEach((outer) => {
								outer.forEach((element) => {
									temp.push(element);
									orgname = element.DistrictName;
								});
								this.map.set(orgname, temp);
								temp = [];
							});
						}
					}
				}
			});
	}

	protected async GetUserRoles(organizationMapping: any) {
		await this.GetCompeleteRoles(organizationMapping);
	}

	protected async GetCompeleteRoles(organizationMapping: any) {
		//TODO: Zain verify if the permissions cache should be cleared  at this point
		this.userAffilationServices
			.getNewAffiliationRoles(organizationMapping.MultipleIDs)
			.then(async (response: ContractHttpResponse<permission>) => {
				if (response.Success === true) {
					// console.log(response);
					this.permissionMangerService.clearPermissions();
					this.permissionMangerService.setPermissionsFromAccessToken(response.Source);
					const isSettingSaved = await this.SaveUserAffiliationInSettings(organizationMapping);
					this.organizationService.timeZoneResponse = null;
					this.userProfileService.userprofileByDistrict = null;
					if (isSettingSaved) {
						const roles = this.cachedSettingsService.getRolesFromSettings();
						const setPermissionRes = await this.cachedSettingsService.setPermissions(roles);
						if (setPermissionRes.Success == true) {
							this.router.navigate(['home']);
						} else {
							this.utilityService.displayErrorMessage('Some thing went wrong! Please try again.');
						}
					}
				}
			});
	}

	protected async SaveUserAffiliationInSettings(organizationMapping: any) {
		const userAffilations: userAffilation[] = [];
		const Roles = organizationMapping.MultipleIDs.split(',');

		Roles.forEach((element) => {
			const affiliation = new userAffilation();
			affiliation.CompanyID = organizationMapping.CompanyID;
			affiliation.DistrictID = organizationMapping.DistrictID;
			affiliation.OrganizationID = organizationMapping.OrganizationID;
			affiliation.RoleID = element;
			affiliation.ExternalCompanyID = organizationMapping.ExternalCompanyID;
			affiliation.ExternalCompanyName = organizationMapping.ExternalCompanyName;
			affiliation.AllowExternalUserAccess = organizationMapping.AllowExternalUserAccess;
			affiliation.RoleName = organizationMapping.RoleName;
			userAffilations.push(affiliation);
		});

		const userSetting = new UserSettings();

		userSetting.UserId = this.UserContextService.userId;
		userSetting.Code = CommonConstants.CURRENT_LOGIN_AFFILIATION;
		userSetting.Name = 'CURRENT LOGIN AFFILIATION ';
		userSetting.AppCode = this.UserContextService.appCode;
		userSetting.Description = 'CURRENT LOGIN AFFILIATION';
		userSetting.DataType = typeof String.name;
		userSetting.IsDeleted = false;
		userSetting.Value = JSON.stringify(userAffilations);
		// const response: any = await this.UserSettingsService.saveUserSettings(userSetting).then(() => {
		// 	if (response === true) {
		// 		this.cachedSettingsService.updateAffiliationLocally(userSetting.Value);
		// 		this.cachedSettingsService.setUserSettings();
		// 		return true;
		// 	}
		// });
		const response: any = await this.UserSettingsService.saveUserSettings(userSetting);
		if (response === true) {
			const responseSet: any = await this.cachedSettingsService.setUserSettings();
			if (responseSet.Success != true) {
				this.utilityService.displayErrorMessage('Some thing went wrong! Please try again.');
				return false;
			}
			const delay = await this.delay();
			if (!delay) {
				this.utilityService.displayErrorMessage('Some thing went wrong! Please try again.');
				return false;
			}
			return true;
		} else {
			this.utilityService.displayErrorMessage('Some thing went wrong! Please try again.');
			return false;
		}
	}

	// protected test(test2): string {

	// 	return JSON.stringify(test2);
	// }

	public async setupHeader() {
		this.router.events
			.pipe(
				filter((evt: any) => evt instanceof RoutesRecognized),
				pairwise()
			)
			.subscribe((events: RoutesRecognized[]) => {
				if (events[0].urlAfterRedirects == '/login') {
					this.ShowOwnLayout = true;
				} else {
					this.ShowOwnLayout = false;
				}
				// console.log('previous url', events[0].urlAfterRedirects);
				// console.log('current url', events[1].urlAfterRedirects);
			});
	}
	trackByFn(index, item) {
		return item.key;
	}

	delay() {
		return new Promise((resolve) => {
			setTimeout(async () => {
				const responseGet: any = await this.cachedSettingsService.getUserAffiliationSetting();
				if (responseGet == true) {
					resolve(true);
				} else {
					resolve(false);
				}
			}, 500);
		});
	}
}
