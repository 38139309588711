import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DocViewType } from '../../../Infrastructure/Models/docViewerType.model';

@Component({
  selector: 'lib-ssi-doc-viewer',
  templateUrl: './ssi-doc-viewer.component.html',
  styleUrls: ['./ssi-doc-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SsiDocViewerComponent implements OnInit {

  @Input() url? : string;
  @Input() viewType? : string;
  @Input() base64_File? : string;
  @Input() fileExtention? : string;
  @Input() Content_Loaded : boolean = false;
  @Input() disableTool = 'none';
  @Input() width = 900;
  @Input() height = 500;

  @Output() ContentLoaded: EventEmitter<any> = new EventEmitter(); 
  contentType: string;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void{
    if (this.base64_File != '') {

      if (this.fileExtention == 'pdf') {
       this.contentType = "application/pdf "
      }else if (this.fileExtention == 'docx'){
        this.contentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      }else if (this.fileExtention == 'jpeg' || this.fileExtention == 'png' || this.fileExtention == 'jpg' ||
      this.fileExtention == 'jpe' || this.fileExtention == 'bmp'){
        this.contentType = "image/"+this.fileExtention+""
      }

      const b64toBlob = (b64Data, contentType = '', sliceSize = 2048) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
          const byteNumbers = new Array(slice.length);

          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
      }
      const blob = b64toBlob(this.base64_File, this.contentType);
    
      const blobUrl = URL.createObjectURL(blob);
      this.url = blobUrl   
    }
  }

  ngOnInit(): void {
    
  }

  fileLoaded(event){
    this.ContentLoaded.emit(event)
  }
  

}
