import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMessagingKeys } from 'src/app/Common/services/common-messaging-keys.service';
import { HelperService } from 'src/app/Common/services/helper.service';
import { QuickUserCreation } from 'src/app/information/models/quick-user-creation.model';
import { UserProfileDetail } from 'src/app/information/models/user-profile-detail.model';
import { UserProfile } from 'src/app/information/models/user-profile.model'; // Adjust the path to the UserProfile model
import { UserProfilesService } from 'src/app/information/services/user-profiles.service'; // Adjust the path to the UserProfilesService
import {
	ContractHttpResponse,
	BaseAEComponent,
	RouteHandlerService,
	UtilityService,
	CommonMessageKeys,
	LayoutService,
	UserContextService,
} from 'ssi-framework';
import { LoadsService } from 'src/app/loading-dock/services/loads.service';
import { ShipmentTypes } from 'src/app/Common/constants/lookup-constants';
import { Organization } from 'src/app/information/models/organization.model';
import { OrganizationService } from 'src/app/information/services/organization.service';
import { CompanyService } from 'src/app/information/services/company-service';
import { Company } from 'src/app/information/models/company-model';
import { PROGRESS_STATUS } from '../login-layout/login/login.component';
import { LookupsService } from 'src/app/information/services/lookups.service';
import { InformationConstants } from 'src/app/information/common/information-constants';
import { Lookup } from 'src/app/information/models/lookup.model';

@Component({
	selector: 'app-signup-external',
	templateUrl: './signup-external.component.html',
	styleUrls: ['./signup-external.component.scss'],
})
export class SignupExternalComponent implements OnInit {
	// Fields for the signup form
	@Output()
	SignupProgressStatusEmitter = new EventEmitter();

	isDataLoaded: boolean = false;
	userProfiles: UserProfile = new UserProfile();
	newUser: QuickUserCreation = new QuickUserCreation();
	userProfileDetail: UserProfileDetail = new UserProfile();
	Email: string = '';
	FirstName: string = '';
	LastName: string = '';
	Username: string = '';
	Password: string = '';
	externalCompanyID: number = 0;
	confirmPassword: string = '';
	passwordType: string = 'password';
	confirmPasswordType: string = 'password';
	disableNameFields: boolean = false;
	disableSignup: boolean = false;
	showLogin: boolean = false;
	redirectUrl: string = '';
	loadIds: Number[] = [];
	loadLookups: Lookup[] = [];
	Role: Lookup[] = [];
	showEmail: boolean = true;
	route: ActivatedRoute;
	router: Router;
	loadDetail: { OrganizationID: Number, organizationName: string, Role: string, ExternalCompanyID: number, ExternalCompanyName: String, Tenant: String } = { OrganizationID: 0, organizationName: "", Role: "", ExternalCompanyID: 0, ExternalCompanyName: "", Tenant: "" };
	organizationID: number = 0;
	protected _utilityService: UtilityService;
	protected CommonMessagingKeys: CommonMessageKeys;

	constructor(
		routeHandlerService: RouteHandlerService,
		route: ActivatedRoute,
		private _layoutService: LayoutService,
		utilityService: UtilityService,
		commonMessageKeys: CommonMessageKeys,
		_injector: Injector,
		router: Router,
		public userContextService: UserContextService,
		private _userProfilesService: UserProfilesService, // Inject the UserProfilesService
		private _loadService: LoadsService,
		private _organizationService: OrganizationService,
		private _companyService: CompanyService,
		private _lookupService: LookupsService
	) {
		this._utilityService = utilityService;
		this.CommonMessagingKeys = commonMessageKeys;
		this.route = route;
		this.router = router;
	}

	ngOnInit(): void {
		const queryParams = this.route.snapshot.queryParamMap;

		// Perform initialization here if needed
		if (queryParams.has('loads')) {
			this.loadIds = JSON.parse(queryParams.get('loads'));
		}
		if (queryParams.has('redirectUrl')) {
			this.redirectUrl = decodeURIComponent(queryParams.get('redirectUrl'));
		}
		if (queryParams.has('orgId')) {
			this.organizationID = JSON.parse(queryParams.get('orgId'));
			this.getLoadLookups();
		}

		this._layoutService.setTitle("SignUp")
	}

	//input handling methods //



	// Method to toggle password visibility
	handleTogglePasswordButtonClick() {
		this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
	}

	// Method to toggle confirm password visibility
	handleToggleConfirmPasswordButtonClick() {
		this.confirmPasswordType = this.confirmPasswordType === 'password' ? 'text' : 'password';
	}

	// Method to handle signup
	handleSignUpButtonClick() {

		if (this.validate()) {
			this.disableSignup = true; // avoid bulk requests
			this.disableNameFields = true;
			this.SignupProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_START);

			//if record for user does not exist 
			if (!this.userProfiles || Object.keys(this.userProfiles).length === 0) {
				this.MapUserNotExistFields();
				this.createUserAndLogin(this.userProfiles);
			}
			//this user exists in another tenant, copy it to the current tenant
			else if (this.userProfiles.ID == 0)
			{
				this.MapUserNotExistFields();
				this.createUserAndLogin(this.userProfiles);
			}
			else {
				// if record for user exists in current tenant and only need to add credentials
				this.MapUserExistsFields();
				this.CreateLogin();
			}
		}
	}

	handleUserCredentialsExist() {
		if (this.userProfiles && this.userProfiles.User) {
			this.disableSignup = true;
			this._utilityService.displayErrorMessage(CommonMessagingKeys.USER_LOGIN_EXISTS);
			this.showLogin = true;
			this.showEmail = true;
			return true;
		}
		else {
			this.disableSignup = false;
			this.showLogin = false;
			this.showEmail = false;
			return false;
		}
	}

	handleEmailBlur() {

		if (this.validatePersonalEmail(this.Email)) {
			this.SignupProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_START);
			this.disableNameFields = true;
			this.getMatchingUserProfiles(this.Email, "", "", 0, this.organizationID);
		}
		else {
			this.disableSignup = true; // avoid bulk requests
		}
	}

	handleLoginBtnClick() {
		this.router.navigateByUrl('/login');
	}


	// mapping methods //

	MapUserExistsFields() {
		this.userProfiles.User = this.Username;
		this.userProfiles.Password = this.Password;
		this.userProfiles.ExternalCompanyID = this.externalCompanyID;
		this.userProfiles.ConfirmPassword = this.confirmPassword;
		this.userProfiles.PersonalEmail = this.Email;
		this.userProfiles.Role = this.loadDetail.Role;
		this.userProfiles.SubTenantID = this.organizationID;
	}

	//to map from input model to DTO
	MapUserNotExistFields() {
		//user details
		this.userProfiles.PersonalEmail = this.Email;
		this.userProfiles.WorkEmail = this.Email;
		this.userProfiles.ExternalCompanyID = this.externalCompanyID;
		this.userProfiles.FirstName = this.FirstName;
		this.userProfiles.LastName = this.LastName;
		this.userProfiles.SubTenantID = this.organizationID;
		this.userProfiles.Role = this.loadDetail.Role;

		//user credentials
		this.userProfiles.User = this.Username;
		this.userProfiles.Password = this.Password;
		this.userProfiles.ConfirmPassword = this.confirmPassword;
	}


	// API methods for creating user and/or login //

	private getMatchingUserProfiles(
		email: string,
		phoneNumber: string,
		loginAffiliations: string,
		masterUserProfileId: number,
		organizationID: number
	): void {
		this._userProfilesService
			.getDuplicateUserProfiles(email, phoneNumber, loginAffiliations, masterUserProfileId, this.organizationID)
			.then((response: ContractHttpResponse<UserProfile[]>) => {
				if (response.Success) {
					this.userProfiles = response?.Source[0];

					if (this.userProfiles && this.userProfiles.TenantID > 0) {
						this.disableNameFields = true;
						this.FirstName = this.userProfiles.FirstName;
						this.LastName = this.userProfiles.LastName;
					}
					else {
						this.userProfiles = new UserProfile();
						this.disableNameFields = false;

						this.FirstName = "";
						this.LastName = "";
					}
					
					this.handleUserCredentialsExist();
				}
				this.SignupProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
			});
	}

	private CreateLogin(): Promise<ContractHttpResponse<UserProfile>> {
		return new Promise<ContractHttpResponse<UserProfile>>((resolve) => {
			this._userProfilesService
				.saveOrUpdateLoginCredential(this.userProfiles.ID, this.userProfiles)
				.then((response: ContractHttpResponse<UserProfile>) => {
					if (response.Success) {
						this.userProfiles = response.Source;
						this._utilityService.displaySuccessMessage("Username and password created. Please login to continue.");
						//redirect
						this.SignupProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
						setTimeout(() => {
							this.router.navigateByUrl(`${this.redirectUrl}`);
						}, 2000);

					}
					else {
						this.disableSignup = true; // avoid bulk requests
						this.disableNameFields = true;
						if (response.Message != null && response.Message != '' && response.Message != undefined) {
							const msg = response.Message;
							this._utilityService.displayErrorMessage(msg);
							return;
						}
						this._utilityService.displayErrorMessage("An Error occured.");
					}
					resolve(response);
				});
		});
	}

	private createUserAndLogin(User: UserProfile) {
		this._userProfilesService.CreateUserAndLogin(User)
			.then((response: ContractHttpResponse<boolean>) => {
				if (response.Success) {
					if (response.Source == true) {
						this._utilityService.displaySuccessMessage("User and login created. Please login to continue.");
						//redirect
						this.SignupProgressStatusEmitter.emit(PROGRESS_STATUS.PROGRESS_END);
						setTimeout(() => {
							this.router.navigateByUrl(`${this.redirectUrl}`);
						}, 2000);
					}
					else {
						this.disableSignup = true; // avoid bulk requests
						this.disableNameFields = true;
						this._utilityService.displayErrorMessage("An Error occured.");
					}
				}

				else {

					this.disableSignup = true; // avoid bulk requests
					this.disableNameFields = true;
					console.log('here');
					if (response.Message != null && response.Message != '' && response.Message != undefined) {
						const msg = response.Message;
						console.log(msg);
						this._utilityService.displayErrorMessage(msg);
						return;
					}
				}
			}
			);
	}

	private getLoadDetailsFromIds() {
		this._loadService.GetLoadList(this.loadIds, this.organizationID)
			.then(res => {
				if (res.Success) {
					const x = res.Source[0];
					if (x) {
						//get lookups
						const loadTypeLookup = this.loadLookups.find(lookup => lookup.Name.toLowerCase() == x.LoadTypeLookupName.toLowerCase());
						const loadType = loadTypeLookup ? loadTypeLookup.Name.toLowerCase() : "";

						//get roles
						const customerRole = this.Role.find(role => role.Code.toLowerCase() === "customer");
						const vendorRole = this.Role.find(role => role.Code.toLowerCase() === "vendor");

						//assign role based on loads
						const role = loadType.includes(ShipmentTypes.OUTBOUND.toLowerCase()) ? (customerRole ? customerRole.Code : "") : (vendorRole ? vendorRole.Code : "");

						//get readonly data
						this.loadDetail = { OrganizationID: x.OrganizationID, organizationName: x.OrganizationName, Role: role, ExternalCompanyID: x.ExternalCompanyID, ExternalCompanyName: "", Tenant: "", }
						this.getOrganizationInfo(x.OrganizationID)
						this.getCompanyName(this.loadDetail.ExternalCompanyID);
					}
				}
			})
	}

	//to get tenantInfo

	private getOrganizationInfo(id: number): Promise<boolean> {
		return new Promise((resolve) => {
			return this._organizationService.getLightAnonymous(id)
				.then((response: ContractHttpResponse<Organization>) => {
					if (response.Success === true) {
						this.loadDetail.Tenant = response.Source.OrganizationParentName; // retun the received data
						resolve(true);
					}
					resolve(false);
				});
		}
		)
	};

	private getCompanyName(id: number): Promise<boolean> {
		return new Promise((resolve) => {
			return this._companyService.GetAnonymous(id)
				.then((response: ContractHttpResponse<Company>) => {
					if (response.Success === true) {
						this.loadDetail.ExternalCompanyName = response.Source.Name; // retun the received data
						this.externalCompanyID = response.Source.ID;
						this.isDataLoaded = true;
						resolve(true);
					}
					resolve(false);
				});
		}
		)

	}

	private getLoadLookups(): void {
		this._lookupService
			.getGetLookupsByCategoryCodeForDropDownForAnonymous(InformationConstants.LookupCategories.LOAD_TYPES, this.organizationID)
			.then((response: ContractHttpResponse<Lookup[]>) => {
				if (response.Success === true) {
					this.loadLookups = response.Source;
					this.getRoleLookups();
				}
			});
	}

	private getRoleLookups(): void {
		this._lookupService
			.getGetLookupsByCategoryCodeForDropDownForAnonymous(InformationConstants.LookupCategories.ExternalCompanyTypes, this.organizationID)
			.then((response: ContractHttpResponse<Lookup[]>) => {
				if (response.Success === true) {
					this.Role = response.Source;
					this.getLoadDetailsFromIds();
				}
			});
	}
	// validation checks //

	validate(): boolean {
		if (this.Username.length == 0 || this.Email.length == 0 || this.Password.length == 0 || this.confirmPassword.length == 0 || this.FirstName.length == 0 || this.LastName.length == 0) {
			this._utilityService.displayErrorMessage(this.CommonMessagingKeys.MANDATORY_FIELD_ERROR);
			return false;
		}

		if (!this.validateUser(this.Username) || !this.validatePersonalEmail(this.Email) ||
			!this.validatePassword(this.Password) || !this.validateConfirmPassword(this.Password, this.confirmPassword)) {
			return false;
		}

		return true;
	}

	validateUser(user: string): boolean {
		return HelperService.validateEmptyOrBlankSpaces(user);
	}

	validatePersonalEmail(personalEmail: string): boolean {
		if (HelperService.validateEmptyOrBlankSpaces(personalEmail) && !HelperService.validateEmail(personalEmail)) {
			this._utilityService.displayErrorMessage(CommonMessagingKeys.INVALID_EMAIL_ERROR);
			return false;
		}
		return true;
	}

	validatePassword(password: string): boolean {
		// const passRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
		// const specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

		// if (specialChar.exec(password)) {
		// 	this._utilityService.displayErrorMessage('Password must not contain special character');
		// 	return false;
		// } 
		if (password.length<8) {
			this._utilityService.displayErrorMessage('Password must be at least eight characters long');
			return false;
		}

		return true;
	}

	validateConfirmPassword(password: string, confirmPassword: string): boolean {
		if (password != confirmPassword) {
			this._utilityService.displayErrorMessage(CommonMessagingKeys.ERROR_CONFIRM_PASSWORD_MISMATCH);
			return false;
		}
		return true;
	}

	onClickBack(){
		this.showEmail = true;
		this.disableSignup = false;
	}
}
