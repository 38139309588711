<lib-ssi-dialog #viewNotification width="640" height="200" header="View Notification" (cancelClicked)="$event.hide();"
  *ngIf="selectedNotification" leftButtonLabel="Close" closeOnOutsideSelect="true">
  <div class="dialog-notification">
    <div class="title">
      <ng-container [ngTemplateOutlet]="viewDetailTemplate" [ngTemplateOutletContext]="{ item:selectedNotification }">
      </ng-container>
      <span class="job-title">{{ selectedNotification.SubjectTemplate }}</span>
      <div>{{ selectedNotification.CreatedOn | ssiDate :"datetime" : true }}</div>
    </div>
    <div class="job-desc">{{ selectedNotification.MessageTemplate }}</div>
  </div>
</lib-ssi-dialog>