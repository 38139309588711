import { Injectable } from '@angular/core';
import {
	WebApiProxyFactoryService,
	IWebApiProxy,
	BaseApiService,
	ContractHttpResponse,
	PagedList,
} from 'ssi-framework';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { NotificationHistory } from '../models/notification-history.model';
import { SortingDirection } from '@infragistics/igniteui-angular/public_api';

@Injectable({
	providedIn: 'root',
})
export class NotificationHistoryService extends BaseApiService<NotificationHistory> {
	private readonly proxy: IWebApiProxy;

	constructor(private proxyFactory: WebApiProxyFactoryService) {
		super();
		this.proxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'NotificationHistory');
	}
	// eslint-disable-next-line
	public registerRoutes(route: ActivatedRoute): void {}

	get(id: number): Promise<ContractHttpResponse<NotificationHistory>> {
		const callback = this.proxy.Get(`${id}`);
		return callback;
	}
	// eslint-disable-next-line
	public getAll(): Promise<any> {
		throw new Error('Method not implemented.');
	}

	public getPaged(
		pageIndex: number,
		pageSize: number,
		orderBy: string,
		orderDir: SortingDirection,
		// eslint-disable-next-line
		filter: any
	): Promise<ContractHttpResponse<PagedList<NotificationHistory>>> {
		const callback = this.proxy.Get('GetPage', {
			searchText: filter.searchText == null ? '' : filter.searchText,
			organizationId: filter.organizationID,
			userId: filter.userId ?? null,
			pageIndex: pageIndex,
			pageSize: pageSize,
			orderBy: orderBy ? orderBy : 'ID',
			orderDirection: !orderDir ? 0 : orderDir - 1,
			EventCode: filter.EventCode ?? '',
			TemplateCode: filter.TemplateCode ?? '',
			isRead: true,
		});
		return callback;
	}
	public GetUnreadNotifications(
		// pageIndex: number,
		// pageSize: number,
		// orderBy: string,
		// orderDir: SortingDirection,
		// // eslint-disable-next-line
		// filter: any
		organizationID:number,
		userProfileID:number
	): Promise<any> {
		const callback = this.proxy.Get('GetUnreadNotifications', {
			// searchText: filter.searchText == null ? '' : filter.searchText,
			// organizationId: filter.organizationID,
			// userId: filter.userId ?? null,
			// pageIndex: pageIndex,
			// pageSize: pageSize,
			// orderBy: orderBy ? orderBy : 'ID',
			// orderDirection: !orderDir ? 0 : orderDir - 1,
			// EventCode: filter.EventCode ?? '',
			// TemplateCode: filter.TemplateCode ?? '',
			isRead: false,
			userId:userProfileID,
			organizationId:organizationID,
		});
		return callback;
	}

	public GetReadNotifications(
		// pageIndex: number,
		// pageSize: number,
		// orderBy: string,
		// orderDir: SortingDirection,
		// // eslint-disable-next-line
		// filter: any
		organizationID:number,
		userProfileID:number
	): Promise<any> {
		const callback = this.proxy.Get('GetReadNotifications', {
			isRead: true,
			userId:userProfileID,
			organizationId:organizationID,
		});
		return callback;
	}
	// eslint-disable-next-line
	public validate(obj: NotificationHistory): boolean {
		return true;
	}

	public saveOrUpdate(notificationHistory: NotificationHistory): Promise<ContractHttpResponse<NotificationHistory>> {
		throw new Error('Method not implemented.');
	}

	public MarkNotificationAsRead(userProfileId, notificationId): Promise<ContractHttpResponse<NotificationHistory>> {
		const callback = this.proxy.Post('markNotificationAsRead', { userProfileId, notificationId });
		return callback;
	}
	public MarkAllNotificationAsRead(userProfileId): Promise<ContractHttpResponse<NotificationHistory>> {
		const callback = this.proxy.Put('markAllNotificationAsRead', userProfileId);
		return callback;
	}

	public delete(id: number): Promise<ContractHttpResponse<boolean>> {
		const callback = this.proxy.Delete(`${id}`);
		return callback;
	}
}
