import { CommonConstants } from '../constants/constants';
import * as EXCEL from 'exceljs/dist/exceljs.min.js';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class SelfCheckinService {
	private screen = new Subject<string>();

	updateScreen(name: string) {
		this.screen.next(name);
	}

	clearScreen() {
		this.screen.next();
	}

	getScreen(): Observable<string> {
		return this.screen.asObservable();
	}
}
