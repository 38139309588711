import { Component } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { UtilityService } from 'ssi-framework';
import { SharedMessagingKeys } from 'src/app/shared/common/shared-messaging-keys.service';
import { CommonConstants } from 'src/app/Common/constants/constants';
import { environment } from 'src/environments/environment';
import { Meta } from '@angular/platform-browser';
import { ResetPasswordService } from 'src/app/information/services/resest-password.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-forget-password',
	templateUrl: './forget-password.component.html',
	styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent {
	ClientName: string = environment.AppTitle;
	Email: string;
	Error: string;
	ErrorClass: string = 'help-block';
	constructor(private userService: UserService, private utilityService: UtilityService, private Meta: Meta, private resetPasswordService: ResetPasswordService, private router: Router) {}


	onBack(){
		this.router.navigateByUrl('/login');
	}
	onSubmit() {
		let regexp = new RegExp(CommonConstants.RegularExpressions.Email);
		if (this.Email && regexp.test(this.Email)) {
			this.Error = '';
			this.resetPasswordService.ForgotUserNamePassword(this.Email).then((r) => {
				if (r.Success && r.Source == true) {
					this.ErrorClass = 'success-block';
					this.Error = SharedMessagingKeys.RESET_PASSWORD_URL_SENT_MESSAGE;
				} else if (r.Success && r.Source == false) {
					this.ErrorClass = 'help-block';
					this.Error = SharedMessagingKeys.INVALID_EMAIL_ADDRESS;
				}
			});
		} else {
			this.Error = SharedMessagingKeys.ENTER_VALID_EMAIL_ADDRESS;
		}
	}

	ngOnInit() {
		this.Meta.updateTag({ name: 'viewport', content: 'width=device-width' });
	}

	//Reset content width on page redirect
	ngOnDestroy() {
		this.Meta.updateTag({ name: 'viewport', content: 'width=1366, initial-scale=0.5, maximum-scale=0.5' });
	}
}
