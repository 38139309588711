import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, Injector, TemplateRef } from '@angular/core';
import { IgxGridComponent, SortingDirection, IgxGridCellComponent } from 'igniteui-angular';
import { UserSettingsService, LayoutService, UtilityService, CommonMessageKeys, PermissionManagerService, BaseLVComponent,
   UserContextService, SsiTranslatorPipe, GridRowActionItem, ContractHttpResponse } from 'ssi-framework';
import { Router, ActivatedRoute } from '@angular/router';
import { Notification } from '../../models/notification';
import { NotificationService } from '../../services/notification.service';
import { NotificationConstants } from '../../Common/notification-constants';
import { ViewNotificationComponent } from '../../view-notification/view-notification.component';

@Component({
  selector: 'lib-ssi-received-notification-lv',
  templateUrl: './received-notification-lv.component.html',
  styleUrls: ['./received-notification-lv.component.scss']
})
export class ReceivedNotificationLVComponent extends BaseLVComponent<Notification> {

  @ViewChild('FWNotificationGrid', {static: false}) public gridRef: IgxGridComponent;

  @ViewChild('viewNotificationComponent', {static: false}) viewNotificationComponent: ViewNotificationComponent;

  public statuses: any[] = [];
  public selectedNotification: Notification;
  public viewDetailTemplate: TemplateRef<any>;

  private _ssiTranslatorPipe: SsiTranslatorPipe;

  public items: GridRowActionItem[] = [
    new GridRowActionItem(this, 'viewDetail', 'View Detail', 'VIEW_RECEIVEDNOTIFICATION', 'viewNotificationDetail', 'eye')
  ];

  constructor(private layoutService: LayoutService,
    private _injector: Injector,
    viewRef: ViewContainerRef,
    componentResolver: ComponentFactoryResolver,
    userSettingsService: UserSettingsService,
    router: Router, route: ActivatedRoute,
    private notificationService: NotificationService,
    utilityService: UtilityService,
    commonMessageKeys: CommonMessageKeys,
    public permissionMangerService: PermissionManagerService,
    private ucs: UserContextService) {
    super(viewRef, componentResolver, userSettingsService, utilityService, commonMessageKeys, router, route);
    this._ssiTranslatorPipe = new SsiTranslatorPipe(_injector);
  }

  public setTitle() {
    const title = this._ssiTranslatorPipe.transform('RECIEVED_NOTIFICATIONS_TITLE');
    this.layoutService.setTitle(title);
  }

  protected getData(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any): Promise<any> {
    filter.modes = [NotificationConstants.Modes.InApp];
    const response = this.notificationService.getPageByRecipient(pageIndex, pageSize, orderBy, orderDir, filter);
    return response;
  }

  protected onBeforeLoad(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loadNotificationStatuses();
      resolve(true);
    });
  }

  protected onAfterLoad() {
    this.viewDetailTemplate = this.notificationService.viewDetailTemplate;
  }

  public firstCap(str: string): string {
    if (str != null && str.length > 1) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } else {
      return str;
    }
  }


  public viewNotificationDetail(cell: IgxGridCellComponent) {
    const notification = cell.rowData as Notification;
    if (notification.NotificationStatus === NotificationConstants.Statuses.UnRead) {
      this.notificationService.MarkAsRead(notification.ID).
        then((response: ContractHttpResponse<boolean>) => {
          if (response.Success) {
            this.ngOnInit();
          }
        })
    }

    this.selectedNotification = notification;
    setTimeout(() => {
      this.viewNotificationComponent.notificationDialog.show();
    }, 100);
  }

  private loadNotificationStatuses() {
    this.statuses.push({ Text: NotificationConstants.Statuses.Read, Value: NotificationConstants.Statuses.Read });
    this.statuses.push({ Text: NotificationConstants.Statuses.UnRead, Value: NotificationConstants.Statuses.UnRead });
  }
}
