import { Component, OnInit } from '@angular/core';

@Component({
selector: 'user-access-component',
templateUrl: './user-access.component.html',
styleUrls: ['./user-access.component.scss']
})
export class UserAccessComponent implements OnInit {

constructor() {
}

ngOnInit(){

}
}