import { Component, OnInit,ViewChild } from '@angular/core';
import { SsiLoaderComponent } from 'ssi-framework';
import { PROGRESS_STATUS } from './login/login.component';
@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent {

// ideally, this.progressBar.show() or this.progressBar.hide() should be used
// But, The underlying functionality has a bug, so a go around is used
//   @ViewChild("progressBar") 
//   public progressBar:SsiLoaderComponent;
public isProgressbarHidden = true;
public hideProgressbarTimeoutId = null;

  constructor() { }

  public getBrowserName() {
		const agent = window.navigator.userAgent.toLowerCase();
		switch (true) {
			case agent.indexOf('edge') > -1:
				return false;
			case agent.indexOf('edg') > -1:
				return false;
			case agent.indexOf('opr') > -1 && !!(<any>window).opr:
				return false;
			case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
				return true;
			default:
				return false;
		}
	}

	showProgressBar(){
		if(this.hideProgressbarTimeoutId != null)
			clearTimeout(this.hideProgressbarTimeoutId);
		
			this.isProgressbarHidden = false;
	}

	hideProgressBar(){
		this.hideProgressbarTimeoutId = setTimeout(()=>{
			this.isProgressbarHidden = true;
		},2000);
	}

	handleProgressStatusChange(event){

		if(event == PROGRESS_STATUS.PROGRESS_START){
			this.showProgressBar();
		}else if(event == PROGRESS_STATUS.PROGRESS_END){
			this.hideProgressBar();
		}


	}

}
