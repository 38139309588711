<div class="ssi-formGroup">
  <div class="btn-holder">
    <ul class="btnlist">
      <li>
        <button tabindex="2" igxButton="flat" type="submit" class="btn-primary" (click)="onSave()" *ngIf="permissionMangerService.checkPermission('EDIT_USER') && isSelf == false" ssiTooltip="{{'SAVE_BTN_TTP' | ssiTranslator }}">
          <span class="icon icon-save"></span>{{'SAVE_BTN' | ssiTranslator }}
        </button>
      </li>
      <!-- <li>
            <button tabindex="{{permissionMangerService.checkPermission('EDIT_USER') && isSelf == false ? 2:3}}" igxButton="flat" type="submit" (click)="onCancel()" ssiTooltip="Cancel">
              <span class="icon icon-times-circle"></span>Cancel
            </button>
          </li> -->
    </ul>
  </div>
  <div>
    <div class="mt-3" *ngFor="let role of roles;">
      <igx-checkbox tabindex="1" [(ngModel)]="role.Selected">
        {{ role.Name }}
      </igx-checkbox>
    </div>
  </div>
</div>

