import { Injectable } from '@angular/core';
import { SharedConstant } from '../Helpers/shared-constant';

@Injectable({
	providedIn: 'root',
})
export class PermissionManagerService {
	loginAffiliation: any;
	constructor(private sharedConstant: SharedConstant) {
		sharedConstant.accessiblePermissions = [];
	}

	setPermissionsFromAccessToken(permissions: any) {
		if (permissions != null) {
			if (permissions instanceof Array) {
				this.sharedConstant.accessiblePermissions = permissions as any[];
			} else {
				this.sharedConstant.accessiblePermissions = [permissions];
			}
		}
	}

	clearPermissions() {
		this.sharedConstant.accessiblePermissions = [];
	}

	checkPermission(permission: string): boolean {
		let check = this.sharedConstant.accessiblePermissions.some((x) => x.Code === permission); //isDevMode() ? true : true; //todo this.sharedConstant.accessiblePermissions.some((x) => x.Code === permission);
		 return check;		
	}

	isPermissionInit(): boolean {
		if (
			this.sharedConstant == undefined ||
			this.sharedConstant == null ||
			this.sharedConstant.accessiblePermissions == undefined ||
			this.sharedConstant.accessiblePermissions == null ||
			this.sharedConstant.accessiblePermissions.length == 0
		) {
			return false;
		}

		return true;
	}
}
