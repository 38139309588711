export class userAffilation {
	public CompanyID: number;
	public OrganizationID: number;
	public RoleID: number;
	public DistrictID: number;
	public UserProfileID: number;
	public AllowExternalUserAccess: boolean;
	public ExternalCompanyID: number;
	public ExternalCompanyName: string;
	public RoleName:string;
}
