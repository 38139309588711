import { Injectable } from '@angular/core';
import { IWebApiProxy, WebApiProxyFactoryService, UserContextService, PermissionManagerService } from 'ssi-framework';
import { environment } from 'src/environments/environment';
@Injectable()
export class UserService {
    private proxy: IWebApiProxy;

    constructor(private proxyFactory: WebApiProxyFactoryService, private userContext: UserContextService, private permissionService: PermissionManagerService) {
        this.proxy = this.proxyFactory.GetProxy("User");
    }

    public ForgotUserNamePassword(email: string): Promise<any> {
        var identityConfig: any = environment.IdentityConfig.find(n => n.Name == environment.AppWebClientName);
        return this.proxy.Get("ForgotUserNamePassword", {
            email: email,
            redirectUrl: identityConfig.RelyingPartyUrl
        });
    }
}