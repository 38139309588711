import { Component, ViewContainerRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonConstants, LocalStorageKeys } from './Common/constants/constants';
import { UserManagementService } from 'ssi-identity';
import { Subscription } from 'rxjs';
import { UserAffiliationsService } from './core/services/UserAffiliations.service';
import { NavigationEnd, Router } from '@angular/router';
import {
	UserSettingsService,
	PermissionManagerService,
	ContractHttpResponse,
	AuthenticateClientService,
	SSIDialogComponent,
} from 'ssi-framework';
import { permission } from './core/models/permission.model';
import { CachedUserSettingsService } from './core/services/cached-user-settings-service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { filter } from 'rxjs/operators';
import { LoggedInUserInformation } from './Common/services/LoggedInUserInformation.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
	AuthenticationResult,
	AuthorizationCodeRequest,
	CacheLookupPolicy,
	EventType,
	SilentRequest,
} from '@azure/msal-browser';
import { error } from 'console';
import { LogoutService } from './shared/services/azure-logout-notify.service';
import asyncLocalStorage from './Common/utils/AsyncLocalStorage';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	public isAuthenticated = null;
	public isDriverCheckIn = null;
	public isDownloadBOLReport = null;
	public isDownloadAttachment = null;
	public is2FaVerified = null;
	public is2FAEnabled = null;
	public uncaughtIdleTimeoutIntervalId = null;
	public isVisitorCheckIn = null;

	subscriptions: Subscription[] = [];
	breadCrumb: string;
	//loadLayout: boolean= true ;
	loginAffiliation: any;
	loadLayout: boolean;
	@ViewChild('idleDialog', { static: false }) public idleDialog: SSIDialogComponent;
	constructor(
		public viewRef: ViewContainerRef,
		private _userManagementService: UserManagementService,
		private auth: AuthenticateClientService,
		private userAffilationServices: UserAffiliationsService,
		private permissionMangerService: PermissionManagerService,
		private router: Router,
		private userSettingsService: UserSettingsService,
		private permissionManager: PermissionManagerService,
		private idle: Idle,
		private changeDetectorRef: ChangeDetectorRef,
		private loggedInUserInformation: LoggedInUserInformation,
		private authService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private logoutService: LogoutService
	) {
		this.isAuthenticated = this.isUserAuthenticated();
		this.isDriverCheckIn = false;
		this.isDownloadBOLReport = false;
		this.isDownloadAttachment = false;
		this.isVisitorCheckIn = false;

		this.is2FaVerified = localStorage.getItem(LocalStorageKeys.TWO_FA_VERIFIED);
		this.is2FAEnabled = localStorage.getItem(LocalStorageKeys.TWO_FA_ENABLED);
		// console.log("App Component started")

		// if (!this.isAuthenticated) {
		// 	 this.SetUserPermissions();
		// }
		this.configureIdleTimeout();
	}
	ngOnInit(): void {
		// this.msalBroadcastService.msalSubject$.subscribe((event: any) => {
		// 	console.log('subscribed to logout event hit..');
		// 	if (event.eventType === EventType.LOGOUT_SUCCESS) {
		// 		// Handle logout success
		// 		this.isAuthenticated = false;
		// 		this.auth.signOut();
		// 		console.log('Logout success event:', event);
		// 	}
		// });
		this.logoutService.logout$.subscribe(() => {
			console.log('Received logout notification in ExampleComponent- App');
			// Implement your logic here (e.g., clear user data, update UI)
		});
		console.log('isAuthenticated', this.isAuthenticated);
		const currentRoute = window.location.pathname.replace(window['base-href'], '');
		if (currentRoute.includes(CommonConstants.WEB_RESPONSIVE_URL)) {
			this.isDriverCheckIn = true;
			this.loadLayout = true;
		}
		if (currentRoute.includes(CommonConstants.DOWNLOAD_BOL_REPORT_URL)) {
			this.isDownloadBOLReport = true;
			this.loadLayout = true;
		}
		if (currentRoute.includes(CommonConstants.DOWNLOAD_ATTACHMENT_URL)) {
			this.isDownloadAttachment = true;
			this.loadLayout = false;
		}
		if (currentRoute.includes(CommonConstants.Visitor_URL)) {
			this.isVisitorCheckIn = true;
			this.loadLayout = true;
		} 

		this.router.events.pipe(filter((r) => r instanceof NavigationEnd)).subscribe((r) => {
			let n = r as NavigationEnd;
			let isAnonymousUrl: boolean =
				environment.AllowAnonymousURLs.filter((s) => {
					if (n.urlAfterRedirects.substring(1).includes(s)) return true;
					return false;
				}).length > 0
					? true
					: false;

			if (isAnonymousUrl) {
				this.idle.stop();
				this.stopWatchingUncaughtIdleTimeout();
				return;
			}

			if (this.isAuthenticated) {
				this.idle.watch();
				this.startWatchingUncaughtIdleTimeout();
			}
		});

		if (
			this.isAuthenticated &&
			this.is2FAEnabled == 'true' &&
			(this.is2FaVerified == null || this.is2FaVerified == undefined || this.is2FaVerified == 'false')
		) {
			this.auth.signOut();
		}
	}

	countdown?: number = null;

	// timerIntervalId = null;
	// startTimer(seconds: number) {
	// 	this.countdown = seconds;

	// 	let secondsElapsed: number = 0;
	// 	let lastTime = new Date();

	// 	this.timerIntervalId = setInterval(() => {
	// 		if (this.countdown <= 0) {
	// 			this.auth.signOut();
	// 			clearInterval(this.timerIntervalId);
	// 		} else {
	// 			secondsElapsed = Date.now() - lastTime.getTime();
	// 			lastTime = new Date();
	// 			this.countdown -= Math.round(secondsElapsed / 1000);
	// 		}
	// 	}, 1000);
	// }

	// resetTimer() {
	// 	clearInterval(this.timerIntervalId);
	// }

	configureIdleTimeout() {
		let idle_time = environment.IdleUserHandlerConfig.IdleTime;
		let warning_at = environment.IdleUserHandlerConfig.IdleWarningAt;

		if (warning_at >= idle_time) {
			console.log(
				'incorrect enviorment configuration for idle user handler, warning is being displayed after idle time is over'
			);
			return;
		}

		idle_time = idle_time * 60;
		warning_at = warning_at * 60;

		let idle = this.idle;
		idle.setIdle(warning_at);
		idle.setTimeout(idle_time - warning_at);
		idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
		this.configureIdleTimeoutListeners();
	}

	startWatchingUncaughtIdleTimeout() {
		let idle_time = environment.IdleUserHandlerConfig.IdleTime * 60 * 1000;

		if (this.uncaughtIdleTimeoutIntervalId == null) {
			let initialTime = Date.now();
			localStorage.setItem(LocalStorageKeys.BACKUP_IDLE_TIME, initialTime.toString());
			this.uncaughtIdleTimeoutIntervalId = setInterval(() => {
				let prevTime = +localStorage.getItem(LocalStorageKeys.BACKUP_IDLE_TIME);
				let currentTime = Date.now();
				localStorage.setItem(LocalStorageKeys.BACKUP_IDLE_TIME, currentTime.toString());
				if (prevTime != 0 && currentTime - prevTime > idle_time) {
					clearInterval(this.uncaughtIdleTimeoutIntervalId);
					this.auth.signOut();
					return;
				}
			}, idle_time / 2);
		}
	}

	stopWatchingUncaughtIdleTimeout() {
		clearInterval(this.uncaughtIdleTimeoutIntervalId);
	}

	configureIdleTimeoutListeners() {
		if (this.idle == null) return;

		this.idle.onIdleStart.subscribe(() => {
			if (this.isAuthenticated) this.idleDialog.show();
			this.idle.setInterrupts([]);
		});

		this.idle.onTimeoutWarning.subscribe((seconds) => {
			this.countdown = seconds;
		});

		this.idle.onTimeout.subscribe(() => {
			if (this.isAuthenticated) this.auth.signOut();
		});
	}

	onIdleDialogSaveClick() {
		this.idleDialog.hide();
		this.configureIdleTimeout();
	}

	onIdleDialogCancelClick() {
		this.auth.signOut();
	}

	convertSecondsToFormatedTime(seconds: number) {
		if (seconds === null || seconds === undefined) return '';

		seconds = seconds < 0 ? 0 : seconds;

		return new Date(seconds * 1000).toISOString().substring(11, 19);
	}

	private isUserAuthenticated(): boolean {
		const identityConfig = environment.IdentityConfig.find((n) => n.Name == environment.AppWebClientName);
		if (identityConfig.Flow == CommonConstants.IdentityClientFlows.ResourceOwner && !this.auth.isAuthenticated()) {
			const currentRoute = window.location.pathname.replace(window['base-href'], '');
			if (
				currentRoute !== 'login' &&
				//currentRoute !== 'logout-azure-ad' &&
				environment.UnAuthorizedRedirection != null &&
				!environment.AllowAnonymousURLs.some((x) => currentRoute.startsWith(x))
			) {
				//TODO: see if this timeout is important
				setTimeout(() => {
					return true;
				}, 1000);
			} else {
				return false;
			}
		} else {
			// debugger;
			// //console.log('silent token flow started in app component.');
			//const allAccounts = this.authService.instance.getAllAccounts();
			// //console.log(allAccounts);
			// acquireTokenRedirect;
			// const activeAccount = this.authService.instance.();
			// console.log(activeAccount);

			const result = this.auth.isAuthenticated();
			//if (result == true) {
			//const activeAccount = this.authService.instance.getActiveAccount();
			//if (activeAccount != null) {
			// const request: AuthorizationCodeRequest = {
			// 	code: '0.AS0A6My6qAV9pUat-77XQfdxPt4Jbt4uskNBsiRjs8TsEJDLAMc.AgABAAIAAAAmoFfGtYxvRrNriQdPKIZ-AgDs_wUA9P9q5C2GAp9uU_1vDPJ7EbKbZ9ie5ZSlHAgw3ZEWh3u2UrG3Rc0cnc6GhQ9pl1EQ5qILKNHcrm22EBK2tvSGfaoRoBMFY7lYdE8G0aAcrdyZ03pe0Q-Rb7Zbu85PhBD20O0Gtsym_NxyCCktAIzUfprpyQCENh5A8GT_rQeat6caPQShCKT6GjNy48e3GSZxO7QXGF8LkFVl_BxUpq2CkPFeOCY4Ei_uPBpDkkLVv_5-k6ojmqa02dZrB1pC8j9iJZZCjAqja-WBj-hVlv-xElB188-gZPAMf5bPI0R8Ed1kV6BXzuVPdFN6911KfiJG2uDKKbmmcfS2VC2Zp8vGLxRrf33RIww2lFUOqto2pbQWQYwWRiCEd2C3ppzRjA_i3xkrOTtXMPGGfhULM3RXjFLY3SmxXc8F8UCP_u6bk9FMdmhoPR8M32cN3JaGQ-EGyxoZKgLiHliOXirdMal9hg7RyZXgTv_fCYwX3FOOPE7zBtOb5SL8m_br5fb8VZKwZEz1umzg9xzIUp6-EZfsOojo6TScMQEFNE6728aQhnShdHfwZBeBmLOpw0Q3c9EGo_aI8Ec_DSBRQcBOLe9rbqKEeeSrH-m85tbvVayPnbJl9GcOaqzH99__wXk0eAMQVdOMVu6dMsMX13ielB3BY0eHSsBfHgjrKvNuACc3btKdWUP1AXy8rR4ft9yG9Kl6j5xuYMdTnrsa1URCR2vwfMOsWYZp4UUC-8JdRV6O1GdoBqB6tXB8000arzHMiz6MyWCG_AHc8UW5cM40ZDzM_02NA8jHd-WAviIRBVFUqVy3a6ZviEBZB78vxJtWz0IyjIhs_1uXL-4I43E0xE8E-Tym',
			// 	clientInfo:
			// 		'eyJ1aWQiOiI2OWI1MzkwYS02YjYyLTQ4MjgtYmJjZC05YzBhMTk5MTFlNzQiLCJ1dGlkIjoiYThiYWNjZTgtN2QwNS00NmE1LWFkZmItYmVkNzQxZjc3MTNlIn0',
			// 	scopes: ['user.read openid profile offline_access'],
			// 	redirectUri: environment.AzureEntraIDConfiguration.RedirectUri,
			// 	authority: environment.AzureEntraIDConfiguration.Authority,
			// 	// scopes: environment.AzureEntraIDConfiguration.Scopes,
			// 	// account: activeAccount,
			// 	// cacheLookupPolicy: CacheLookupPolicy.AccessToken,
			// 	// forceRefresh: false,
			// };
			// debugger;
			// this.authService.instance
			// 	.acquireTokenByCode(request)
			// 	.then((response: AuthenticationResult) => {
			// 		debugger;
			// 		console.log('silent token response: ', response);
			// 	})
			// 	.catch((error) => {
			// 		console.log(error);
			// 	});
			// console.log('silent token flow end');
			// const loggedInTime = localStorage.getItem(LocalStorageKeys.LAST_LOGIN_REFRESH_TIME);
			// const currentTime = Date.now();
			// let differenceValue = (currentTime - parseFloat(loggedInTime)) / 1000;
			// differenceValue /= 60;
			// const diffAbsolute = Math.abs(Math.round(differenceValue));
			// if (diffAbsolute >= 15) {
			// 	localStorage.removeItem(LocalStorageKeys.IS_SOCIAL_USER);
			// 	localStorage.removeItem(LocalStorageKeys.LAST_LOGIN_REFRESH_TIME);
			// 	this.auth.signOut();
			// 	return false;
			// }
			//}
			//}
			return result;
		}
	}

	// public async RouteHandling() {
	// 	this.subscriptions.push(
	// 		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
	// 			const event_url: string = event['url'];
	// 			const locations = event_url?.split('/');
	// 			this.breadCrumb = locations[locations.length - 1];
	// 			if (!(event_url == '/login')) {

	// 			}

	// 		})
	// 	);
	// }

	//private  SetUserPermissions(): Promise<true> {
	//	return new Promise((resolve) => {
	//		this.loadLayout = false;
	//		const settingCode = CommonConstants.CURRENT_LOGIN_AFFILIATION;
	//		console.log('user settings access', 'app component');
	//		this.SetPermissions(this.cachedUserSettingsService.getRolesFromSettings());
	//		this.loadLayout = true;
	//		return  this.userSettingsService.loadUserSettings().then((response) => {;
	//			if (response){
	//				resolve(true);
	//			}
	//			resolve(null);
	//			});
	//		});

	// // const UsersAffiliationsetting = this.userSettingsService.getUserSettingsByCode(settingCode);
	// // if (UsersAffiliationsetting != null) {
	// // 	this.loginAffiliation = JSON.parse(UsersAffiliationsetting.Value);
	// // 	let roles = '';

	// // 	for (const iterator of this.loginAffiliation) {
	// // 		if (roles === '') {
	// // 			roles = iterator.RoleID;
	// // 		} else {
	// // 			roles = roles + ',' + iterator.RoleID;
	// // 		}
	// // 	}

	// // } else {

	// // }
	//	}

	// protected async SetPermissions(roles: string): Promise<any> {
	// 	console.log('SetPermissions');

	// 	if (roles ==''){
	// 		return ;
	// 	}
	// 	const response = await this.cachedUserSettingsService.getPermissions(roles);

	// 	if (response.Success === true) {
	// 		console.log('PermissionsClearSet');
	// 		this.permissionManager.clearPermissions();
	// 		console.log('PermissionsSet');
	// 		this.permissionManager.setPermissionsFromAccessToken(response.Source);
	// 		console.log('PermissionsDone');
	// 		this.loadLayout = true;
	// 	}
	// 	// this.userAffilationServices
	// 	// 	.getNewAffiliationRoles(roles)
	// 	// 	.then(async (response: ContractHttpResponse<permission>) => {
	// 	// 		if (response.Success === true) {
	// 	// 			console.log('PermissionsClearSet');
	// 	// 			this.permissionManager.clearPermissions();
	// 	// 			console.log('PermissionsSet');
	// 	// 			this.permissionManager.setPermissionsFromAccessToken(response.Source);
	// 	// 			console.log('PermissionsDone');
	// 	// 			this.loadLayout = true;
	// 	// 		}
	// 	// 	});
	// }
}
