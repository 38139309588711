import { BaseHttpHandler } from './base-http-handler';
import { WebApi } from '../Discovery/web-api';
import { HttpClient } from '@angular/common/http';

export class HttpDeleteHandlerService extends BaseHttpHandler {
    constructor(private api: WebApi | Promise<WebApi>,
        private apiName: string,
        private data: any,
        private http: HttpClient,
        private silentRefresh: boolean) {
        super(api);
    }

    protected GetUrl(address: string): string {
        const url = `${address}/${this.apiName}`;
        return url;
    }

    protected AttemptReponse(url: string): Promise<any> {
        const headers = { 'ssi-api-request': 'true' };
        if (this.silentRefresh) {
          headers['ssi-api-request-silent'] = 'true';
        }
        const options = {headers, params: null};
        if (this.data != null) {
            options.params = this.data;
        }

        const requestPromise = this.http.delete(url, options).toPromise();
        return requestPromise;
    }
}