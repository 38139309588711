import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SortingDirection } from '@infragistics/igniteui-angular';
import { dateGetTimeOfDay } from 'igniteui-angular-core';
import { environment } from 'src/environments/environment';
import {
	WebApiProxyFactoryService,
	IWebApiProxy,
	BaseApiService,
	IOrderColumn,
	ContractHttpResponse,
} from 'ssi-framework';
import { ResponseModel } from '../models/Responce.model';
import { UserProfilePropertyModel } from '../models/user-profile-property.model';

@Injectable({
	providedIn: 'root',
})
export class ResetPasswordService extends BaseApiService<UserProfilePropertyModel> {
	private readonly proxy: IWebApiProxy;
	//private loggedInUserInformation:LoggedInUserInformation;

	constructor(private proxyFactory: WebApiProxyFactoryService) {
		super();
		this.proxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'ResetPassword');
	}

	registerRoutes(route: ActivatedRoute): void {
		throw new Error('Method not implemented.');
	}
	get(id: number): Promise<any> {
		throw new Error('Method not implemented.');
	}

    public ForgotUserNamePassword(email: string): Promise<any> {
        var identityConfig: any = environment.IdentityConfig.find(n => n.Name == environment.AppWebClientName);
        return this.proxy.Get("ForgotUserNamePassword", {
            email: email,
            redirectUrl: identityConfig.RelyingPartyUrl
        });
    }
	public IsLinkExpired(guidId: string): Promise<any> {
        var identityConfig: any = environment.IdentityConfig.find(n => n.Name == environment.AppWebClientName);
        return this.proxy.Get("IsLinkExpired", {
            linkGuid: guidId,
            redirectUrl: identityConfig.RelyingPartyUrl
        });
    }

	public changePasswordByLink(guidId: string, password: string, confirmpassword: string ): Promise<any> {
        var identityConfig: any = environment.IdentityConfig.find(n => n.Name == environment.AppWebClientName);
        return this.proxy.Get("ChangePasswordFromResetLink", {
            linkGuid: guidId,
			password: password,
			confirmpassword: confirmpassword,
            redirectUrl: identityConfig.RelyingPartyUrl
        });
    }
	
	getPaged(
		pageIndex: number,
		pageSize: number,
		orderBy: string,
		orderDir: SortingDirection,
		filter: any,
		orderColumns?: IOrderColumn[]
	): Promise<any> {
		throw new Error('Method not implemented.');
	}
	getAll(): Promise<any> {
		throw new Error('Method not implemented.');
	}
	validate(obj: UserProfilePropertyModel): boolean {
		return true;
	}
	saveOrUpdate(obj: UserProfilePropertyModel): Promise<ContractHttpResponse<ResponseModel>> {
        throw new Error('Method not implemented.');
	}
	
	delete(id: number): Promise<any> {
		throw new Error('Method not implemented.');
	}
	
}
