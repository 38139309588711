import { Component, Output, EventEmitter, ViewChild, Input, OnInit, Inject, ElementRef, OnDestroy } from '@angular/core';
import { PositionSettings, HorizontalAlignment, VerticalAlignment, OverlaySettings, ConnectedPositioningStrategy, ISelectionEventArgs,
  IgxOverlayService, AbsoluteScrollStrategy } from 'igniteui-angular';
import {IgxDropDownComponent} from '@infragistics/igniteui-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RecentSearchOverlayComponent } from './recent-search-overlay/recent-search-overlay.component';
import { SsiFilterService } from '../../../WebInfrastructure/Services/ssi-filter.service';

@Component({
  selector: 'lib-ssi-filter',
  templateUrl: './ssi-filter.component.html',
  styleUrls: ['./ssi-filter.component.css']
})

export class SsiFilterComponent implements OnInit, OnDestroy {

  private _overlayId = ''; // The unique identifier assigned to the component by the Overlay service
  private _overlayShown = false; // Is the component rendered in the Overlay?
  private isOpen = false;
  private destroy$ = new Subject<boolean>();

  @Input() enabelSaveAndSearch = true;
  @Input() showRecentSearches = false;
  @Input() containerWidth = 615;
  @Input() showSearchDropdown = false;
  @Input() isOverlayFilter: Boolean = false;
  @Input() maxChips = 2;

  @Output() search = new EventEmitter();
  @Output() saveAndSearch = new EventEmitter();
  @Output() resetSearch = new EventEmitter();

  @ViewChild('filterContainerDDL', { read: IgxDropDownComponent, static: false })
  filterContainerDDL: IgxDropDownComponent;

  @ViewChild('filterTagContainerDDL', { read: IgxDropDownComponent, static: false })
  filterTagContainerDDL: IgxDropDownComponent;

  @ViewChild('recentSearchesOverlay', { read: ElementRef, static: false })
  recentSearchesOverlay: ElementRef;

  @ViewChild('searchDropdown')
  public searchDropdown: IgxDropDownComponent;


  private _positionSettings: PositionSettings = {
    horizontalStartPoint: HorizontalAlignment.Right,
    verticalStartPoint: VerticalAlignment.Bottom,
    horizontalDirection: HorizontalAlignment.Left,
    verticalDirection: VerticalAlignment.Bottom
  };
  private _overlaySettings: OverlaySettings = {
    closeOnOutsideClick: true,
    modal: false,
    positionStrategy: new ConnectedPositioningStrategy(this._positionSettings),
    scrollStrategy: new AbsoluteScrollStrategy()
  };

  public gridId = '';
  public filterObject = [];

  searchText = '';

  constructor(@Inject(IgxOverlayService) private overlayService: IgxOverlayService,
    private ssiFilterService: SsiFilterService) {
    this.overlayService
      .onClosed
      .pipe(
        filter((x) => x.id === this._overlayId),
        takeUntil(this.destroy$))
      .subscribe(() => delete this._overlayId);
  }

  ngOnInit(): void {
    this.ssiFilterService.applySearchFilter.subscribe(n => {
      this.search.emit(n);
      this.overlayService.hide(this._overlayId);
    });

    this.ssiFilterService.closeSearchFilter.subscribe(n => {
      this.overlayService.hide(this._overlayId);
      this._overlayShown = false;
      this.isOpen = false;
    });
  }

  public toggleDropDown(eventArgs) {
    this._overlaySettings.positionStrategy.settings.target = eventArgs.target;
    if (this.filterContainerDDL.collapsed) {

      this.filterContainerDDL.open(this._overlaySettings);
    } else {
      this.filterContainerDDL.close();
    }
  }

  public searchHistoryFilter(eventArgs) {
    this._overlaySettings.positionStrategy.settings.target = eventArgs.target;

    if (!this._overlayShown) {
      //  generate ID
      if (!this._overlayId) {
        this.ssiFilterService.setGridId(this.gridId);
        this._overlayId = this.overlayService.attach(RecentSearchOverlayComponent, this._overlaySettings);
        this.overlayService.show(this._overlayId);
        this.isOpen = true;
        this._overlayShown = !this._overlayShown;
      }
    } else {
      this.overlayService.hide(this._overlayId);
      this._overlayShown = !this._overlayShown;
    }
    if (this.isOpen) {
      this._overlayShown = !this._overlayShown;
      this.isOpen = false;
    }
  }

  public toggleTagsDropDown(eventArgs) {
    this._overlaySettings.positionStrategy.settings.target = eventArgs.target;
    if (this.filterTagContainerDDL.collapsed) {
      this.filterTagContainerDDL.open(this._overlaySettings);
    } else {
      this.filterTagContainerDDL.close();
    }
  }

  public onSelection(eventArgs: ISelectionEventArgs) {
    eventArgs.cancel = true;
  }

  public applyGlobalFilterValue(value: string) {
    const searchTextIndex = this.filterObject.findIndex(n => n.fieldName === 'searchText');
    if (searchTextIndex > -1) {
      this.filterObject.splice(searchTextIndex, 1);
    }
    this.searchText = value;

    if (value != null && value.length > 0) {
      this.filterObject.push({
        fieldName: 'searchText',
        key: value,
        value,
        label: 'General Search'
      });
    }
  }

  searchFilter(event: any) {
    event.preventDefault();

    this.search.emit();

    this.filterContainerDDL.close();
  }

  saveAndApplyFilter(event: any) {
    event.preventDefault();

    this.saveAndSearch.emit();

    this.filterContainerDDL.close();
  }

  resetFilter(event: any) {
    this.searchText = null;
    event.preventDefault();

    this.resetSearch.emit();

    this.filterContainerDDL.close();
  }

  public clearSearchInput() {
    this.searchText = '';

    this.search.emit();

    this.filterContainerDDL.close();
    return false;
  }

  onKeydown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      this.search.emit();

      this.filterContainerDDL.close();
    }
    event.stopImmediatePropagation();
  }

  filterRemoved(event) {
    if (event.fieldName === 'searchText') {
      this.searchText = '';
    }
    this.search.emit(event);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onSearchSelected(event) {
    this.searchText = event;
    this.searchDropdown.close();
  }
}

