import { Component, Input, ContentChild, TemplateRef, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'lib-ssi-buttongroup',
  templateUrl: './ssi-buttongroup.component.html',
  styleUrls: ['./ssi-buttongroup.component.scss']
})
export class SsiButtonGroupComponent implements OnChanges {
  @Input() name: string;
  @Input() label: string;
  @Input() items: any[];
  @Input() itemKey: string;
  @Input() itemText: string;
  @Input() multiSelection = false;
  @ContentChild(TemplateRef, { static: false }) template: TemplateRef<any>;

  @Output() Select: EventEmitter<any> = new EventEmitter();
  @Output() unSelect: EventEmitter<any> = new EventEmitter();
  public selectedItemIndexes: any[] = [];
  @Input() public selectedItems: any[] = [];
  buttonData: any[] = [];
  constructor() { }

  ngOnChanges() {
    this.setSelection(this.selectedItems);
  }

  setSelection(selections: any[]) {
    if (this.items) {
      this.buttonData = [];
      this.items.forEach(item => {
        this.buttonData.push({ item, isSelected: selections.some(n => n[this.itemKey] === item[this.itemKey]) });
      })
    }
  }

  toggleSelection(selection: boolean, index, event) {
    if (selection) {
      if (this.selectedItemIndexes.indexOf(index) === -1) {
        this.selectedItemIndexes.push(index);
        this.selectedItems.push(this.items[index]);
      }
      this.Select.emit(this.items[index]);
    } else {
      const itemIndex = this.selectedItemIndexes.indexOf(index);
      if (itemIndex > -1) {
        this.selectedItemIndexes.splice(itemIndex, 1);
        this.selectedItems.splice(itemIndex, 1);
        event.button.element.nativeElement.blur();
      }
      this.unSelect.emit(this.items[index]);
    }
  }

  isSelected(item: any) {
    return this.selectedItems && this.selectedItems.some(n => n[this.itemKey] === item[this.itemKey]);
  }

}
