import { Component, OnInit } from '@angular/core';
import {
	ContractHttpResponse,
	LayoutService,
	PermissionManagerService,
	UserContextService,
	UtilityService,
} from 'ssi-framework';
import { Router } from '@angular/router';
import { GlobalSettingService } from 'src/app/information/services/global-settings.services';
import { ConfigKeys } from 'src/app/Common/constants/constants';
import { LoggedInUserInformation } from 'src/app/Common/services/LoggedInUserInformation.service';
import { Configurations } from 'src/app/information/models/configuration.model';
import { parseBool } from 'igniteui-angular-core';
import { LogoutService } from 'src/app/shared/services/azure-logout-notify.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
	public title: number;
	public test: number;
	public isExternalUser: boolean = false;
	public items: any[] = [
		{ Name: 'Mubashir Ahmad', Value: 1 },
		{ Name: 'M.Yousaf Khan', Value: 2 },
		{ Name: 'Haseeb Hassan', Value: 3 },
		{ Name: 'Sana Junaid', Value: 4 },
		{ Name: 'M.Hamza Sohail', Value: 5 },
		{ Name: 'Binish Ejaz', Value: 6 },
		{ Name: 'Shaheer Riaz Qadari', Value: 7 },
		{ Name: 'Faisal Amjad', Value: 8 },
		{ Name: 'M.Aqib Khan', Value: 9 },
		{ Name: 'Ghayour Ali', Value: 10 },
	];
	//eslint-disable-next-line
	loginAffiliation: any;
	independentYardWorkflow: boolean;
	constructor(
		layoutService: LayoutService,
		private permissionManager: PermissionManagerService,
		private userContextService: UserContextService,
		private router: Router,
		private utilityService: UtilityService,
		private globalSettingsService: GlobalSettingService,
		private _loggedInUserInformation: LoggedInUserInformation,
		private logoutService: LogoutService
	) {
		layoutService.setTitle('Dashboard');
		layoutService.disableBackNavigation();
	}
	ngOnInit(): void {
		this.loginAffiliation = this._loggedInUserInformation.GetUserAffiliationFromSettings();
		this.isExternalUser = this.loginAffiliation[0].AllowExternalUserAccess;
		this.getGlobalSettings();
		// Subscribe to logout notifications
		this.logoutService.logout$.subscribe(() => {
			console.log('Received logout notification in ExampleComponent- Home');
			// Implement your logic here (e.g., clear user data, update UI)
		});
	}
	getGlobalSettings(): void {
		this.globalSettingsService
			.getConfigurationsByOrganizationIdAnonymous(
				this.loginAffiliation[0].OrganizationID,
				ConfigKeys.IndependantYardWorkflow
			)
			.then((response: ContractHttpResponse<Configurations[]>) => {
				if (response.Success === true) {
					const independantYardWorkflow = response.Source.filter(
						(x) => x.ConfigKey == ConfigKeys.IndependantYardWorkflow
					);
					if (independantYardWorkflow && independantYardWorkflow.length > 0) {
						this.independentYardWorkflow = parseBool(independantYardWorkflow[0].ConfigValue);
					}
				}
			});
	}

	checkPermission(PermissionCode): boolean {
		var flag = false;

		if (this.permissionManager.checkPermission(PermissionCode)) {
			flag = true;
		}

		return flag;
	}
}
