import { WebApiProxyFactoryService, IWebApiProxy, UserContextService, PermissionManagerService } from 'ssi-framework';
import { NotificationEventConfiguration } from '../models/notification-configuration';
import { NotificationSubscription } from '../models/notification-subscription';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'ssi-framework';
import { SortingDirection } from 'igniteui-angular';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
  })
export class NotificationConfigurationService extends BaseApiService<NotificationEventConfiguration>{
    private proxy: IWebApiProxy;
    private userProxy: IWebApiProxy;
    private showAllDataPermission = 'VIEW_ALL_COMPANY_DATA';

    constructor(private proxyFactory: WebApiProxyFactoryService, private permissionService: PermissionManagerService,
         private userContext: UserContextService){
        super();
        this.proxy = this.proxyFactory.GetProxy('NotificationEvent');
        this.userProxy = this.proxyFactory.GetProxy('User');
    }

    registerRoutes(route: ActivatedRoute){

    }

    public get(id: number): Promise<any>{
        const request = this.proxy.Get('GetConfiguration', { id });
        return request;
    }

    public getPaged(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any): Promise<any>{
        const callback = this.proxy.Get('GetPageConfiguration', {
            searchText: filter.searchText ? filter.searchText : '',
            eventCode: filter.eventCode ? filter.eventCode : '',
            modes: filter.modes ? filter.modes : null,
            pageIndex,
            pageSize,
            orderBy: 'ID',
            orderDirection: 0
          });
          return callback;
    }

    public getAll(): Promise<any>{
        const request = this.proxy.Get('GetAllConfiguration');
        return request;
    }

    public validate(obj: NotificationEventConfiguration): boolean{
        return true;
    }

    public saveOrUpdate(obj: NotificationEventConfiguration): Promise<any>{
        const request = this.proxy.Post('SaveEventConfiguration', obj );
        return request;
    }

    public delete(id: number): Promise<any> {
        const request = this.proxy.Post('Delete', { id });
        return request;
    }

    public getSubscriptions(id: number): Promise<any>{
        const request = this.proxy.Get('GetSubscriptions', { eventConfigId: id });
        return request;
    }

    public saveSubscriptions(eventConfigID: number, obj: NotificationSubscription[]): Promise<any>{
        const loginNames: string[] = [];
        obj.forEach(element => {
            loginNames.push(element.LoginName);
        });
        const saveEventSubscriptionKey = 'SaveEventSubscription';
        const request = this.proxy.Get(saveEventSubscriptionKey, { eventConfigID, loginNames });
        return request;
    }

    public getAllUsers(){
        if (this.permissionService.checkPermission(this.showAllDataPermission)) {
            const request = this.userProxy.Get('GetAll');
            return request;
        } else {
            const request = this.userProxy.Get('GetAllByCompany', { companyId: this.userContext.companyId });
            return request;

        }
    }

    public deleteSubscription(id: number){
        const callback = this.proxy.Get('DeleteSubscription', {id});
        return callback;
    }
}