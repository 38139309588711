import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { IgxTimePickerComponent } from 'igniteui-angular';
import { IgxInputDirective } from '@infragistics/igniteui-angular';
import * as moment from 'moment';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'lib-ssi-time-picker',
  templateUrl: './ssi-time-picker.component.html',
  styleUrls: ['./ssi-time-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SsiTimePickerComponent implements OnInit {

  @Input() id: string;
  @Input() label: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() editable = false;
  @Input() hoursInterval: number = 1;
  @Input() minutesInterval: number = 5;
  @Input() IstwentyFourFormate: boolean = false;
  @Input() IsfilterTimePicker: boolean = false;
  @Input() selectOnFocus = true;
  @Input() moveCursorToStart = false;
  @Input() isFocused = false;

  @Input() get model() {
    return this.modelValue;
  }

  @Output() dateSelected: EventEmitter<any> = new EventEmitter();
  @Output() modelChange = new EventEmitter();
  @Output() onInputChanged: EventEmitter<Event> = new EventEmitter();

  // Public
  selectedTime: Date;
  customError: string;
  inputModel: string;

  @ViewChild('picker', { static: true }) picker: IgxTimePickerComponent;
  @ViewChild('dropDownTarget', { static: false }) dropDownTarget: IgxInputDirective;
  @ViewChild('input', { static: false }) input: NgModel;

  // Private
  private modelValue: any
  set model(val) {
    if (val === undefined) {
      this.selectedTime = null;
    } else {
      this.selectedTime = val;
    }
    this.modelValue = val;
    setTimeout(() => {
      this.inputModel = this.picker.displayTime;
    });
    this.modelChange.emit(this.modelValue);
  }
  constructor() { }

  ngOnInit() {
    this.picker.onClosed.subscribe((n: IgxTimePickerComponent) => {
      this.model = n.value;
      this.dateSelected.emit(this.model);
      this.updateInputState('markAsTouched');
    });
  }


  public onBlur(inputValue: string, value: Date, picker: IgxTimePickerComponent) {
    if (inputValue.length === picker.format.length) {
      const parts = inputValue.split(/[\s:]+/);
      let hour = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      let IsValidTwenty = picker.validHourEntries.indexOf(hour)
      if (this.IstwentyFourFormate && IsValidTwenty == -1) {
        let twentyFourformate = moment(inputValue, "HH:mm:ss").format("LT");
        if (twentyFourformate == "Invalid date") {
          this.dropDownTarget.nativeElement.value = null;
          this.selectedTime = null;
          this.model = null;
          this.dateSelected.emit(null);
        } else {

          const time_parts = twentyFourformate.split(/[\s:]+/);
          let hour = parseInt(time_parts[0], 10);
          const minutes = parseInt(time_parts[1], 10);

          if (time_parts.length === 3 && hour !== 12 && time_parts[2].toLowerCase() === 'pm') {
            hour += 12;
          } else if (time_parts.length === 3 && hour === 12 && time_parts[2].toLowerCase() === 'am') {
            hour = 0;
          }

          if (value == null) {
            value = new Date();
          }

          value.setHours(hour, minutes, 0);
          this.model = value;
          this.dateSelected.emit(this.model);
        }
      }

      else if (picker.validHourEntries.indexOf(hour) !== -1 && picker.validMinuteEntries.indexOf(minutes) !== -1) {
        if (parts.length === 3 && hour !== 12 && parts[2].toLowerCase() === 'pm') {
          hour += 12;
        } else if (parts.length === 3 && hour === 12 && parts[2].toLowerCase() === 'am') {
          hour = 0;
        }
        if (value == null) {
          value = new Date();
        }

        value.setHours(hour, minutes, 0);
        this.model = value;

        this.dateSelected.emit(this.model);
      }
      else {
        this.dropDownTarget.nativeElement.value = null;
        this.selectedTime = null;
        this.model = null;

        this.dateSelected.emit(null);
      }
    } else {
      this.dropDownTarget.nativeElement.value = null;
      this.selectedTime = null;
      this.model = null;

      this.dateSelected.emit(null);
    }
  }

  onFocus(event: FocusEvent) {
    if (this.moveCursorToStart && !this.model) {
      setTimeout(() => {
        this.dropDownTarget.nativeElement.setSelectionRange(0, 0);
      }, 0);
    }
  }

  setError(error: string) {
    this.customError = error;
  }

  clearError() {
    this.customError = '';
  }

  onDialogOpening() {
    setTimeout(() => {
      this.updateInputState('markAsUntouched');
    });
  }

  reset() {
    this.input.control.reset();
  }

  private updateInputState(state: string) {
    this.input.control[state]();
    this.input.control.updateValueAndValidity();
  }

  onInputKeyUp(event: KeyboardEvent) {
    this.onInputChanged.emit(event);
  }
}
