import { LookupsService } from '../../information/services/lookups.service';
import { ContractHttpResponse } from 'ssi-framework';
import { Lookup } from '../../information/models/lookup.model';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LookupStoreService {
	private readonly _key = 'renaissant-lookup-values-' + environment.Version;
	private readonly _privateLookupCategories = ['AWS_LANGUAGES']; //todo add private lookup category
	private _lookups: Lookup[];

	constructor(private _lookupsService: LookupsService) {}

	public InitLookups(): Promise<boolean> {
		return this.savePrivateLookups(false);
	}

	public savePrivateLookups(forceReload: boolean): Promise<boolean> {
		const storedLookups = localStorage.getItem(this._key);
		if (!storedLookups || forceReload) {
			return new Promise<boolean>((resolve) => {
				this._lookupsService
					.getByCategories(this._privateLookupCategories)
					.then((response: ContractHttpResponse<Lookup[]>) => {
						if (response.Success) {
							this._lookups = response.Source;
							localStorage.setItem(this._key, JSON.stringify(response.Source));
						}
						resolve(true);
					});
			});
		} else {
			this._lookups = JSON.parse(storedLookups);
			return new Promise<boolean>((resolve) => {
				resolve(true);
			});
		}
	}

	public getLookups(): Lookup[] {
		if(this._lookups ===undefined || this._lookups ===null)
			this.savePrivateLookups(false);
		return this._lookups;
	}
}
