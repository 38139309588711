import { WebApi } from '../Discovery/web-api';
import { ContractHttpResponse } from './contract-http-response';

export abstract class BaseHttpHandler {
    private readonly _maxRetryCount: number = 1;
    private readonly _retryDelay: number = 1000;

    protected constructor(private _api: WebApi | Promise<WebApi>) {
    }

    public Handle(): Promise<any> {
        // let _self = this;
        const promise = new Promise<any>((resolve, reject) => {
            if (this._api instanceof Promise) {
                (this._api as Promise<WebApi>)
                    .then((res: WebApi) => {
                        const request = this.GetResponse(res);
                        request.then(n => {
                            //debugger;
                            if(n==null){
                                n = new ContractHttpResponse();
                                n.Success = false;
                                n.Message = null;                        
                            }
                            resolve(n);
                        }).catch(error => {
                            const resp = new ContractHttpResponse();
                            resp.Success = false;
                            resp.Message = error.error.Message
                            resolve(resp);
                            // throw new SSIException(`An error occured while performing this operation.`);
                        });
                    }, (error) => {
                        const resp = new ContractHttpResponse();
                        resp.Success = false;
                        resp.Message = 'The API is not reachable.';
                        resolve(resp);
                        // throw new SSIException(`The API is not reachable.`);
                    });
            } else {
                const request = this.GetResponse(this._api as WebApi);
                request.then(n => {
                    //debugger;
                    if(n==null){
                        n = new ContractHttpResponse();
                        n.Success = false;
                        n.Message = null;                        
                    }
                    resolve(n);
                }).catch(error => {
                    const resp = new ContractHttpResponse();
                    resp.Success = false;
                    resp.Message = error.error.Message
                    resolve(resp);
                    // throw new SSIException(`An error occured while performing this operation.`);
                });
            }
        });
        return promise;
    }

    private GetResponse(api: WebApi): Promise<any> {
        // for (let i = 0; i < api.Addresses.length; i++) {
        const url = this.GetUrl(api.Addresses[0]);
        const request = this.AttemptReponse(url)
        // if (this.AttemptReponse(url)) {
        //     break;
        // }
        // }
        return request;
    }

    protected abstract AttemptReponse(url: string): Promise<any>;

    protected abstract GetUrl(address: string): string;
}
