<div class="list-searches">
    <p>
        Use following shortcuts for quick search. For example ATS:123 will search the job having this ATS Id and ignoring any other filter.
    </p>
    <div class="list-wrap">
        <table>
            <tr (click)="selectSearch('ID:')">
                <td>ID:</td>
                <td>Job Reference</td>
            </tr>
            <tr (click)="selectSearch('ATS:')">
                <td>ATS:</td>
                <td>Order ID from CTM</td>
            </tr>
            <tr (click)="selectSearch('Prof:')">
                <td>Prof:</td>
                <td>Job by Professions</td>
            </tr>
            <tr (click)="selectSearch('S:')">
                <td>S:</td>
                <td>Job by Specialty</td>
            </tr>
            <tr (click)="selectSearch('FName:')">
                <td>F: or FName:</td>
                <td>Job By Facility short Name</td>
            </tr>
            <tr (click)="selectSearch('CName:')">
                <td>C: or CName:</td>
                <td>Job by Candidate Name</td>
            </tr>
            <tr (click)="selectSearch('CATS:')">
                <td>CATS:</td>
                <td>Job By Candidate ATS ID </td>
            </tr>
        </table>
    </div>
</div>