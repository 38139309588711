import { Injectable, Inject, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  title = new BehaviorSubject(this.environment.AppTitle);
  backUrl = new BehaviorSubject('');
  showBackButton = new BehaviorSubject(false);
  menuExp = new BehaviorSubject(false);
  breadCrumbs = new BehaviorSubject('');

  appHeaderChanged = new EventEmitter<any>(true);
  appContentChanged = new EventEmitter<string>(true);

  constructor(@Inject('EnvironmentVariables') private environment: any, private titleService: Title) { }

  setTitle(title: string) {
    this.titleService.setTitle(title);
    this.title.next(title);
  }

  setBreadCrumbs(breadCrumbs: string) {
    this.breadCrumbs.next(breadCrumbs);
  }

  enableBackNavigation(backUrl: string = '') {
    this.showBackButton.next(true);
    this.backUrl.next(backUrl);
  }

  disableBackNavigation() {
    this.showBackButton.next(false);
    this.backUrl.next('');
  }

  getTitle(): string {
    return this.title.getValue();
  }

  menuExpanded() {
    this.menuExp.next(true);
  }

  menuCollapsed() {
    this.menuExp.next(false);
  }
}
