import { Component, OnInit, Inject } from '@angular/core';
import { SsiFilterService } from '../../../../WebInfrastructure/Services/ssi-filter.service';
import { UserSettingsService } from '../../../../WebInfrastructure/Services/user-settings.service';
import { SsiDatePipe } from '../../../CustomPipes/ssi-date.pipe';
import { UtilityService } from '../../../../Common/utility.service';

@Component({
  selector: 'recent-search-overlay',
  templateUrl: './recent-search-overlay.component.html',
  styleUrls: ['./recent-search-overlay.component.scss']
})
export class RecentSearchOverlayComponent implements OnInit {

  filterObjects = [];
  ssiDatePipe: SsiDatePipe;

  constructor(private ssiFilterService: SsiFilterService,
    private userSettingsService: UserSettingsService,
    @Inject('EnvironmentVariables') private _environment: any,
    _utilityService: UtilityService) {
    this.ssiDatePipe = new SsiDatePipe(_environment, _utilityService);
  }

  ngOnInit() {
    let setting = this.userSettingsService.getUserSettingsByCode(this.ssiFilterService.gridId + "_RecentSearchs");
    this.filterObjects = setting ? JSON.parse(setting.Value) : [];
  }

  getObjectEntry(obj: any): string[] {
    return Object.keys(obj);
  }

  transformKey(key: string) {
    return key.replace("IDs", "").replace("ID", "").replace("Lookups", "").replace("Lookup", "").replace(/([A-Z])/g, ' $1').trim();
  }

  getObjectEntryValue(obj: any, key: string): string {
    let value = obj[key];
    if (key == "searchedOn") {
      value = new Date(value);
      return this.ssiDatePipe.transform(value, 'datetime');
    } else if (Array.isArray(value)) {
      return (value as string[]).join(", ");
    }
    return value;
  }

  applyFilter(obj: any, details: any) {
    this.ssiFilterService.applySearchFilter.emit({filter: obj, filterDetails: details});
  }

  onClearAll(event: any) {
    if (this.filterObjects != null || this.filterObjects != undefined) {
      let setting = this.userSettingsService.getUserSettingsByCode(this.ssiFilterService.gridId + "_RecentSearchs");
      this.filterObjects = [];  //Clear all filters saved
      setting.Value = JSON.stringify([]);
      this.userSettingsService.clearRecentSearches(setting);
    }
  }

  onClose(event: any) {
    this.ssiFilterService.closeSearchFilter.emit();
  }

}
