import { Component, Injector, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NotificationEventConfiguration } from '../../models/notification-configuration';
import { ContractHttpResponse, LayoutService, UtilityService, CommonMessageKeys,
  BaseAEComponent, SsiTranslatorPipe, RouteHandlerService, SsiSelectComponent } from 'ssi-framework';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationConfigurationService } from '../../services/notification-configuration.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'lib-notification-event-configuration-ae',
  templateUrl: './notification-event-configuration-ae.component.html',
  styleUrls: ['./notification-event-configuration-ae.component.scss']
})
export class NotificationEventConfigurationAeComponent extends BaseAEComponent<NotificationEventConfiguration> {

  @ViewChild("MessageTemplate", { static: false }) MessageTemplate: SsiSelectComponent;
  @ViewChild("SubjectTemplate", { static: false }) SubjectTemplate: SsiSelectComponent;

  private _ssiTranslatorPipe: SsiTranslatorPipe;
  model: NotificationEventConfiguration = new NotificationEventConfiguration();
  templates_list: any;

  constructor(layoutService: LayoutService, route: ActivatedRoute, router: Router, routeHandlerService: RouteHandlerService,
    private notificationConfigurationService: NotificationConfigurationService, private notificationService: NotificationService,
    viewRef: ViewContainerRef, utilityService: UtilityService, commonMessageKeys: CommonMessageKeys, _injector: Injector) {
    super(route, router, routeHandlerService, viewRef, utilityService, commonMessageKeys);
    this._ssiTranslatorPipe = new SsiTranslatorPipe(_injector);
    var title = this._ssiTranslatorPipe.transform("");
    layoutService.setTitle(this.route.snapshot.data.url);
    layoutService.enableBackNavigation(this.route.snapshot.data.enableBackNavigation);
  }

  get(id: number): Promise<any> {
    return this.notificationConfigurationService.get(id);
  }

  onBeforeAddEdit() {
    this.loadTemplates();
  }

  onAfterAddEdit(obj: NotificationEventConfiguration) {
  }

  validate(obj: NotificationEventConfiguration): boolean {
    if ( (obj.MessageTemplate == undefined || obj.MessageTemplate.length == 0) || 
    ( obj.SubjectTemplate  == undefined ||  obj.SubjectTemplate.length == 0)) {
      this.utilityService.displayErrorMessage(this.commonMessageKeys.MANDATORY_FIELD_ERROR);
      return false;
    }
    return true;
  }
  
  saveInternal(obj: NotificationEventConfiguration): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.notificationConfigurationService.saveOrUpdate(obj)
        .then((response: ContractHttpResponse<NotificationConfigurationService>) => {
          if (response.Success == true) {
            this.model = new NotificationEventConfiguration();
            resolve(response);
          }
          else {
            resolve(response);
            this.utilityService.displayErrorMessage(response.Message);
          }
        });
    });
  }

  loadTemplates(){
   return this.notificationService.getAll().then((response: ContractHttpResponse<any>) => {
      if (response.Success) {
        this.templates_list = response.Source
      } else {
        console.log(response);

      }
    });
  }


  onMsgChanging($event){
    if ($event.added.length > 0) {
      $event.cancel = true
      this.MessageTemplate.setSingleModelValue($event.added[0], false);
      this.model.MessageNotificationTemplateID = this.templates_list.find(x => x.FileName == $event.added[0]).ID;
      
    }
  }

  onSubjectChanging($event){
    if ($event.added.length > 0) {
      $event.cancel = true
      this.SubjectTemplate.setSingleModelValue($event.added[0], false);
      this.model.SubjectNotificationTemplateID = this.templates_list.find(x => x.FileName == $event.added[0]).ID;
      
    }
  }

}
