import { WebApiProxyFactoryService, IWebApiProxy, PermissionManagerService, UserContextService, ContractHttpResponse, IOrderColumn } from 'ssi-framework';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'ssi-framework';
import { Company } from '../models/company.model';
import { SortingDirection } from 'igniteui-angular';
import { ActivatedRoute } from '@angular/router';
import { updateLocale } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseApiService<Company>{

  private proxy: IWebApiProxy;
  private teamProxy: IWebApiProxy;
  private showAllDataPermission = 'VIEW_ALL_COMPANY_DATA';

  constructor(private proxyFactory: WebApiProxyFactoryService, private permissionService: PermissionManagerService,
    private userContext: UserContextService) {
    super();
    this.proxy = this.proxyFactory.GetProxy('Company');
    this.teamProxy = this.proxyFactory.GetProxy('Team');

  }

  registerRoutes(route: ActivatedRoute) {
    throw new Error('Method not implemented.');
  }

  get(id: number): Promise<any> {
    // tslint:disable-next-line: no-shadowed-variable
    const request = this.proxy.Get('Get', {id});
    return request;
  }

  getPaged(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any, orderColumns?: IOrderColumn[]): Promise<any> {
    // tslint:disable-next-line: no-shadowed-variabl
    const request = this.proxy.Get('GetPage', {
      code: filter.code ? filter.code : '',
      name: filter.name ? filter.name : '',
      address: filter.address ? filter.address : '',
      country: filter.country ? filter.country : '',
      searchText: filter.searchText ? filter.searchText : '',
      pageIndex,
      pageSize,
      orderBy: orderBy ? orderBy : 'ID',
      orderDirection: (!orderDir || orderDir === 0) ? 0 : orderDir - 1,
    }, null, null, orderColumns);
    return request;
  }

  getAll(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.proxy.Get('GetAll')
        .then((response: ContractHttpResponse<Company[]>) => {
          if (response.Success === true) {
            if (this.permissionService.checkPermission(this.showAllDataPermission) === false) {
              response.Source = response.Source.filter(o => o.ID === this.userContext.companyId);
            }
          }
          resolve(response);
        });
    });
  }

  getTeams(companyId: number): Promise<any> {
    return this.teamProxy.Get('GetByCompany', { companyId });
  }

  validate(obj: any): boolean {
    return true;
  }

  saveOrUpdate(obj: Company): Promise<any> {
    if (obj) {
      return this.proxy.Post('SaveOrUpdate', obj);
    }
  }

  delete(id: number): Promise<any> {
    return this.proxy.Get('Delete', {id});
  }

}
