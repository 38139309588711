export class NotificationEventConfiguration {
    public ID: number;
    public EventId: number;
    public EventCode: string;
    public EventName: string;
    public NotificationMode: string;
    public SubjectTemplate: string;
    public MessageTemplate: string;
    public CreatedBy: string;
    public CreatedOn: Date;
    public ModifiedBy: string;
    public ModifiedOn: Date;
}
