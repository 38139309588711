import { Component, OnInit, ViewChild } from '@angular/core';
import { SSIDialogComponent, UtilityService } from 'ssi-framework';
import { NotificationListnerService } from 'src/app/core/socket-services/services/notification-listener/notification-listener-service';
import { Router } from '@angular/router';
import { NotificationHistoryService } from 'src/app/information/services/notification-history.service';
import { SortingDirection } from '@infragistics/igniteui-angular';
import { LoggedInUserInformation } from 'src/app/Common/services/LoggedInUserInformation.service';
import { userAffilation } from 'src/app/core/models/userAffiliation';
import { UserProfilesService } from 'src/app/information/services/user-profiles.service';
import { InternetConnectionDetailsService } from 'src/app/Common/services/internet-connection-details.service';

@Component({
	selector: 'app-inapp-notifications',
	templateUrl: './inapp-notifications.component.html',
	styleUrls: ['./inapp-notifications.component.scss'],
	providers: [NotificationListnerService],
})
export class InappNotificationsComponent implements OnInit {
	notifications: any;
	selectedNotification: {};
	notificationCount: number = 0;
	OrganizationID: number;
	UserId: number;
	userAffiliation: userAffilation;
	loginAffiliation: string;

	@ViewChild('showNotificationModal', { static: false }) public showNotificationModal: SSIDialogComponent;

	constructor(
		public utilityService: UtilityService,
		public notificationListnerService: NotificationListnerService,
		private _router: Router,
		private _notificationHistoryService: NotificationHistoryService,
		private loggedInService: LoggedInUserInformation,
		private userProfilesService: UserProfilesService,
		private InternetConnectionDetails: InternetConnectionDetailsService,
		private notificationHistoryService: NotificationHistoryService
	) {
		this.selectedNotification = {};
	}

	ngOnInit(): void {
		this.notifications = [];
		const loginAffiliation = this.loggedInService.GetUserAffiliationFromSettings();
		this.userAffiliation = loginAffiliation;
		this.loginAffiliation = JSON.stringify(loginAffiliation);
		this.OrganizationID = loginAffiliation[0].OrganizationID;
		this.UserId = loginAffiliation[0].UserProfileID;
		this.signalRKeepAlive = true;
		this.configureNotificationListener();
	}

	public getNotifications(): Promise<boolean> {
		return new Promise((resolve) => {
			let filter = { organizationID: 0, userId: 0, isRead: false };
			filter.organizationID = this.OrganizationID;
			filter.userId = this.UserId;
			console.log('USER ID : ' + this.UserId);
			console.log('ORG ID : ' + this.OrganizationID);

			this._notificationHistoryService
				.GetUnreadNotifications(this.OrganizationID, this.UserId)
				.then((responce) => {
					if (responce.Success && responce.Source != undefined) {
						console.log('Received Notifications!');
						console.log(responce);
						console.log('----------------');
						this.notifications = responce.Source;
						//this.notifications = this.notifications.slice(0,5);
						this.notificationCount = responce.Source.length;
						resolve(true);
					}
				});
		});
	}
	public getReadNotifications(): Promise<boolean> {
		return new Promise((resolve) => {
			let filter = { organizationID: 0, userId: 0, isRead: false };
			filter.organizationID = this.OrganizationID;
			filter.userId = this.UserId;
			console.log('USER ID : ' + this.UserId);
			console.log('ORG ID : ' + this.OrganizationID);

			this._notificationHistoryService.GetReadNotifications(this.OrganizationID, this.UserId).then((responce) => {
				if (responce.Success && responce.Source != undefined) {
					this.notifications = responce.Source;

					//this.notificationCount = responce.Source.length;
					resolve(true);
				}
			});
		});
	}
	getDate(date: string) {
		const selectedDate = this.utilityService.getLocalDateFromUTC(new Date(date)).getTime();
		const today = new Date().getTime();

		const diffMs = today - selectedDate; // milliseconds
		const diffDays = Math.floor(diffMs / 86400000); // days
		const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
		const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

		if (diffDays > 0) {
			return diffDays + 'd ago';
		} else if (diffHrs > 0) {
			return diffHrs + 'h ago';
		} else if (diffMins == 0) {
			return 'Just now';
		} else {
			return diffMins + 'm ago';
		}
	}

	onSelectNotification(data) {
		this.selectedNotification = data;
		//this.markNotificationAsRead(data.ID);
		this.markAllNotificationAsRead();
		this.showNotificationModal.show();
	}
	markAllNotificationAsRead() {
		return new Promise((resolve) => {
			this.notificationHistoryService.MarkAllNotificationAsRead(Number(this.UserId)).then((responce) => {
				console.log(responce);
				this.getReadNotifications();
				resolve(true);
			});
		});
	}
	markNotificationAsRead(notificationid) {
		return new Promise((resolve) => {
			console.log('About to mark notification as read');
			this._notificationHistoryService.MarkNotificationAsRead(this.UserId, notificationid).then((responce) => {
				console.log('Succeeded in marking as read with reponce');
				console.log(responce);
				if (responce.Success) {
					// this.notifications=this.notifications.filter(x=>x.ID!=notificationid)
					// this.notificationCount--;
					this.getNotifications();
					//this.notificationCount--;
					resolve(true);
				}
			});
		});
	}

	disconnectedHandlerId = null;
	public wasHubConnectionDisconnected = false;
	public signalRKeepAlive: boolean;

	configureNotificationListener() {
		let root = this;
		if (this.notificationListnerService.hubConnection.state === 'Disconnected') {
			this.notificationListnerService
				.startConnection()
				.then(() => {
					console.log('Notification Hub SignalR connection successful');
					clearInterval(this.disconnectedHandlerId); // if exists
					this.disconnectedHandlerId = null;
					this.addSignalRListeners();
					setTimeout(() => {
						this.getNotifications();
					}, 0);
				})
				.catch((e) => {
					this.wasHubConnectionDisconnected = true;
					console.log('failed to make connection using startConnection');
					console.log(e);
				});
		} else {
			console.log('Notification Hub Connection Already Exists. Not Connecting');
		}
	}

	disconnectedHandler() {
		console.log('Disconnect handler called');
		//you may add exponential backoff pattern
		if (this.disconnectedHandlerId === null)
			this.disconnectedHandlerId = setInterval(() => {
				this.configureNotificationListener();
				console.log('disconnectedHandler notificationListnerService called');
			}, 10000);
		// handler already exists
		else console.log('Disconnected Handler Already Exists. It is recalled');
	}

	addSignalRListeners() {
		this.notificationListnerService.hubConnection.onreconnected(() => {
			console.log('Notification Hub Executing Reconnection Callback!');
			this.notificationListnerService.showAllReceivedDataOnConsole();
			//this.reloadData();
		});

		this.InternetConnectionDetails.checkInternetConnection().subscribe((isOnline: boolean) => {
			if (isOnline === true) {
				console.log('is online==true. configuring entity change listener now');
				this.configureNotificationListener();
			}
		});

		this.notificationListnerService.getNotificationUpdateObservable().subscribe((data) => {
			console.log('GOT DATA FROM OBSERVABLE for update');
			this.getNotifications();
		});
		this.notificationListnerService.getNotificationAddObservable().subscribe((data) => {
			console.log('GOT DATA FROM OBSERVABLE');
			console.log(data);

			let notification = data.data[0];
			this.notifications.push({
				CreatedOn: notification.createdOn,
				TemplateBody: notification.templateBody,
				TemplateSubject: notification.templateSubject,
				ID: notification.id,
				IsRead: notification.isRead,
			});
			this.notifications = this.notifications.sort((a, b) => b.ID - a.ID);

			this.notifications = this.notifications.slice(0, 8);

			this.notificationCount++;

			if (data.data[0].hasSound) {
				let audio = new Audio();
				audio.src = './assets/files/notification_sound.mp3';
				audio.load();
				audio.play();
			}
			// alert("got data");
			console.log(data);
		});

		this.notificationListnerService.hubConnection.onclose(() => {
			console.log('Notification Hub Connection Disconnected');
			this.wasHubConnectionDisconnected = true;
			if (this.signalRKeepAlive === true) this.disconnectedHandler();
		});
	}
}
