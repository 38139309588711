import { Input, Directive, HostListener } from '@angular/core';
import { IgxInputDirective } from 'igniteui-angular'
import { NgModel } from '@angular/forms';
@Directive({
  selector: '[ssiNumber]'
})
export class SsiNumberDirective {
  lastSafe: any = null;
  recent = '';
  signEntered = false;
  key: any;
  decimalCount = 0;
  @Input('ssiNumber') afterDecimal: number;
  @Input() min: number;
  @Input() max: number;

  constructor(protected input: IgxInputDirective, protected ngControl: NgModel) {
  }

  @HostListener('keydown', ['$event'])
  keydown(event) {
    this.key = event.key;
    this.recent = this.input.value;
  }

  @HostListener('keypress', ['$event'])
  keypress(event) {
    if (!this.isNumeric(event.key))
      return false;

    if (this.afterDecimal.toString() === '')
      this.afterDecimal = 100;

    if (event.key === '.' && this.afterDecimal === 0)
      return false;

    if (event.key === '.') {
      this.decimalCount++;
      return (this.decimalCount <= 1)
    }
    if (event.key ==='+' || event.key ==='-')
    {
      return !this.signEntered;
    }
    return true;
  }

  @HostListener('input', ['$event'])
  Input(event) {
    if(this.key === '.')
    {
      const arr = this.input.value.split('.');
      if(arr.length > 2)
      {
        this.ngControl.reset(this.recent);
      }
    }
    if (this.key === 'Backspace' && this.input.value === this.lastSafe) {
      this.decimalCount--;
      this.signEntered = false;
    }

    if (!this.valid(this.input.value) && this.key !== 'Backspace') {
      this.ngControl.reset(this.lastSafe);
    }
    else if(!this.valid(this.input.value) && this.key === 'Backspace')
    {
      this.ngControl.reset(this.lastSafe);

    }
    else if (this.key === 'Backspace' && this.recent.includes('.') && !this.input.value.includes('.')) {
        this.decimalCount--;
      }
    else if (this.key === 'Backspace' && this.input.value === '') {
      this.lastSafe = '';
      this.decimalCount = 0;
      this.signEntered = false;
    }
    else if (this.lastSafe === '' && this.input.value === '') {
      if (!(this.key === '+' || this.key === '-' || this.key ==='.'))
        this.ngControl.reset(0)
      else
      {
        if (this.key ==='.')
          this.decimalCount++;
        else
          this.signEntered=true;
      }
    }
    else if (this.input.value !== '') {
      if (this.afterDecimal.toString() === '') {
        this.afterDecimal = 100;
      }
      if (this.input.value.includes('.')) {
        if (this.input.value.split('.')[1].length > this.afterDecimal) {
          this.ngControl.reset(this.lastSafe)
          if (this.key === '.')
            this.decimalCount--;
        }
        else
          this.lastSafe = this.input.value;
      }
      else {
        if(!this.input.value.includes('.'))
        {
          this.lastSafe = this.input.value;
          this.decimalCount = 0;
        }

      }
    }
    else if (this.lastSafe !== '') {
      if (this.key === '.')
        this.decimalCount--;
      //this.ngControl.reset(this.lastSafe)
    }

  }
  private isNumeric(letter) {
    return (letter === '0'
      || letter === '1'
      || letter === '2'
      || letter === '3'
      || letter === '4'
      || letter === '5'
      || letter === '6'
      || letter === '7'
      || letter === '8'
      || letter === '9'
      || letter === '+'
      || letter === '-'
      || letter === '.')

  }
  private valid(value: any) {
    value = value.toString();
    let i = 0;
    while (i < value.length) {
      if (!this.isNumeric(value[i]))
        return false;
      i++;
    }
    if(this.min && Number(value) < this.min){
      return false;
    }else if(this.max && Number(value) > this.max){
      return false;
    }
    return true;
  }
  private countDecimals(value) {
    value = value.toString();
    if (value.includes('.'))
      return value.split('.')[1];
    else
      return 0;
  }
}