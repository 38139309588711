import { Injectable, EventEmitter, ContentChild, TemplateRef, Directive } from '@angular/core';
import { BaseApiService, WebApiProxyFactoryService, IWebApiProxy, UtilityService, UserContextService } from 'ssi-framework';
import { SortingDirection } from 'igniteui-angular';
import { Notification } from '../models/notification';
import { NotificationConstants } from '../Common/notification-constants';
import { NotificationTemplate } from '../models/notification-template.model';

@Directive()
@Injectable({
  providedIn: 'root'
})

export class NotificationService extends BaseApiService<Notification> {

  proxy: IWebApiProxy;
  notificationConfigProxy : IWebApiProxy;
  notificationReceived: EventEmitter<any> = new EventEmitter();
  @ContentChild(TemplateRef, { static: false }) viewDetailTemplate: TemplateRef<any>;

  constructor(private proxyFactory: WebApiProxyFactoryService, private utilityService: UtilityService, private ucs: UserContextService) {
    super();

    this.proxy = this.proxyFactory.GetProxy('Notification');
    this.notificationConfigProxy = this.proxyFactory.GetProxy('NotificationEvent');
  }

  registerRoutes(route: import('@angular/router').ActivatedRoute) {
    throw new Error('Method not implemented.');
  }

  get(id: number): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getPaged(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any): Promise<any> {
    const request = this.proxy.Get('GetPage', {
      modes: filter.modes ? filter.modes : [],
      statuses: filter.statuses ? filter.statuses : [],
      subject: filter.subject ? filter.subject : '',
      recievedByUserId: filter.recievedByUserId ? filter.recievedByUserId : '',
      recievedByEmail: filter.recievedByEmail ? filter.recievedByEmail : '',
      recievedByPhone: filter.recievedByPhone ? filter.recievedByPhone : '',
      createdBy: filter.createdBy ? filter.createdBy : '',
      searchText: filter.searchText ? filter.searchText : '',
      pageIndex,
      pageSize,
      orderBy: orderBy ? orderBy : 'ID',
      orderDirection: (!orderDir || orderDir === 0) ? 0 : orderDir - 1,
    });
    return request;
  }

  getPageByRecipient(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection,
    filter: any, silentRefresh: boolean = false): Promise<any> {
    const request = this.proxy.Get('GetPageByRecipient', {
      modes: filter.modes ? filter.modes : [],
      statuses: filter.statuses ? filter.statuses : [],
      eventCodes: filter.eventCodes ? filter.eventCodes : [],
      subject: filter.subject ? filter.subject : '',
      createdBy: filter.createdBy ? filter.createdBy : '',
      searchText: filter.searchText ? filter.searchText : '',
      pageIndex,
      pageSize,
      orderBy: orderBy ? orderBy : 'CreatedOn',
      orderDirection: (!orderDir || orderDir === 0) ? SortingDirection.Desc - 1 : orderDir - 1,
    }, silentRefresh);
    return request;
  }

  getRecentNotifications(notificationParams : any): Promise<any> {  
    const request = this.proxy.Post('GetRecentNotifications', notificationParams);
    return request;
  }

  getAll(): Promise<any> {
    const request = this.proxy.Get('GetTemplates')
    return request
  }

  validate(obj: Notification): boolean {
    throw new Error('Method not implemented.');
  }

  saveOrUpdate(obj: Notification): Promise<any> {
    throw new Error('Method not implemented.');
  }

  delete(id: number): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getUnReadCount(modes: string[], silentRefresh: boolean = false): Promise<any> {
    return this.proxy.Get('GetUnReadCount', { modes }, silentRefresh);
  }


  getUnReadCountByEvent(modes: string[]): Promise<any> {
    return this.proxy.Get('GetUnReadCountByEvent', { modes });
  }

  SendNotification(eventCode: string, userIds: string[], payload: any): Promise<any> {
    if (!userIds || !userIds.length) {
      return;
    }

    const obj = new Notification();
    obj.InternalKey = this.utilityService.getUniqueIdentifier(9, true) + '-' + this.utilityService.getUniqueIdentifier(4, true) + '-' +
     this.utilityService.getUniqueIdentifier(4, true) + '-' + this.utilityService.getUniqueIdentifier(4, true) + '-' +
      this.utilityService.getUniqueIdentifier(9, true);
    obj.ApplicationCode = this.ucs.appCode;
    obj.EventCode = eventCode;
    obj.UserIds = userIds;
    obj.Payload = JSON.stringify(payload);
    obj.CreatedBy = this.ucs.userName;
    obj.CreatedOn = new Date();
    return this.proxy.Post('Send', obj);
  }

  SendInAppNotification(eventCode: string, userIds: string[], payload: any): Promise<any> {
    if (!userIds || !userIds.length) {
      return;
    }

    const obj = new Notification();
    obj.InternalKey = this.utilityService.getUniqueIdentifier(9, true) + '-' + this.utilityService.getUniqueIdentifier(4, true) + '-' +
     this.utilityService.getUniqueIdentifier(4, true) + '-' + this.utilityService.getUniqueIdentifier(4, true) + '-' +
     this.utilityService.getUniqueIdentifier(9, true);
    obj.ApplicationCode = this.ucs.appCode;
    obj.NotificationMode = NotificationConstants.Modes.InApp;
    obj.EventCode = eventCode;
    obj.UserIds = userIds;
    obj.Payload = JSON.stringify(payload);
    obj.CreatedBy = this.ucs.userName;
    obj.CreatedOn = new Date();
    return this.proxy.Post('Send', obj);
  }

  GetAllNotificationEvents() : Promise<any>{
    return this.notificationConfigProxy.Get('GetAll');
  }

  SendEmailNotification(eventCode: string, userIds: string[], payload: any): Promise<any> {
    if (!userIds || !userIds.length) {
      return;
    }

    const obj = new Notification();
    obj.InternalKey = this.utilityService.getUniqueIdentifier(9, true) + '-' + this.utilityService.getUniqueIdentifier(4, true) + '-' +
     this.utilityService.getUniqueIdentifier(4, true) + '-' + this.utilityService.getUniqueIdentifier(4, true) + '-' +
     this.utilityService.getUniqueIdentifier(9, true);
    obj.ApplicationCode = this.ucs.appCode;
    obj.NotificationMode = NotificationConstants.Modes.Email;
    obj.EventCode = eventCode;
    obj.UserIds = userIds;
    obj.Payload = JSON.stringify(payload);
    obj.CreatedBy = this.ucs.userName;
    obj.CreatedOn = new Date();
    return this.proxy.Post('Send', obj);
  }

  public GetRecipients(notificationID: number) {
    const callback = this.proxy.Get('GetRecipients', { id: notificationID });
    return callback;
  }

  MarkAllAsRead(modes: string[]) {
    const callback = this.proxy.Get('MarkAllAsRead', { modes });
    return callback;
  }

  MarkAsRead(id: number) {
    const callback = this.proxy.Get('MarkAsRead', { notificationID: id });
    return callback;
  }


  save(obj: NotificationTemplate): Promise<any> {
    if (obj.ID > 0) {
      let callback = this.proxy.Post('UpdateTemplate', obj);
      return callback;
    } else {
      let callback = this.proxy.Post('AddTemplate', obj);
      return callback;
    }
  }

  getTemplate(name: string){
    let callback = this.proxy.Get('GetTemplate', {name});
    return callback;
  }

  deleteTemplate(name: string): Promise<any> {
    let callback = this.proxy.Delete('DeleteTemplate', {name});
    return callback;
  }
}
