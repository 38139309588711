<div class="cal-day-view">
  <div class="cal-day-title">{{activeDate.format('dddd')}}</div>
  <div class="cal-days">
    <div class="row no-gutters cal-cell-row">
      <!-- <div class="col cal-cell cal-time-cell">
        <ul>
          <li>12 AM</li>
          <li>1 AM</li>
          <li>2 AM</li>
          <li>3 AM</li>
          <li>4 AM</li>
          <li>5 AM</li>
          <li>6 AM</li>
          <li>7 AM</li>
          <li>8 AM</li>
          <li>9 AM</li>
          <li>10 AM</li>
          <li>11 AM</li>
          <li>12 PM</li>
        </ul>
      </div> -->
      <div class="col cal-cell">
        <lib-cal-day-tile *ngIf="dayTemplate; else default " [template]="dayTemplate" [data]="getData(activeDate.toDate())"
          [date]="activeDate.toDate()" [displayTileExtensionButton]="displayTileExtensionButton">
        </lib-cal-day-tile>
        <ng-template #default>
          <lib-cal-day-tile [template]="defaultDay" [data]="getData(activeDate.toDate())" [date]="activeDate.toDate()"
            [displayTileExtensionButton]="displayTileExtensionButton">
          </lib-cal-day-tile>
        </ng-template>
      </div>
    </div>
  </div>
</div>


<!-- Default Template -->
<ng-template #defaultDay let-date='date'>
  <div class="tile">{{date.getDate()}}</div>
</ng-template>
