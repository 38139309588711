<igx-time-picker #picker [id]="id" [value]="selectedTime" format="hh:mm tt"
  [itemsDelta]="{ hours: hoursInterval, minutes: minutesInterval }" mode="dropdown">
  <ng-template igxTimePickerTemplate let-value="value" let-openDialog="openDialog" let-displayTime="displayTime">

    <igx-input-group tabindex="1" [disabled]="disabled"
      [ngClass]="{'ssi-timepicker--invalid': input?.touched && input?.errors?.required }">
      <igx-suffix *ngIf="!disabled;else noAction">
        <igx-icon (click)="openDialog(dropDownTarget); onDialogOpening();">access_time</igx-icon>
      </igx-suffix>
      <ng-template #noAction>
        <igx-suffix>
          <igx-icon>access_time</igx-icon>
        </igx-suffix>
      </ng-template>

      <label igxLabel [ngClass]="{required: required}">{{ label }}</label>

      <input #dropDownTarget igxInput #input="ngModel" [(ngModel)]="inputModel" [required]="required" [value]="displayTime"
        [igxMask]="'00:00 LL'" autocomplete="none" [placeholder]="'--:-- --'" [promptChar]="'-'"
        (keydown.Space)="openDialog(dropDownTarget)" (blur)="onBlur(dropDownTarget.value, value, picker)"
        [readonly]="!editable" [igxTextSelection]="selectOnFocus && model" [igxFocus]="isFocused" (focus)="onFocus($event)"
        (keyup)="onInputKeyUp($event)" [disabled]="disabled" />

      <igx-suffix title="Reset" class="ssi-dropdown dropdown__clear-button" *ngIf="selectedTime != null && !disabled"
        (click)="selectedTime = null;">
        <igx-icon class="ssi-dropdown-reset">clear</igx-icon>
      </igx-suffix>

    </igx-input-group>

  </ng-template>
</igx-time-picker>

<!-- Hint -->
<div class="input-helper">
  <div>
    <div class="error">
      <ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
    </div>
  </div>
</div>

<!-- Error Template -->
<ng-template #errorTemplate>
  <ng-container *ngIf="customError">
    {{ customError }}
  </ng-container>
  <ng-container *ngIf="input?.touched && !customError">
    <ng-container *ngIf="input?.errors?.required">
      {{ label }} is a required field
    </ng-container>
  </ng-container>
</ng-template>
