export class Team
{
    public ID: number;
    public Code: string;
    public Name: string;
    public IsActive: boolean;
    public SortOrder: number;
    public CompanyID: number;
    public CompanyCode: string;
    public CompanyName: string;
    public CreatedBy: string;
    public CreatedOn: Date;
    public ModifiedBy: string;
    public ModifiedOn: Date;
}