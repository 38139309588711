import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserManagementService } from '../../../Services/usermanagement.service';
import { ContractHttpResponse, UtilityService, PermissionManagerService, UserContextService, CommonMessageKeys } from 'ssi-framework';
import { User } from '../../../models/user.model';
import { CompanyService } from '../../../Services/company.service';
import { Company } from '../../../models/company.model';
import { Team } from '../../../models/team.model';

@Component({
  selector: 'ssi-identity-user-personal-info',
  templateUrl: './user-personal-info.component.html',
  styleUrls: ['./user-personal-info.component.scss']
})
export class UserPersonalInfoComponent implements OnInit {

  model: User = new User();
  originalModal: User;
  public companies: Company[] = [];
  public teams: Team[] = [];
  constructor(
    private route: ActivatedRoute,
    private userContextService: UserContextService,
    private userService: UserManagementService,
    private companyService: CompanyService,
    private utilityService: UtilityService,
    public permissionMangerService: PermissionManagerService,
    private commonMessageKeys: CommonMessageKeys) { }

  ngOnInit() {
    setTimeout(() => {
      this.loadCompanies();
      this.loadUser();
    },10)
  }
  loadUser() {
    this.route.parent.params.subscribe(params => {
      const idKey = 'id';
      const id = +params[idKey];

      if (id) {
        this.userService.get(id)
          .then((response: ContractHttpResponse<User>) => {
            if (response.Success === true) {
              this.model = response.Source;
              this.originalModal = JSON.parse(JSON.stringify(this.model));
            }
          });
      } else {
      }
    });
  }

  onSave() {
    this.userService.saveOrUpdate(this.model)
      .then((response: ContractHttpResponse<User>) => {
        if (response.Success === true) {
          this.originalModal = JSON.parse(JSON.stringify(this.model));
          this.userService.onUserUpdated.emit({ userId: this.model.ID , fullName : this.model.FirstName + ' ' + this.model.LastName});
          this.utilityService.displaySuccessMessage(this.commonMessageKeys.RECORD_SAVED_SUCCESSFULLY+' Profile change will reflect after you log out and log in again.');
        }
        else{
          this.utilityService.displayErrorMessage(response.Message);
        }
      },
        (error) => {
          if (error.error) {
            this.utilityService.displayErrorMessage(error.error.Message);
          } else {
            this.utilityService.displayErrorMessage(error.message);
          }
        }
      );
  }

  onCancel() {
    this.model = JSON.parse(JSON.stringify(this.originalModal));
  }

  loadCompanies(){
    this.companyService.getAll()
    .then((response: ContractHttpResponse<Company[]>) => {
      if (response.Success == true) {
        this.companies = this.companies.concat(response.Source);
      }
      else {
      }
    });
  }

  OnCompanyChanged(event: any) {
    this.companyService.getTeams(event.newSelection.value.ID)
      .then((response: ContractHttpResponse<Team[]>) => {
        if (response.Success === true) {
          this.teams = response.Source;
        }
        else {
        }
      });
  }
}
