import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Attachment } from 'src/app/information/models/attachment.mode.l';
import { AttachmentService } from 'src/app/information/services/attachment.service';
import {
	ContractHttpResponse,
	UtilityService,
} from 'ssi-framework';

@Component({
	selector: 'app-download-attachment',
	templateUrl: './download-attachment.component.html',
	styleUrls: ['./download-attachment.component.scss'],
})
export class DownloadAttachmentComponent {
	constructor(private _attachmentProxy: AttachmentService, private route: ActivatedRoute,private utilityService: UtilityService) {}
	message = "";
	isHidden= false;
	ngOnInit(): void {
		this.route.params.subscribe((params) => {
			const idKey = 'id';
			const id = params[idKey];
			if (id) {
				this.downloadImage(id);
			}
		});
	}
	downloadImage(guid: string): void {
		this.message = "Please wait. It will take few seconds..."
		this._attachmentProxy.downloadImageBase64(0, guid).then((response: ContractHttpResponse<Attachment>) => {
			if (response != null && response.Success && response.Source) {
				const a = document.createElement('a'); //Create <a>
				a.href = response.Source.ImageBase64; //Image Base64 Goes here
				a.download = response.Source.FileName; //File name Here
				a.target = '_blank';
				a.click(); //Downloaded file
				this.utilityService.displaySuccessMessage("File download successfully.");
				this.message = "File download successfully.";
				this.isHidden = true;
			}
		});
	}
}
