import { WebApiProxyFactoryService, IWebApiProxy, UserContextService, PermissionManagerService, IOrderColumn } from 'ssi-framework';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'ssi-framework';
import { Role } from '../models/role.model';
import { SortingDirection } from 'igniteui-angular';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class RoleService extends BaseApiService<Role> {
    private proxy: IWebApiProxy;
    private showAllDataPermission = 'VIEW_ALL_COMPANY_DATA';
    constructor(private proxyFactory: WebApiProxyFactoryService, private userContext: UserContextService,
         private permissionService: PermissionManagerService) {
        super();
        this.proxy = this.proxyFactory.GetProxy('Role');
    }

    registerRoutes(route: ActivatedRoute) {
    }

    public get(id: number): Promise<any> {
        const request = this.proxy.Get('Get', {id});
        return request;
    }

    public getPaged(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any, orderColumns?: IOrderColumn[]): Promise<any> {
        let companyId = 0;
        if (this.permissionService.checkPermission(this.showAllDataPermission)) {
            companyId = filter.companyId ? filter.companyId : 0
        } else {
            companyId = this.userContext.companyId;
        }
        const request = this.proxy.Get('GetPage', {
            code: filter.code ? filter.code : '',
            name: filter.name ? filter.name : '',
            showPrivate: false,
            companyId,
            searchText: filter.searchText ? filter.searchText : '',
            applicationCode: this.userContext.appCode,
            pageIndex,
            pageSize,
            orderBy: orderBy ? orderBy : 'ID',
            orderDirection: (!orderDir || orderDir === 0) ? 0 : orderDir - 1
        },null, null, orderColumns);
        return request;
    }

    public getByUserCompany(): Promise<any> {
        const request = this.proxy.Get('GetByAppAndCompany', { applicationCode: this.userContext.appCode,
             companyID: this.userContext.companyId });
        return request;
    }

    public getByCompany(companyId: number): Promise<any> {
        const request = this.proxy.Get('GetByAppAndCompany', { applicationCode: this.userContext.appCode, companyID: companyId });
        return request;
    }

    public getAll(): Promise<any> {
        if (this.permissionService.checkPermission(this.showAllDataPermission)) {
            const request = this.proxy.Get('GetAll');
            return request;
        } else {
            return this.getByUserCompany();
        }
    }

    public getByUserId(userId: number): Promise<any> {
        const request = this.proxy.Get('GetByUserID', { userId });
        return request;
    }

    public validate(obj: Role): boolean {
        return true;
    }

    public copyRole(model: Role): Promise<any> {
        const roleId = 'roleId';
        const code = 'code';
        const name = 'name';
        const description = 'description';
        const companyId = 'companyId';

        const request = this.proxy.Get('Copy',{
            roleId: model.ID,
            code: model.Code,
            name: model.Name,
            description: model.Description ? model.Description : '',
            companyId: model.CompanyID
        });
        return request;
    }

    public saveOrUpdate(obj: Role): Promise<any> {
        if (obj) {
            obj.IsActive = true;

            if (!obj.ID || obj.ID === 0) {
                obj.ApplicationCode = this.userContext.appCode;

                if (this.permissionService.checkPermission(this.showAllDataPermission) === false) {
                    obj.CompanyID = this.userContext.companyId;
                }
            }
            const request = this.proxy.Post('SaveOrUpdate', obj);
            return request;
        }
    }

    delete(id: number): Promise<any> {
        const request = this.proxy.Post('Delete', [id]);
        return request;
    }

    savePermissionAssignments(roleId: number, permissionIds: number[]) {
        const request = this.proxy.Post('SavePermissionAssignments', { ID: roleId, SelectedIDs: permissionIds });
        return request;
    }

}