<igx-dialog #dialog  [message]="message">
  <igx-dialog-title>
    <div class="dialog-container">
      <div class="dialog-title">{{header}}</div>
    </div>
    <ng-container *ngIf="showCrossIcon">
      <div class="action-close"> <a href="javascript:;">
          <span class="material-icons" (click)="Close($event)">close</span></a></div>
    </ng-container>
  </igx-dialog-title>
  <div igxDialogActions>
    <button igxButton class="btn-primary" (click)="Close($event)">{{rightButtonLabel}}</button>
  </div>
</igx-dialog>