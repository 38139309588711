<ng-template #basicPager>
  <div class="grid-paginator">
    <span>
      Showing {{(_selectedPage * (_perPage)) - (+(_perPage) - 1) }} - {{ _selectedPage == _totalPage ? ((_selectedPage *
      +(_perPage)) + _pageRows) - _perPage :
      (_selectedPage * +(_perPage)) }} of {{_totalRows}}
      Records
    </span>
    <select (change)="pageSizeChanged($event.target.value)" class="form-control">
      <option [value]="val" [selected]="_perPage == val" *ngFor="let val of _pages">{{ val }}
      </option>
    </select>
    <button [disabled]="hasFirstPage" (click)="goToPage(1)">
      <igx-icon>first_page</igx-icon>
    </button>
    <button [disabled]="hasPreviousPage" (click)="goToPage(_selectedPage-1)">
      <igx-icon>chevron_left</igx-icon>
    </button>
    <span>{{ _selectedPage }} of {{_totalPage}}</span>
    <button [disabled]="hasNextPage" (click)="goToPage(_selectedPage+1)">
      <igx-icon>chevron_right</igx-icon>
    </button>
    <button [disabled]="hasLastPage" (click)="goToPage(_totalPage)">
      <igx-icon>last_page</igx-icon>
    </button>
  </div>
</ng-template>