import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import {
	IgxAvatarModule,
	IgxBadgeModule,
	IgxButtonModule,
	IgxGridModule,
	IgxIconModule,
	IgxInputGroupModule,
	IgxProgressBarModule,
	IgxRippleModule,
	IgxSwitchModule,
} from 'igniteui-angular';
import { SsiFrameworkModule, RouteHandlerService } from 'ssi-framework';
import { FormsModule } from '@angular/forms';
import { RoleHandlerService } from '../Common/services/role-handler.service';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { UserAffiliationLvComponent } from './UserAffiliation/user-affiliation-lv/user-affiliation-lv.component';
import { SharedModule } from '../shared/shared.module';

const coreRoutes: Routes = [
	{ path: 'home', component: HomeComponent, data: { mainRoute: true }, canActivate: [RouteHandlerService] }, //todo add in canActivate RoleHandlerService
	{ path: 'access-denied', component: AccessDeniedComponent, data: { mainRoute: true } },
	{ path: 'userAffilation', component: UserAffiliationLvComponent, canActivate: [RouteHandlerService] },
];

@NgModule({
	imports: [
		RouterModule.forRoot(coreRoutes),
		CommonModule,
		FormsModule,
		IgxAvatarModule,
		IgxBadgeModule,
		IgxButtonModule,
		IgxGridModule,
		IgxIconModule,
		IgxInputGroupModule,
		IgxProgressBarModule,
		IgxRippleModule,
		IgxSwitchModule,
		SsiFrameworkModule,
		SharedModule,
	],
	declarations: [HomeComponent, AccessDeniedComponent, UserAffiliationLvComponent],
	exports: [],
})
export class CoreModule {}
