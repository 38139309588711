import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class CommonMessageKeys {
	public readonly RECORD_SAVED_SUCCESSFULLY: string = 'Record(s) saved successfully.';
	public readonly ERROR_SAVING_RECORD: string = 'Error in saving record.';
	public readonly RECORD_UPDATED_SUCCESSFULLY: string = 'Record(s) updated successfully.';
	public readonly ERROR_UPDATING_RECORD: string = 'Error in updating record.';
	public readonly RECORD_DELETED_SUCCESSFULLY: string = 'Selected record has been deleted successfully.';
	public readonly SET_ContactPerson_SUCCESSFULLY: string =
		'Selected employee(s) were Set as Contact Person Successfuly.';
	public readonly SET_PrimaryContactPerson_SUCCESSFULLY: string =
		'Selected employee(s) were Set as Primary Contact Person Successfuly.';
	public readonly REMOVE_ContactPerson_SUCCESSFULLY: string =
		'Selected employee(s) were Removed as Contact Person Successfuly.';
	public readonly REMOVE_PrimaryContactPerson_SUCCESSFULLY: string =
		'Selected employee(s) were Removed as Primary Contact Person Successfuly.';
	public readonly MANDATORY_FIELD_ERROR: string = 'Please fill all mandatory fields.';
	public readonly MANDATORY_QUESTIONS_ERROR: string = 'Please mark all the questions to continue.';

	public readonly INVALID_EMAIL_ADDRESS: string = 'Please provide valid Email Address.';
	public readonly INVALID_CELL_NUMBER: string = 'Please provide valid Cell Number.';
	public readonly FILE_UPLOAD_SUCCESSFULL: string = 'File uploaded successfully';
	public readonly FILE_SIZE_ERROR: string = 'Please upload less than or equal to {0} sized file.';
	public readonly INVALID_IMAGE_FILE_SELECTED_ERROR: string = 'Please select a file with a valid image format.';
	public readonly ROLE_ALREADY_EXIST: string = 'Record with the same code/name already exists';
	public readonly EMAIL_SENT_SUCCESSFULLY: string = 'Email sent successfully.';

	public static FormatString(str: string, val: string[]): string {
		if (val) {
			for (let index = 0; index < val.length; index++) {
				str = str.replace(`{${index}}`, val[index]);
			}
		}
		return str;
	}
}
