<div class="portlet portlet__wrap">
  <div class="ssi-formGroup">
    <div class="btn-holder">
      <ul class="btnlist">
        <li><button ssiTooltip="{{'SAVE_BTN_TTP' | ssiTranslator }}" igxButton type="submit" class="btn-primary"
            (click)="saveInternal()" tabindex="7"><span class="icon icon-save"></span>{{ 'SAVE_BTN' | ssiTranslator
            }}</button></li>
        <li><button ssiTooltip="{{'CANCEL_BTN_TTP' | ssiTranslator }}" igxButton type="submit" (click)="onCancel()"
            tabindex="9">
            <span class="icon icon-times-circle"></span>{{ 'CANCEL_BTN' | ssiTranslator }}</button>
        </li>
        <li *ngIf="model.ID" class="li-badge">
          <span>{{'SYSTEM_ID_LBL' | ssiTranslator }}: {{model.ID}}</span>
        </li>
      </ul>
    </div>
    <div class="ssi-FourColForm">
      <div class="row">
        <div class="col control-holder">
          <igx-input-group>
            <label igxLabel for="FileName">{{'NAME_LBL' | ssiTranslator }}</label>
            <input igxInput igxFocus="true" [(ngModel)]="model.FileName" name="FileName" type="text" [required]="true"
              autocomplete="none" tabindex="1" [disabled]="this.addOrEdit == 'Edit'" />
          </igx-input-group>
          
        </div>
       
        <igx-checkbox [checked]="model.IsMasterLayout" [(ngModel)]="model.IsMasterLayout" (change)="sourceSelection($event)" >Is Master Template</igx-checkbox>
        <div  class="col control-holder">
         
        <ng-container *ngIf="!model.IsMasterLayout">
          <lib-ssi-select #masterLayout label="{{'Master Layout' | ssiTranslator }}" name="MasterLayoutName" [items]="templateList"
          itemKey="FileName" itemText="FileName" [singleSelect]="true" [(singleModel)]="model.MasterLayoutName"
          [required]="true" tabindex="2" (onSelectionChanging)="parentTemplateSelect($event)">
        </lib-ssi-select>
        </ng-container>
          
        </div>
      </div>
    </div>
    <div class="ssi-TwoColForm">
      <div class="row">
        <div class="col control-holder">
          <textarea [(ngModel)]="model.Content" ></textarea>
        </div>
        <div class="col control-holder">
          <iframe [srcdoc]="model.Content"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>