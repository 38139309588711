import { IgxGridComponent, IgxComboComponent, IgxColumnComponent, SortingDirection, IgxDatePickerComponent, ISortingExpression, IgxExcelExporterService, IgxExcelExporterOptions, IColumnExportingEventArgs, IgxCsvExporterService, CsvFileTypes, IgxCsvExporterOptions } from 'igniteui-angular';
import { IgxInputDirective } from '@infragistics/igniteui-angular';
import { EventEmitter, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ViewChildren, OnInit, Directive, Injector } from '@angular/core';
import type { QueryList } from '@angular/core';
import { ContractHttpResponse } from '../../WebApiClient/HttpHandler/contract-http-response';
import { PagedList } from '../../Infrastructure/Models/paged.model';
import { BasicGridPagerComponent } from '../../WebControls/Controls/grid/basic-grid-pager/basic-grid-pager.component';
import { UserSettingsService } from '../Services/user-settings.service';
import { GridSettings, ColumnSetting } from '../../Infrastructure/Models/grid-settings.model';
import { UtilityService } from '../../Common/utility.service';
import { CommonMessageKeys } from '../../Common/common-message-keys';
import { SsiDropdownComponent } from '../../WebControls/Controls/ssi-dropdown/ssi-dropdown.component';
import { SsiFilterComponent } from '../../WebControls/Controls/ssi-filter/ssi-filter.component';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { SsiTimePickerComponent } from '../../WebControls/Controls/ssi-time-picker/ssi-time-picker.component';
import { SsiButtonGroupComponent } from '../../WebControls/Controls/ssi-buttongroup/ssi-buttongroup.component';
import { SsiSelectComponent } from '../../WebControls/Controls/ssi-select/ssi-select.component';
import { IOrderColumn } from '../../Infrastructure/Models/order-column.model';
import { AppInjector } from '../../ssi-framework.module';
import { SsiInputComponent } from '../../WebControls/Controls/ssi-input/ssi-input.component';
import { SsiNumberComponent } from '../../WebControls/Controls/ssi-number/ssi-number.component';
import { SsiDatepickerComponent } from '../../WebControls/Controls/ssi-datepicker/ssi-datepicker.component';

@Directive()
export abstract class BaseLVComponent<T> implements OnInit {

  protected abstract get gridRef(): IgxGridComponent;

  @ViewChildren(SsiFilterComponent) filterComponents: QueryList<SsiFilterComponent>;

  @ViewChildren(IgxInputDirective) inputs: QueryList<IgxInputDirective>;
  @ViewChildren(IgxComboComponent) combos: QueryList<IgxComboComponent>;
  @ViewChildren(SsiDropdownComponent) dropdowns: QueryList<SsiDropdownComponent>;
  @ViewChildren(SsiSelectComponent) ssiSelects: QueryList<SsiSelectComponent>;
  @ViewChildren(SsiTimePickerComponent) timePickers: QueryList<SsiTimePickerComponent>;
  @ViewChildren(IgxDatePickerComponent) datePickers: QueryList<IgxDatePickerComponent>;
  @ViewChildren(SsiButtonGroupComponent) buttonGroups: QueryList<SsiButtonGroupComponent>;
  @ViewChildren(SsiInputComponent) ssiInputs: QueryList<SsiInputComponent>;
  @ViewChildren(SsiNumberComponent) ssiNumbers: QueryList<SsiNumberComponent>;
  @ViewChildren(SsiDatepickerComponent) ssiDatePickers: QueryList<SsiDatepickerComponent>;

  private pagerComponent: BasicGridPagerComponent;
  private data: PagedList<T>;

  private paginateGridCallback = new EventEmitter();

  private userSettingsApplied = false;
  private filterObj: any = {};
  public filters: any = [];
  private reload = false;

  protected isVisible = true;

  private get allDatePickers() {
    const allDatePickers = [...this.datePickers, ...this.ssiDatePickers.map(x => x.datePickerComponent)];
    return allDatePickers;
  }

  private get allInputs() {
    const allInputs = [...this.inputs, ...this.ssiInputs.map(x => x.igxInput), ...this.ssiNumbers.map(x => x.igxInput)];
    return allInputs;
  }

  protected abstract onBeforeLoad(): Promise<any>;
  protected abstract onAfterLoad(data: PagedList<T>);
  protected abstract getData(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any, orderColumns?: IOrderColumn[]): Promise<any>;

  protected constructor(protected viewRef: ViewContainerRef,
    private componentResolver: ComponentFactoryResolver,
    private userSettingsService: UserSettingsService,
    protected utilityService: UtilityService,
    protected commonMessageKeys: CommonMessageKeys,
    protected router: Router, protected route: ActivatedRoute) {
    const path = this.router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && event.url === path && this.route.routeConfig.data && this.route.routeConfig.data.persistState) {
        if (this.reload === true) {
          this.setTitle();
          this.reloadData();
        }
        this.reload = true;
      }

      if (event instanceof NavigationEnd && event.url === path) {
        this.isVisible = true;
      } else if (event instanceof NavigationStart) {
        this.isVisible = false;
      }
    });
  }

  ngOnInit(): void {
    const loaded = this.onBeforeLoad();
    loaded.then(n => {

      setTimeout(() => {
        if (this.filterComponents && this.filterComponents.first) {
          this.filterComponents.first.gridId = this.gridRef.id;
        }

        this.setTitle();
        this.paginateGridCallback.subscribe(pageEvent => {
          this.gridRef.perPage = pageEvent.perPage;

          this.saveGridSettings(pageEvent.perPage);
          this.loadData(pageEvent.page, pageEvent.perPage);
        });

        this.gridRef.onSortingDone.subscribe(sortEvent => {

          this.loadData(this.gridRef.page, this.gridRef.perPage);
        });
        this.loadData(this.gridRef.page, this.gridRef.perPage);
      }, 100);

    }, error => {

    });
  }

  public setTitle() {
  }

  public onSavedFiltersLoad(filterObj: any): any {
  }

  public reloadData(navigateToTop = true) {
    // override filters if needed
    const overridenFilters = this.onSavedFiltersLoad(this.filterObj);
    if (overridenFilters) {
      this.filterObj = overridenFilters;
    } else {
      this.filterObj = this.getFilterValues();
    }

    this.loadData(this.pagerComponent._selectedPage, this.gridRef.perPage, navigateToTop);
  }

  public onApplyFilter(filter?: any, navigateToTop = true) {
    // filter passed from Filter Tags
    if (filter) {
      if (filter.fieldName) {
        // this.userSettingsApplied = true;
        this.clearRemovedFilter(filter);
      } else {
        this.clearFilters();
        this.filterObj = filter;
        if(filter.filterDetails){
          this.filterObj = filter.filter;
          this.filters = filter.filterDetails;
        }
      }
      this.setFilterValues(this.filterObj, false);
    } else {
      this.filterObj = this.getFilterValues();
    }
    this.loadData(this.gridRef.page, this.gridRef.perPage, navigateToTop);
    this.saveRecentSearchSettings();
  }

  public onSaveAndApplyFilter(removedFilter?: any) {
    // filter passed from Filter Tags
    if (removedFilter) {
      this.clearRemovedFilter(removedFilter);
      this.setFilterValues(this.filterObj, false);
    } else {
      this.filterObj = this.getFilterValues();
    }
    this.loadData(this.gridRef.page, this.gridRef.perPage);

    const filterSettings = {
      filterValues: this.filterObj,
      filterDetails: this.filters
    };
    this.userSettingsService.saveFilterSettings(this.gridRef.id, filterSettings);
    this.saveRecentSearchSettings();
  }

  clearRemovedFilter(removedFilter: any) {
    const value = this.filterObj[removedFilter.fieldName];
    if (Array.isArray(value)) {
      this.filterObj[removedFilter.fieldName] = value.filter(n => n !== removedFilter.value);
    } else {
      this.filterObj[removedFilter.fieldName] = null;
    }
  }

  public onResetFilter() {
    this.filterObj = {};
    this.filters = [];
    this.userSettingsApplied = true;
    this.resetFilterValues();

    this.loadData(this.gridRef.page, this.gridRef.perPage);
    if (this.filterComponents && this.filterComponents.first) {
      this.filterComponents.first.filterObject = [];
    }
  }

  // for recent searches reset all filters
  public clearFilters() {
    this.resetFilterValues();
    this.filterObj = {};
    //this.filters = [];
  }

  protected setFilterValues(filterObj: any, clearFilterTags: boolean = true, ssiSelectDummyItem = null) {
    if (clearFilterTags) {
      this.filters = [];
    }

    const searchKey = 'searchText'
    const key = 'name'
    if (filterObj != null) {
      this.buttonGroups.forEach(n => {
        if (n.items) {
          const values = this.filterObj[n.name];
          n.selectedItems = [];
          n.selectedItemIndexes = [];
          if (values != null) {
            let i = 0;
            n.items.forEach(item => {
              if ((Array.isArray(values) && values.some(v => v === item[n.itemKey]))
                || (!Array.isArray(values) && values === item[n.itemKey])) {
                n.selectedItems.push(item);
                n.selectedItemIndexes.push(i);

                // this.filters.push({
                //   fieldName: n.name,
                //   key: item[n.itemText],
                //   value: item[n.itemKey],
                //   label: n.label
                // });
              }
              i++;
            });
            n.setSelection(n.selectedItems);
          } else {
            n.setSelection([]);
          }
        }
      });

      this.allInputs.forEach(n => {
        if (this.checkIfControlIsFilter(n.nativeElement)) {
          this.setIgxInputValue(n, filterObj[n.nativeElement.attributes[key].nodeValue]);

          // if (filterObj[n.nativeElement.attributes[key].nodeValue] != null) {
          //   this.filters.push({
          //     fieldName: n.nativeElement.attributes[key].nodeValue,
          //     key: filterObj[n.nativeElement.attributes[key].nodeValue],
          //     value: filterObj[n.nativeElement.attributes[key].nodeValue],
          //     label: n.nativeElement.title
          //   });
          // }
        }
      });

      this.combos.forEach(n => {
        if (this.checkIfControlIsFilter(n.comboInput.nativeElement)) {
          const name = this.GetNameByNode(n.comboInput.nativeElement, 'igx-combo');
          if (name) {
            this.setIgxComboValue(n, filterObj[name], name);
          }
        }
      });

      this.ssiSelects.forEach(n => {
        if (this.checkIfControlIsFilter(n.ssiCombo.comboInput.nativeElement)) {
          const name = this.GetNameByNode(n.ssiCombo.comboInput.nativeElement, 'igx-combo');
          if (name) {
            let dummyItems = null;
            if (ssiSelectDummyItem && ssiSelectDummyItem.field === name) {
              dummyItems = ssiSelectDummyItem.items;
            }
            this.setSsiSelectValue(n, filterObj, name, dummyItems);
          }
        }
      });

      this.dropdowns.forEach(n => {
        if (this.checkIfControlIsFilter(n.element.nativeElement)) {
          if (n.items.filter(m => m[n.itemKey] === filterObj[n.name]).length) {
            n.value = filterObj[n.name];
            // Tag filters
            if (n.value != null && n.value.toString().length) {

              const item = n.items.filter(m => m[n.itemKey] === n.value);
              if (item && item[0][n.itemText]) {
                // this.filters.push({
                //   fieldName: n.name,
                //   key: item[0][n.itemText],
                //   value: filterObj[n.name],
                //   label: n.label
                // });

                n.selectedText = item[0][n.itemText];
              }
            }
          } else {
            n.value = null;
          }
        }
      });

      this.timePickers.forEach(picker => {
        // Todo: check if it is part of filter and bring picker from id to name for consistency
        const value = filterObj[picker.picker.id];
        // Tag filters
        if (value != null) {
          // this.filters.push({
          //   fieldName: picker.picker.id,
          //   key: value,
          //   value,
          //   label: picker.label
          // });
          this.setDateTimePickerValue(picker, value);
        } else {
          picker.model = null;
        }
      });

      this.allDatePickers.forEach(picker => {
        // Todo: check if it is part of filter and bring picker from id to name for consistency
        const value = filterObj[picker.id];
        // Tag filters
        if (value != null) {
          // this.filters.push({
          //   fieldName: picker.id,
          //   key: value,
          //   value,
          //   label: (picker as any).element.nativeElement.title
          // });
          this.setDatePickerValue(picker, value);
        } else {
          picker.value = null;
        }
      });

      if (this.filterObj[searchKey] != null) {
        // this.filters.push({
        //   fieldName: 'searchText',
        //   key: this.filterObj[searchKey],
        //   value: this.filterObj[searchKey],
        //   label: 'General Search'
        // });
      }
      if (this.filterComponents && this.filterComponents.first) {
        this.filterComponents.first.filterObject = this.filters;

        this.filterComponents.first.applyGlobalFilterValue(this.filterObj[searchKey]);
      }
    }
  }

  protected resetFilterValues() {
    this.buttonGroups.forEach(n => {
      n.selectedItems = [];
      n.selectedItemIndexes = [];
      n.setSelection([]);
    })

    this.allInputs.forEach(n => {
      if (this.checkIfControlIsFilter(n.nativeElement)) {
        this.setIgxInputValue(n, '');
      }
    });

    this.combos.forEach(n => {
      if (this.checkIfControlIsFilter(n.comboInput.nativeElement)) {
        this.setIgxComboValue(n, []);
      }
    });

    this.ssiSelects.forEach(n => {
      if (this.checkIfControlIsFilter(n.ssiCombo.comboInput.nativeElement)) {
        const name = this.GetNameByNode(n.ssiCombo.comboInput.nativeElement, 'igx-combo');
        this.setSsiSelectValue(n, [], name);
      }
    });

    this.dropdowns.forEach(n => {
      if (this.checkIfControlIsFilter(n.element.nativeElement)) {
        n.selectedIndex = -1;
      }
    });

    this.timePickers.forEach(picker => {
      picker.model = null;
    });

    this.allDatePickers.forEach(picker => {
      picker.value = null;
    });
  }

  protected getFilterValues(): any {
    const filter = {};
    this.filters = [];
    const key = 'name';
    const searchKey = 'searchText'

    this.buttonGroups.forEach(n => {
      if (n.selectedItems) {
        const values = [];
        n.selectedItems.forEach(item => {
          values.push(item[n.itemKey]);
          this.filters.push({
            fieldName: n.name,
            key: item[n.itemText],
            value: item[n.itemKey],
            label: n.label
          });
        });
        if (n.multiSelection) {
          filter[n.name] = values;
        } else {
          filter[n.name] = values[0];
        }
      }
    })

    this.allInputs.forEach(n => {
      if (this.checkIfControlIsFilter(n.nativeElement)) {
        if (n.value) {
          filter[n.nativeElement.attributes[key].nodeValue] = n.value;
          if (n.value) {
            this.filters.push({
              fieldName: n.nativeElement.attributes[key].nodeValue,
              key: n.value,
              value: n.value,
              label: n.nativeElement.title
            });
          }
        }
      }
    });

    this.combos.forEach(n => {
      if (this.checkIfControlIsFilter(n.comboInput.nativeElement)) {
        const name = this.GetNameByNode(n.comboInput.nativeElement, 'igx-combo');
        if (name) {
          const itemValues = n.selectedItems();
          const items = n.data.filter(x => itemValues.some(y => y === x[n.valueKey]));
          if (items) {
            const itemIds = items.map(value => {
              if (value[n.valueKey]) {
                this.filters.push({
                  fieldName: name,
                  key: value[n.displayKey],
                  value: value[n.valueKey],
                  label: (n as any).elementRef.nativeElement.title
                });
              }
              return value[n.valueKey];
            });
            if (itemIds) {
              filter[name] = itemIds.length ? itemIds : [];
            }
          }
        }
      }
    });

    this.ssiSelects.forEach(n => {
      if (this.checkIfControlIsFilter(n.ssiCombo.comboInput.nativeElement)) {
        const name = this.GetNameByNode(n.ssiCombo.comboInput.nativeElement, 'igx-combo');
        if (name) {
          let items = [];
          if (n.initialRemoteItems == undefined || n.initialRemoteItems == null) {
            n.initialRemoteItems = [];
          }
          if (!n.singleSelect && n.hasRemoteData) {
            const multiSelectValues = n.ssiCombo.selectedItems();
            items = n.allItems.filter(x => multiSelectValues.some(y => y === x[n.ssiCombo.valueKey]));
            if (multiSelectValues.length !== items.length) {
              const remainingItemValues = multiSelectValues.filter(x => !items.some(y => y[n.ssiCombo.valueKey] === x));
              items.push(...n.initialRemoteItems.filter(x => remainingItemValues.some(y => y === x[n.ssiCombo.valueKey])));
            }
          } else {
            const itemValues = n.ssiCombo.selectedItems();
            if (n.hasRemoteData) {
              items = n.allItems.filter(x => itemValues.some(y => y === x[n.ssiCombo.valueKey]));
              if (itemValues.length !== items.length) {
                const remainingItemValues = itemValues.filter(x => !items.some(y => y[n.ssiCombo.valueKey] === x));
                items.push(...n.initialRemoteItems.filter(x => remainingItemValues.some(y => y === x[n.ssiCombo.valueKey])));
              }
            } else {
              items = n.ssiCombo.data.filter(x => itemValues.some(y => y === x[n.ssiCombo.valueKey]));
            }
          }
          if (items) {
            const itemIds = items.map(value => {
              if (value[n.ssiCombo.valueKey]) {
                this.filters.push({
                  fieldName: name,
                  key: value[n.ssiCombo.displayKey],
                  value: value[n.ssiCombo.valueKey],
                  label: (n as any).label
                });
              }
              return value[n.ssiCombo.valueKey];
            });
            if (itemIds) {
              if (n.singleSelect) {
                filter[name] = itemIds.length ? itemIds[0] : null;
              } else {
                filter[name] = itemIds.length ? itemIds : null;
              }
            }
          }
        }
      }
    });

    this.timePickers.forEach(picker => {
      // Todo: check if it is part of filter and bring picker from id to name for consistency
      if (picker.model && picker.IsfilterTimePicker) {
        filter[picker.picker.id] = picker.dropDownTarget.nativeElement.value;

        this.filters.push({
          fieldName: picker.picker.id,
          key: picker.dropDownTarget.nativeElement.value,
          value: picker.dropDownTarget.nativeElement.value,
          label: picker.label
        });
      }
    });

    this.allDatePickers.forEach(picker => {
      // Todo: check if it is part of filter and bring picker from id to name for consistency
      if (this.checkIfControlIsFilter(picker.element.nativeElement)) {
        if (picker.displayData) {
          filter[picker.id] = picker.displayData;

          this.filters.push({
            fieldName: picker.id,
            key: picker.displayData,
            value: picker.displayData,
            label: (picker as any).element.nativeElement.title
          });
        }
      }
    });

    this.dropdowns.forEach(n => {
      if (this.checkIfControlIsFilter(n.element.nativeElement)) {
        filter[n.name] = n.value;
        const item = n.items.find(m => m[n.itemKey] === n.value);
        if (n.value > -1 && item) {
          this.filters.push({
            fieldName: n.name,
            key: item[n.itemText],
            value: n.value,
            label: n.label
          });
        }

      }
    });

    if (this.filterComponents.length && this.filterComponents.first.searchText) {
      filter[searchKey] = this.filterComponents.first.searchText;
      this.filters.push({
        fieldName: 'searchText',
        key: this.filterComponents.first.searchText,
        value: this.filterComponents.first.searchText,
        label: 'General Search'
      });
    } else {
      filter[searchKey] = null;
    }

    if (this.filterComponents && this.filterComponents.first) {
      this.filterComponents.first.filterObject = this.filters;
      this.filterComponents.first.applyGlobalFilterValue(filter[searchKey]);
    }

    return filter;
  }

  protected getFilterNames(): any {
    const filter: any = {};

    this.buttonGroups.forEach(n => {
      if (n.selectedItems) {
        const values = [];
        n.selectedItems.forEach(item => {
          values.push(item[n.itemText]);
        });
        if (n.multiSelection) {
          filter[n.name] = values;
        } else {
          filter[n.name] = values[0];
        }
      }
    })

    this.allInputs.forEach(n => {
      if (this.checkIfControlIsFilter(n.nativeElement)) {
        if (n.value) {
          filter[n.nativeElement.attributes['name'].nodeValue] = n.value;
        }
      }
    });

    this.combos.forEach(n => {
      if (this.checkIfControlIsFilter(n.comboInput.nativeElement)) {
        const name = this.GetNameByNode(n.comboInput.nativeElement, 'igx-combo');
        if (name) {
          const itemValues = n.selectedItems();
          const items = n.data.filter(x => itemValues.some(y => y === x[n.valueKey]));
          if (items) {
            const itemNames = items.filter(value => value[n.valueKey]).map(value => {
              return value[n.displayKey];
            });
            if (itemNames) {
              filter[name] = itemNames.length ? itemNames : [];
            }
          }
        }
      }
    });

    this.ssiSelects.forEach(n => {
      if (this.checkIfControlIsFilter(n.ssiCombo.comboInput.nativeElement)) {
        const name = this.GetNameByNode(n.ssiCombo.comboInput.nativeElement, 'igx-combo');
        if (name) {
          let items = [];
          if (!n.singleSelect && n.hasRemoteData) {
            const multiSelectValues = n.ssiCombo.selectedItems();
            items = n.allItems.filter(x => multiSelectValues.some(y => y === x[n.ssiCombo.valueKey]));
            if (multiSelectValues.length !== items.length) {
              const remainingItemValues = multiSelectValues.filter(x => !items.some(y => y[n.ssiCombo.valueKey] === x));
              items.push(...n.initialRemoteItems.filter(x => remainingItemValues.some(y => y === x[n.ssiCombo.valueKey])));
            }
          } else {
            const itemValues = n.ssiCombo.selectedItems();
            if (n.hasRemoteData) {
              items = n.allItems.filter(x => itemValues.some(y => y === x[n.ssiCombo.valueKey]));
              if (itemValues.length !== items.length) {
                const remainingItemValues = itemValues.filter(x => !items.some(y => y[n.ssiCombo.valueKey] === x));
                items.push(...n.initialRemoteItems.filter(x => remainingItemValues.some(y => y === x[n.ssiCombo.valueKey])));
              }
            } else {
              items = n.ssiCombo.data.filter(x => itemValues.some(y => y === x[n.ssiCombo.valueKey]));
            }
          }
          if (items) {
            const itemNames = items.filter(value => value[n.ssiCombo.valueKey]).map(value => {
              return value[n.ssiCombo.displayKey];
            });
            if (itemNames) {
              filter[name] = itemNames.length ? itemNames : [];
            }
          }
        }
      }
    });

    this.timePickers.forEach(picker => {
      // Todo: check if it is part of filter and bring picker from id to name for consistency
      if (picker.dropDownTarget.nativeElement.value) {
        filter[picker.picker.id] = picker.dropDownTarget.nativeElement.value;
      }
    });

    this.allDatePickers.forEach(picker => {
      // Todo: check if it is part of filter and bring picker from id to name for consistency
      if (picker.displayData) {
        filter[picker.id] = picker.displayData;
      }

    });

    this.dropdowns.forEach(n => {
      if (this.checkIfControlIsFilter(n.element.nativeElement)) {
        if (n.value > -1 && n.selectedText) {
          filter[n.name] = n.selectedText;
        }

      }
    });

    if (this.filterComponents.length && this.filterComponents.first.searchText) {
      filter.searchText = this.filterComponents.first.searchText;
    } else {
      filter.searchText = null;
    }

    return filter;
  }

  protected getFilterLabels(): any {
    const filter = {};
    const searchKey = 'searchText'
    const key = 'name';
    this.buttonGroups.forEach(n => {
      if (n.selectedItems) {
        filter[n.name] = n.label;
      }
    })

    this.allInputs.forEach(n => {
      if (this.checkIfControlIsFilter(n.nativeElement)) {
        if (n.nativeElement.title) {
          filter[n.nativeElement.attributes[key].nodeValue] = n.nativeElement.title;
        }
      }
    });

    this.combos.forEach(n => {
      if (this.checkIfControlIsFilter(n.comboInput.nativeElement)) {
        const name = this.GetNameByNode(n.comboInput.nativeElement, 'igx-combo');
        if (name) {
          const items = n.selectedItems();
          if (items) {
            filter[name] = (n as any).elementRef.nativeElement.title;
          }
        }
      }
    });

    this.ssiSelects.forEach(n => {
      if (this.checkIfControlIsFilter(n.ssiCombo.comboInput.nativeElement)) {
        const name = this.GetNameByNode(n.ssiCombo.comboInput.nativeElement, 'igx-combo');
        if (name) {
          const items = n.ssiCombo.selectedItems();
          if (items) {
            filter[name] = n.label;
          }
        }
      }
    });

    this.timePickers.forEach(picker => {
      // Todo: check if it is part of filter and bring picker from id to name for consistency
      if (picker.dropDownTarget.nativeElement.value) {
        filter[picker.picker.id] = picker.label;
      }
    });

    this.allDatePickers.forEach(picker => {
      // Todo: check if it is part of filter and bring picker from id to name for consistency
      if (picker.displayData) {
        filter[picker.id] = (picker as any).element.nativeElement.title;
      }
    });

    this.dropdowns.forEach(n => {
      if (this.checkIfControlIsFilter(n.element.nativeElement)) {
        if (n.value > -1 && n.selectedText) {
          filter[n.name] = n.label;
        }
      }
    });

    if (this.filterComponents.length && this.filterComponents.first.searchText) {
      filter[searchKey] = 'General Search';
    } else {
      filter[searchKey] = null;
    }

    return filter;
  }

  private loadData(pageIndex: number, pageSize: number, navigateToTop = true) {
    this.applySavedFilter();

    let isortExp: ISortingExpression [] = [];
    let orderColumns : IOrderColumn [] = null;
    const sortExpression = this.gridRef.sortingExpressions.length
      ? this.gridRef.sortingExpressions[0]
      :  { fieldName: '', dir: SortingDirection.None, ignoreCase: true };
      if (this.gridRef.nativeElement.attributes['ng-reflect-enable-multi-column-sort'] !== undefined
      && this.gridRef.nativeElement.attributes['ng-reflect-enable-multi-column-sort'].value == 'true') {

        isortExp = this.gridRef.sortingExpressions.length > 0 ? isortExp = this.gridRef.sortingExpressions : isortExp = [];
        orderColumns = [];
        if (isortExp.length > 0) {
          isortExp.forEach( x=> {
            let obj : IOrderColumn = {
              OrderBy : x.fieldName,
              OrderDirection : (!x.dir || x.dir === 0) ? 0 : x.dir - 1
            }

            orderColumns.push(obj);
          })
        }
      }

    const request = this.getData(pageIndex, pageSize, sortExpression.fieldName, sortExpression.dir, this.filterObj, orderColumns);
    request.then((response: ContractHttpResponse<PagedList<T>>) => {
      if (response.Success === true) {
        this.data = response.Source;

        this.initGrid();
      }
      else {
        this.data = null;
        this.utilityService.displayErrorMessage(response.Message);
      }

      this.onAfterLoad(this.data);
      if (navigateToTop) {
        this.gridRef.navigateTo(0);
      }
    }, error => {
    });
  }

  private initGrid() {
    this.gridRef.data = this.data.Source;

    if (!this.pagerComponent) {
      const factory: ComponentFactory<BasicGridPagerComponent> = this.componentResolver.resolveComponentFactory(BasicGridPagerComponent);
      const pager = this.viewRef.createComponent(factory);
      this.pagerComponent = pager.instance;
      this.pagerComponent.paginateGridCallback = this.paginateGridCallback;

      this.pagerComponent.init(this.data.Source.length, this.data.TotalRows, this.data.PageIndex, this.gridRef.perPage);

      this.gridRef.paginationTemplate = pager.instance.basicPager;
    } else {
      this.pagerComponent.init(this.data.Source.length, this.data.TotalRows, this.data.PageIndex, this.gridRef.perPage);
    }

    this.gridRef.reflow();
  }

  /**
   * @deprecated The method should not be used and removed in future
  */
  protected applyOnBeforeLoadFilters(filters : any): any  {
    if (filters != undefined && filters != null) {
      const namedFilterObj = this.getFilterNames();
      const valuedFilterObj = this.getFilterValues();
      const labelsObj = this.getFilterLabels();
      if (Object.keys(namedFilterObj).filter(key => namedFilterObj[key] && namedFilterObj[key] !== '').length) {
        Object.keys(namedFilterObj).forEach(key => {
          if (!namedFilterObj[key] || namedFilterObj[key] === '') {
            delete namedFilterObj[key];
            delete valuedFilterObj[key];
            delete labelsObj[key];
          }
        });
      }
    }
}

  private applySavedFilter(): any {
    if (!this.userSettingsApplied) {
      this.userSettingsApplied = true;

      const filterSetting = this.userSettingsService.getFilterSettings(this.gridRef.id);
      if (filterSetting && filterSetting.filterValues) {
        this.filterObj = filterSetting.filterValues;
      }

      // override filters if needed
      const overridenFilters = this.onSavedFiltersLoad(this.filterObj);
      if (overridenFilters) {
        this.filterObj = overridenFilters;
      }

      if (filterSetting && filterSetting.filterDetails) {
        this.filters = filterSetting.filterDetails;
      }

      this.setFilterValues(this.filterObj, false);

      // Update chips if filters are overriden
      if (overridenFilters) {
        this.getFilterValues();
      }

      // work around for some issue when component is used in ssi-tab
      if (this.filterComponents && this.filterComponents.first && this.filterObj.searchText != null) {
        this.filterComponents.first.applyGlobalFilterValue(this.filterObj.searchText);
      }
    }
  }

  private checkIfControlIsFilter(nativeElement: any): boolean {
    let element: any = nativeElement;
    if (nativeElement.attributes.name) {
      while (element) {
        if (element.parentElement && element.parentElement.className.indexOf('ssi-filter') > -1) {
          return true;
        } else {
          element = element.parentElement;
        }
      }
    }
    return false;
  }

  private GetNameByNode(nativeElement: any, nodeName: string): string {
    let element: any = nativeElement;
    while (element) {
      if (element.attributes.name && element.localName === nodeName) {
        return element.attributes.name.nodeValue;
      } else {
        element = element.parentElement;
      }
    }
    return null;
  }

  private saveGridSettings(perPage: number) {
    let gridSettings = this.userSettingsService.getGridSettings(this.gridRef.id);
    if (gridSettings == null) {
      gridSettings = new GridSettings();
      gridSettings.ID = this.gridRef.id;
      gridSettings.PageSize = perPage;
      gridSettings.ColumnSettings = [];

      if (this.gridRef.columns != null && this.gridRef.columns.length > 0) {
        this.gridRef.columns.forEach((column: IgxColumnComponent) => {
          const columnSettings = new ColumnSetting();
          columnSettings.Key = column.field;
          columnSettings.Index = column.index;
          columnSettings.Hidden = column.hidden;
          columnSettings.Width = column.width;

          gridSettings.ColumnSettings.push(columnSettings);
        });
      }
    }
    else {
      gridSettings.PageSize = perPage;
    }

    this.userSettingsService.saveGridSettings(gridSettings);
  }

  private saveRecentSearchSettings() {
    const namedFilterObj = this.getFilterNames();
    const valuedFilterObj = this.getFilterValues();
    const labelsObj = this.getFilterLabels();
    if (Object.keys(namedFilterObj).filter(key => namedFilterObj[key] && namedFilterObj[key] !== '').length) {
      Object.keys(namedFilterObj).forEach(key => {
        if (!namedFilterObj[key] || namedFilterObj[key] === '') {
          delete namedFilterObj[key];
          delete valuedFilterObj[key];
          delete labelsObj[key];
        }
      });
      this.userSettingsService.saveRecentSearchSettings(this.gridRef.id, {
        named: namedFilterObj,
        valued: valuedFilterObj,
        labels: labelsObj,
        searchedOn: new Date(),
        filterDetails: this.filters
      });
    }
  }

  private setIgxInputValue(element: IgxInputDirective, value: any) {
    if (!value) {
      value = '';
    }
    element.value = value;
  }

  private setDateTimePickerValue(element: SsiTimePickerComponent, value: string) {
    if (value) {
      const splittedValue = value.split(':');
      if (splittedValue && splittedValue.length > 0) {
        let hours = Number(splittedValue[0]);
        const splits = splittedValue[1].split(' ');
        const minutes = Number(splits[0]);
        const amPm = splits[1];
        if (amPm === 'PM') {
          hours += 12;
        }
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        element.model = date;
      }
    }
  }

  private setDatePickerValue(element: IgxDatePickerComponent, value: string) {
    if (value) {
      const date = new Date(value);
      element.value = date;
    }
  }

  private setIgxComboValue(element: IgxComboComponent, values: any[], name?: string) {
    if (values) {
      values = Array.isArray(values) ? values : [values];
      const selectedItems: any[] = [];
      const selectedValues: any[] = values;

      element.data.forEach(n => {
        selectedValues.forEach(val => {
          if (val != null && n[element.valueKey] != null && val.toString() === n[element.valueKey].toString()) {
            selectedItems.push(n[element.valueKey]);

            // if (n[element.displayKey]) {
            //   this.filters.push({
            //     fieldName: name,
            //     key: n[element.displayKey],
            //     value: n[element.valueKey],
            //     label: (element as any).elementRef.nativeElement.title
            //   });
            // }
          }
        });
      });

      element.selectItems(selectedItems, true);
    }
  }

  private setSsiSelectValue(element: SsiSelectComponent, filterObj: any, name?: string, initialDummyItems?: any[]) {
    let selectedFilterValues = filterObj[name] ? filterObj[name] : [];
    if (selectedFilterValues) {
      selectedFilterValues = Array.isArray(selectedFilterValues) ? selectedFilterValues : [selectedFilterValues];
      const selectedItems: any[] = [];

      if (element.hasRemoteData) {
        if (initialDummyItems && initialDummyItems.length) {
          initialDummyItems.forEach(val => {
            this.filters.push({
              fieldName: name,
              key: val[element.ssiCombo.displayKey],
              value: val[element.ssiCombo.valueKey],
              label: element.label
            });
          });
        }

        selectedFilterValues.forEach(val => {
          if (val) {
            selectedItems.push(val);
          }
        });

        const dummyItems = [];
        const fileds = this.filters.filter(n => n.fieldName === name);
        if (fileds) {
          fileds.forEach(field => {
            const dummyItem = {};
            dummyItem[element.itemKey] = field.value;
            dummyItem[element.itemText] = field.key;
            dummyItems.push(dummyItem);
          });
        }
        element.initialRemoteItems = dummyItems;
      } else {
        element.ssiCombo.data.forEach(n => {
          selectedFilterValues.forEach(val => {
            if (val != null && n[element.ssiCombo.valueKey] != null
              && val.toString() === n[element.ssiCombo.valueKey].toString()) {
              selectedItems.push(n[element.ssiCombo.valueKey]);

              // if (n[element.ssiCombo.displayKey]) {
              //   this.filters.push({
              //     fieldName: name,
              //     key: n[element.ssiCombo.displayKey],
              //     value: n[element.ssiCombo.valueKey],
              //     label: element.label
              //   });
              // }
            }
          });
        });
      }

      element.ssiCombo.selectItems(selectedItems, true);
    }
  }

  exportExcelData(){
    const excelExportService  = AppInjector.get<IgxExcelExporterService>(IgxExcelExporterService);
      excelExportService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
          if (args.field == undefined) {
            args.cancel = true;
          }
        });
    excelExportService.export(this.gridRef, new IgxExcelExporterOptions(this.gridRef.id))
  }

  exportCSVData(){
    const CsvExportService  = AppInjector.get<IgxCsvExporterService>(IgxCsvExporterService);
    CsvExportService.onColumnExport.subscribe((args: IColumnExportingEventArgs) => {
          if (args.field == undefined) {
            args.cancel = true;
          }
        });

    CsvExportService.export(this.gridRef, new IgxCsvExporterOptions(this.gridRef.id, CsvFileTypes.CSV))

  }

}
