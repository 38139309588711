import { Company } from './../../models/company.model';
import { Component, ViewContainerRef, Injector } from '@angular/core';
import { BaseAEComponent, LayoutService, RouteHandlerService, UtilityService, CommonMessageKeys,
        ContractHttpResponse,
        SsiTranslatorPipe} from 'ssi-framework';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../../Services/company.service';
import { ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment } from 'igniteui-angular';

@Component({
  selector: 'ssi-identity-company-ae',
  templateUrl: './company-ae.component.html',
  styleUrls: ['./company-ae.component.scss']
})
export class CompanyAeComponent extends BaseAEComponent<Company> {

  private _ssiTranslatorPipe : SsiTranslatorPipe;
  model: Company = new Company();
  companies: Company[] = [] ;
  public overlaySettings = {
    positionStrategy: new ConnectedPositioningStrategy({
      horizontalDirection: HorizontalAlignment.Right,
      horizontalStartPoint: HorizontalAlignment.Right,
      verticalStartPoint: VerticalAlignment.Top
    })
  };

  constructor(private layoutService: LayoutService, private _injector: Injector,route: ActivatedRoute,
     router: Router, routeHandlerService: RouteHandlerService,
              private companyService: CompanyService, viewRef: ViewContainerRef,
              utilityService: UtilityService, commonMessageKeys: CommonMessageKeys) {
    super(route, router, routeHandlerService, viewRef, utilityService, commonMessageKeys);
    this._ssiTranslatorPipe = new SsiTranslatorPipe(_injector);
    const title = this._ssiTranslatorPipe.transform('COMPANY_AE_TITLE');
    this.layoutService.setTitle(title);
  }

  get(id: number): Promise<any> {
    return this.companyService.get(id);
  }

  onBeforeAddEdit() {
    this.loodLookups();
  }

  onAfterAddEdit(obj: Company) {
  }
  validate(obj: Company): boolean {
    return true;
  }
  saveInternal(obj: Company): Promise<any> {
    return this.companyService.saveOrUpdate(obj);
  }

  private loodLookups(): void {
    this.companyService.getAll().then((response: ContractHttpResponse<Company[]>) => {
      if (response.Success === true) {
        this.companies = this.companies.concat(response.Source);
        if (this.companies.length > 0) {
          this.model.ID = this.companies[0].ID;
        }
      }
    });
  }
}
