<div class="grid-wrap">
  <igx-grid id="FWNotifEventGrid" #FWNotifEventGrid ssiGrid [enableToolbar]="true" [data]="notificationEvents">
    <igx-column field="Name" header="{{'NOTIFICATION_LBL' | ssiTranslator }}" [sortable]="true"></igx-column>

    <igx-column header="{{'EMAIL_LBL' | ssiTranslator }}" field="Email" [sortable]="false">
      <ng-template igxHeader let-column>
        <igx-checkbox (change)="onEmailSelectionChange($event)">{{column.field}}</igx-checkbox>
      </ng-template>
      <ng-template igxCell let-user="cell">
        <igx-checkbox [disabled]="user.row.rowData.IsEmailDisabled" [(ngModel)]="user.row.rowData.Email"></igx-checkbox>
      </ng-template>
    </igx-column>

    <igx-column field="InApp" [sortable]="false" header="{{'IN_APP_LBL' | ssiTranslator }}">
      <ng-template igxHeader let-column>
        <igx-checkbox (change)="onInAppSelectionChange($event)">{{column.field}}</igx-checkbox>
      </ng-template>
      <ng-template igxCell let-user="cell">
        <igx-checkbox [disabled]="user.row.rowData.IsInAppDisabled" [(ngModel)]="user.row.rowData.InApp"></igx-checkbox>
      </ng-template>
    </igx-column>

    <igx-column field="SMS" [sortable]="false" header="{{'SMS_LBL' | ssiTranslator }}">
      <ng-template igxHeader let-column>
        <igx-checkbox (change)="onSMSSelectionChange($event)">{{column.field}}</igx-checkbox>
      </ng-template>
      <ng-template igxCell let-user="cell">
        <igx-checkbox [disabled]="user.row.rowData.IsSMSDisabled" [(ngModel)]="user.row.rowData.SMS"></igx-checkbox>
      </ng-template>
    </igx-column>

    <igx-column field="Push" [sortable]="false" header="{{'PUSH_LBL' | ssiTranslator }}">
      <ng-template igxHeader let-column>
        <igx-checkbox (change)="onPushSelectionChange($event)">{{column.field}}</igx-checkbox>
      </ng-template>
      <ng-template igxCell let-user="cell">
        <igx-checkbox [disabled]="user.row.rowData.IsPushDisabled" [(ngModel)]="user.row.rowData.Push"></igx-checkbox>
      </ng-template>
    </igx-column>

    <ng-template igxToolbarCustomContent let-igxGrid="grid">
      <div class="action-area">
        <ul class="btn-action-list">
          <li><button igxButton type="submit" class="btn-primary" (click)="onSave()"
              *ngIf="permissionMangerService.checkPermission('EDIT_USER')"
              ssiTooltip="{{'SAVE_BTN_TTP' | ssiTranslator }}">
              <span class="icon icon-save"></span>{{'SAVE_BTN' | ssiTranslator }}</button></li>
          <!-- <li><button igxButton type="submit" (click)="onCancel()" ssiTooltip="Cancel">
              <span class="icon icon-times-circle"></span>Cancel</button></li> -->
        </ul>
      </div>
    </ng-template>
  </igx-grid>
</div>