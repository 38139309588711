<div class="main-content-holder row">
  <div class=" col p0">
    <igx-grid #FWCompanyGrid id="FWCompanyGrid" ssiGrid [actionItems]="items" [enableDefault]="true">

      <igx-column field="Code" header="{{'CODE_LBL' | ssiTranslator }}"></igx-column>

      <igx-column field="Name" header="{{'NAME_LBL' | ssiTranslator }}">
        <ng-template igxCell let-val let-cell="cell">
          <a href="#" [routerLink]="['/Companies/Edit', cell.cellID.rowID.ID]">
            {{ val }}
          </a>
        </ng-template>
      </igx-column>

      <igx-column field="AddressLine1" header="{{'ADDRESS_1_LBL' | ssiTranslator }}"></igx-column>
      <igx-column field="AddressLine2" header="{{'ADDRESS_2_LBL' | ssiTranslator }}"></igx-column>
      <igx-column field="AddressTown" header="{{'TOWN_LBL' | ssiTranslator }}"></igx-column>
      <igx-column field="AddressPostCode" header="{{'POST_CODE_LBL' | ssiTranslator }}"></igx-column>
      <igx-column field="AddressCountry" header="{{'COUNTRY_LBL' | ssiTranslator }}"></igx-column>
      <igx-column field="IsActive" header="{{'ACTIVE_LBL' | ssiTranslator }}" [headerClasses]="'column-center'" [cellClasses]="{'column-center' : true }">
        <ng-template igxCell let-value>
          <span *ngIf="value" class="icon icon-check-circle static"></span>
        </ng-template>
      </igx-column>

      <igx-column field="IsPrivate" header="Private" [headerClasses]="'column-center'" [cellClasses]="{'column-center' : true }">
        <ng-template igxCell let-value>
          <span *ngIf="value" class="icon icon-check-circle static"></span>
        </ng-template>
      </igx-column>


      <igx-column field="CreatedBy" header="{{'CREATED_BY_LBL' | ssiTranslator }}"></igx-column>

      <igx-column field="CreatedOn" header="{{'CREATED_ON_LBL' | ssiTranslator }}">
        <ng-template igxCell let-value>
          {{ value | ssiDate :"datetime" : true}}
        </ng-template>
      </igx-column>

      <igx-column field="ModifiedBy" header="{{'MODIFIED_BY_LBL' | ssiTranslator }}" [hidden]="true"></igx-column>

      <igx-column field="ModifiedOn" header="{{'MODIFIED_ON_LBL' | ssiTranslator }}" [hidden]="true">
        <ng-template igxCell let-value>
          {{ value | ssiDate :"datetime" : true}}
        </ng-template>
      </igx-column>

      <igx-column></igx-column>

      <ng-template igxToolbarCustomContent let-igxGrid="grid">

        <div class="action-area">
          <ul class="btn-action-list">
            <li>
              <a [routerLink]="['/Companies/Add']">
                <button igxButton="flat" class="btn-primary" ssiTooltip="Add Company">
                  <span class="icon icon-add"></span>{{'ADD_BTN' | ssiTranslator }}
                </button>
              </a>
            </li>
          </ul>
        </div>

        <lib-ssi-filter #ssiGridFilter class="filter" (search)="onApplyFilter($event)"
          (saveAndSearch)="onSaveAndApplyFilter()" (resetSearch)="onResetFilter()">
          <div class="ssi-ThreeColForm">
            <div class="row">
              <div class="col control-holder">
                <igx-input-group>
                  <label igxLabel for="code">{{'CODE_LBL' | ssiTranslator }}</label>
                  <input igxInput name="code" type="text"  autocomplete="none"/>
                </igx-input-group>
              </div>
              <div class="col control-holder">
                <igx-input-group>
                  <label igxLabel for="address">{{'ADDRESS_LBL' | ssiTranslator }}</label>
                  <input igxInput name="address" type="text"  autocomplete="none"/>
                </igx-input-group>
              </div>
              <div class="col control-holder">
                <igx-input-group>
                  <label igxLabel for="name">{{'NAME_LBL' | ssiTranslator }}</label>
                  <input igxInput name="name" type="text"  autocomplete="none" />
                </igx-input-group>
              </div>
              <div class="col control-holder">
                <igx-input-group>
                  <label igxLabel for="country">{{'COUNTRY_LBL' | ssiTranslator }}</label>
                  <input igxInput name="country" type="text"   autocomplete="none"/>
                </igx-input-group>
              </div>
            </div>
          </div>
        </lib-ssi-filter>

      </ng-template>


    </igx-grid>
  </div>
</div>
