<div class="main-content-holder row grid-notifications grid-row-selection">
    <div class="col p0">
        <igx-grid #FWNotificationGrid id="FWNotificationGrid" ssiGrid [enableDefault]="true" [rowSelection]="'single'"
            (onRowSelectionChange)="handleRowSelection($event)" [hideRowSelectors]="true">
            <igx-column field="SubjectTemplate" header="{{'SUBJECT_LBL' | ssiTranslator }}">
            </igx-column>
            <igx-column field="NotificationMode" header="{{'NOTIFICATION_MODE_LBL' | ssiTranslator }}">
                <ng-template igxCell let-value>
                    {{ addSpace(value) }}
                </ng-template>
            </igx-column>
            <igx-column field="StartDate" header="Start Date">
                <ng-template igxCell let-value>
                    {{ value | ssiDate :"date" }}
                </ng-template>
            </igx-column>
            <igx-column field="EndDate" header="End Date">
                <ng-template igxCell let-value>
                    {{ value | ssiDate :"date" }}
                </ng-template>
            </igx-column>
            <igx-column field="CreatedBy" header="Sent By"></igx-column>
            <igx-column field="CreatedOn" header="Sent On">
                <ng-template igxCell let-value>
                    {{ value | ssiDate :"datetime" : true}}
                </ng-template>
            </igx-column>
            <igx-column></igx-column>
            <ng-template igxToolbarCustomContent let-igxGrid="grid">
                <div class="action-area">
                    <ul class="btn-action-list">
                        <li>
                        </li>
                    </ul>
                </div>

                <lib-ssi-filter #ssiGridFilter class="filter" (search)="onApplyFilter($event)"
                    (saveAndSearch)="onSaveAndApplyFilter()" (resetSearch)="onResetFilter()" [containerWidth]="800">
                    <div class="ssi-ThreeColForm">
                        <div class="row">
                            <div class="col control-holder">
                                <igx-input-group>
                                    <label igxLabel for="subject">{{'SUBJECT_LBL' | ssiTranslator }}</label>
                                    <input igxInput name="subject" type="text" autocomplete="none" />
                                </igx-input-group>
                            </div>
                            <div class="col control-holder">
                                <lib-ssi-select label="Notification Modes" name="modes"
                                    [items]="notificationModes" itemKey="Value" itemText="Text">
                                </lib-ssi-select>
                            </div>
                            <div class="col control-holder">
                                <lib-ssi-select label="Notification Status" name="statuses"
                                    [items]="notificationStatuses" itemKey="Value" itemText="Text">
                                </lib-ssi-select>
                            </div>
                            <div class="col control-holder">
                                <igx-input-group>
                                    <label igxLabel for="recievedByUserId">Receiver</label>
                                    <input igxInput name="recievedByUserId" type="text" autocomplete="none" />
                                </igx-input-group>
                            </div>
                            <div class="col control-holder">
                                <igx-input-group>
                                    <label igxLabel for="recievedByEmail">Receiver Email</label>
                                    <input igxInput name="recievedByEmail" type="text" autocomplete="none" />
                                </igx-input-group>
                            </div>
                            <div class="col control-holder">
                                <igx-input-group>
                                    <label igxLabel for="recievedByPhone">Receiver Phone</label>
                                    <input igxInput name="recievedByPhone" type="text" autocomplete="none" />
                                </igx-input-group>
                            </div>
                            <div class="col control-holder">
                                <igx-input-group>
                                    <label igxLabel for="createdBy">Sent By</label>
                                    <input igxInput name="createdBy" type="text" autocomplete="none" />
                                </igx-input-group>
                            </div>
                        </div>
                    </div>
                </lib-ssi-filter>
            </ng-template>
        </igx-grid>
    </div>
</div>
<lib-ssi-notification-recipient-lv [notificationID]="notificationID" [recipients]="recipients">
</lib-ssi-notification-recipient-lv>