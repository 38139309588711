<div class="ssi-select" [ngClass]="!showClearButton ?  'clear-icon-hide' : ''">
  <label class="combo-select--label" *ngIf="labelOutsideBox" [ngClass]="{'required': required}">{{ label }}</label>
  <igx-combo #ssiCombo #combo="ngModel" [placeholder]="placeHolder" class="input-container" [data]="items"
    displayKey="{{ itemText }}" valueKey="{{ itemKey }}" [disabled]="disabled" tabindex="{{ index }}"
    title="{{ title }}" [(ngModel)]="model" [igxFocus]="isFocused" [required]="required ? 'required' : null"
    [filterable]="!filterableItemCount || (filterableItemCount && items?.length > filterableItemCount)"
    [searchPlaceholder]="searchPlaceholder" [type]="type" (onOpening)="onOpening($event)">
    <label igxLabel *ngIf="!labelOutsideBox">{{ label }}</label>

    <ng-template igxComboHeader>
      <div class="dropdown-loader">
        <div class="progress-wrap" *ngIf="showLoader">
          <div class="progress-bar sm"></div>
        </div>
      </div>
    </ng-template>

    <ng-template igxComboClearIcon>
      <igx-icon (click)="clear()" *ngIf="showClearButton ? 'showClearButton' : null">clear</igx-icon>
    </ng-template>

    <ng-template *ngIf="template" igxComboItem let-display>
      <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ item:display }">
      </ng-container>
    </ng-template>

    <ng-template igxComboEmpty>
      <div class="combo--empty">
        Currently, there are no items to display.
      </div>
    </ng-template>
  </igx-combo>
  <ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
</div>

<ng-template #errorTemplate>
  <span class="combo-select--error">
    <ng-container *ngIf="customError">
      {{ customError }}
    </ng-container>
    <ng-container *ngIf="combo?.touched && !customError">
      <ng-container *ngIf="combo?.errors?.required">
        {{ label }} is a required field
      </ng-container>
    </ng-container>
  </span>
</ng-template>
