import { Component } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { ContractHttpResponse, UtilityService } from 'ssi-framework';
import { SharedMessagingKeys } from 'src/app/shared/common/shared-messaging-keys.service';
import { CommonConstants } from 'src/app/Common/constants/constants';
import { environment } from 'src/environments/environment';
import { Meta } from '@angular/platform-browser';
import { ResetPasswordService } from 'src/app/information/services/resest-password.service';
import { CommonMessagingKeys } from 'src/app/Common/services/common-messaging-keys.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMessageKeys } from 'projects/ssi-identity/src/lib/Common/common-message-keys';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
	ClientName: string = environment.AppTitle;
	Email: string;
	Error: string;
	ErrorClass: string = 'help-block';
	ConfirmPassword: string;
	Password: string;
	guidId: string;
	type = 'password';
	type1 = 'password';
	isLinkExpired = false;
	InValidLink = false;
	constructor(private userService: UserService, private utilityService: UtilityService, private Meta: Meta, private resetPasswordService: ResetPasswordService, private activateRouter: ActivatedRoute, private router: Router) {
		this.activateRouter.queryParams.subscribe(params => {
			this.guidId = params['id'];
		});	
	}

	onSubmit() {
		if (this.validate()) {
			this.resetPasswordService.changePasswordByLink(this.guidId, this.Password, this.ConfirmPassword).then((r) => {
				if (r.Success) {
					this.utilityService.displaySuccessMessage("Password changed successfully");
					this.ErrorClass = "success-block"
					this.router.navigateByUrl('/login');
				} else  {
					if(r.Message == '[IDENTITY_PWD_NON_REPEATING]'){
						this.utilityService.displayErrorMessage(CommonMessagingKeys.PASSWORD_NOT_CHANGED);
					}
					else{
						this.utilityService.displayErrorMessage(r.Message);
					}
					
				}
			});
		} 
	}
	onBack(){
		this.router.navigateByUrl('/backupEmail');
	}

	validate(){
		// const specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		if ((this.Password === '' || this.Password == null || this.ConfirmPassword === '' || this.ConfirmPassword == null)) {
		this.utilityService.displayErrorMessage(CommonMessageKeys.MANDATORY_FIELD_ERROR);
		return false;
		}
		if(this.Password !== this.ConfirmPassword){
		this.utilityService.displayErrorMessage(CommonMessageKeys.ERROR_CONFIRM_PASSWORD_MISMATCH);
		return false;
		}
		// else if (specialChar.exec(this.Password)) {
		// 	this.utilityService.displayErrorMessage('Password must not contain special character');
		// 	return false;
		// }
		if(this.Password.length<8){
			this.utilityService.displayErrorMessage('Password must be at least eight characters long');
			return false;
		}
		else
		{
			return true;
		}
	}
	togglePassword(): void {
		if (this.type == 'text') {
			this.type = 'password';
		} else {
			this.type = 'text';
		}
	}

	toggleConfirmPassword(): void {
		if (this.type1 === 'text') {
			this.type1 = 'password';
		} else {
			this.type1 = 'text';
		}
	}

	handleToggleConfirmPasswordButtonClick(): void{
		this.toggleConfirmPassword();
	}

	handleTogglePasswordButtonClick(): void {
		this.togglePassword();
	}
	ngOnInit() {
		this.Meta.updateTag({ name: 'viewport', content: 'width=device-width' });
		this.resetPasswordService.IsLinkExpired(this.guidId).then((r) => {
			if (r.Success) {
				this.InValidLink = false;
				this.isLinkExpired = r.Source;
				if(this.isLinkExpired){
					this.utilityService.displayErrorMessage("Your reset link has expired. Please request a new one.");
				}
			} else  {
				this.InValidLink = true;
				this.utilityService.displayErrorMessage(r.Message)
			}
		});
	}

	//Reset content width on page redirect
	ngOnDestroy() {
		this.Meta.updateTag({ name: 'viewport', content: 'width=1366, initial-scale=0.5, maximum-scale=0.5' });
	}

	private validatePassword(password: string) {
		const exp: RegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/; 
		return exp.test(password);
	}
}
