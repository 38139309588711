<label *ngIf="label && label.length > 0" class="label-btn-group">{{label}}</label>
<igx-buttongroup [multiSelection]="multiSelection" class="igx-button-group--compact" (onSelect)="toggleSelection(true,$event.index, $event)"
  (onUnselect)="toggleSelection(false,$event.index, $event)">
  <button igxButton *ngFor="let obj of buttonData" [selected]="obj.isSelected">
    <ng-container *ngIf="template; else standardView" [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ item:obj.item }">
    </ng-container>
    <ng-template #standardView>
      {{obj.item[itemText]}}
    </ng-template>
    <ng-content></ng-content>
  </button>
</igx-buttongroup>