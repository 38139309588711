export class IdentityProvider
{
    public ID : number;
    public ClientID: number;
    public ClientCode: string;
    public ClientSecret: string;
    public ClientSecretID: number;
    public CreatedBy: string;
    public CreatedOn: Date;
    public EntityID: number;
    public CompanyID: number;
    public GrantType: string;
    public IsActive: boolean;
    public RoleIds : number[] = [];
    public IsDeleted: boolean;
    public IssuerDomain: string;
    public IssuerType: string;
    public IssuingAuthority: string;
    public ModifiedBy: string;
    public ModifiedOn: Date;
}   