import { Inject, Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { SharedConstant } from 'ssi-framework/lib/Helpers/shared-constant';
import { PermissionManagerService } from 'ssi-framework/public_api';
import { Subject, Observable } from 'rxjs';
import { LocalStorageKeys } from '../constants/constants';
import asyncLocalStorage from '../utils/AsyncLocalStorage';


@Injectable({
  providedIn: 'root'
})
export class RenaissantAuthenticateClientService {


  private queryParams: Params;

  constructor(private oauthService: OAuthService,private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params;
    });
  }


  public login(loginName: string, password: string, redirectUrl?: string) {    
    const request = this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(loginName, password);
    request.then((resp) => {
      asyncLocalStorage.getItem(LocalStorageKeys.TWO_FA_VERIFIED).then((value)=>{
        if(value==="true"){
          if (this.queryParams != null && this.queryParams.redirectUrl != null) {
            window.location.href = this.queryParams.redirectUrl;
            // this.router.navigateByUrl(this.queryParams["redirectUrl"]);
          } else if (redirectUrl) {
            window.location.href = redirectUrl;
            // this.router.navigateByUrl(redirectUrl);
          }
        }
      })
    });
    return request;
  }


}
