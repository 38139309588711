
import { DatePickerComponent } from './date-picker/date-picker.component';
import { Constants } from './../../../Common/constants';
import { Component, OnInit, Input, ContentChild, TemplateRef, ElementRef, Output, EventEmitter, ViewChild } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'lib-ssi-calendar',
  templateUrl: './ssi-calendar.component.html',
  styleUrls: ['./ssi-calendar.component.scss']
})
export class SSICalendarComponent implements OnInit {

  @Output() dateUpdated = new EventEmitter();

  @Input() tileData: Map<string, any>;
  // tslint:disable-next-line: no-input-rename
  @Input('defaultDate') date: any;
  @Input() viewType: string = Constants.CalendarViewType.week;
  @Input() firstDayOfWeek: number = Constants.StartDayOfWeek.sunday;
  @Input() monthDateFormat: string;
  @Input() dayDateFormat: string;
  @Input() displayDatePickerControlButtons: boolean;
  @Input() displayTileExtensionButton: boolean;
  @Input() displayWeekDayName = true;
  @Input() displayCalenderView = true;
  @ContentChild('RightHeader') rightHeader: TemplateRef<ElementRef>;
  @ContentChild('LeftHeader') leftHeader: TemplateRef<ElementRef>;
  @ContentChild('MonthTemplate') monthTemplate: TemplateRef<ElementRef>;
  @ContentChild('WeekTemplate') weekTemplate: TemplateRef<ElementRef>;
  @ContentChild('DayTemplate') dayTemplate: TemplateRef<ElementRef>;
  @ContentChild('TileExpandTemplate') tileExpandTemplate: TemplateRef<ElementRef>;
  @ContentChild('TileCollapseTemplate') tileCollapseTemplate: TemplateRef<ElementRef>;

  @ViewChild('datePicker')
  datePicker: DatePickerComponent;

  startDate;
  endDate;
  currentDate;
  activeDates = [];

  daysName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  constructor() {
  }

  ngOnInit() {
    this.initMoment();
    this.initActiveDates();
    this.populateActiveDates();
  }

  public onDateChanged(date) {
    this.date = date;
    this.currentDate = date.clone();
    this.initActiveDates();

    this.dateUpdated.emit({ startDate: this.startDate.toDate(), endDate: this.endDate.toDate(), viewType: this.viewType });
  }

  public changeView(viewType: string) {
    this.viewType = viewType;
    this.initActiveDates();
    this.populateActiveDates();
    this.datePicker.onViewChange(viewType);
  }

  public getCurrentDates() {
    return { startDate: this.startDate.toDate(), endDate: this.endDate.toDate()};
  }
  public reflow() {
    this.populateActiveDates();
  }

  public getViewType(viewType) {
    if (viewType === 'month') {
      return Constants.CalendarViewType.month;
    } else if (viewType === 'week') {
      return Constants.CalendarViewType.week;
    } else if (viewType === 'day') {
      return Constants.CalendarViewType.day;
    }
  }

  private initMoment() {
    moment.locale('en', {
      week: {
        dow: this.firstDayOfWeek,
        doy: moment.localeData('en').firstDayOfYear()
      }
    });

    if (this.date == null) {
      const temp = new Date();
      temp.setHours(0, 0, 0, 0);
      this.date = moment(temp);
    } else {
      this.date = moment(this.date);
    }
    this.currentDate = this.date.clone();
  }

  private initActiveDates() {
    if (this.viewType === Constants.CalendarViewType.month) {
      const firstDayOfMonth = moment(this.date.clone().startOf('month').format('YYYY-MM-DD'));
      const lastWeekOfMonth = firstDayOfMonth.clone().add(5, 'w');
      this.startDate = moment(firstDayOfMonth.year() + '-' + firstDayOfMonth.week() + '-' + 0, 'YYYY-w-e');
      this.endDate = moment(lastWeekOfMonth.year() + '-' + lastWeekOfMonth.week() + '-' + 6, 'YYYY-w-e');
    } else if (this.viewType === Constants.CalendarViewType.week) {
      this.startDate = moment(this.date.clone().weekday(0));
      this.endDate = moment(this.date.clone().weekday(6));
    } else if (this.viewType === Constants.CalendarViewType.day) {
      this.startDate = this.date.clone();
      this.endDate = this.date.clone();
    }
  }

  private populateActiveDates() {
    this.activeDates = [];
    const currDate = this.startDate.clone().startOf('day');
    const lastDate = this.endDate.clone().startOf('day');
    let i = 0;
    while (lastDate.diff(currDate) >= 0) {
      if (i % 7 === 0) {
        this.activeDates.push([]);
      }
      this.activeDates[this.activeDates.length - 1].push(currDate.clone());
      currDate.add(1, 'd');
      i++;
    }
  }

}
