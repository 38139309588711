<div class="portlet portlet__wrap">
  <div class="ssi-formGroup">
    <div class="btn-holder">
      <ul class="btnlist">
        <li><button ssiTooltip="{{'SAVE_BTN_TTP' | ssiTranslator }}" igxButton type="submit" class="btn-primary"
            (click)="onSave()" tabindex="7"><span class="icon icon-save"></span>{{ 'SAVE_BTN' | ssiTranslator
            }}</button></li>
        <li><button ssiTooltip="{{'CANCEL_BTN_TTP' | ssiTranslator }}" igxButton type="submit"
            (click)="onCancel('/NotificationEventConfigurations')" tabindex="9">
            <span class="icon icon-times-circle"></span>{{ 'CANCEL_BTN' | ssiTranslator }}</button>
        </li>
        <li *ngIf="model.ID > 0" class="li-badge">
          <span>{{'SYSTEM_ID_LBL' | ssiTranslator }}: {{model.ID}}</span>
        </li>
      </ul>
    </div>
    <div class="ssi-FourColForm">
      <div class="row">
        <div class="col control-holder">
          <igx-input-group>
            <label igxLabel for="EventName">{{'EVENT_NAME_LBL' | ssiTranslator }}</label>
            <input igxInput igxFocus="true" [(ngModel)]="model.EventName" name="EventName" type="text"
              autocomplete="none" tabindex="3" [disabled]="true" />
          </igx-input-group>
        </div>
        <div class="col control-holder">
          <igx-input-group>
            <label igxLabel for="NotificationMode">{{'NOTIFICATION_MODE_LBL' | ssiTranslator }}</label>
            <input igxInput igxFocus="true" [(ngModel)]="model.NotificationMode" name="NotificationMode" type="text"
              autocomplete="none" tabindex="4" [disabled]="true" />
          </igx-input-group>
        </div>
      </div>
      <div class="row">
        <div class="col control-holder">
          <lib-ssi-select #SubjectTemplate label="{{'SUBJECT_TEMPLATE_LBL' | ssiTranslator }}" name="SubjectTemplate"
            [items]="templates_list" itemKey="FileName" itemText="FileName" [singleSelect]="true" [required]="true"
            [(singleModel)]="model.SubjectTemplate" tabindex="2" (onSelectionChanging)="onSubjectChanging($event)">
          </lib-ssi-select>
        </div>
        <div class="col control-holder">
          <lib-ssi-select #MessageTemplate label="{{'MESSAGE_TEMPLATE_LBL' | ssiTranslator }}" name="MessageTemplate"
            [items]="templates_list" itemKey="FileName" itemText="FileName" [singleSelect]="true" [required]="true"
            [(singleModel)]="model.MessageTemplate" tabindex="1" (onSelectionChanging)="onMsgChanging($event)">
          </lib-ssi-select>
        </div>
      </div>
    </div>
  </div>
</div>