export class Constants {
    static IdentityClientFlows = class {
        public static get Implicit(): string { return 'Implicit'; }
        public static get ResourceOwner(): string { return 'ResourceOwner'; }
        public static get ClientCredentials(): string { return 'ClientCredentials'; }
      }

    static CalendarViewType = class {
      public static get month(): string { return 'month'; }
      public static get week(): string { return 'week'; }
      public static get day(): string { return 'day'; }
    }
    static StartDayOfWeek = class {
      public static get sunday(): number { return 0; }
      public static get monday(): number { return 1; }
      public static get tuesday(): number { return 2; }
      public static get wednesday(): number { return 3; }
      public static get thursday(): number { return 4; }
      public static get friday(): number { return 5; }
      public static get saturday(): number { return 6; }
    }
}

export enum Weekdays {
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
}

export enum Weekends {
  Saturday = 6,
  Sunday
}

export enum DateRangeTypeEnum {
  None = 'None',
  After = 'After',
  Before = 'Before',
  Between = 'Between',
  Specific = 'Specific',
  Weekdays = 'Weekdays',
  Weekends = 'Weekends',
}

