export class GridRowActionItem {
  public isDisabled = false;
  constructor(public componentRef: any,
    public key: string,
    public displayName: string,
    public permissionCode: string,
    public callback: string,
    public icon?: string,
    public hide?: boolean,
    public pinned?: boolean,
    public tooltip?: string) {
  }
}
