<div class="notificationLink">
  <span class="icon icon-bell-o"></span>
  <span *ngIf="unreadCount>0 && unreadCount>99" class="totalCount wide">
    99<sup>+</sup>
  </span>
  <span *ngIf="unreadCount>0 && unreadCount<=99" class="totalCount">
    {{unreadCount}}
  </span>
</div>
<div class="dropdown-menu dropdown-menu-right inappNotification">
  <div class="header">
    <strong class="title">Notifications</strong>
  </div>
  <div class="content_area">
    <ul class="dropdownList">
      <li *ngFor="let notification of notifications" (click)="notificationClicked(notification)"
        [ngClass]="{'notification-unread': notification.NotificationStatus != readStatus}">
        <div class="subjectArea">
          <span class="subject">{{notification.SubjectTemplate}}</span>
          <ul class="actionArea">
            <li>
              <span class="itme">{{getDate(notification.CreatedOn)}}</span>
            </li>
            <li>
              <span class="iconHolder">
                <igx-icon *ngIf="notification.NotificationStatus == readStatus">drafts</igx-icon>
                <igx-icon *ngIf="notification.NotificationStatus != readStatus">markunread</igx-icon>
              </span>
            </li>
          </ul>
        </div>
        <div class="message">
          <span>{{notification.MessageTemplate}}</span>
        </div>
      </li>
      <li *ngIf="notifications.length == 0" style="border:none;">
        <h3 class="text-center" style="padding:24px 0;">There are no notifications to display.</h3>
      </li>
    </ul>
  </div>
  <div class="footerActions">
    <button igxButton class="btn-primary" [routerLink]="[route]">View All</button>
    <button igxButton class="btn-light" (click)="MarkAllRead()">Mark All As Read</button>
  </div>
</div>

<lib-view-notification #viewNotificationComponent [viewDetailTemplate]="viewDetailTemplate"
  [selectedNotification]="selectedNotification"></lib-view-notification>