import { SortingDirection } from 'igniteui-angular';

export class ColumnSetting {
    public Index: number;
    public Key: string;
    public Width: string;
    public Hidden: boolean;
    public Pinned: boolean;
}

export class GridSettings {
    public ID: string;
    public PageSize: number;
    public OrderBy: string;
    public OrderDirection: SortingDirection;
    public ColumnSettings: ColumnSetting[];
}