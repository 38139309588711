import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { IgxInputDirective, IgxInputGroupType } from '@infragistics/igniteui-angular';
import { UtilityService } from '../../../Common/utility.service';

@Component({
  selector: 'lib-ssi-number',
  templateUrl: './ssi-number.component.html',
  styleUrls: ['./ssi-number.component.scss']
})
export class SsiNumberComponent implements OnInit {
  // Inputs
  @Input() groupType: IgxInputGroupType = 'line';
  @Input() labelOutsideBox = false;
  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() name: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() index: number;
  @Input() hint: string;
  @Input() decimalPlaces: number;
  @Input() min: number;
  @Input() max: number;
  @Input() isFocused: boolean = false;

  // Outputs
  @Output() private modelChange = new EventEmitter();
  @Output() onKeyUp: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() onKeyDown: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() onChanged: EventEmitter<Event> = new EventEmitter();

  // Reference
  @ViewChild('input', { static: false }) input: NgModel;
  @ViewChild(IgxInputDirective, { static: false }) public igxInput: IgxInputDirective;

  // NgModel
  @Input() get model() {
    return this.modelValue;
  }
  set model(val) {
    this.modelValue = val;
    this.modelChange.emit(this.modelValue);
  }

  // Private
  private modelValue: any;

  // Public
  customError: string;
  currentDecimalPlaces: number = 0;
  step: number;

  constructor(private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.setStep();
  }

  //#region Public Methods
  setError(error: string) {
    this.customError = error;
  }

  clearError() {
    this.customError = '';
  }

  onInputKeyDown(event: KeyboardEvent) {
    this.onKeyDown.emit(event);
  }

  onInputKeyUp(event: KeyboardEvent) {
    this.onKeyUp.emit(event);

    const modelSplit = String(this.model).split('.');
    if (modelSplit.length > 1) {
      let decimals = modelSplit[1];
      this.currentDecimalPlaces = decimals.length;
    } else {
      this.currentDecimalPlaces = 0;
    }
  }

  onInputChanged(event: Event) {
    this.onChanged.emit(event);

    if (this.model == undefined || this.model == null) { return; }

    // Revert to Max or Min
    this.revertToMinOrMax();

    // Trim Decimal Place
    this.trimDecimalPlaces();
  }

  reset() {
    this.input.control.reset();
  }

  get errorMessage(): string {
    if (this.customError) {
      return this.customError;
    } else if (this.input?.touched && this.input?.errors?.required && !this.customError) {
      return `${this.label} is a required field`;
    } else if (this.input?.dirty && !this.customError && this.utilityService.isNumeric(this.model)) {
      if (this.model < this.min) {
        return `Input should be greater than ${this.min}`;
      } else if (this.model > this.max) {
        return `Input should be less than ${this.max}`;
      } else if (!this.input?.errors && this.currentDecimalPlaces > this.decimalPlaces) {
        return `Decimal places should be less than ${this.decimalPlaces + 1}`;
      }
    }
    return '';
  }
  //#endregion

  //#region private methods
  private revertToMinOrMax() {
    if (this.model > this.max) {
      this.model = this.max;
      this.currentDecimalPlaces = 0;
    } else if (this.model < this.min) {
      this.model = this.min;
      this.currentDecimalPlaces = 0;
    }
  }

  private trimDecimalPlaces() {
    if (this.decimalPlaces >= 0) {
      const modelSplit = String(this.model).split('.');

      if (modelSplit.length > 1) {
        // We have a decimal
        let numbers = modelSplit[0];
        let decimals = modelSplit[1];
        if (decimals.length > this.decimalPlaces) {
          decimals = decimals.slice(0, this.decimalPlaces);
          this.currentDecimalPlaces = decimals.length;

          this.model = +`${numbers}.${decimals}`;
        }
      }
    }
  }

  private setStep() {
    let decimalPlaces = this.decimalPlaces >= 0 ? this.decimalPlaces : 20;
    const decimal = '1';
    const step = decimalPlaces == 0 ? 0 : +`0.${decimal.padStart(decimalPlaces, '0')}`;
    this.step = step;
  }
  //#endregion
}
