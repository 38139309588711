import { WebApi } from '../WebApiClient/Discovery/web-api';
import { Injectable } from '@angular/core';
import { DiscoveryConfiguration } from '../WebApiClient/Discovery/discovery-configuration';
import { IdentityClient } from '../Authentication/identity-client';
import { MessageInfo } from '../Infrastructure/Models/message-info.model';

@Injectable({
	providedIn: 'root',
})
export class SharedConstant {
	public localStorageKey: string;
	public discoveryServiceName = 'Registry';
	public webClientName: string;
	public availableWebApis: WebApi[] = [];

	public ssifwAppcode: string;
	public discoveryConfig: DiscoveryConfiguration;
	public clients: IdentityClient[];
	public webClient: IdentityClient;

	public accessiblePermissions: any[];
	public messageTexts: MessageInfo[];

	public PathsToMenuFile: string;

	public ThirdPartyCustomGrant: string;
	public CognitoSamlPlatformName: string;

	public InitConstants(environments: any) {
		this.localStorageKey = 'cch-shared-values-' + environments.Version;
		this.ssifwAppcode = environments.SSIFW_Appcode;
		this.webClientName = environments.AppWebClientName;

		this.discoveryConfig = environments.DiscoveryConfig;

		this.clients = environments.IdentityConfig;

		this.PathsToMenuFile = environments.PathsToMenuFile;

		this.ThirdPartyCustomGrant = environments.ThirdPartyCustomGrant;
		this.CognitoSamlPlatformName = environments.CognitoSamlPlatformName;
	}
	public IssuerTypes = class {
		public URL = 'URL';
	};
}
