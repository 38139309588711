import {
  Injectable, ComponentFactory, ViewContainerRef, ComponentFactoryResolver, ComponentRef, ApplicationRef,
  TemplateRef, ElementRef
} from '@angular/core';
import { IgxExcelExporterOptions, IgxExcelExporterService } from 'igniteui-angular';
import { SsiToastComponent } from '../WebControls/Controls/ssi-toast/ssi-toast.component';
import { SsiConfirmationComponent } from '../WebControls/Controls/ssi-confirmation/ssi-confirmation.component';
import { SsiLoaderComponent } from '../WebControls/Controls/ssi-loader/ssi-loader.component';
import { SsiMessageBoxComponent } from '../WebControls/Controls/ssi-message-box/ssi-message-box.component';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  private loaderComponents = new Map();

  constructor(private excelExportService: IgxExcelExporterService,
    private componentResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef) {
  }

  public arrayObjectIndexOf(myArray, property, searchTerm) {
    for (let i = 0, len = myArray.length; i < len; i++) {
      if (myArray[i][property] === searchTerm) return i;
    }
    return -1;
  }

  public getUniqueIdentifier(maxSize: number = 8, alphaNumeric: boolean) {
    let identifier = '';
    const possible = alphaNumeric ? 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890' : '1234567890';

    for (let i = 0; i < maxSize; i++) {
      identifier += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return identifier;
  }

  public exportToExcel(data: any, fileName: string) {
    this.excelExportService.exportData(data, new IgxExcelExporterOptions(fileName));
  }

  public displayLoadingOverlay(show: boolean) {
    if (document.getElementsByTagName('app-layout').length) {
      if (show) {
        document.getElementsByTagName('app-layout').item(0).classList.add('busy');
      } else {
        document.getElementsByTagName('app-layout').item(0).classList.remove('busy');
      }
    }
  }

  public displayContentAreaLoadingOverlay(show: boolean) {
    if (document.getElementById('page-content')) {
      if (show) {
        document.getElementById('page-content').classList.add('busy');
      } else {
        document.getElementById('page-content').classList.remove('busy');
      }
    }
  }

  public displaySuccessMessage(message: string | TemplateRef<ElementRef>) {
    const viewRef: ViewContainerRef = this.appRef.components[0].instance.viewRef as ViewContainerRef;
    const toastComponent = this.createToast(viewRef);
    toastComponent.instance.showToast(message, 'success');
    toastComponent.instance.onClose.subscribe(n => {
      if (n) {
        toastComponent.destroy();
      }
    });
  }

  public displayErrorMessage(message: string | TemplateRef<ElementRef>) {
    if (message != null) {
      const viewRef: ViewContainerRef = this.appRef.components[0].instance.viewRef as ViewContainerRef;
      const toastComponent = this.createToast(viewRef);
      toastComponent.instance.showToast(message, 'error');
      toastComponent.instance.onClose.subscribe(n => {
        if (n) {
          toastComponent.destroy();
        }
      })
    }
  }

  public displayWarningMessage(message: string | TemplateRef<ElementRef>) {
    const viewRef: ViewContainerRef = this.appRef.components[0].instance.viewRef as ViewContainerRef;
    const toastComponent = this.createToast(viewRef);
    toastComponent.instance.showToast(message, 'warning');
    toastComponent.instance.onClose.subscribe(n => {
      if (n) {
        toastComponent.destroy();
      }
    });
  }

  public displayInfoMessage(message: string) {
    const viewRef: ViewContainerRef = this.appRef.components[0].instance.viewRef as ViewContainerRef;
    const toastComponent = this.createToast(viewRef);
    toastComponent.instance.showToast(message, 'info');
    toastComponent.instance.onClose.subscribe(n => {
      if (n) {
        toastComponent.destroy();
      }
    });
  }

  public displayConfirmationMessage(message: string, showCrossIcon?: boolean): SsiConfirmationComponent {
    const viewRef: ViewContainerRef = this.appRef.components[0].instance.viewRef as ViewContainerRef;
    const confirmationComponent = this.createConfirmationDialog(viewRef);
    confirmationComponent.instance.show(message, showCrossIcon);
    confirmationComponent.instance.onConfirm.subscribe(n => {
      confirmationComponent.destroy();
    });
    confirmationComponent.instance.onCancel.subscribe(n => {
      confirmationComponent.destroy();
    });

    return confirmationComponent.instance;
  }

  public displayMessageBox(message: string): SsiMessageBoxComponent {
    const viewRef: ViewContainerRef = this.appRef.components[0].instance.viewRef as ViewContainerRef;
    const messageBoxComponent = this.createMessageDialog(viewRef);
    messageBoxComponent.instance.show(message);
    return messageBoxComponent.instance;
  }

  public displayComponentLoadingOverlay(show: boolean, componentReference: any) {
    const viewRef = componentReference.viewRef;
    if (show) {
      document.getElementsByTagName(viewRef.element.nativeElement.localName).item(0).classList.add('busy');
      this.createComponentLoadingOverlay(viewRef);
    } else {
      const loaderRef: any = this.loaderComponents.get(viewRef.element.nativeElement.localName);
      if (loaderRef) {
        loaderRef.count--;
        if (loaderRef.count < 1) {
          loaderRef.loader.destroy();
          this.loaderComponents.delete(viewRef.element.nativeElement.localName);
          document.getElementsByTagName(viewRef.element.nativeElement.localName).item(0).classList.remove('busy');
        } else {
          this.loaderComponents.set(viewRef.element.nativeElement.localName, loaderRef);
        }
      }
    }
  }

  private createComponentLoadingOverlay(viewRef: ViewContainerRef) {
    const loader: any = this.loaderComponents.get(viewRef.element.nativeElement.localName);
    if (loader) {
      loader.count++;
      this.loaderComponents.set(viewRef.element.nativeElement.localName, loader);
    } else {
      const factory: ComponentFactory<SsiLoaderComponent> = this.componentResolver.resolveComponentFactory(SsiLoaderComponent);
      const ref: any = viewRef.createComponent(factory);
      this.loaderComponents.set(viewRef.element.nativeElement.localName, { loader: ref, count: 1 });
    }
  }

  private createToast(viewRef: ViewContainerRef): ComponentRef<SsiToastComponent> {
    const factory: ComponentFactory<SsiToastComponent> = this.componentResolver.resolveComponentFactory(SsiToastComponent);
    const toast = viewRef.createComponent(factory);
    return toast;
  }

  private createConfirmationDialog(viewRef: ViewContainerRef): ComponentRef<SsiConfirmationComponent> {
    const factory: ComponentFactory<SsiConfirmationComponent> = this.componentResolver.resolveComponentFactory(SsiConfirmationComponent);
    const confim = viewRef.createComponent(factory);
    return confim;
  }

  private createMessageDialog(viewRef: ViewContainerRef): ComponentRef<SsiMessageBoxComponent> {
    const factory: ComponentFactory<SsiMessageBoxComponent> = this.componentResolver.resolveComponentFactory(SsiMessageBoxComponent);
    const confim = viewRef.createComponent(factory);
    return confim;
  }

  public exportDataToFile(data: any, fileType: string, fileName: string) {
    const newBlob = new Blob([this.base64ToArrayBuffer(data)], { type: fileType });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    const data1 = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data1;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
      window.URL.revokeObjectURL(data1);
    }, 100);

  }

  public getFileInfoDetails(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      if (file) {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          const data = new Uint8Array(fileReader.result as ArrayBuffer);

          const fileInfo: any = {};
          const fileSplits = file.name.split('.');
          fileInfo.Extension = '.' + fileSplits[fileSplits.length - 1];
          fileInfo.FileName = file.name;
          fileInfo.Content = this.ab2str(data);

          resolve(fileInfo);
        }

        fileReader.readAsArrayBuffer(file);
      } else {
        resolve(null);
      }
    });
  }

  public getFileURL(fileContent: any): string {
    return 'data:image/JPEG;base64,' + fileContent;
  }

  public base64ToArrayBuffer(base64): Uint8Array {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  public ab2str(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  public getLocalDateFromUTC(utcDate: Date): Date {
    const isoDate = utcDate.getFullYear() + '-' + this.setPadding(utcDate.getMonth() + 1, 2) + '-' + this.setPadding(utcDate.getDate(), 2) + 'T' + this.setPadding(utcDate.getHours(), 2) + ':' + this.setPadding(utcDate.getMinutes(), 2) + ':' + this.setPadding(utcDate.getSeconds(), 2) + '+00:00';
    utcDate = new Date(isoDate);
    return utcDate;
  }

  public setPadding(value, length) {
    value = value + '';
    return value.length >= length ? value : new Array(length - value.length + 1).join('0') + value;
  }

  public isNumeric(val: any): boolean {
    return typeof (val) === 'number' || (typeof (val) === 'string' && !isNaN(+val));
  }
}
