<label class="label" *ngIf="labelOutsideBox && (label || labelTemplate)" [ngClass]="{'required': required}">
  <ng-container [ngTemplateOutlet]="inputLabelTemplate"></ng-container>
</label>

<igx-input-group [type]="groupType" class="input-group__textarea">

  <label *ngIf="!labelOutsideBox && (label || labelTemplate)" igxLabel [for]="name">
    <ng-container [ngTemplateOutlet]="inputLabelTemplate"></ng-container>
  </label>

  <textarea #textArea="ngModel" igxInput [name]="name" [maxlength]="maxlength" [(ngModel)]="model"
    [placeholder]="placeholder" [required]="required" [disabled]="disabled" [readonly]="readonly" [rows]="rows"
    [cols]="cols" [tabindex]="index" (keydown)="onTextAreaKeyDown($event)" (keyup)="onTextAreaKeyUp($event)"
    (change)="onTextAreaChanged($event)" [igxFocus]="isFocused" [igxTextSelection]="selectOnFocus"></textarea>

</igx-input-group>

<!-- Hint -->
<div class="input-helper">
  <div>
    <div class="error">
      <ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
    </div>
    <div *ngIf="hint" class="desc">{{ hint }}</div>
  </div>
  <div *ngIf="maxlength && showCharacterCount" class="count">{{ model?.length ? model?.length : 0 }}/{{ maxlength }}
  </div>
</div>

<ng-template #inputLabelTemplate>
  <ng-container *ngIf="label">{{ label }}</ng-container>
  <ng-container *ngIf="labelTemplate" [ngTemplateOutlet]="labelTemplate"></ng-container>
</ng-template>

<!-- Error Template -->
<ng-template #errorTemplate>
  <ng-container *ngIf="textArea?.touched">
    <ng-container *ngIf="textArea?.errors?.required">
      {{ label }} is a required field
    </ng-container>
  </ng-container>
</ng-template>
