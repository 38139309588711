import { Injectable } from '@angular/core';
import { Lookup } from '../models/lookup.model';
import {
	WebApiProxyFactoryService,
	IWebApiProxy,
	BaseApiService,
	PermissionManagerService,
	ContractHttpResponse,
} from 'ssi-framework';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { SortingDirection } from 'igniteui-angular';
import { LookupWithExtraAttributes } from '../models/lookupWithExtraAttributes.model';

@Injectable({
	providedIn: 'root',
})
export class LookupsService extends BaseApiService<Lookup> {
	private readonly proxy: IWebApiProxy;

	constructor(private proxyFactory: WebApiProxyFactoryService, private permissionService: PermissionManagerService) {
		super();
		this.proxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'Lookup');
	}
	//eslint-disable-next-line
	public registerRoutes(route: ActivatedRoute) {}

	get(id: number): Promise<ContractHttpResponse<Lookup>> {
		const callback = this.proxy.Get(`${id}`);
		return callback;
	}

	public getAll(): Promise<ContractHttpResponse<Lookup[]>> {
		throw new Error('Method not implemented.');
	}

	public getPaged(
		pageIndex: number,
		pageSize: number,
		orderBy: string,
		orderDir: SortingDirection,
		//eslint-disable-next-line
		filter: any
	): //eslint-disable-next-line
	Promise<any> {
		const orgid = JSON.parse(filter.loginAffiliation)[0].DistrictID;
		const callback = this.proxy.Get('Search', {
			lookupCategoryId: filter.LookupCategoryID ? filter.LookupCategoryID : 0,
			// lookupCategoryCode: "",
			code: filter.txtCode ? filter.txtCode : '',
			name: filter.txtName ? filter.txtName : '',
			isActive: filter.IsActive,
			parentLookupId: filter.parentLookupId ? filter.parentLookupId : 0,
			OrganizationID: orgid ? orgid : 0,
			searchText: filter.searchText ? filter.searchText : '',
			pageIndex: pageIndex,
			pageSize: pageSize,
			orderBy: orderBy ? orderBy : 'ID',
			orderDirection: !orderDir ? 0 : orderDir - 1,
		});
		return callback;
	}
	//eslint-disable-next-line
	public validate(obj: Lookup): boolean {
		return true;
	}

	public saveOrUpdate(lookupDTO: Lookup): Promise<ContractHttpResponse<Lookup>> {
		if (lookupDTO.ID > 0) {
			const callback = this.proxy.Put('Update', lookupDTO);
			return callback;
		} else {
			const callback = this.proxy.Post('Save', lookupDTO);
			return callback;
		}
	}

	public delete(id: number): Promise<ContractHttpResponse<boolean>> {
		const callback = this.proxy.Delete(`${id}`);
		return callback;
	}

	public getByCategories(codes: string[]): Promise<ContractHttpResponse<Lookup[]>> {
		const request = this.proxy.Get(`GetByCategoryCodeAndOrganizationID`, {
			lookupCategoryCode: codes,
		});
		return request;
	}
	
	public getByCategory(code: string, organizationID: number): Promise<ContractHttpResponse<Lookup[]>> {
		const request = this.proxy.Get(`GetByCategoryCodeAndOrganizationID`, {
			lookupCategoryCode: code,
			OrganizationID: organizationID,
		});
		return request;
	}
	public GetLookupsByMultipleCategoryCode(
		code: string,
		applyIsActive: boolean,
		loginAffiliation: string,
		OrganizationID: number,
		isOnlyParent: boolean = true
	): Promise<ContractHttpResponse<Lookup[]>> {
		const request = this.proxy.Get(`GetLookupsByMultipleCategoryCode`, {
			lookupCategoryCodes: code,
			loginAffiliation: loginAffiliation,
			applyIsActive: applyIsActive,
			OrganizationID: OrganizationID,
			isOnlyParent: isOnlyParent,
		});
		return request;
	}
	public GetParentLookupsByCategory(
		CategoryId: number,
		loginaffiliation: string
	): Promise<ContractHttpResponse<Lookup[]>> {
		const request = this.proxy.Get(`ParentLookupsByCategory`, {
			lookupCategoryID: CategoryId,
			LoginAffiliation: loginaffiliation,
		});
		return request;
	}
	getAreaTypeLookup(): Promise<ContractHttpResponse<Lookup[]>> {
		return this.proxy.Get('GetBasicLookupsByCategoryCodeForDropdown', {
			lookupCategoryCode: 'ORG_AREA_TYPE',
		});
	}

	getGetLookupsByCategoryCodeForDropDownForAnonymous(
		code: string,
		organizationID: number
	): Promise<ContractHttpResponse<Lookup[]>> {
		return this.proxy.Get(`GetLookupsByCategoryCodeForDropDownForAnonymous`, {
			lookupCategoryCode: code,
			OrganizationID: organizationID,
		});
	}

	getGetLookupsByCategoryCodeForQuestionaireForAnonymous(
		code: string,
		organizationID: number
	): Promise<ContractHttpResponse<LookupWithExtraAttributes[]>> {
		return this.proxy.Get(`GetLookupsByCategoryCodeForDropDownForAnonymous`, {
			lookupCategoryCode: code,
			OrganizationID: organizationID,
		});
	}
	getLookupsByParentIDForAnonymous(
		parentID: number,
		organizationID: number
	): Promise<ContractHttpResponse<Lookup[]>> {
		return this.proxy.Get(`GetLookupsByParentIDForAnonymous`, {
			parentID: parentID,
			OrganizationID: organizationID,
		});
	}
	getLookupsByParentID(parentID: number, organizationID: number): Promise<ContractHttpResponse<Lookup[]>> {
		const request = this.proxy.Get(`GetLookupsByParentID`, {
			parentID: parentID,
			OrganizationID: organizationID,
		});
		return request;
	}
	public getAssetTypeLookups(organizationID: number): Promise<ContractHttpResponse<Lookup[]>> {
		const request = this.proxy.Get(`GetAssetTypeLookups`, {
			OrganizationID: organizationID,
		});
		return request;
	}

	public getLoadNumberTypeLookups(organizationID: number): Promise<ContractHttpResponse<Lookup[]>> {
		const request = this.proxy.Get(`GetAssetTypeLookups`, {
			OrganizationID: organizationID,
		});
		return request;
	}

	public getLookupsByConfigKey(organizationID: number, configKey: string): Promise<ContractHttpResponse<Lookup[]>> {
		const request = this.proxy.Get(`GetConfigureLookups`, {
			organizationID: organizationID,
			configKey: configKey,
		});
		return request;
	}

	
	getVehicleStatusLookups(organizationID){
		const request = this.proxy.Get(`GetByCategoryCodeAndOrganizationID`, {
			lookupCategoryCode: "VEHICLE_STATUS",
			organizationID: organizationID
		});
		return request;
	}

}
