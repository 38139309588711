import { CommonConstants } from '../constants/constants';
import * as EXCEL from 'exceljs/dist/exceljs.min.js';
import { Injectable } from '@angular/core';

@Injectable()
export class HelperService {
	// returns true if valid
	public static validateEmail(email): boolean {
		const exp: RegExp =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return exp.test(email ? email.toLowerCase() : email);
	}

	// returns true if valid
	public static validatePassword(password: string) {
		const exp: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}/;
		return exp.test(password);
	}

	// zipcode with exectly five digits // returns true if valid
	public static validateZipcode(zipCode: string): boolean {
		const numberReg: RegExp = /^\d[0-9]{4}$/;
		return numberReg.test(zipCode);
	}

	// phone with exectly 10 digits // returns true if valid
	public static validatePhone(phone: string): boolean {
		const numberReg: RegExp = /^\d{10}$/;
		return numberReg.test(phone);
	}

	// ssn with exactly 9 digits // returns true if valid
	public static validateSSN(ssn: string): boolean {
		const numberReg: RegExp = /^\d{9}$/;
		return numberReg.test(ssn);
	}

	// return phone in 000-000-0000 format
	public static formatPhone(phone: string): string {
		return phone ? phone.substring(0, 3) + '-' + phone.substring(3, 6) + '-' + phone.substring(6) : phone;
	}

	// validate empty or blank spaces // returns true if valid
	public static validateEmptyOrBlankSpaces(field: string): boolean {
		return field != null && field.replace(/\s/g, '').length > 0;
	}

	public static validateEmptyOrBlankSpacesForCell(field: string): boolean {
		return field != null && field.replace(/\s/g, '').length == 10;
	}

	public static validateImageFile(ext: string): boolean {
		if (CommonConstants.FileExtensions.ImageExtensions.includes(ext ? ext.toLowerCase() : ext)) {
			return true;
		}
		return false;
	}
	public static validateLoadNumbersMultipleFormats(loadNumber: string, loadNumberFormats: string[]): boolean {
		let result = false;
		loadNumberFormats.forEach((format) => {
			if (this.validateLoadNumberPattern(loadNumber, format)) {
				result = true;
				return;
			}
		});
		return result;
	}
	public static validateLoadNumberPattern(loadNumber: string, loadNumberFormat: string): boolean {
		if (loadNumber && loadNumberFormat && loadNumber.length == loadNumberFormat.length) {
			for (let i = 0; i < loadNumberFormat.length; i++) {
				const charCode = loadNumber.charCodeAt(i);
				if (loadNumberFormat[i] == '$') {
					if (
						!(
							(charCode >= 65 && charCode <= 95) ||
							(charCode >= 97 && charCode <= 122) ||
							(charCode >= 48 && charCode <= 57)
						)
					) {
						return false;
					}
				} else if (loadNumberFormat[i] == '&') {
					if (!((charCode >= 65 && charCode <= 95) || (charCode >= 97 && charCode <= 122))) {
						return false;
					}
				} else if (loadNumberFormat[i] == '*') {
					if (!(charCode >= 48 && charCode <= 57)) {
						return false;
					}
				} else if (loadNumberFormat[i] != '^') {
					if (loadNumber[i] != loadNumberFormat[i]) {
						return false;
					}
				}
			}
			return true;
		}
		return false;
	}
	public static validateDocumentFile(ext: string) {
		if (CommonConstants.FileExtensions.DocumentExtensions.includes(ext ? ext.toLowerCase() : ext)) {
			return true;
		}
		return false;
	}
	public static validateResumeFile(ext: string) {
		if (CommonConstants.FileExtensions.ResumeExtensions.includes(ext ? ext.toLowerCase() : ext)) {
			return true;
		}
		return false;
	}

	public static allowedImgExtensions() {
		return CommonConstants.FileExtensions.ImageExtensions;
	}
	public static validateNumericInput(event: any) {
		if (event.charCode < 48 || event.charCode > 57) 
			event.preventDefault(); 
	}

	public exportToExcel(headers: string[], data: any[], excelFileName: string): void {
		const workbook = new EXCEL.Workbook();
		const worksheet = workbook.addWorksheet('Sheet');

		const headerRow = worksheet.addRow(headers);

		// Cell Style : Fill and Border
		headerRow.eachCell((cell) => {
			cell.fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: { argb: 'FFFFFF00' },
				bgColor: { argb: 'FF0000FF' },
			};
			cell.border = {
				top: { style: 'thin' },
				left: { style: 'thin' },
				bottom: { style: 'thin' },
				right: { style: 'thin' },
			};
		});

		worksheet.columns = headers.map((head) => {
			return { key: head, width: head.length * 1.5 };
		});

		worksheet.addRows(data);

		workbook.xlsx.writeBuffer().then((data) => {
			const blob = new Blob([data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
			});
			this.downloadFile(blob, excelFileName);
		});
	}

	public downloadFile(blob: Blob, filename: string) {
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = filename;
		link.click();
		link.remove();
	}

	public setDecimal(value, decimalPoint) {
		value = value !== undefined && value !== '' ? parseFloat(value).toFixed(decimalPoint) : 0;
		return value;
	}

	

}
