import { Component, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import {
	LayoutService,
	RouteHandlerService,
	UserSettingsService,
	UtilityService,
	ContractHttpResponse,
	AuthenticateClientService,
} from 'ssi-framework';
import { Router, ActivatedRoute } from '@angular/router';
import { debug } from 'console';
declare var $: any;

@Component({
	selector: '.app-main-content',
	templateUrl: './main-content.component.html',
	styleUrls: ['./main-content.component.scss'],
})
export class MainContentComponent {
	@ViewChild('UserProfileInfo', { static: false }) userProfileAdditionalInfo: TemplateRef<ElementRef>;
	connection: any;
	proxy: any;
	title: string;
	showBackButton: boolean;
	backUrl: string;
	isDashboard = null;
	constructor(
		private layoutService: LayoutService,
		protected router: Router,
		protected routeHandlerService: RouteHandlerService,
		private authClient: AuthenticateClientService,
		private _userSettingsService: UserSettingsService,
		private _utilityService: UtilityService,
		protected route: ActivatedRoute
	) {
		this.isDashboard = false;
	}

	ngOnInit() {
		this.layoutService.title.subscribe((updatedTitle) => {
			this.title = updatedTitle;
		});

		this.layoutService.showBackButton.subscribe((showBackButton) => {
			this.showBackButton = showBackButton;
		});

		this.layoutService.backUrl.subscribe((backUrl) => {
			this.backUrl = backUrl;
		});
		this.checkHome();
	}

	onBack() {
		if (this.backUrl != '') {
			this.router.navigateByUrl(this.backUrl);
		} else {
			//this.router.navigate(['../'], { relativeTo: this.route });
			this.router.navigateByUrl(this.routeHandlerService.getPreviousUrl());
		}
	}

	onActivate(componentRef: any) {
		if (componentRef && componentRef.imageUploaded) {
			componentRef.imageUploaded.subscribe((response) => {
				var image = response.image;
				let extensions = image.Extension.split('/');
				if (extensions && extensions.length >= 1) {
					image.Extension = '.' + extensions[1];
				}
				image.ID = 0;
			});
		}
		this.checkHome();
	}
	checkHome() {
		const currentRoute = window.location.pathname.replace(window['base-href'], '');
		if (currentRoute.includes('home')) {
			this.isDashboard = true;
		} else {
			this.isDashboard = false;
		}
	}
}
