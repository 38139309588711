import { Injectable } from '@angular/core';
import {
	WebApiProxyFactoryService,
	IWebApiProxy,
	BaseApiService,
	PermissionManagerService,
	ContractHttpResponse,
} from 'ssi-framework';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { SortingDirection } from 'igniteui-angular';
import { LoggedInUserInformation } from 'src/app/Common/services/LoggedInUserInformation.service';
import { GlobalSettingsHeirarchy } from '../models/global-settings-heirarchy.model';
import { Configurations } from '../models/configuration.model';
import { GlobalSettingsConfigurations } from '../models/global-settings-configuration.model';
import { RenRole } from 'src/app/core/models/ren-role.model';
import { DriverCheckInOrgLogoResponse } from 'src/app/loading-dock/models/driver-checkin.model';

@Injectable({
	providedIn: 'root',
})
export class GlobalSettingService extends BaseApiService<GlobalSettingsHeirarchy> {
	private readonly proxy: IWebApiProxy;
	private readonly roleProxy: IWebApiProxy;
	//private loggedInUserInformation:LoggedInUserInformation;

	constructor(
		private proxyFactory: WebApiProxyFactoryService,
		private permissionService: PermissionManagerService,
		private loggedInUserInformation: LoggedInUserInformation
	) {
		super();
		this.proxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'GlobalSettings');
		this.roleProxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'WrapperRole');
	}

	get(id: number): Promise<ContractHttpResponse<GlobalSettingsHeirarchy>> {
		const callback = this.proxy.Get(`${id}`);
		return callback;
	}

	public getAll(): Promise<ContractHttpResponse<GlobalSettingsHeirarchy>> {
		const loginAffiliation = this.loggedInUserInformation.GetUserAffiliationFromSettings();
		const affiliation = JSON.stringify(loginAffiliation);
		const callback = this.proxy.Get('GetGlobalSettings', { affiliation: affiliation });
		return callback;
	} // eslint-disable-next-line
	registerRoutes(route: ActivatedRoute) {
		throw new Error('Method not implemented.');
	} // eslint-disable-next-line
	public getPaged(
		pageIndex: number,
		pageSize: number,
		orderBy: string,
		orderDir: SortingDirection,
		filter: any
	): Promise<ContractHttpResponse<GlobalSettingsHeirarchy>> {
		const callback = this.proxy.Get('Search', {
			lookupCategoryId: filter.LookupCategoryID ? filter.LookupCategoryID : 0,
			// lookupCategoryCode: "",
			code: filter.txtCode ? filter.txtCode : '',
			name: filter.txtName ? filter.txtName : '',
			isActive: filter.IsActive ? filter.IsActive : null,
			parentLookupId: filter.parentLookupId ? filter.parentLookupId : 0,
			searchText: filter.searchText ? filter.searchText : '',
			pageIndex: pageIndex,
			pageSize: pageSize,
			orderBy: orderBy ? orderBy : 'ID',
			orderDirection: !orderDir ? 0 : orderDir - 1,
			OrganizationID: filter.OrganizationID ? filter.OrganizationID : 0,
		});
		return callback;
	}

	public validate(): boolean {
		return true;
	}

	public saveOrUpdate(
		globalSettingsDTOs: GlobalSettingsHeirarchy
	): Promise<ContractHttpResponse<GlobalSettingsHeirarchy>> {
		const callback = this.proxy.Post('SaveGlobalSettings', globalSettingsDTOs);
		return callback;
	}

	public SaveGlobalSettingConfigurations(
		configurations: GlobalSettingsConfigurations[]
	): Promise<ContractHttpResponse<GlobalSettingsHeirarchy>> {
		const callback = this.proxy.Post('SaveGlobalSettings', configurations);
		return callback;
	}

	public delete(id: number): Promise<ContractHttpResponse<GlobalSettingsHeirarchy>> {
		const callback = this.proxy.Delete(`${id}`);
		return callback;
	}
	getConfigurationsByOrganizationIdAnonymous(
		id: number,
		type: string,
		getLoadFormates: boolean = false
	): Promise<ContractHttpResponse<Configurations[]>> {
		return this.proxy.Get('GetConfigurationsByOrganizationIdAnonymous', {
			id: id,
			type: type,
			getLoadFormats: getLoadFormates,
		});
	}

	getLogoByOrganizationIdAnonymous(id: number): Promise<ContractHttpResponse<DriverCheckInOrgLogoResponse>> {
		return this.proxy.Get('GetOrganizationLogo', {
			organizationID: id,
		});
	}

	getConfigurationsByOrganizationId(id: number, type: string): Promise<ContractHttpResponse<Configurations[]>> {
		return this.proxy.Get('GetConfigurationsByOrganizationId', {
			id: id,
			type: type,
		});
	}
	GetOrganizationDateTimeFormat(organizationID: number): Promise<ContractHttpResponse<Configurations[]>> {
		return this.proxy.Get('GetOrganizationDateTimeFormat', {
			organizationID: organizationID,
		});
	}
	GetOrganizationDateTimeFormatAnnonymous(organizationID: number): Promise<ContractHttpResponse<Configurations[]>> {
		return this.proxy.Get('GetOrganizationDateTimeFormatAnnonymous', {
			organizationID: organizationID,
		});
	}

	
	public getRoles(companyId: number): Promise<ContractHttpResponse<RenRole[]>> {
		return this.roleProxy.Get('GetByAppAndCompany', {
			applicationCode: environment.ApplicationCode,
			companyID: companyId,
		});
	}
}
