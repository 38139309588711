<label class="label" *ngIf="labelOutsideBox && label" [ngClass]="{'required': required}">{{ label }}</label>

<ng-container *ngIf="mask; then maskTemplate else noMaskTemplate"></ng-container>

<!-- For Masked Inputs -->
<ng-template #maskTemplate>
  <igx-input-group [type]="groupType" [ngClass]="{'input-label-external': labelOutsideBox}">

    <label *ngIf="!labelOutsideBox && label" igxLabel [for]="name">{{ label }}</label>

    <input #inputRef #input="ngModel" igxInput [attr.name]="name" type="text" [(ngModel)]="model"
      [placeholder]="placeholder" [required]="required" [disabled]="disabled" [readonly]="readonly" [pattern]="pattern"
      [tabindex]="index" [maxlength]="maxlength" [autocomplete]="autocomplete" (keydown)="onInputKeyDown($event)"
      (keyup)="onInputKeyUp($event)" (change)="onInputChanged($event)" [igxMask]="mask" [igxFocus]="isFocused"
      [igxTextSelection]="selectOnFocus && model?.length" (focus)="onFocus($event)" />

  </igx-input-group>
</ng-template>

<!-- For Non-Masked Inputs -->
<ng-template #noMaskTemplate>
  <igx-input-group [type]="groupType" [ngClass]="{'input-label-external': labelOutsideBox}">

    <label *ngIf="!labelOutsideBox" igxLabel [for]="name">{{ label }}</label>

    <input #input="ngModel" igxInput [attr.name]="name" [type]="type" [(ngModel)]="model"
      [placeholder]="placeholder" [required]="required" [disabled]="disabled" [readonly]="readonly" [pattern]="pattern"
      [tabindex]="index" [maxlength]="maxlength" [autocomplete]="autocomplete" (keydown)="onInputKeyDown($event)"
      (keyup)="onInputKeyUp($event)" (change)="onInputChanged($event)" [igxFocus]="isFocused"
      [igxTextSelection]="selectOnFocus && model?.length" />

  </igx-input-group>
</ng-template>

<!-- Hint -->
<div class="input-helper">
  <div>
    <div class="error">
      <ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
    </div>
    <div *ngIf="hint" class="desc">{{ hint }}</div>
  </div>
  <div *ngIf="maxlength && showCharacterCount" class="count">{{ model?.length ? model?.length : 0 }}/{{ maxlength }}
  </div>
</div>

<!-- Error Template -->
<ng-template #errorTemplate>
  <ng-container *ngIf="customError">
    {{ customError }}
  </ng-container>
  <ng-container *ngIf="input?.touched && !customError">
    <ng-container *ngIf="input?.errors?.required">
      {{ label }} is a required field
    </ng-container>
    <ng-container *ngIf="input?.errors?.pattern">
      Invalid {{ _type | titlecase }} Pattern
    </ng-container>
  </ng-container>
</ng-template>
