import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticateClientService, SsiLoaderComponent } from 'ssi-framework';
import { PROGRESS_STATUS } from './deactivate-user/deactivate-user.component';
import { LoggedInUserInformation } from 'src/app/Common/services/LoggedInUserInformation.service';
@Component({
  selector: 'app-deactivate-user-layout',
  templateUrl: './deactivate-user-layout.component.html',
  styleUrls: ['./deactivate-user-layout.component.scss']
})
export class DeactivateUserLayoutComponent implements OnInit {
  // ideally, this.progressBar.show() or this.progressBar.hide() should be used
  // But, The underlying functionality has a bug, so a go around is used
  //   @ViewChild("progressBar") 
  //   public progressBar:SsiLoaderComponent;
  public isProgressbarHidden = true;
  public hideProgressbarTimeoutId = null;

  constructor(
    private authenticateClientService: AuthenticateClientService,
		private loggedInUserInformation: LoggedInUserInformation,

  ) { }
  ngOnInit() {
    const affiliation = this.loggedInUserInformation.GetLoginAffiliation();
    if(affiliation!=null &&  affiliation!=undefined){
     this.authenticateClientService.signOut();
     window.location.href = 'deleteuser';
    }

  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return false;
      case agent.indexOf('edg') > -1:
        return false;
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return false;
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return true;
      default:
        return false;
    }
  }

  showProgressBar() {
    if (this.hideProgressbarTimeoutId != null)
      clearTimeout(this.hideProgressbarTimeoutId);

    this.isProgressbarHidden = false;
  }

  hideProgressBar() {
    this.hideProgressbarTimeoutId = setTimeout(() => {
      this.isProgressbarHidden = true;
    }, 2000);
  }

  handleProgressStatusChange(event) {

    if (event == PROGRESS_STATUS.PROGRESS_START) {
      this.showProgressBar();
    } else if (event == PROGRESS_STATUS.PROGRESS_END) {
      this.hideProgressBar();
    }


  }

}