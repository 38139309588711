
export class NotificationConstants {

    static Modes = class {
        public static get Email(): string { return 'Email'; }
        public static get InApp(): string { return 'InApp'; }
        public static get SMS(): string { return 'SMS'; }
        public static get Push(): string { return 'Push'; }
    }

    static Statuses = class{
        public static get Read(): string { return 'Read'; }
        public static get UnRead(): string{ return 'UnRead'; }
        public static get Unknown(): string{ return 'Unknown'; }
        public static get Failed(): string{ return 'Failed'; }
    }
}

export function GetSpecialCharctersErrorMessage(fieldName) {
    return `Special Character are not allowed in the ${fieldName} Field.`;
  }