import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractHttpResponse, UtilityService } from 'ssi-framework';
import { CommonMessageKeys } from '../../Common/common-message-keys';
import { UserManagementService } from '../../Services/usermanagement.service';


@Component({
  selector: 'ssi-identity-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  ClientName: string = '' //environment.AppTitle;
  ConfirmPassword: string;
  Password: string;
  Error: string;
  guidId: string;
  showResetPasswordForm: boolean = false;
  showLink: boolean = false;
  ErrorClass: string = "";

  constructor(private userManagement: UserManagementService, private router: Router,
    private utilityService: UtilityService,
    private activateRouter: ActivatedRoute) {
    this.activateRouter.queryParams.subscribe(params => {
      this.guidId = params['guid'];
    });
  }

  onSubmit() {
    if (this.validate()) {
      this.userManagement.changePasswordByLink(this.guidId, this.Password, this.ConfirmPassword).then(
        (response: ContractHttpResponse<[]>) => {
          if (response.Success && response.Source) {
            this.ErrorClass = "success-block"
            this.router.navigate(['/login'])
          } else {
            this.utilityService.displayErrorMessage(response.Message)
          }
        });
    } 
  }

  ngOnInit() {
    if (!this.guidId) {
      this.showResetPasswordForm = false;
      this.ErrorClass = "help-block";
      this.Error = CommonMessageKeys.ERROR_INVALID;
      this.showLink = true;
      return;
    }

    this.userManagement.isResetLinkValid(this.guidId).then((response: ContractHttpResponse<[]>) => {
      console.log(response);
      if (response.Success && response.Source) {
        this.showResetPasswordForm = true;
        this.showLink = false;
      } else {
        this.showResetPasswordForm = false;
        this.ErrorClass = "help-block";
        this.Error = CommonMessageKeys.ERROR_INVALID;
        this.showLink = true;
      }
    });
  }

  validate()
  {
    if ((this.Password === '' || this.Password == null || this.ConfirmPassword === '' || this.ConfirmPassword == null)) {
      this.utilityService.displayErrorMessage(CommonMessageKeys.MANDATORY_FIELD_ERROR);
      return false;
    }
    if(this.Password !== this.ConfirmPassword){
      this.utilityService.displayErrorMessage(CommonMessageKeys.ERROR_CONFIRM_PASSWORD_MISMATCH);
      return false;
    }
    if(!this.validatePassword(this.Password)){
      this.utilityService.displayErrorMessage(CommonMessageKeys.ERROR_PASSWORD_POLICY_MISMATCH);
      return false;
    }
    else
    {
      return true;
    }
  }

   private validatePassword(password: string) {
    const exp: RegExp = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/;
    return exp.test(password);
  }

}
