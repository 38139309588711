import { Component, OnInit, ViewContainerRef, ViewChild } from '@angular/core';
import { ContractHttpResponse, RouteHandlerService, LayoutService, UtilityService, CommonMessageKeys, AuthenticateClientService, UserContextService, PermissionManagerService } from 'ssi-framework';
import { ApplicationPermission } from '../../models/applicationPermission.model';
import { IChangeCheckboxEventArgs, IgxTreeGridComponent } from 'igniteui-angular';
import { Permission } from '../../models/permission.model';
import { PermissionService } from '../../Services/permission.service';
import { RoleService } from '../../Services/role.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Role } from '../../models/role.model';


@Component({
  selector: 'ssi-identity-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css']
})
export class PermissionComponent implements OnInit {

  @ViewChild('treeGrid', { static: true }) public gridRef: IgxTreeGridComponent;

  applicationPermissions: ApplicationPermission[] = [];
  roleId: number;
  privatePermissionIds: number[] = [];
  role: Role = new Role();
  //disableAssignPermission: boolean = true;

  constructor(private permissionService: PermissionService, private roleService: RoleService, private layoutService: LayoutService,
    private route: ActivatedRoute, private router: Router, private routeHandlerService: RouteHandlerService,
    private authClientService: AuthenticateClientService, private ucs: UserContextService,
    private permissionManager: PermissionManagerService,
    viewRef: ViewContainerRef, private utilityService: UtilityService, private commonMessageKeys: CommonMessageKeys) {
  }

  ngOnInit(): void {
    this.setGridHeight();
    //this.disableAssignPermission = !this.permissionManager.checkPermission("ASSIGN_PERMISSION");
    this.layoutService.menuExp.subscribe(m => {
      try {
        if (this.gridRef) {
          this.gridRef.reflow();
        }
      } catch (error) {
        // ignore error as grid is not rendered at that point
      }

    });

    const roleId = 'roleId';
    this.route.params.subscribe(
      (params: Params) => {
        this.roleId = params[roleId];

        this.roleService.get(this.roleId).then((response: ContractHttpResponse<Role>) => {
          if (response.Success) {
            this.role = response.Source;
            this.layoutService.setTitle('Manage Permissions');
          }
        });

        this.permissionService.getAppPermissionByAppCode().then((response: ContractHttpResponse<ApplicationPermission>) => {
          if (response.Success === true) {
            this.applicationPermissions = response.Source.Modules;

            // tslint:disable-next-line: no-shadowed-variable
            this.permissionService.getByRoleId(this.roleId).then((response: ContractHttpResponse<Permission[]>) => {

              if (response.Success === true) {
                const ids = response.Source.map(o => o.ID);

                this.privatePermissionIds = response.Source.filter(o => o.IsPrivate).map(o => o.ID);
                this.removePrivatePermissions(this.applicationPermissions);
                this.removeEmptyNodes();
                this.makeSelection(this.applicationPermissions, ids);

                // tslint:disable-next-line: prefer-for-of
                for (let index = 0; index < this.applicationPermissions.length; index++) {
                  this.setParent(this.applicationPermissions[index]);
                }
                this.toggleState(this.applicationPermissions);
              }
            });
          }
          else {
            this.utilityService.displayErrorMessage(response.Message);
          }
        });
      });
  }

  makeSelection(appPermission: ApplicationPermission[], selectedIds: number[]) {
    appPermission.forEach(element => {
      const filtered = element.Permissions.filter(x => selectedIds.includes(x.ID));
      // tslint:disable-next-line: no-shadowed-variable
      filtered.forEach(element => {
        element.Selected = true;
      });

      // recursive call to make selection in modules.
      if (element.Modules.length > 0) {
        this.makeSelection(element.Modules, selectedIds);
      }
    });
  }

  onSave() {
    let selectedIds = this.getSelectedPermissionIds(this.applicationPermissions);
    selectedIds = selectedIds.concat(this.privatePermissionIds);
    this.roleService.savePermissionAssignments(this.roleId, selectedIds).then((response: ContractHttpResponse<any>) => {
      if (response.Success) {
        this.utilityService.displaySuccessMessage(this.commonMessageKeys.RECORD_SAVED_SUCCESSFULLY);
        this.router.navigate([this.routeHandlerService.getPreviousUrl()]);
        //this.refreshPermissions();
      } else {
        this.utilityService.displayErrorMessage(response.Message);
      }
    },
      (error) => {
        if (error.error) {
          this.utilityService.displayErrorMessage(error.error.Message);
        } else {
          this.utilityService.displayErrorMessage(error.message);
        }
      });
  }

  refreshPermissions() {
    this.roleService.getByUserId(this.ucs.userId)
      // tslint:disable-next-line: no-shadowed-variable
      .then((response: ContractHttpResponse<Role[]>) => {
        if (response.Success === true) {
          const ids = response.Source.map(o => o.ID);
          if (ids.some(n => n == this.roleId)) {
            this.authClientService.getPermissions().then(() => {
              this.router.navigate([this.routeHandlerService.getPreviousUrl()]);
            });
          }
          else {
            this.router.navigate([this.routeHandlerService.getPreviousUrl()]);
          }
        }
        else {
          this.router.navigate([this.routeHandlerService.getPreviousUrl()]);
        }
      });
  }

  onCancel() {
    this.router.navigate([this.routeHandlerService.getPreviousUrl()]);
  }

  onModuleSelectionChange(e: IChangeCheckboxEventArgs, appPermission: ApplicationPermission) {
    this.togglePermissions([appPermission], e.checked);
    const root = this.getModuleRoot(appPermission);
    if (root != null) {
      this.toggleState([root]);
    }
  }

  togglePermissions(appPermissions: ApplicationPermission[], checked: boolean) {
    appPermissions.forEach(element => {
      element.Selected = checked;
      // tslint:disable-next-line: no-shadowed-variable
      element.Permissions.forEach(element => {
        element.Selected = checked;
      });
      this.togglePermissions(element.Modules, checked);
    });
  }

  onPermissionSelectionChange(e: IChangeCheckboxEventArgs, permission: Permission) {
    permission.Selected = e.checked;
    this.toggleState([this.getPermissionRoot(permission)]);
  }

  toggleState(appPermission: ApplicationPermission[]) {
    appPermission.forEach(element => {
      element.Selected = (element.Permissions.filter(o => o.Selected == null || o.Selected === false).length === 0);
      element.Indetermine = element.Permissions.length > 0 && (element.Permissions.filter(o => o.Selected === true).length > 0) &&
        (element.Permissions.filter(o => o.Selected === true).length !== element.Permissions.length);

      // recursive call to make selection in modules.
      if (element.Modules.length > 0) {
        this.toggleState(element.Modules);

        // Select parent module if child module is selected along with its own permission.
        element.Selected = element.Selected && (element.Modules.filter(o => o.Selected == null || o.Selected === false).length === 0);
        element.Indetermine = element.Indetermine || (element.Modules.filter(o => o.Indetermine === true).length > 0) ||
          ((element.Modules.filter(o => o.Selected == null || o.Selected === false).length > 0) &&
            (element.Modules.filter(o => o.Selected == null || o.Selected === true).length > 0));
      }
    });
  }

  setParent(appPermission: ApplicationPermission) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < appPermission.Permissions.length; i++) {
      appPermission.Permissions[i].Parent = appPermission;
    }

    // tslint:disable-next-line: prefer-for-of
    for (let j = 0; j < appPermission.Modules.length; j++) {
      appPermission.Modules[j].Parent = appPermission;
      this.setParent(appPermission.Modules[j]);
    }
  }

  removePrivatePermissions(appPermissions: ApplicationPermission[]) {
    appPermissions.forEach(element => {
      element.Permissions = element.Permissions.filter(o => !o.IsPrivate);
      this.removePrivatePermissions(element.Modules);
    });
  }

  removeEmptyNodes() {
    this.removeEmptyChildNodes(this.applicationPermissions);
    this.applicationPermissions = this.applicationPermissions.filter(o => o.Permissions.length > 0 || o.Modules.length > 0);
  }

  removeEmptyChildNodes(appPermissions: ApplicationPermission[]) {
    appPermissions.forEach(element => {
      this.removeEmptyChildNodes(element.Modules);
      element.Modules = element.Modules.filter(o => o.Permissions.length > 0 || o.Modules.length > 0);
    });
  }

  getSelectedPermissionIds(appPermissions: ApplicationPermission[]): number[] {
    let selectedIds: number[] = [];

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < appPermissions.length; i++) {
      selectedIds = selectedIds.concat(appPermissions[i].Permissions.filter(o => o.Selected).map(o => o.ID));
      selectedIds = selectedIds.concat(this.getSelectedPermissionIds(appPermissions[i].Modules));
    }

    return selectedIds;
  }

  getPermissionRoot(permission: Permission): ApplicationPermission {
    let root = permission.Parent;
    while (root.Parent != null) {
      root = root.Parent;
    }
    return root;
  }

  getModuleRoot(module: ApplicationPermission): ApplicationPermission {
    let root = module.Parent;
    while (root != null && root.Parent != null) {
      root = root.Parent;
    }
    return root;
  }

  private setGridHeight() {
    let parent = this.gridRef.nativeElement.parentElement;
    while (parent != null && parent.clientHeight === 0) {
      parent = parent.parentElement;
    }
    if (parent == null) {
      parent = this.gridRef.nativeElement.parentElement;
    }
    const height = parent.clientHeight;
    this.gridRef.height = height + 'px';
  }

}
