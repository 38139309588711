import { Component, Injector, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CommonMessageKeys, ContractHttpResponse, LayoutService, RouteHandlerService, SsiSelectComponent, SsiTextEditorComponent,
  SsiTranslatorPipe, UtilityService
} from 'ssi-framework';
import { NotificationTemplate } from '../../models/notification-template.model';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'lib-notification-template-ae',
  templateUrl: './notification-template-ae.component.html',
  styleUrls: ['./notification-template-ae.component.scss']
})
export class NotificationTemplateAeComponent implements OnInit {

  private _ssiTranslatorPipe: SsiTranslatorPipe;
  model: NotificationTemplate = new NotificationTemplate();
  namekey: any;
  addOrEdit: any;
  previousUrl: string;
  isMasterTemplate: boolean = false;
  templateList: any;

  @ViewChild("masterLayout", { static: false }) masterLayout: SsiSelectComponent;

  constructor(private router: Router, private route: ActivatedRoute, private utilityService: UtilityService,
    private routehandlerService: RouteHandlerService,
    private commonMessageKeys: CommonMessageKeys, private notificationService: NotificationService) {
    this.previousUrl = this.routehandlerService.getPreviousUrl();
    this.route.url.subscribe(u => {
      this.addOrEdit = u[1].path;
    })
    this.route.params.subscribe(params => {
      const nameKey = 'name';
      this.namekey = params[nameKey];
    })
  }

  ngOnInit() {
    this.notificationService.getAll().then((response: ContractHttpResponse<any>) => {
      if (response.Success) {
       this.templateList =  response.Source
        
      }else{
        console.log(response);
        
      }
    })
    if (this.addOrEdit == "Edit") {
      this.notificationService.getTemplate(this.namekey).then((response: ContractHttpResponse<NotificationTemplate>) => {
        if (response.Success) {
          this.model = response.Source;
        }
      });
    }
  }

  saveInternal(): Promise<any> {

    if ( (!this.model.IsMasterLayout && this.model.MasterLayoutID > 0) || (this.model.IsMasterLayout && this.model.MasterLayoutID == 0)) {
      if (this.model.FileName.length > 0 && this.model.Content.length > 0) {
        this.model.FileName.trim();
        return new Promise<any>((resolve, reject) => {
          this.notificationService.save(this.model)
            .then((response: ContractHttpResponse<NotificationTemplate>) => {
              if (response.Success == true) {
                this.router.navigate(['/NotificationTemplates'])
              }
              else {
                resolve(response);
                this.utilityService.displayErrorMessage(response.Message);
              }
            });
        });
      } else {
        this.utilityService.displayErrorMessage(this.commonMessageKeys.MANDATORY_FIELD_ERROR);
      }
    }else {
      this.utilityService.displayErrorMessage(this.commonMessageKeys.MANDATORY_FIELD_ERROR);
    }

   
  }

  onCancel() {
    this.router.navigate(['/NotificationTemplates'])
  }


  sourceSelection(event){
    this.model.IsMasterLayout = event.checked

    if (this.model.IsMasterLayout) {
      this.model.MasterLayoutID = 0;
      this.model.MasterLayoutName = null
    }else{
      this.model.MasterLayoutID = 0;
      this.model.MasterLayoutName = null
    }
    
  }

  parentTemplateSelect(event){
    if (event.added.length > 0) {
      event.cancel = true

      this.masterLayout.setSingleModelValue(event.added[0], false);
      this.model.MasterLayoutID = this.templateList.find(x => x.FileName == event.added[0]).ID;
    }
    
  }

}
