import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'ssi-framework';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  constructor(layoutService: LayoutService) {
    layoutService.setTitle(' ');
   }

  ngOnInit() {
  }

}
