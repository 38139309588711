import { Component, OnInit, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'lib-cal-day-view',
  templateUrl: './day-view.component.html',
  styleUrls: ['./day-view.component.scss']
})
export class DayViewComponent implements OnInit {

  @Input() dayTemplate: ElementRef;
  @Input() activeDate;
  @Input() tileData: Map<string, any>;
  @Input() displayTileExtensionButton;

  constructor() { }

  ngOnInit() {
  }


  getData(date: Date) {
    if(this.tileData.size){
      return this.tileData.get(date.toDateString());
    }
  }

}
