import { Component, OnInit, Input, ViewChild, ViewEncapsulation, ComponentFactoryResolver } from '@angular/core';
import { SsiTabItem } from '../../../Infrastructure/Models/ssi-tab-item';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteHandlerService } from '../../../WebInfrastructure/Routing/route-handler.service';
import { IValidateTabItem } from '../../../Infrastructure/Interfaces/Ivalidate-tab-item';
import { IgxTabsComponent } from 'igniteui-angular';
import { PermissionManagerService } from '../../../Authentication/permission-manager.service';

@Component({
	selector: 'lib-ssi-tab',
	templateUrl: './ssi-tab.component.html',
	styleUrls: ['./ssi-tab.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SsiTabComponent implements OnInit {
	@ViewChild('tabControl', { static: false }) igxTabComponent: IgxTabsComponent;
	@Input() entity: any;
	@Input() public tabs: SsiTabItem[];
	@Input() parentRoute: string;
	@Input() hasNoIdentifier = false;
	@Input() isVertical = false;

	@Input() selectedIndex = -1;
	public id: number;
	private _activeIndex = -1;
	private navigate = true;

	constructor(
		private router: Router,
		private routeHandlerService: RouteHandlerService,
		private componentFactoryResolver: ComponentFactoryResolver,
		private route: ActivatedRoute,
		private permissionManagerService: PermissionManagerService
	) {}

	ngOnInit() {
		this.tabs = this.tabs.filter(
			(n) => this.permissionManagerService.checkPermission(n.Permission) || n.Permission === ''
		);
		this.route.params.subscribe((params) => {
			this.id = +params.id;
			this.afterNavigationProcess();
		});
		this.setTabsUrl();
	}

	onActivate(event) {
		this.routeHandlerService.previousTabComponentSelector = this.routeHandlerService.currentTabComponentSelector;

		const factory = this.componentFactoryResolver.resolveComponentFactory(this.route.firstChild.component as any);
		if (factory) {
			this.routeHandlerService.currentTabComponentSelector = factory.selector;
		}
		if (this.route.children.length && (this.route.children[0].url as any).value.length) {
			const tabRoute = (this.route.children[0].url as any).value[0].path;
			if (tabRoute != null) {
				if (this.tabs !== undefined && this.tabs.length > 0) {
					for (let i = 0; i < this.tabs.length; i++) {
						if (this.tabs[i].Route === tabRoute) {
							this.selectedIndex = i;
							break;
						}
					}
				}
			}
		}
	}

	public tabItemSelected(event: any) {
		const index = event.group.index;
		if (this.tabs && index > -1) {
			if (
				this.routeHandlerService.currentTabComponent != null &&
				this._activeIndex > -1 &&
				this.igxTabComponent.selectedIndex !== this.selectedIndex
			) {
				// && event.tab.index != event.tab.tabindex
				(this.routeHandlerService.currentTabComponent as IValidateTabItem).validateTab().then((response) => {
					if (response) {
						this.routeHandlerService.currentTabComponent = null;
						this.performNavigation(index);
					} else {
						this.selectedIndex = this._activeIndex > -1 ? this.selectedIndex : 0;
						this.igxTabComponent.selectedIndex = this.selectedIndex;
					}
				});
			} else {
				this.performNavigation(index);
			}
		}
	}

	private afterNavigationProcess() {
		// check if identifier is defined or identifier is not required for using component
		if (this.id || this.hasNoIdentifier) {
			if (this.route.children.length && (this.route.children[0].url as any).value.length) {
				const tabRoute = (this.route.children[0].url as any).value[0].path;
				if (tabRoute != null) {
					for (let i = 0; i < this.tabs.length; i++) {
						if (this.tabs[i].Route === tabRoute) {
							this.navigate = false;
							//this.router.navigateByUrl(this.router.url, { preserveQueryParams: true, queryParamsHandling: 'preserve' });
							this.selectedIndex = i;
							break;
						}
					}
				}
			}
		} else {
			this.id = 0;
			this.selectedIndex = 0;
		}
	}

	private performNavigation(index: number) {
		if (this.navigate) {
			this.router.navigate(
				[
					this.parentRoute,
					{
						outlets: {
							ssiTabs: [this.tabs[index].Route],
						},
						preserveQueryParams: true,
						queryParamsHandling: 'preserve',
					},
				],
				{
					replaceUrl: true,
				}
			);
		}
		this.navigate = true;
		this.selectedIndex = index;
		this._activeIndex = index;
	}

	private setTabsUrl() {
		for (const tab of this.tabs) {
			tab.Url = this.parentRoute + '/(ssiTabs:' + [tab.Route] + ')';
		}
	}

	public tabLinkClick() {
		return false;
	}
}
