
import { Injectable, Injector } from '@angular/core';
import { SharedConstant } from '../../Helpers/shared-constant';
import { UtilityService } from '../../Common/utility.service';
import { WebApi } from './web-api';
import { HttpClient, HttpHandler, HttpXhrBackend } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DiscoveryClientService {

  resolving: boolean;

  constructor(private sharedConstant: SharedConstant, private utility: UtilityService) {
  }

  public Init() {
    let storedConstants = JSON.parse(localStorage.getItem(this.sharedConstant.localStorageKey));
    if(storedConstants && storedConstants.availableWebApis && storedConstants.availableWebApis.length > 0){
      this.sharedConstant.availableWebApis = storedConstants.availableWebApis;
    }
    else{
      const maxDate = new Date(8640000000000000);
      this.sharedConstant.availableWebApis.push({
        AppCode: this.sharedConstant.ssifwAppcode,
        Name: this.sharedConstant.discoveryServiceName + 'Controller',
        ResolvedTime: maxDate,
        Addresses: [
          this.DiscoveryServiceUrl()
        ]
      });
    }
  }

  public Resolve(appCode: string, contract: string): Promise<WebApi> | WebApi {
    const webApi = this.GetWebApi(appCode, contract);
    if (webApi != null) {
      return webApi;
    }

    const injector = Injector.create({
      providers: [
        { provide: HttpClient, deps: [HttpHandler] },
        { provide: HttpHandler, useValue: new HttpXhrBackend({ build: () => new XMLHttpRequest() }) },
      ],
    });
    const http: HttpClient = injector.get(HttpClient);

    const registryApi = this.Resolve(this.sharedConstant.ssifwAppcode, this.sharedConstant.discoveryServiceName) as WebApi;
    const request = http.get<WebApi>(`${registryApi.Addresses[0]}/Resolve`, {
      params: {
        applicationCode: appCode,
        contract: contract + 'Controller',
      }
    }).toPromise();

    request.then((res) => {
      this.sharedConstant.availableWebApis.push(res);

      const storedConstants = JSON.parse(localStorage.getItem(this.sharedConstant.localStorageKey));
      if (storedConstants) {
        storedConstants.availableWebApis = this.sharedConstant.availableWebApis;
        localStorage.setItem(this.sharedConstant.localStorageKey, JSON.stringify(storedConstants));
      }
    });

    return request;
  }

  private GetWebApi(appCode: string, contract: string): WebApi {
    for (let i = 0, len = this.sharedConstant.availableWebApis.length; i < len; i++) {
      if (this.sharedConstant.availableWebApis[i] != null
        && this.sharedConstant.availableWebApis[i].AppCode === appCode
        && this.sharedConstant.availableWebApis[i].Name === contract + 'Controller') {
        return this.sharedConstant.availableWebApis[i];
      }
    }
    return null;
  }

  private DiscoveryServiceUrl(): string {
    const url = `${this.sharedConstant.discoveryConfig.Scheme}://${this.sharedConstant.discoveryConfig.Host}:${this.sharedConstant.discoveryConfig.Port}/api/Registry`;
    return url;
  }
}
