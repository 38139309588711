import { NgModule } from '@angular/core';
import { InappNotificationComponent } from './inapp-notification/inapp-notification.component';
import { ViewNotificationComponent } from './view-notification/view-notification.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { IgxGridModule, IgxIconModule, IgxButtonModule, IgxRippleModule, IgxDialogModule, IgxInputGroupModule,
   IgxSnackbarModule, IgxToastModule, IgxComboModule, IgxTreeGridModule, IgxListModule, IgxTabsModule } from 'igniteui-angular';
import { SsiFrameworkModule, RouteHandlerService } from 'ssi-framework';
import { SsiIdentityModule, UserManagementService } from 'ssi-identity';
import { UserNotificationsComponent } from './user-notifications/user-notifications.component';
import { NotificationEventConfigurationLVComponent } from './notificationeventconfiguration/notification-event-configuration-lv/notification-event-configuration-lv.component';
import { NotificationConfigurationService } from './services/notification-configuration.service';
import { NotificationSubscriptionLVComponent } from './notificationeventconfiguration/notification-subscription-lv/notification-subscription-lv.component';
import { NotificationSubscriptionAEComponent } from './notificationeventconfiguration/notification-subscription-ae/notification-subscription-ae.component';
import { ReceivedNotificationLVComponent } from './receiveNotifications/received-notification-lv/received-notification-lv.component';
import { NotificationEventService } from './services/notification-event.service';
import { NotificationService } from './services/notification.service';
import { NotificationRecipientService } from './services/notification-recipient.service';
import { NotificationLVComponent } from './notification/notification-lv/notification-lv.component';
import { NotificationRecipientLVComponent } from './notification/notification-recipient-lv/notification-recipient-lv.component';
import { AngularSplitModule } from 'angular-split';
import { NotificationTemplateLvComponent } from './notification-template/notification-template-lv/notification-template-lv.component';
import { NotificationTemplateAeComponent } from './notification-template/notification-template-ae/notification-template-ae.component';
import { NotificationEventConfigurationAeComponent } from './notificationeventconfiguration/notification-event-configuration-ae/notification-event-configuration-ae.component';

const routes: Routes = [
  { path: 'NotificationEventConfigurations', component: NotificationEventConfigurationLVComponent, data: {
    mainRoute: true, permissions: ''}},
  { path: 'NotificationEventConfigurations/Edit/:id', component: NotificationEventConfigurationAeComponent, data: {
      permissions: ''}},
  { path: 'NotificationEventConfigurations/Subscriptions/:id', component: NotificationSubscriptionAEComponent, data: {
      permissions: ''}},
  { path: 'ViewNotifications', component: ReceivedNotificationLVComponent, data: { mainRoute: true } },
  { path: 'Notifications', component: NotificationLVComponent, data: { mainRoute: true, permissions: ''}},
  { path: 'NotificationTemplates', component: NotificationTemplateLvComponent, data: { permissions: ''}},
  { path: 'NotificationTemplates/Add', component: NotificationTemplateAeComponent, data: {permissions: ''}},
  { path: 'NotificationTemplates/Edit/:name', component: NotificationTemplateAeComponent, data: {permissions: ''}}
];

@NgModule({
  declarations: [
    NotificationLVComponent,
    ViewNotificationComponent, 
    UserNotificationsComponent,
    InappNotificationComponent, 
    ReceivedNotificationLVComponent,
    NotificationTemplateLvComponent, 
    NotificationTemplateAeComponent,  
    NotificationRecipientLVComponent,   
    NotificationSubscriptionLVComponent, 
    NotificationSubscriptionAEComponent,  
    NotificationEventConfigurationLVComponent,
    NotificationEventConfigurationAeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    IgxGridModule,
    IgxIconModule,
    IgxButtonModule,
    IgxRippleModule,
    IgxDialogModule,
    IgxInputGroupModule,
    IgxSnackbarModule,
    IgxToastModule,
    SsiFrameworkModule,
    SsiIdentityModule,
    IgxComboModule,
    IgxTreeGridModule,
    IgxListModule,
    IgxTabsModule,
    AngularSplitModule
  ],
  entryComponents: [
    NotificationLVComponent,
    UserNotificationsComponent,
    ReceivedNotificationLVComponent,
    NotificationEventConfigurationLVComponent
  ],
  providers: [
    NotificationService,
    UserManagementService, 
    NotificationEventService,
    NotificationRecipientService, 
    NotificationConfigurationService, 
    ],
  exports: [InappNotificationComponent, NotificationEventConfigurationLVComponent, 
    NotificationSubscriptionLVComponent,ReceivedNotificationLVComponent, 
    NotificationLVComponent, ViewNotificationComponent]
})
export class SsiNotificationModule {
  constructor(private routeHandler: RouteHandlerService) {
    routeHandler.addChildRoutes('Users/Edit/:id', [{ path: 'notifications', component: UserNotificationsComponent,
     outlet: 'ssiTabs', data: { title: 'Notifications', sortOrder: 5 } }]);
  }
}
