<div class="login-wrap flex-column">

  <div class="login-form-wrap mb-4 d-flex flex-column">

    <div class="login-form top">
      <p class="help-block {{ErrorClass}}"> {{Error}} </p>
      <span *ngIf="showLink"><a routerLink="/forgetPassword">click here to reset password</a></span>
      <ng-container *ngIf="showResetPasswordForm">

      <h2 class="title">Reset Password</h2>
      <form action="">
        <div class="form-group">
          <igx-input-group>
            <input igxInput [igxFocus]="true" name="Password" type="password" required="required" [(ngModel)]="Password"
              autocomplete="none" [required]="true" placeholder="New Password"  />

          </igx-input-group>

        </div>
        <div class="form-group mb-5" >
          <igx-input-group>
            <input  igxInput ngModel name="Email" type="password" required="required"
              [(ngModel)]="ConfirmPassword"  [required]="true" placeholder="Confirm Password"
              autocomplete="none" />

          </igx-input-group>
        </div>
        <div class="form-group">
          <button igxButton class="btn-primary" (click)="onSubmit();">{{'SUBMIT_BTN' | ssiTranslator }}</button>
        </div>

      </form>
      </ng-container>
    </div>
  </div>
  <div class="login-form-wrap bottom">
    <div class="logo">
      <img src="assets/images/logo.png" alt="logo">
    </div>
  </div>
</div>

