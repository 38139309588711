import { Injectable } from '@angular/core';
import { WebApiProxy } from './HttpHandler/web-api-proxy';
import { SharedConstant } from '../Helpers/shared-constant';
import { IWebApiProxy } from '../ServiceInterfaces/WebApi/iweb-api-proxy';
import { DiscoveryClientService } from './Discovery/discovery-client.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebApiProxyFactoryService {
  constructor(private sharedConstant: SharedConstant, private discovery: DiscoveryClientService, private http:HttpClient) {
  }

  public GetProxy(contract: string): IWebApiProxy {
    return this.GetProxyByApp(this.sharedConstant.ssifwAppcode, contract);
  }

  public GetProxyByApp(appCode: string, contract: string): IWebApiProxy {
    return new WebApiProxy(appCode, contract, this.discovery, this.http);
  }
}
