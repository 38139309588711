import { Injectable } from '@angular/core';
import { NotificationRecipient } from '../models/notification-recipient';
import { BaseApiService, IWebApiProxy, WebApiProxyFactoryService } from 'ssi-framework';
import { ActivatedRoute } from '@angular/router';
import { SortingDirection } from 'igniteui-angular';

@Injectable({
  providedIn: 'root'
})
export class NotificationRecipientService extends BaseApiService<NotificationRecipient> {

  proxy: IWebApiProxy;

  constructor(private proxyFactory: WebApiProxyFactoryService) {
    super();

    this.proxy = this.proxyFactory.GetProxy("Notification");
  }

  public registerRoutes(route: ActivatedRoute) {
    throw new Error("Method not implemented.");
  }

  public get(id: number): Promise<any> {
    throw new Error("Method not implemented.");
  }

  public getPaged(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any): Promise<any> {
    let modes = ["InApp"];
    let statuses = [];
    let request = this.proxy.Get("GetPage", {
      modes: modes,
      statuses: statuses,
      subject: filter.subject ? filter.subject : "",
      createdBy: filter.createdBy ? filter.createdBy : "",
      searchText: filter.searchText ? filter.searchText : "",
      pageIndex: pageIndex,
      pageSize: pageSize,
      orderBy: orderBy ? orderBy : "ID",
      orderDirection: (!orderDir || orderDir == 0) ? 0 : orderDir - 1
    });
    return request;
  }

  public getAll(): Promise<any> {
    throw new Error("Method not implemented.");
  }

  public validate(obj: NotificationRecipient): boolean {
    throw new Error("Method not implemented.");
  }

  public saveOrUpdate(obj: NotificationRecipient): Promise<any> {
    throw new Error("Method not implemented.");
  }

  public delete(id: number): Promise<any> {
    throw new Error("Method not implemented.");
  }

}
