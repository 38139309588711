import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IgxDialogComponent, IgxGridCellComponent, IgxGridComponent } from '@infragistics/igniteui-angular';
import { CommonMessageKeys, ContractHttpResponse, GridRowActionItem, LayoutService, SsiTranslatorPipe, UtilityService } from 'ssi-framework';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'lib-notification-template-lv',
  templateUrl: './notification-template-lv.component.html',
  styleUrls: ['./notification-template-lv.component.scss']
})
export class NotificationTemplateLvComponent implements OnInit {
  @ViewChild("FWNotificationTemplateLvGrid", { static: false })
  gridRef: IgxGridComponent;
  
  private _ssiTranslatorPipe: SsiTranslatorPipe;
  title: string = "";

  constructor(private notificationService: NotificationService, private utilityService: UtilityService,
    private router: Router, private route: ActivatedRoute, private  commonMessageKeys: CommonMessageKeys,
    private layoutService: LayoutService, _injector: Injector, ){
      this._ssiTranslatorPipe = new SsiTranslatorPipe(_injector)
   
  }

  items: GridRowActionItem[] = [
      new GridRowActionItem(this, "edit", "Edit", "EDIT_NOTIFICATION_TEMPLATE", "editTemplate", "edit"),
      new GridRowActionItem(this, "delete", "Delete", "EDIT_NOTIFICATION_TEMPLATE", "deleteTemplate", "trash"),
  ];

  ngOnInit(): void {
    this.notificationService.getAll().then((response: ContractHttpResponse<any>) => {
      if (response.Success) {
        this.gridRef.paging = false
        this.gridRef.data = response.Source
        
      }else{
        console.log(response);
        
      }
    })
    this.setTitle();
   }

   setTitle() {
    var title = this._ssiTranslatorPipe.transform("NOTIFICATION_TEMPLATES");
    this.layoutService.setTitle(title);
}


  editTemplate(cell: IgxGridCellComponent) {
    this.router.navigate(['NotificationTemplates/Edit/', cell.cellID.rowID.FileName]);
  }

  deleteTemplate(cell: IgxGridCellComponent) {
    var deleteMsg = this.utilityService.displayConfirmationMessage("Are you sure you want to delete the selected record(s)?");
    if (deleteMsg) {
        deleteMsg.onConfirm.subscribe((dialog: IgxDialogComponent) => {
            let callback = this.notificationService.deleteTemplate(cell.cellID.rowID.FileName);
            callback.then((response: any) => {
                if (response.Success == true) {
                    this.utilityService.displaySuccessMessage(this.commonMessageKeys.RECORD_DELETED_SUCCESSFULLY);
                    this.ngOnInit()
                }
                else {
                    this.utilityService.displayErrorMessage(response.Message);
                }
            });
        });
    }
}

}
