import { Injectable } from '@angular/core';

import { UserSettings } from '../../Infrastructure/Models/user-settings.model';
import { UserContextService } from '../../Authentication/user-context.service';
import { WebApiProxyFactoryService } from '../../WebApiClient/web-api-proxy-factory.service';
import { ContractHttpResponse } from '../../WebApiClient/HttpHandler/contract-http-response';
import { GridSettings } from '../../Infrastructure/Models/grid-settings.model';

@Injectable({
	providedIn: 'root',
})
export class UserSettingsService {
	private userSettingsArray: UserSettings[];

	constructor(private userContext: UserContextService, private proxyFactory: WebApiProxyFactoryService) {}

	public get userSettings() {
		return this.userSettingsArray;
	}

	public updateUserSettings(userSettings: UserSettings[]) {
		this.userSettingsArray = userSettings;
	}

	public loadUserSettings(silentRefresh: boolean = true): Promise<any> {
		return new Promise((resolve, reject) => {
			if (this.userContext.userId > 0) {
				const api = this.proxyFactory.GetProxy('UserSetting');
				const request = api.Get('GetByUser', { userId: this.userContext.userId }, silentRefresh, false);
				request.then((response: ContractHttpResponse<UserSettings[]>) => {
					if (response.Success === true) {
						this.userSettingsArray = response.Source;
						resolve(true);
					} else {
						resolve(true);
					}
				});
			} else {
				resolve(true);
			}
		});
	}

	public getUserSettingsByCode(code: string): UserSettings {
		if (this.userSettings != null && this.userSettings.length > 0) {
			const settings = this.userSettings.filter((item) => {
				if (item.Code === code) {
					return item;
				}
			});

			return settings != null && settings.length > 0 ? settings[0] : null;
		} else {
			return null;
		}
	}

	public getGridSettings(gridId: string): GridSettings {

		if (this.userSettings != null && this.userSettings.length > 0) {
			const settings = this.userSettings.filter((item) => {
				return item.Code === gridId + '_GP';
			});

			if (settings.length > 0) {
				const gridSettings: GridSettings = JSON.parse(settings[0].Value);
				return gridSettings;
			}
		}
		return null;
	}

	public saveGridSettings(gridSettings: GridSettings) {

		let userSetting = this.getUserSettingsByCode(gridSettings.ID + '_GP');

		if (userSetting == null) {
			userSetting = new UserSettings();
			userSetting.UserId = this.userContext.userId;
			userSetting.Code = gridSettings.ID + '_GP';
			userSetting.Name = 'Grid Personalization';
			userSetting.AppCode = this.userContext.appCode;
			userSetting.Description = 'Personalization for Grid: ' + gridSettings.ID;
			userSetting.DataType = typeof String.name;
			userSetting.IsDeleted = false;
			userSetting.Value = JSON.stringify(gridSettings);
		} else {
			userSetting.Value = JSON.stringify(gridSettings);
		}

		this.updateLocalUserSettings(userSetting);
		this.saveUserSettings(userSetting);
	}

	public getFilterSettings(gridId: string) {
		const response = this.getUserSettingsByCode(gridId + '_Filter');
		if (response !== null && response.Value !== null) {
			return JSON.parse(response.Value);
		} else {
			return null;
		}
	}

	public saveFilterSettings(gridId: string, filterSetting: any) {
		let userSetting = this.getUserSettingsByCode(gridId + '_Filter');
		if (userSetting == null) {
			userSetting = new UserSettings();
			userSetting.UserId = this.userContext.userId;
			userSetting.Code = gridId + '_Filter';
			userSetting.Name = 'Filters';
			userSetting.AppCode = this.userContext.appCode;
			userSetting.Description = 'Filters for Grid: ' + gridId;
			userSetting.DataType = typeof String.name;
			userSetting.IsDeleted = false;
		}
		userSetting.Value = JSON.stringify(filterSetting);

		this.updateLocalUserSettings(userSetting);
		this.saveUserSettings(userSetting);
	}

	public saveRecentSearchSettings(gridId: string, filterSetting: any) {
		let userSetting = this.getUserSettingsByCode(gridId + '_RecentSearchs');
		if (userSetting == null) {
			userSetting = new UserSettings();
			userSetting.UserId = this.userContext.userId;
			userSetting.Code = gridId + '_RecentSearchs';
			userSetting.Name = 'Recent Searchs';
			userSetting.AppCode = this.userContext.appCode;
			userSetting.Description = 'Recent Searchs on Grid: ' + gridId;
			userSetting.DataType = typeof String.name;
			userSetting.IsDeleted = false;

			const settings = [filterSetting];
			userSetting.Value = JSON.stringify(settings);
		} else {
			let settings = JSON.parse(userSetting.Value) as any[];
			const index = settings.findIndex((n) => {
				const a = Object.assign({}, n);
				const b = Object.assign({}, filterSetting);
				delete a.searchedOn;
				delete b.searchedOn;
				return JSON.stringify(a) === JSON.stringify(b);
			});

			if (index > -1) {
				settings.splice(index, 1);
			}
			settings = [filterSetting].concat(settings);
			settings = settings.slice(0, 10);
			userSetting.Value = JSON.stringify(settings);
		}

		this.updateLocalUserSettings(userSetting);
		this.saveUserSettings(userSetting);
	}

	public clearRecentSearches(userSetting: any) {
		this.updateLocalUserSettings(userSetting);
		this.saveUserSettings(userSetting);
	}

	public getOrCreateSetting(setting?: { code: string; value: any; description: string }): UserSettings {

		let userSetting = this.getUserSettingsByCode(setting.code);

		if (setting.value != null) {
			if (userSetting == null) {
				userSetting = new UserSettings();
				userSetting.UserId = this.userContext.userId;
				userSetting.Code = setting.code;
				userSetting.Name = setting.code;
				userSetting.AppCode = this.userContext.appCode;
				userSetting.Description = setting.description;
				userSetting.DataType = typeof String.name;
				userSetting.IsDeleted = false;
				userSetting.Value = JSON.stringify(setting.value);
			} else {
				userSetting.Value = JSON.stringify(setting.value);
			}

			this.updateLocalUserSettings(userSetting);
			this.saveUserSettings(userSetting);
		}
		return userSetting;
	}

	public saveUserSettings(userSetting: UserSettings) {

		const api = this.proxyFactory.GetProxy('UserSetting');
		if (userSetting.Code != '') {
			let gridId = userSetting.Code.split('_');

			if ('RequestLoadItemsGrid' == gridId[0]) return;
		}
		const request = api.Post('CreateOrUpdate', userSetting, true);
		return request.then((response: any) => {
			if (response.Success === true) {
				this.updateLocalUserSettings(userSetting);
				return true;
			} else {
				return false;
			}
		});
	}

	private updateLocalUserSettings(userSetting: UserSettings) {

		let updated = false;
		if (this.userSettings && this.userSettings.length) {
			for (const iterator of this.userSettings) {
				if (iterator.Code === userSetting.Code) {
					iterator.Value = userSetting.Value;
					updated = true;
				}
			}

			if (updated === false) {
				this.userSettings.push(userSetting);
			}
		}
		if (
			updated === false &&
			(this.userSettings == undefined || this.userSettings.length == 0) &&
			(userSetting != null || userSetting != undefined)
		) {
			var userSettingArray: UserSettings[] = [];
			userSettingArray.push(userSetting);
			this.updateUserSettings(userSettingArray);
		}
	}
}
