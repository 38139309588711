import { AfterViewInit, Component, ContentChild, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgModel } from '@angular/forms';
import { IComboSearchInputEventArgs, IComboSelectionChangeEventArgs, IgxComboComponent, IgxInputGroupType } from '@infragistics/igniteui-angular';
import { IBaseCancelableBrowserEventArgs } from '@infragistics/igniteui-angular/lib/core/utils';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { Subject } from 'rxjs/internal/Subject';
import { ContractHttpResponse } from '../../../WebApiClient/HttpHandler/contract-http-response';
import { WebApiProxyFactoryService } from '../../../WebApiClient/web-api-proxy-factory.service';

@Component({
  selector: 'lib-ssi-select',
  templateUrl: './ssi-select.component.html',
  styleUrls: ['./ssi-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SsiSelectComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() label: string;
  @Input() title: string;
  @Input() placeHolder = undefined;
  @Input() name: string;
  @Input() itemKey: string;
  @Input() itemText: string;
  @Input() itemTexts: string[];
  @Input() required = false;
  @Input() showClearButton = true;
  @Input() disabled = false;
  @Input() index: number;
  @Input() singleSelect = false;
  @Input() appCodeEnvKey = 'SSIFW_Appcode';
  @Input() controllerName = '';
  @Input() actionName = '';
  @Input() requestType = 'GET';
  @Input() params: any;
  @Input() pageSize = 10;
  @Input() loadRemoteData : boolean = false;
  @Input() IsScrollEventValid : boolean = false;
  @Input() isFocused : boolean = false;
  @Input() filterableItemCount: number;
  @Input() labelOutsideBox: boolean = false;
  @Input() type: IgxInputGroupType = 'line';
  @Input() searchPlaceholder: string = 'Start typing to search..';

  showLoader = false;

  @Output() ItemSelected: EventEmitter<any> = new EventEmitter();
  @Output() RemoteItemsLoaded: EventEmitter<any[]> = new EventEmitter();
  @Output() onSelectionChanging: EventEmitter<IComboSelectionChangeEventArgs> = new EventEmitter<IComboSelectionChangeEventArgs>()

  public allItems: any[] = [];
  customError: string;

  private _proxy: any;
  private _items: any[];
  @Output() itemsChange = new EventEmitter();
  @Output() itemsChanged = new EventEmitter();
  @Input() get items() {
    return this._items;
  }
  set items(val) {
    this._items = val;
    this.itemsChange.emit(this.items);
    if (this.items?.length) {
      setTimeout(() => this.itemsChanged.emit(this.items), 50);
    }
  }
  private modelValue: any;
  @Output() modelValueChange = new EventEmitter();
  @Input() get model() {
    return this.modelValue;
  }
  set model(val) {
    this.modelValue = val;
    this.modelValueChange.emit(this.modelValue);
  }

  private singleModelValue: any;
  @Output() singleModelChange = new EventEmitter();
  @Input() get singleModel() {
    if (this._itemValueType
      && this.singleModelValue !== undefined
      && this.singleModelValue !== null
      && this._itemValueType === 'boolean') {
      // return bool if type is boolean instead of string
      return (/true/i).test(this.singleModelValue) ? true
        : (/false/i).test(this.singleModelValue) ? false : this.singleModelValue;
    } else {
      return this.singleModelValue;
    }
  }
  set singleModel(val) {
    if (this._itemValueType && this._itemValueType === 'boolean' && val != undefined) {
      // convert to string if type is boolean
      this.singleModelValue = val.toString();
    } else {
      this.singleModelValue = val;
    }

    this.model = this.singleModelValue ? [this.singleModelValue] : [];
    this.singleModelChange.emit(this.singleModelValue);
  }

  private multiModelValue: any[];
  @Output() multiModelChange = new EventEmitter();
  @Input() get multiModel() {
    return this.multiModelValue;
  }
  set multiModel(val) {
    this.multiModelValue = val;
    this.model = val;
    this.multiModelChange.emit(this.multiModelValue);
  }

  get hasRemoteData() {
    if (this.appCodeEnvKey && this.appCodeEnvKey.length && this.controllerName && this.controllerName.length
      && this.actionName && this.actionName.length) {
      return true;
    }
    return false;
  }

  get selectedItems() {
    return this._selectedItems;
  }

  @ViewChild('combo', { static: false }) combo: NgModel;
  @ViewChild('ssiCombo', { static: false }) ssiCombo: IgxComboComponent;
  @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>;

  private _itemValueType: string;
  private _singleSelectEventChange: boolean = true;
  private _selectionInProgress: boolean[] = [];
  private inputRemoteDelaySub = new Subject<string>();
  private _selectedItems = [];

  initialRemoteItems: any[] = [];

  constructor(@Inject('EnvironmentVariables') private _environment: any,
    private proxyFactory: WebApiProxyFactoryService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if items are loaded after value selection
   if (!this.IsScrollEventValid) {
    if (this.singleSelect && changes.items && this.singleModel) {
      this.setSelection([this.singleModel], true, null, 50);
      if (this._singleSelectEventChange) {
        this.triggerSingleSelectEvent(changes, true);
      }
    } else if (!this.singleSelect && changes.items && this.multiModel) {
      this.setSelection(this.multiModel, true, null, 50);
    }

    // if items are loaded before value selection
    if (this._items && this.singleSelect && changes.singleModel) {
      if (this._selectionInProgress.length) {
        this.setSelection(this.multiModel, true, null, 50);
      }

      if (this._singleSelectEventChange) {
        this.triggerSingleSelectEvent(changes);
      }
    } else if (this._items && !this.singleSelect && changes.multiModel) {
      if (this._selectionInProgress.length) {
        this.setSelection(this.multiModel, true, null, 50);
      }

      let _multiSelectEvent: any = null;
      if (changes.multiModel.previousValue && changes.multiModel.previousValue.length && !changes.multiModel.currentValue) {
        _multiSelectEvent = {
          newSelection: changes.multiModel.currentValue && changes.multiModel.currentValue.length === 0 ? null
            : changes.multiModel.currentValue,
          oldSelection: changes.multiModel.previousValue && changes.multiModel.previousValue.length === 0
            ? null
            : changes.multiModel.previousValue
        };
      } else {
        _multiSelectEvent = {
          newSelection: changes.multiModel.currentValue && changes.multiModel.currentValue.length === 0 ? null
            : changes.multiModel.currentValue,
          oldSelection: changes.multiModel.previousValue && changes.multiModel.previousValue.length === 0
            ? null
            : changes.multiModel.previousValue
        };
      }
      this.ItemSelected.emit(_multiSelectEvent);
    }
    this._singleSelectEventChange = true;
   }
  }

  ngOnInit(): void {
    this.HandleBoolType();
  }

  ngAfterViewInit(): void {
    (this.ssiCombo as any).elementRef.nativeElement.setAttribute('name', this.name);

    if (this.singleSelect) {
      this.initSingleSelect();
    } else {
      this.initMultiSelect();
    }

    if (this.hasRemoteData) {
      this.initRemoteFeature();
    }
  }

  clearSelection() {
    this.setSelection([], true, null);
  }

  private initSingleSelect() {
    this.ssiCombo.onSelectionChange.subscribe((event: IComboSelectionChangeEventArgs) => {
      this.onSelectionChanging.emit(event)
      // make sure only single is selectable in combo
      if (!event.cancel) {
        if (event.event || (event.added != null && event.added.length > 0)) {
          if (event.added.length) {
            event.newSelection = event.added;
          }

          if (event.newSelection.length) {
            this.singleModel = event.newSelection[0];
          } else {
            this.singleModel = undefined;
          }
        }

        if (!event.displayText.length
          && event.added && event.added.length
          && this._items && this._items.some(n => n[this.itemKey] === event.added[0])) {
          event.displayText = this._items.find(n => n[this.itemKey] === event.added[0])[this.itemText];
        } else if (!event.displayText.length
          && event.newSelection && event.newSelection.length
          && this.initialRemoteItems && this.initialRemoteItems.some(n => n[this.itemKey] === event.added[0])) {
          event.displayText = this.initialRemoteItems.find(n => n[this.itemKey] === event.added[0])[this.itemText];
          // this.initialRemoteItems = [];
        }
      }

      // close combo on item selection
      if (!this.ssiCombo.collapsed) {
        this.ssiCombo.close();
      }
    });

    // set selection on model value set
    if (this._itemValueType === 'boolean'
      && this.singleModel !== undefined
      && this.singleModel !== null
      && this.singleModel.toString().length) {
      this.setSelection([this.singleModel.toString()], true, null, 100);
    } else if (this.singleModel) {
      this.setSelection([this.singleModel], true, null, 100);
    }

    if (!this.hasRemoteData) {
      this.ssiCombo.onOpening.subscribe((n: IBaseCancelableBrowserEventArgs) => {
        this.applySingleSelectClass();
      });
      // this.ssiCombo.onSearchInput.subscribe((n: IComboSearchInputEventArgs) => {
      //   setTimeout(() => {
      //     this.applySingleSelectClass();
      //   });
      // });
    }
  }

  private initMultiSelect() {
    // make sure only single is selectable in combo
    this.ssiCombo.onSelectionChange.subscribe((event: IComboSelectionChangeEventArgs) => {

      // adding event to cancel multi selection event cancel
      this.onSelectionChanging.emit(event);

      if (!event.cancel) {
        const items = this.hasRemoteData ? this.allItems : this.items;
        if (event.event || (event.removed != null && event.removed.length > 0 && items || (event.added != null && event.added.length > 0))) {
          if (event.newSelection && event.newSelection.length) {
            this._selectedItems = items.filter(n => event.oldSelection.indexOf(n[this.itemKey]) !== -1);
            let newSelectionItems = items.filter(n => event.newSelection.indexOf(n[this.itemKey]) !== -1);
            newSelectionItems = newSelectionItems.filter(n => !this._selectedItems.map(m => m[this.itemKey]).includes(n[this.itemKey]));
            this._selectedItems.push(...newSelectionItems);
          }

          if (event.newSelection.length) {
            const removedItems = event.oldSelection.filter(value => !event.newSelection.includes(value));
            if (removedItems && removedItems.length) {
              const removedItemsIndex = removedItems.map(n => {
                return this._selectedItems.map(m => m[this.itemKey]).indexOf(n);
              });
              removedItemsIndex.forEach(index => {
                this._selectedItems.splice(index, 1);
              });
            }

            this.multiModel = event.newSelection;
          } else {
            this._selectedItems = [];
            this.multiModel = undefined;
          }
        }

        if (!event.displayText.length
          && event.newSelection && event.newSelection.length
          && this.initialRemoteItems && this.initialRemoteItems.some(n => event.newSelection.indexOf(n[this.itemKey]) !== -1)) {
          const initialSelectedItems = this.initialRemoteItems.filter(n => event.newSelection.indexOf(n[this.itemKey]) !== -1).map(n => {
            return n[this.itemText];
          }) ?? [];
          event.displayText = initialSelectedItems.join(', ')
          // this.initialRemoteItems = [];
        } else if (this._selectedItems.length > 0) {
          event.displayText = this._selectedItems.map(m => m[this.itemText]).join(', ')
        }
      }
    });

    // set selection on model value set
    if (this.multiModel) {
      this.setSelection(this.multiModel, true, null, 100);
    }
  }

  private initRemoteFeature() {
    const appCode = this._environment[this.appCodeEnvKey];
    this._proxy = this.proxyFactory.GetProxyByApp(appCode, this.controllerName);

    // wait for 1 seconds before remote api is called
    this.inputRemoteDelaySub.pipe(
      debounceTime(1000)
    ).subscribe((filterValue: string) => {
      this.getRemoteData(filterValue);
    });


    this.ssiCombo.onOpening.subscribe((n: IBaseCancelableBrowserEventArgs) => {
      // Fix loader hiding issue on same data load
      //commented to avoid initial data load
      //this.showLoader = true;
      //this.inputRemoteDelaySub.next(null);

      if (this.loadRemoteData) {
        if (this.items == undefined || this.items?.length) {
          this.getRemoteData(null)
        }
      }
    });

    this.ssiCombo.onClosed.subscribe(() => {
      //this.items = null;
    });
    this.ssiCombo.onSearchInput.subscribe((n: IComboSearchInputEventArgs) => {
      n.cancel = true;
      this.inputRemoteDelaySub.next(n.searchText);
    });
  }

  private setSelection(values: any[], clearSelection: boolean = true, event: Event = null, useTimeout: number = null) {
    if (this.items && this.items.length) {
      if (values) {
        if (useTimeout) {
          this._selectionInProgress.push(true);
          setTimeout(() => {
            this._selectionInProgress.pop();
            this.ssiCombo.selectItems(values, clearSelection, event);
          }, useTimeout);
        } else {
          this.ssiCombo.selectItems(values, clearSelection, event);
        }
      } else {
        if (useTimeout) {
          this._selectionInProgress.push(true);
          setTimeout(() => {
            this._selectionInProgress.pop();
            this.ssiCombo.selectItems([], clearSelection, event);
          }, useTimeout);
        } else {
          this.ssiCombo.selectItems([], clearSelection, event);
        }
      }
    }
  }

  private HandleBoolType() {
    // convert all values to string if value type is boolean
    if (!this._itemValueType && this.items && this.items.length && this.items.some(n => typeof n[this.itemKey] === 'boolean')) {
      this._itemValueType = 'boolean';

      this.items.forEach(n => {
        n[this.itemKey] = n[this.itemKey].toString();
      });
    }
  }

  clear() {
    if (this.hasRemoteData && this.loadRemoteData)
    {
      this.getRemoteData(null);
    }
  }

  private getRemoteData(filterValue: string = null) {
    if (filterValue != '' || (filterValue == '' && this.loadRemoteData) ) {
      this.showLoader = true;
      const obj = this.params != null ? this.params : {};
      obj.searchText = filterValue != null ? filterValue : '';
      obj.pageSize = this.pageSize;

      let request: Promise<any>;
      if (this.requestType.toLowerCase() === 'get') {
        request = this._proxy.Get(this.actionName, obj, true);
      }
      request.then((n: ContractHttpResponse<any[]>) => {
        if (n.Success) {
          this.items = n.Source;
          this.allItems.push(...n.Source.filter(x => !this.allItems.some(y => y[this.itemKey] == x[this.itemKey])));
          this.showLoader = false;
          if (this.singleSelect) {
            setTimeout(() => {
              this.applySingleSelectClass();
            });
          }
          this.RemoteItemsLoaded.emit(this.items);
        }
      });
    }
    else {
      this.items = [];
      this.allItems = [];
      // if (this.singleSelect) {
      //   setTimeout(() => {
      //     this.applySingleSelectClass();
      //   });
      // }
      this.RemoteItemsLoaded.emit(this.items);
    }
  }

  private applySingleSelectClass() {
    if (this.ssiCombo.dropdown.items.length) {
      this.ssiCombo.dropdown.items[0].element.nativeElement.parentElement.parentElement.classList.add("singleSelectParent");
    }
  }

  private triggerSingleSelectEvent(changes: SimpleChanges, itemsLoadedAfterValue: boolean = null) {
    let _singleSelectEvent: any = null;
    if (changes.singleModel && changes.singleModel.previousValue && !changes.singleModel.currentValue) {
    } else if (changes.singleModel) {
      _singleSelectEvent = {
        newSelection: this._items.filter(n => {
          if (changes.singleModel.currentValue && changes.singleModel.currentValue === n[this.itemKey]) {
            return true;
          }
        }),
        oldSelection: this._items.filter(n => {
          if (changes.singleModel.previousValue && changes.singleModel.previousValue === n[this.itemKey]) {
            return true;
          }
        })
      };
      _singleSelectEvent.newSelection = _singleSelectEvent.newSelection.length === 0 ? null : _singleSelectEvent.newSelection[0];
      _singleSelectEvent.oldSelection = _singleSelectEvent.oldSelection.length === 0 ? null : _singleSelectEvent.oldSelection[0];
    } else if (itemsLoadedAfterValue != null && itemsLoadedAfterValue) {
      _singleSelectEvent = {
        newSelection: this._items.filter(n => {
          if (this.singleModel && this.singleModel === n[this.itemKey]) {
            return true;
          }
        }),
        oldSelection: null
      };
      _singleSelectEvent.newSelection = _singleSelectEvent.newSelection.length === 0 ? null : _singleSelectEvent.newSelection[0];
    }

    this.ItemSelected.emit(_singleSelectEvent);
  }

  public setSingleModelValue(value, singleSelectEventChange: boolean  ){
    this._singleSelectEventChange = singleSelectEventChange;
    this.singleModel = value;
  }

  setError(error: string) {
    this.customError = error;
  }

  clearError() {
    this.customError = '';
  }

  reset() {
    this.combo.control.reset();
  }

  onOpening(event: IBaseCancelableBrowserEventArgs) {
    if (this.disabled == true) {
      event.cancel = true;
    }
  }
}
