export class IdentityConstants{
    static KeyValuePairs = class {
        public static get Titles(): any[] {
            return [{ Name: 'Mr.', Value: 'Mr' }, { Name: 'Mrs.', Value: 'Mrs' }, { Name: 'Other', Value: 'Other' }];
        }
    }
    static Boolean = class {
        public static get Status(): any[] {
            return [{ Name: 'Active', Value: true }, { Name: 'Inactive', Value: false }];
          }
    }
}



