import { Component, OnInit, Input } from '@angular/core';
import { PermissionManagerService, UtilityService, CommonMessageKeys, UserContextService, ContractHttpResponse } from 'ssi-framework';
import { UserManagementService } from '../../../Services/usermanagement.service';
import { RoleService } from '../../../Services/role.service';
import { ActivatedRoute } from '@angular/router';
import { User } from '../../../models/user.model';
import { Role } from '../../../models/role.model';

@Component({
  selector: 'ssi-identity-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit {
  public roles: any[] = [];
  public originalRoles: any[] = [];
  user: User;
  public isSelf = false;
  @Input() inputUserId = 0;
  constructor(
    private route: ActivatedRoute,
    public permissionMangerService: PermissionManagerService,
    private userService: UserManagementService,
    private utilityService: UtilityService,
    private commonMessageKeys: CommonMessageKeys,
    private roleService: RoleService,
    private userContextService: UserContextService) { }

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      const idKey = 'id';
      const id = +params[idKey];
      this.isSelf = (id === this.userContextService.userId);
      if (id && this.inputUserId === 0 ) {
        setTimeout(() => {
          this.userService.get(id)
          .then((response: ContractHttpResponse<User>) => {
            if (response.Success === true) {
              this.user = response.Source;
              this.loadRoles();
            }
          });
        },10)
      } else {
        if(this.inputUserId > 0) {
          setTimeout(() => {
            this.userService.get(this.inputUserId)
            .then((response: ContractHttpResponse<User>) => {
              if (response.Success === true) {
                this.user = response.Source;
                this.loadRoles();
              }
            });
          },10)
        }
      }
    });
  }

  loadRoles() {
    this.roleService.getByCompany(this.user.CompanyID)
      .then((response: ContractHttpResponse<Role[]>) => {
        if (response.Success === true) {
          this.roles = response.Source.sort((r1, r2)=>{return r2 > r1? -1:1;});

          if (this.user && this.user.ID > 0) {
            this.roleService.getByUserId(this.user.ID)
              // tslint:disable-next-line: no-shadowed-variable
              .then((response: ContractHttpResponse<Role[]>) => {
                if (response.Success === true) {
                  const ids = response.Source.map(o => o.ID);
                  const filtered = this.roles.filter(x => ids.includes(x.ID));
                  filtered.forEach(r => { r.Selected = true; });

                  this.originalRoles = JSON.parse(JSON.stringify(this.roles));
                }
                else {
                }
              });
          }
        }
        else {
        }
      });
  }

  onSave() {
    const selectionDto = { ID: this.user.ID, SelectedIDs: this.roles.filter(x=>x.Selected === true).map(o => o.ID) };

    this.userService.saveRoleAssignments(selectionDto)
      .then((response: ContractHttpResponse<any>) => {
        if (response.Success === true) {
          this.originalRoles = JSON.parse(JSON.stringify(this.roles));
          this.utilityService.displaySuccessMessage(this.commonMessageKeys.RECORD_SAVED_SUCCESSFULLY);
        }
        else{
          this.utilityService.displayErrorMessage(response.Message);
        }
      },
        (error) => {
          if (error.error) {
            this.utilityService.displayErrorMessage(error.error.Message);
          } else {
            this.utilityService.displayErrorMessage(error.message);
          }
        }
      );
  }

  onCancel() {
    this.roles = JSON.parse(JSON.stringify(this.originalRoles));
  }

}
