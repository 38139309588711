import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CommonMessageKeys {


public static readonly ERROR_CONFIRM_PASSWORD_MISMATCH: string = 'Your confirm password does not match password.';
public static readonly ERROR_INVALID: string = 'Link expired. Please use the reset password functionality to generate a new link.';
public static readonly MANDATORY_FIELD_ERROR: string = 'Please fill all mandatory fields.';
public static readonly ERROR_PASSWORD_POLICY_MISMATCH: string = 'Minimum length of Password should be 8 characters, at least one capital, lowercase, number and special character';


}