import { WebApiProxyFactoryService, IWebApiProxy } from 'ssi-framework';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'ssi-framework';
import { SortingDirection } from 'igniteui-angular';
import { ActivatedRoute } from '@angular/router';
import { IdentityProvider } from '../models/identityProvider.model';

@Injectable()
export class IdentityProviderService extends BaseApiService<IdentityProvider> {
    private proxy: IWebApiProxy;

    constructor(private proxyFactory: WebApiProxyFactoryService) {
        super();
        this.proxy = this.proxyFactory.GetProxy("IdentityProvider");
    }

    registerRoutes(route: ActivatedRoute) {
    }

    public get(id: number): Promise<any> {
        let request = this.proxy.Get("Get", {
            id: id
        });
        return request;
    }


    public getAll(): Promise<any> {
        let request = this.proxy.Get("GetAll");
        return request;
    }

    public getPaged(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any): Promise<any> {
        const request = this.proxy.Get('GetPage', {
            entityId: filter.entityId ? filter.entityId : 0,
            companyId: filter.companyId ? filter.companyId : 0,
            domain: filter.domain ? filter.domain : '',
            roleIds: filter.roleIds ? filter.roleIds : [],
            searchText: filter.searchText ? filter.searchText : '',
            pageIndex,
            pageSize,
            orderBy: orderBy ? orderBy : 'ID',
            orderDirection: (!orderDir || orderDir === 0) ? 0 : orderDir - 1
        },null, null);
        return request;
    }

    public GetByEntityIdAndCompanyId(entityId:number, companyId:number): Promise<any>{
        let request = this.proxy.Get("GetByEntityIdAndCompanyId", {
            entityId: entityId,
            companyId: companyId
        });
        return request;
    };

    public validate(obj: IdentityProvider): boolean {
        throw new Error("Method not implemented.");
    }
    public saveOrUpdate(obj: IdentityProvider): Promise<any> {
        if (obj) {
            obj.IsActive = true;
            const request = this.proxy.Post('SaveOrUpdate', obj);
            return request;
        }
    }

    public delete(id: number): Promise<any> {
        const request = this.proxy.Delete('Delete', {
            id: id
        });
        return request;
    }
}

