import { Component, OnInit, ViewChild, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { IgxDialogComponent } from 'igniteui-angular';

@Component({
  selector: 'lib-ssi-confirmation',
  templateUrl: './ssi-confirmation.component.html',
  styleUrls: ['./ssi-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SsiConfirmationComponent implements OnInit {

  @Input() title: string = "Confirmation";
  @Input() showCrossIcon: boolean = false;

  @ViewChild('dialog', {static: true}) dialog: IgxDialogComponent;

  onConfirm: EventEmitter<IgxDialogComponent> = new EventEmitter<IgxDialogComponent>();
  onCancel: EventEmitter<IgxDialogComponent> = new EventEmitter<IgxDialogComponent>();

  message = 'Are you sure you want to perform the action?';

  ngOnInit(): void {
    this.onConfirm.subscribe((n: IgxDialogComponent) => {
      n.close();
    });

    this.onCancel.subscribe((n: IgxDialogComponent) => {
      n.close();
    });
  }

  public show(message?: string, showIcon?: boolean) {
    if (message) {
      this.message = message;
    }
    if (showIcon) {
      this.showCrossIcon = showIcon;
    }
    this.dialog.open();
  }

  public Confirm(event) {
    this.onConfirm.emit(event.dialog);
  }

  public Cancel(event) {
    this.onCancel.emit(event.dialog);
  }

}
