<ng-template igxCell let-cell="cell" #rowActionMenuTemplate>
  <div class="gridAction" *ngIf="rowActionType == 'Dropdown'">
    <span class="icon-holder" (click)="toggleDropDown($event, cell, rowActionsDDL)" *ngIf="rowItemsLength(cell) > 0">
      <igx-icon>more_vert</igx-icon>
    </span>
    <igx-drop-down #rowActionsDDL (onOpening)="onOpening($event)" class="dropdon-action-list" [width]="'auto'"
      (onSelection)="onSelection($event)">
      <span *ngFor="let row of displayRowItems" [ssiTooltip]="row.tooltip">
        <igx-drop-down-item class="action-dropdown-item" [disabled]="row.isDisabled" [value]="row.key">
          <span class="icon icon-{{row.icon}}"></span>{{ row.displayName }}
        </igx-drop-down-item>
      </span>
    </igx-drop-down>
  </div>

  <div class="grid-actions" *ngIf="rowActionType == 'IconOverlay'">
    <div class="overlay-actions" *ngIf="rowItemsLength(cell) > 0">
      <span *ngFor="let row of getRowItems(cell)" [ngClass]="{'disabled': row.isDisabled}" class="action-wrap">
        <span class="icon icon-{{row.icon}}" (click)="onOverlayActionIconClicked($event,row.key, cell)"
          ssiTooltip="{{row.displayName}}"></span>
      </span>
    </div>
  </div>

  <div class="grid-actions-wrap" *ngIf="rowActionType == 'Icon'">
    <div class="action-icon" [ngClass]="{'disabled': action.isDisabled}" *ngFor="let action of getRowItems(cell)">
      <div class="action-link" (click)="onActionClick(action.key, cell);">
        <span class="icon icon-{{action.icon}}" ssiTooltip="{{action.displayName}}"></span>
        <span>{{action.displayName}}</span>
      </div>
    </div>
  </div>
</ng-template>
