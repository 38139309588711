<div
	[ngClass]="{ 'new-toggle-content': isWidthUpdated, 'toggle-content': !isWidthUpdated }"
	igxToggle
	#toggle
	[style.width.px]="isWidth"
>
	<div class="toggle-section toggle-dropdown">
		<div class="header">
			<h5 class="title">{{ header }}</h5>
			<div class="filter" *ngIf="showSearchBox">
				<igx-input-group type="search" class="search search-line">
					<input
						#search
						igxInput
						type="text"
						class="background-transparent"
						placeholder="Search"
						autocomplete="none"
						(input)="filter($event.target.value)"
					/>
					<igx-suffix *ngIf="search.value.length > 0" (click)="search.value = null">
						<igx-icon (click)="clear()">clear</igx-icon>
					</igx-suffix>
				</igx-input-group>
			</div>
		</div>
		<igx-tree-grid
			#treeGridDDL
			*ngIf="showContent"
			[primaryKey]="valueKey"
			[data]="data"
			[childDataKey]="childKey"
			[autoGenerate]="false"
		>
			<igx-column [header]="" [field]="textKey" dataType="string">
				<ng-template igxCell let-cell="cell" let-val>
					<igx-checkbox
						[readonly]="cell.row.rowData.Readonly"
						[(ngModel)]="cell.row.rowData.Selected"
						[indeterminate]="cell.row.rowData.Indeterminate"
						(change)="onRowSelectionChange($event, cell.row.rowData)"
						[ngClass]="{ readonly: cell.row.rowData.Readonly }"
					>
						{{ val }}
					</igx-checkbox>
				</ng-template>
			</igx-column>
		</igx-tree-grid>

		<div class="control-footer">
			<button igxButton type="button" (click)="onCloseToggle(true)">{{ cancelLabel }}</button>
			<button igxButton type="button" class="btn-primary" (click)="onSaveToggle()">{{ saveLabel }}</button>
		</div>
	</div>
</div>

<ng-container *ngIf="isDropdown == true">
	<igx-input-group (click)="showDropdown()" #overlayDropDown>
		<label *ngIf="label != undefined" igxLabel>{{ label }}</label>
		<input
			[required]="required"
			index="index"
			igxInput
			type="text"
			readonly="readonly"
			id="{{ name }}"
			[disabled]="disabled"
			name="{{ name }}"
			[(ngModel)]="selectedNames"
		/>
		<igx-suffix igxButton="icon" (click)="!this.disabled && showDropdown()">
			<igx-icon fontSet="material">arrow_drop_down</igx-icon>
		</igx-suffix>
	</igx-input-group>
</ng-container>

<!-- Display overlay in a custom wrapper outlet -->
<ng-container *ngIf="overlayOutlet">
	<div igxOverlayOutlet #outlet="overlay-outlet" class="overlay-outlet"></div>
</ng-container>
