import { Component, OnInit, Inject, ViewChild, ElementRef, Input } from '@angular/core';
import { IgxOverlayService, HorizontalAlignment, VerticalAlignment, GlobalPositionStrategy } from 'igniteui-angular';

@Component({
  selector: 'lib-ssi-loader',
  templateUrl: './ssi-loader.component.html',
  styleUrls: ['./ssi-loader.component.scss']
})
export class SsiLoaderComponent implements OnInit {

  @ViewChild('loader', { read: ElementRef, static: false})
  private loader: ElementRef;
  @Input() isHidden = false;
  @Input() type: string;

  id: string;
  isVisible = false;

  constructor(@Inject(IgxOverlayService) private overlayService: IgxOverlayService) { }

  ngOnInit() {
  }

  hide() {
    if (this.isVisible) {
      setTimeout(() => {
        this.overlayService.hide(this.id);
      }, 50);

      this.isVisible = false;
    }
  }

  showLoader() {
    if (!this.isVisible) {
      this.id = this.overlayService.show(this.loader, {
        positionStrategy: new GlobalPositionStrategy({
          target: this.loader.nativeElement,
          horizontalDirection: HorizontalAlignment.Center,
          verticalDirection: VerticalAlignment.Middle
        }),
        modal: false,
        closeOnOutsideClick: false
      });

      this.isVisible = true;
    }
  }

}
