import { Injectable } from '@angular/core';
import {
	WebApiProxyFactoryService,
	ContractHttpResponse,
	IWebApiProxy,
	BaseApiService,
	PermissionManagerService,
	PagedList,
} from 'ssi-framework';
import { environment } from 'src/environments/environment';
import { SortingDirection } from 'igniteui-angular';
import { LoadDetail } from '../models/loads-detail.model';
import { Load } from '../models/load.model';
import { SendMessageResult } from '../models/send-message-result.model';
import { CommentSection } from '../models/load-feed.model';
import { LoadDropDown } from '../models/load-dropdown.model';
import { LoadCalendar } from '../models/load-calendar-model';
import { Lookup } from 'src/app/information/models/lookup.model';
import { LoadNumber } from '../models/driver-checkin.model';
import { Attachment } from 'src/app/information/models/attachment.mode.l';

@Injectable({
	providedIn: 'root',
})
export class LoadsService extends BaseApiService<LoadDetail> {
	private readonly proxy: IWebApiProxy;
	public filterValue: any = {};

	constructor(private proxyFactory: WebApiProxyFactoryService, private permissionService: PermissionManagerService) {
		super();
		this.proxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'Loads');
	}
	public registerRoutes(): LoadDetail {
		throw new Error('Method not implemented.');
	}

	get(id: number, loginAffiliation: string = ''): Promise<ContractHttpResponse<LoadDetail>> {
		return this.proxy.Get(`${id}?loginAffiliation=${loginAffiliation}`);
	}

	GetLoadForItems(loadID: number): Promise<ContractHttpResponse<Load>> {
		return this.proxy.Get('GetLoadForItems', {
			loadID: loadID,
		});
	}
	public GetLookupsByLoads(
		LoadID: number,
		LoadIDs: number[],
		isOnlyParent: boolean = true
	): Promise<ContractHttpResponse<number[]>> {
		const request = this.proxy.Get(`GetLookupsByLoads`, {
			LoadID: LoadID,
			LoadIDs: LoadIDs,
			isOnlyParent: isOnlyParent,
		});
		return request;
	}
	GetLoadLite(loadID: number): Promise<ContractHttpResponse<Load>> {
		return this.proxy.Get('GetLight', {
			id: loadID,
		});
	}

	GetLoadLiteAnonymous(loadID: number): Promise<ContractHttpResponse<Load>> {
		return this.proxy.Get('GetLightAnonymous', {
			id: loadID,
		});
	}

	public getAll(): Promise<LoadDetail> {
		throw new Error('Method not implemented.');
	}

	public getPaged(
		pageIndex: number,
		pageSize: number,
		orderBy: string,
		orderDir: SortingDirection,
		region: string,
		//eslint-disable-next-line
		filter: any
	): //eslint-disable-next-line
	Promise<ContractHttpResponse<PagedList<Load>>> {
		const callback = this.proxy.Get('Search', {
			driverStatusLookupIds: filter.driverStatusLookupID ? filter.driverStatusLookupID.join(',') : '',
			loadStatusLookupIds: filter.loadStatusLookupID ? filter.loadStatusLookupID.join(',') : '',
			loadtripStatusLookupIds: filter.tripStatusLookupID ? filter.tripStatusLookupID.join(',') : '',
			loadNumber: filter.txtNumber ? filter.txtNumber : '',
			trailerNumber: filter.txtTrailerNumber ? filter.txtTrailerNumber : '',
			containerNumber: filter.txtContainerNumber ? filter.txtContainerNumber : '',
			carrierName: filter.txtCarrier ? filter.txtCarrier : '',
			driverName: filter.txtDriver ? filter.txtDriver : '',
			flopName: filter.txtFLOPName ? filter.txtFLOPName : '',
			isCpu: filter.showCPU,
			warehouseID: filter.warehouseID ? filter.warehouseID.join(',') : '',
			vehicleIdentifier: filter.txtVehicleIdentifier ? filter.txtVehicleIdentifier : '',
			appointmentDateFrom: filter.AppointmentDateTimeFrom,
			appointmentDateTo: filter.AppointmentDateTimeTo,
			timeRoller: filter.timeroller,
			organizationID: filter.organizationID ? filter.organizationID : 0,
			searchText: filter.searchText ? filter.searchText : '',
			loadTypesList: filter.loadSubTypesList,
			pageIndex: pageIndex,
			pageSize: pageSize,
			orderBy: orderBy ? orderBy : 'ID',
			orderDirection: !orderDir ? 0 : orderDir - 1,
			showCompletedLoads: filter.ShowCompletedLoads,
			showActiveLoads: filter.ShowActiveLoads,
			showAllLoads: filter.ShowAllLoads,
			wareHouseFilterValue: filter.WareHouseFilter != undefined ? filter.WareHouseFilter.toString() : null,
			TripNumber: filter.TripNumber ? filter.TripNumber : '',
			OrignalLoadNumber: filter.OrignalLoadNumber ? filter.OrignalLoadNumber : '',
		});
		return callback;
	}
	public DownloadLoads(
		//eslint-disable-next-line
		filter: any
	): //eslint-disable-next-line
	Promise<ContractHttpResponse<Load[]>> {
		const callback = this.proxy.Get('DownloadLoads', {
			driverStatusLookupIds: filter.driverStatusLookupID ? filter.driverStatusLookupID.join(',') : '',
			loadStatusLookupIds: filter.loadStatusLookupID ? filter.loadStatusLookupID.join(',') : '',
			loadtripStatusLookupIds: filter.tripStatusLookupID ? filter.tripStatusLookupID.join(',') : '',
			loadNumber: filter.txtNumber ? filter.txtNumber : '',
			carrierName: filter.txtCarrier ? filter.txtCarrier : '',
			driverName: filter.txtDriver ? filter.txtDriver : '',
			flopName: filter.txtFlop ? filter.txtFlop : '',
			isCpu: filter.showCPU,
			warehouseID: filter.warehouseID ? filter.warehouseID.join(',') : '',
			appointmentDateFrom: filter.AppointmentDateTimeFrom,
			appointmentDateTo: filter.AppointmentDateTimeTo,
			timeRoller: filter.timeroller,
			organizationID: filter.organizationID ? filter.organizationID : 0,
			searchText: filter.searchText ? filter.searchText : '',
			loadTypesList: filter.loadSubTypesList,
			showCompletedLoads: filter.ShowCompletedLoads,
			showActiveLoads: filter.ShowActiveLoads,
			showAllLoads: filter.ShowAllLoads,
			wareHouseFilterValue: filter.WareHouseFilter != undefined ? filter.WareHouseFilter.toString() : null,
			TripNumber: filter.TripNumber ? filter.TripNumber : '',
			OrignalLoadNumber: filter.OrignalLoadNumber ? filter.OrignalLoadNumber : '',
		});
		return callback;
	}

	public DownloadBOL(tripID): //eslint-disable-next-line
	Promise<ContractHttpResponse<Attachment>> {
		const callback = this.proxy.Get('DownloadBOL', {
			tripID: tripID,
		});
		return callback;
	}
	public getCalendarData(
		//eslint-disable-next-line
		filter: any
	): //eslint-disable-next-line
	Promise<ContractHttpResponse<LoadCalendar[]>> {
		const callback = this.proxy.Get('GetCalendarData', {
			driverStatusLookupIds: filter.driverStatusLookupID ? filter.driverStatusLookupID.join(',') : '',
			loadStatusLookupIds: filter.loadStatusLookupID ? filter.loadStatusLookupID.join(',') : '',
			loadtripStatusLookupIds: filter.tripStatusLookupID ? filter.tripStatusLookupID.join(',') : '',
			loadNumber: filter.txtNumber ? filter.txtNumber : '',
			carrierName: filter.txtCarrier ? filter.txtCarrier : '',
			driverName: filter.txtDriver ? filter.txtDriver : '',
			flopName: filter.txtFlop ? filter.txtFlop : '',
			isCpu: filter.showCPU,
			warehouseID: filter.warehouseID ? filter.warehouseID.join(',') : '',
			appointmentDateFrom: filter.AppointmentDateTimeFrom,
			appointmentDateTo: filter.AppointmentDateTimeTo,
			timeRoller: filter.timeroller,
			organizationID: filter.organizationID ? filter.organizationID : 0,
			searchText: filter.searchText ? filter.searchText : '',
			loadTypesList: filter.loadSubTypesList,
			showCompletedLoads: filter.ShowCompletedLoads,
			showActiveLoads: filter.ShowActiveLoads,
			showAllLoads: filter.ShowAllLoads,
			wareHouseFilterValue: filter.WareHouseFilter != undefined ? filter.WareHouseFilter.toString() : null,
			TripNumber: filter.TripNumber ? filter.TripNumber : '',
			OrignalLoadNumber: filter.OrignalLoadNumber ? filter.OrignalLoadNumber : '',
		});
		return callback;
	}

	public LoadPageList(
		pageIndex: number,
		pageSize: number,
		orderBy: string,
		orderDir: SortingDirection,
		loadNumber: string,
		//eslint-disable-next-line
		filter: any
	): //eslint-disable-next-line
	Promise<ContractHttpResponse<PagedList<Load>>> {
		const callback = this.proxy.Get('LoadPageList', {
			loadNumbers: loadNumber,
			number: filter.txtNumber ? filter.txtNumber : '',
			searchText: filter.searchText ? filter.searchText : '',
			pageIndex: pageIndex,
			pageSize: pageSize,
			orderBy: orderBy ? orderBy : 'ID',
			orderDirection: !orderDir ? 0 : orderDir - 1,
		});
		return callback;
	}

	public validate(): boolean {
		return true;
	}

	public saveOrUpdate(LoadDetail: LoadDetail): Promise<ContractHttpResponse<LoadDetail>> {
		if (LoadDetail.ID > 0) {
			if (LoadDetail.Loads != null) {
				LoadDetail.Loads = LoadDetail.Loads.toString();
			}
			return this.proxy.Put('Update', LoadDetail);
		} else {
			return this.proxy.Post('Save', LoadDetail);
		}
	}
	public delete(): Promise<LoadDetail> {
		throw new Error('Method not implemented.');
	}
	public CheckIfIsCarrierRequired(
		loadIDs: number[],
		ActualCarrierID: number
	): Promise<ContractHttpResponse<boolean>> {
		const callback = this.proxy.Get('CheckIfIsCarrierRequired', {
			loadIDs: loadIDs,
			ActualCarrierID: ActualCarrierID,
		});
		return callback;
	}
	public checkForExistingActiveTrip(loadID: number): Promise<ContractHttpResponse<boolean>> {
		const callback = this.proxy.Get('CheckIfActiveTripExists', {
			loadID: loadID,
		});
		return callback;
	}
	public checkForExistingTrip(loadID: number): Promise<ContractHttpResponse<boolean>> {
		const callback = this.proxy.Get('CheckIfTripExists', {
			loadID: loadID,
		});
		return callback;
	}
	public checkForInProgressTrip(loadID: number): Promise<ContractHttpResponse<boolean>> {
		const callback = this.proxy.Get('CheckIfInProgressTripExists', {
			loadID: loadID,
		});
		return callback;
	}
	public sendDriverStatusMessage(
		loadID: number,
		driverCheckedIn: boolean,
		driverUserProfileID: number,
		cellNumber: string,
		operatorID: number
	): Promise<ContractHttpResponse<SendMessageResult>> {
		const callback = this.proxy.Get('SendDriverStatusMessage', {
			loadID: loadID,
			DriverCheckedIn: driverCheckedIn,
			DriverUserProfileID: driverUserProfileID,
			MobileNumber: cellNumber,
			operatorUserProfileID: operatorID,
		});
		return callback;
	}
	public textTheDriver(
		loadID: number,
		messageText: string,
		operatorID: number,
		loadTypeLookupValue: number
	): Promise<ContractHttpResponse<SendMessageResult>> {
		const callback = this.proxy.Get('TextTheDriver', {
			loadID: loadID,
			MessageText: messageText,
			operatorID: operatorID,
			loadTypeLookupValue: loadTypeLookupValue,
		});
		return callback;
	}
	public GetLoadsAvailableForAttachment(
		LoginAffiliation: string,
		currentLoadId: number
	): Promise<ContractHttpResponse<Load[]>> {
		const request = this.proxy.Get('GetLoadsAvailableForAttachment', {
			LoginAffiliation: LoginAffiliation,
			currentLoadId: currentLoadId,
		});
		return request;
	}
	public getDriverMessageContent(
		loadNumber = null,
		dockName = null,
		doorName = null,
		yard = null,
		yardLocation = null,
		loadTypeLookupValue = 0,
		organizationID = 0
	): Promise<ContractHttpResponse<SendMessageResult>> {
		const callback = this.proxy.Get('GetDriverMessageContent', {
			LoadNumber: loadNumber,
			DockName: dockName,
			DoorName: doorName,
			Yard: yard,
			YardLocation: yardLocation,
			loadTypeLookupValue: loadTypeLookupValue,
			organizationID: organizationID,
		});
		return callback;
	}
	public getLoadFeed(loadId: number, organizationID: number): Promise<ContractHttpResponse<CommentSection>> {
		return this.proxy.Get('GetLoadComments', {
			loadID: loadId,
			organizationID: organizationID,
		});
	}
	public getLoadsByAssetStatus(
		assetStatusId: number,
		organizationID: number,
		loadID: number[],
		isLive:boolean,
	): Promise<ContractHttpResponse<LoadDropDown[]>> {
		return this.proxy.Get('GetLoadsByAssetStatus', {
			assetStatusID: assetStatusId,
			OrganizationID: organizationID,
			loadIDs: loadID,
			isLive:isLive,
		});
	}
	public getUnreadMessage(loadId: number): Promise<ContractHttpResponse<number>> {
		return this.proxy.Get('GetUnreadMessages', {
			loadID: loadId,
		});
	}
	public GetLoadsOfSimilarType(loadID: number, organizationID: number): Promise<ContractHttpResponse<Load[]>> {
		const callback = this.proxy.Get('GetLoadsOfSimilarType', {
			loadID: loadID,
			organizationID: organizationID,
		});
		return callback;
	}

	public GetMultipleLoadsForAppointment(
		loadID: number,
		organizationID: number,
		isRequestCreated: boolean,
		tripID: number
	): Promise<ContractHttpResponse<Load[]>> {
		const callback = this.proxy.Get('GetMultipleLoadsForAppointment', {
			loadID: loadID,
			organizationID: organizationID,
			isRequestCreated: isRequestCreated,
			tripID: tripID,
		});
		return callback;
	}

	public GetMultipleLoadsForAppointmentAnonymous(
		loadID: number,
		organizationID: number,
		isRequestCreated: boolean,
		tripID: number
	): Promise<ContractHttpResponse<Load[]>> {
		const callback = this.proxy.Get('GetMultipleLoadsForAppointmentAnonymous', {
			loadID: loadID,
			organizationID: organizationID,
			isRequestCreated: isRequestCreated,
			tripID: tripID,
		});
		return callback;
	}

	public GetMatchingLoadsOfSimilarType(
		LoadTypeId: number,
		organizationID: number,
		CompanyId: number,
		IsInternalCompany: boolean,
		transferOrderSubtenantid: number,
		isCompleted: boolean
	): Promise<ContractHttpResponse<Load[]>> {
		const callback = this.proxy.Get('GetMatchingLoadsOfSimilarType', {
			LoadTypeId: LoadTypeId,
			CompanyId: CompanyId,
			organizationID: organizationID,
			IsInternalCompany: IsInternalCompany,
			transferOrderSubtenantid: transferOrderSubtenantid,
			isCompleted: isCompleted,
		});
		return callback;
	}

	public getByIds(ids): Promise<ContractHttpResponse<Load[]>> {
		const callback = this.proxy.Get('GetList', {
			ids: [ids],
		});
		return callback;
	}

	public GetLoadList(ids, organizationId: number): Promise<ContractHttpResponse<Load[]>> {
		const callback = this.proxy.Get('GetLoadList', {
			ids: ids,
			organizationId: organizationId,
		});
		return callback;
	}

	public SaveBulk(loads: Load[]): Promise<ContractHttpResponse<Load[]>> {
		const callback = this.proxy.Post('SaveBulk', loads);
		return callback;
	}

	public SaveBulkForSplitLoad(loadDTOs: Load[]): Promise<ContractHttpResponse<Load[]>> {
		const callback = this.proxy.Post('SaveBulkForSplitLoad', loadDTOs);
		return callback;
	}

	public GetAllowedLoadsForAppointment(organizationID): Promise<ContractHttpResponse<number[]>> {
		const callback = this.proxy.Get('GetAllowedLoadsForAppointment', {
			organizationID: organizationID,
		});
		return callback;
	}

	GetLoadIDsByLoadNumber(loadNumbers: string, OrganizationID: number): Promise<ContractHttpResponse<any>> {
		return this.proxy.Get('GetLoadIDsByLoadNumber', {
			loadNumbers: loadNumbers,
			OrganizationID: OrganizationID,
		});
	}
}
