<div class="main-content-holder row">
  <div class="col p0">
    <div class="ssi-formGroup">
      <div class="btn-holder">
        <ul class="btnlist">
          <li *ssiPermission="'UPDATE_NOTIFICATIONSUBSCRIPTION'">
            <button igxButton type="submit" class="btn-primary" (click)="onSave()">{{'SAVE_BTN_TTP' | ssiTranslator }}</button>
          </li>
          <li><button igxButton type="submit" (click)="onCancel()">{{'CANCEL_BTN_TTP' | ssiTranslator }}</button></li>
        </ul>
      </div>
    </div>

    <div class="addSubscriptionArea">
      <div class="leftPanel">
        <igx-grid #FWNewUsersGrid id="FWNewUsersGrid" [data]="newUsers" [primaryKey]="'LoginName'" ssiGrid
          [enableColumnMovable]="true" [enableDefault]="false"  [enableToolbar]="false"
          [enableSorting]="true" [enableColumnHiding]="false" [enablePersonalization]="true" >
          <igx-column field="ID" width="4%">
            <ng-template igxCell let-user="cell" >
              <igx-checkbox [checked]="user.row.rowData.Selected" [(ngModel)]="user.row.rowData.Selected" (change)="sourceSelection($event, user.row.rowData)"  ></igx-checkbox>
            </ng-template>
          </igx-column>
          <igx-column field="LoginName" header="{{'LOGIN_NAME_LBL' | ssiTranslator }}">
          </igx-column>
          
        </igx-grid>
      </div>
      <div class="actionArea">
        <ul>
          <li>
            <button igxButton igxButton="icon" (click)="moveFromDestination()" type="submit">
              <igx-icon style="transform: rotate(180deg);padding-right: 36px;">play_arrow</igx-icon>
            </button>
          </li>
          <li>
              <button igxButton igxButton="icon" (click)="moveAllFromDestination()" type="submit">
                  <igx-icon>fast_rewind</igx-icon>
              </button>
          </li>
          <li>
              <button igxButton igxButton="icon" (click)="moveFromSource()" type="submit">
                  <igx-icon>play_arrow</igx-icon>
              </button>
          </li>
          <li>
              <button igxButton igxButton="icon" (click)="moveAllFromSource()" type="submit">
                  <igx-icon>fast_forward</igx-icon>
              </button>
          </li>
      </ul>

      </div>

      <div class="rightPanel">
        <igx-grid #FWNotificationSubscriptionGrid id="FWNotificationSubscriptionGrid" [data]="model"
          [primaryKey]="'LoginName'" ssiGrid [enableColumnMovable]="true" [enableDefault]="false"
          [enableToolbar]="false" [enableSorting]="true" [enableColumnHiding]="false" [enablePersonalization]="true">
          <igx-column width="5%">
            <ng-template igxCell let-user="cell" >
              <igx-checkbox [checked]="user.row.rowData.Selected" [(ngModel)]="user.row.rowData.Selected" (change)="destinationSelection($event, user.row.rowData)"  ></igx-checkbox>
            </ng-template>
          </igx-column>
          <igx-column field="LoginName" header="{{'LOGIN_NAME_LBL' | ssiTranslator }}">
          </igx-column>
        </igx-grid>
      </div>
    </div>
  </div>


  <!-- <div class="col p0">
      <igx-grid #FWNotificationSubscriptionGrid id="FWNotificationSubscriptionGrid" ssiGrid [enableDefault]="true" [data] = "newUsers">
          <igx-column field="ID" header="ID" hidden="true">
          </igx-column>
        <igx-column field="LoginName" header="Login Name">
          </igx-column>
      </igx-grid>
  </div> -->
