<igx-date-picker #picker="ngModel" [(ngModel)]="model" [mode]="mode" [disabledDates]="DisableDates"
  [id]="id" [attr.name]="name" [required]="required" >

  <ng-template igxDatePickerTemplate let-openDialog="openDialog" let-value="value">
    <igx-input-group>
      <label igxLabel [ngClass]="{'required': required}">{{label | ssiTranslator}}</label>

      <input #dropDownTarget igxInput #input="ngModel" [(ngModel)]="inputModel" (blur)="onDateSelection(dropDownTarget.value)" [disabled]="disabled"
        [value]="value | ssiDate:'date'" type="text" autocomplete="off" [igxMask]="mask" [tabindex]="index"
        [igxTextSelection]="selectOnFocus && model" [placeholder]="placeholder" [readonly]="!editable"
        [required]="required" [igxFocus]="isFocused" (focus)="onFocus($event)" />

      <igx-suffix (click)="!disabled && openDialog(dropDownTarget);">
        <span class="icon icon-calendar"></span>
      </igx-suffix>
    </igx-input-group>

  </ng-template>
</igx-date-picker>


<!-- Hint -->
<div class="input-helper">
  <div>
    <div class="error">
      <ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
    </div>
  </div>
</div>

<ng-template #errorTemplate>
  <ng-container *ngIf="errorMessage">
    {{ errorMessage }}
  </ng-container>
  <ng-container *ngIf="input?.touched && !errorMessage">
    <ng-container *ngIf="input?.errors?.required">
      {{ label }} is a required field
    </ng-container>
  </ng-container>
</ng-template>
