import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChange, ElementRef, ContentChild, TemplateRef } from '@angular/core';
import {
  IgxToggleDirective, VerticalAlignment, ConnectedPositioningStrategy, CloseScrollStrategy,
  HorizontalAlignment, OverlaySettings, IgxTreeGridComponent, IgxOverlayOutletDirective, IgxStringFilteringOperand, IgxInputGroupComponent, IgxInputDirective, AbsoluteScrollStrategy, BlockScrollStrategy
} from 'igniteui-angular';

@Component({
  selector: 'lib-ssi-hierarchical-dropdown',
  templateUrl: './ssi-hierarchical-dropdown.component.html',
  styleUrls: ['./ssi-hierarchical-dropdown.component.scss']
})
export class SsiHierarchicalDropdownComponent implements OnInit, OnChanges {

  @Input() data: any[] = []; 
  @Input() childKey: string;
  @Input() parentKey: string;
  @Input() textKey: string;
  @Input() valueKey: string;
  @Input() selectionLevel: number;
  @Input() showSearchBox = true;
  @Input() enableReadonlyCheked: boolean = true;
  @Input() header: string;
  @Input() saveLabel = 'Save';
  @Input() cancelLabel = 'Cancel';
  @Input() width: string;
  @Input() height: string;
  @Input() overlayOutlet: Boolean = false;
  @Input() isModal: boolean = true;
  @Input() isDropdown: boolean = false;

  @Input() label: string;
  @Input() name: string;
  @Input() required: boolean;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() index: number;
  @Input() selectedNames: any;
  @Input() selectedIds: any;

  @Output() saveClicked = new EventEmitter<any[]>();
  @Output() closeClicked = new EventEmitter(); overlayDropDownInputGroup; 

  @ViewChild('overlayDropDown', { static: false }) overlayDropDown :IgxInputGroupComponent;
  @ViewChild('search') searchFilter:ElementRef;
  @ViewChild(IgxToggleDirective, { static: true }) toggle: IgxToggleDirective;
  @ViewChild(IgxTreeGridComponent, { static: false }) treeGridDDL: IgxTreeGridComponent;
  @ViewChild(IgxOverlayOutletDirective, { static: false }) outlet: IgxOverlayOutletDirective;

  @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>;

  showContent = false;
  isWidthUpdated = false;
  public _positionSettings = {
    horizontalStartPoint: HorizontalAlignment.Left,
    horizontalDirection: HorizontalAlignment.Right,
    verticalStartPoint: VerticalAlignment.Bottom,
    verticalDirection: VerticalAlignment.Bottom
  };
  public _dropdownOverlaySettings: OverlaySettings = {
    closeOnOutsideClick: true,
    modal: this.isModal,
    positionStrategy: new ConnectedPositioningStrategy(this._positionSettings),
    scrollStrategy: new AbsoluteScrollStrategy()
  };

  public _overlaySettings: OverlaySettings = {
    closeOnOutsideClick: true,
    modal: this.isModal,
    positionStrategy: new ConnectedPositioningStrategy(this._positionSettings),
    scrollStrategy: new CloseScrollStrategy()
  };
  isWidth: any;
  constructor() { }

  ngOnInit() {
    this.toggle.onClosed.subscribe(n => {
      this.onCloseToggle(false);
    });
  }

  ngOnChanges() {
    
    if (this.showSearchBox) {
      this.showSearchBox = this.showSearchBox
    } else {
      this.showSearchBox = this.showSearchBox
    }

  }

  public filter(term) {
    this.treeGridDDL.filter(this.textKey, term, IgxStringFilteringOperand.instance().condition("contains"));
  }

  clear() {
    this.treeGridDDL.clearFilter(this.textKey);
  }

  public show(element: HTMLElement, values: any[]) {
    this.searchFilter.nativeElement.value = ''
    if (this.toggle.collapsed) {
      this.showContent = true;

      this.filterDataByLevel();

      setTimeout(() => {
        if (this.overlayOutlet) {
          this._overlaySettings.outlet = this.outlet;
        }
        if (this.width !== undefined && this.width != null && this.height != null && this.height !== undefined) {
          this.isWidthUpdated = true;
          this.treeGridDDL.width = this.width;
          this.treeGridDDL.height = this.height;
        }
        else {
          this.treeGridDDL.width = '350px';
          this.treeGridDDL.height = '350px';
          this.isWidthUpdated = false;
        }
        this.setOverlaySettingAccordingToWindow(element);
        this._overlaySettings.target = element;
        this._overlaySettings.modal = this.isModal;

        //this._overlaySettings.target = element.parentElement.parentElement;
        //this._overlaySettings.positionStrategy = new ConnectedPositioningStrategy(this._positionSettings);
        this.toggle.open(this._overlaySettings);

        this.setSelection(values);
      }, 100);
    }
  }

  public showDropdown() {
    this.searchFilter.nativeElement.value = ''
    if (this.toggle.collapsed) {
      this.showContent = true;

      this.filterDataByLevel();

      setTimeout(() => {
        if (this.overlayOutlet) {
          this._overlaySettings.outlet = this.outlet;
        }
        if (this.width !== undefined && this.width != null && this.height != null && this.height !== undefined) {
          this.isWidthUpdated = true;
          this.treeGridDDL.width = this.width;
          this.treeGridDDL.height = this.height;
        }
        else {
          this.treeGridDDL.width = '350px';
          this.treeGridDDL.height = '350px';
          this.isWidthUpdated = false;
        }

        this.setDropdownOverlaySetting(this.overlayDropDown.element.nativeElement);
        this._dropdownOverlaySettings.target = this.overlayDropDown.element.nativeElement;
        this._dropdownOverlaySettings.modal = this.isModal;
        this.toggle.open(this._dropdownOverlaySettings);

        this.setSelection(this.selectedIds);
      }, 100);
    }
  }

  public setSelection(values: any[]) {
    if (values != null) {
      this.selectChildren(this.data, values);
    }
  }

  public onRowSelectionChange(event: any, rowData: any) {
    if (event != null) {
      this.selectChilds(rowData, event.checked);

      if (event.checked) {
        this.selectParent(rowData);
      } else {
        this.unselectParent(rowData);
      }
    }
  }

  public onCloseToggle(closeToggle: boolean) {
    this.clearSelection(this.data);
    if (closeToggle) {
      this.toggle.close();
    }
    this.closeClicked.emit();

    this.showContent = false;
  }

  public onSaveToggle() {
    const selectedItems = this.getSelectedValues(this.data, 1);
    this.saveClicked.emit(selectedItems);
    this.clearSelection(this.data);

    this.toggle.close();

  }

  private getSelectedValues(items: any[], level: number): any[] {
    let selected: any[] = [];
    if (items != null && items.length) {
      items.forEach(n => {
        if ((this.selectionLevel == null || this.selectionLevel === level) && n.Selected != null && n.Selected) {
          selected.push(n);
        }

        const selectedItems = this.getSelectedValues(n[this.childKey], level + 1);
        if (selectedItems != null && selectedItems.length) {
          selected = selected.concat(selectedItems);
        }
      });
    }
    return selected;
  }

  private clearSelection(items: any[]) {
    if (items != null && items.length) {
      items.forEach(n => {
        if (n.Selected != null && n.Selected) {
          n.Selected = null;
        }
        if (n.Indeterminate != null && n.Indeterminate) {
          n.Indeterminate = null;
        }

        this.clearSelection(n[this.childKey]);
      });
    }
  }

  private selectChildren(items: any[], values: any[]) {
    if (items != null && items.length) {
      items.forEach(n => {
        if (values.indexOf(n[this.valueKey]) !== -1) {
          n.Selected = true;

          this.selectChilds(n, true);
          this.selectParent(n);
        }

        this.selectChildren(n[this.childKey], values);
      });
    }
  }

  public setReadonly(values: any[], readonly: boolean) {
    if (values != undefined && values.length > 0) {
      this.setChildrenReadonly(this.data, values, readonly);
    }
  }

  private setChildrenReadonly(items: any[], values: any[], readonly: boolean) {
    if (items != null && items.length) {
      items.forEach(n => {
        if (values.indexOf(n[this.valueKey]) !== -1) {
          n.Readonly = readonly;
        }
        this.setChildrenReadonly(n[this.childKey], values, readonly);
      });
    }
  }

  private selectChilds(item: any, selected: boolean) {
    const children = item[this.childKey] as any[];
    if (children != null && children.length) {
      children.forEach(n => {
        if (!this.enableReadonlyCheked) {
          n.Selected = n.Readonly != undefined && n.Readonly == true ? false : selected;
          n.Indeterminate = n.Selected == true ? false : n.Indeterminate;
        }else{
          n.Selected = selected;
          n.Indeterminate = n.Selected == true ? false : n.Indeterminate;
        }
        
        this.selectChilds(n, selected);
      });
    }
  }

  private unselectParent(item: any) {
    let parent = this.treeGridDDL.flatData.filter(n => n[this.valueKey] === item[this.parentKey]);
    while (parent != null && parent.length) {
      parent[0].Selected = false;
      if (this.isAnyChildrenSelected(parent[0], item)) {
        parent[0].Indeterminate = true;
      } else {
        parent[0].Indeterminate = false;
      }

      const parentId = parent[0][this.parentKey];
      if (parentId > 0) {
        parent = this.treeGridDDL.flatData.filter(n => n[this.valueKey] === parent[0][this.parentKey]);
      } else {
        parent = null;
      }
    }
  }

  private selectParent(item: any) {
    let parent = this.treeGridDDL.flatData.filter(n => { return n[this.valueKey] === item[this.parentKey] });
    while (parent != null && parent.length) {
      if (this.isAllChildrenSelected(parent[0], item)) {
        parent[0].Selected = true;
        parent[0].Indeterminate = false;
      } else {
        parent[0].Selected = false;
        parent[0].Indeterminate = true;
      }

      const parentId = parent[0][this.parentKey];
      if (parentId > 0) {
        parent = this.treeGridDDL.flatData.filter(n => n[this.valueKey] === parent[0][this.parentKey]);
      } else {
        parent = null;
      }
    }
  }

  private isAllChildrenSelected(item: any, ignoreItem: any) {
    let selected = true;
    const children = item[this.childKey] as any[];
    if (children != null && children.length) {
      children.forEach(n => {
        if (n[this.valueKey] !== ignoreItem[this.valueKey] && !n.Selected) {
          selected = false;
        } else {
          const _selected = this.isAllChildrenSelected(n, ignoreItem);
          if (!_selected) {
            selected = false;
          }
        }
      });
    }
    return selected;
  }

  private isAnyChildrenSelected(item: any, ignoreItem: any) {
    let selected = false;
    const children = item[this.childKey] as any[];
    if (children != null && children.length) {
      children.forEach(n => {
        if (n[this.valueKey] !== ignoreItem[this.valueKey] && n.Selected) {
          selected = true;
        } else {
          const _selected = this.isAnyChildrenSelected(n, ignoreItem);
          if (_selected) {
            selected = true;
          }
        }
      });
    }
    return selected;
  }

  private filterDataByLevel() {
    if (this.selectionLevel != null && this.data != null) {
      this.data = this.data.filter((item) => this.hasSelectionLevelItem(item, 1));
    }
  }

  private hasSelectionLevelItem(item: any, level: number): boolean {
    let isValid = false;

    if (level === this.selectionLevel) {
      isValid = true;
    } else {
      const children = item[this.childKey];
      if (children != null && children.length) {
        children.forEach((childItem) => {
          const isValidChild = this.hasSelectionLevelItem(childItem, level + 1);
          if (isValidChild) {
            isValid = true;
          }
        });
      }
    }

    return isValid;
  }

  private setOverlaySettingAccordingToWindow(nativeElement: HTMLElement) {
    this._overlaySettings.positionStrategy.settings.target = nativeElement;
    if ((window.innerHeight - nativeElement.getBoundingClientRect().top) < 550) {
      if (nativeElement.getBoundingClientRect().top > 550) {
        // if the height above the element is greater than required height
        this._overlaySettings.positionStrategy.settings.verticalStartPoint = VerticalAlignment.Top;
        this._overlaySettings.positionStrategy.settings.verticalDirection = VerticalAlignment.Top;

        this.treeGridDDL.height = this.height != null && this.height !== undefined ? this.height : '350px';
      } else {
        // if the height above/below the element is not according to required height,
        // then adjust grid height according to the height available below

        if ((window.innerHeight - nativeElement.getBoundingClientRect().top - 200) > (nativeElement.getBoundingClientRect().top - 100)) {
          this._overlaySettings.positionStrategy.settings.verticalStartPoint = VerticalAlignment.Bottom;
          this._overlaySettings.positionStrategy.settings.verticalDirection = VerticalAlignment.Bottom;
          this.treeGridDDL.height = (window.innerHeight - nativeElement.getBoundingClientRect().top - 200) + 'px';
        } else {
          this._overlaySettings.positionStrategy.settings.verticalStartPoint = VerticalAlignment.Top;
          this._overlaySettings.positionStrategy.settings.verticalDirection = VerticalAlignment.Top;
          this.treeGridDDL.height = (nativeElement.getBoundingClientRect().top - 200) + 'px';
        }
      }
    } else {
      // if the height below the element is greater than required height
      this._overlaySettings.positionStrategy.settings.verticalStartPoint = VerticalAlignment.Bottom;
      this._overlaySettings.positionStrategy.settings.verticalDirection = VerticalAlignment.Bottom;

      this.treeGridDDL.height = this.height != null && this.height !== undefined ? this.height : '350px';
    }

    if ((window.innerWidth - (nativeElement.getBoundingClientRect() as DOMRect).x - nativeElement.getBoundingClientRect().width) > 360) {
      // if the width on right side of the element is greater than required width
      this._overlaySettings.positionStrategy.settings.horizontalStartPoint = HorizontalAlignment.Left;
      this._overlaySettings.positionStrategy.settings.horizontalDirection = HorizontalAlignment.Right;
    } else {
      // if the width on right side of the element is not greater than required width, then show on left
      this._overlaySettings.positionStrategy.settings.horizontalStartPoint = HorizontalAlignment.Right;
      this._overlaySettings.positionStrategy.settings.horizontalDirection = HorizontalAlignment.Left;
    }
  }

  private setDropdownOverlaySetting(nativeElement: HTMLElement ){
    this._dropdownOverlaySettings.target = nativeElement;
    //let ancester nativeElement ///this.findAncestor(nativeElement)
    
    if ((window.innerHeight - nativeElement.getBoundingClientRect().top) < 550) {
      if (nativeElement.getBoundingClientRect().top > 550) {
        // if the height above the element is greater than required height
        this._overlaySettings.positionStrategy.settings.verticalStartPoint = VerticalAlignment.Bottom;
        this._overlaySettings.positionStrategy.settings.verticalDirection = VerticalAlignment.Bottom;
        this.treeGridDDL.height = this.height != null && this.height !== undefined ? this.height : '350px';
      } else {
        // if the height above/below the element is not according to required height,
        // then adjust grid height according to the height available below

        if ((window.innerHeight - nativeElement.getBoundingClientRect().top - 200) > (nativeElement.getBoundingClientRect().top - 100)) {
          this._overlaySettings.positionStrategy.settings.verticalStartPoint = VerticalAlignment.Bottom;
          this._overlaySettings.positionStrategy.settings.verticalDirection = VerticalAlignment.Bottom;
          this.treeGridDDL.height = (window.innerHeight - nativeElement.getBoundingClientRect().top - 200) + 'px';
          
        } else {
          this._overlaySettings.positionStrategy.settings.verticalStartPoint = VerticalAlignment.Bottom;
          this._overlaySettings.positionStrategy.settings.verticalDirection = VerticalAlignment.Bottom;
          this.treeGridDDL.height = (nativeElement.getBoundingClientRect().top - 200) + 'px';
        }
      }
    } else {
      // if the height below the element is greater than required height
      this._overlaySettings.positionStrategy.settings.verticalStartPoint = VerticalAlignment.Bottom;
      this._overlaySettings.positionStrategy.settings.verticalDirection = VerticalAlignment.Bottom;
      this.treeGridDDL.height = this.height != null && this.height !== undefined ? this.height : '350px';
    }

    if ((window.innerWidth - (nativeElement.getBoundingClientRect() as DOMRect).x - nativeElement.getBoundingClientRect().width) > 360) {
      // if the width on right side of the element is greater than required width
      this._overlaySettings.positionStrategy.settings.horizontalStartPoint = HorizontalAlignment.Center;
      this._overlaySettings.positionStrategy.settings.horizontalDirection = HorizontalAlignment.Center;
      this.isWidth = nativeElement.offsetWidth.toString();
      this.treeGridDDL.width =  nativeElement.offsetWidth.toString() + 'px';
    } else {
      // if the width on right side of the element is not greater than required width, then show on left
      this._overlaySettings.positionStrategy.settings.horizontalStartPoint = HorizontalAlignment.Center;
      this._overlaySettings.positionStrategy.settings.horizontalDirection = HorizontalAlignment.Center;
      this.isWidth = nativeElement.offsetWidth.toString();
      this.treeGridDDL.width =  nativeElement.offsetWidth.toString() + 'px';
    }

    
  }

}
