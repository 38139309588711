import { Directive, HostListener, OnInit, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
  selector: '[ssiImageLoader]'
})
export class SsiImageLoaderDirective implements OnInit {
  private _loader: HTMLElement;
  private _fileSrc: string;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @Input('ssiImageLoader') set fileSource(src: string) {
    if (this._fileSrc !== src) {
      this._fileSrc = src;
      this.renderer.setAttribute(this.el.nativeElement, 'src', '');
      this.renderer.setAttribute(this.el.nativeElement, 'src', src);
      // remove existing loader if not removed
      if (this.el.nativeElement.parentElement.contains(this._loader)) {
        this.renderer.removeChild(document.body, this._loader);
      }
      this._loader = this.renderer.createElement('div');
      this.renderer.addClass(this._loader, 'img-loader');
      this.renderer.insertBefore(
        this.el.nativeElement.parentElement,
        this._loader,
        this.el.nativeElement
      );
    }
  }

  ngOnInit() {
    this.renderer.setStyle(this.el.nativeElement, 'min-height', '80px');
  }

  @HostListener('load')
  onImageLoaded() {
    if (this.el.nativeElement.parentElement.contains(this._loader)) {
      this.renderer.removeChild(document.body, this._loader);
    }
  }

}
