import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticateClientService, UserSettingsService, PermissionManagerService } from 'ssi-framework';
import { CachedUserSettingsService } from '../../core/services/cached-user-settings-service';
import { CommonConstants } from '../constants/constants';

@Injectable({ providedIn: 'root' })
export class LoggedInUserInformation {
	constructor(
		private router: Router,
		protected activeRoute: ActivatedRoute,
		private auth: AuthenticateClientService,
		private userSettingsService: UserSettingsService,
		private permissionManager: PermissionManagerService,
		private cachedSettingsService: CachedUserSettingsService
	) {}

	public GetUserAffiliationFromSettings() {
		//let settingCode = CommonConstants.CURRENT_LOGIN_AFFILIATION;
		const UsersAffiliationsetting = this.cachedSettingsService.userAffiliations;
		// this.userSettingsService.getUserSettingsByCode(settingCode);
		if (UsersAffiliationsetting != null) {
			return JSON.parse(UsersAffiliationsetting);
		} else {
			return null;
		}
	}

	public GetLoginAffiliation() {
		let settingCode = CommonConstants.CURRENT_LOGIN_AFFILIATION;
		let UsersAffiliationsetting = this.cachedSettingsService.userAffiliations;
		//this.userSettingsService.getUserSettingsByCode(settingCode);
		return UsersAffiliationsetting;
	}
}
