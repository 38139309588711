<div #snackbar class="toast-container" [ngClass]="status">
  <div class="toast-icon">
    <span class="icon icon-info"></span> 
  </div>
  <div *ngIf="isContent == false; else displayContent" class="toast-message">
    {{message}}
  </div>
  <ng-template #displayContent>
    <div [ngTemplateOutlet]="message" class="toast-message">
    </div>
  </ng-template>
  <div class="toast-action">
    <igx-icon (click)="closeToast()" *ngIf="status == 'error'">close</igx-icon> 
  </div>
</div>
