export class NotificationEventConfiguration{
    public ID: number;
    public EventID: number;
    public EventCode: string;
    public EventName: string;
    public NotificationMode: string;
    public SubjectNotificationTemplateID: number;
    public SubjectTemplate: string;
    public MessageNotificationTemplateID: number;
    public MessageTemplate: string;
    public CreatedBy: string;
    public CreatedOn: Date;
    public ModifiedBy: string;
    public ModifiedOn: Date;
}