import { Injectable, ComponentFactoryResolver, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, CanActivate, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthenticateClientService } from '../../Authentication/authenticate-client.service';
import { MenuItem } from '../Menu/menu.model';
import { LayoutService } from '../Services/layout.service';
import { UtilityService } from '../../Common/utility.service';

@Injectable({
    providedIn: 'root'
})
export class RouteHandlerService implements CanActivate {
    path: ActivatedRouteSnapshot[];
    route: ActivatedRouteSnapshot;
    currentTabComponent: any;
    previousTabComponentSelector: any;
    currentTabComponentSelector: any;

    private previousUrl: string;
    private currentUrl: string;
    private lazyMenuItems: MenuItem[] = [];

    public getPreviousUrl() {
        return this.previousUrl;
    }

    constructor(private oauthService: OAuthService,
        private authClient: AuthenticateClientService,
        private router: Router,
        private resolver: ComponentFactoryResolver,
        private layoutService: LayoutService,
        private utilityService: UtilityService,
        @Inject('EnvironmentVariables') private _environment: any,
        protected activeRoute: ActivatedRoute)  {
		this.currentUrl = this.router.url;

		router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.previousUrl = this.currentUrl;
				this.currentUrl = event.url;

				let path = this.ExcludeModuleFromRoute(this.currentUrl);
				let r = this.getMatchingRoutes(path);
				let disableBack = false;
				if (r.length > 0) {
					r.forEach((x) => {
						if (x.data && x.data.mainRoute) {
							this.layoutService.disableBackNavigation();
							disableBack = true;
							return;
						}
					});
					if (disableBack) {
						return;
					}
				}
				path = this.ExcludeModuleFromRoute(this.previousUrl);
				r = this.getMatchingRoutes(path);
				if (r.length > 0) {
					r.forEach((x) => {
						if (x.data && x.data.mainRoute) {
							this.layoutService.enableBackNavigation(this.previousUrl);
							return;
						}
					});
				}
			}
		});
	}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if ((route.routeConfig.path === 'login' || route.routeConfig.path === 'forgotPassword' || route.routeConfig.path === 'resetPassword')
            && this.authClient.isAuthenticated()) {
            this.router.navigateByUrl('/home');
            return false;
        }
        else if (route.routeConfig.path === 'forgotPassword' || route.routeConfig.path === 'resetPassword') {
            return true;
        }
        else if (this._environment.AllowAnonymousURLs.some(x => route.routeConfig.path.startsWith(x))) {
            return true;
        }
        else if (route.routeConfig.path !== 'login' && !this.authClient.isAuthenticated()) {
            this.router.navigateByUrl('/login');
            return false;
        }
        // else if (route.routeConfig.path != "login" && this.authClient.isAuthenticated()) {
        //   return true;
        // }
        else {
            return true;
        }
    }

    getChildRoutes(parentPath: string): Route[] {
        const parents = this.router.config.filter(o => o.path === parentPath);
        if (parents.length > 0) {
            return parents[0].children;
        }

        return [];
    }

    addChildRoutes(parentPath: string, childRoutes: Route[]) {
        const parents = this.router.config.filter(o => o.path === parentPath);
        if (parents.length > 0) {
            if (parents[0].children == null) {
                parents[0].children = [];
            }
            parents[0].children = parents[0].children.concat(childRoutes);
            this.router.resetConfig(this.router.config);
        }
    }

    removeChildRoutes(parentPath: string, childPath: string) {
        const parents = this.router.config.filter(o => o.path === parentPath);
        if (parents.length > 0) {
            parents[0].children = parents[0].children.filter(route => route.path !== childPath);

            this.updateRouteConfig(parents);
        }
    }

    private ExcludeModuleFromRoute(url: string) {
        const parts = url.split('/');
        const initialPart = parts[0] !== '' ? parts[0] : parts[1];
        if (this.router.config.some(n => n.loadChildren && n.path === initialPart)) {
            return url.replace(`/${initialPart}/`, ``).replace(`${initialPart}/`, '');
        }
        return url;
    }

    private updateRouteConfig(config) {
        this.router.resetConfig(config);
    }

    private getMatchingRoutes(path: string) {
        const r = [];
        this.router.config.forEach(o => {
            if (o.path !== '' && this.isRouteMatching(path, o.path)) {
                r.push(o);
            }// forChildRoutes
            else if (o.path !== '' && (o as any)._loadedConfig != null && (o as any)._loadedConfig.routes != null 
            && (o as any)._loadedConfig.routes.length > 0) {
                (o as any)._loadedConfig.routes.forEach(childRoute => {
                    if (childRoute.path != '' && this.isRouteMatching(path, childRoute.path)) {
                        r.push(childRoute);
                    }
                })
            }
        });

        return r;
    }

    private isRouteMatching(path: string, route: string) {
        if (route.includes(':')) {
            // clearing Param Syntax before comparison because path syntax contains variable params
            const colonIndex = route.indexOf(':');
            let end = route.indexOf('/', colonIndex);
            end = end > -1 ? end : route.length;
            if (end > -1) {
                // let substr = route.substring(colonIndex, end);
                route = route.replace(route.substring(colonIndex, end), '');
                path = path.replace(/(\d+)/g, '');
            }
        }
        return route === path || path === '/' + route;
    }

}
