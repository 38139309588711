export class Company {
    public ID: number;
    public Code: string;
    public Name: string;
    public AddressLine1: string;
    public AddressLine2: string;
    public AddressTown: string;
    public AddressPostCode: string;
    public AddressCountry: string;
    public IsPrivate: boolean;
    public IsActive: boolean;
    public SortOrder: number;
    public CreatedBy: string;
    public CreatedOn: Date;
    public ModifiedBy: string;
    public ModifiedOn: Date;
}