import { Component, OnInit, HostListener, Injector, ViewChild, OnDestroy } from '@angular/core';
import {
	WebApiProxyFactoryService,
	UserContextService,
	LayoutService,
	UserSettingsService,
	PermissionManagerService,
	ContractHttpResponse,
} from 'ssi-framework';
import { NavigationEnd, Router } from '@angular/router';
import { LookupStoreService } from 'src/app/Common/services/lookup-store.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HeaderActionComponent } from './header/header-actions/header-actions.component';
import { CommonConstants } from 'src/app/Common/constants/constants';
import { UserAffiliationsService } from 'src/app/core/services/UserAffiliations.service';
import { permission } from 'src/app/core/models/permission.model';
import { CachedUserSettingsService } from 'src/app/core/services/cached-user-settings-service';
import { LoggedInUserInformation } from 'src/app/Common/services/LoggedInUserInformation.service';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
	active: Boolean = false;
	displayHeader: boolean = true;
	public displayLeftMenu: boolean = true;
	subscriptions: Subscription[] = [];
	breadCrumb: string;
	@ViewChild('headerActionComponent', { static: false }) headerActionComponent: HeaderActionComponent;
	loginAffiliation: any;
	isExternalUser = false;
	// isShown:boolean = true;

	constructor(
		private proxyFactory: WebApiProxyFactoryService,
		private ucs: UserContextService,
		private router: Router,
		private layoutService: LayoutService,
		private injector: Injector,
		private UserSettingsService: UserSettingsService,
		private userAffilationServices: UserAffiliationsService,
		private permissionMangerService: PermissionManagerService,
		private cachedUserSettingsService: CachedUserSettingsService,
		private loggedInService: LoggedInUserInformation
	) {}
	ngOnDestroy(): void {
		if (this.subscriptions?.length > 0) {
			this.subscriptions.forEach((sub) => sub.unsubscribe());
		}
	}

	ngOnInit() {
		const lookupsStoreService = this.injector.get<LookupStoreService>(LookupStoreService);
		lookupsStoreService.InitLookups().then((n) => {});
		this.setupTitle();

		this.loginAffiliation = this.loggedInService.GetUserAffiliationFromSettings();
		this.isExternalUser =
			this.loginAffiliation != null && this.loginAffiliation.length > 0
				? this.loginAffiliation[0].AllowExternalUserAccess
				: false;
		const currentRoute = window.location.pathname.replace(window['base-href'], '');
		if (currentRoute.includes('userAffilation')) {
			this.displayHeader = false;
			this.displayLeftMenu = false;
		} else {
			this.displayHeader = true;
			this.displayLeftMenu = true;
		}
	}
	ngAfterViewChecked(): void {
		this.loginAffiliation = this.loggedInService.GetUserAffiliationFromSettings();
		this.isExternalUser =
			this.loginAffiliation != null && this.loginAffiliation.length > 0
				? this.loginAffiliation[0].AllowExternalUserAccess
				: false;
		const currentRoute = window.location.pathname.replace(window['base-href'], '');
		if (currentRoute.includes('userAffilation')) {
			this.displayHeader = false;
			this.displayLeftMenu = false;
		} else {
			this.displayHeader = true;
			this.displayLeftMenu = true;
		}
	}
	setActiveClass(e): void {
		this.active = e;
	}

	@HostListener('window:resize', ['$event'])
	onResized(event: any) {
		if (this.active == true) {
			this.layoutService.menuExpanded();
		} else {
			this.layoutService.menuCollapsed();
		}
	}

	getRoute() {}
	public async setupTitle() {
		this.subscriptions.push(
			this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
				const event_url: string = event['url'];
				const locations = event_url?.split('/');
				this.breadCrumb = locations[locations.length - 1];
				this.displayHeader = true;
				this.displayLeftMenu = true;
				if (event_url == '/userAffilation') {
					this.displayHeader = false;
					this.displayLeftMenu = false;
				} else {
					if (!this.GetUserOrganizationFromSettings()) {
						window.location.href = 'userAffilation';
					}
				}
			})
		);
	}

	protected GetUserOrganizationFromSettings(): boolean {
		//return this.loginAffiliation;
		const loginAffiliation = this.loggedInService.GetUserAffiliationFromSettings();
		if (loginAffiliation == undefined || loginAffiliation == null) {
			return false;
		}
		return true;
	}
}
