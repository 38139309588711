<div class="ssi-formGroup">
  <div class=" btn-holder">
    <ul class="btnlist">
      <li><button igxButton="flat" type="submit" class="btn-primary" (click)="onSave()" ssiTooltip="{{'SAVE_BTN_TTP' | ssiTranslator }}">{{'SAVE_BTN' | ssiTranslator }}</button></li>
      <li><button igxButton="flat" type="submit" (click)="onCancel()" ssiTooltip="{{'CANCEL_BTN_TTP' | ssiTranslator }}">{{'CANCEL_BTN' | ssiTranslator }}</button></li>
    </ul>
  </div>
  <div class="ssi-FourColForm">
    <div class="row">
      <div class="col control-holder">
        <igx-input-group>
          <label igxLabel for="Name">{{'NAME_LBL' | ssiTranslator }}</label>
          <input igxInput [(ngModel)]="model.Name" name="CompanyName" type="text" [required]="true"  autocomplete="none"/>
        </igx-input-group>
      </div>
      <div class="col control-holder">
        <igx-input-group>
          <label igxLabel for="Code">{{'CODE_LBL' | ssiTranslator }}</label>
          <input igxInput [(ngModel)]="model.Code" name="CompanyCode" type="text" [required]="true"  autocomplete="none"/>
        </igx-input-group>
      </div>
      <div class="col control-holder">
        <igx-input-group>
          <label igxLabel for="AddressLine1">{{'ADDRESS_LINE_1_LBL' | ssiTranslator }}</label>
          <input igxInput [(ngModel)]="model.AddressLine1" name="AddressLine1" type="text"  autocomplete="none"/>
        </igx-input-group>
      </div>
      <div class="col control-holder">
        <igx-input-group>
          <label igxLabel for="AddressLine2">{{'ADDRESS_LINE_2_LBL' | ssiTranslator }}</label>
          <input igxInput [(ngModel)]="model.AddressLine2" name="AddressLine2" type="text"  autocomplete="none"/>
        </igx-input-group>
      </div>
      <div class="col control-holder">
        <igx-input-group>
          <label igxLabel for="AddressTown">{{'TOWN_LBL' | ssiTranslator }}</label>
          <input igxInput [(ngModel)]="model.AddressTown" name="AddressTown" type="text"  autocomplete="none"/>
        </igx-input-group>
      </div>
      <div class="col control-holder">
        <igx-input-group>
          <label igxLabel for="AddressPostCode">{{'POST_CODE_LBL' | ssiTranslator }}</label>
          <input igxInput [(ngModel)]="model.AddressPostCode" name="AddressPostCode" type="text"  autocomplete="none"/>
        </igx-input-group>
      </div>
      <div class="col control-holder">
        <igx-input-group>
          <label igxLabel for="AddressCountry">{{'COUNTRY_LBL' | ssiTranslator }}</label>
          <input igxInput [(ngModel)]="model.AddressCountry" name="AddressCountry" type="text"  autocomplete="none"/>
        </igx-input-group>
      </div>
      <div class="col control-holder">
        <div>
          <igx-checkbox [(ngModel)]="model.IsActive">{{'IS_ACTIVE_LBL' | ssiTranslator }}</igx-checkbox>
        </div>
      </div>
      <div class="col control-holder">
        <div>
          <igx-checkbox [(ngModel)]="model.IsPrivate">{{'IS_PRIVATE_LBL' | ssiTranslator }}</igx-checkbox>
        </div>
      </div>

    </div>
  </div>
</div>
