<div class="list-searches">
  <h5>Recent Searches</h5>
  <div class="list-wrap">
    <igx-list>
      <igx-list-item #item *ngFor="let obj of filterObjects" class="">
        <div class="widget-search" (click)="applyFilter(obj.valued, obj.filterDetails)">
          <div class="row mb-1">
            <div class="col">
              <!-- <span class="text-steel mr-2">Searched On</span> -->
              <span class="title">{{getObjectEntryValue(obj, 'searchedOn')}}</span>
            </div>
            <div class="col-auto pl-0">
              <div class="widget-action" ssiTooltip="Search">
                <igx-icon>search</igx-icon>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <table>
                <tr *ngFor="let key of getObjectEntry(obj.named)">
                  <td class="key">{{getObjectEntryValue(obj.labels, key)}}:</td>
                  <td class="val">{{getObjectEntryValue(obj.named, key)}}</td>
                </tr>
              </table>
              <!-- <div class="row" *ngFor="let key of getObjectEntry(obj.named)">
                  <div class="col-auto">{{transformKey(key)}}:</div>
                  <div class="col">{{getObjectEntryValue(obj.named, key)}}</div>
              </div> -->
            </div>
          </div>
        </div>
      </igx-list-item>
    </igx-list>
  </div>
  <div class="list-footer">
    <div class="btn-holder">
      <ul class="btnlist">
        <li><button igxButton type="button" class="btn-primary" (click)="onClearAll($event)" title="Clear All">Clear
            All</button></li>
        <li><button igxButton type="button" class="btn-light" (click)="onClose($event)" title="Close">Close</button></li>
      </ul>
    </div>
  </div>
</div>