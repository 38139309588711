import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PermissionManagerService, UtilityService, CommonMessageKeys, UserContextService, ContractHttpResponse } from 'ssi-framework';
import { NotificationEventService } from '../services/notification-event.service';
import { NotificationEvent } from '../models/notification-event';
import { NotificationSubscription } from '../models/notification-subscription';
import { NotificationEventConfiguration } from '../models/notification-event-configuration';
import { IgxGridComponent, SortingDirection, IChangeCheckboxEventArgs } from 'igniteui-angular';
import { User, UserManagementService } from 'ssi-identity';

@Component({
  selector: 'lib-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss']
})
export class UserNotificationsComponent implements OnInit {

  userName: string;
  notificationEvents: NotificationEvent[];
  originalNotificationEvents: NotificationEvent[];
  allNotificationEventConfiguration: NotificationEventConfiguration[] = [];
  @ViewChild('FWNotifEventGrid', {static: false})
  protected gridRef: IgxGridComponent;

  constructor(
    private route: ActivatedRoute,
    public permissionMangerService: PermissionManagerService,
    private utilityService: UtilityService,
    private commonMessageKeys: CommonMessageKeys,
    private userContextService: UserContextService,
    private notificationEventService: NotificationEventService,
    private userService: UserManagementService,
  ) {

  }

  ngOnInit() {
    this.route.parent.params.subscribe((params: Params) => {
      const idKey = 'id';
      const id = +params[idKey];
      // this.userName = params['userName'];
      if (id) {
        this.notificationEventService.getAll().then((response: ContractHttpResponse<NotificationEvent[]>) => {
          if (response.Success === true) {
            this.notificationEvents = response.Source;
            this.notificationEvents.forEach(e => {
              e.Email = false;
              e.SMS = false;
              e.InApp = false;
              e.Push = false;
              e.IsEmailDisabled = false;
              e.IsSMSDisabled = false;
              e.IsInAppDisabled = false;
              e.IsPushDisabled = false;
            });
            this.setDisabledConfigurations();
            // tslint:disable-next-line: no-shadowed-variable
            this.userService.get(id).then((response: ContractHttpResponse<User>) => {
              if (response.Success === true) {
                this.userName = response.Source.LoginName;
                this.notificationEventService.getSubscriptionsByLogin(this.userName).
                // tslint:disable-next-line: no-shadowed-variable
                then((response: ContractHttpResponse<NotificationSubscription[]>) => {
                  if (response.Success === true) {
                    // Email selection
                    let notifIds = response.Source.filter(o => o.EventConfigMode.toLowerCase() === 'email').map(o => o.EventConfigCode);
                    let filtered = this.notificationEvents.filter(x => notifIds.includes(x.Code));
                    filtered.forEach(f => {
                      f.Email = true;
                    });

                    // SMS selection
                    notifIds = response.Source.filter(o => o.EventConfigMode.toLowerCase() === 'sms').map(o => o.EventConfigCode);
                    filtered = this.notificationEvents.filter(x => notifIds.includes(x.Code));
                    filtered.forEach(f => {
                      f.SMS = true;
                    });

                    // InApp selection
                    notifIds = response.Source.filter(o => o.EventConfigMode.toLowerCase() === 'inapp').map(o => o.EventConfigCode);
                    filtered = this.notificationEvents.filter(x => notifIds.includes(x.Code));
                    filtered.forEach(f => {
                      f.InApp = true;
                    });

                    // Push selection
                    notifIds = response.Source.filter(o => o.EventConfigMode.toLowerCase() === 'push').map(o => o.EventConfigCode);
                    filtered = this.notificationEvents.filter(x => notifIds.includes(x.Code));
                    filtered.forEach(f => {
                      f.Push = true;
                    });
                    this.gridRef.sort({ fieldName: 'Name', dir: SortingDirection.Asc, ignoreCase: true });
                    this.originalNotificationEvents = JSON.parse(JSON.stringify(this.notificationEvents));
                  }
                  this.gridRef.reflow();
                });
              }
            });
          }

        });
      } else {

      }
    });
  }
  onSave() {
    let codes = this.notificationEvents.filter(o => o.Email === true).map(o => o.Code);
    const emailIds = this.allNotificationEventConfiguration.filter(o => codes.includes(o.EventCode) && o.NotificationMode.toLowerCase() === 'email').map(o => o.ID);

    codes = this.notificationEvents.filter(o => o.InApp === true).map(o => o.Code);
    const inAppIds = this.allNotificationEventConfiguration.filter(o => codes.includes(o.EventCode) && o.NotificationMode.toLowerCase() === 'inapp').map(o => o.ID);

    codes = this.notificationEvents.filter(o => o.SMS === true).map(o => o.Code);
    const smsIds = this.allNotificationEventConfiguration.filter(o => codes.includes(o.EventCode) && o.NotificationMode.toLowerCase() === 'sms').map(o => o.ID);

    codes = this.notificationEvents.filter(o => o.Push === true).map(o => o.Code);
    const pushIds = this.allNotificationEventConfiguration.filter(o => codes.includes(o.EventCode) && o.NotificationMode.toLowerCase() === 'push').map(o => o.ID);


    let ids = [];
    ids = ids.concat(emailIds);
    ids = ids.concat(inAppIds);
    ids = ids.concat(smsIds);
    ids = ids.concat(pushIds);

    this.notificationEventService.saveSubscriptionByLogin(this.userName, ids).then((response: ContractHttpResponse<any>) => {
      if (response.Success === true) {
        this.originalNotificationEvents = JSON.parse(JSON.stringify(this.notificationEvents));
        this.utilityService.displaySuccessMessage(this.commonMessageKeys.RECORD_SAVED_SUCCESSFULLY);
      } else {
        this.utilityService.displayErrorMessage(response.Message);
      }

    });

    // let selectionDto = { "ID": this.user.ID, "SelectedIDs": this.roles.filter(x => x.Selected == true).map(o => o.ID) };

    // this.userService.saveRoleAssignments(selectionDto)
    //   .then((response: ContractHttpResponse<any>) => {
    //     if (response.Success == true) {
    //       this.originalRoles = JSON.parse(JSON.stringify(this.roles));
    //       this.utilityService.displaySuccessMessage(this.commonMessageKeys.RECORD_SAVED_SUCCESSFULLY);
    //     }
    //     else {
    //       this.utilityService.displayErrorMessage(response.Message);
    //     }
    //
    //   },
    //     (error) => {
    //       if (error.error) {
    //         this.utilityService.displayErrorMessage(error.error.Message);
    //       } else {
    //         this.utilityService.displayErrorMessage(error.message);
    //       }
    //
    //     }
    //   );
  }

  private setDisabledConfigurations() {
    this.notificationEventService.getAllConfiguration().then((response: ContractHttpResponse<NotificationEventConfiguration[]>) => {
      if (response.Success === true) {
        this.allNotificationEventConfiguration = response.Source;
        this.notificationEvents.forEach(notificationEvent => {
          if (!this.allNotificationEventConfiguration.some(n => notificationEvent.Code === n.EventCode &&
            n.NotificationMode.toLowerCase() === 'email')) {
            notificationEvent.IsEmailDisabled = true;
          }
          if (!this.allNotificationEventConfiguration.some(n => notificationEvent.Code === n.EventCode &&
            n.NotificationMode.toLowerCase() === 'inapp')) {
            notificationEvent.IsInAppDisabled = true;
          }
          if (!this.allNotificationEventConfiguration.some(n => notificationEvent.Code === n.EventCode &&
             n.NotificationMode.toLowerCase() === 'sms')) {
            notificationEvent.IsSMSDisabled = true;
          }
          if (!this.allNotificationEventConfiguration.some(n => notificationEvent.Code === n.EventCode &&
            n.NotificationMode.toLowerCase() === 'push')) {
            notificationEvent.IsPushDisabled = true;
          }
        });
      }
    });
  }

  onCancel() {
    this.notificationEvents = JSON.parse(JSON.stringify(this.originalNotificationEvents));
  }

  onPushSelectionChange(event: IChangeCheckboxEventArgs) {
    this.notificationEvents.forEach(e => {
      e.Push = event.checked;
    });
  }

  onEmailSelectionChange(event: IChangeCheckboxEventArgs) {
    this.notificationEvents.forEach(e => {
      e.Email = event.checked;
    });
  }

  onInAppSelectionChange(event: IChangeCheckboxEventArgs) {
    this.notificationEvents.forEach(e => {
      e.InApp = event.checked;
    });
  }

  onSMSSelectionChange(event: IChangeCheckboxEventArgs) {
    this.notificationEvents.forEach(e => {
      e.SMS = event.checked;
    });
  }

}
