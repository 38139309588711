import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { LogoutService } from 'src/app/shared/services/azure-logout-notify.service';

@Component({
	selector: 'app-logout-azure',
	templateUrl: './logout-azure.component.html',
	styleUrls: ['./logout-azure.component.scss'],
})
export class LogoutAzureComponent implements OnInit {
	constructor(private authService: MsalService, private logoutService: LogoutService) {}

	ngOnInit(): void {
		console.log('In Logout Azure Component.');
		this.logoutService.notifyLogout();
		this.authService.instance.logoutRedirect({
			onRedirectNavigate: () => {
				// Return false to stop navigation after local logout
				return false;
			},
		});
		// this.auth.signOut();
	}
}
