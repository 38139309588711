<igx-dialog #dialog [leftButtonLabel]="leftButtonLabel" [rightButtonLabel]="rightButtonLabel"
  [closeOnOutsideSelect]="closeOnOutsideSelect" igxDrag [ghost]="false" (dragMove)="dragMove($event)">
  <igx-dialog-title igxDragHandle [ngClass]="{ 'draggable': isDraggable }">
    <div class="dialog-container">
      <div class="dialog-title">{{header}}
      </div>
    </div>
    <ng-container *ngIf="showCrossButton">
      <div class="action-close">
        <a href="javascript:;">
          <span class="material-icons" igxDragIgnore (click)="onCrossClicked();">
            close
          </span>
        </a>
      </div>
    </ng-container>
  </igx-dialog-title>
  <div class="content-area">
    <div class="dialog-container" [ngStyle]="{'width.px': width, 'height.px': height}">
      <ng-content></ng-content>
    </div>
  </div>
</igx-dialog>
