import { WebApiProxyFactoryService, IWebApiProxy, UserContextService, PermissionManagerService } from 'ssi-framework';
import { Injectable, EventEmitter } from '@angular/core';
import { BaseApiService } from 'ssi-framework';
import { User } from '../models/user.model';
import { SortingDirection } from 'igniteui-angular';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class UserManagementService extends BaseApiService<User> {
    public onProfilePictureUpdated: EventEmitter<any> = new EventEmitter();
    public onUserUpdated: EventEmitter<any> = new EventEmitter();
    private PROFILE_IMAGE_KEY = 'PROFILE_IMAGE_ID';
    private proxy: IWebApiProxy;
    private companyProxy: IWebApiProxy;
    private teamProxy: IWebApiProxy;
    private roleProxy: IWebApiProxy;
    private fileProxy: IWebApiProxy;
    private userSettingProxy: IWebApiProxy;
    private showAllDataPermission = 'VIEW_ALL_COMPANY_DATA';

    constructor(private proxyFactory: WebApiProxyFactoryService, private userContext: UserContextService,
         private permissionService: PermissionManagerService) {
        super();
        this.proxy = this.proxyFactory.GetProxy('User');
        this.companyProxy = this.proxyFactory.GetProxy('Company');
        this.teamProxy = this.proxyFactory.GetProxy('Team');
        this.roleProxy = this.proxyFactory.GetProxy('Role');
        this.fileProxy = this.proxyFactory.GetProxy('File');
        this.userSettingProxy = this.proxyFactory.GetProxy('UserSetting');
    }

    registerRoutes(route: ActivatedRoute) {
    }

    public get(id: number): Promise<any> {
        const request = this.proxy.Get('Get', {id});
        return request;
    }

    public getPaged(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection | number, filter: any): Promise<any> {
        let companyId = 0;
        if (this.permissionService.checkPermission(this.showAllDataPermission)) {
            companyId = filter.companyId ? filter.companyId : 0
        } else {
            companyId = this.userContext.companyId;
        }

        const request = this.proxy.Get('GetPage', {
            name: filter.name ? filter.name : '',
            login: filter.loginName ? filter.loginName : '',
            companyId,
            searchText: filter.searchText ? filter.searchText : '',
            email: filter.email ? filter.email : '',
            roleIds: filter.roles ? filter.roles : [],
            pageIndex,
            pageSize,
            orderBy: orderBy ? orderBy : 'ID',
            orderDirection: (!orderDir || orderDir === 0) ? 0 : orderDir - 1
        });
        return request;
    }

    public getAll(): Promise<any> {
        if (this.permissionService.checkPermission(this.showAllDataPermission)) {
            const request = this.proxy.Get('GetAll');
            return request;
        } else {
            const request = this.proxy.Get('GetAllByCompany', { companyId: this.userContext.companyId });
            return request;
        }
    }

    public validate(obj: User): boolean {
        return true;
    }

    public saveOrUpdate(obj: User): Promise<any> {
        if (obj) {
            if (!obj.ID || obj.ID === 0) {
                obj.IsActive = true;
                const request = this.proxy.Post('Save', obj);
                return request;
            } else {
                const request = this.proxy.Post('Update', obj);
                return request;
            }
        }
    }

    public saveRoleAssignments(obj: any): Promise<any> {
        const request = this.proxy.Post('SaveRoleAssignments', obj);
        return request;
    }

    public delete(id: number): Promise<any> {
        const request = this.proxy.Post('Delete', [id]);
        return request;
    }

    public activateUser(id: number): Promise<any> {
        const request = this.proxy.Post('Activate', id);
        return request;
    }

    public deactivateUser(id: number): Promise<any> {
        const request = this.proxy.Post('Deactivate', id);
        return request;
    }

    public getCompanies() {
        const request = this.companyProxy.Get('GetAll');
        return request;
    }

    public getTeams() {
        const request = this.teamProxy.Get('GetAll');
        return request;
    }

    public getRoles() {
        const request = this.roleProxy.Get('GetAll');
        return request;
    }

    public getRolesByCompanyCode(appCode: string, companyCode: string) {
        return this.roleProxy.Get('GetByAppAndCompanyCode', {
            applicationCode: appCode,
            companyCode
        });
    }

    public changePassword(LoginName: string, password: string, confirmPassword: string) {
        const request = this.proxy.Post('ChangePassword', { LoginName: LoginName, Password: password, ConfirmPassword: confirmPassword });
        return request;
    }

    public changeMyPassword(userId: number, oldPassword: string, password: string, confirmPassword: string) {
        const request = this.proxy.Post('ChangeMyPassword', { UserID: userId, OldPassword: oldPassword,
             Password: password, ConfirmPassword: confirmPassword });
        return request;
    }

    public uploadProfileImage(fileName: string, extension: string, key: string, content: number[]): Promise<any> {
        const request = this.fileProxy.Post('Upload', { ApplicationCode: this.userContext.appCode,
            DocumentType: 'Image', FileName: fileName, Title: fileName, Key: key, Extension: extension,
             Content: content, CreatedBy: this.userContext.userName, CreatedOn: new Date() });
        return request;

    }

    public downloadProfileImage(imageId: number[]): Promise<any> {
        const request = this.fileProxy.Get('Download', { id: imageId });
        return request;
    }

    public saveImageID(userId: number, id: string): Promise<any> {
        const request = this.userSettingProxy.Post('CreateOrUpdate', { AppCode: this.userContext.appCode, Code: this.PROFILE_IMAGE_KEY,
             Name: 'Profile Image ID', Value: id, UserId: userId, DataType: 'system.Int64' });
        return request;
    }

    public getImageSetting(userId: number): Promise<any> {
        const request = this.userSettingProxy.Get('GetByCode', { code: this.PROFILE_IMAGE_KEY, userId });
        return request;
    }

    public isResetLinkValid(guidId: string): Promise<any> {
        const request = this.proxy.Get('IsResetLinkValid',{linkGuid: guidId});
        return request;
    }

    public changePasswordByLink(guidId: string, password: string, confirmPassword: string): Promise<any> {
        const request = this.proxy.Get('ChangePasswordFromResetLink',{
            linkGuid: guidId, 
            password: password,
            confirmpassword: confirmPassword
        });
        return request;
    }
}
