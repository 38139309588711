<div class="cal-wrapper">
  <div class="cal-inner">
    <div class="row cal-toolbar no-gutters">
      <div class="col text-left">
        <ng-template [ngTemplateOutlet]="leftHeader"></ng-template>
      </div>
      <div class="col-auto align-self-start">
        <cal-date-picker #datePicker [date]="date" [viewType]="viewType" [firstDayOfWeek]="firstDayOfWeek"
          [displayControlButtons]="displayDatePickerControlButtons" [monthDateFormat]="monthDateFormat"
          [dayDateFormat]="dayDateFormat" (dateChanged)="onDateChanged($event)">
        </cal-date-picker>
      </div>
      <div class="col text-lg-right pl-0">
        <ng-template [ngTemplateOutlet]="rightHeader"></ng-template>
      </div>
    </div>

    <div class="cal-view-wrap" *ngIf="displayCalenderView">
      <div class="cal-view">
        <!-- Rendring Day Names -->
        <div class="row no-gutters cal-header" *ngIf="viewType != 'day' && displayWeekDayName">
          <div class="col" *ngFor="let name of daysName; let i = index;"> {{daysName[(firstDayOfWeek+i)%7]}}
          </div>
        </div>

        <!-- Rendering Month View -->
        <lib-cal-month-view *ngIf="viewType == getViewType('month') " [activeDates]="activeDates" [tileData]="tileData"
          [monthTemplate]="monthTemplate" [currentDate]="currentDate"
          [displayTileExtensionButton]="displayTileExtensionButton" [tileCollapseTemplate]="tileCollapseTemplate"
          [tileExpandTemplate]="tileExpandTemplate">
        </lib-cal-month-view>

        <!-- Rendering Week View -->

        <lib-cal-week-view *ngIf="viewType == getViewType('week') " [activeDates]="activeDates[0]" [tileData]="tileData"
          [weekTemplate]="weekTemplate" [displayTileExtensionButton]="displayTileExtensionButton"></lib-cal-week-view>


        <!-- Rendring Day View -->
        <lib-cal-day-view *ngIf="viewType == getViewType('day') " [activeDate]="activeDates[0][0]" [tileData]="tileData"
          [dayTemplate]="dayTemplate" [displayTileExtensionButton]="displayTileExtensionButton"></lib-cal-day-view>
      </div>
    </div>
  </div>
</div>
