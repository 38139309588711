import { Component, OnInit, ViewChild, EventEmitter, Input } from '@angular/core';
import { IgxDialogComponent } from 'igniteui-angular';

@Component({
  selector: 'lib-ssi-message-box',
  templateUrl: './ssi-message-box.component.html',
  styleUrls: ['./ssi-message-box.component.scss']
})
export class SsiMessageBoxComponent implements OnInit {
  @Input() header: string;
  @Input() rightButtonLabel : string;
  @Input() showCrossIcon : boolean = true;

  @ViewChild('dialog', {static: false}) dialog: IgxDialogComponent;

  onClose: EventEmitter<IgxDialogComponent> = new EventEmitter<IgxDialogComponent>();

  message: string;

  ngOnInit(): void {
    this.onClose.subscribe((n: IgxDialogComponent) => {
      n.close();
    });
  }

  public show(message: string) {
    this.message = message;
    this.dialog.open();
  }

  public Close(event) {
    this.dialog.close();
    this.onClose.emit(event.dialog);
  }
}
