import { Directive, ElementRef, EventEmitter, Injectable, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, NgZone } from '@angular/core';

@Injectable()
@Directive({ selector: '[ssiClickOutside]' })
export class SsiOutsideClickDirective implements OnInit, OnChanges, OnDestroy {
  @Input() clickOutsideEnabled = true;
  @Input() excludedClassesFromOutsideClick = [];
  @Output() clickOutside: EventEmitter<Event> = new EventEmitter<Event>();

  private _events: Array<string> = ['click'];

  constructor(private _el: ElementRef, private _ngZone: NgZone) {
    this._initOnClickBody = this._initOnClickBody.bind(this);
    this._onClickBody = this._onClickBody.bind(this);
  }

  ngOnInit() {
    this._initOnClickBody();
  }

  ngOnDestroy() {
    this._removeClickOutsideListener();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.attachOutsideOnClick) {
      this._initOnClickBody();
    }
  }
  private _initOnClickBody() {
    this._initClickOutsideListener();
  }
  private _onClickBody(ev: Event) {
    if (!this.clickOutsideEnabled) { return; }

    let exists = false;
    this.excludedClassesFromOutsideClick?.forEach((className) => {
      const hasClass = this.hasSomeParentTheClass(ev.target as HTMLElement, className);
      if (hasClass) { exists = true; }
    })

    if (!this._el.nativeElement.contains(ev.target) && document.body.contains(ev.target as HTMLElement) && !exists) {
      this._emit(ev);
    }
  }

  private hasSomeParentTheClass(element: HTMLElement, classname: string): boolean | null {
    if (element.className?.split(" ").indexOf(classname) >= 0) return true;
    return element.parentNode && this.hasSomeParentTheClass(<HTMLElement>element.parentNode, classname);
  }

  private _emit(ev: Event) {
    if (!this.clickOutsideEnabled) { return; }
    this._ngZone.run(() => this.clickOutside.emit(ev));
  }

  private _initClickOutsideListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => document.body.addEventListener(e, this._onClickBody));
    });
  }
  private _removeClickOutsideListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => document.body.removeEventListener(e, this._onClickBody));
    });
  }
}
