import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SsiFilterService {

  gridId: string;

  applySearchFilter = new EventEmitter<any>(true);
  closeSearchFilter = new EventEmitter();

  constructor() { }

  setGridId(gridId: string) {
    this.gridId = gridId;
  }

}
