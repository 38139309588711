import { Component, OnInit } from '@angular/core';
import {
	AuthenticateClientService,
	UserContextService,
	ContractHttpResponse,
	UserSettingsService,
	UtilityService,
	PermissionManagerService,
} from 'ssi-framework';
import { NavigationExtras, Router } from '@angular/router';
import { UserManagementService } from 'ssi-identity';
import { CachedUserSettingsService } from 'src/app/core/services/cached-user-settings-service';
import { LoggedInUserInformation } from 'src/app/Common/services/LoggedInUserInformation.service';
import { UserProfilesService } from 'src/app/information/services/user-profiles.service';
import { LocalStorageKeys } from 'src/app/Common/constants/constants';

@Component({
	selector: 'app-header-actions',
	templateUrl: './header-actions.component.html',
	styleUrls: ['./header-actions.component.scss'],
})
export class HeaderActionComponent implements OnInit {
	name: string;
	userId: number;
	profileURL: string;
	candidateId: number;
	showOrientation = false;
	isCandidate = false;
	IsEmployerUser = false;
	notificationCount = 0;
	routeURL = '';
	isSocialUser = false;
	// @ViewChild("customInAppComponent", {static: false}) customInAppComponent: CustomInappNotificationComponent;
	// @ViewChild("helpDialog", {static: false}) helpDialog: HelpDialogComponent;
	// @ViewChild("contactus", {static: false}) contactus: RevenueCenterContactUsComponent;

	constructor(
		private authClient: AuthenticateClientService,
		private userService: UserManagementService,
		private userContext: UserContextService,
		private utilityService: UtilityService,
		// private _jobCandidate: JobCandidateService,
		private userSettingsService: UserSettingsService,
		private permissionManagerService: PermissionManagerService,
		private userProfileService: UserProfilesService,
		private router: Router,
		private loggedInService: LoggedInUserInformation
	) {
		this.name = userContext.fullName;
		this.userId = userContext.userId;
		userService.onUserUpdated.subscribe((response) => {
			if (this.userId == response.userId) {
				this.name = response.fullName;
			}
		});

		userService.onProfilePictureUpdated.subscribe((response) => {
			if (this.userId == response.userId) {
				this.profileURL = 'data:image/JPEG;base64,' + response.image.Content;
				localStorage.setItem('profilePictureUrl', this.profileURL);
			}
		});
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.loadProfileImage();
		}, 10);
		this.setProfileURL();
		this.isSocialUser = localStorage.getItem(LocalStorageKeys.IS_SOCIAL_USER) == 'true' ? true : false;
	}
	loadProfileImage(): void {
		const profilePictureUrl = localStorage.getItem('profilePictureUrl');
		if (!profilePictureUrl) {
			const imageSetting = this.userSettingsService.getUserSettingsByCode('PROFILE_IMAGE_ID');
			if (imageSetting != null || imageSetting != undefined) {
				const imageIds = [];
				imageIds.push(Number(imageSetting.Value));
				this.userService.downloadProfileImage(imageIds).then((response: ContractHttpResponse<any>) => {
					if (response.Success && response.Source != null) {
						const binaryString = response.Source.Content;
						this.profileURL = 'data:image/JPEG;base64,' + binaryString;
						localStorage.setItem('profilePictureUrl', this.profileURL);
					}
				});
			}
		} else {
			this.profileURL = profilePictureUrl;
			localStorage.removeItem('profilePictureUrl');
			localStorage.setItem('profilePictureUrl', this.profileURL);
		}
	}
	async setProfileURL() {
		const userProfileID = await this.userProfileService.getLoggedInUserId();

		this.routeURL = `Information/profile/${userProfileID}/(ssiTabs:Information)`;
		this.userProfileService.setMyProfileURL(this.routeURL);
	}
	checkPermission(permission: string): boolean {
		if (this.permissionManagerService.checkPermission(permission)) {
			return true;
		}
		return false;
	}
	signOut(): void {
		localStorage.removeItem('profilePictureUrl');
		localStorage.removeItem(LocalStorageKeys.IS_SOCIAL_USER);
		localStorage.removeItem(LocalStorageKeys.LAST_LOGIN_REFRESH_TIME);

		this.authClient.signOut();
	}
	redirectToMyProfile() {
		// this.router.navigateByUrl(this.userProfileService.getMyProfileURL(),{skipLocationChange:true});
		this.router
			.navigateByUrl('/Information', { skipLocationChange: true })
			.then(() => this.router.navigateByUrl(this.userProfileService.getMyProfileURL()));
	}
	onLinkSelected(): void {
		this.showOrientation = true;
	}

	// onProfileLinkSelected(path: string): void {
	// 	const navigationExtras: NavigationExtras = {
	// 		queryParams: {
	// 			userName: this.userContext.userName,
	// 			userId: this.userContext.userId,
	// 		},
	// 	};

	// 	this.router.navigate(
	// 		['Information/UserProfiles/Edit/', this.userContext.userId, { outlets: { ssiTabs: [path] } }],
	// 		navigationExtras
	// 	);
	// }

	ViewProfile(): void {
		this.router.navigateByUrl('Information/profile');
	}

	ShowConfrimAction(): boolean {
		return false;
	}

	ShowInAppViewDetail(): boolean {
		return false;
	}

	ConfirmAction(): void {
		return;
	}

	InAppViewDetail(): void {
		return;
	}

	ShowContactUs(): void {
		return;
	}
}
