import { EventEmitter, Directive, ElementRef, Input, ViewContainerRef, ComponentFactory, ComponentFactoryResolver, Output } from '@angular/core';
import { SSIContextMenuComponent } from '../Controls/ssi-context-menu/ssi-context-menu.component';


@Directive({
  selector: '[SSIContextMenuDirective]',
  host: {
    '(contextmenu)': 'rightClick()'
  }
})
export class SSIContextMenuDirective {
  @Input() menuItems;

  @Output() selected = new EventEmitter();

  constructor(private target: ElementRef, private container: ViewContainerRef, private componentResolver: ComponentFactoryResolver) {
  }

  public rightClick() {
    const factory: ComponentFactory<SSIContextMenuComponent> = this.componentResolver.resolveComponentFactory(SSIContextMenuComponent);
    const contextMenuRef = this.container.createComponent(factory);
    contextMenuRef.instance.Selected.subscribe((selectedValue) => {
      this.selected.emit({ targetElement: this.target, selectedItem: selectedValue });
    });

    contextMenuRef.instance.items = this.menuItems;

    contextMenuRef.instance.openDropDown(this.target);

    return false;
  }
}
