import { AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, 
  OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbsoluteScrollStrategy, ConnectedPositioningStrategy, HorizontalAlignment, OverlaySettings, PositionSettings, VerticalAlignment }
  from '@infragistics/igniteui-angular';
import { IgxDropDownComponent } from '@infragistics/igniteui-angular';
import { IgxInputDirective } from '@infragistics/igniteui-angular';
import { IgxInputGroupComponent } from 'igniteui-angular';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ContractHttpResponse } from '../../../WebApiClient/HttpHandler/contract-http-response';
import { WebApiProxyFactoryService } from '../../../WebApiClient/web-api-proxy-factory.service';

@Component({
  selector: 'lib-ssi-dropdown-remote',
  templateUrl: './ssi-dropdown-remote.component.html',
  styleUrls: ['./ssi-dropdown-remote.component.scss']
})
export class SsiDropdownRemoteComponent implements OnInit, AfterViewInit {

  @Input() label: string;
  @Input() placeHolder = '';
  @Input() name: string;
  @Input() items: any[];
  @Input() itemKey: string;
  @Input() itemText: string;
  @Input() itemTexts: string[];
  @Input() required: boolean;
  @Input() readonly = false;
  @Input() showClearButton = true;
  @Input() focusOnSelect = false;
  @Input() disabled = false;
  @Input() index: number;
  @Input() appCode = '';
  @Input() controllerName = '';
  @Input() actionName = '';
  @Input() requestType = 'GET';
  @Input() params: any;

  @Output() itemSelected: EventEmitter<any> = new EventEmitter();

  @ViewChild('ssidropDown', { static: true })
  public dropDown: IgxDropDownComponent;
  @ViewChild('dropDownInput', { static: true })
  public dropDownInput: IgxInputDirective;
  @ViewChild('dropDownInputGroup', { static: true })
  public dropDownInputGroup: IgxInputGroupComponent;

  @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>;

  selectedText = '';
  searchText = '';
  focused = false;
  preventClosing = false;
  showLoader = false;

  private _dropdownFocused = false;
  private inputRemoteDelaySub = new Subject<string>();

  private _positionSettings: PositionSettings = {
    horizontalStartPoint: HorizontalAlignment.Left,
    verticalStartPoint: VerticalAlignment.Bottom,
    horizontalDirection: HorizontalAlignment.Right,
    verticalDirection: VerticalAlignment.Bottom
  };

  private _overlaySettings: OverlaySettings = {
    closeOnOutsideClick: true,
    modal: false,
    positionStrategy: new ConnectedPositioningStrategy(this._positionSettings),
    scrollStrategy: new AbsoluteScrollStrategy()
  };

  private modelValue: any;
  @Output() modelChange = new EventEmitter();
  @Input() get model() {
    return this.modelValue;
  }
  set model(val) {
    if (val === undefined) {
      this.selectedText = null;
    }

    this.modelValue = val;
    this.modelChange.emit(this.modelValue);
  }

  constructor(public element: ElementRef,
    private proxyFactory: WebApiProxyFactoryService) {
  }

  ngAfterViewInit(): void {
    // set width of dropdown equal to the width of input group
    this.dropDown.width = this.dropDownInputGroup.element.nativeElement.offsetWidth + 'px';

    // set hieght of dropdown
    this.dropDown.maxHeight = '300px';
  }

  ngOnInit(): void {
    if (this.appCode && this.appCode.length && this.controllerName && this.controllerName.length
      && this.actionName && this.actionName.length) {
      // Register remote delay Subject event
      this.getRemoteItems();

      this.inputRemoteDelaySub.next(this.selectedText);
    }

     // dropdown openning event
     this.dropDown.onOpening.subscribe(n => {
      // set width of dropdown equal to the width of input group
      this.dropDown.width = this.dropDownInputGroup.element.nativeElement.offsetWidth + 'px';
    });

    // dropdown closing event
    this.dropDown.onClosing.subscribe(n => {
      // check if closing source have "ssi-dropdown-reset", then cancel the closing event
      if (n.event != null && n.event.srcElement.className.indexOf('ssi-dropdown-reset') !== -1) {
        n.cancel = true;
      }

      if (this.preventClosing) {
        this.preventClosing = false;
        n.cancel = true;
      }

      if (this._dropdownFocused) {
        this._dropdownFocused = false;
        n.cancel = true;
      }
    });


  }

  get value() {
    return this.modelValue;
  }

  set value(val) {
    this.model = val;
  }

  public get selectedIndex(): number {
    this.items.forEach((value: any, index: number, array: any[]) => {
      if (value[this.itemKey] === this.modelValue) {
        return index;
      }
    });
    return -1;
  }

  public set selectedIndex(index: number) {
    if (index === -1) {
      this.model = null;
      this.selectedText = null;
    } else {
      if (this.items != null && index <= (this.items.length - 1)) {
        this.model = this.items[index][this.itemKey];
      }
    }
  }

  onSelection(event: any) {
    this._dropdownFocused = false;
    this.searchText = '';
    this.model = event.newSelection.value[this.itemKey];

    setTimeout(() => {
    this.itemSelected.emit(event);
    }, 100);

    if (this.model != null) {
      setTimeout(() => {
        const item = this.getItemByValue(this.modelValue);
        if (item != null && item.length) {
          this.selectedText = this.getText(item[0]);
        }
      }, 100);
    }
  }

  isSelected(item): boolean {
    if (item[this.itemKey] === this.model) {
      return true;
    }
    return false;
  }

  handleKeys($event: any) {
    this.preventClosing = true;

    if (this.dropDown.focusedItem) {
      this.dropDown.focusedItem = null;
    }

    if (this.selectedText) {
      this.selectedText += ' ';
    } else {
      this.selectedText = ' ';
    }
  }

  onKeydown($event: any) {
    this.searchText = this.selectedText;
    if (this.appCode.length && this.controllerName.length && this.actionName.length) {
      // get filtered data from remote api
      this.inputRemoteDelaySub.next(this.selectedText);
    } else {
      // open dropdown if dropdown is closed
      if (this.dropDown.collapsed) {
        this.setOverlaySettingAccordingToWindow(this.dropDownInputGroup.element.nativeElement);
        this.dropDown.open(this._overlaySettings);
      }
    }

    // clear selection if all text from field is removed
    if (this.selectedText.length === 0) {
      this.clearSelection();
    }
  }

  onDownArrowKeydown($event) {
    if (this.dropDown.collapsed) {
      this.setOverlaySettingAccordingToWindow(this.dropDownInputGroup.element.nativeElement);
      this.dropDown.open(this._overlaySettings);
    }
  }

  onTextChange($event) {
    this.searchText = '';
    if ($event.srcElement.value.length === 0) {
      this.onClear($event);
    } else {
      const item = this.getItemByValue(this.modelValue);
      if (item != null && item.length) {
        this.selectedText = this.getText(item[0]);
      } else {
        if (this.appCode.length && this.controllerName.length && this.actionName.length) {
          // get filtered data from remote api
          this.inputRemoteDelaySub.next(this.selectedText);
        }
        else {
          this.onClear($event);
        }
      }
    }
  }

  onClear($event) {
    this.clearSelection();
  }

  getRemoteItems() {
    const searchKey = 'searchText';
    // wait for 1 seconds before remote api is called
    this.inputRemoteDelaySub.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((filterValue: string) => {
      this.showLoader = true;
      const proxy = this.proxyFactory.GetProxyByApp(this.appCode, this.controllerName);
      const obj = this.params != null ? this.params : {};
      obj[searchKey] = (filterValue != null && filterValue.length) ? filterValue : '';

      let response: Promise<any>;
      if (this.requestType.toLowerCase() === 'get') {
        response = proxy.Get(this.actionName, obj, true);
      } else if (this.requestType.toLowerCase() === 'post') {
        response = proxy.Post(this.actionName, obj, true);
      } else {
        response = proxy.Get(this.actionName, obj, true);
      }
      response.then((n: ContractHttpResponse<any[]>) => {
        this.items = n.Source;
        this.showLoader = false;
      });
    });
  }

  clearSelection() {
    this.searchText = '';
    this.selectedText ='';
    this.model = null;
    if (this.appCode.length && this.controllerName.length && this.actionName.length) {
      // get filtered data from remote api
      this.inputRemoteDelaySub.next(this.selectedText);
    }
    this.itemSelected.emit(null);
  }

  @HostListener('focus')
  focusHandler() {
    this.dropDownInput.nativeElement.focus();
  }

  focusedInput() {
    this.focused = true;
    this._dropdownFocused = true;
    if (this.dropDown.collapsed) {
      this.setOverlaySettingAccordingToWindow(this.dropDownInputGroup.element.nativeElement);
      this.dropDown.open(this._overlaySettings);
    }
  }

  focusedoutInput() {
    this.focused = false;
    this._dropdownFocused = false;

    if (!this.dropDown.collapsed) {
      this.dropDown.close();
    }
  }

  clickedInput() {
    if (this.dropDown.collapsed) {
      this.setOverlaySettingAccordingToWindow(this.dropDownInputGroup.element.nativeElement);
      this.dropDown.open(this._overlaySettings);
    }
  }

  getText(item: any) {
    if (this.itemTexts && this.itemTexts.length) {
      const text = [];
      this.itemTexts.forEach(n => {
        text.push(item[n]);
      });
      return text.join(' - ');
    } else {
      return item[this.itemText];
    }
  }

  private getItemByValue(value) {
    return this.items.filter(n => {
      return n[this.itemKey] === value;
    });
  }

  private setOverlaySettingAccordingToWindow(nativeElement: HTMLElement) {
    this._overlaySettings.positionStrategy.settings.target = nativeElement;
    if ((window.innerHeight - nativeElement.getBoundingClientRect().top) < (this.getItemsHeight() + 40)) {
      if (nativeElement.getBoundingClientRect().top > this.getItemsHeight()) {
        this._overlaySettings.positionStrategy.settings.verticalStartPoint = VerticalAlignment.Top;
        this._overlaySettings.positionStrategy.settings.verticalDirection = VerticalAlignment.Top;

        this.dropDown.maxHeight = '300px';
      } else {
        this._overlaySettings.positionStrategy.settings.verticalStartPoint = VerticalAlignment.Bottom;
        this._overlaySettings.positionStrategy.settings.verticalDirection = VerticalAlignment.Bottom;

        this.dropDown.maxHeight = (window.innerHeight - nativeElement.getBoundingClientRect().top - 20) + 'px';
      }
    } else {
      this._overlaySettings.positionStrategy.settings.verticalStartPoint = VerticalAlignment.Bottom;
      this._overlaySettings.positionStrategy.settings.verticalDirection = VerticalAlignment.Bottom;

      this.dropDown.maxHeight = '300px';
    }
  }

  private getItemsHeight(): number {
    const height = this.items != null && this.items !== undefined ? (300 / 10) * (this.items.length > 10 ? 10 : this.items.length) : 0;
    return height;
  }

}
