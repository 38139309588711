import { Pipe, PipeTransform, Inject } from '@angular/core';
import * as moment from 'moment';
import { UtilityService } from '../../Common/utility.service';

@Pipe({
  name: 'ssiDate'
})
export class SsiDatePipe implements PipeTransform {

  clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  dateFormat = 'MM/DD/YYYY';

  constructor(@Inject('EnvironmentVariables') private _environment: any, private _utilityService:UtilityService) {
  }

  transform(value: any, dateFormatName?: any, isUtcTime?: any): any {
    if (!value) {
      return '';
    }

    if (dateFormatName) {
      this.dateFormat = this._environment.DateFormats[dateFormatName];
    }

    if (isUtcTime) {
      value = this._utilityService.getLocalDateFromUTC(value);
    }

    return value && moment(value).isValid() ? moment(value).format(this.dateFormat) : value;
  }

}
