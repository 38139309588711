import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IgxExcelExporterOptions, IgxExcelExporterService } from '@infragistics/igniteui-angular';

@Component({
  selector: 'lib-grid-exporter',
  templateUrl: './grid-exporter.component.html',
  styleUrls: ['./grid-exporter.component.scss']
})
export class GridExporterComponent implements OnInit {
  @ViewChild('gridExporter', { read: TemplateRef, static: false })
  public gridExporter: TemplateRef<any>;
  public grid: any;

  constructor(private exportService: IgxExcelExporterService) { }

  ngOnInit(): void {
  }

  public exportButtonHandler() {
    this.exportService.export(this.grid, new IgxExcelExporterOptions(this.grid.id));
  }

}
