<div  class="img-container-wrap" [style.height]="styleHeight" [style.backgroundColor]="config.containerBackgroundColor" (wheel)="scrollZoom($event)">
    <div id="imgContainer" class="img-container">
        <img [src]="src" [ngStyle]="style" alt="Image not found..." 
        (load)="onLoad(src)" (error)="imageNotFound(src)" (loadstart)="onLoadStart(src)" />
        <!-- Div below will be used to hide the 'ghost' image when dragging -->
        <div></div>
        <div class="spinner-container" *ngIf="loading">
            <div class="spinner"></div>
        </div>

        <!-- Button Container -->
        <div class="btn-container" [class]="config.btnContainerClass">
            <!-- Zoom Out -->
            <ng-container *ngIf="config.btnShow.zoomOut">
                <!-- <button type="button" [class]="config.btnClass"
                    (click)="zoomOut()" *ngIf="config.btnIcons.zoomOut.classes" >
                    <span [class]="config.btnIcons.zoomOut.classes"></span>
                </button> -->
                <a [class]="config.btnClass" *ngIf="config.btnIcons.zoomOut.text" (click)="zoomOut()">
                    <span [class]="config.btnSubClass">{{config.btnIcons.zoomOut.text}}</span>
                </a>
            </ng-container>

            <!-- Zoom In -->
            <ng-container *ngIf="config.btnShow.zoomIn">
                <!-- <button type="button" [class]="config.btnClass"
                    (click)="zoomIn()" *ngIf="config.btnIcons.zoomIn.classes" >
                    <span [class]="config.btnIcons.zoomIn.classes"></span>
                </button> -->
                <a [class]="config.btnClass" *ngIf="config.btnIcons.zoomIn.text" (click)="zoomIn()">
                    <span [class]="config.btnSubClass">{{config.btnIcons.zoomIn.text}}</span>
                </a>
            </ng-container>

            <!-- Fullscreen -->
            <ng-container *ngIf="config.allowFullscreen">
                <!-- <button type="button" [class]="config.btnClass"
                    (click)="toggleFullscreen()" *ngIf="config.btnIcons.fullscreen.classes" >
                    <span [class]="config.btnIcons.fullscreen.classes"></span>
                </button> -->
                <a [class]="config.btnClass" *ngIf="config.btnIcons.fullscreen.text" (click)="toggleFullscreen()">
                    <span [class]="config.btnSubClass">{{config.btnIcons.fullscreen.text}}</span>
                </a>
            </ng-container>

            <!-- Reset -->
            <ng-container *ngIf="config.btnShow.reset">
                <!-- <button type="button" [class]="config.btnClass"
                    (click)="reset()" *ngIf="config.btnIcons.reset.classes">
                    <span [class]="config.btnIcons.reset.classes"></span>
                </button> -->
                <a [class]="config.btnClass" (click)="reset()">
                    <span [class]="config.btnIcons.reset.classes"></span>
                </a>
            </ng-container>
        </div>
    </div>
</div>