<div class="main-content-holder row grid-notifications grid-row-selection">
    <div class="col p0">
        <igx-grid #FWNotificationTemplateLvGrid id="FWNotificationTemplateLvGrid" ssiGrid [enableDefault]="true"
        [actionItems]="items" [enablePaging]="false">

      
            <igx-column field="FileName" header="{{'SUBJECT_LBL' | ssiTranslator }}">
            </igx-column>

            <igx-column></igx-column>
            <ng-template igxToolbarCustomContent let-igxGrid="grid"> 
                <div class="action-area">
                    <ul class="btn-action-list">
                        <li *ssiPermission="'ADD_NOTIFICATION_TEMPLATE'">
                            <a [routerLink]="['Add']">
                                <button ssiTooltip="{{'ADD_BUTTON_TOOLTIP' | ssiTranslator }}" igxButton
                                    class="btn-primary">
                                    <span class="icon icon-add"></span>{{ 'ADD_BTN' | ssiTranslator }}
                                </button>
                            </a>
                        </li>
                    </ul>
                </div>
            </ng-template>
        </igx-grid>
    </div>
</div>