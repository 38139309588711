import { IgxGridComponent, IgxComboComponent, IgxColumnComponent, IgxTimePickerComponent, IgxDatePickerComponent } from 'igniteui-angular';
import { IgxInputDirective } from 'igniteui-angular';
import { EventEmitter, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ViewChildren, OnInit, Directive } from '@angular/core';
import type { QueryList } from '@angular/core';
import { PagedList } from '../../Infrastructure/Models/paged.model';
import { BasicGridPagerComponent } from '../../WebControls/Controls/grid/basic-grid-pager/basic-grid-pager.component';
import { UserSettingsService } from '../Services/user-settings.service';
import { GridSettings, ColumnSetting } from '../../Infrastructure/Models/grid-settings.model';
import { UtilityService } from '../../Common/utility.service';
import { CommonMessageKeys } from '../../Common/common-message-keys';
import { SsiDropdownComponent } from '../../WebControls/Controls/ssi-dropdown/ssi-dropdown.component';
import { SsiFilterComponent } from '../../WebControls/Controls/ssi-filter/ssi-filter.component';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ContractHttpResponse } from '../../WebApiClient/HttpHandler/contract-http-response';
import { SsiSelectComponent } from '../../WebControls/Controls/ssi-select/ssi-select.component';

@Directive()
export abstract class BaseLVLocalComponent<T> implements OnInit {
  @ViewChildren(SsiFilterComponent) filterComponents: QueryList<SsiFilterComponent>;
  @ViewChildren(IgxInputDirective) inputs: QueryList<IgxInputDirective>;
  @ViewChildren(IgxComboComponent) combos: QueryList<IgxComboComponent>;
  @ViewChildren(SsiSelectComponent) ssiSelects: QueryList<SsiSelectComponent>;
  @ViewChildren(SsiDropdownComponent) dropdowns: QueryList<SsiDropdownComponent>;
  @ViewChildren(IgxTimePickerComponent) timePickers: QueryList<IgxTimePickerComponent>;
  @ViewChildren(IgxDatePickerComponent) datePickers: QueryList<IgxDatePickerComponent>;

  private pagerComponent: BasicGridPagerComponent;
  private originalData: T[];
  private sortedData: T[];
  protected data: PagedList<T>;

  private paginateGridCallback = new EventEmitter();

  private userSettingsApplied = false;
  private filterObj: any = {};
  public filters: any = [];
  private reload = false;

  loaded: Promise<any>;
  protected abstract onBeforeLoad(): Promise<any>;
  protected abstract onAfterLoad(data: PagedList<T>);
  protected abstract get gridRef(): IgxGridComponent;
  protected abstract getData(): Promise<any>;
  protected abstract filterData(data: T[], filter: any): T[];


  protected constructor(protected viewRef: ViewContainerRef,
    private componentResolver: ComponentFactoryResolver,
    private userSettingsService: UserSettingsService,
    protected utilityService: UtilityService,
    protected commonMessageKeys: CommonMessageKeys,
    protected router: Router, protected route: ActivatedRoute) {

    this.router.events.subscribe(event => {
      const path = '/' + this.router.url;

      if (event instanceof NavigationEnd && event.url === path && this.route.routeConfig.data && this.route.routeConfig.data.persistState) {
        if (this.reload === true) {
          this.setTitle();
          this.reloadData();
        }
        this.reload = true;
      }
    });
  }

  
  ngOnInit(): void {
    this.loaded = this.onBeforeLoad();
    if (this.loaded) {
      this.loaded.then(n => {

        setTimeout(() => {
          this.setTitle();
          this.paginateGridCallback.subscribe(pageEvent => {
            this.gridRef.perPage = pageEvent.perPage;

            this.saveGridSettings(pageEvent.perPage);
            this.loadData(pageEvent.page, pageEvent.perPage);
          });

          this.gridRef.onSortingDone.subscribe(sortEvent => {
            this.loadData(this.gridRef.page, this.gridRef.perPage, sortEvent);
          });

          this.loadData(this.gridRef.page, this.gridRef.perPage);
        }, 100);

      }, error => {

      });
    } else {
      this.loadData(this.gridRef.page, this.gridRef.perPage);
    }
  }
  public setTitle() {

  }

  public reloadData() {
    this.originalData = null;
    this.sortedData = null;
    this.filterObj = this.getFilterValues();
    this.loadData(this.pagerComponent ? this.pagerComponent._selectedPage : 1, this.gridRef.perPage);
  }

  public refreshGridData() {
    this.originalData = null;
    this.sortedData = null;

    this.loadData(this.pagerComponent ? this.pagerComponent._selectedPage : 1, this.gridRef.perPage);
  }

  public onApplyFilter(removedFilter?: any) {
    // filter passed from Filter Tags
    if (removedFilter) {
      this.userSettingsApplied = true;
      this.clearRemovedFilter(removedFilter);
      this.setFilterValues(this.filterObj, false);
    } else {
      this.filterObj = this.getFilterValues();
    }
    this.loadData(this.gridRef.page, this.gridRef.perPage);
  }

  public onSaveAndApplyFilter(removedFilter?: any) {
    // filter passed from Filter Tags
    if (removedFilter) {
      this.clearRemovedFilter(removedFilter);
      this.setFilterValues(this.filterObj,false);
    } else {
      this.filterObj = this.getFilterValues();
    }
    this.loadData(this.gridRef.page, this.gridRef.perPage);

    const filterSettings = {
      filterValues: this.filterObj,
      filterDetails: this.filters
    };
    this.userSettingsService.saveFilterSettings(this.gridRef.id, filterSettings);
  }

  clearRemovedFilter(removedFilter: any) {
    const value = this.filterObj[removedFilter.fieldName];
    if (Array.isArray(value)) {
      this.filterObj[removedFilter.fieldName] = value.filter(n => n !== removedFilter.value);
    } else {
      this.filterObj[removedFilter.fieldName] = null;
    }

    this.filters = this.filters.filter(x => x.fieldName != removedFilter.fieldName || x.value != removedFilter.value);
  }

  public onResetFilter() {
    this.filterObj = {};
    this.filters = [];
    this.userSettingsApplied = true;
    this.resetFilterValues();
    this.loadData(this.gridRef.page, this.gridRef.perPage);
    if (this.filterComponents && this.filterComponents.first) {
      this.filterComponents.first.filterObject = [];
    }
  }

  protected setFilterValues(filterObj: any, clearFilterTags: boolean = true,ssiSelectDummyItem = null) {
    if (clearFilterTags) {
      this.filters = [];
    }

    const key = 'name'
    if (filterObj != null) {
      this.inputs.forEach(n => {
        if (this.checkIfControlIsFilter(n.nativeElement)) {
          this.setIgxInputValue(n, filterObj[n.nativeElement.attributes[key].nodeValue]);

          if (filterObj[n.nativeElement.attributes[key].nodeValue] != null) {
            // this.filters.push({
            //   fieldName: n.nativeElement.attributes[key].nodeValue,
            //   key: filterObj[n.nativeElement.attributes[key].nodeValue],
            //   value: filterObj[n.nativeElement.attributes[key].nodeValue]
            // });
          }
        }
      });

      this.combos.forEach(n => {
        if (this.checkIfControlIsFilter(n.comboInput.nativeElement)) {
          const name = this.GetNameByNode(n.comboInput.nativeElement, 'igx-combo');
          if (name) {
            this.setIgxComboValue(n, filterObj[name], name);
          }
        }
      });

      this.ssiSelects.forEach(n => {
        if (this.checkIfControlIsFilter(n.ssiCombo.comboInput.nativeElement)) {
          const name = this.GetNameByNode(n.ssiCombo.comboInput.nativeElement, 'igx-combo');
          if (name) {
            let dummyItems = null;
            if (ssiSelectDummyItem && ssiSelectDummyItem.field === name) {
              dummyItems = ssiSelectDummyItem.items;
            }
            this.setSsiSelectValue(n, filterObj, name, dummyItems);
          }
        }
      });


      this.dropdowns.forEach(n => {
        if (this.checkIfControlIsFilter(n.element.nativeElement)) {
          if (n.items.filter(m => m[n.itemKey] === filterObj[n.name]).length) {
            n.value = filterObj[n.name];
            // Tag filters
            // if (n.value != null) {
            //   const item = n.items.filter(m => m[n.itemKey] === n.value);
            //   if (item && item[0][n.itemText]) {
            //     this.filters.push({
            //       fieldName: n.name,
            //       key: item[0][n.itemText],
            //       value: filterObj[n.name]
            //     });
            //   }
            // }
          } else {
            n.value = null;
          }
        }
      });

      this.timePickers.forEach(picker => {
        // Todo: check if it is part of filter and bring picker from id to name for consistency
        const value = filterObj[picker.id];
        // Tag filters
        if (value != null) {
          // this.filters.push({
          //   fieldName: picker.id,
          //   key: value,
          //   value
          // });
          this.setDateTimePickerValue(picker, value);
        } else {
          picker.value = null;
        }
      });

      this.datePickers.forEach(picker => {
        // Todo: check if it is part of filter and bring picker from id to name for consistency
        const value = filterObj[picker.id];
        // Tag filters
        if (value != null) {
          // this.filters.push({
          //   fieldName: picker.id,
          //   key: value,
          //   value
          // });
          this.setDatePickerValue(picker, value);
        } else {
          picker.value = null;
        }
      });

      if (this.filterComponents && this.filterComponents.first) {
        this.filterComponents.first.filterObject = this.filters;
      }
    }
  }

  protected resetFilterValues() {
    this.inputs.forEach(n => {
      if (this.checkIfControlIsFilter(n.nativeElement)) {
        this.setIgxInputValue(n, '');
      }
    });

    this.combos.forEach(n => {
      if (this.checkIfControlIsFilter(n.comboInput.nativeElement)) {
        this.setIgxComboValue(n, []);
      }
    });

    this.ssiSelects.forEach(n => {
      if (this.checkIfControlIsFilter(n.ssiCombo.comboInput.nativeElement)) {
        const name = this.GetNameByNode(n.ssiCombo.comboInput.nativeElement, 'igx-combo');
        this.setSsiSelectValue(n, [], name);
      }
    });

    this.dropdowns.forEach(n => {
      if (this.checkIfControlIsFilter(n.element.nativeElement)) {
        n.selectedIndex = -1;
      }
    });

    this.timePickers.forEach(picker => {
      picker.value = null;
    });

    this.datePickers.forEach(picker => {
      picker.value = null;
    });
  }

  protected getFilterValues(): any {
    const filter = {};
    this.filters = [];
    const key = 'name';
    const searchKey = 'searchText';
    this.inputs.forEach(n => {
      if (this.checkIfControlIsFilter(n.nativeElement)) {
        if (n.value) {
          filter[n.nativeElement.attributes[key].nodeValue] = n.value;
          if (n.value) {
            this.filters.push({
              fieldName: n.nativeElement.attributes[key].nodeValue,
              key: n.value,
              value: n.value
            });
          }
        }
      }
    });

    this.combos.forEach(n => {
      if (this.checkIfControlIsFilter(n.comboInput.nativeElement)) {
        const name = this.GetNameByNode(n.comboInput.nativeElement, 'igx-combo');
        if (name) {
          const itemValues = n.selectedItems();
          const items = n.data.filter(x => itemValues.some(y => y === x[n.valueKey]));
          if (items) {
            const itemIds = items.map(value => {

              if (value[n.valueKey]) {
                this.filters.push({
                  fieldName: name,
                  key: value[n.displayKey],
                  value: value[n.valueKey]
                });
              }
              return value[n.valueKey];
            });
            if (itemIds) {
              filter[name] = itemIds.length ? itemIds : [];
            }
          }
        }
      }
    });

    this.ssiSelects.forEach(n => {
      if (this.checkIfControlIsFilter(n.ssiCombo.comboInput.nativeElement)) {
        const name = this.GetNameByNode(n.ssiCombo.comboInput.nativeElement, 'igx-combo');
        if (name) {
          let items = [];
          if (n.initialRemoteItems == undefined || n.initialRemoteItems == null) {
            n.initialRemoteItems = [];
          }
          if (!n.singleSelect && n.hasRemoteData) {
            const multiSelectValues = n.ssiCombo.selectedItems();
            items = n.allItems.filter(x => multiSelectValues.some(y => y === x[n.ssiCombo.valueKey]));
            if (multiSelectValues.length !== items.length) {
              const remainingItemValues = multiSelectValues.filter(x => !items.some(y => y[n.ssiCombo.valueKey] === x));
              items.push(...n.initialRemoteItems.filter(x => remainingItemValues.some(y => y === x[n.ssiCombo.valueKey])));
            }
          } else {
            const itemValues = n.ssiCombo.selectedItems();
            if (n.hasRemoteData) {
              items = n.allItems.filter(x => itemValues.some(y => y === x[n.ssiCombo.valueKey]));
              if (itemValues.length !== items.length) {
                const remainingItemValues = itemValues.filter(x => !items.some(y => y[n.ssiCombo.valueKey] === x));
                items.push(...n.initialRemoteItems.filter(x => remainingItemValues.some(y => y === x[n.ssiCombo.valueKey])));
              }
            } else {
              items = n.ssiCombo.data.filter(x => itemValues.some(y => y === x[n.ssiCombo.valueKey]));
            }
          }
          if (items) {
            const itemIds = items.map(value => {

              if (value[n.ssiCombo.valueKey]) {
                this.filters.push({
                  fieldName: name,
                  key: value[n.ssiCombo.displayKey],
                  value: value[n.ssiCombo.valueKey]
                });
              }
              return value[n.ssiCombo.valueKey];
            });
            if (itemIds) {
              if (n.singleSelect) {
                filter[name] = itemIds.length ? itemIds[0] : null;
              } else {
                filter[name] = itemIds.length ? itemIds : null;
              }
            }
          }
        }
      }
    });

    this.timePickers.forEach(picker => {
      // Todo: check if it is part of filter and bring picker from id to name for consistency
      if (picker.displayValue) {
        filter[picker.id] = picker.displayValue;

        this.filters.push({
          fieldName: picker.id,
          key: picker.displayValue,
          value: picker.displayValue
        });
      }
    });

    this.datePickers.forEach(picker => {
      // Todo: check if it is part of filter and bring picker from id to name for consistency
      if (picker.displayData) {
        filter[picker.id] = picker.displayData;

        this.filters.push({
          fieldName: picker.id,
          key: picker.displayData,
          value: picker.displayData
        });
      }

    });

    this.dropdowns.forEach(n => {
      if (this.checkIfControlIsFilter(n.element.nativeElement)) {
        filter[n.name] = n.value;
        if (n.value > -1 && n.selectedText) {
          this.filters.push({
            fieldName: n.name,
            key: n.selectedText,
            value: n.value
          });
        }

      }
    });

    if (this.filterComponents.length && this.filterComponents.first.searchText) {
      filter[searchKey] = this.filterComponents.first.searchText;
      this.filters.push({
        fieldName: 'searchText',
        key: this.filterComponents.first.searchText,
        value: this.filterComponents.first.searchText
      });
    } else {
      filter[searchKey] = null;
    }

    if (this.filterComponents && this.filterComponents.first) {
      this.filterComponents.first.filterObject = this.filters;
    }

    return filter;
  }

  private loadData(pageIndex: number, pageSize: number, sortEvent = null) {
    this.applySavedFilter();
    if (this.originalData == null) {
      this.getData().then((response: ContractHttpResponse<T[]>) => {
        if (response.Success) {
          this.originalData = JSON.parse(JSON.stringify(response.Source));
          this.sortedData = JSON.parse(JSON.stringify(response.Source));
          const filteredData = this.filterData(response.Source, this.filterObj);
          if (filteredData) {
            this.data = this.paginateData(filteredData, pageIndex, pageSize);
          }
          this.initGrid();
        } else {
          this.utilityService.displayErrorMessage(response.Message);
        }
        this.onAfterLoad(this.data);
      });
    } else {
      if (sortEvent != null) {
        if (sortEvent.dir !== 0) {
          this.sortedData = JSON.parse(JSON.stringify(this.originalData));
          this.sortedData = this.sortedData.sort((a, b) => {
            return sortEvent.dir === 1 ? a[sortEvent.fieldName]
              .localeCompare(b[sortEvent.fieldName]) : -1 * a[sortEvent.fieldName]
                .localeCompare(b[sortEvent.fieldName]);
          });
        } else {
          this.sortedData = JSON.parse(JSON.stringify(this.originalData));
        }
      }
      const filteredData = this.filterData(JSON.parse(JSON.stringify(this.sortedData)), this.filterObj);
      if (filteredData) {
        this.data = this.paginateData(filteredData, pageIndex, pageSize);
      }
      this.initGrid();
    }
  }

  private paginateData(data: T[], pageIndex: number, pageSize: number): PagedList<T> {
    pageSize = Number(pageSize);
    const pagedData = new PagedList<T>();
    pagedData.HasNextPage = false;
    pagedData.HasPreviousPage = false;
    pagedData.PageIndex = pageIndex > 0 ? pageIndex : 1;
    pagedData.PageSize = pageSize;
    pagedData.TotalRows = data.length;
    pagedData.TotalPages = data.length / pageSize + (data.length % pageSize > 0 ? 1 : 0);

    if (pageIndex < pagedData.TotalPages) {
      pagedData.HasNextPage = true;
    }

    if (pageIndex > 1) {
      pagedData.HasPreviousPage = true;
    }

    const startIndex = (pageIndex - 1 > 0 ? pageIndex - 1 : 0) * pageSize;
    pagedData.Source = data.slice(startIndex, startIndex + pageSize);

    return pagedData;
  }

  private initGrid() {
    this.gridRef.data = this.data.Source;

    if (!this.pagerComponent) {
      const factory: ComponentFactory<BasicGridPagerComponent> = this.componentResolver.resolveComponentFactory(BasicGridPagerComponent);
      const pager = this.viewRef.createComponent(factory);
      this.pagerComponent = pager.instance;
      this.pagerComponent.paginateGridCallback = this.paginateGridCallback;

      this.pagerComponent.init(this.data.Source.length, this.data.TotalRows, this.data.PageIndex, this.gridRef.perPage);

      this.gridRef.paginationTemplate = pager.instance.basicPager;
    } else {
      this.pagerComponent.init(this.data.Source.length, this.data.TotalRows, this.data.PageIndex, this.gridRef.perPage);
    }

    this.gridRef.reflow();
  }

  private applySavedFilter(): any {
    if (!this.userSettingsApplied) {
      this.userSettingsApplied = true;

      const filterSetting = this.userSettingsService.getFilterSettings(this.gridRef.id);
      if (filterSetting && filterSetting.filterValues) {
        this.filterObj = filterSetting.filterValues;
      }

      if (filterSetting && filterSetting.filterDetails) {
        this.filters = [];
        this.filters.push(...filterSetting.filterDetails);
      }

      this.setFilterValues(this.filterObj, false);

    }
  }

  private checkIfControlIsFilter(nativeElement: any): boolean {
    let element: any = nativeElement;
    const key = 'name';
    if (nativeElement.attributes[key]) {
      while (element) {
        if (element.parentElement && element.parentElement.className.indexOf('ssi-filter') > -1) {
          return true;
        } else {
          element = element.parentElement;
        }
      }
    }
    return false;
  }

  private GetNameByNode(nativeElement: any, nodeName: string): string {
    let element: any = nativeElement;
    const key = 'name';
    while (element) {
      if (element.attributes[key] && element.localName === nodeName) {
        return element.attributes[key].nodeValue;
      } else {
        element = element.parentElement;
      }
    }
    return null;
  }

  private saveGridSettings(perPage: number) {
    let gridSettings = this.userSettingsService.getGridSettings(this.gridRef.id);
    if (gridSettings == null) {
      gridSettings = new GridSettings();
      gridSettings.ID = this.gridRef.id;
      gridSettings.PageSize = perPage;
      gridSettings.ColumnSettings = [];

      if (this.gridRef.columns != null && this.gridRef.columns.length > 0) {
        this.gridRef.columns.forEach((column: IgxColumnComponent) => {
          const columnSettings = new ColumnSetting();
          columnSettings.Key = column.field;
          columnSettings.Index = column.index;
          columnSettings.Hidden = column.hidden;
          columnSettings.Width = column.width;

          gridSettings.ColumnSettings.push(columnSettings);
        });
      }
    }
    else {
      gridSettings.PageSize = perPage;
    }

    this.userSettingsService.saveGridSettings(gridSettings);
  }

  private setIgxInputValue(element: IgxInputDirective, value: any) {
    if (!value) {
      value = '';
    }
    element.value = value;
  }

  private setDateTimePickerValue(element: IgxTimePickerComponent, value: string) {
    if (value) {
      const splittedValue = value.split(':');
      if (splittedValue && splittedValue.length > 0) {
        let hours = Number(splittedValue[0]);
        const splits = splittedValue[1].split(' ');
        const minutes = Number(splits[0]);
        const amPm = splits[1];
        if (amPm === 'PM') {
          hours += 12;
        }
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        element.value = date;
      }
    }
  }

  private setDatePickerValue(element: IgxDatePickerComponent, value: string) {
    if (value) {
      const date = new Date(value);
      element.value = date;
    }
  }

  private setIgxComboValue(element: IgxComboComponent, values: any[], name?: string) {
    if (values) {
      values = Array.isArray(values) ? values : [values];
      const selectedItems: any[] = [];
      element.data.forEach(n => {

        if (values.includes(n[element.valueKey])) {
          selectedItems.push(n[element.valueKey]);
          if (n[element.displayKey]) {
            // this.filters.push({
            //   fieldName: name,
            //   key: n[element.displayKey],
            //   value: n[element.valueKey]
            // });
          }
        }
      });
      element.selectItems(selectedItems, true);
    }
  }

  private setSsiSelectValue(element: SsiSelectComponent, filterObj: any, name?: string, initialDummyItems?: any[]) {
    let selectedFilterValues = filterObj[name] ? filterObj[name] : [];
    if (selectedFilterValues) {
      selectedFilterValues = Array.isArray(selectedFilterValues) ? selectedFilterValues : [selectedFilterValues];
      const selectedItems: any[] = [];

      if (element.hasRemoteData) {
        if (initialDummyItems && initialDummyItems.length) {
          initialDummyItems.forEach(val => {
            this.filters.push({
              fieldName: name,
              key: val[element.ssiCombo.displayKey],
              value: val[element.ssiCombo.valueKey],
              label: element.label
            });
          });
        }

        selectedFilterValues.forEach(val => {
          if (val) {
            selectedItems.push(val);
          }
        });

        const dummyItems = [];
        const fileds = this.filters.filter(n => n.fieldName === name);
        if (fileds) {
          fileds.forEach(field => {
            const dummyItem = {};
            dummyItem[element.itemKey] = field.value;
            dummyItem[element.itemText] = field.key;
            dummyItems.push(dummyItem);
          });
        }
        element.initialRemoteItems = dummyItems;
      } 
      else {
        element.ssiCombo.data.forEach(n => {
          selectedFilterValues.forEach(val => {
            if (val != null && n[element.ssiCombo.valueKey] != null
              && val.toString() === n[element.ssiCombo.valueKey].toString()) {
              selectedItems.push(n[element.ssiCombo.valueKey]);

              // if (n[element.ssiCombo.displayKey]) {
              //   this.filters.push({
              //     fieldName: name,
              //     key: n[element.ssiCombo.displayKey],
              //     value: n[element.ssiCombo.valueKey],
              //     label: element.label
              //   });
              // }
            }
          });
        });
      }

      element.ssiCombo.selectItems(selectedItems, true);
    }
  }
}
