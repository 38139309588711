import { Injectable } from '@angular/core';
import {
	ContractHttpResponse,
	IWebApiProxy,
	UserContextService,
	UserSettings,
	UserSettingsService,
	WebApiProxyFactoryService,
} from 'ssi-framework';
import { environment } from 'src/environments/environment';
import { CommonConstants } from 'src/app/Common/constants/constants';
@Injectable({
	providedIn: 'root',
})
export class CachedUserSettingsService {
	private api: IWebApiProxy;
	public userAffiliations: any;
	public AffiliatedOrganzationName: any;

	constructor(
		private userContext: UserContextService,
		private proxyFactory: WebApiProxyFactoryService,
		private userSettingsService: UserSettingsService
	) {}

	public getUserAffiliationSetting(silentRefresh: boolean = true): Promise<any> {
		this.api = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'CachedSettings');
		const userSettingsPromise = new Promise((resolve) => {
			if (this.userContext.userId > 0) {
				const request = this.api.Get(
					'usersettingsCode',
					{ userId: this.userContext.userId, code: CommonConstants.CURRENT_LOGIN_AFFILIATION },
					silentRefresh,
					false
				);
				request.then((response: ContractHttpResponse<UserSettings>) => {
					if (response.Success === true) {
						const affiliation = response.Source;
						if (response.Source == undefined || response.Source == null) {
							return resolve(false);
						}
						this.userAffiliations = affiliation.Value;
						this.AffiliatedOrganzationName = this.GetAffiliatedOrganizationName();
						resolve(true);
					} else {
						resolve(true);
					}
				});
			} else {
				resolve(false);
			}
		});

		// this.api.Post(`usersettings/${this.userContext.userId}`, {}, silentRefresh);

		return userSettingsPromise;
	}

	public setUserSettings(silentRefresh: boolean = true): Promise<any> {
		this.api = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'CachedSettings');
		return this.api.Post(
			`usersettings/${CommonConstants.CURRENT_LOGIN_AFFILIATION}/${this.userContext.userId}`,
			{},
			silentRefresh
		);
	}

	public getPermissions(roles: string, silentRefresh: boolean = true): Promise<any> {
		this.api = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'CachedSettings');

		const response = this.api.Get('permissions', { roles: roles }, silentRefresh, false);
		// this.api.Post(`permissions/${roles}`, {}, silentRefresh);

		return response;
	}

	public setPermissions(roles: string, silentRefresh: boolean = true): Promise<any> {
		this.api = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'CachedSettings');
		return this.api.Post(`permissions/${roles}`, {}, silentRefresh);
	}
	public saveEntry(entry: any): Promise<any> {
		this.api = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'CachedSettings');
		return this.api.Post(`SaveEntryToCache`, entry);
	}
	public getRolesFromSettings(): string {
		let roles: string;
		roles = '';
		roles = this.GetCommaSepRoles();
		return roles;
	}
	public deletePermissionsByRoleID(roleID: number): Promise<any> {
		this.api = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'CachedSettings');
		return this.api.Delete(`DeletePermissionsFromCache/${roleID}`);
	}
	public deleteAffiliationsByUserId(userID: number): Promise<any> {
		this.api = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'CachedSettings');
		return this.api.Delete(`DeleteAffiliationsFromCache/${userID}`);
	}
	private GetCommaSepRoles(): string {
		if (this.userAffiliations != undefined && this.userAffiliations != null) {
			const affiliations = JSON.parse(this.userAffiliations);
			const roles = [];
			for (const iterator of affiliations) {
				roles.push(iterator.RoleID);
			}

			return roles.join(',');
		}

		return '';
	}

	private GetAffiliatedOrganizationName(): string {
		if (this.userAffiliations != undefined && this.userAffiliations != null) {
			const affiliations = JSON.parse(this.userAffiliations);
			return affiliations[0].AllowExternalUserAccess
				? affiliations[0].OrganizationName + ' - ' + affiliations[0].ExternalCompanyName
				: affiliations[0].OrganizationName;
		}

		return '';
	}

	public GetAffiliatedOrganizationID(): string {
		if (this.userAffiliations != undefined && this.userAffiliations != null) {
			const affiliations = JSON.parse(this.userAffiliations);
			return affiliations[0].OrganizationID;
		}

		return '';
	}

	public GetCommaSepRolesByParam(loginAffilation: any): string {
		if (loginAffilation != undefined && loginAffilation != null) {
			const affiliations = loginAffilation;
			const roles = [];
			for (const iterator of affiliations) {
				roles.push(iterator.RoleID);
			}

			return roles.join(',');
		}

		return '';
	}
}
