import { BaseHttpHandler } from './base-http-handler';
import { WebApi } from '../Discovery/web-api';
import { HttpClient, HttpParameterCodec, HttpParams } from '@angular/common/http';

export class HttpGetHandlerService extends BaseHttpHandler {
  constructor(private api: WebApi | Promise<WebApi>,
    private apiName: string,
    private data: any,
    private http: HttpClient,
    private silentRefresh: boolean, private isCancelable: boolean) {
    super(api);
  }

  protected GetUrl(address: string): string {
    const url = `${address}/${this.apiName}`;
    return url;
  }

  protected AttemptReponse(url: string): Promise<any> {
    const options: any = { headers: null, params: null };
    const headers = { 'ssi-api-request': 'true' };
    if (this.silentRefresh) {
      headers['ssi-api-request-silent'] = 'true';
    }
    if (this.isCancelable) {
      headers['ssi-api-cancelable'] = 'true';
    }
    options.headers = headers;

    let params = this.data;
    if (params != null) {
      options.params = new HttpParams({ encoder: new CustomEncoder(), fromObject: params });
    }

    const requestPromise = this.http.get(url, options).toPromise();
    return requestPromise;
  }
}

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}