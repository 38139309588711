import { BaseHttpHandler } from './base-http-handler';
import { WebApi } from '../Discovery/web-api';
import { HttpClient } from '@angular/common/http';

export class HttpPutHandlerService extends BaseHttpHandler {
  constructor(private api: WebApi | Promise<WebApi>,
    private apiName: string,
    private data: any,
    private http: HttpClient,
    private silentRefresh: boolean) {
    super(api);
  }

  protected GetUrl(address: string): string {
    const url = `${address}/${this.apiName}`;
    return url;
  }

  protected AttemptReponse(url: string): Promise<any> {
    const options: any = {headers: null, params: null};
    const headers: any = { 'ssi-api-request': 'true' };
    if (this.silentRefresh) {
      headers['ssi-api-request-silent'] = 'true';
    }
    headers.timeout = '300000';
    options.headers = headers;

    const requestPromise = this.http.put(url, this.data, options).toPromise();
    return requestPromise;
  }
}
