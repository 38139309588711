import { Injectable } from '@angular/core';
import {
	WebApiProxyFactoryService,
	IWebApiProxy,
	BaseApiService,
	PermissionManagerService,
	ContractHttpResponse,
	PagedList,
} from 'ssi-framework';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { SortingDirection } from 'igniteui-angular';
import { Company } from '../models/company-model';
import { DropdownItem } from '../models/dropdown-item.model';
import { CompanyAddress } from '../models/company-address.model';

@Injectable({
	providedIn: 'root',
})
export class CompanyService extends BaseApiService<Company> {
	private readonly proxy: IWebApiProxy;

	constructor(private proxyFactory: WebApiProxyFactoryService, private permissionService: PermissionManagerService) {
		super();
		this.proxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'ExternalCompanies');
	}
	//eslint-disable-next-line
	public registerRoutes(route: ActivatedRoute): void {}

	get(id: number): Promise<ContractHttpResponse<Company>> {
		const callback = this.proxy.Get(`${id}`);
		return callback;
	}
	public getAll(): Promise<ContractHttpResponse<Company[]>> {
		throw new Error('Method not implemented.');
	}

	public getPaged(
		pageIndex: number,
		pageSize: number,
		orderBy: string,
		orderDir: SortingDirection,
		//eslint-disable-next-line
		filter: any
	): //eslint-disable-next-line
	Promise<ContractHttpResponse<PagedList<Company>>> {
		return this.proxy.Get('Search', {
			categoryTypeID: filter.CategoryType ? filter.CategoryType : 0,
			name: filter.txtName ? filter.txtName : '',
			searchText: filter.searchText ? filter.searchText : '',
			pageIndex: pageIndex,
			pageSize: pageSize,
			orderBy: orderBy ? orderBy : 'ID',
			orderDirection: !orderDir ? 0 : orderDir - 1,
			organizationID: filter.OrganizationID,
			designatedPerson: '',
			loginAffiliation: filter.loginAffiliation,
			roleName: filter.roleName,
			allowExternalUserAccess: filter.allowExternalUserAccess,
		});
	}
	//eslint-disable-next-line
	public validate(obj: Company): boolean {
		return true;
	}

	public saveOrUpdate(company: Company): Promise<ContractHttpResponse<Company>> {
		if (company.ID > 0) {
			const callback = this.proxy.Put('Update', company);
			return callback;
		} else {
			const callback = this.proxy.Post('Save', company);
			return callback;
		}
	}

	public delete(id: number): Promise<ContractHttpResponse<boolean>> {
		const callback = this.proxy.Delete(`Delete/${id}`);
		return callback;
	}
	searchCarrierByName(name: string, loginAffiliation: string): Promise<ContractHttpResponse<Company>> {
		const callback = this.proxy.Get('SearchCarrierByName', {
			searchText: name,
			loginAffiliation: loginAffiliation,
		});
		return callback;
	}
	GetAnonymous(id: number): Promise<ContractHttpResponse<Company>> {
		const callback = this.proxy.Get('GetAnonymous', {
			id: id,
		});
		return callback;
	}
	GetCarrierCompanies(OrganizationId: number,DoorId:number): Promise<ContractHttpResponse<Company>> {
		const callback = this.proxy.Get('GetCarrierCompanies', {
			OrganizationId: OrganizationId,
			DoorId:DoorId,
		});
		return callback;
	}
	searchOwnerByNameForDropdown(
		name: string,
		loginAffiliation: string
	): Promise<ContractHttpResponse<DropdownItem[]>> {
		const callback = this.proxy.Get('SearchOwnerByNameForDropdown', {
			searchText: name,
			loginAffiliation: loginAffiliation,
		});
		return callback;
	}
	GetCompanyAddress(ID: number): Promise<ContractHttpResponse<CompanyAddress[]>> {
		const callback = this.proxy.Get('GetCompanyAddress', {
			CompanyID: ID,
		});
		return callback;
	}
	SearchCarrierbyVendors(name: string, loginAffiliation: string): Promise<ContractHttpResponse<DropdownItem[]>> {
		const callback = this.proxy.Get('SearchCarrierbyVendors', {
			searchText: name,
			loginAffiliation: loginAffiliation,
		});
		return callback;
	}
	SearchCarrierbyCustomers(name: string, loginAffiliation: string): Promise<ContractHttpResponse<DropdownItem[]>> {
		const callback = this.proxy.Get('SearchCarrierbyCustomers', {
			searchText: name,
			loginAffiliation: loginAffiliation,
		});
		return callback;
	}
	searchCarrierByNameForDropdown(
		name: string,
		loginAffiliation: string
	): Promise<ContractHttpResponse<DropdownItem[]>> {
		const callback = this.proxy.Get('SearchCarrierByNameForDropdown', {
			searchText: name,
			loginAffiliation: loginAffiliation,
		});
		return callback;
	}

	public getByOrganizationAnonymous(id: number): Promise<ContractHttpResponse<Company[]>> {
		return this.proxy.Get(`GetByOrganizationAnonymous`, {
			OrganizationID: id,
		});
	}

	public GetByOwnerOrganizationAnonymous(id: number): Promise<ContractHttpResponse<Company[]>> {
		return this.proxy.Get(`GetByOwnerOrganizationAnonymous`, {
			OrganizationID: id,
		});
	}

	public GetCompanies(id: number): Promise<ContractHttpResponse<Company[]>> {
		return this.proxy.Get(`GetCompanies`, {
			OrganizationID: id,
		});
	}

	public GetCompaniesAnonymous(id: number): Promise<ContractHttpResponse<Company[]>> {
		return this.proxy.Get(`GetCompaniesAnonymous`, {
			OrganizationID: id,
		});
	}
	public IsDedicatedCarrier(id: number): Promise<ContractHttpResponse<boolean>> {
		return this.proxy.Get(`IsDedicatedCarrier`, {
			id: id,
		});
	}
}
