     <div class="ssi-hierarchy">
        <div class="header">
            <div class="filter" *ngIf="showSearchBox">
                <igx-input-group type="search" class="search search-line">
                    <input #search igxInput type="text" class="background-transparent" placeholder="Search"
                        autocomplete="none" (input)="filter($event.target.value)">
                    <igx-suffix *ngIf="search.value.length > 0" (click)="search.value = null;">
                        <igx-icon (click)="clear()">clear</igx-icon>
                    </igx-suffix>
                </igx-input-group>
            </div>
        </div>
    
        <div class="grid-wrap">
            <igx-tree-grid #treeGrid  [primaryKey]="parentKey" [data]="data" [childDataKey]="childKey"
                [autoGenerate]="false">
                <igx-column [header]=" " [field]="textKey" dataType="string">
                    <ng-template igxCell let-cell="cell" let-val>
                    
                        <ng-container *ngIf="!enableReadonly">
                            <igx-checkbox [readonly]="cell.row.rowData.Readonly" [(ngModel)]="cell.row.rowData.Selected" [indeterminate]="cell.row.rowData.Indeterminate"
                                (change)="onRowSelectionChange($event, cell.row.rowData)" [ngClass]="{'readonly': cell.row.rowData.Readonly}">
                            </igx-checkbox>
                        </ng-container>
                    
                        <ng-container *ngIf="template != undefined; else elseTemplate">  
                            <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ item:cell.row.rowData }">
                            </ng-container>
                        </ng-container>
                        <ng-template #elseTemplate>
                            {{ val }}
                        </ng-template>
                    
                    </ng-template>
                </igx-column>
            </igx-tree-grid>
            
            <div class="control-footer" *ngIf="showButtons">
                <button igxButton="flat" type="button" (click)="onCloseToggle()">{{cancelLabel}}</button>
                <button igxButton="flat" type="button" class="btn-primary" (click)="onSaveToggle()">{{saveLabel}}</button>
              </div>

        </div>
    </div>



<!-- Display overlay in a custom wrapper outlet -->
<ng-container *ngIf="overlayOutlet">
    <div igxOverlayOutlet #outlet="overlay-outlet" class="overlay-outlet"></div>
  </ng-container>