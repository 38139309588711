import { Component, ViewContainerRef, Injector } from '@angular/core';
import { ContractHttpResponse, BaseAEComponent, RouteHandlerService, LayoutService, UtilityService, CommonMessageKeys,
   PermissionManagerService, UserContextService, SsiTranslatorPipe } from 'ssi-framework';
import { ActivatedRoute, Router } from '@angular/router';
import { UserManagementService } from '../../Services/usermanagement.service';
import { User } from '../../models/user.model';
import { Company } from '../../models/company.model';
import { Team } from '../../models/team.model';
import { RoleService } from '../../Services/role.service';
import { Role } from '../../models/role.model';
import { CompanyService } from '../../Services/company.service';
import { IdentityConstants } from '../../Common/constants';

@Component({
  selector: 'ssi-identity-user-ae',
  templateUrl: './user-ae.component.html',
  styleUrls: ['./user-ae.component.css']
})
export class UserAEComponent extends BaseAEComponent<User> {

  model: User = new User();
  titles: any[] = IdentityConstants.KeyValuePairs.Titles;
  companies: Company[] = [];
  teams: Team[] = [];
  roles: any[] = [];
  selectedRoleIds: any[] = [];
  private _ssiTranslatorPipe : SsiTranslatorPipe;

  constructor(layoutService: LayoutService, private _injector: Injector,route: ActivatedRoute, router: Router,
     routeHandlerService: RouteHandlerService, public permissionMangerService: PermissionManagerService,
    private userManagementService: UserManagementService, private companyService: CompanyService,
     viewRef: ViewContainerRef, utilityService: UtilityService, commonMessageKeys: CommonMessageKeys
    , private roleService: RoleService, private userContextService: UserContextService) {
    super(route, router, routeHandlerService, viewRef, utilityService, commonMessageKeys);
    this._ssiTranslatorPipe = new SsiTranslatorPipe(_injector);
    const title = this._ssiTranslatorPipe.transform('USER_AE_TITLE');
    layoutService.setTitle(title);
  }

  get(id: number): Promise<any> {
    return this.userManagementService.get(id);
  }

  onBeforeAddEdit() {
    return this.loadLookups();
  }

  onAfterAddEdit(obj: User) {
    if (obj.ID && obj.ID > 0) {
      this.roleService.getByUserId(obj.ID)
        .then((response: ContractHttpResponse<Role[]>) => {
          if (response.Success === true) {
            const ids = response.Source.map(o => o.ID);
            const filtered = this.roles.filter(x => ids.includes(x.ID));
            this.selectedRoleIds = filtered.map(o => o.ID);
          }
          else {
          }
        });
    }
  }

  validate(vm: User): boolean {
    // for CCH
    vm.Title = 'Mr';
    return true;
  }

  saveInternal(vm: User) {
    return new Promise<any>((resolve, reject) => {
      // this.model.CompanyID = this.userContextService.companyId;
      this.userManagementService.saveOrUpdate(this.model)
        .then((response: ContractHttpResponse<User>) => {
          if (response.Success === true) {
            const selectionDto = { ID: response.Source.ID, SelectedIDs: this.selectedRoleIds };
            this.userManagementService.saveRoleAssignments(selectionDto);
            this.userManagementService.onUserUpdated.emit(response.Source.ID);
            resolve(response);
          }
          else {
            resolve(response);
          }
        });
    });
  }

  showPasswordFields(): boolean {
    if (this.model.ID == null || this.model.ID === 0) {
      return true;
    }
    return false;
  }

  OnCompanyChanged(event: any) {
    if (event && event.newSelection) {
      this.companyService.getTeams(event.newSelection.ID)
        .then((response: ContractHttpResponse<Team[]>) => {
          if (response.Success === true) {
            this.teams = response.Source;
          }
        });

      this.roleService.getByCompany(event.newSelection.ID)
        .then((response: ContractHttpResponse<Role[]>) => {
          if (response.Success === true) {
            this.roles = response.Source;

            if (this.model.ID && this.model.ID > 0) {
              this.roleService.getByUserId(this.model.ID)
                // tslint:disable-next-line: no-shadowed-variable
                .then((response: ContractHttpResponse<Role[]>) => {
                  if (response.Success === true) {
                    const ids = response.Source.map(o => o.ID);
                    const filtered = this.roles.filter(x => ids.includes(x.ID));
                    this.selectedRoleIds = filtered.map(o => o.ID);
                  }
                  else {
                  }
                });
            }
          }
          else {
          }
        });
    }
  }

  private loadLookups() {
    return new Promise((resolve, reject) => {
      this.companyService.getAll()
        .then((response: ContractHttpResponse<Company[]>) => {
          if (response.Success == true) {
            this.companies = this.companies.concat(response.Source);
            if (this.companies.length > 0) {
              this.model.CompanyID = this.companies[0].ID;
            }
          }
          resolve(true);
        });
    });
  }

}
