<igx-input-group [disabled]="disabled" #dropDownInputGroup class="input-group input-group__dropdown" type="box">
  <label *ngIf="label != undefined" igxLabel for="{{name}}">{{label}}</label>

  <igx-suffix title="Reset" class="ssi-dropdown dropdown__clear-button"
    *ngIf="showClearButton && focused && selectedText && selectedText.length > 0" (click)="onClear($event)">
    <igx-icon class="ssi-dropdown-reset">clear</igx-icon>
  </igx-suffix>

  <input #dropDownInput name="{{name}}" [(ngModel)]="selectedText" [required]="required" class="input" type="text"
    [tabindex]="index" igxInput [igxDropDownItemNavigation]="ssidropDown" [readonly]="readonly"
    [igxFocus]="focusOnSelect" autocomplete="none" (change)="onTextChange($event)" (input)="onKeydown($event)"
    (keydown.space)="handleKeys($event)" (keyup.arrowdown)="!this.disabled && onDownArrowKeydown($event)"
    (focus)="!this.disabled && focusedInput()" title="{{label}}" (focusout)="!this.disabled && focusedoutInput()"
    (click)="!this.disabled && clickedInput()" />

  <igx-suffix igxButton="icon" class="dropdownToggleButton" igxRipple (click)="!this.disabled && clickedInput()">
    <igx-icon *ngIf="ssidropDown.collapsed; else toggleUp" fontSet="material">arrow_drop_down
    </igx-icon>
    <ng-template #toggleUp>
      <igx-icon fontSet="material">arrow_drop_up</igx-icon>
    </ng-template>
  </igx-suffix>
</igx-input-group>

<igx-drop-down #ssidropDown (onSelection)="onSelection($event)">
  <igx-drop-down-item disabled="true" *ngIf="showLoader">
    <div class="dropdown-loader">
      <img src="assets/images/loader.gif"><span>Loading</span>
    </div>
  </igx-drop-down-item>
  <igx-drop-down-item *ngFor="let item of items" [value]="item" [selected]="isSelected(item)">
    <ng-container *ngIf="template; else standardView" [ngTemplateOutlet]="template"
      [ngTemplateOutletContext]="{ item:item }">
    </ng-container>
    <ng-template #standardView>
      {{item[itemText]}}
    </ng-template>
    <ng-content></ng-content>
  </igx-drop-down-item>
</igx-drop-down>