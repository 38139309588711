<div class="tabs-wrapper" [ngClass]="{'vertical': isVertical}">
  <div class="tabs-area">
    <div class="tabs-holder">
      <igx-tabs #tabControl (onTabItemSelected)="tabItemSelected($event)" [selectedIndex]="selectedIndex">
        <igx-tabs-group *ngFor="let tab of tabs" [disabled]="tab.isDisabled">
          <ng-template igxTab>
            <a href="{{tab.Url}}" (click)="tabLinkClick()">
              <span class="title">{{tab.Name}}</span>
              <ul *ngIf="tab.Data">
                  <li *ngFor="let data of tab.Data">
                    <label>{{data.label}}:</label>
                    <span class="value">{{data.value}}</span>
                  </li>
              </ul>
            </a>
          </ng-template>
        </igx-tabs-group>
      </igx-tabs>
    </div>
  </div>
  
  <div class="tabsHolder"> 
    <router-outlet name="ssiTabs" (activate)="onActivate($event)"></router-outlet>
  </div>
</div>


<!-- <igx-tabs class="tabs-default">
  <igx-tabs-group *ngFor="let tab of tabs" [disabled]="tab.isDisabled" label="{{tab.Name}}">
  </igx-tabs-group>
</igx-tabs> -->