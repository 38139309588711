import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, Injector } from '@angular/core';
import { IgxGridComponent, IgxGridCellComponent, SortingDirection } from 'igniteui-angular';
import {
	ContractHttpResponse,
	BaseLVComponent,
	UserSettingsService,
	PagedList,
	GridRowActionItem,
	LayoutService,
	UtilityService,
	CommonMessageKeys,
	PermissionManagerService,
	SsiTranslatorPipe,
	SSIDialogComponent,
	UserContextService,
	SsiSelectComponent,
	IOrderColumn,
} from 'ssi-framework';
import { Router, ActivatedRoute } from '@angular/router';
import { RoleService } from '../../Services/role.service';
import { CompanyService } from '../../Services/company.service';
import { Role } from '../../models/role.model';
import { Company } from '../../models/company.model';

@Component({
	selector: 'ssi-identity-role-lv',
	templateUrl: './role-lv.component.html',
	styleUrls: ['./role-lv.component.css'],
})
export class RoleLVComponent extends BaseLVComponent<Role> {
	protected viewRef: ViewContainerRef;
	protected utilityService: UtilityService;
	protected commonMessageKeys: CommonMessageKeys;

	@ViewChild('FWRoleGrid', { static: false })
	protected gridRef: IgxGridComponent;

	items: GridRowActionItem[] = [
		new GridRowActionItem(this, 'edit', 'Edit', 'EDIT_ROLE', 'editRole', 'edit'),
		// new GridRowActionItem(this, 'delete', 'Delete', 'DEL_ROLE', 'deleteRole', 'delete'),
		new GridRowActionItem(
			this,
			'permission',
			'Permissions',
			'ASSIGN_PERMISSIONS',
			'configurePermission',
			'ram-permission'
		),
		new GridRowActionItem(this, 'copy', 'Copy Role', 'EDIT_ROLE', 'copyRole', 'ram-copy-roles'),
	];
	companies: Company[] = [];
	private _ssiTranslatorPipe: SsiTranslatorPipe;
	@ViewChild('companyRef') companyRef: SsiSelectComponent;

	@ViewChild('copyRoleDialog', { static: false }) copyRoleDialogRef: SSIDialogComponent;
	model: Role = new Role();
	roles: Role[] = [];
	constructor(
		viewRef: ViewContainerRef,
		componentResolver: ComponentFactoryResolver,
		router: Router,
		route: ActivatedRoute,
		public permissionMangerService: PermissionManagerService,
		userSettingsService: UserSettingsService,
		private roleService: RoleService,
		private _injector: Injector,
		private companyService: CompanyService,
		utilityService: UtilityService,
		commonMessageKeys: CommonMessageKeys,
		private layoutService: LayoutService,
		private userContext: UserContextService
	) {
		super(viewRef, componentResolver, userSettingsService, utilityService, commonMessageKeys, router, route);
		this._ssiTranslatorPipe = new SsiTranslatorPipe(_injector);
	}

	public setTitle() {
		// const title = this._ssiTranslatorPipe.transform('MANAGE_ROLES_TITLE');
		this.layoutService.setTitle('Manage Roles');
	}

	protected onBeforeLoad(): Promise<any> {
		const loaded = new Promise<any>((resolve, reject) => {
			this.loadCompanies();
			resolve(true);
		});
		return loaded;
	}

	protected onAfterLoad(data: PagedList<Role>) {}
	protected getData(
		pageIndex: number,
		pageSize: number,
		orderBy: string,
		orderDir: SortingDirection,
		filter: any,
		orderColumns?: IOrderColumn[]
	): Promise<any> {
		return this.roleService.getPaged(pageIndex, pageSize, orderBy, orderDir, filter, orderColumns);
	}

	copyRole(cell: IgxGridCellComponent) {
		this.model.ID = cell.rowData.ID;
		this.model.CompanyID = cell.rowData.CompanyID;
		this.loadRoles();
	}

	loadRoles() {
		return new Promise((resolve, reject) => {
			this.roleService.getAll().then((response: ContractHttpResponse<Role[]>) => {
				if (response.Success === true) {
					this.roles = response.Source;
					setTimeout(() => {
						this.copyRoleDialogRef.show();
						this.companyRef.ssiCombo.selectItems([this.model.CompanyID], true);
					}, 20);
				}
				resolve(true);
			});
		});
	}

	editRole(cell: IgxGridCellComponent) {
		this.router.navigate(['Roles/Edit', cell.cellID.rowID.ID]);
	}

	configurePermission(cell: IgxGridCellComponent) {
		this.router.navigate(['Roles/Permission', cell.cellID.rowID.ID]);
	}

	deleteRole(cell: IgxGridCellComponent) {
		this.roleService.delete(cell.cellID.rowID.ID).then((response: any) => {
			if (response.Success === true) {
				this.utilityService.displaySuccessMessage(this.commonMessageKeys.RECORD_DELETED_SUCCESSFULLY);
				this.onApplyFilter();
			} else {
				this.utilityService.displayErrorMessage(response.Message);
			}
		});
	}

	private loadCompanies() {
		return new Promise<any>((resolve, reject) => {
			this.companyService.getAll().then((response: ContractHttpResponse<Company[]>) => {
				if (response.Success === true) {
					this.companies = [];
					this.companies = this.companies.concat(response.Source);
					this.model.CompanyID = Number(this.userContext.companyId);
					resolve(true);
				} else {
					resolve(true);
				}
			});
		});
	}

	onCopyRoleSaved() {
		if (this.validateRole(this.model)) {
			this.roleService.copyRole(this.model).then((response: ContractHttpResponse<Role>) => {
				if (response.Success) {
					this.utilityService.displaySuccessMessage(this.commonMessageKeys.RECORD_SAVED_SUCCESSFULLY);
					this.model = new Role();
					this.copyRoleDialogRef.hide();
					this.onApplyFilter();
				} else {
					this.model = new Role();
					this.copyRoleDialogRef.hide();
					this.utilityService.displayErrorMessage(this.commonMessageKeys.ROLE_ALREADY_EXIST);
				}
			});
		} else {
			this.utilityService.displayErrorMessage(this.commonMessageKeys.MANDATORY_FIELD_ERROR);
		}
	}

	validateRole(model: Role) {
		if (
			model.ID === undefined ||
			model.CompanyID === undefined ||
			model.Code === '' ||
			model.Code === undefined ||
			model.Name === '' ||
			model.Name === undefined
		) {
			this.utilityService.displayErrorMessage(this.commonMessageKeys.MANDATORY_FIELD_ERROR);
			return false;
		}
		return true;
	}

	onCopyRoleCancel() {
		this.model = new Role();
		this.roles = [];
		this.copyRoleDialogRef.hide();
	}
}
