export class Role {
    public ID: number;
    public ApplicationID: number;
    public ApplicationCode: string;
    public ApplicationName: string;
    public Code: string;
    public Name: string;
    public Description: string;
    public IsActive: boolean;
    public IsReadOnly: boolean;
    public CreatedBy: string;
    public CreatedOn: Date;
    public ModifiedBy: string;
    public ModifiedOn: Date;
    public CompanyID: number;
    public CompanyCode: string;
    public CompanyName: string;
    public Selected: boolean;
}