<div class="main-content-holder row">
  <div class="col p0">
    <div class="btn-holder">
      <ul class="btnlist">
        <li><button igxButton="flat" type="submit" class="btn-primary" (click)="onSave()" ssiTooltip="Save"><span class="icon icon-save"></span>Save</button></li>
        <li><button igxButton="flat" type="submit" (click)="onCancel()" ssiTooltip="Cancel"><span class="icon icon-times-circle"></span>Cancel</button></li>
      </ul>
    </div>
    <div class="row py-1">
      <div class="col-lg-12">
        <h4>Role: {{ role.Name }}</h4>
      </div>
    </div>
    <div class="grid-wrap">
      <igx-tree-grid #treeGrid height="100%" [data]="applicationPermissions" childDataKey="Modules"
        [autoGenerate]="false" expansionDepth="0">
        <igx-column header="Module" width="20%" field="Selected" dataType="string"
          [cellTemplate]="cellSelectionTemplate"></igx-column>
        <!-- <igx-column field="Name" dataType="string"></igx-column> -->
        <igx-column width="80%" header="Permissions" [cellTemplate]="cellTemplate" field="Permissions"></igx-column>
      </igx-tree-grid>

      <ng-template #cellSelectionTemplate igxCell let-user="cell">
        <igx-checkbox [(ngModel)]="user.row.rowData.Selected" [indeterminate]="user.row.rowData.Indetermine"
          (change)="onModuleSelectionChange($event, user.row.rowData)">
          {{ user.row.rowData.Name }}
        </igx-checkbox>
      </ng-template>

      <ng-template #cellTemplate igxCell let-value>
        <div class="row w-100">
          <igx-checkbox class="col-lg-2 py-2" *ngFor="let permission of value" [(ngModel)]="permission.Selected"
            (change)="onPermissionSelectionChange($event, permission)">
            {{ permission.Name }}
          </igx-checkbox>
        </div>
      </ng-template>
    </div>
  </div>

</div>
