import { Injectable } from '@angular/core';
import {
	WebApiProxyFactoryService,
	IWebApiProxy,
	ContractHttpResponse,
	PermissionManagerService,
	UserContextService,
} from 'ssi-framework';
import { UserManagementService } from 'ssi-identity';
@Injectable({
	providedIn: 'root',
})
export class UserService extends UserManagementService {
	private readonly userProxy: IWebApiProxy;
	constructor(
		private proxyFactoryService: WebApiProxyFactoryService,
		userContextService: UserContextService,
		permissionManagerService: PermissionManagerService
	) {
		super(proxyFactoryService, userContextService, permissionManagerService);
		this.userProxy = this.proxyFactoryService.GetProxy('User');
	}

	public unlockUser(id: number): Promise<ContractHttpResponse<void>> {
		return this.userProxy.Post('Unlock', id);
	}
}
