import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { OverlaySettings, ConnectedPositioningStrategy, PositionSettings, HorizontalAlignment, VerticalAlignment,
  AbsoluteScrollStrategy } from 'igniteui-angular';
import { IgxDropDownComponent } from 'igniteui-angular';
import { ContextMenu } from '../../../Infrastructure/Models/context-menu.model';

@Component({
  selector: 'lib-ssi-context-menu',
  templateUrl: './ssi-context-menu.component.html',
  styleUrls: ['./ssi-context-menu.component.scss']
})
export class SSIContextMenuComponent implements OnInit {

  @ViewChild(IgxDropDownComponent, {static: false}) public igxDropDown: IgxDropDownComponent;
  @Output() Selected = new EventEmitter();
  @Input() items: ContextMenu[] = [];
  private _positionSettings: PositionSettings = {
    horizontalStartPoint: HorizontalAlignment.Left,
    verticalStartPoint: VerticalAlignment.Bottom,
    horizontalDirection: HorizontalAlignment.Left
  };
  private _overlaySettings: OverlaySettings = {
    closeOnOutsideClick: true,
    modal: false,
    positionStrategy: new ConnectedPositioningStrategy(this._positionSettings),
    scrollStrategy: new AbsoluteScrollStrategy()
  };
  constructor() { }

  ngOnInit() {
  }
  public getItems() {
    return this.items.filter(x=> !x.Hidden);
  }
  public openDropDown(target) {
    if (this.igxDropDown.collapsed) {
      this._overlaySettings.positionStrategy.settings.target = target.target
        this.igxDropDown.toggle(this._overlaySettings);
    }
  }

  public selection(event) {
    event.cancel = true;
    this.igxDropDown.close();
    const filterItems = this.getItems();
    this.Selected.emit(filterItems[event.newSelection.itemIndex]);
  }

}
