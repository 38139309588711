import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { IgxGridComponent } from 'igniteui-angular';
import { UtilityService, CommonMessageKeys, PermissionManagerService } from 'ssi-framework';
import { NotificationService } from '../../services/notification.service';
import { NotificationRecipient } from '../../models/notification-recipient';

@Component({
  selector: 'lib-ssi-notification-recipient-lv',
  templateUrl: './notification-recipient-lv.component.html',
  styleUrls: ['./notification-recipient-lv.component.scss']
})
export class NotificationRecipientLVComponent implements OnInit {

  @Input() recipients: NotificationRecipient[];
  @Input() notificationID: number;
  @ViewChild('FWNotificationRecipientsGrid', {static: false}) public gridRef: IgxGridComponent;

  constructor(private utilityService: UtilityService, public permissionManagerService: PermissionManagerService,
     private notificationService: NotificationService, public commonMessageKeys: CommonMessageKeys) { }

  ngOnInit() {
  }

  public firstCap(str: string): string {
    if (str != null && str.length > 1) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } else {
      return str;
    }
  }

}
