import { Component, OnInit, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';
import { ContractHttpResponse, SsiSelectComponent, UserSettings, UserSettingsService } from 'ssi-framework';
import { HeaderActionComponent } from './header-actions/header-actions.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService, PermissionManagerService, UserContextService, UtilityService } from 'ssi-framework';
import { SelectListItem } from '../../models/SelectListItem';
import { UserAffiliationsService } from 'src/app/core/services/UserAffiliations.service';
import { userAffilation } from 'src/app/core/models/userAffiliation';
import { CommonConstants, ConfigKeys, LocalStorageKeys } from 'src/app/Common/constants/constants';
import { permission } from 'src/app/core/models/permission.model';
import { url } from 'inspector';
import { LocalIdentifierStrategy } from '@angular/compiler-cli/src/ngtsc/imports';
import { CachedUserSettingsService } from 'src/app/core/services/cached-user-settings-service';
import { LoggedInUserInformation } from 'src/app/Common/services/LoggedInUserInformation.service';
import { OrganizationService } from 'src/app/information/services/organization.service';
import { OrganizationCreateService } from '../../services/organization-create.service';
import { GlobalSettingService } from 'src/app/information/services/global-settings.services';
import { Configurations } from 'src/app/information/models/configuration.model';

@Component({
	selector: '.app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onActive: EventEmitter<boolean> = new EventEmitter<boolean>();

	title: string;
	active = false;
	showMasterFilter = false;
	@ViewChild('headerActionComponent', { static: false }) headerActionComponent: HeaderActionComponent;
	IsEmployerUser = false;
	isCandidate = false;
	userId: number;
	affiliationListItem: SelectListItem[] = [];
	userAffiliations = [];
	selected: any;
	loginAffiliation: any = null;
	showsection: boolean = null;
	model: SelectListItem = new SelectListItem();
	showHeader = true;
	retryCount = 0;
	isExternalUser = false;
	@ViewChild('organizationRef', { static: false }) organizationRef: SsiSelectComponent;

	constructor(
		private userSettingsService: UserSettingsService,
		private UserSettingsService: UserSettingsService,
		private router: Router,
		private userContextService: UserContextService,
		private userAffilationServices: UserAffiliationsService,
		private organziation: OrganizationService,
		private UserContextService: UserContextService,
		private permissionMangerService: PermissionManagerService,
		private cachedUserSettingsService: CachedUserSettingsService,
		private route: ActivatedRoute,
		private loggedInService: LoggedInUserInformation,
		private cachedSettingsService: CachedUserSettingsService,
		private _organizationCreateSerive: OrganizationCreateService,
		private globalSettingsService: GlobalSettingService,
	) {
		const EmployerIdSetting = this.userSettingsService.getUserSettingsByCode('UserType');
	}

	ngOnInit(): void {
		//check if user is candidate
		const candidateSetting = this.userSettingsService.getUserSettingsByCode('CandidateId');
		if (!candidateSetting) {
			this.showMasterFilter = true;
		}

		//this.getAndSetUserMapping();
		setTimeout(() => {
			if (this.router.url == '/userAffilation') {
				this.showsection = false;
			} else {
				this.showsection = true;
			}
		}, 2000);
		this.loginAffiliation = this.loggedInService.GetUserAffiliationFromSettings();
		this.isExternalUser = this.loginAffiliation[0].AllowExternalUserAccess;
		this.getUserOrganizationMapping();
		this.setDateTimeFormat(this.loginAffiliation[0].OrganizationID);


	}

	async ngAfterViewInit(): Promise<void> {
		//await this.getUserOrganizationMapping();
	}

	navbartoggler(): void {
		this.active = !this.active;
		this.onActive.emit(this.active);
	}

	openHome(): void {
		this.router.navigateByUrl('/home');
	}
	private async reloadUserSettings() {
		await this.userSettingsService.loadUserSettings();
	}

	private async setDateTimeFormat(organizationID: number) {
		const date = localStorage.getItem(LocalStorageKeys.DATE_TIME_FORMAT);
		if (date != null) return;

		await setTimeout(() => {
			this.globalSettingsService
				.GetOrganizationDateTimeFormat(organizationID)
				.then((response: any) => {
					if (response.Success == true && response.Source) {
						let format = response.Source.DateTime;
						localStorage.setItem(LocalStorageKeys.DATE_TIME_FORMAT, format);
					}
				});
		}, 1000);
	}

	protected async getUserOrganizationMapping() {
		this.affiliationListItem = [];

		const objSelectedItem = new SelectListItem();
		objSelectedItem.Text = 'Browse Organization';
		objSelectedItem.Value = '-1';
		this.affiliationListItem.push(objSelectedItem);

		const objSelectedItem1 = new SelectListItem();
		objSelectedItem1.Text = this.cachedUserSettingsService.AffiliatedOrganzationName;
		objSelectedItem1.Value = this.cachedUserSettingsService.GetAffiliatedOrganizationID() as unknown as string;
		objSelectedItem1.Selected = true;
		this.affiliationListItem.push(objSelectedItem1);

		setTimeout(() => {
			this.organizationRef.setSingleModelValue(objSelectedItem1.Value, false);
		}, 10);

		this.userId = this.userContextService.userId;
		await setTimeout(() => {
			this.userAffilationServices.getAffiliationByUserID(this.userId).then((Source) => {
				this.userAffiliations = Source.Source;
				this.userAffiliations.forEach(x => {
					x.forEach(o => {
						if (objSelectedItem1.Value != o.OrganizationID && o.OrganizationName != null) {
							let organizationName = o.OrganizationName;
							if (o.AllowExternalUserAccess) {
								organizationName = organizationName + ' - ' + o.ExternalCompanyName;
							}
							//this.affiliationListItem.push({ Text: organizationName, Value: o.OrganizationID, Selected: false, Disabled: false })
							this.affiliationListItem.sort((a, b) => (a.Text === 'Browse Organization' ? -1 : b.Text === 'Browse Organization' ? 1 : a.Text.localeCompare(b.Text)));
						}
					})
				});
			});
		}, 1000);
	}

	//disable-eslint-next-line
	onItemSelected(event: any) {
		if (event && event.newSelection && event.newSelection.Value == -1) {
			this.router.navigateByUrl('/userAffilation');
			//      window.location.reload();
			//location.reload();
			return;
		}

		if (event.oldSelection != null) {
			if (event.newSelection.Value != event.oldSelection.Value) {
				var organizationMapping = null;
				this.userAffiliations.forEach((x) => {
					organizationMapping = x.find((o) => o.OrganizationID == event.newSelection.Value);
					if (organizationMapping) {
						this.GetCompeleteRoles(organizationMapping);
						return;
					}
				});
			}
		}
	}

	protected async GetCompeleteRoles(organizationMapping: any) {
		//TODO: Zain verify if the permissions cache should be cleared  at this point
		await this.userAffilationServices
			.getNewAffiliationRoles(organizationMapping.MultipleIDs)
			.then((response: ContractHttpResponse<permission>) => {
				if (response.Success === true) {
					this.permissionMangerService.clearPermissions();
					this.permissionMangerService.setPermissionsFromAccessToken(response.Source);
					this.SaveUserAffiliationInSettings(organizationMapping).then((x) => {
						if (x) {
							const roles = this.cachedSettingsService.getRolesFromSettings();
							this.cachedSettingsService.setPermissions(roles).then((y) => {
								if (this.router.url == '/home') {
									location.reload();
								} else {
									this.router.navigateByUrl('/home');
									setTimeout(() => {
										location.reload();
									}, 10);
								}
							});
						}
					});
				}
			});
	}

	protected async GetUserRoles(organizationMapping: any): Promise<any> {
		const response = await this.cachedUserSettingsService.getPermissions(organizationMapping);
		if (response.Success === true) {
			this.permissionMangerService.clearPermissions();
			this.permissionMangerService.setPermissionsFromAccessToken(response.Source);
			await this.SaveUserAffiliationInSettings(organizationMapping);
			this.router.navigate(['home']);
		}
	}

	async SaveUserAffiliationInSettings(organizationMapping: any): Promise<any> {
		const userAffilations: userAffilation[] = [];
		const Roles = organizationMapping.MultipleIDs.split(',');

		Roles.forEach((element) => {
			const affiliation = new userAffilation();
			affiliation.CompanyID = organizationMapping.CompanyID;
			affiliation.DistrictID = organizationMapping.DistrictID;
			affiliation.OrganizationID = organizationMapping.OrganizationID;
			affiliation.RoleID = element;
			affiliation.ExternalCompanyID = organizationMapping.ExternalCompanyID;
			affiliation.ExternalCompanyName = organizationMapping.ExternalCompanyName;
			affiliation.AllowExternalUserAccess = organizationMapping.AllowExternalUserAccess;
			affiliation.RoleName = organizationMapping.RoleName;
			userAffilations.push(affiliation);
		});

		const userSetting = new UserSettings();

		userSetting.UserId = this.UserContextService.userId;
		userSetting.Code = CommonConstants.CURRENT_LOGIN_AFFILIATION;
		userSetting.Name = 'CURRENT LOGIN AFFILIATION ';
		userSetting.AppCode = this.UserContextService.appCode;
		userSetting.Description = 'CURRENT LOGIN AFFILIATION';
		userSetting.DataType = typeof String.name;
		userSetting.IsDeleted = false;
		userSetting.Value = JSON.stringify(userAffilations);

		return await this.UserSettingsService.saveUserSettings(userSetting).then((response) => {
			if (response === true) {
				this.cachedSettingsService.setUserSettings();
				this.cachedSettingsService.getUserAffiliationSetting();
				return true;
			} else {
				return false;
			}
		});
	}
}
