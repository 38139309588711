import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SortingDirection } from '@infragistics/igniteui-angular';
import { dateGetTimeOfDay } from 'igniteui-angular-core';
import { environment } from 'src/environments/environment';
import {
	WebApiProxyFactoryService,
	IWebApiProxy,
	BaseApiService,
	IOrderColumn,
	ContractHttpResponse,
} from 'ssi-framework';
import { ResponseModel } from '../models/Responce.model';
import { UserProfilePropertyModel } from '../models/user-profile-property.model';
@Injectable({
	providedIn: 'root',
})
export class UserProfilePropertyService extends BaseApiService<UserProfilePropertyModel> {
	private readonly proxy: IWebApiProxy;
	//private loggedInUserInformation:LoggedInUserInformation;

	constructor(private proxyFactory: WebApiProxyFactoryService) {
		super();
		this.proxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'UserProfileProperties');
	}

	registerRoutes(route: ActivatedRoute): void {
		throw new Error('Method not implemented.');
	}
	get(id: number): Promise<any> {
		throw new Error('Method not implemented.');
	}
	getPaged(
		pageIndex: number,
		pageSize: number,
		orderBy: string,
		orderDir: SortingDirection,
		filter: any,
		orderColumns?: IOrderColumn[]
	): Promise<any> {
		throw new Error('Method not implemented.');
	}
	getAll(): Promise<any> {
		throw new Error('Method not implemented.');
	}
	validate(obj: UserProfilePropertyModel): boolean {
		return true;
	}
	saveOrUpdate(dto: UserProfilePropertyModel): Promise<ContractHttpResponse<ResponseModel>> {
		const responce = this.proxy.Post('SavePin', dto);
		return responce;
	}
	GenratePin(length: number, Orgid: number): Promise<ContractHttpResponse<UserProfilePropertyModel>> {
		return this.proxy.Get('GenratePin', {
			Length: length,
			Orgid: Orgid,
		});
	}
	GetProperty(
		code: string,
		orgId: number,
		userprofileid: number
	): Promise<ContractHttpResponse<UserProfilePropertyModel>> {
		return this.proxy.Get('GetProperty', {
			code: code,
			OrganizationID: orgId,
			userProfileId: userprofileid,
		});
	}
	
	GetProperties(
		orgId: number,
		userprofileid: number
	): Promise<ContractHttpResponse<UserProfilePropertyModel[]>> {
		return this.proxy.Get('GetProperties', {
			OrganizationID: orgId,
			userProfileId: userprofileid,
		});
	}
	delete(id: number): Promise<any> {
		throw new Error('Method not implemented.');
	}
	GetUserProfileIDByPIN(pin: string, organizationID: number): Promise<ContractHttpResponse<number>> {
		return this.proxy.Get('GetUserProfileIDByPIN', {
			PIN: pin,
			OrganizationID: organizationID,
		});
	}
}
