import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageViewerConfig } from '../../../Infrastructure/Models/image-viewer.model';


const DEFAULT_CONFIG: ImageViewerConfig = {
  btnContainerClass: 'btn-container',
  btnClass: 'default',
  btnSubClass: 'material-icons',
  zoomFactor: 0.1,
  containerBackgroundColor: '#d7d7d7',
  wheelZoom: false,
  allowFullscreen: false,
  btnShow: {
    zoomIn: true,
    zoomOut: true,
    reset: true
  },
  btnIcons: {
    zoomIn: {
      classes: 'fa fa-plus',
      text: 'zoom_in'
    },
    zoomOut: {
      classes: 'fa fa-minus',
      text: 'zoom_out'
    },
    rotateClockwise:  {
      classes: 'fa fa-repeat',
      text: 'rotate_right'
    },
    rotateCounterClockwise:  {
      classes: 'fa fa-undo',
      text: 'rotate_left'
    },
    next:  {
      classes: 'fa fa-arrow-right',
      text: 'arrow_right'
    },
    prev:  {
      classes: 'fa fa-arrow-left',
      text: 'arrow_left'
    },
    fullscreen:  {
      classes: 'fa fa-arrows-alt',
      text: 'fullscreen'
    },
    reset:  {
      classes: 'icon icon-fit-screen',
      text: ' '
    },
  }
};

@Component({
  selector: 'lib-ssi-image-viewer',
  templateUrl: './ssi-image-viewer.component.html',
  styleUrls: ['./ssi-image-viewer.component.scss']
})
export class SsiImageViewerComponent implements OnInit, OnChanges {

  styleHeight = '100%';

  public style = { transform: '', msTransform: '', oTransform: '', webkitTransform: '' };
  public fullscreen = false;
  public loading = true;
  private scale = 1;
  private rotation = 0;
  private translateX = 0;
  private translateY = 0;

  
  @Input()
  index = 0;

  @Input()
  src: string;

  @Input()
  config: ImageViewerConfig;

  @Input()
  screenHeightOccupied: 0;             // In Px

  @Output()
  configChange: EventEmitter<ImageViewerConfig> = new EventEmitter();

  constructor(@Optional() @Inject('config') public moduleConfig: ImageViewerConfig,
    private sanitizer: DomSanitizer) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.screenHeightOccupied) {
      this.styleHeight = 'calc(100% - ' + this.screenHeightOccupied + 'px)';
      // console.log('Style Height:', this.styleHeight);
    }
  }

  ngOnInit() {
    const merged = this.mergeConfig(DEFAULT_CONFIG, this.moduleConfig);
    this.config = this.mergeConfig(merged, this.config);
    this.triggerConfigBinding();
  }


  zoomIn() {
    this.scale *= (1 + this.config.zoomFactor);
    this.updateStyle();
  }

  zoomOut() {
    if (this.scale > 1) {
      if (this.scale > this.config.zoomFactor) {
        this.scale /= (1 + this.config.zoomFactor);
      }
      this.updateStyle();
    }
  }

  scrollZoom(evt) {
    if (this.config.wheelZoom) {
      evt.deltaY > 0 ? this.zoomOut() : this.zoomIn();
      return false;
    }
  }

  reset() {
    this.scale = 1;
    this.rotation = 0;
    this.translateX = 0;
    this.translateY = 0;
    this.updateStyle();
  }


  private updateStyle() {
    this.style.transform = `translate(${this.translateX}px, ${this.translateY}px) rotate(${this.rotation}deg) scale(${this.scale})`;
    this.style.msTransform = this.style.transform;
    this.style.webkitTransform = this.style.transform;
    this.style.oTransform = this.style.transform;
  }

  private mergeConfig(defaultValues: ImageViewerConfig, overrideValues: ImageViewerConfig): ImageViewerConfig {
    let result: ImageViewerConfig = { ...defaultValues };
    if (overrideValues) {
      result = { ...defaultValues, ...overrideValues };

      if (overrideValues.btnIcons) {
        result.btnIcons = { ...defaultValues.btnIcons, ...overrideValues.btnIcons };
      }
    }
    return result;
  }

  triggerConfigBinding() {
    this.configChange.next(this.config);
  }

  onLoad(url) {
    // console.log('Loading Image Done:', url);
    this.loading = false;
  }

  onLoadStart(url) {
    // console.log('Loading Image:', url);
    this.loading = true;
  }

  imageNotFound(url) {
    // console.log('Image not found Url:', url);
  }

  toggleFullscreen(){

  }
}
