import { Component, Inject, ViewChild, ElementRef, TemplateRef, Renderer2 } from '@angular/core';
import { IgxOverlayService } from 'igniteui-angular';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-ssi-toast',
  templateUrl: './ssi-toast.component.html',
  styleUrls: ['./ssi-toast.component.css']
  // styleUrls: ['./ssi-toast.component.css', '../../../../../../../src/globalTheme/toast-ui.scss']
})

export class SsiToastComponent {

  @ViewChild('snackbar', { read: ElementRef, static: true})
  private snackbar: ElementRef;
  private id: string;
  message: string | TemplateRef<ElementRef>;
  status: string;
  isContent = false;
  onClose = new BehaviorSubject(false);

  constructor(@Inject(IgxOverlayService) private overlayService: IgxOverlayService, private router: Router, private renderer: Renderer2) {
  }

  showToast(message: string | TemplateRef<ElementRef>, status: string) {
    this.isContent = (typeof(message) !== 'string');
    this.message = message;
    this.status = status;
    // this.id = this.overlayService.show(this.snackbar, {
    //   positionStrategy: new GlobalPositionStrategy({
    //     target: this.snackbar.nativeElement,
    //     horizontalDirection: HorizontalAlignment.Right,
    //     horizontalStartPoint: HorizontalAlignment.Right,
    //     verticalDirection: VerticalAlignment.Top
    //   }),
    //   modal: false,
    //   closeOnOutsideClick: false
    // });
    // Append toast to body
    this.renderer.appendChild(document.body, this.snackbar.nativeElement);

    // Change opacity to add fade-in animation
    setTimeout(n => {
      this.snackbar.nativeElement.style.opacity = '1';
    }, 500);

    if (status === 'success') {
      setTimeout(n => {
        this.closeToast();
      }, 5000);
    } else {
      setTimeout(n => {
        this.closeToast();
      }, 10000);
    }
  }

  closeToast() {
    // this.overlayService.hide(this.id);
    this.onClose.next(true);

    // Change opacity to add fade-out animation
    this.snackbar.nativeElement.style.opacity = '0';

    // Remove toast from body
    setTimeout(n => {
      this.renderer.removeChild(document.body, this.snackbar.nativeElement);
    }, 500);
  }

}
