<div class="overlay-wrapper"
  [ngClass]="{'show': isOverlayActive, 'static': !showInOverlay,
  'overlay-fluid': isFluid, 'overlay-horizontal': isHorizontal, 'overlay-expanded': isExpanded, 'overlay-expanded-x': isExpandedX}"
  [ngStyle]="{'width': width && isOverlayActive && !isExpanded && !isExpandedX ? width + 'px' : null}">
  <div class="overlay" ssiClickOutside (clickOutside)="onClickedOutside()" [clickOutsideEnabled]="clickOutsideEnabled"
    [excludedClassesFromOutsideClick]="excludedClassesFromOutsideClick">
    <div class="overlay-head">
      <!-- Overlay Expand X Button -->
      <ng-container *ngIf="!isHorizontal">
        <button class="btn-round btn-expand-x" igxButton="icon" (click)="toggleOverlayX()">
          <span *ngIf="!isExpandedX" class="icon icon-chevron-left"></span>
          <span *ngIf="isExpandedX" class="icon icon-chevron-right"></span>
        </button>
      </ng-container>

      <!-- Title Icon -->
      <div *ngIf="titleIcon" class="overlay-icon">
        <span class="icon icon-{{titleIcon}} icon-md"></span>
      </div>

      <!-- Overlay Close button -->
      <button *ngIf="!isHorizontal && !showCrossIcon" class="btn-round" igxButton="icon" (click)="hideOverlay()">
        <igx-icon class="text-secondary">navigate_next</igx-icon>
      </button>

      <!-- Overlay Title -->
      <div *ngIf="title" class="overlay-title">{{title}}</div>

      <!-- Overlay Header Template -->
      <ng-template *ngIf="Header != null" [ngTemplateOutlet]="Header"></ng-template>

      <!-- Overlay Horizontal -->
      <ng-container *ngIf="isHorizontal">
        <button class="btn-round" igxButton="icon" (click)="toggleOverlay()">
          <span *ngIf="isExpanded" class="icon icon-chevron-down"></span>
          <span *ngIf="!isExpanded" class="icon icon-chevron-up"></span>
        </button>
      </ng-container>
      <div *ngIf="showCrossIcon" (click)="hideOverlay()" class="action-close">
        <igx-icon>close</igx-icon>
      </div>
    </div>
    <div class="overlay-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<div *ngIf="isModal" class="overlay-backdrop" [ngClass]="{'show': isOverlayActive}"></div>
