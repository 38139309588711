import { EventEmitter, Injectable } from '@angular/core';
import {
	WebApiProxyFactoryService,
	IWebApiProxy,
	BaseApiService,
	ContractHttpResponse,
	PagedList,
	UserContextService,
} from 'ssi-framework';
import { environment } from 'src/environments/environment';
import { UserProfileDetail } from '../models/user-profile-detail.model';
import { UserProfileCredentialsModel } from '../models/user-profile-credentials-model';
import { AutoCompleteUserName } from '../models/auto-complete-username.model';
import { UserProfile } from '../models/user-profile.model';
import { UserProfileCommon } from '../models/user-profile-common.model';
import { DropdownItem } from '../models/dropdown-item.model';
import { SortingDirection } from '@infragistics/igniteui-angular';
import { Organization } from '../models/organization.model';
import { Lookup } from '../models/lookup.model';
import { ValidateUserProfileDetailDTO } from 'src/app/loading-dock/models/driver-checkin.model';
import { LoggedInUserInformation } from 'src/app/Common/services/LoggedInUserInformation.service';
import { QuickUserCreation } from '../models/quick-user-creation.model';

@Injectable({
	providedIn: 'root',
})
export class UserProfilesService extends BaseApiService<UserProfileDetail> {
	private readonly proxy: IWebApiProxy;
	private myProfileURL = '';
	userID = 0;

	public userprofileByDistrict: any;
	public onUserProfileUpdated: EventEmitter<UserProfileCommon> = new EventEmitter();

	constructor(
		private proxyFactory: WebApiProxyFactoryService,
		private userContext: UserContextService,
		private loggedInService: LoggedInUserInformation
	) {
		super();
		this.proxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'UserProfiles');
	}
	// eslint-disable-next-line
	public registerRoutes(): void {}

	public getLoggedInUserId(): number {
		// return new Promise((res,rej)=>{
		//const identityUserID = this.userContext.userId;
		const loginAffiliation = this.loggedInService.GetUserAffiliationFromSettings();
		//const tenantID = loginAffiliation[0].DistrictID;
		this.userID = loginAffiliation[0].UserProfileID;
		return this.userID;
		// this.GetUserByIdentityUserIdAndDistrict(identityUserID, tenantID).then((response) => {
		// 	return response.Success != null && response.Source.ID != null ? res(this.userID) : rej();
		// }).catch((err)=>{
		// 	return rej();
		// });
		// })
	}

	get(
		id: number,
		masterID: boolean = false,
		loginAffiliation: string = ''
	): Promise<ContractHttpResponse<UserProfileDetail>> {
		return this.proxy.Get(`${id}?masterID=${masterID}`, {
			loginAffiliation: loginAffiliation,
		});
	}
	// eslint-disable-next-line
	public getAll(): Promise<any> {
		throw new Error('Method not implemented.');
	}
	// eslint-disable-next-line
	public getPaged(
		pageIndex: number,
		pageSize: number,
		orderBy: string,
		orderDir: SortingDirection,
		//eslint-disable-next-line
		filter: any
	): Promise<ContractHttpResponse<PagedList<UserProfile>>> {
		return this.proxy.Get('Search', {
			name: filter.txtName ? filter.txtName : '',
			txtLoginName: filter.txtLoginName ? filter.txtLoginName : '',
			searchText: filter.searchText ? filter.searchText : '',
			ddlRoleTypeIDs: filter.Role ? filter.Role : '',
			pageIndex: pageIndex,
			pageSize: pageSize,
			orderBy: orderBy ? orderBy : 'ID',
			orderDirection: !orderDir ? 0 : orderDir - 1,
			organizationID: filter.OrganizationID,
			LoginAffiliation: filter.LoginAffiliation,
			GetOrphansOnly: filter.orphansCheckBox,
			CreatedBy: filter.CreatedBy,
		});
	}

	public validate(): boolean {
		return true;
	}

	public saveOrUpdate(userProfileDetail: UserProfileDetail): Promise<ContractHttpResponse<UserProfileDetail>> {
		if (userProfileDetail.ID > 0) {
			return this.proxy.Put('Update', userProfileDetail);
		} else {
			return this.proxy.Post('Save', userProfileDetail);
		}
	}
	// eslint-disable-next-line
	public delete(id: number): Promise<any> {
		throw new Error('Method not implemented.');
	}
	public saveOrUpdateLoginCredential(
		id: number,
		userProfile: UserProfile
	): Promise<ContractHttpResponse<UserProfile>> {
		return this.proxy.Put(`${id}/LoginCredential`, userProfile);
	}
	public deleteLoginCredential(id: number): Promise<ContractHttpResponse<boolean>> {
		return this.proxy.Delete(`${id}/DeleteLoginCredential`);
	}
	public searchUsersByNameAndOrganization(
		name: string,
		loginAffiliations: string
	): Promise<ContractHttpResponse<AutoCompleteUserName[]>> {
		return this.proxy.Get(`searchUsersByNameAndOrganization`, {
			name: name,
			loginAffiliation: loginAffiliations,
		});
	}
	public getDuplicateUserProfiles(
		email: string,
		phoneNumber: string,
		loginAffiliations: string,
		masterUserProfileId: number,
		organizationID: number
	): Promise<ContractHttpResponse<UserProfile[]>> {
		return this.proxy.Get(`GetDuplicateUserProfiles`, {
			email: email,
			phoneNumber: phoneNumber,
			loginAffiliation: loginAffiliations,
			masterUserProfileId: masterUserProfileId,
			organizationID: organizationID,
		});
	}

	public searchUsersByNameAndRoleAndOrganization(
		name: string,
		roledID: number,
		organizationID: number
	): Promise<any> {
		return this.proxy.Get(`GetByUsernameAndRoleId`, {
			roleId: roledID,
			searchText: name,
			organizationID: organizationID,
		});
	}
	public searchUsersByNameAndRoleAndOrganizationForDropdown(
		name: string,
		roledID: number,
		organizationID: number
	): Promise<ContractHttpResponse<DropdownItem[]>> {
		return this.proxy.Get(`GetByUsernameAndRoleIdForDropdown`, {
			roleId: roledID,
			searchText: name,
			organizationID: organizationID,
		});
	}
	public getCellPhoneByID(userProfileID: number): Promise<ContractHttpResponse<string>> {
		return this.proxy.Get(`GetCellNoByUserProfileID`, {
			userProfileID: userProfileID,
		});
	}
	public getAssignedRoles(id: number, loginAffiliations: string): Promise<ContractHttpResponse<string[]>> {
		return this.proxy.Get(`${id}/AssignedRoles`, {
			loginAffiliation: loginAffiliations,
		});
	}
	public GetConsentsAndCredentilasAffiliations(
		loginAffiliation: string,
		userID: number,
		orderBy: string,
		orderDirection: SortingDirection,
		DataFilterType: number,
		pageIndex: number,
		pageSize: number
	): Promise<any> {
		return this.proxy.Get(`GetConsentsAndCredentilasAffiliations`, {
			loginAffiliation: loginAffiliation,
			userID: userID,
			orderBy: orderBy,
			orderDirection: !orderDirection ? 0 : orderDirection - 1,
			DataFilterType,
			pageIndex,
			pageSize,
		});
	}

	public GetUserProfileCredentialsLookups(
		loginAffiliation: string,
		organizationId: number,
		medicalCred: boolean
	): Promise<any> {
		return this.proxy.Get(`GetUserProfileCredentialsLookups`, {
			loginAffiliation: loginAffiliation,
			organizationId: organizationId,
			medicalCred: medicalCred,
		});
	}

	public GetUserProfileCredentialsByUserAndOrganization(
		loginAffiliation: string,
		userProfileID: number,
		organizationId: number,
		medicalCred: boolean
	): Promise<ContractHttpResponse<any>> {
		return this.proxy.Get(`GetUserProfileCredentialsByUserAndOrganization`, {
			loginAffiliation: loginAffiliation,
			userProfileId: userProfileID,
			organizationId: organizationId,
			medicalCred: medicalCred,
		});
	}
	public SaveUserProfilePropertiesAndCredentials(
		loginAffiliation: string,
		UserLoginName: string,
		userProfileCredentials: UserProfileCredentialsModel[],
		userProfilePersonalCredentials: UserProfileCredentialsModel[]
	): Promise<ContractHttpResponse<any>> {
		return this.proxy.Post(`SaveUserProfilePropertiesAndCredentials`, {
			loginAffiliation: loginAffiliation,
			UserLoginName: UserLoginName,
			TurnOnMedicalCredentialNotification: false,
			UserProfileProperties: null,
			UserProfileCredentials: userProfileCredentials,
			UserProfileMedicalCredentials: userProfilePersonalCredentials,
		});
	}
	public GetUserByIdentityUserIdAndDistrict(
		identityUserID: number,
		tenantID: number
	): Promise<ContractHttpResponse<UserProfile>> {
		if (this.userprofileByDistrict != null) {
			return this.userprofileByDistrict;
		} else {
			this.userprofileByDistrict = this.proxy.Get(`GetUserByIdentityUserIdAndDistrict`, {
				identityUserId: identityUserID,
				tenantID: tenantID,
			});
			return this.userprofileByDistrict;
		}
	}
	public GetOrCreateUser(identityUserID: number, subTenantID: number): Promise<ContractHttpResponse<UserProfile>> {
		return this.proxy.Get(`GetOrCreateUserProfile`, {
			identityUserId: identityUserID,
			subTenantID: subTenantID,
		});
	}
	public GetOrCreateSocialUser(userProfileDTO: UserProfileDetail): Promise<ContractHttpResponse<UserProfileDetail>> {
		return this.proxy.Post(`GetOrCreateSocialUser`, userProfileDTO);
	}

	validateCellNumberForCheckin(
		phoneNumber: string,
		organizationID: number,
		requireOTP: number,
		preferredLanguageID: number
	): Promise<ContractHttpResponse<ValidateUserProfileDetailDTO>> {
		return this.proxy.Get('ValidateUserByCellPhone', {
			phoneNumber: phoneNumber,
			organizationID: organizationID,
			requireOTP: requireOTP,
			preferredLanguageID: preferredLanguageID,
		});
	}

	SaveAndAssignCompany(user: QuickUserCreation): Promise<ContractHttpResponse<UserProfileDetail>> {
		return this.proxy.Post('SaveAndAssignCompany', user);
	}

	CreateUserAndLogin(user: UserProfile): Promise<ContractHttpResponse<boolean>> {
		return this.proxy.Post('CreateUserAndLogin', user);
	}

	setMyProfileURL(route: string) {
		this.myProfileURL = route;
	}
	getMyProfileURL(): string {
		return this.myProfileURL;
	}

	ScanCDL(base64Image: string): Promise<ContractHttpResponse<any>> {
		return this.proxy.Post('ScanCDL', { Base64Image: base64Image });
	}
}
