export class CommonConstants {
	public static JobTrackerStatus: { value: number; name: string }[] = [
		{ name: 'InProgress', value: 1 },
		{ name: 'Completed', value: 2 },
		{ name: 'Error', value: 3 },
	];
	public static SyncDirection: { value: string; name: string }[] = [
		{ name: 'Pull', value: 'Pull' },
		{ name: 'Push', value: 'Push' },
	];
	public static AssetStatusFilters: { value: number; name: string }[] = [
		{ name: 'Active', value: 1 },
		{ name: 'Departed', value: 2 },
		{ name: 'All', value: 3 },
	];
	public static IsLiveOrYard: { value: number; name: string }[] = [
		{ name: 'Live', value: 1 },
		{ name: 'Yard', value: 2 },
		{ name: 'All', value: 3 },
	];
	static IdentityClientFlows = class {
		public static get Implicit(): string {
			return 'Implicit';
		}
		public static get ResourceOwner(): string {
			return 'ResourceOwner';
		}
		public static get ClientCredentials(): string {
			return 'ClientCredentials';
		}
	};

	static RegularExpressions = class {
		public static get Email(): RegExp {
			return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		}
	};

	static Boolean = class {
		public static get NullableValues(): any[] {
			return [
				{ Name: 'N/A', Value: -1 },
				{ Name: 'Yes', Value: true },
				{ Name: 'No', Value: false },
			];
		}
	};

	static FileExtensions = class {
		public static get ImageExtensions(): string[] {
			return ['.jpe', '.jpeg', '.jpg', '.png', '.heic'];
		}
		public static get DocumentExtensions(): string[] {
			return ['.jpe', '.jpeg', '.jpg', '.png', '.heic', '.pdf', '.doc', '.docx', '.ppt', '.pptx'];
		}
		public static get ResumeExtensions(): string[] {
			return ['.pdf', '.doc', '.docx'];
		}
	};
	static UserProfilePropertiesCode = class {
		public static USER_PIN = 'USER_PIN';
		public static ID_DOC_Expiry = 'ID_DOC_Expiry';
		public static ID_DOC_No = 'ID_DOC_No';
		public static Last_Name = 'Last_Name';
		public static First_Name = 'First_Name';
	};
	static Permissions = class {
		public static VIEW_ALL_SUBTENANTS_WITHIN_TENANT = 'VIEW_ALL_SUBTENANTS_WITHIN_TENANT';
		public static ADD_USER_PROFILES = 'ADD_USER_PROFILES';
		public static EDIT_USER_PROFILES = 'EDIT_USER_PROFILES';
		public static ADD_COMPANY = 'ADD_COMPANIES';
		public static EDIT_COMPANY = 'EDIT_COMPANIES';
		public static VIEW_COMPANY = 'VIEW_COMPANIES';
		public static VIEW_LOADS = 'VIEW_LOADS';
		public static EDIT_LOADS = 'EDIT_LOADS';
		public static ADD_LOADS = 'ADD_LOADS';
		public static VIEW_ALL_COMPANY_DATA = 'VIEW_ALL_COMPANY_DATA';
		public static VIEW_ALL = 'VIEW_ALL';
		public static VIEW_USER_PROFILES = 'VIEW_USER_PROFILES';
		public static CHANGEPASSWORD = 'CHANGEPASSWORD';
		public static RE_OPEN_LOAD = 'RE_OPEN_LOADS';
		public static ADD_FILE_UPLOAD_TEMPLATE = 'ADD_FILE_UPLOAD_TEMPLATE';
		public static EDIT_FILE_UPLOAD_TEMPLATE = 'EDIT_FILE_UPLOAD_TEMPLATE';
		public static VIEW_FILE_UPLOAD_TEMPLATE = 'VIEW_FILE_UPLOAD_TEMPLATE';
		public static VIEW_LOOKUPS = 'VIEW_LOOKUPS';
		public static EDIT_LOOKUPS = 'EDIT_LOOKUPS';
		public static ADD_LOOKUPS = 'ADD_LOOKUPS';
		public static VIEW_LOADSING_DOCK = 'VIEW_LOADING_DOCK';
		public static VIEW_ORGANIZATIONS = 'VIEW_ORGANIZATIONS';
		public static EDIT_ORGANIZATIONS = 'EDIT_ORGANIZATIONS';
		public static ADD_ORGANIZATIONS = 'ADD_ORGANIZATIONS';
		public static VIEW_GLOBAL_SETTINGS = 'VIEW_GLOBAL_SETTINGS';
		public static VIEW_YARD_ASSET = 'VIEW_YARD_ASSET';
		public static ADD_YARD_ASSET = 'ADD_YARD_ASSET';
		public static EDIT_YARD_ASSET = 'EDIT_YARD_ASSET';
		public static DELETE_YARD_ASSET = 'DELETE_YARD_ASSET';
		public static EDIT_YARD_CHECK = 'EDIT_YARD_CHECK';
		public static ADD_YARD_CHECK = 'ADD_YARD_CHECK';
		public static VIEW_YARD_CHECK = 'VIEW_YARD_CHECK';
		public static ADD_NOTIFICATION_TEMPLATE = 'ADD_NOTIFICATION_TEMPLATE';
		public static EDIT_NOTIFICATION_TEMPLATE = 'EDIT_NOTIFICATION_TEMPLATE';
		public static VIEW_NOTIFICATION_TEMPLATE = 'VIEW_NOTIFICATION_TEMPLATE';
		public static ADD_NOTIFICATION_EVENT = 'ADD_NOTIFICATION_EVENT';
		public static EDIT_NOTIFICATION_EVENT = 'EDIT_NOTIFICATION_EVENT';
		public static VIEW_NOTIFICATION_EVENT = 'VIEW_NOTIFICATION_EVENT';
		public static VIEW_EVENT_NOTIFICATION_HISTORY = 'VIEW_EVENT_NOTIFICATION_HISTORY';
		public static VIEW_SYNC_LOGS = 'VIEW_SYNC_LOGS';
		public static RUN_DATA_SYNC = 'RUN_DATA_SYNC';
		public static VIEW_VEHICLES = 'VIEW_VEHICLES';
		public static EDIT_VEHICLES = 'EDIT_VEHICLES';
		public static ADD_VEHICLES = 'ADD_VEHICLES';
		public static EDIT_CAMERAEVENTS = 'EDIT_CAMERAEVENTS';
		public static VIEW_CAMERAEVENTS = 'VIEW_CAMERAEVENTS';
		public static VIEW_APPOINTMENT_SCHEDULES = 'VIEW_APPOINTMENT_SCHEDULES';
		public static EDIT_APPOINTMENT_SCHEDULES = 'EDIT_APPOINTMENT_SCHEDULES';
		public static ADD_APPOINTMENT_SCHEDULES = 'ADD_APPOINTMENT_SCHEDULES';
		public static VIEW_REWORK_REPORT = 'VIEW_REWORK_REPORT';
		public static ALLOW_EXTERNAL_USER = 'ALLOW_EXTERNAL_USER';
		public static VIEW_TRIGGER_JOBS = 'VIEW_TRIGGER_JOBS';
		public static ADD_TRIGGER_JOBS = 'ADD_TRIGGER_JOBS';
		public static EDIT_TRIGGER_JOBS = 'EDIT_TRIGGER_JOBS';
		public static ADD_SPILT_LOAD = 'ADD_SPILT_LOAD';
		public static VIEW_REQUESTS = 'VIEW_REQUESTS';
		public static APPROVE_REQUESTS = 'APPROVE_REQUESTS';
		public static REJECT_REQUESTS = 'REJECT_REQUESTS';
		public static VIEW_ROLE = 'VIEW_ROLE';
		public static ADD_ROLE = 'ADD_ROLE';
		public static EDIT_ROLE = 'EDIT_ROLE';

		public static VIEW_DEVICE_MANAGMENT = 'VIEW_DEVICE_MANAGMENT';
		public static ADD_DEVICE_MANAGMENT = 'ADD_DEVICE_MANAGMENT';
		public static EDIT_DEVICE_MANAGMENT = 'EDIT_DEVICE_MANAGMENT';
		public static EDIT_COMPLETED_TRIP = 'EDIT_COMPLETED_TRIP';
		public static VIEW_TRIPS = 'VIEW_TRIPS';
		public static EDIT_TRIPS = 'EDIT_TRIPS';
		public static ADD_TRIPS = 'ADD_TRIPS';
		public static VIEW_VISITORS = 'VIEW_VISITORS';
		public static CHECKOUT_VISITORS = 'CHECKOUT_VISITORS';
		public static EXPORT_VISITORS = 'EXPORT_VISITORS';
		public static VIEW_DOOR_MANAGEMENT = 'VIEW_DOOR_MANAGEMENT';
		public static VACANT_DOOR = 'VACANT_DOOR';
	};
	static ConfigKeysConstants = class {
		public static SHIP_WITHOUT_TRIP_SUB = 'SHIP_WITHOUT_TRIP_SUB';
	};

	public static get CURRENT_LOGIN_AFFILIATION(): string {
		return 'CurrentLoginAffiliation';
	}
	public static get RENAISSANT_PERMISSION(): string {
		return 'RenaissantPermissions';
	}
	public static get SuperAdmin(): string {
		return 'renadmin';
	}
	public static IssuerTypes = class {
		public static URL = 'URL';
	};
	static Roles = class {
		public static Customer = 'CUSTOMER';
		public static Vendor = 'VENDOR';
		public static Driver = 'DRIVER';
		public static Flop = 'FLOP';
		public static Employee = 'EMPLOYEE';
		public static Visitor = 'VISITOR';
	};
	static GlobalSettings = class {
		public static DateTimeFormates: { id: string; name: string }[] = [
			{ id: 'MMM-dd-yyyy hh:mm tt', name: 'MMM-dd-yyyy hh:mm tt (12 hrs)' },
			{ id: 'MMM-dd-yyyy HH:mm', name: 'MMM-dd-yyyy HH:mm (24 hrs)' },
		];
		public static LoadingWindowStartTimeGreaterof: { id: string; name: string }[] = [
			{ name: 'Appointment Time', id: '1' },
			{ name: 'Checked-In', id: '2' },
		];
		public static ExemptArrivalStatus: { id: string; name: string }[] = [
			{ name: 'Early', id: '1' },
			{ name: 'Late', id: '2' },
			{ name: 'On Time', id: '3' },
		];
		public static OTPVerificationOptions: { id: string; name: string }[] = [
			{ name: 'Not Required', id: '0' },
			{ name: 'Required (Verify Every time)', id: '1' },
			{ name: 'Required (Verify One-time)', id: '3' },
			{ name: 'Partially Required (Verify Every time)', id: '2' },
			{ name: 'Partial Required (Verify One-time)', id: '4' },
		];
		public static WarehouseFilters: { id: string; name: string }[] = [
			{ name: 'QC', id: '1' },
			{ name: 'Stager', id: '2' },
			{ name: 'Loader', id: '3' },
		];
		public static LoadStatusesforWarehouseFilters: { id: string; name: string }[] = [
			{ name: 'QC', id: '1' },
			{ name: 'Stager', id: '2' },
			{ name: 'Loader', id: '3' },
		];
		public static VehicleIdentifier: { id: string; name: string }[] = [
			{ name: 'Vehicle License Plate Number', id: '1' },
			{ name: 'MC Number', id: '2' },
			{ name: 'USDOT Number', id: '3' },
			{ name: 'VIN Number', id: '4' },
			{ name: 'CA Number', id: '5' },
			{ name: 'Vehicle Weight Rating', id: '6' },
			{ name: 'Emissions Type', id: '7' },
		];
	};

	public static get WEB_RESPONSIVE_URL(): string {
		return 'LoadingDock/drivercheckin';
	}

	public static get WEB_RESPONSIVE_V2_URL(): string {
		return 'LoadingDock/drivercheckinv2';
	}

	public static get CAMERA_EVENT_LV_URL(): string {
		return '/LoadingDock/camera-events';
	}

	public static get DOWNLOAD_BOL_REPORT_URL(): string {
		return 'LoadingDock/download-bol-report';
	}
	public static get DOWNLOAD_ATTACHMENT_URL(): string {
		return 'download-attachment';
	}
	public static get Visitor_URL(): string {
		return 'visitor/visitorcheckin';
	}
}

export enum LocalStorageKeys {
	TWO_FA_ENABLED = 'TWO_FA_ENABLED',
	TWO_FA_VERIFIED = 'TWO_FA_VERIFIED',
	BACKUP_IDLE_TIME = 'BACKUP_IDLE_TIME',
	DATE_TIME_FORMAT = 'DATE_TIME_FORMAT',
	IS_SOCIAL_USER = 'IS_SOCIAL_USER',
	LAST_LOGIN_REFRESH_TIME = 'LAST_LOGIN_REFRESH_TIME',
}

export enum EntityTypesForComment {
	LOAD = 'LOAD',
	TRIP = 'TRIP',
}

export enum MoveRequestEntityTypes {
	ASSET = 'ASSET',
	LOAD = 'LOAD',
	SPLIT_LOAD = 'SPLIT_LOAD',
	SCHEDULE_LOAD = 'SCHEDULE_LOAD',
}

export enum MoveRequestUserFriendlyMsg {
	LOAD = 'Load',
	SPLIT_LOAD = 'Split Loads',
	SCHEDULE_LOAD = 'Scheduled Loads',
}

export enum ClientTypes {
	DomainPull = 'DomainPull',
	DomainPush = 'DomainPush',
	CharterPull = 'CHOraclePull',
	CharterPush = 'CHOraclePush',
	Camera = 'Camera',
}

export enum RequestStatusNames {
	PENDING = 'Pending',
	INPROGRESS = 'In Progress',
	COMPLETE = 'Complete',
	REJECTED = 'Rejected',
}
export enum RequestStatusValues {
	PENDING = 1,
	INPROGRESS = 2,
	COMPLETE = 3,
	REJECTED = 4,
}
export enum MoveRequestTypes {
	INTERNAL = 'INTERNAL',
}
export enum AttachmentEventTypes {
	DOCUMENT_UPLOAD = 'DocumentUpload',
	AddedFromHDC = 'AddedByDriver_HDC',
}

export enum ConfigKeys {
	RequireOtpForLogin = 'REQUIRED_OTP_FOR_CHECKIN',
	OTPVerificationOptions = 'REQUIRE_OTP_FOR_DRIVER_CHECKIN',
	OneTimeSafetyQuestionnaire = 'SAFETY_QUESTIONNAIRE_KIOSK_SUB',
	SIGNATURE_KIOSK = 'SIGNATURE_KIOSK_SUB',
	GlobalArrivalIncrementWindow = 'GLOBAL_ARRIVAL_WINDOW_INCREMENT_SUB',
	GlobalLoadingWindow = 'GLOBAL_LOADING_WINDOW_SUB',
	GlobalDetentionRate = 'GLOBAL_DETENTION_RATE_SUB',
	UnconstrainedLoadNumbers = 'UNCONSTRAINED_LOAD_NUMBERS',
	StatusForDriverWaitTime = 'STATUS_FOR_DRIVER_WAIT_TIME_SUB',
	YardAssetsFilters = 'YARD_ASSETS',
	OutBoundLoadNumbers = 'OUTBOUND_LOAD_NUMBERS',
	YardAssets = 'YARD_ASSETS',
	ShipmentReceiptTypes = 'SHIPMENT_RECEIPT_TYPES',
	ExemptedLoadStatusForDwellTime = 'EXEMPTED_LOAD_STATUSES_FOR_DWELL_TIME',
	AppointmentDuration = 'APPOINTMENT_DURATION',
	FreeHDCTextField = 'FREE_HDC_TEXT_FIELD',

	HDCAllowedWorkflows = 'ALLOWED_WORKFLOWS',
	HDCConstraintWFs = 'HIGH_LEVEL_WORKFLOWS_LOAD_NUMBER',
	HDCInboundWFLoadNumbers = 'INBOUND_LOAD_NUMBERS',
	HDCOutBoundWFLoadNumbers = 'OUTBOUND_LOAD_NUMBERS',
	HDCAppointementWFs = 'HIGH_LEVEL_WORKFLOWS_APPOINTMENT',
	HDCCarrierWFs = 'HIGH_LEVEL_WORKFLOWS_CARRIER',
	HDCTrailerTypeWFs = 'HIGH_LEVEL_WORKFLOWS_TRAILER_TYPE',
	HDCAssetLengthWFs = 'HIGH_LEVEL_WORKFLOWS_ASSET_LENGTH',
	HDCAssetMaxLoadWeightWFs = 'HIGH_LEVEL_WORKFLOWS_ASSET_MAX_LOAD_WEIGHT',
	HDCMaxTemperatureWFs = 'HIGH_LEVEL_WORKFLOWS_MAX_TEMPRATURE',
	HDCReeferFuelWFs = 'HIGH_LEVEL_WORKFLOWS_REEFER_FUEL',

	OutboundLoadNumberFormat = 'LOAD_NUMBER_FORMAT_OUTBOUND',
	OutboundBookingNumberFormat = 'BOOKING_NUMBER_FORMAT_OUTBOUND',
	OutboundFileNumberFormat = 'FILE_NUMBER_FORMAT_OUTBOUND',
	OutboundDrayageNumberFormat = 'DRAYAGE_NUMBER_FORMAT_OUTBOUND',
	OutboundTrailerNumberFormat = 'TRAILER_NUMBER_FORMAT_OUTBOUND',
	OutboundContainerNumberFormat = 'CONTAINER_NUMBER_FORMAT_OUTBOUND',

	InboundLoadNumberFormat = 'LOAD_NUMBER_FORMAT_INBOUND',
	InboundBookingNumberFormat = 'BOOKING_NUMBER_FORMAT_INBOUND',
	InboundFileNumberFormat = 'FILE_NUMBER_FORMAT_INBOUND',
	InboundDrayageNumberFormat = 'DRAYAGE_NUMBER_FORMAT_INBOUND',
	InboundTrailerNumberFormat = 'TRAILER_NUMBER_FORMAT_INBOUND',
	InboundContainerNumberFormat = 'CONTAINER_NUMBER_FORMAT_INBOUND',
	InboundPurchaseOrderNumberFormat = 'PURCHASE_ORDER_NUMBER_FORMAT_INBOUND',
	InboundAdvanceShippingNoticeNumberFormat = 'ADVANCE_SHIPPING_NUMBER_FORMAT_INBOUND',
	HighPriorityComment = 'HIGH_PRIORITY_COMMENT',
	AllowDriverToUploadAttachment = 'ALLOW_DRIVER_TO_UPLOAD_ATTACHMENT',

	OutboundPreLoadNumberSuffix = '_FORMAT_OUTBOUND_PRELOAD',
	OutboundLoadNumberSuffix = '_FORMAT_OUTBOUND',
	InboundLoadNumberSuffix = '_FORMAT_INBOUND',
	InboundDropLoadNumberSuffix = '_FORMAT_INBOUND_DROPLOAD',
	UseLoadingDockPIN = 'USE_MANAGE_LOAD_PIN_SUB',
	AskPinForNotification = 'ASK_PIN_FOR_NOTIFICATION',
	IndependantYardWorkflow = 'INDEPENDENT_YARD_WORKFLOW',
	AllowMoveAsset = 'ALLOW_MOVE_ASSET',
	ValidateAssetLocation = 'VALIDATE_ASSET_LOCATION',
	RequireDriverCheckout = 'REQUIRE_DRIVER_CHECKOUT',
	SafetyQuestionOptions = 'SAFETYQUESTIONOPTION',
	EXTERNAL_USERS_ALLOWED_WORKFLOWS = 'EXTERNAL_USERS_ALLOWED_WORKFLOWS',
	GS_VehicleIdentifier = 'VEHICLE_IDENTIFIER',
	GS_AssetTracker = 'ASSETTRACKER',
	GS_VehicleTracker = 'VEHICLETRACKER',
	SkipHDCQuestionScreen = 'SKIPHDCQUESTIONSCREEN',
	ALLOW_MOVE_ASSET = 'ALLOW_MOVE_ASSET',
	ALLOWED_TRIP_TYPE = 'ALLOWED_TRIP_TYPE',

	DATE_RANGE = 'DATE_RANGE',
	LEAD_TIME = 'LEAD_TIME',
	REQUIRE_BOL_FOR_EXTERNAL_SCHEDULING = 'REQUIRE_BOL_FOR_EXTERNAL_SCHEDULING',
	VERIFIED_CDL = 'VERIFIED_CDL',
	CAPTURE_PHOTO = 'CAPTURE_PHOTO',
	ALLOW_ORDER_MODE = 'ALLOW_ORDER_MODE',
	ALLOW_MULTIPLE_LOADS_CONSTRAINT = 'ALLOW_MULTIPLE_LOADS_CONSTRAINT',

	ASK_LOCATION = 'ASK_LOCATION',
	STORE_DRIVER_PHOTO = 'STORE_DRIVER_PHOTO',
	// REQUIRE_CDL_SCAN = 'REQUIRE_CDL_SCAN',
	VALIDATE_CDL_PHOTO = 'VALIDATE_CDL_PHOTO',
	VALIDATE_ASSET_DATA = 'VALIDATE_ASSET_DATA',
	REQUIRE_SEAL_NUMBER = 'REQUIRE_SEAL_NUMBER',
	VALIDATE_SEAL_NUMBER = 'VALIDATE_SEAL_NUMBER',
	REQUIRE_PHOTO_OF_SEAL = 'REQUIRE_PHOTO_OF_SEAL',
	ASSETPOOL = 'ASSET_POOL',
	CHASISREQUIRED = 'CHASISREQUIRED',
	ALLOW_WITHOUT_LOAD_NUMBER = 'ALLOW_WITHOUT_LOAD_NUMBER',

	CaptureVisitorImage = 'CAPTURE_VISITOR_IMAGE',
	ScanVisitorID = 'SCAN_VISITOR_ID',
	CompareVisitorPictureAndID = 'COMPARE_VISITOR_PICTURE_AND_ID',
	SaveVisitorPicture = 'SAVE_VISITOR_PICTURE',
	SaveVisitorID = 'SAVE_VISITOR_ID',
	VisitorModuleURL = 'VISITOR_MODULE_URL',
	EnableVisitorModule = 'ENABLE_VISITOR_MODULE',
	USE_RENAISSANT_TEMPLATE_FOR_FILE = 'USE_RENAISSANT_TEMPLATE_FOR_FILE',
	TrackLiveLoadsAsset = 'TRACK_LIVE_LOADS_ASSET',
}
export enum AuthenticationTypes {
	MobileVisitorCheckIn_SMS = 12,
	MobileVisitorCheckIn_Email = 13,
	LoadingDockCheckin_MobileFriendlyWebApp_SMS = 15,
	LoadingDockBOL_SMS = 16,
}

export enum OccurrenceEnum {
	Sun = 'Sunday',
	Mon = 'Monday',
	Tue = 'Tuesday',
	Wed = 'Wednesday',
	Thu = 'Thursday',
	Fri = 'Friday',
	Sat = 'Saturday',
}
export enum MoveRequestPriority {
	Low = 1,
	Normal = 2,
	High = 3,
}
export enum MISType {
	Clever = 'Clever',
	Oracle = 'Oracle',
	Skyward = 'Skyward',
	ImportUsers = 'ImportUsers',
	GmailEmailSync = 'GmailEmailSync',
	SplitLoad = 'SplitLoad',
	ImportLoads = 'ImportLoads',
	RENAISSANT_SCHEDULAR = 'RenaissantSchedular',
}

export enum DriverArrivalStatusValues {
	Early = 1,
	Late = 2,
	OnTime = 3,
}

export enum CameraMode {
	user = 'user', //use for front camera
	environment = 'environment', //use for back camera
}
