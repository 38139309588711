import { CompanyAeComponent } from './usermanagement/company-ae/company-ae.component';
import { NgModule } from '@angular/core';
import { UserLVComponent } from './usermanagement/user-lv/user-lv.component';
import { UserAEComponent } from './usermanagement/user-ae/user-ae.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { IgxGridModule, IgxIconModule, IgxButtonModule, IgxRippleModule, IgxTreeGridModule, IgxDialogModule, IgxInputGroupModule,
   IgxComboModule, IgxSnackbarModule, IgxToastModule, IgxListModule, IgxTabsModule } from 'igniteui-angular';
import { SsiFrameworkModule } from 'ssi-framework';
import { UserManagementService } from './Services/usermanagement.service';
import { RoleService } from './Services/role.service';
import { RoleLVComponent } from './usermanagement/role-lv/role-lv.component';
import { CompanyService } from './Services/company.service';
import { RoleAEComponent } from './usermanagement/role-ae/role-ae.component';
import { PermissionComponent } from './usermanagement/permission/permission.component';
import { PermissionService } from './Services/permission.service';
import { UserProfileComponent } from './usermanagement/user-profile/user-profile.component';
import { UserPersonalInfoComponent } from './usermanagement/user-profile/user-personal-info/user-personal-info.component';
import { UserPasswordComponent } from './usermanagement/user-profile/user-password/user-password.component';
import { UserRolesComponent } from './usermanagement/user-profile/user-roles/user-roles.component';
import { UserPreferencesComponent } from './usermanagement/user-profile/user-preferences/user-preferences.component';
import { CompanyLvComponent } from './usermanagement/company-lv/company-lv.component';
import { UserAccessComponent } from './usermanagement/user-profile/user-access/user-access.component';
import { IdentityProviderService } from './Services/identityprovider.service';
import { ResetPasswordComponent } from './usermanagement/reset-password/reset-password.component';

const routes: Routes = [
  // { path: 'Users/Edit/:id', component: UserAEComponent},
  { path: 'Users', component: UserLVComponent, data: { mainRoute: true, permissions: 'VIEW_USER' } },
  {
    path: 'Users/Edit/:id', component: UserProfileComponent, children: [
      { path: 'personalInfo', component: UserPersonalInfoComponent, outlet: 'ssiTabs', data: { title: 'Personal Info', sortOrder: 1 } },
      // { path: 'preferences', component: UserPreferencesComponent, outlet: 'ssiTabs', data:{title: 'Preferences', sortOrder:2} },
      { path: 'roles', component: UserRolesComponent, outlet: 'ssiTabs', data: { title: 'Roles', sortOrder: 3, permissions: 'ASSIGN_ROLES' } },
      { path: 'Access', component: UserAccessComponent, outlet: 'ssiTabs', data: { title: 'Access', sortOrder: 4 } },
      { path: 'password', component: UserPasswordComponent, outlet: 'ssiTabs', data: { title: 'Password', sortOrder: 5 } },
    ]
  },

  { path: 'Users/Add', component: UserAEComponent, data: { permissions: 'ADD_USER' } },
  { path: 'Companies/Edit/:id', component: CompanyAeComponent },
  { path: 'Companies/Add', component: CompanyAeComponent },
  { path: 'Companies', component: CompanyLvComponent },
  { path: 'Roles', component: RoleLVComponent, data: { mainRoute: true, permissions: 'VIEW_ROLE' } },
  { path: 'Roles/Edit/:id', component: RoleAEComponent, data: { permissions: 'EDIT_ROLE' } },
  { path: 'Roles/Add', component: RoleAEComponent, data: { permissions: 'ADD_ROLE' } },
  { path: 'Roles/Permission/:roleId', component: PermissionComponent, data: { permissions: 'ASSIGN_PERMISSIONS' } }
];

@NgModule({
  declarations: [
    UserLVComponent,
    UserAEComponent,
    RoleLVComponent,
    RoleAEComponent,
    PermissionComponent,
    UserProfileComponent,
    UserPersonalInfoComponent,
    UserPasswordComponent,
    UserRolesComponent,
    UserPreferencesComponent,
    CompanyAeComponent,
    CompanyLvComponent,
    ResetPasswordComponent,
    UserAccessComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    IgxGridModule,
    IgxIconModule,
    IgxButtonModule,
    IgxRippleModule,
    IgxDialogModule,
    IgxInputGroupModule,
    IgxSnackbarModule,
    IgxToastModule,
    SsiFrameworkModule,
    IgxComboModule,
    IgxTreeGridModule,
    IgxListModule,
    IgxTabsModule
  ],
  providers: [
    UserManagementService,
    RoleService,
    CompanyService,
    PermissionService,
    IdentityProviderService
  ],
  entryComponents: [
    UserLVComponent,
    UserAEComponent,
    UserProfileComponent,
    UserPersonalInfoComponent,
    RoleLVComponent,
    RoleAEComponent,
    ResetPasswordComponent
  ],
  exports: [
    UserLVComponent,
    RoleLVComponent,
    CompanyAeComponent,
    CompanyLvComponent,
    UserPasswordComponent,
    UserRolesComponent,
    ResetPasswordComponent
  ]
})
export class SsiIdentityModule {
}
