import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { map, catchError, takeUntil, finalize } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { ContractHttpResponse } from '../HttpHandler/contract-http-response';
import { UtilityService } from '../../Common/utility.service';
import { Subject, throwError } from 'rxjs';
import { AuthenticateClientService } from '../../Authentication/authenticate-client.service';
import { ActivationEnd, Router } from '@angular/router';
import * as moment from 'moment';

@Injectable()
export class SSIHttpInterceptor implements HttpInterceptor {
    private _iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
    private _activeRequestsCount = 0;
    private pendingHTTPRequests$ = new Subject<void>();

    constructor(private _oauthService: OAuthService,
        private _authenticateClientService: AuthenticateClientService,
        router: Router,
        private _utilityService: UtilityService) {
        router.events.subscribe(event => {
            // An event triggered at the end of the activation part of the Resolve phase of routing.
            if (event instanceof ActivationEnd) {
                // Cancel pending calls
                this.pendingHTTPRequests$.next();
            }
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has('ssi-api-request')) {
            this._authenticateClientService.refreshAccessToken()

            return this.handleRequest(req, next);
        } else {
            return next.handle(req);
        }
    }

    private handleRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this._activeRequestsCount === 0) {
            if (!req.headers.has('ssi-api-request-silent')) {
                setTimeout(() => {
                    if (this._activeRequestsCount > 0) {
                        this._utilityService.displayContentAreaLoadingOverlay(true);
                    }
                }, 50);
            }
        }

        if (!req.headers.has('ssi-api-request-silent')) {
            this._activeRequestsCount++;
        }

        req = this.injectAccessToken(req);
        req = this.removeEmptyArrayParams(req);
        req = this.convertDateToString(req);

        let subscription = next.handle(req).pipe(
            finalize(() => {
                // request completes, errors, or is cancelled
                if (!req.headers.has('ssi-api-request-silent')) {
                    this._activeRequestsCount--;
                }

                if (this._activeRequestsCount === 0) {
                    const _self = this;
                    setTimeout(() => {
                        if (this._activeRequestsCount === 0) {
                            _self._utilityService.displayContentAreaLoadingOverlay(false);
                        }
                    }, 100);
                }
            })
        );
        
        if (req.headers.has('ssi-api-cancelable') && !req.url.includes('signalr')) {
            subscription = subscription.pipe(takeUntil(this.pendingHTTPRequests$.asObservable()));
        }
      
        return subscription.pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    event = event.clone({ body: this.transformResponse(event.body) });
                }
                return event;
            }),
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if ((err as HttpErrorResponse).status === 401) {
                        this._authenticateClientService.signOut();
                    }
                }
                return throwError(err);
            }));
    }

    private removeEmptyArrayParams(req: HttpRequest<any>): HttpRequest<any> {
        if (req.method.toLowerCase() === 'get') {
            let parms = req.params;
            const keys = parms.keys();
            for (const key of keys) {
                const value = parms.getAll(key);
                if (Array.isArray(value)) {
                    if (value == null || !value.length) {
                        parms = parms.delete(key);
                    }
                }
            }

            req = req.clone({
                params: parms
            });
        }
        return req;
    }

    private convertDateToString(req: HttpRequest<any>) {
        if (req.method.toLowerCase() === 'get') {
            let parms = req.params;
            const keys = parms.keys();

            for (const key of keys) {
                const value = parms.getAll(key);
                if ((value as any) instanceof Date) {
                    if (value != null) {
                        parms = parms.set(key, moment((value as any) as Date).format("MM/DD/YYYY hh:mm:ss A"));
                    }
                } else if (Array.isArray(value)) {
                    parms = parms.delete(key);
                    for (let i = 0; i < value.length; i++) {
                        if ((value[i] as any) instanceof Date) {
                            if (value[i] != null) {
                                value[i] = moment((value[i] as any) as Date).format("MM/DD/YYYY hh:mm:ss A");
                            }
                        }
                        parms = parms.append(key, value[i]);
                    }
                }
            }

            req = req.clone({
                params: parms
            });
        } else if (req.method.toLowerCase() === 'post' || req.method.toLowerCase() === 'put') {
            const body = req.body;

            if (Array.isArray(body)) {
                for (const item of body) {
                    this.objectDatesToString(item);
                }
            } else {
                this.objectDatesToString(body);
            }

            req = req.clone({
                body
            });
        }

        return req;
    }

    private objectDatesToString(obj: any): any {
        for (const key of Object.keys(obj)) {
            if (obj[key] instanceof Date) {
                if (obj[key] != null) {
                    obj[key] = moment((obj[key] as Date)).format("MM/DD/YYYY hh:mm:ss A"); 
                }
            } else if (Array.isArray(obj[key])) {
                for (let i = 0; i < obj[key].length; i++) {
                    if (obj[key][i] instanceof Date) {
                        if (obj[key][i] != null) {
                            obj[key][i] = moment((obj[key][i] as Date)).format("MM/DD/YYYY hh:mm:ss A");
                        }
                    } else {
                        obj[key][i] = this.objectDatesToString(obj[key][i]);
                    }
                }
            }
        }
        return obj;
    }

    private injectAccessToken(req: HttpRequest<any>) {
        return req.clone({
            setHeaders: {
                Authorization: `Bearer ${this._oauthService.getAccessToken()}`
            }
        });
    }

    private transformResponse(body: any): ContractHttpResponse<any> {
        this.convertToDate(body);

        const response = new ContractHttpResponse<any>();
        response.Success = true;
        response.Source = body;
        return response;
    }

    private convertToDate(body) {
        if (body === null || body === undefined) {
            return body;
        }

        if (typeof body !== 'object') {
            return body;
        }

        for (const key of Object.keys(body)) {
            let value = body[key];
            if (this.isIso8601(value)) {
                if (value.length > 19) {
                    value = value.substring(0, value.length - 6);// + new Date().toString().match(/([-\+][0-9]+)\s/)[1];
                } else {
                    value = value;// + new Date().toString().match(/([-\+][0-9]+)\s/)[1];
                }

                //const splittedValue = value.split(/[^0-9]/);
                const splittedValue = typeof (value) == 'string' ? value.split(/[^0-9]/) : value.join().split(/[^0-9]/);
                body[key] = new Date(splittedValue[0], splittedValue[1] - 1,
                    splittedValue[2], splittedValue[3], splittedValue[4], splittedValue[5]);
            } else if (typeof value === 'object') {
                this.convertToDate(value);
            }
        }
    }

    private isIso8601(value) {
        if (value === null || value === undefined) {
            return false;
        }
        return this._iso8601.test(value);
    }

    private displayLoadingOverlay(show: boolean) {
        if (document.getElementsByTagName('app-layout').length) {
            if (show) {
                document.getElementsByTagName('app-layout').item(0).classList.add('busy');
            } else {
                document.getElementsByTagName('app-layout').item(0).classList.remove('busy');
            }
        }
    }
}
