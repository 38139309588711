<div class="filter-area ssi-filter">
  <div class="tags-holder">
    <igx-chips-area>
      <igx-chip *ngFor="let obj of filterObject | slice : 0:maxChips;" [id]="obj" [removeIcon]="ChipRemoveIcon"
        [removable]="true" (onRemove)="filterRemoved($event.owner.id)">
        <span>{{obj.key}}</span>
      </igx-chip>
    </igx-chips-area>
    <ng-template #ChipRemoveIcon>
      <span class="icon icon-times-circle"></span>
    </ng-template>
  </div>
  <div class="tags-btn" *ngIf="filterObject.length > maxChips">
    <span (click)="toggleTagsDropDown($event)" title="View All Tags">
      <button igxButton="icon">
        <span class="icon icon-view-all" style="font-size: 3px;"></span>
      </button>
    </span>
    <igx-drop-down #filterTagContainerDDL width="250px">
      <igx-drop-down-item class="filter-tags-dropdown">
        <div class="content-holder ssi-filter filter-tags">
          <igx-chips-area>
            <igx-chip *ngFor="let obj of filterObject" [removeIcon]="ChipRemoveIcon" [id]="obj" [removable]="true"
              (onRemove)="filterRemoved($event.owner.id)">
              <span>{{obj.key}}</span>
            </igx-chip>
          </igx-chips-area>
          <ng-template #ChipRemoveIcon>
            <span class="icon icon-times-circle"></span>
          </ng-template>
        </div>
      </igx-drop-down-item>
    </igx-drop-down>
  </div>
  <div class="filter-holder">

    <div *ngIf="showSearchDropdown; else searchTemplate" class="glbSearch" [igxToggleAction]="searchDropdown">
      <igx-input-group type="search">
        <igx-suffix *ngIf="searchInput.value.length > 0" (click)="clearSearchInput()" title="Reset">
          <igx-icon>clear</igx-icon>
        </igx-suffix>
        <input igxInput #searchInput name="searchText" type="text" placeholder="Search and Filter" autocomplete="off"
          [(ngModel)]="searchText" (keydown)="onKeydown($event)" />
      </igx-input-group>
    </div>

    <ng-template #searchTemplate>
      <div class="glbSearch">
        <igx-input-group type="search">
          <igx-suffix *ngIf="searchInput.value.length > 0" (click)="clearSearchInput()" title="Reset">
            <igx-icon>clear</igx-icon>
          </igx-suffix>
          <input igxInput #searchInput name="searchText" type="text" placeholder="Search and Filter" autocomplete="off"
            [(ngModel)]="searchText" (keydown)="onKeydown($event)" />
        </igx-input-group>
      </div>
    </ng-template>

    <div class="pull-down">
      <span class="icon-hlder" (click)="toggleDropDown($event)" title="Advance Search">
        <button igxButton="icon" igxButtonColor="#7c7c7c" igxButtonBackground="#fff">
          <igx-icon>expand_more</igx-icon>
        </button>
      </span>
      <span class="icon-hlder vr" (click)="searchFilter($event)" title="Search">
        <button igxButton="icon" igxButtonColor="#7c7c7c" igxButtonBackground="#fff">
          <igx-icon class="text-primary">search</igx-icon>
        </button>
      </span>

      <span *ngIf="showRecentSearches" class="icon-hlder vr" title="Recent Searches">
        <button #searchHistoryBtn igxButton="icon" igxButtonColor="#7c7c7c" igxButtonBackground="#fff" (click)="searchHistoryFilter($event)">
          <igx-icon class="text-primary">history</igx-icon>
        </button>
      </span>

      <igx-drop-down #filterContainerDDL width="{{containerWidth}}px" (onSelection)="onSelection($event)">
        <igx-drop-down-item class="ssifilter">
          <div class="content-holder ssi-filter w-100" [ngClass]="{'overlay-filter': isOverlayFilter}">
            <div class="filter-content">
              <ng-content></ng-content>
            </div>
            <div class="filter-footer">
              <div class="btn-holder">
                <ul class="btnlist">
                  <li><button igxButton class="btn-primary" (click)="searchFilter($event)" type="button"
                      title="Search">Search</button></li>
                  <li><button *ngIf="enabelSaveAndSearch == true" igxButton class="btn-light"
                    (click)="saveAndApplyFilter($event)" type="button" title="Save & Search">Save & Search</button>
                  </li>
                  <li><button igxButton (click)="resetFilter($event)" type="button" class="btn-light"
                      title="Reset">Reset</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </igx-drop-down-item>
      </igx-drop-down>
    </div>
  </div>
</div>

<igx-drop-down #searchDropdown *ngIf="showSearchDropdown">
  <search-shortcuts-overlay (onSearchSelected)="onSearchSelected($event)"></search-shortcuts-overlay>
</igx-drop-down>
