import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { MainMenuComponent } from './layout/header/main-menu/main-menu.component';
import { HeaderActionComponent } from './layout/header/header-actions/header-actions.component';
import { MainContentComponent } from './layout/main-content/main-content.component';
import { SecondaryActionComponent } from './layout/secondary-action/secondary-action.component';
import {
	IgxNavigationDrawerModule,
	IgxIconModule,
	IgxAvatarModule,
	IgxInputGroupModule,
	IgxButtonModule,
	IgxGridModule,
	IgxListModule,
	IgxComboModule,
	IgxExpansionPanelModule,
} from 'igniteui-angular';
import { SsiFrameworkModule, RouteHandlerService } from 'ssi-framework';
import { SsiNotificationModule } from 'ssi-notification';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { AuthContentComponent } from './auth-layout/auth-content/auth-content.component';
import { LoginComponent } from './auth-layout/auth-content/login-layout/login/login.component';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { ForgetPasswordComponent } from './auth-layout/auth-content/forget-password/forget-password.component';
import { ResetPasswordComponent } from './auth-layout/auth-content/reset-password/reset-password.component';
import { UserService } from './services/user.service';
import { SharedMessagingKeys } from './common/shared-messaging-keys.service';
import { SignupComponent } from './auth-layout/auth-content/signup/signup.component';
import { CandidateService } from './services/candidate.service';
// import { MasterFilterComponent } from './layout/header/master-filter/master-filter.component';
// import { CustomInappNotificationComponent } from './custom-inapp-notification/custom-inapp-notification.component';
import { UserAffiliationLvComponent } from '../core/UserAffiliation/user-affiliation-lv/user-affiliation-lv.component';
import { LoginLayoutComponent } from './auth-layout/auth-content/login-layout/login-layout.component';
import { CodeInputModule } from 'angular-code-input';
import { DownloadAttachmentComponent } from './common/components/download-attachment/download-attachment.component';
import { InappNotificationsComponent } from '../information/components/notification-event-management/inapp-notifications/inapp-notifications.component';
import { DeactivateUserLayoutComponent } from './auth-layout/auth-content/deactivate-user-layout/deactivate-user-layout.component';
import { DeactivateUserComponent } from './auth-layout/auth-content/deactivate-user-layout/deactivate-user/deactivate-user.component';
import { SignupExternalComponent } from './auth-layout/auth-content/signup-external/signup-external.component';
import { SignupExternalLayoutComponent } from './auth-layout/auth-content/signup-external/signup-external-layout/signup-layout-external-layout.component';
import { OrganizationCreateService } from './services/organization-create.service';
import { LogoutAzureComponent } from './auth-layout/auth-content/logout-azure/logout-azure.component';

const coreRoutes: Routes = [
	{ path: 'login', component: LoginLayoutComponent, canActivate: [RouteHandlerService] },
	{ path: 'deleteuser', component: DeactivateUserLayoutComponent },
	{ path: 'backupEmail', component: ForgetPasswordComponent, canActivate: [RouteHandlerService] },
	{ path: 'resetPassword', component: ResetPasswordComponent, canActivate: [RouteHandlerService] },
	{ path: 'SignUp', component: SignupComponent },
	{ path: 'download-attachment/:id', component: DownloadAttachmentComponent, canActivate: [RouteHandlerService] },
	{ path: 'signup-external', component: SignupExternalLayoutComponent, canActivate: [RouteHandlerService] },
	{ path: 'logout-azure-ad', component: LogoutAzureComponent },

	// {path:'abc',component: VerifyOTPComponent,children:[{path:"cc",component:FooterComponent}]}
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule.forChild(coreRoutes),
		SsiFrameworkModule,
		// SsiIdentityModule,
		SsiNotificationModule,
		// SsiFileModule,
		IgxNavigationDrawerModule,
		IgxIconModule,
		IgxInputGroupModule,
		IgxButtonModule,
		IgxGridModule,
		IgxAvatarModule,
		IgxListModule,
		IgxComboModule,
		IgxExpansionPanelModule,
		CodeInputModule
		// GeneralModule
	],
	declarations: [
		LayoutComponent,
		HeaderComponent,
		MainMenuComponent,
		HeaderActionComponent,
		FooterComponent,
		MainContentComponent,
		SecondaryActionComponent,
		AuthLayoutComponent,
		AuthContentComponent,
		LoginComponent,
		ForgetPasswordComponent,
		ResetPasswordComponent,
		SignupComponent,
		LoginLayoutComponent,
		InappNotificationsComponent,
		DeactivateUserLayoutComponent,
		DeactivateUserComponent,
		SignupExternalComponent,
		SignupExternalLayoutComponent,
		LogoutAzureComponent,
		// MasterFilterComponent,
		// CustomInappNotificationComponent,
	],
	providers: [UserService, CandidateService, SharedMessagingKeys, OrganizationCreateService],
	entryComponents: [],
	exports: [LayoutComponent, AuthLayoutComponent],
})
export class SharedModule {}
