<div class="main-content-holder row">
    <div class="col p0">
        <igx-grid #FWNotificationGrid id="FWNotificationGrid" ssiGrid [actionItems]="items" [enableDefault]="true">
            <igx-column field="SubjectTemplate" header="{{'SUBJECT_LBL' | ssiTranslator }}">
            </igx-column>
            <igx-column field="MessageTemplate" header="{{'MSG_LBL' | ssiTranslator }}"></igx-column>
            <igx-column field="NotificationStatus" header="{{'STATUS_LBL' | ssiTranslator }}">
                <ng-template igxCell let-value>
                    {{ firstCap(value) }}
                </ng-template>
            </igx-column>
            <igx-column field="CreatedBy" header="{{'CREATED_BY_LBL' | ssiTranslator }}"></igx-column>
            <igx-column field="CreatedOn" header="{{'CREATED_ON_LBL' | ssiTranslator }}">
                <ng-template igxCell let-value>
                    {{ value | ssiDate :"datetime" : true}}
                </ng-template>
            </igx-column>
            <igx-column></igx-column>

            <ng-template igxToolbarCustomContent let-igxGrid="grid">
                <div class="action-area">
                    <ul class="btn-action-list">
                        <li>
                        </li>
                    </ul>
                </div>

                <lib-ssi-filter #ssiGridFilter class="filter" (search)="onApplyFilter($event)"
                    (saveAndSearch)="onSaveAndApplyFilter()" (resetSearch)="onResetFilter()">
                    <div class="ssi-TwoColForm">
                        <div class="row">
                            <div class="col control-holder">
                                <igx-input-group>
                                    <label igxLabel for="subject">{{'SUBJECT_LBL' | ssiTranslator }}</label>
                                    <input igxInput name="subject" type="text" autocomplete="none"/>
                                </igx-input-group>
                            </div>
                            <div class="col control-holder">
                                <lib-ssi-select label="{{'STATUS_LBL' | ssiTranslator }}" name="statuses"
                                    [items]="statuses" itemKey="Value" itemText="Text">
                                </lib-ssi-select>
                            </div>
                        </div>
                    </div>
                </lib-ssi-filter>
            </ng-template>
        </igx-grid>
    </div>
</div>

<lib-view-notification #viewNotificationComponent [viewDetailTemplate]="viewDetailTemplate"
  [selectedNotification]="selectedNotification"></lib-view-notification>