import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserContextService, UtilityService, PermissionManagerService, CommonMessageKeys, ContractHttpResponse } from 'ssi-framework';
import { UserManagementService } from '../../../Services/usermanagement.service';

@Component({
  selector: 'ssi-identity-user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.scss']
})
export class UserPasswordComponent implements OnInit {
  private userId: number;
  private loginname: string;
  public isSelf = false;
  public oldPassword = '';
  public password = '';
  public confirmPassword = '';
  @Input() inputUserId = 0;
  constructor(
    private route: ActivatedRoute,
    private userContextService: UserContextService,
    private userService: UserManagementService,
    private utilityService: UtilityService,
    public permissionMangerService: PermissionManagerService,
    private commonMessageKeys: CommonMessageKeys) { }


  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      // this.userId = +params['id'];
      const idKey = 'id';
      this.userId = this.inputUserId > 0 ? this.inputUserId : +params[idKey];
      this.isSelf = (this.userId === this.userContextService.userId);
      this.loginname=this.userContextService.userName;
    });
  }

  onSave() {
    if(this.isSelf){
      this.changeMyPassword();
    }
    else{
      this.changePassword();
    }
  }

  changePassword(){
    if(this.validate())
    {
      this.userService.changePassword(this.loginname, this.password, this.confirmPassword)
      .then((response: ContractHttpResponse<any>) => {
        if (response.Success === true) {
          this.oldPassword = '';
          this.password = '';
          this.confirmPassword = '';
          this.utilityService.displaySuccessMessage('Password changed successfully.');
        }
        else{
          this.utilityService.displayErrorMessage(response.Message);
        }
      },
        (error) => {
          if (error.error) {
            this.utilityService.displayErrorMessage(error.error.Message);
          } else {
            this.utilityService.displayErrorMessage(error.message);
          }
        }
      );
    }
  }

  validate()
  {
    if ((this.password === '' || this.password == null || this.confirmPassword === '' || this.confirmPassword == null)) {
      this.utilityService.displayErrorMessage("You cannot add previous five passwords. Please try new password.");
      return false;
    }
    else
    {
      return true;
    }
  }

  changeMyPassword(){
    this.userService.changeMyPassword(this.userId, this.oldPassword, this.password, this.confirmPassword)
    .then((response: ContractHttpResponse<any>) => {
      if (response.Success === true) {
        this.oldPassword = '';
        this.password = '';
        this.confirmPassword = '';
        this.utilityService.displaySuccessMessage('Password changed successfully.');
      }
      else{
        if (response.Message == '[IDENTITY_PWD_NON_REPEATING]') {
          this.utilityService.displayErrorMessage("You cannot add previous five passwords. Please try new password.");
        } else {
          this.utilityService.displayErrorMessage(response.Message);
        }
      }
    },
      (error) => {
        if (error.error) {
          this.utilityService.displayErrorMessage(error.error.Message);
        } else {
          this.utilityService.displayErrorMessage(error.message);
        }
      }
    );

  }

  onCancel() {
    this.oldPassword = '';
    this.password = '';
    this.confirmPassword = '';
  }

}