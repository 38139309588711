import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { IgxInputDirective, IgxInputGroupType } from '@infragistics/igniteui-angular';

@Component({
  selector: 'lib-ssi-input',
  templateUrl: './ssi-input.component.html',
  styleUrls: ['./ssi-input.component.scss']
})
export class SsiInputComponent implements OnInit {
  // Inputs
  @Input() type: string;
  @Input() groupType: IgxInputGroupType = 'line';
  @Input() mask: string;
  @Input() pattern: string;
  @Input() labelOutsideBox = false;
  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() name: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() index: number;
  @Input() maxlength: number;
  @Input() hint: string;
  @Input() autocomplete: string = 'off';
  @Input() showCharacterCount = false;
  @Input() moveCursorToStart = false;
  @Input() selectOnFocus: boolean;
  @Input() isFocused: boolean = false;

  // Outputs
  @Output() private modelChange = new EventEmitter();
  @Output() onKeyUp: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() onKeyDown: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() onChanged: EventEmitter<Event> = new EventEmitter();

  // NgModel
  @Input() get model() {
    return this.modelValue;
  }
  set model(val) {
    this.modelValue = val;
    this.modelChange.emit(this.modelValue);
  }

  // Private
  private allowedTypes = ['text', 'password', 'email', 'hidden', 'url', 'tel']
  private modelValue: any;

  // Public
  customError: string;
  _type: string;

  // Reference
  @ViewChild('input', { static: false }) input: NgModel;
  @ViewChild('inputRef', { static: false }) inputRef: ElementRef;
  @ViewChild(IgxInputDirective, { static: false }) public igxInput: IgxInputDirective;

  constructor() { }

  ngOnInit(): void {
    this.setType();
    this.setPatterns();
    this.setTextSelection();
  }

  //#region Public Methods
  setError(error: string) {
    this.customError = error;
  }

  clearError() {
    this.customError = '';
  }

  onInputKeyDown(event: KeyboardEvent) {
    this.onKeyDown.emit(event);
  }

  onInputKeyUp(event: KeyboardEvent) {
    this.onKeyUp.emit(event);
  }

  onInputChanged(event: Event) {
    this.onChanged.emit(event);
  }

  onFocus(event: FocusEvent) {
    if (this.moveCursorToStart && !this.model) {
      setTimeout(() => {
        this.inputRef.nativeElement.setSelectionRange(0, 0);
      }, 0);
    }
  }

  reset() {
    this.input.control.reset();
  }
  //#endregion

  //#region Private Methods
  private setType() {
    this._type = this.type;

    if (!this.allowedTypes.includes(this.type)) {
      this.type = 'text'
    }
  }

  private setPatterns() {
    if (this.pattern) return

    if (this._type == 'email') {
      this.pattern = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    } else if (this._type == 'url') {
      this.pattern = 'http(s?)(:\/\/)((www.)?)(([^.]+)\.)?([a-zA-z0-9\-_]+)(.{1,})(\/[^\s]*)?';
    }
  }

  private setTextSelection() {
    if (this.mask) {
      if (this.selectOnFocus == null || this.selectOnFocus == undefined) {
        this.selectOnFocus = true;
      }
    } else {
      if (this.selectOnFocus == null || this.selectOnFocus == undefined) {
        this.selectOnFocus = false;
      }
    }
  }
  //#endregion

}
