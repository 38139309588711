import { SortingDirection } from 'igniteui-angular';
import { ActivatedRoute } from '@angular/router';
import { IOrderColumn } from '../../Infrastructure/Models/order-column.model';

export abstract class BaseApiService<T> {
    public abstract registerRoutes(route: ActivatedRoute);
    public abstract get(id: number): Promise<any>;
    public abstract getPaged(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any, orderColumns?: IOrderColumn[]): Promise<any>;
    public abstract getAll(): Promise<any>;
    public abstract validate(obj: T): boolean;
    public abstract saveOrUpdate(obj: T): Promise<any>;
    public abstract delete(id: number): Promise<any>;
}
