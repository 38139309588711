import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgModel } from '@angular/forms';
import { DateRangeDescriptor, DateRangeType, IgxDatePickerComponent, IgxInputDirective } from '@infragistics/igniteui-angular';
import * as moment from 'moment';
import { DateRangeTypeEnum, Weekdays, Weekends } from '../../../Common/constants';

@Component({
  selector: 'lib-ssi-datepicker',
  templateUrl: './ssi-datepicker.component.html',
  styleUrls: ['./ssi-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SsiDatepickerComponent implements OnInit, AfterViewInit {

  @Input() label: string = "";
  @Input() name: string = "";
  @Input() id: string = "";
  @Input() index: number;
  @Input() placeholder: string = this._environment.DateFormats.date;
  @Input() required: boolean = false;
  @Input() editable = true;
  @Input() mode: string = 'dropdown';
  @Input() disabled: boolean = false;
  @Input() mask: string = "00/00/0000";
  @Input() dateRangeType: string = DateRangeTypeEnum.None;
  @Input() disableDateFrom: Date;
  @Input() disableDateTo: Date;
  @Input() specificDatesArray: Date[] = [];
  @Input() DisableDates: DateRangeDescriptor[] = [];
  @Input() selectOnFocus: boolean = true;
  @Input() moveCursorToStart = false;
  @Input() isFocused = false;


  @Output() dateSelected: EventEmitter<any> = new EventEmitter();

  @ViewChild('dropDownTarget', { static: false }) dropDownTarget: IgxInputDirective;
  @ViewChild(IgxDatePickerComponent, { static: false }) datePickerComponent: IgxDatePickerComponent;
  @ViewChild('picker', { static: false }) picker: NgModel;
  @ViewChild('input', { static: false }) input: NgModel;

  // public
  errorMessage: string = "";
  inputModel: string;
  isPickerDialogOpenWithCalenderIcon: boolean = false;

  private disableDates: DateRangeDescriptor[] = [];

  constructor(@Inject('EnvironmentVariables') private _environment: any) { }

  @Input() get model() {
    return this.modelValue;
  }

  @Output() modelChange = new EventEmitter();
  private modelValue: Date;
  set model(val) {
    this.modelValue = val;
    setTimeout(() => {
      this.inputModel = this.dropDownTarget?.nativeElement?.value;
    });
    this.modelChange.emit(this.modelValue);
  }

  ngOnInit(): void {

    if (this.dateRangeType !== DateRangeTypeEnum.None) {

      if (this.dateRangeType == DateRangeTypeEnum.Weekdays) {
        this.DisableDates = [{ type: DateRangeType.Weekdays, dateRange: null }];

      } else if (this.dateRangeType == DateRangeTypeEnum.Weekends) {
        this.DisableDates = [{ type: DateRangeType.Weekends, dateRange: null }];

      } else if (this.dateRangeType == DateRangeTypeEnum.Between) {
        this.DisableDates = [{ type: DateRangeType.Between, dateRange: [this.disableDateFrom, this.disableDateTo] }];

      } else if (this.dateRangeType == DateRangeTypeEnum.Specific) {
        this.DisableDates = [{ type: DateRangeType.Specific, dateRange: this.specificDatesArray }];
      }
    }
  }

  ngAfterViewInit(): void {
    this.setDateByModel();
    this.datePickerComponent.onOpened.subscribe((event) => {
      this.updateInputState('markAsUntouched');
    });

    this.datePickerComponent.onClosing.subscribe((event) => {
      this.updateInputState('markAsUntouched');
    });
  }

  public onDateSelection(value: any) {
    let date = new Date(value);
    var isValid = date instanceof Date && !isNaN(date.valueOf());
    this.disableDates = [];
    if (isValid) {
      if (this.dateRangeType !== DateRangeTypeEnum.None) {
        this.calculateDisableDates(date);
      } else {
        this.dateSelected.emit(date);
      }
    } else if (value !== "" && !isValid) {
      this.datePickerComponent.deselectDate()
      this.dateSelected.emit(null);
      this.dropDownTarget.nativeElement.value = null;
    } else if (value === "" && !isValid && !this.isPickerDialogOpenWithCalenderIcon) {
      this.datePickerComponent.deselectDate();
      this.dateSelected.emit(undefined);
    }
    this.isPickerDialogOpenWithCalenderIcon = false;
  }

  public showError(error) {
    this.errorMessage = error;
  }

  public clearError() {
    this.errorMessage = "";
  }

  public onFocus(event: FocusEvent) {
    if (this.moveCursorToStart && !this.model) {
      setTimeout(() => {
        this.dropDownTarget.nativeElement.setSelectionRange(0, 0);
      }, 0);
    }
  }

  public getDisabledDates(): DateRangeDescriptor[] {
    return this.disableDates;
  }

  private checkDateFromDateRange(dateFrom, dateTo, date) {
    let fromDate, toDate, checkDate;
    fromDate = Date.parse(dateFrom);
    toDate = Date.parse(dateTo);
    checkDate = Date.parse(date);
    if ((checkDate <= toDate && checkDate >= fromDate)) return true
    return false;
  }

  private calculateDisableDates(date) {
    if (this.dateRangeType == DateRangeTypeEnum.After) {
      this.calculateDateForAfterRangeType(date)
    } else if (this.dateRangeType == DateRangeTypeEnum.Before) {
      this.calculateDateForBeforeRangeType(date)
    } else if (this.dateRangeType == DateRangeTypeEnum.Weekdays) {
      this.calculateDateForWeekdayRangeType(date);
    } else if (this.dateRangeType == DateRangeTypeEnum.Weekends) {
      this.calculateDateForWeekendRangeType(date);
    } else if (this.dateRangeType == DateRangeTypeEnum.Between) {
      this.calculateDateForBetweenRangeType(date);
    } else if (this.dateRangeType == DateRangeTypeEnum.Specific) {
      this.calculateDateForSpecificRangeType(date)
    }
  }

  private calculateDateForBeforeRangeType(date: any) {
    this.disableDates = [{ type: DateRangeType.Before, dateRange: [date] },
    { type: DateRangeType.After, dateRange: [moment().add(1000, 'years').toDate()] }];
    this.dateSelected.emit(date);
  }

  private calculateDateForAfterRangeType(date: any) {
    this.disableDates = [{ type: DateRangeType.Before, dateRange: [moment().subtract(1000, 'years').toDate()] },
    { type: DateRangeType.After, dateRange: [date] }];
    this.dateSelected.emit(date);
  }

  private calculateDateForWeekdayRangeType(date) {
    this.disableDates = [{ type: DateRangeType.Weekdays, dateRange: null }];
    Weekdays[date.getDay()] ? this.setDisableDate(null) : this.setDisableDate(date);
  }

  private calculateDateForWeekendRangeType(date) {
    this.disableDates = [{ type: DateRangeType.Weekends, dateRange: null }];
    Weekends[date.getDay()] ? this.setDisableDate(null) : this.setDisableDate(date);
  }

  private calculateDateForBetweenRangeType(date) {
    this.disableDates = [{ type: DateRangeType.Between, dateRange: [this.disableDateFrom, this.disableDateTo] }];
    this.checkDateFromDateRange(this.disableDateFrom, this.disableDateTo, date) ? this.setDisableDate(null) : this.setDisableDate(date);
  }

  private calculateDateForSpecificRangeType(date) {
    this.disableDates = [{ type: DateRangeType.Specific, dateRange: this.specificDatesArray }];
    let findDate = this.specificDatesArray.find((x) => x.getDay() == date.getDay() && x.getMonth() == date.getMonth() && x.getFullYear() == date.getFullYear());
    !!findDate ? this.setDisableDate(null) : this.setDisableDate(date);
  }

  private setDisableDate(date) {
    !!date ? this.dateSelected.emit(date) :
      this.dateSelected.emit(null);
    this.dropDownTarget.nativeElement.value = null;
    this.model = null;
  }

  private setDateByModel() {
    if (!!this.model) {
      let date = new Date(this.model);
      var isValid = date instanceof Date && !isNaN(date.valueOf());
      if (isValid) {
        this.onDateSelection(date);
      }
    }
  }

  private updateInputState(state: string) {
    this.isPickerDialogOpenWithCalenderIcon = true;
    this.input.control[state]();
    this.input.control.updateValueAndValidity();
    this.picker.control.markAsUntouched();
    this.picker.control.updateValueAndValidity();
  }

  reset() {
    this.input.control.reset();
  }

}
