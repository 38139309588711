import { Component, ViewContainerRef, ViewChild, Injector } from '@angular/core';
import { ContractHttpResponse, BaseAEComponent, RouteHandlerService, LayoutService, UtilityService, CommonMessageKeys,
   PermissionManagerService, UserContextService, SsiTranslatorPipe } from 'ssi-framework';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationSubscription } from '../../models/notification-subscription';
import { NotificationConfigurationService } from '../../services/notification-configuration.service';
import { User } from 'ssi-identity';
import { IgxGridComponent, ISortingExpression, SortingDirection, DefaultSortingStrategy } from 'igniteui-angular';

@Component({
  selector: 'lib-ssi-notification-subscription-ae',
  templateUrl: './notification-subscription-ae.component.html',
  styleUrls: ['./notification-subscription-ae.component.scss']
})
export class NotificationSubscriptionAEComponent extends BaseAEComponent<NotificationSubscription[]> {
  @ViewChild('FWNewUsersGrid', {static: false}) public newUsersGrid: IgxGridComponent;
  @ViewChild('FWNotificationSubscriptionGrid', {static: false}) public subscriptionsGrid: IgxGridComponent;

  model: NotificationSubscription[] = [];
  public eventConfigID: number;
  public newUsers: NotificationSubscription[] = [];
  public expr: ISortingExpression[];
  private _ssiTranslatorPipe : SsiTranslatorPipe;
  public selectedSource: NotificationSubscription [] = [];
  public selectedDestination: NotificationSubscription [] = [];

  constructor(layoutService: LayoutService,private _injector: Injector,route: ActivatedRoute, router: Router,
     routeHandlerService: RouteHandlerService, public permissionMangerService: PermissionManagerService,
     private notificationConfigurationService: NotificationConfigurationService, viewRef: ViewContainerRef,
     utilityService: UtilityService, commonMessageKeys: CommonMessageKeys, public userContextService: UserContextService) {
    super(route, router, routeHandlerService, viewRef, utilityService, commonMessageKeys);
    const valueKey = 'value';
    this.eventConfigID = route.params[valueKey].id;
    this._ssiTranslatorPipe = new SsiTranslatorPipe(_injector);
    const title = this._ssiTranslatorPipe.transform('SUBSCRIPTION_AE_TITLE');
    layoutService.setTitle(title);
   }

   get(id: number){
     return this.notificationConfigurationService.getSubscriptions(id);
   }

   onBeforeAddEdit(){
   }

   onAfterAddEdit(obj: NotificationSubscription[]){
    const callback = this.notificationConfigurationService.getAllUsers().
      then((response: ContractHttpResponse<User[]>) => {
      if(response.Success){
        response.Source.forEach(element => {
          if(this.model !== undefined && this.model.length > 0){
            const isUserSubscribed = this.model.filter(cls => cls.LoginName === element.LoginName);
            if(isUserSubscribed.length === 0){
              const user = new NotificationSubscription();
              user.ID = element.ID
              user.EventConfigID = this.eventConfigID;
              user.LoginName = element.LoginName;
              this.newUsersGrid.addRow(user);
            }
          }
          else{
            const user = new NotificationSubscription();
            user.ID = element.ID
            user.EventConfigID = this.eventConfigID;
            user.LoginName = element.LoginName;
            this.newUsersGrid.addRow(user);
          }
        });
      }
      });     
   }

   validate(): boolean{
     return true;
   }

   saveInternal(vm: NotificationSubscription[]): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const data = this.subscriptionsGrid.data;
      this.notificationConfigurationService.saveSubscriptions(this.eventConfigID, data)
      .then((response: ContractHttpResponse<boolean>) => {
        if(response.Success){
          resolve(response);
        }
        else{
          resolve(true);
        }
      });
    });
  }
  setGridExpression(){
    this.expr = [
      { dir: SortingDirection.Asc, fieldName: 'LoginName', ignoreCase: false,
        strategy: DefaultSortingStrategy.instance() },
     ];
  }

  sourceSelection(event, row) {
       row.Selected = event.checked
      const selectedLogin = row.LoginName;
      if (!!this.selectedSource && this.selectedSource.length > 0) {
        let copyselectedSource: any = [];
        copyselectedSource = this.selectedSource?.filter(cls => cls.LoginName === selectedLogin)[0];
        if (copyselectedSource == undefined) {
          this.selectedSource.push(this.newUsersGrid.data.filter(cls => cls.LoginName === selectedLogin)[0]);
        
        } else if (!!copyselectedSource) {
          for (let index = 0; index < this.selectedSource.length; index++) {
            const element = this.selectedSource[index];
            if (element.LoginName == copyselectedSource.LoginName) {
              this.selectedSource.splice(index, 1)
            }
          }
        }
      } else {
      
        this.selectedSource.push(this.newUsersGrid.data.filter(cls => cls.LoginName === selectedLogin)[0]);
        
      }

      console.log('source',this.selectedSource, 'Grid', this.newUsersGrid.data);
      
  }

  destinationSelection(event, row) {
    row.Selected = event.checked
      const selectedLogin = row.LoginName;
      if (!!this.selectedDestination && this.selectedDestination.length > 0) {

        let copyselectedDestination: any = [];
        copyselectedDestination = this.selectedDestination?.filter(cls => cls.LoginName === selectedLogin)[0];

        if (copyselectedDestination == undefined) {
          this.selectedDestination.push(this.subscriptionsGrid.data.filter(cls => cls.LoginName === selectedLogin)[0]);

        } else if (!!copyselectedDestination) {

          for (let index = 0; index < this.selectedDestination.length; index++) {
            const element = this.selectedDestination[index];

            if (element.LoginName == copyselectedDestination.LoginName) {
              this.selectedDestination.splice(index, 1)
            }

          }
        }
      } else {
        this.selectedDestination.push(this.subscriptionsGrid.data.filter(cls => cls.LoginName === selectedLogin)[0]);
      }
    
  }

  public moveFromSource() {
    if (!!this.selectedSource && this.selectedSource.length) {
      this.selectedSource.forEach(element => {
        this.subscriptionsGrid.addRow({
          ID: element.ID, EventConfigID: element.EventConfigID,
          EventConfigName: element.EventConfigName, EventConfigCode: element.EventConfigCode,
          EventConfigMode: element.EventConfigMode, LoginName: element.LoginName,
          Selected: false
        })
      });

      setTimeout(() => {
        this.selectedSource.forEach(element => {
          this.newUsersGrid.deleteRow(element.LoginName)
        });
        this.selectedSource = [];
      }, 50)
    }

  }

  public moveFromDestination() {
    if (!!this.selectedDestination && this.selectedDestination.length) {

      this.selectedDestination.forEach(element => {
        this.newUsersGrid.addRow({
          ID: element.ID, EventConfigID: element.EventConfigID,
          EventConfigName: element.EventConfigName, EventConfigCode: element.EventConfigCode,
          EventConfigMode: element.EventConfigMode, LoginName: element.LoginName,
          Selected: false
        })
      });

      setTimeout(() => {
        this.selectedDestination.forEach(element => {
          this.subscriptionsGrid.deleteRow(element.LoginName)
        });
        this.selectedDestination = [];
      }, 50);
    }
  }

  public moveAllFromSource(){
    const gridData = this.newUsersGrid.data;
    this.newUsers = [];
    gridData.forEach(element => {
      this.subscriptionsGrid.addRow(element);
    });
  }

  public moveAllFromDestination(){
    const gridData = this.subscriptionsGrid.data;
    this.model = [];
    gridData.forEach(element => {
      this.newUsersGrid.addRow(element);
    });
  }

  setectedItem(value){
   console.log(value);
   
  }
}
