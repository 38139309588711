import { Directive, HostListener, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[ssiCursorPosition]'
})
export class SsiCursorPositionDirective {

  constructor(private _view : ViewContainerRef) { }
  @HostListener('focus', ['$event'])
  focus(event) {
    const value = this._view.element.nativeElement.value
    if(value){
      const numericDigits = value.split('').filter(x => !isNaN(parseInt(x, 10)));
      const lastNumericDigit = numericDigits ? numericDigits[numericDigits.length - 1] : null;
      const lastNumericDigitIndex = lastNumericDigit ? value.lastIndexOf(lastNumericDigit) : -1;

      let validLength = 0;
      if(lastNumericDigitIndex && lastNumericDigitIndex > -1){
        // substr does not include index itself
        validLength = value.substr(0, lastNumericDigitIndex + 1).length;
      }
      this._view.element.nativeElement.setSelectionRange(validLength, validLength);
    }
  }
}
