import { Component, OnInit, ViewChild } from '@angular/core';
import { SsiLoaderComponent } from 'ssi-framework';
import { PROGRESS_STATUS } from '../../login-layout/login/login.component';

@Component({
	selector: 'app-signup-external-layout',
	templateUrl: './signup-external-layout.component.html',
	styleUrls: ['./signup-external-layout.component.scss']
})
export class SignupExternalLayoutComponent {

	public isProgressbarHidden = true;

	constructor() { }

	public getBrowserName() {
		const agent = window.navigator.userAgent.toLowerCase();
		switch (true) {
			case agent.indexOf('edge') > -1:
				return false;
			case agent.indexOf('edg') > -1:
				return false;
			case agent.indexOf('opr') > -1 && !!(<any>window).opr:
				return false;
			case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
				return true;
			default:
				return false;
		}
	}


	showProgressBar() {
		this.isProgressbarHidden = false;
	}

	hideProgressBar() {
		this.isProgressbarHidden = true;
	}

	handleProgressStatusChange(event) {

		if (event == PROGRESS_STATUS.PROGRESS_START) {
			this.showProgressBar();
		} else if (event == PROGRESS_STATUS.PROGRESS_END) {
			this.hideProgressBar();
		}
	}
}
