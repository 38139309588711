<div class="main-content-holder row">
  <div class=" col p0">
    <igx-grid #FWUserGrid id="FWUserGrid" ssiGrid [actionItems]="items" [enableDefault]="true">
      <igx-column field="LoginName" header="{{'LOGIN_LBL' | ssiTranslator }}">
        <ng-template igxCell let-val let-cell="cell">
          <a href="Javascript:void(0)" *ssiPermission="'EDIT_USER'; else noPermissionTemplate"
            [routerLink]="['Edit', cell.cellID.rowID.ID]">
            {{ val }}
          </a>
          <ng-template #noPermissionTemplate>
            {{ val }}
          </ng-template>
        </ng-template>
      </igx-column>
      <igx-column field="Name" header="{{'NAME_LBL' | ssiTranslator }}"></igx-column>
      <!-- <igx-column field="CompanyName" header="Company" [hidden]="true"></igx-column>
                <igx-column field="TeamName" header="Team" [hidden]="true"></igx-column> -->
      <igx-column field="Email" header="Email">
      </igx-column>
      <igx-column field="IsActive" header="{{'ACTIVE_LBL' | ssiTranslator }}" [headerClasses]="'column-center'"
        [cellClasses]="{'column-center' : true }">
        <ng-template igxCell let-value>
          <span *ngIf="value" class="icon icon-check-circle static"></span>
        </ng-template>
      </igx-column>
      <igx-column field="LastLoggedInOn" header="{{'LAST_LOG_ON_LBL' | ssiTranslator }}">
        <ng-template igxCell let-value>
          {{ value | ssiDate :"datetime" : true }}
        </ng-template>
      </igx-column>
      <igx-column field="CreatedBy" header="{{'CREATED_BY_LBL' | ssiTranslator }}">
      </igx-column>
      <igx-column field="CreatedOn" header="{{'CREATED_ON_LBL' | ssiTranslator }}">
        <ng-template igxCell let-value>
          {{ value | ssiDate :"datetime" : true}}
        </ng-template>
      </igx-column>
      <igx-column field="ModifiedBy" header="{{'MODIFIED_BY_LBL' | ssiTranslator }}" [hidden]="true">
      </igx-column>
      <igx-column field="ModifiedOn" header="{{'MODIFIED_ON_LBL' | ssiTranslator }}" [hidden]="true">
        <ng-template igxCell let-value>
          {{ value | ssiDate :"datetime" : true }}
        </ng-template>
      </igx-column>
      <igx-column></igx-column>

      <ng-template igxToolbarCustomContent let-igxGrid="grid">
        <div class="action-area">
          <ul class="btn-action-list">
            <li>
              <a [routerLink]="['/Users/Add']">
                <button ssiTooltip="Add User" igxButton="flat" igxRipple class="btn-primary"
                  *ngIf="permissionMangerService.checkPermission('ADD_USER')">
                  <span class="icon icon-add-btn"></span>
                  {{'ADD_BTN' | ssiTranslator }}
                </button>
              </a>
            </li>
          </ul>
        </div>

        <lib-ssi-filter #ssiGridFilter class="filter" (search)="onApplyFilter($event)" 
          (saveAndSearch)="onSaveAndApplyFilter($event)" (resetSearch)="onResetFilter()">
          <div class="ssi-ThreeColForm">
            <div class="row"> 
              <div class="col control-holder">
                <igx-input-group>
                  <label igxLabel for="loginName">{{'LOGIN_LBL' | ssiTranslator }}</label>
                  <input igxInput name="loginName" type="text" autocomplete="none" />
                </igx-input-group>
              </div>
              <div class="col control-holder">
                <igx-input-group>
                  <label igxLabel for="name">{{'NAME_LBL' | ssiTranslator }}</label>
                  <input igxInput name="name" type="text" autocomplete="none" />
                </igx-input-group>
              </div>
              <div class="col control-holder">
                <igx-input-group>
                  <label igxLabel for="email">{{'EMAIL_LBL' | ssiTranslator }}</label>
                  <input igxInput name="email" type="email" autocomplete="none" />
                </igx-input-group>
              </div>
              <div class="col control-holder">
                <lib-ssi-select label="{{'ROLES_LBL' | ssiTranslator }}" name="roles" [items]="roles" itemKey="ID"
                  itemText="Name">
                </lib-ssi-select>
              </div>
              <div class="col control-holder">
                <lib-ssi-select label="{{'COMPANY_LBL' | ssiTranslator }}" name="companyId" [items]="companies"
                  itemKey="ID" itemText="Name" [singleSelect]="true">
                </lib-ssi-select>
              </div>
            </div>
          </div>
        </lib-ssi-filter>
      </ng-template>
    </igx-grid>
  </div>
</div>