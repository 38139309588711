import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SortingDirection } from '@infragistics/igniteui-angular';
import {
	WebApiProxyFactoryService,
	IWebApiProxy,
	BaseApiService,
	ContractHttpResponse,
	PagedList,
  IOrderColumn,
} from 'ssi-framework';
import { UserProfileDetail } from '../models/user-profile-detail.model';
import { environment } from 'src/environments/environment';
import { UserProfile } from '../models/user-profile.model';

@Injectable({
  providedIn: 'root'
})
export class MasterUserProfileService extends BaseApiService<UserProfileDetail> {
  registerRoutes(route: ActivatedRoute) {
    throw new Error('Method not implemented.');
  }
  get(id: number): Promise<any> {
    throw new Error('Method not implemented.');
  }
  getPaged(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any, orderColumns?: IOrderColumn[]): Promise<any> {
    throw new Error('Method not implemented.');
  }
  getAll(): Promise<any> {
    throw new Error('Method not implemented.');
  }
  validate(obj: UserProfileDetail): boolean {
    throw new Error('Method not implemented.');
  }
  saveOrUpdate(obj: UserProfileDetail): Promise<any> {
    throw new Error('Method not implemented.');
  }
  delete(id: number): Promise<any> {
    throw new Error('Method not implemented.');
  }
	private readonly proxy: IWebApiProxy;

	constructor(private proxyFactory: WebApiProxyFactoryService) {
		super();
		this.proxy = this.proxyFactory.GetProxyByApp(environment.ApplicationCode, 'UserProfileMaster');
	}

  public is2faEnabled(userProfileDetail:UserProfileDetail):Promise<ContractHttpResponse<boolean>>{
			return this.proxy.Get("isTwoFaEnabled",userProfileDetail);
	}


  public change2faStatus(userProfileDetail:UserProfileDetail):Promise<ContractHttpResponse<boolean>>{
    return this.proxy.Post("CreateUpdate2FAAuthentication",userProfileDetail);
  }


}





















