import { Router, ActivatedRoute, Params } from '@angular/router';
import { RouteHandlerService } from '../Routing/route-handler.service';
import { ContractHttpResponse } from '../../WebApiClient/HttpHandler/contract-http-response';
import {  OnInit, ViewContainerRef, Directive } from '@angular/core';
import { UtilityService } from '../../Common/utility.service';
import { CommonMessageKeys } from '../../Common/common-message-keys';
@Directive()
export abstract class BaseAEComponent<T> implements OnInit {
    public abstract model: T;
    public abstract get(id: number): Promise<any>;
    public abstract onBeforeAddEdit();
    public abstract onAfterAddEdit(obj: T);
    public abstract validate(obj: T): boolean;
    public abstract saveInternal(obj: T): Promise<any>;

    constructor(protected route: ActivatedRoute,
        protected router: Router,
        protected routeHandlerService: RouteHandlerService,
        protected viewRef: ViewContainerRef,
        protected utilityService: UtilityService,
        protected commonMessageKeys: CommonMessageKeys) {
    }

    ngOnInit() {
        const promise = this.onBeforeAddEdit();
        if (promise === undefined) {
            this.init();
        } else {
            (promise as Promise<any>).then((n) => {
                this.init();
            });
        }
    }

    private handleGet(id) {
        if (id) {
            const response = this.get(id);
            if (response) {
                response.then((resp: ContractHttpResponse<T>) => {
                    if (resp.Success === true) {
                        this.model = resp.Source;
                        this.onAfterAddEdit(resp.Source);
                    }
                });
            }
        }
    }

    private init() {
        this.route.params.subscribe((params: Params) => {
            const key = 'id';
            const id = params[key];
            if (id) {
                this.handleGet(id);
            } else if (this.route.parent && this.route.parent.params) {
                this.route.parent.params.subscribe((parentParams) => {
                    const parentId = parentParams[key];
                    if (parentId) {
                        this.handleGet(parentId);
                    }
                });
            }
        });
    }

    public onSave(redirect: boolean = true, redirectUrl?: string) {
        if (this.validate(this.model)) {
            const request = this.saveInternal(this.model);
            request.then((response: ContractHttpResponse<any>) => {
                let goToUrl = redirectUrl;
                if (redirect && goToUrl == null) {
                    goToUrl = this.routeHandlerService.getPreviousUrl();
                }
                if (response.Success === true) {
                    this.utilityService.displaySuccessMessage(this.commonMessageKeys.RECORD_SAVED_SUCCESSFULLY);
                    if(redirect === true){
                        this.router.navigateByUrl(goToUrl);
                    }
                    else
                    {
                        setTimeout(() => 
                        {
                            document.querySelectorAll(".igx-input-group--invalid").forEach((key) => 
                            {
                                key.classList.remove("igx-input-group--invalid");
                            });   
                        }, 300);
                    }
                } else {
                    this.utilityService.displayErrorMessage(response.Message);
                }
            }, (error) => {
                if (error.error) {
                    this.utilityService.displayErrorMessage(error.error.Message);
                } else {
                    this.utilityService.displayErrorMessage(error.message);
                }
            });
        }
        else {
        }
    }

    public onCancel(redirectUrl?: string) {
        if(redirectUrl){
            this.router.navigateByUrl(redirectUrl);
        }else{
            this.router.navigateByUrl(this.routeHandlerService.getPreviousUrl());
        }
    }
}
