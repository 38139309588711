import { Injectable, EventEmitter, Injector, Inject } from '@angular/core';
import { DiscoveryClientService } from '../WebApiClient/Discovery/discovery-client.service';
import { SharedConstant } from './shared-constant';
import { AuthenticateClientService } from '../Authentication/authenticate-client.service';
import { MenuService } from '../WebInfrastructure/Menu/menu.service';
import { MenuItem } from '../WebInfrastructure/Menu/menu.model';
import { ContractHttpResponse } from '../WebApiClient/HttpHandler/contract-http-response';
import { MessageInfoService } from '../WebInfrastructure/Services/message-info.service';
import { MessageInfo } from '../Infrastructure/Models/message-info.model';

@Injectable({
  providedIn: 'root'
})
export class BootstrapperService {

  constructor(private sharedConstant: SharedConstant,
    private discoveryBootstrap: DiscoveryClientService,
    private authClient: AuthenticateClientService,
    private _injector: Injector,
    @Inject('EnvironmentVariables') private _environment: any,
    private menuService: MenuService) {
  }

  Init(environments: any) {
    return new Promise((resolve, reject) => {
      const self = this;

      // initialize constants in framework library from app environments
      self.sharedConstant.InitConstants(environments);

      // initialize discovery service
      self.discoveryBootstrap.Init();

      self.authClient.InitAuthClient().then(n => {

        self.loadConfigsFromLocalStorage();

        // Loading Message Keys
        if (!self.sharedConstant.messageTexts) {
          const messageInfoService = self._injector.get<MessageInfoService>(MessageInfoService);
          messageInfoService.getAllTexts(self._environment.ApplicationCode).then((n: ContractHttpResponse<MessageInfo[]>) => {
            if (n.Success) {
              self.sharedConstant.messageTexts = n.Source;
            }

            self.saveConfigsFromLocalStorage();

            // initialize menu items
            self.initMenu(resolve);
          });
        } else {
          // initialize menu items
          self.initMenu(resolve);
        }

      });

    });
  }

  private initMenu(resolve: any) {
    // intializing menu
    const response = this.menuService.GetMenu();
    if (response instanceof EventEmitter) {
      (response as EventEmitter<MenuItem[]>)
        .subscribe((resp: MenuItem[]) => {
          resolve();
        });
    }
    else {
      resolve();
    }
  }

  private loadConfigsFromLocalStorage() {
    const storedConstants = JSON.parse(localStorage.getItem(this.sharedConstant.localStorageKey));
    if (storedConstants) {
      this.sharedConstant.availableWebApis = storedConstants.availableWebApis;
      this.sharedConstant.messageTexts = storedConstants.messageTexts;
    }
  }

  private saveConfigsFromLocalStorage() {
    if (!JSON.parse(localStorage.getItem(this.sharedConstant.localStorageKey))) {
      const constants: any = {};
      constants.availableWebApis = this.sharedConstant.availableWebApis;
      constants.messageTexts = this.sharedConstant.messageTexts;
      localStorage.setItem(this.sharedConstant.localStorageKey, JSON.stringify(constants));
    }
  }

}