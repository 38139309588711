export class NotificationEvent {
    public ID: number;
    public ApplicationCode: string;
    public Code: string;
    public Name: string;
    public CreatedBy: string;
    public CreatedOn: Date;
    public ModifiedBy: string;
    public ModifiedOn: Date;
    public Email: boolean;
    public SMS: boolean;
    public InApp: boolean;
    public Push: boolean;
    public IsEmailDisabled: boolean;
    public IsSMSDisabled: boolean;
    public IsInAppDisabled: boolean;
    public IsPushDisabled: boolean;
}
