import { Subject } from "rxjs";
import * as signalR from "@microsoft/signalr";


export default abstract class SocketService{


    public hubConnection:signalR.HubConnection;
    private connection_string:string;
    private eventsBeingListened:string[];
    private subject: Subject<any>;
    private isAuthenticated:boolean;
    private sharedConnection:boolean;
  
    private getSocketHubConnection(){
        //must include http:// otherwise gives error
        this.hubConnection = new signalR.HubConnectionBuilder().withUrl(this.connection_string,{
          skipNegotiation:true,
          transport: signalR.HttpTransportType.WebSockets
      }).build();
      return this.hubConnection;
    };
  
    constructor(connection_string:string,isAuthenticated:boolean,sharedConnection:boolean) {
      this.connection_string = connection_string;
      this.isAuthenticated = isAuthenticated;
      this.sharedConnection = sharedConnection;
      this.eventsBeingListened = [];
      //can be later connected to sockethub connection manager
      //This will ensure that if any other component is currently utilizing that connection, it will not close it
      this.hubConnection = this.getSocketHubConnection();
      this.subject = new Subject<any>();
    };

    public startConnection(){
        return new Promise<void>((resolve,reject)=>{
          if(this.isAuthenticated === true){
            reject();
          }
        
          this.hubConnection.start().then(()=>{
              resolve();
          }).catch((err)=>{
              reject();
          });
      
          this.hubConnection.onclose((err?: Error) => {
              if (err) {
                  this.subject.error({event_name:"error",data:err}); 
                } else {
                  this.subject.complete();
              }
              this.subject = new Subject<any>();

          });
        });
    }

    public listenToEvent(event_name){
        if(event_name in this.eventsBeingListened)
          return;
    
        this.eventsBeingListened.push(event_name);
        this.hubConnection.on(event_name,(...args: any[])=>{
          this.subject.next({event_name,data:args});
        });
    }


    public setActionOnReconnection(f:any){
      return this.hubConnection.onreconnected(f);
    }
    
    public getObservable(){
        return this.subject;
    }


    public showAllReceivedDataOnConsole(){
      this.getObservable().subscribe((data)=>{
        console.log(data);
      });
    }
  
  
    public closeConnection(){
          for(let event_name in this.eventsBeingListened){
            this.hubConnection.off(event_name);
          }
          this.hubConnection.stop();
    }
  
}