import { Component, OnInit, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'lib-cal-week-view',
  templateUrl: './week-view.component.html',
  styleUrls: ['./week-view.component.scss']
})
export class WeekViewComponent implements OnInit {

  @Input() weekTemplate: ElementRef;
  @Input() activeDates;
  @Input() tileData: Map<string, any>;
  @Input() displayTileExtensionButton;
  constructor() { }

  ngOnInit() {
  }

  getData(date: Date) {
    if(this.tileData.size){
      return this.tileData.get(date.toDateString());
    }
  }

}
