import { Component, OnInit } from '@angular/core';

@Component({
  selector: '.app-secondary-action',
  templateUrl: './secondary-action.component.html',
  styleUrls: ['./secondary-action.component.scss']
})
export class SecondaryActionComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
