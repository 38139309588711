// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
	SSIFW_Appcode: 'SSIFW',
	Version: '1.0.0',
	ApplicationCode: 'RENAISSANT_PROD',
	IdentityScheme: 'https',
	AppWebClientName: 'WEB.CLIENT.RO',
	AppCCClientName: 'CLIENT.CC',
	FrameworkCCClientName: 'SSIFW.CLIENT.CC',
	DiscoveryConfig: {
		Scheme: 'https',
		Host: 'app-apis.renaissant.com',
		Port: 55333,
		CacheTimeOut: 60,
	},
	IdentityConfig: [
		{
			Name: 'WEB.CLIENT.RO',
			IdentityServiceUrl: 'https://app-apis.renaissant.com:55335',
			Scopes: 'openid profile email idmgr',
			RelyingPartyUrl: 'https://live.renaissant.com/login',
			ApplicationCode: 'RENAISSANT_PROD',
			ClientCode: 'RENAISSANT_PROD.CLIENT.RO',
			ClientSecret: 'g4242890-9632-684c-d77d-5ds9106e6d6k',
			Flow: 'ResourceOwner',
		},
		{
			Name: 'WEB.CLIENT',
			IdentityServiceUrl: 'https://app-apis.renaissant.com:55335',
			Scopes: 'openid profile email permissions idmgr',
			RelyingPartyUrl: 'https://live.renaissant.com/login',
			ApplicationCode: 'RENAISSANT_PROD',
			ClientCode: 'RENAISSANT_PROD.WEB.CLIENT',
			ClientSecret: '165c8d5d-438e-yt01-d186-bxx66eca632d',
			Flow: 'Implicit',
		},
		{
			Name: 'CLIENT.CC',
			IdentityServiceUrl: 'https://app-apis.renaissant.com:55335',
			Scopes: 'permissions',
			ApplicationCode: 'RENAISSANT_PROD',
			ClientCode: 'RENAISSANT_PROD.CLIENT.CC',
			ClientSecret: 'b5730b3c-2896-4f02-ac91-eb284c462a57',
		},
		{
			Name: 'SSIFW.CLIENT.CC',
			IdentityServiceUrl: 'https://app-apis.renaissant.com:55335',
			Scopes: 'permissions',
			ApplicationCode: 'SSIFW',
			ClientCode: 'SSIFW.WEB.CLIENT',
			ClientSecret: '4fccb5b9-45fd-8855-a33d-9b4050b0e872',
		},
	],
	PathsToMenuFile: 'assets/menu-config.xml',
	GridPages: [10, 20, 30, 40, 50],
	DefaultGridPage: 10,
	AppTitle: 'Renaissant',

	DateFormats: {
		datetime: 'MM/DD/YYYY hh:mm A',
		date: 'MM/DD/YYYY',
		time: 'hh:mm A',
		jobdate: 'MM/DD/YYYY ddd',
		dateMonth: 'MM/DD',
	},

	MaxFileSize: 25 * 1024 * 1024, //25 mbs
	AllowedExtensions: ['.jpe', '.jpeg', '.jpg', '.png'],
	AllowAnonymousURLs: [
		'user-response',
		'LoadingDock/drivercheckin',
		'LoadingDock/drivercheckinv2',
		'RateCandidate',
		'ReadOnlyWorkedJobDetail',
		'FAQs',
		'LoadingDock/download-bol-report',
		'loadingdock/drivercheckin',
		'loadingdock/drivercheckinv2',
		'visitor/visitorcheckin',
		'download-attachment',
		'deleteuser',
		'signup-external',
		'backupEmail',
		'resetPassword',
		'LoadingDock/RequestAppointment',
		'logout-azure-ad',
	],
	UnAuthorizedRedirection: 'https://live.renaissant.com/login',
	SignalrEntityChangeEndpoint: 'https://app-apis.renaissant.com:55340/EntityChangeNotifier',
	SignalrNotificationHubEndpoint: 'https://app-apis.renaissant.com:55347/NotificationListener',
	IdleUserHandlerConfig: {
		IdleTime: 120,
		IdleWarningAt: 115,
	},
	ThirdPartyCustomGrant: 'password',
	CognitoSamlPlatformName: 'saml',
	AzureEntraIDConfiguration: {
		ClientID: '53f2ebef-aab9-4ec0-a657-5e2a02093aae',
		Authority: 'https://login.microsoftonline.com/organizations',
		RedirectUri: 'https://live.renaissant.com',
		GraphEndpoint: 'https://graph.microsoft.com/v1.0/me',
		Scopes: ['user.read'],
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
