export class Notification {
    public ID: number;
    public ApplicationCode: string;
    public ParentEntityID: number;
    public EntityID: number;
    public InternalKey: string;
    public SubjectTemplate: string;
    public MessageTemplate: string;
    public StartDate: Date;
    public EndDate: Date;
    public IsCancelled: boolean;
    public NotificationMode: string;
    public CreatedBy: string;
    public CreatedOn: Date;
    public ModifiedBy: string;
    public ModifiedOn: Date;

    public LoginName: string;
    public Email: string;
    public PhoneNumber: string;
    public NotificationStatus: string;

    public EventCode: string;
    public Payload: string;
    public HasTemplate: boolean;

    public UserIds: string[];
}
