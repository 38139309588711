import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, Injector } from '@angular/core';
import { IgxGridComponent, SortingDirection } from 'igniteui-angular';
import { ContractHttpResponse, UserSettingsService, LayoutService, UtilityService, CommonMessageKeys, PermissionManagerService,
   BaseLVComponent, SsiTranslatorPipe } from 'ssi-framework';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { Notification } from '../../models/notification';
import { NotificationConstants } from '../../Common/notification-constants';
import { NotificationRecipient } from '../../models/notification-recipient';

@Component({
  selector: 'lib-ssi-notification-lv',
  templateUrl: './notification-lv.component.html',
  styleUrls: ['./notification-lv.component.scss']
})
export class NotificationLVComponent extends BaseLVComponent<Notification> {

  @ViewChild('FWNotificationGrid', {static: false}) public gridRef: IgxGridComponent;
  public notificationModes: any[] = [];
  public notificationStatuses: any[] = [];
  public recipients: NotificationRecipient[];
  public notificationID: number;
  private _ssiTranslatorPipe : SsiTranslatorPipe;
  private _prevRow: any = null;
  constructor(private layoutService: LayoutService, private _injector: Injector,
    viewRef: ViewContainerRef, componentResolver: ComponentFactoryResolver, userSettingsService: UserSettingsService, router: Router,
     route: ActivatedRoute, private notificationService: NotificationService, utilityService: UtilityService,
      commonMessageKeys: CommonMessageKeys, public permissionMangerService: PermissionManagerService) {
    super(viewRef, componentResolver, userSettingsService, utilityService, commonMessageKeys, router, route);
    this._ssiTranslatorPipe = new SsiTranslatorPipe(_injector);

  }

  public setTitle() {
    const title = this._ssiTranslatorPipe.transform('MANAGE_NOTIFICATIONS_TITLE');
    this.layoutService.setTitle(title);
  }

  protected onBeforeLoad(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loadNotificationModes();
      this.loadNotificationStatuses();
      resolve(true);
    });
  }

  protected getData(pageIndex: number, pageSize: number, orderBy: string, orderDir: SortingDirection, filter: any): Promise<any> {
    const response = this.notificationService.getPaged(pageIndex, pageSize, orderBy, orderDir, filter);
    return response;
  }

  protected onAfterLoad() {
  }

  private loadNotificationModes() {
    this.notificationModes.push({ Text: NotificationConstants.Modes.Email, Value: NotificationConstants.Modes.Email });
    this.notificationModes.push({ Text: NotificationConstants.Modes.InApp, Value: NotificationConstants.Modes.InApp });
  }

  private loadNotificationStatuses() {
    this.notificationStatuses.push({ Text: NotificationConstants.Statuses.Read, Value: NotificationConstants.Statuses.Read });
    this.notificationStatuses.push({ Text: NotificationConstants.Statuses.UnRead, Value: NotificationConstants.Statuses.UnRead });
    this.notificationStatuses.push({ Text: NotificationConstants.Statuses.Unknown, Value: NotificationConstants.Statuses.Unknown });
    this.notificationStatuses.push({ Text: NotificationConstants.Statuses.Failed, Value: NotificationConstants.Statuses.Failed });
  }

  public handleRowSelection(e) {
    const rowID = e.newSelection[0].ID;
    const callback = this.notificationService.GetRecipients(rowID);
    callback.then((response: ContractHttpResponse<NotificationRecipient[]>) => {
      if (response.Success) {
        this.notificationID = rowID;
        this.recipients = response.Source;
      }
      else {
      }
    });
  }

  public addSpace(str: string): string {
    if (str === 'InApp') {
    }
    return str;
  }
}
