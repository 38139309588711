import { Input, Directive, ElementRef, Renderer2, HostListener, OnDestroy, SimpleChanges, NgZone, OnInit, OnChanges } from '@angular/core';

@Directive({
  selector: '[ssiTooltip]'
})
export class SsiTooltipDirective implements OnInit, OnChanges, OnDestroy {

  @Input('ssiTooltip') tooltipText: string;
  @Input() placement = 'bottom';
  @Input() tooltipClass: string;
  @Input() delay = 500;
  @Input() trigger: string;
   clickOutsideEnabled = true;

  private _tooltip: HTMLElement;
  offset = 10;
  private _events: Array<string> = ['click'];

  constructor(private el: ElementRef, private renderer: Renderer2,
    private _ngZone: NgZone) {
    this._initOnClickBody = this._initOnClickBody.bind(this);
    this._onClickBody = this._onClickBody.bind(this);
   }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.trigger !== 'click') {
      if (!this._tooltip) { this.show(); }
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (this.trigger !== 'click') {
      if (this._tooltip) { this.hide(); }
    }
  }

  @HostListener('click')
  onClick() {
    if (this.trigger === 'click') {
      if (!this._tooltip) {
        this.show();
      }
      else {
        this.hide();
      }
    }
    else {
      if (this._tooltip) {  this.hide(); }
    }
  }

  ngOnDestroy() {
    this._removeClickOutsideListener();
    this.hide();
  }

  private show() {
    this.create();
    if (this._tooltip) {
      this.clickOutsideEnabled = true;
      this.setPosition();
      this.renderer.addClass(this._tooltip, 'ng-tooltip-show');
    }
  }

  private hide() {
    this.clickOutsideEnabled = false;
    if (this._tooltip) {
      this.renderer.removeClass(this._tooltip, 'ng-tooltip-show');
      window.setTimeout(() => {
        if (document.body.contains(this._tooltip)) {
          this.renderer.removeChild(document.body, this._tooltip);
        }
        this._tooltip = null;
      }, this.delay / 2);
    }
  }

  private create() {
    if (this.tooltipText != null && this.tooltipText !== '') {
      this._tooltip = this.renderer.createElement('span');
      const tooltipInner = this.renderer.createElement('span');

      this.renderer.appendChild(
        tooltipInner,
        this.renderer.createText(this.tooltipText)
      );

      this.renderer.appendChild(
        this._tooltip,
        tooltipInner
      );

      this.renderer.appendChild(document.body, this._tooltip);
      this.renderer.addClass(this._tooltip, 'ng-tooltip');
      this.renderer.addClass(this._tooltip, `ng-tooltip-${this.placement}`);
      this.renderer.addClass(this._tooltip, `${this.tooltipClass}`);

      this.renderer.setStyle(this._tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
      this.renderer.setStyle(this._tooltip, '-moz-transition', `opacity ${this.delay}ms`);
      this.renderer.setStyle(this._tooltip, '-o-transition', `opacity ${this.delay}ms`);
      this.renderer.setStyle(this._tooltip, 'transition', `opacity ${this.delay}ms`);
    }
  }

  private setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();

    const tooltipPos = this._tooltip.getBoundingClientRect();

    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top;
    let left;
    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.renderer.setStyle(this._tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this._tooltip, 'left', `${left}px`);
  }

// Handled Outside click for ssi-tooltip

  ngOnInit() {
    this._initOnClickBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.attachOutsideOnClick) {
      this._initOnClickBody();
    }
  }
  private _initOnClickBody() {
      this._initClickOutsideListener();
  }
  private _onClickBody(ev: Event) {
    if (!this.clickOutsideEnabled) { return; }
    if (!this.el.nativeElement.contains(ev.target) ) {
      this._emit(ev);
    }
  }

  private _emit(ev: Event) {
    if (!this.clickOutsideEnabled) { return; }
    this._ngZone.run(() => this.hide());
  }

  private _initClickOutsideListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => document.body.addEventListener(e, this._onClickBody));
    });
  }
  private _removeClickOutsideListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => document.body.removeEventListener(e, this._onClickBody));
    });
  }

}
