<div class="portlet portlet__wrap">
  <div class="ssi-formGroup">
    <div class="btn-holder">
      <ul class="btnlist">
        <li><button tabindex="{{ showPasswordFields() ? 12 : 10}}" igxButton="flat" type="submit" class="btn-primary"
            (click)="onSave()" *ngIf="permissionMangerService.checkPermission('ADD_USER')" ssiTooltip="Save"><span
              class="icon icon-save"></span>Save</button></li>
        <li><button tabindex="{{ showPasswordFields() ? 13 : 11}}" igxButton="flat" type="submit" (click)="onCancel()"
            ssiTooltip="Cancel"><span class="icon icon-times-circle"></span>Cancel</button></li>
      </ul>
    </div>
    <div class="ssi-FourColForm">
      <div class="row">
        <div class="col control-holder">
          <igx-input-group>
            <label igxLabel for="Login">{{'LOGIN_NAME_LBL' | ssiTranslator }}</label>
            <input igxFocus="true" tabindex="1" igxInput ngModel [(ngModel)]="model.LoginName" name="LoginName"  autocomplete="none"
              type="text" [required]="true" [readonly]="model.ID > 0? 'readonly':null" />
          </igx-input-group>
        </div>

        <!-- commented for CCH -->
        <!-- Title DropDown -->
        <!-- <div class="col control-holder">
          <lib-ssi-dropdown index="2" label="Title" name="Title" [items]="titles" itemKey="Value" itemText="Name"
            [(model)]="model.Title" width="160px" [required]="true" [readonly]="true" [showClearButton]="false">
          </lib-ssi-dropdown>
        </div> -->

        <div class="col control-holder">
          <igx-input-group>
            <label igxLabel for="FirstName">{{'FIRST_NAME_LBL' | ssiTranslator }}</label>
            <input tabindex="3" igxInput ngModel [(ngModel)]="model.FirstName" name="FirstName" type="text"  autocomplete="none"
              [required]="true" />
          </igx-input-group>
        </div>
        <div class="col control-holder">
          <igx-input-group>
            <label igxLabel for="MiddleName">{{'MIDDLE_NAME_LBL' | ssiTranslator }}</label>
            <input tabindex="4" igxInput ngModel [(ngModel)]="model.MiddleName" name="MiddleName" type="text"  autocomplete="none" />
          </igx-input-group>
        </div>
        <div class="col control-holder">
          <igx-input-group>
            <label igxLabel for="LastName">{{'LAST_NAME_LBL' | ssiTranslator }}</label>
            <input tabindex="5" igxInput ngModel [(ngModel)]="model.LastName" name="LastName" type="text"  autocomplete="none"
              [required]="true" />
          </igx-input-group>
        </div>
        <div class="col control-holder">
          <igx-input-group>
            <label igxLabel for="Email">{{'EMAIL_LBL' | ssiTranslator }}</label>
            <input tabindex="6" igxInput ngModel [(ngModel)]="model.Email" name="Email" type="email"  autocomplete="none"
              [required]="true" />
          </igx-input-group>
        </div>
        <div class="col control-holder">
          <!-- <lib-ssi-dropdown index="7" label="{{'COMPANY_LBL' | ssiTranslator }}" name="CompanyName" [items]="companies" itemKey="ID" itemText="Name"
            [(model)]="model.CompanyID" width="300px" [required]="true" (ItemSelected)='OnCompanyChanged($event)'>
          </lib-ssi-dropdown> -->

          <lib-ssi-select label="{{'COMPANY_LBL' | ssiTranslator }}" name="CompanyName" [items]="companies"
            itemKey="ID" itemText="Name" [(singleModel)]="model.CompanyID" [singleSelect]="true" tabindex="7" (ItemSelected)='OnCompanyChanged($event)' [required]="true">
          </lib-ssi-select>
        </div>

        <div class="col control-holder d-none">
            <lib-ssi-select label="{{'TEAM_LBL' | ssiTranslator }}" name="TeamName" [items]="teams"
             itemKey="ID" itemText="Name" [singleSelect]="true" [(singleModel)]="model.TeamID" [required]="true">
            </lib-ssi-select>
        </div>

        <div class="col control-holder">
          <lib-ssi-select label="{{'ROLES_LBL' | ssiTranslator }}" name="roles"
            [items]="roles" itemKey="ID" itemText="Name" [(multiModel)]="selectedRoleIds" tabindex="9">
          </lib-ssi-select>
        </div>

      </div>
    </div>
    <div class="ssi-FourColForm">
      <div class="row">
        <div class="col control-holder">
          <igx-input-group *ngIf="showPasswordFields()">
            <label igxLabel>{{'PASSWORD_LBL' | ssiTranslator }}</label>
            <input tabindex="10" tabindex="10" igxInput ngModel [(ngModel)]="model.Password" name="Password"  autocomplete="none"
              type="password" [required]="true" />
          </igx-input-group>
        </div>
        <div class="col control-holder">
          <igx-input-group *ngIf="showPasswordFields()">
            <label igxLabel>{{'CONFIRM_PASSWORD_LBL' | ssiTranslator }}</label>
            <input tabindex="11" igxInput ngModel [(ngModel)]="model.ConfirmPassword" name="ConfirmPassword"  autocomplete="none"
              type="password" [required]="true" />
          </igx-input-group>
        </div>
      </div>
    </div>
  </div>
</div>