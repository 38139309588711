export class UserSettings {
    public ID: number;
    public CreatedBy: string;
    public CreatedOn: Date;
    public ModifiedBy: string;
    public ModifiedOn: Date;
    public IsDeleted: Boolean;
    public Name: string;
    public Description: string;
    public Code: string;
    public AppCode: string;
    public Value: string;
    public UserId: number;
    public DataType: string;
}