import { Injectable } from '@angular/core';
import { AuthenticateClientService } from './authenticate-client.service';
import { WebApiProxyFactoryService } from '../WebApiClient/web-api-proxy-factory.service';

@Injectable({
  providedIn: 'root'
})
export class UserContextService {

  private readonly _nameIdentifier= "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";
  private readonly _givenName = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname";
  private readonly _email = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress";
  
  constructor(private authClient: AuthenticateClientService, private proxyFactory: WebApiProxyFactoryService) { }

  public get companyId(): number {
    const companyId = this.authClient.getClaim('company_id');
    if (companyId != null) {
      return companyId;
    }
    return 0;
  }

  public get companyCode(): string {
    const companyCode = this.authClient.getClaim('company_code');
    if (companyCode != null) {
      return companyCode;
    }
    return 'Unknown';
  }

  public get comanyName(): string {
    const comanyName = this.authClient.getClaim('company_name');
    if (comanyName != null) {
      return comanyName;
    }
    return 'Unknown';
  }

  public get teamId(): number {
    const teamId = this.authClient.getClaim('team_id');
    if (teamId != null) {
      return teamId;
    }
    return 0;
  }

  public get teamCode(): string {
    const teamCode = this.authClient.getClaim('team_code');
    if (teamCode != null) {
      return teamCode;
    }
    return 'Unknown';
  }

  public get teamName(): string {
    const teamName = this.authClient.getClaim('team_name');
    if (teamName != null) {
      return teamName;
    }
    return 'Unknown';
  }

  public get userId(): number {
    let userId = this.authClient.getClaim('sub');
    if (userId == null) {
      userId = this.authClient.getClaim(this._nameIdentifier);
    }
    return userId != null ? userId : 0;
  }

  public get title(): string {
    const title = this.authClient.getClaim('title');
    if (title != null) {
      return title;
    }
    return 'Unknown';
  }

  public get userName(): string {
    const userName = this.authClient.getClaim('name');
    if (userName != null) {
      return userName;
    }
    return 'Unknown';
  }

  public get firstName(): string {
    const firstName = this.authClient.getClaim('first_name');
    if (firstName != null) {
      return firstName;
    }
    return 'Unknown';
  }

  public get middleName(): string {
    const middleName = this.authClient.getClaim('middle_name');
    if (middleName != null) {
      return middleName;
    }
    return 'Unknown';
  }

  public get lastName(): string {
    const lastName = this.authClient.getClaim('last_name');
    if (lastName != null) {
      return lastName;
    }
    return 'Unknown';
  }

  public get fullName(): string {
    let fullName = this.authClient.getClaim('given_name');
    if (fullName == null) {
      fullName = this.authClient.getClaim(this._givenName);
    }
    return fullName != null ? fullName : 'Unknown';
  }

  public get phone(): string {
    const phone = this.authClient.getClaim('phone');
    if (phone != null) {
      return phone;
    }
    return 'Unknown';
  }

  public get email(): string {
    let email = this.authClient.getClaim('email');
    if (email != null) {
      email = this.authClient.getClaim(this._email);
    }
    return email != null ? email : 'Unknown';
  }

  public get clientID(): string {
    const clientID = this.authClient.getClaim('client_id');
    if (clientID != null) {
      return clientID;
    }
    return 'Unknown';
  }

  public get appCode(): string {
    const appCode = this.authClient.getClaim('client_appcode');
    if (appCode != null) {
      return appCode;
    }
    return 'Unknown';
  }

  public get culture(): string {
    const culture = this.authClient.getClaim('user_culture');
    if (culture != null) {
      return culture;
    }
    return 'Unknown';
  }

  public get passwordResetRequired(): boolean {
    const culture = this.authClient.getClaim('is_password_reset_required');
    if (culture != null) {
      return culture;
    }
    return false;
  }
}
