import { UserProfileCredentials } from 'src/app/loading-dock/models/user-profile-credentials';
import { UserProfileCommon } from './user-profile-common.model';

export class UserProfile extends UserProfileCommon {
	public FirstName: string;
	public MiddleName: string;
	public LastName: string;
	public DateOfBirth: Date;
	public Age?: string;
	public Gender: string;
	public IdentityUserID: number;
	public CompanyID: number;
	public Title: string;
	public Password: string;
	public ConfirmPassword: string;
	public User: string;
	public PersonalEmail: string;
	public CertificationLevel: string;
	public CertificationLicenseNumber: string;
	public OtherID: string;
	public DeviceToken: string;
	public Comments: string;
	public AndroidDeviceToken: string;
	public HomePhone: string;
	public Address: string;
	public City: string;
	public State: string;
	public Zip: string;
	public Country: string;
	public IsTwoFactAuthenticationActive: boolean;
	public TenantID: number;
	public MasterUserProfileID: number;
	public PreferredLanguageLookupID: number;
	public IsSaveChildTable: boolean;
	public isMapToUserProfileProperties: boolean;
	public NoOfAffiliatedTenant: number;
	public IsMasterCopy: boolean;
	public UpdateType: number;
	public IsUpdateName: boolean;
	public ContractingCompanyList: string;
	public NoOfAffiliatedDistrict: number;
	public SubTenantID: number;
	public CreatedBy: string;
	public CreatedOn: Date;
	public ModifiedBy: string;
	public ModifiedOn: Date;
	public CellPhone: string;
	public PreferredLanguageLookupName: string;
	public AffiliationCollection: string;
	public UserProfileCredentialsList: UserProfileCredentials[];
	public Role: string;
	public ExternalCompanyID: number;
	public DefaultLandingPage: string;
	public PhoneExtension: string;
	public UserPhotoAttachmentId: number;
	public LicenseNumber: string;
	public IsSocialUser: string;
	public IsVerified: boolean;
	public DriverCDLAttachmentId: number;
	public CertificationLicenseNumberExpiration: Date;
	public IdDocNumber: string;
	public IdDocNumberExpiry: Date;
	public VisitorFirstName: string;
	public VisitorLastName: string;
}
